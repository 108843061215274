<template>
  <div>
    <b-modal
      id="modal-3"
      size="sm"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div class="row" id="validate_mapSetting">
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
          <b-list-group flush>
            <!-- <b-list-group horizontal="md"> -->

            <!-- <b-list-group-item style="background-color:transparent;color:black;"> -->
            <b-form-group
              label="Map Title"
              label-for="title"
              :invalid-feedback="errors.first('title')"
              style="width: 100%; padding: 0px 0px 10px 0px"
            >
              <b-form-input
                name="title"
                type="text"
                placeholder="Enter Title"
                v-model="map.title"
                :state="submitted ? !errors.has('title') : null"
                v-validate="'required|min:2|max:50'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Center Latitude"
                label-for="centLat"
                :invalid-feedback="errors.first('centLat')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="centLat"
                  type="text"
                  placeholder="Enter Center Latitude"
                  v-model="map.floorOptions.center[0]"
                  :state="submitted ? !errors.has('centLat') : null"
                  v-validate="'required|decimal:10|min_value:-90|max_value:90'"
                  style="width: 100%"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Center Logitude"
                label-for="centLng"
                :invalid-feedback="errors.first('centLng')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="centLng"
                  type="text"
                  placeholder="Enter Center Logitude"
                  v-model="map.floorOptions.center[1]"
                  :state="submitted ? !errors.has('centLng') : null"
                  v-validate="
                    'required|decimal:10|min_value:-180|max_value:180'
                  "
                  style="width: 100%"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-form-group
              label="Tile Map"
              label-for="tileId"
              :invalid-feedback="errors.first('tileId')"
              style="width: 100%; padding: 0px 0px 10px 0px"
            >
              <b-form-select
                name="tileId"
                size="lg"
                v-model="map.floorOptions.tileId"
                :options="tileIdList"
                :state="submitted ? !errors.has('tileId') : null"
                v-validate="'required'"
                style="width: 100%"
              ></b-form-select>
            </b-form-group>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Minimum Zoom"
                label-for="minZoom"
                :invalid-feedback="errors.first('minZoom')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="minZoom"
                  type="range"
                  min="0"
                  max="25"
                  placeholder="Enter Minimum Zoom"
                  v-model="map.floorOptions.minZoom"
                  :state="submitted ? !errors.has('minZoom') : null"
                  v-validate="'required|decimal|min_value:0|max_value:25'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.minZoom }}</div>
              </b-form-group>

              <b-form-group
                label="Maximum Zoom"
                label-for="maxZoom"
                :invalid-feedback="errors.first('maxZoom')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="maxZoom"
                  type="range"
                  min="0"
                  max="25"
                  placeholder="Enter Smooth Factor"
                  v-model="map.floorOptions.maxZoom"
                  :state="submitted ? !errors.has('maxZoom') : null"
                  v-validate="'required|decimal|min_value:0|max_value:25'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.maxZoom }}</div>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Max. Native Zoom"
                label-for="maxNativeZoom"
                :invalid-feedback="errors.first('maxNativeZoom')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="maxNativeZoom"
                  type="range"
                  min="0"
                  max="25"
                  placeholder="Enter Maximum Native Zoom"
                  v-model="map.floorOptions.maxNativeZoom"
                  :state="submitted ? !errors.has('maxNativeZoom') : null"
                  v-validate="'required|decimal|min_value:0|max_value:25'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.maxNativeZoom }}</div>
              </b-form-group>

              <b-form-group
                label="View Zoom"
                label-for="viewZoom"
                :invalid-feedback="errors.first('viewZoom')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="viewZoom"
                  type="range"
                  min="0"
                  max="25"
                  placeholder="Enter Maximum Native Zoom"
                  v-model="map.floorOptions.viewZoom"
                  :state="submitted ? !errors.has('viewZoom') : null"
                  v-validate="'required|decimal|min_value:0|max_value:25'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.viewZoom }}</div>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Line Weight"
                label-for="weight"
                :invalid-feedback="errors.first('weight')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="weight"
                  type="range"
                  min="0"
                  max="10"
                  step="0.1"
                  placeholder="Enter Line Weight"
                  v-model="map.floorOptions.weight"
                  :state="submitted ? !errors.has('weight') : null"
                  v-validate="'required|decimal:3|min_value:0|max_value:10'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.weight }}</div>
              </b-form-group>

              <b-form-group
                label="Smooth Factor"
                label-for="smoothFactor"
                :invalid-feedback="errors.first('smoothFactor')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="smoothFactor"
                  type="range"
                  min="0"
                  max="10"
                  step="0.1"
                  placeholder="Enter Smooth Factor"
                  v-model="map.floorOptions.smoothFactor"
                  :state="submitted ? !errors.has('smoothFactor') : null"
                  v-validate="'required|decimal:3|min_value:0|max_value:10'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.smoothFactor }}</div>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Line Opacity"
                label-for="opacity"
                :invalid-feedback="errors.first('opacity')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="opacity"
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  placeholder="Enter opacity"
                  v-model="map.floorOptions.opacity"
                  :state="submitted ? !errors.has('opacity') : null"
                  v-validate="'required|decimal:3|min_value:0|max_value:1'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.opacity }}</div>
              </b-form-group>

              <b-form-group
                label="Fill Opacity"
                label-for="fillOpacity"
                :invalid-feedback="errors.first('fillOpacity')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="fillOpacity"
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  placeholder="Enter Fill Opacity"
                  v-model="map.floorOptions.fillOpacity"
                  :state="submitted ? !errors.has('fillOpacity') : null"
                  v-validate="'required|decimal:3|min_value:0|max_value:1'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.fillOpacity }}</div>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row; gap: 10px">
              <b-form-group
                label="Line Color"
                label-for="color"
                :invalid-feedback="errors.first('color')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="color"
                  type="color"
                  placeholder="Enter Color"
                  v-model="map.floorOptions.color"
                  :state="submitted ? !errors.has('color') : null"
                  v-validate="'required'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.color }}</div>
              </b-form-group>

              <b-form-group
                label="Fill Color"
                label-for="fillcolor"
                :invalid-feedback="errors.first('fillcolor')"
                style="width: 100%; padding: 0px 0px 10px 0px"
              >
                <b-form-input
                  name="fillcolor"
                  type="color"
                  placeholder="Enter Fill Color"
                  v-model="map.floorOptions.fillColor"
                  :state="submitted ? !errors.has('fillcolor') : null"
                  v-validate="'required'"
                  style="width: 100%"
                ></b-form-input>
                <div>{{ map.floorOptions.fillColor }}</div>
              </b-form-group>
            </div>

            <!-- <b-form-group
                                label="Order"
                                label-for="order"
                                :invalid-feedback="errors.first('order')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-input
                                    name="order"
                                    type="range" min="-5" max="5"
                                    placeholder="Enter Order"
                                    v-model="map.options.order"
                                    :state="(submitted)? (!errors.has('order')): null"
                                    v-validate="'required|decimal|min_value:-5|max_value:5'"
                                    style="width:100%"
                                ></b-form-input>
                                <div> {{ map.options.order }}</div>
                            </b-form-group>    -->

            <!-- </b-list-group-item> -->
          </b-list-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import mySwitch from "./mySwitch.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "mapSetting",
  data() {
    return {
      modalTitle: "",
      tileIdList: [{ text: "Select One", value: null },
        { text: "outdoors", value: 0 },
        { text: "streets", value: 1 },
        { text: "dark", value: 2 },
        { text: "light", value: 3 },
        { text: "satellite", value: 4 },
        { text: "satellite streets", value: 5 },
        { text: "navigation day", value: 6 },
        { text: "navigation night", value: 7 }],

      submitted: false,
      successful: false,
      settingMode: null,
      message: "",
      map: {
        _id: "",
        title: "",
        floorOptions: {
          fillColor: "",
          color: "",
          weight: "",
          fillOpacity: "",
          opacity: "",
          smoothFactor: "",
          minZoom: "",
          maxZoom: "",
          maxNativeZoom: "",
          viewZoom: "",
          tileId: "",
          center: [],
          type: ""
        }
      }
    };
  },
  computed: {
    mapData() {
      return this.$store.state.panel.mapData;
    },
    updateMap() {
      return this.$store.state.panel.updateMap;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    // toggledSwitches(newValue, oldValue) {
    //     for (var feeder of newValue){
    //         if (this.feeders.find( ({ id }) => id === feeder.id.feeder ))
    //             this.feeders.find( ({ id }) => id === feeder.id.feeder ).status = feeder.status;
    //     }
    // }
    parent2child(val) {
      if (val.task == "settingGet") {
        this.map.floorOptions = val.item.details.floorOptions;
        this.map._id = val.item._id;
        this.map.title = val.item.title;
      } else if (val.task == "markerSetting") {
        console.log(val.item, "markerSetting");
        this.settingMode = "markerSetting";
        this.map.floorOptions = val.item;
      }
    }
  },
  methods: {
    initModal: function() {
      this.modalTitle = "Map Settings";
      this.map._id = this.mapData._id;
      this.map.title = this.mapData.title;
      // this.map.floorOptions = this.mapData.options;
    },
    resetModal: function() {
      this.modalTitle = null;
      this.settingMode = null;
      this.map = {
        _id: "",
        title: "",
        floorOptions: {
          fillColor: "",
          color: "",
          weight: "",
          fillOpacity: "",
          opacity: "",
          smoothFactor: "",
          minZoom: "",
          maxZoom: "",
          maxNativeZoom: "",
          viewZoom: "",
          tileId: "",
          center: [],
          type: ""
        }
      };
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.settingMode == "markerSetting") {
        this.$store.dispatch("chpge/child2parent", {
          task: "settingMapForMarker",
          item: this.map.floorOptions
        });
        this.$nextTick(() => {
          this.$bvModal.hide("modal-3");
        });
      } else {
        this.message = "";
        this.submitted = true;
        const isValid = new FormValidator("#validate_mapSetting").validate();
        if (isValid) {

          this.$store.dispatch("data/editFloor", {
            type: "floor",
            title: this.map.title,
            _id: this.map._id,
            details: {
              floorOptions: this.map.floorOptions,
              floorTitle: this.map.title,
              floorPoints: this.parent2child.item.details.floorPoints,
              floorZones: this.parent2child.item.details.floorZones
            }
          }).then(
            data => {
              this.message = data.message;
              this.successful = true;
              this.$store.dispatch("panel/updateMap", this.updateMap + 1);
              this.$nextTick(() => {
                this.$bvModal.hide("modal-3");
              });
              // this.$store.dispatch('chpge/changePage','controllerSettings')
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );


        }

      }
    }
  }

};
</script>
