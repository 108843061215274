<template>
  <div>
    <b-container fluid class="p-0">
      <div class="my-3 d-flex" :class="{'justify-content-end':lang=='eng','justify-content-start':lang=='per'}">
        <b-button
          variant="primary"
          @click="openUploadModal"
        >
          <span class="e-icons e-add"></span>
          {{ dict.updloadText[lang] }}
        </b-button>
      </div>
      <div class="row mx-auto">
        <div
          :class="
            screenWidth <= 1024
              ? 'col-md-5  col-12 mb-5'
              : 'col-md-3  col-12 mb-5'
          "
          v-for="(item, index) in chartsInfo"
          :id="'holder_' + item.chartData.names"
          :key="index"
        >
        </div>
      </div>
    </b-container>
    <b-modal
      id="uploadLicence"
      size="md"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      hide-footer
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.uploadHeader[lang] }}</h5>
        </div>
      </template>


      <div :dir="dict.dir[lang]" class="form-group mb-2">
        <label class="form-label" for="indoorMap">{{ dict.text[lang] }} </label>
        <div class="d-flex justify-content-between align-items-center">
          <input
            class="form-control"
            type="file"
            ref="indoorMap"
            id="indoorMap"
            @change="uploadLicence"
            accept=".megashid"
          />
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import Plotly from "plotly.js-dist/plotly";
import { mapGetters } from "vuex";
import { debounce } from "@/services/data.service";

export default {
  name: "licenceManager",
  data() {
    return {
      data: [],
      takhsis: [],
      layout: {
        paper_bgcolor: "#f9fafb",
        plot_bgcolor: "#f9fafb",
        autosize: true,
        width: this.screenWidth * 0.15 - 50,
        height: this.screenHeight * 0.4,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0
        },
        showlegend: true,
        legend: {
          x: 1,
          y: 1,
          bgcolor: "#E2E2E2",
          bordercolor: "#FFFFFF",
          borderwidth: 2,
          font: {
            family: "Vazir",
            size: 12,
            color: "black"
          },
          orientation: "v"
        }
      },
      resultUpload: {},
      file: "",
      dict: {
        free:{per:'فضای آزاد',eng:'free space'},
        allocated:{per:'فضای اختصاص داده شده',eng:'allocated space'},
        used:{per:'فضای استفاده شده',eng:'used space'},
        updloadText: { per: "آپلود فایل", eng: "upload licence" },
        dir: { per: "rtl", eng: "ltr" },
        text: { per: "انتخاب فایل", eng: "choose file" },
        uploadHeader: {
          per: "بارگذاری فایل منابع",
          eng: "upload licence file"
        },
        cancel: { per: "بستن", eng: "cancel" },
        ok: { per: "دخیره", eng: "save" },
        jsonMqtt: { per: "پروتکل mqtt با نگاشت جیسون", eng: "Json Mqtt" },
        mtr22Mqtt: { per: "پروتکل mqtt با نگاشت mtr22", eng: "Mtr22 Mqtt" },
        jsonHttps: { per: "پروتکل https با نگاشت جیسون", eng: "Json Https" },
        jsonHttpsServers: { per: "پروتکل سرور https با نگاشت جیسون", eng: "Json Https Servers" },
        jsonCloud: { per: "تعداد فضای ابری", eng: "Json Cloud" },
        totalControlNodes: { per: "دستورات", eng: "Rules" },
        totalEventRules: { per: "قوانین رویدادها", eng: "Event Rules" },
        totalEventTypes: { per: "انواع رویدادها", eng: "Event Types" },
        totalDataIngestionModules: { per: "فرایند استخراج داده", eng: "Data Ingestion Modules" },
        totalProcessFlows: { per: "چرخه های فرایند", eng: "Process Flows" },
        totalData: { per: "تعداد دیتاهای سیستم", eng: "Datas" },
        totalMaps: { per: "نقشه ها", eng: "Maps" },
        totalDashboardWidgets: { per: "ویجت های دشبورد", eng: "Dashboard Widgets" },
        numberOfWorkbenches: { per: "میزکارها", eng: "Workbenches" }
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "rgb(104, 106, 108)",
              "rgb(17, 60, 252)",
              "rgb(86, 170, 255)"
            ],
            hoverOffset: 4
          }
        ]
      },
      chartsInfo: [],
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),

    lang() {
      return this.$store.state.chpge.lang;
    },
    holeUsage() {
      return this.$store.state.data.usedResources.message;
    },
    allRes() {
      return this.$store.state.data.resource;
    }
  },
  watch: {
    screenWidth: {
      handler() {
        debounce(() => {
          this.generator();
        }, 100);
      }
    },
    allRes: {
      handler() {
        this.generator();
      }, deep: true
    },
    lang: {
      handler() {
        this.generator();
      }
    }
  },
  methods: {
    async getData() {
      await this.$store.dispatch("data/getAllSysTakhsis");
      await this.$store.dispatch("data/getMainSysLicences");
      await this.$store.dispatch("data/getUsedSysLicences");
    },
    uploadLicence(val) {
      if (val.target.files[0].name.includes(".megashid")) {
        const formData = new FormData();
        formData.append("file", val.target.files[0]);
        this.$store.dispatch("data/uploadFiles", formData).then((res) => {
          this.$bvModal.hide("uploadLicence");
          this.chartData.labels = [];
          this.chartData.datasets[0].data = [];
          this.resultUpload = res.message;
          this.chartsInfo = [];
          this.$store.dispatch("data/getMainSysLicences");
          this.getData();
          this.generator();

        });
      }
    },
    computeRemain(name, mainVal) {
      if (Object.keys(this.allRes.takhsis).length > 0) {
        if (typeof this.allRes.takhsis[name] == "object") {
          return mainVal - this.allRes.takhsis[name].connections;
        } else
          return mainVal - this.allRes.takhsis[name];
      }
    },
    generator() {
      this.chartsInfo = [];
      Object.entries(this.allRes.all.parameters).forEach((item) => {
        if (item[1].connections != 0)
          this.chartsInfo.push({
            chartData: {
              type: "pie",
              textposition: "inside",
              names: item[0],
              title: {
                text: this.dict[item[0]][this.lang],
                font: {
                  family: "Vazir",
                  size: 15,
                  color: "black"
                },
                x: 0.5,
                y: 0.9,
                xref: "paper",
                yref: "paper"
              },
              info: item[0],
              name: item[0],
              marker: {
                colors: [
                  "rgb(104, 106, 108)",
                  "rgb(17, 60, 252)",
                  "rgb(86, 170, 255)"
                ]
              },
              hoverinfo: "label+percent+name",
              textinfo: "label+value+percent",
              insidetextorientation: "radial",
              labels: [this.dict.free[this.lang]],
              values: [
                this.computeRemain(
                  item[0],
                  typeof item[1] == "object"
                    ? item[1].connections
                    : item[1]
                )
              ],
              hoverOffset: 4
            }
          });
      });
      Object.entries(this.allRes.used).forEach((item) => {
        this.chartsInfo.forEach((chart) => {
          if (chart.chartData.names == item[0]) {
            const a = typeof item[1] == "object" ? item[1].connections : item[1];
            chart.chartData.labels.push(this.dict.used[this.lang]);
            chart.chartData.values.push(a);
          }

        });
      });
      this.chartsInfo.forEach((chart) => {
        Object.entries(this.allRes.takhsis).forEach((item) => {
          if (chart.chartData.names == item[0]) {
            const a = typeof item[1] == "object" ? item[1].connections : item[1];
            const b = typeof this.allRes.used[item[0]] == "object" ? this.allRes.used[item[0]].connections : this.allRes.used[item[0]];
            chart.chartData.labels.push(this.dict.allocated[this.lang]);
            chart.chartData.values.push(a - b);
          }

        });
        setTimeout(() => {
          Plotly.newPlot("holder_" + chart.chartData.names, [chart.chartData], this.layout, {
            displayModeBar: false,
            displaylogo: false,
            responsive: false
          });
        }, 100);
      });
    },
    openUploadModal() {
      this.$bvModal.show("uploadLicence");
    }

  },
  mounted() {
    this.getData();
    this.generator();
  }
};

</script>
<style>
.e-primary {
  background-color: #007bff !important; /* Bootstrap blue color */
  color: white!important;
  border: unset!important;
}


.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>
