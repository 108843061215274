<template>
  <div>
    <div
      class="my-5"
      v-for="(item, index) in linkComponents.color"
      :key="index + 5"
    >
      <p class="text-left mb-3 text-[13px]">
        {{ item.title }}
      </p>
      <b-form-input
        v-model="item.value"
        :value="item.value"
        type="color"
      ></b-form-input>
    </div>
    <div
      class="my-5"
      v-for="(item, index) in linkComponents.router"
      :key="index"
    >
      <p class="text-left mb-3 text-[13px]">{{ item.title }}</p>
      <b-form-select
        name="refPB"
        v-model="item.value"
        :options="routerItems"
        class="form-control"
        style="width: 100%; font-size: small"
      ></b-form-select>
    </div>
    <div
      class="my-5"
      v-for="(item, index) in linkComponents.width"
      :key="index + 9"
    >
      <p class="text-left mb-3 text-[13px]">{{ item.title }}</p>
      <b-form-input v-model="item.value" type="range"></b-form-input>
    </div>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: "linkCustomizer",
  props: {
    value: {
      default: "",
    },
  },
  data() {
    return {
      linkInformation: {},
      linkComponents: {
        color: [
          {
            name: 'color',
            value: "#0EAD69",
            title: "liquid stroke color",
            elName: "liqColor",
          },
          {
            name: 'color',
            value: "#eee",
            title: "line stroke color",
            elName: "lineColor",
          },
          {
            name: 'color',
            value: "#444",
            title: "outline stroke color",
            elName: "outlineColor",
          },
        ],
        router: [
          {
            name: 'select',
            value: "rightAngle",
            title: "routers type",
            elName: "outlineColor",
          },
        ],
        width: [
          {
            name: 'text',
            value: "",
            title: "line stroke width",
            elName: "lineWidth",
          },
          {
            text: 'name',
            value: "",
            title: "outline stroke width",
            elName: "outlineWidth",
          },
        ],
      },
      routerItems: [
        { text: "manhattan", value: "manhattan" },
        { text: "metro", value: "metro" },
        { text: "normal", value: "normal" },
        { text: "orthogonal", value: "orthogonal" },
        { text: "rightAngle", value: "rightAngle" },
      ],
    };
  },
  watch: {
    linkComponents: {
      handler(val) {
        store.commit("updateLink", val);
      },
      deep: true,
    },
  },
};
</script>
