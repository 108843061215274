<template>
  <div>
    <!--    <b-modal-->
    <!--      id="modal-alert-editor"-->
    <!--      size="lg"-->
    <!--      centered-->

    <!--      header-text-variant="dark"-->
    <!--      header-border-variant="primary"-->
    <!--      body-text-variant="dark"-->
    <!--      hide-header-close-->
    <!--      no-close-on-backdrop-->

    <!--      content-class="shadow"-->
    <!--      :title="modalTitle"-->
    <!--      :dir="dict.dir[lang]"-->
    <!--      :ok-title="dict.ok[lang]"-->
    <!--      :cancel-title="dict.cancel[lang]"-->
    <!--      @shown="initModal"-->
    <!--      @hidden="resetModal"-->
    <!--      @ok="handleOk"-->
    <!--      @cancel="resetModal"-->

    <!--    >-->
    <!--     -->
    <!--    </b-modal>-->
    <div>
      <!-- <b-container fluid> -->
      <div class="row" id="events_manager">
        <div class="col-12 col-md-6">
          <ejs-dropdownlist
            floatLabelType="Auto"
            :dataSource="alertTypeList"
            :value="data.type"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.typeAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.type = val.value}"
          ></ejs-dropdownlist>
        </div>
        <div class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.engMessage"
            :placeholder="dict.messageAlertEng[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.engMessage = val.value}"
          ></ejs-textbox>
        </div>
        <div class="col-12 col-md-6 mt-3">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.message"
            :placeholder="dict.messageAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.message = val.value}"
          ></ejs-textbox>
        </div>

        <div v-if="dataType==='Number'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.min"
            :placeholder="dict.minAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.range.min = val.value}"
            type="number"
          ></ejs-textbox>
        </div>
        <div v-if="dataType==='Number'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.max"
            :placeholder="dict.maxAlert[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.range.max = val.value}"
            type="number"
          ></ejs-textbox>
        </div>

        <div v-if="dataType==='Boolean'" class="col-12 col-md-6">
          <ejs-dropdownlist
            floatLabelType="Auto"
            :dataSource="triggerList"
            :value="data.range.state"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.state[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.range.state = val.value}"
          ></ejs-dropdownlist>
        </div>

        <div v-if="dataType==='String'" class="col-12 col-md-6">
          <ejs-textbox
            floatLabelType="Auto"
            :value="data.range.term"
            :placeholder="dict.term[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
            @change="(val) => {data.range.term = val.value}"
          ></ejs-textbox>
        </div>

      </div>

      <!-- </b-container> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "dataPickerModal",

  data() {
    return {
      dict: {
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        typeAlert: { eng: "event type", per: "نوع رویداد" },
        messageAlert: { eng: "message persian", per: "پیام رویداد فارسی" },
        messageAlertEng: { eng: "english message", per: "پیام رویداد انگلیسی" },
        minAlert: { eng: "min", per: "حداقل" },
        maxAlert: { eng: "max", per: "حداکثر" },
        state: { eng: "trigger state", per: "trigger state" },
        term: { eng: "term", per: "term" }
      },
      type: "",
      modalTitle: "",
      dataType: null,
      data: {
        id: "",
        type: null,
        message: "",
        engMessage: "",
        range: {
          min: "",
          max: "",
          state: null,
          term: ""
        }
      },

      alertTypeList: [{ text: "Select One", value: null },
        { text: "Alarm", value: "alarm" },
        { text: "Warning", value: "warning" },
        { text: "Notice", value: "notice" }
      ],

      triggerList: [{ text: "Select One", value: null },
        { text: "True", value: true },
        { text: "False", value: false }
      ],

      submitted: false,
      successful: false,
      message: "",
      validator: null,
      reRen: 0
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child(newValue, oldValue) {
      if (newValue.task === "editAlert") {
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Alert";
        this.dataType = newValue.item.dataType;
        this.data.id = (newValue.item.id || newValue.item.id === 0) ? newValue.item.id : uuidv4();
        this.data.type = newValue.item.details.type ? newValue.item.details.type : null;
        this.data.message = newValue.item.details.message ? newValue.item.details.message : "";
        this.data.engMessage = newValue.item.details.engMessage ? newValue.item.details.engMessage : "";
        this.data.range = newValue.item.details.range ? newValue.item.details.range : {
          min: "",
          max: "",
          state: null,
          term: ""
        };

      }

    }

  },
  methods: {
    initModal: function() {
      this.getAllEventTypes();
      setTimeout(() => {
        // this.validator = new FormValidator("#events_manager", {
        //   rules: {},
        //   customPlacement: (element, error) => {
        //     element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
        //   }
        // });
        this.reRen += 1;
      }, 200);

    },
    resetModal: function() {
      this.type = "";
      this.dataType = "";
      this.modalTitle = "";
      this.data = {
        id: "",
        type: null,
        message: "",
        range: { min: "", max: "", state: null, term: "" }
      };

      this.reRen = 0;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#events_manager").validate();
      if (isValid) {
        this.$store.dispatch("chpge/child2parent", { task: "editAlert", type: this.type, item: this.data });
        return true;
      } else return false;

    },
    getAllEventTypes() {
      this.alertTypeList = [];
      this.$store.dispatch("data/getAllEventTypes").then((res) => {
        res.forEach(item => {
          this.alertTypeList.push({ "text": item.name, "value": item._id });
        });
      });
    }
  },
  mounted() {
    this.initModal();
  },
  beforeDestroy() {
    this.resetModal();
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>