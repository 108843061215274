export default class User {
    constructor(username, email, password, role, floor, selectedFloor, selectedView) {
      this.username = username;
      this.email = email;
      this.password = password;
      this.role = role;
      this.floor = floor;
      this.selectedFloor = selectedFloor;
      this.selectedView = selectedView;
    }
  }
  