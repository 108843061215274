<template>
  <div :dir="dict.dir[lang]" class="container-fluid h-100 p-0 bg-white">

          <div class="row h-100 g-0 align-items-center">
            <div class="col-lg-6 mb-5 mb-lg-0">
              
              <div :class="lang==='per'?'card cascading-right':'card cascading-left'" style="
                      background: hsla(0, 0%, 100%, 0.55);
                      backdrop-filter: blur(30px);
                      width: 50%;"
              >
                
                <div class="card-body p-5 shadow-5">
                  <h2 class="fw-bold mb-5">{{dict.title[lang]}}</h2>
                    
                  <div :class="lang==='per'?'form-outline mb-4 text-end d-flex justify-content-between':'form-outline mb-4 text-start d-flex justify-content-between'">
                    <div :class="lang==='per'?'form-outline text-end':'form-outline text-start'">
                      <label class="form-label " for="fname" style="margin-left: 0px;">{{dict.fname[lang]}}</label>
                      <input type="text" id="fname" class="form-control" v-model="user.fname" v-validate="'required|min:1|max:40'" name="fname">
                      <div v-if="submitted && errors.has('fname')" class="alert alert-danger" role="alert" >{{errors.first('fname')}}</div>
                    </div>
                    <div :class="lang==='per'?'form-outline text-end':'form-outline text-start'">
                      <label class="form-label " for="lname" style="margin-left: 0px;">{{dict.lname[lang]}}</label>
                      <input type="text" id="lname" class="form-control" v-model="user.lname" v-validate="'required|min:1|max:40'" name="lname">
                      <div v-if="submitted && errors.has('lname')" class="alert alert-danger" role="alert" >{{errors.first('lname')}}</div>
                    </div>
                  </div>

                  <div :class="lang==='per'?'form-outline mb-4 text-end':'form-outline mb-4 text-start'">
                    <label class="form-label " for="email" style="margin-left: 0px;">{{dict.email[lang]}}</label>
                    <input type="email" id="email" class="form-control" v-model="user.email" v-validate="'required|email'" name="email">
                    <div v-if="submitted && errors.has('email')" class="alert alert-danger" role="alert" >{{errors.first('email')}}</div>
                  </div>

                  <div :class="lang==='per'?'form-outline mb-4 text-end':'form-outline mb-4 text-start'">
                    <label class="form-label " for="phone" style="margin-left: 0px;">{{dict.phone[lang]}}</label>
                    <input type="tel" id="phone" class="form-control" v-model="user.phone" v-validate="'required|numeric|min:11|max:11'" name="phone">
                    <div v-if="submitted && errors.has('phone')" class="alert alert-danger" role="alert" >{{errors.first('phone')}}</div>
                  </div>

                  <div :class="lang==='per'?'form-outline mb-4 text-end':'form-outline mb-4 text-start'">
                    <label class="form-label" for="password" style="margin-left: 0px;">{{dict.password[lang]}}</label>
                    <input type="password" id="password" class="form-control" v-model="user.password" v-validate="'required|min:6|max:40'" name="password" ref="password">
                    <div v-if="submitted && errors.has('password')" class="alert alert-danger" role="alert" >{{errors.first('password')}}</div>
                  </div>

                  <div :class="lang==='per'?'form-outline mb-4 text-end':'form-outline mb-4 text-start'">
                    <label class="form-label" for="password_confirmation" style="margin-left: 0px;">{{dict.passwordRetype[lang]}}</label>
                    <input type="password" id="password_confirmation" class="form-control" v-model="user.passwordRetype" v-validate="'required|confirmed:password'" name="password_confirmation" data-vv-as="password">
                    <div v-if="submitted && errors.has('password_confirmation')" class="alert alert-danger" role="alert" >{{errors.first('password_confirmation')}}</div>
                  </div>

                  <div class="input-group input-group-outline mb-3">
                    <input type="text" id="code" class="form-control w-50" v-model="user.captchaInput" name="code" v-validate="'required'" >
                    <button @click="refreshCaptcha" class="btn btn-light btn-block w-50" :disabled="loading">
                      <img :src="captchaImageUrl" alt="CAPTCHA" />
                    </button>
                    <div v-if="submitted && errors.has('code')" class="alert alert-danger" role="alert" >Code is required!</div>
                  </div>

                  <div class="d-grid gap-2">
                    <button  @click="handleLogin" class="btn btn-primary btn-block mb-4" :disabled="loading">
                      <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                      {{dict.signup[lang]}}
                    </button>
                  </div>

                  <div v-if="message ==='success'" :class="lang==='per'?'text-end alert alert-success':'text-start alert alert-success'">
                    <p class="mb-0">
                      {{dict.success[lang]}}
                    </p>
                  </div>

                  <div v-if="!successful" :class="lang==='per'?'text-end alert alert-danger':'text-start alert alert-danger'">
                    <p v-if="message ==='err1'" class="mb-0">
                      {{dict.errCaptcha[lang]}}
                    </p>
                    <p v-if="message ==='err2'" class="mb-0">
                      {{dict.errUser[lang]}}
                    </p>
                  </div>

                  <hr>
                  
                  <p>
                    {{dict.loginmsg[lang]}}
                    <a href="#" @click="showLoginForm">{{dict.login[lang]}}</a>
                  </p>

                </div>
              </div>
            </div>

            <div class="col-lg-6 h-100" ref="imageContainer">

            </div>
          </div>
        </div>
  </template>
  
  <script>
    import bgImage from '@/assets/images/login-bg-3.jpg';
    export default {
      name: "LoginForm",
      data() {
        return {
          dict:{
            dir:{eng:'ltr',per:'rtl'},
            title: {eng:'User Registeration',per:'ثبت نام کاربر جدید'},
            fname: {eng:'first name',per:'نام'},
            lname: {eng:'last name',per:'نام خانوادگی'},
            email: {eng:'email',per:'ایمیل'},
            phone: {eng:'phone number',per:'تلفن همراه'},
            organization: {eng:'organization',per:'نام سازمان'},
            password: {eng:'password',per:'کلمه عبور'}, 
            passwordRetype: {eng:'Password Confirmation',per:'تایید کلمه عبور'}, 
            
            signup: {eng:'Register',per:'ثبت تام'},
            loginmsg: {eng:"Already have an account?",per:'حساب کاربری دارید؟'},
            login: {eng:'Log in',per:'ورود'},

            errCaptcha:{eng:'invalid captcha', per:'کد امنیتی وارد شده اشتباه می باشد'},
            errUser:{eng:'the entered username was registred', per:'نام کاربری وارد شده پیش تر ثبت شده است'},
            success:{eng:'successful registration', per:'ثبت نام با موفقیت انجام شد'},

          },
          user:{
            fname:'',
            lname:'',
            phone:'',
            email:'',
            password:'',
            passwordRetype:'',
            captchaInput:'',
            sessionId:''
          },
          captchaImageUrl:'',
          successful:true,
          submitted:true,
          loading:false,
          message:'',
        };
      },
      methods: {
        handleLogin() {
          // console.log('submit')
            this.submitted = true;
            this.successful = true
            this.loading = true;
            this.$validator.validateAll().then(isValid => {
                if (!isValid) {
                  this.loading = false;
                  return;
                }
                else{
                  this.$store.dispatch('auth/register', this.user).then(
                    (resp) => {
                      this.message = resp.message
                      this.submitted = false
                      this.loading = false;
                      setTimeout(this.showLoginForm,2000); 
                    },
                    error => {
                      this.loading = false;
                      this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                      
                      if ((this.message === 'err1')||(this.message === 'err2')){
                        this.submitted = false
                        this.successful = false
                        this.user = {
                          fname:'',
                          lname:'',
                          phone:'',
                          email:'',
                          password:'',
                          passwordRetype:'',
                          captchaInput:'',
                          sessionId:''
                        },
                        this.refreshCaptcha()
                      }else{
                        // alert(this.message)
                      }
                    },
                  );


                }

                
            });
        },
        refreshCaptcha() {
          this.$store.dispatch('auth/captcha').then(
              (response) => {
                this.captchaImageUrl = response.data.imageUrl;
                this.user.sessionId = response.data.sessionId;
                
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
          );
        },
        

       
        showLoginForm() {
          this.$store.dispatch('chpge/changePage','signin')
        },

        loadImage() {
          const initialImageUrl = bgImage;  
          this.setImage(initialImageUrl);
        },
        setImage(imageUrl) {
          const imageContainer = this.$refs.imageContainer;
          imageContainer.style.backgroundImage = `url(${imageUrl})`;
          imageContainer.style.backgroundSize = 'cover';
          imageContainer.style.backgroundPosition = 'center';
        },
      },
      mounted() {
        this.loadImage();
      },
      computed: {
        lang() {
          return this.$store.state.chpge.lang;
        }
      },
      created() {
        this.refreshCaptcha();
      },
    };
  </script>
  


<style>
  .cascading-right {
    margin-right: 75%;
    margin-left: -75%;

  }
  @media (max-width: 991.98px) {
    .cascading-right {
      margin-right: 10px;
      margin-left: 10px;

    }
  }

  .cascading-left {
    margin-right: -75%;
    margin-left: 75%;
  }
  @media (max-width: 991.98px) {
    .cascading-left {
      margin-right: 10px;
      margin-left: 10px;

    }
  }




</style>
  

    <!-- @media (max-width: 991.98px) {
    .cascading-right {
      margin-right: 0;
    }
  } -->