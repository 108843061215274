<template>
  <div>
    <div class="row mx-auto">
      <div
        class="col-md-6 col-12"
        :class="
          lang === 'per'
            ? 'form-outline mb-4 text-end'
            : 'form-outline mb-4 text-start'
        "
        v-for="(item, index) in fields"
        :key="index"
      >
        <label class="form-label" for="username" style="margin-left: 0px">{{
            item[lang]
          }}</label>
        <input
          :type="item.type"
          :id="item.name"
          :readonly="isRegister ?false:item.readonly"
          v-validate="item.required"
          class="form-control"
          v-model="item.value"
          @input="getValue($event, item.name,item.param)"
        />
        <div
          v-if="submitted && errorsList.includes(item.name)"
          class="alert alert-danger"
          role="alert"
        >
          {{ item[lang] }} is required!
        </div>
      </div>
    </div>
    <!--    <button @click="registerUser">submit</button>-->
  </div>
</template>

<script>
export default {
  name: "editUserData",
  props: {
    informations: {
      default: {}
    },
    isRegister: {
      default: false
    },
    value: {
      default: false
    }
  },
  data() {
    return {
      errorsList: [],
      fields: [
        {
          readonly: false,
          name: "fname",
          param: "name",
          value: "",
          required: true,
          per: "نام",
          eng: "name",
          type: "text"
        },
        {
          name: "lname",
          param: "familyName",
          readonly: false,
          value: "",
          type: "text",
          required: true,
          per: "نام خانوادگی",
          eng: "familyName"
        },
        {
          name: "password",
          param: "",
          readonly: false,
          value: "",
          type: "password",
          required: true,
          per: "رمز عبور",
          eng: "password"
        },
        {
          name: "passwordRetype",
          param: "",
          readonly: false,
          value: "",
          type: "password",
          required: true,
          per: "تایید رمز عبور",
          eng: "password repeat"
        },
        {
          name: "phone",
          param: "phoneNumber",
          readonly: false,
          value: "",
          type: "number",
          required: true,
          per: "شماره موبایل",
          eng: "phoneNumber"
        },
        {
          name: "email",
          param: "",
          readonly: true,
          value: "",
          type: "text",
          required: true,
          per: "ایمیل",
          eng: "email"
        },
        {
          name: "username",
          param: "",
          readonly: true,
          value: "",
          type: "text",
          required: true,
          per: "نام کاربری",
          eng: "username"
        }
      ],
      submitted: false,
      message: ""
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  methods: {
    getValue(value, name, param) {
      if (param != "")
        this.fields.find((item) => item.name == param).value = value.target.value;
      else
        this.fields.find((item) => item.name == name).value = value.target.value;
    },
    editDetails() {
      this.submitted = true;
      const data = {
        userId: this.informations.id,
        fname:
          this.fields.find((item) => item.name == "fname").value !==
          this.informations["name"]
            ? this.fields.find((item) => item.name == "fname").value
            : undefined,
        lname:
          this.fields.find((item) => item.name == "lname").value !==
          this.informations["familyName"]
            ? this.fields.find((item) => item.name == "lname").value
            : undefined,
        email:
          this.fields.find((item) => item.name == "email").value !==
          this.informations["email"]
            ? this.fields.find((item) => item.name == "email").value
            : undefined,
        phone:
          this.fields.find((item) => item.name == "phone").value !==
          this.informations["phoneNumber"]
            ? this.fields.find((item) => item.name == "phone").value
            : undefined,
        password:
          this.fields.find((item) => item.name == "password").value !==
          this.informations["password"]
            ? this.fields.find((item) => item.name == "password").value
            : undefined,
        passwordRetype:
          this.fields.find((item) => item.name == "passwordRetype").value !== ""
            ? this.fields.find((item) => item.name == "passwordRetype").value
            : undefined
      };

      this.errorsList = [];
      Object.entries(data).forEach((item) => {
        if (item[1] == "" && !this.errorsList.includes(item[1]))
          this.errorsList.push(item[0]);
      });

      if (this.errorsList.length <= 0) {
        this.$store.dispatch("data/editRoleSystem", data).then(
          (response) => {
            this.message = response.message;
            this.$store.dispatch("data/getUsersAdminListdata");
            this.$bvModal.hide("editModal");
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    registerUser() {
      this.submitted = true;
      let data = {};
      this.fields.forEach((item) => {
        data[item.name] = item.value;
      });
      this.errorsList = [];
      Object.entries(data).forEach((val) => {
        if (val[1] == "") this.errorsList.push(val[0]);
      });

      if (this.errorsList.length <= 0) {
        this.$store.dispatch("auth/register", data).then(
          (resp) => {
            console.log(resp);
            this.$store.dispatch("data/getUsersAdminListdata");
            this.$bvModal.hide("registerModal");
          },
          (error) => {
            console.log(error);
          }
        );
      }

    }
  },
  mounted() {
    if (!this.isRegister) {
      Object.entries(this.informations).forEach((item) => {
        this.fields.forEach((value) => {
          if (value.param == item[0])
            value.value = item[1];
          else if (value.name == item[0]) value.value = item[1];
        });
      });
    }
  }
};
</script>
