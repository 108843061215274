<template>
  <div class="w-full">
    <div class="w-full row mt-5 px-3">
      <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
        <li
          v-for="(item, index) in tabsField"
          class="mga-tab-nav-item"
          @click="getActiveTab(item.value)"
          :key="index"
        >
          <a
            class="mga-tab-nav-link"
            :class="{ active: item.value == activeTab }"
            :id="item.name"
            data-toggle="tab"
            role="tab"
            aria-controls="tab1"
            aria-selected="true"
          >
            {{ item[lang] }}
          </a>
        </li>
      </ul>

      <div class="row mx-auto mt-4">
        <div v-for="(item,index) in new Set(items[activeTab])" class="mx-1 col-md-2 h-[40%]" :key="index">
          <drag-able :img="item" :key="item.name" v-model="selectedElement" />
        </div>
      </div>
      <element-creator v-model="newImg" v-if="activeTab =='gallery' " />

<!--      <b-tabs card :dir="lang == 'per' ? 'rtl' : 'ltr'">-->
<!--        <b-tab v-for="(item,index) in tabsField" :title="item[lang]" :active="item.value == activeTab"-->
<!--               @click="getActiveTab(item.value)" :key="index">-->
<!--          <div class="row mx-auto">-->
<!--            <div v-for="(item,index) in new Set(items[activeTab])" class="mx-1 col-md-2 h-[40%]" :key="index">-->
<!--              <drag-able :img="item" :key="item.name" v-model="selectedElement" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-tab>-->
<!--      </b-tabs>-->

    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters, mapState } from "vuex";
import DragAble from "@/components/processFlowComponents/dragDrop/dragAble.vue";
import ElementCreator from "@/components/processFlowComponents/elementCreator.vue";

export default {
  components: { ElementCreator, DragAble },
  data() {
    return {
      selectedElement: {},
      newImg:{},
      items: {
        defaultTab: [
          {
            element: "conicTank",
            name: "conic tank",
            img: require("@/assets/svgIcons/conic.svg"),
            position: { x: 627, y: 201 }
          },
          {
            element: "liquidTank",
            name: "liquid tank",
            img: require("@/assets/svgIcons/liquid.svg"),
            position: { x: 170, y: 20 }
          },
          {
            element: "panel",
            name: "panel",
            img: require("@/assets/svgIcons/panel.svg"),
            position: { x: 240, y: 20 }
          },
          {
            element: "Zone",
            name: "Zone",
            img: require("@/assets/svgIcons/zone.svg"),
            position: { x: 310, y: 20 }
          },
          {
            element: "ZoneReverse",
            name: "reverse Zone",
            img: require("@/assets/svgIcons/zone.svg"),
            position: { x: 310, y: 20 }
          },
          {
            element: "Join",
            name: "join",
            img: require("@/assets/svgIcons/join.svg"),
            position: { x: 380, y: 20 }
          },
          {
            element: "ControlValve",
            name: "controlValve",
            img: require("@/assets/svgIcons/ctrVlv.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "handValve",
            name: "handValve",
            img: require("@/assets/svgIcons/handVlv.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "pump",
            name: "pump",
            img: require("@/assets/svgIcons/pump.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "FlowMeter",
            name: "FlowMeter",
            img: require("@/assets/svgIcons/flometr.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "ArtBoard",
            name: "ArtBoard",
            img: require("@/assets/svgIcons/Artboard 1.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "Pomps",
            name: "Pomps",
            img: require("@/assets/svgIcons/pomp.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "heightMeter",
            name: "heightMeter",
            img: require("@/assets/svgIcons/mile.svg"),
            position: { x: 450, y: 20 }
          },
          {
            element: "Pool",
            name: "Pool",
            img: require("@/assets/svgIcons/estakhr.svg"),
            position: { x: 450, y: 20 }
          },
        ],
        shapes: [
          {
            element: "Circle",
            name: "circle",
            img: require("@/assets/svgIcons/circle.svg"),
            position: { x: 450, y: 20 },
            size: { width: 100, height: 100 }
          },
          {
            element: "Cylinder",
            name: "Cylinder",
            img: require("@/assets/svgIcons/cylinder.svg"),
            position: { x: 450, y: 20 },
            size: { width: 100, height: 200 }
          },
          {
            element: "Rectangle",
            name: "Rectangle",
            img: require("@/assets/svgIcons/rectangle.svg"),
            position: { x: 450, y: 20 },
            size: { width: 150, height: 50 }
          },
          {
            element: "Ellipse",
            name: "Ellipse",
            img: require("@/assets/svgIcons/ellipse.svg"),
            position: { x: 450, y: 20 },
            size: { width: 150, height: 100 }
          },
          {
            element: "Polygon",
            name: "square",
            img: require("@/assets/svgIcons/square.svg"),
            position: { x: 450, y: 20 },
            size: { width: 100, height: 100 }
          }
          // {
          //   element: 'TextBlock',
          //   name: 'TextBlock',
          //   img: require('@/assets/svgIcons/square.svg'),
          //   position: {x: 450, y: 20},
          //   size:{width: 100, height: 100}
          // },
        ],
        gallery: []
      },
      tabsField: [
        { value: "defaultTab", per: "اشکال پیش فرض", eng: "default shape" },
        { value: "shapes", per: "اشکال هندسی", eng: "geometric shapes" },
        // { value: "gallery", per: "تصاویر بارگذاری شده", eng: "upload shape" }
      ],
      activeTab: "defaultTab",
      draggingItem: null
    };
  },
  computed: {
    ...mapGetters["galleryRender"],
    ...mapState(["imgElement"]),
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  methods: {
    createNow() {
      store.commit("selectElement", {
        name: this.selectedElement.element,
        val: {
          position: this.selectedElement.position
        },
        img: this.selectedElement.img,
        size: this.selectedElement.size ? this.selectedElement.size : undefined
      });
    },
    getActiveTab(val) {
      this.activeTab = val;
    }
  },
  watch: {
    selectedElement: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.createNow();
        }
      },
      deep: true
    },
    newImg: {
      handler(val) {
        console.log(val);
        this.items.gallery.push(val);
      }, deep: true
    }
  }
};
</script>

<style>
.droppable-area {
  margin-top: 20px;
  padding: 20px;
  background-color: #bee3f8;
  border: 2px dashed #3182ce;
  text-align: center;
}
</style>
