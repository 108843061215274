<template>
  <div :dir="lang=='per' ? 'rtl':'ltr' ">
    <div
      v-if="sysData.type === 'Cloud' ||sysData.type === 'httpServer' "
      :ref="sysData.id"
      :id="sysData.id"
      class="Cloud"
      :type="sysData.type"
    >
      <div style="margin-top: 50px">
        <b-button
          @click="handleButton('edit', '')"
          size="sm"
          variant="link"
        >
          <i>
            <font-awesome-icon icon="fas fa-pencil" />
          </i>
        </b-button>

        <b-button @click="handleButton('delete', '')" size="sm" variant="link">
          <i>
            <font-awesome-icon icon="fas fa-xmark" />
          </i>
        </b-button>
      </div>

      <p>{{ title }}</p>
    </div>

    <div
      :dir="lang=='per' ? 'rtl' :'ltr' "
      v-else
      :ref="sysData.id"
      :id="sysData.id"
      :class="sysData.type"
      :type="sysData.type"
    >
      <div style="display: flex;">
        <b-dropdown
          v-if="sysData.type === 'Server'"
          size="sm"
          :dir="lang=='per' ? 'rtl' :'ltr' "
          variant="link"
          no-caret
          :right="lang=='per'"
          :left="lang=='eng'"
        >
          <template #button-content>
            <i v-permission="'hardware_add_server'">
              <font-awesome-icon icon="fas fa-plus" />
            </i>
          </template>
          <!--          <b-dropdown-item v-permission="'createSlot'" href="#" @click="handleButton('add', 'extension')"-->
          <!--          >Extension-->
          <!--          </b-dropdown-item-->
          <!--          >-->
          <b-dropdown-item
            :class="{'text-start':lang=='eng','text-end':lang=='per'}"
            @click="handleButton('add', '')"
          >MQTT client
          </b-dropdown-item>
          <!--          <b-dropdown-item v-permission="'createCloud'" @click="handleButton('add', 'cloud')"-->
          <!--          >Cloud Connection-->
          <!--          </b-dropdown-item-->
          <!--          >-->
          <b-dropdown-item @click="handleButton('add', 'https')"
                           :class="{'text-start':lang=='eng','text-end':lang=='per'}"
          >https Connection
          </b-dropdown-item>

          <b-dropdown-item @click="handleButton('add', 'httpServer')"
                           :class="{'text-start':lang=='eng','text-end':lang=='per'}"
          >https server Connection
          </b-dropdown-item
          >

          <!--          <b-dropdown-item href="#" disabled>Modbus/TCP slave</b-dropdown-item>-->
        </b-dropdown>

        <b-dropdown
          v-else-if="sysData.type === 'Master' && sysData.mapType === 'MTR22'"
          size="sm"
          variant="link"
          no-caret
        >
          <template #button-content>
            <i>
              <font-awesome-icon icon="fas fa-plus" />
            </i>
          </template>
          <b-dropdown-item href="#" @click="handleButton('add', 'extension')"
          >Extension
          </b-dropdown-item
          >
          <b-dropdown-item href="#" @click="handleButton('add', '')"
          >Modbus/RTU slave
          </b-dropdown-item
          >
        </b-dropdown>

        <b-button
          v-else-if="sysData.type === 'slave_E'"
          @click="handleButton('add', '')"
          size="sm"
          variant="outline-success"
          class="mr-2"
          style="
            width: 20px;
            margin-inline: 2px;
            padding: 0px;
            flex: 1%;
            outline: none !important;
            border: none;
            box-shadow: none;
          "
        >
          <i>
            <font-awesome-icon icon="fas fa-plus" />
          </i>
        </b-button>

        <b-button v-permission="'hardware_edit_server'" @click="handleButton('edit', '')" size="sm" variant="link">
          <i>
            <font-awesome-icon icon="fas fa-pencil" />
          </i>
        </b-button>

        <b-button
          v-permission="'hardware_remove_server'"
          v-if="sysData.type !== 'Server'"
          @click="handleButton('delete', '')"
          size="sm"
          variant="link"
        >
          <i>
            <font-awesome-icon icon="fas fa-xmark" />
          </i>
        </b-button>
      </div>

      <hr style="margin-top: 5px; margin-bottom: 5px" />

      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
import { DialogUtility } from "@syncfusion/ej2-popups";
export default {
  name: "controllerSettings",
  props: {
    // sysData: Object,
    database: Object,
    index: [Number, Array]
  },
  data() {
    return {
      sysData: "",
      title: "",
      pop1: false,
      message: "",
      successful: false,
      dict: {
        removeConfirm: {
          per: "لطفا برای حذف این رکورد روی تایید کلیک کنید.",
          eng: "Please confirm that you want to delete it"
        },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
      }
    };
  },
  mounted() {
    this.sysData = this.database;
    this.title = this.sysData.name ? this.sysData.name : this.sysData.topic;
  },
  methods: {
    handleButton: function(action, type, event) {
      if (action == "add") {
        if (this.sysData.type === "Server") {
          var page_ = "master";
          if (type === "extension") page_ = "slot";
          else if (type === "cloud") page_ = "cloud";
          else if (type === "https") page_ = "https";
          else if (type === "httpServer") page_ = "httpServer";

          this.$store.dispatch("chpge/openModal", {
            page: page_,
            type: "Add",
            index: null,
            db: { ptype: "Server", pid: this.database.id }
          });
        } else if (this.sysData.type === "Master") {
          this.$store.dispatch("chpge/openModal", {
            page: type == "extension" ? "slot" : "slave",
            type: "Add",
            index: null,
            db: { ptype: "Master", pid: this.database.id }
          });
        } else if (
          this.sysData.type === "slave_E" ||
          this.sysData.type === "slave_S" ||
          this.sysData.type === "slave_SC"
        ) {
          this.$store.dispatch("chpge/openModal", {
            page: "slot",
            type: "Add",
            index: null,
            db: { ptype: "Slave", pid: this.database.id }
          });
        }
      } else if (action == "edit") {
        if (this.sysData.type === "Https") {
          this.$store.dispatch("chpge/openModal", {
            page: "https",
            type: "Edit",
            index: null,
            db: this.database
          });
        }
        if (this.sysData.type === "Cloud") {
          this.$store.dispatch("chpge/openModal", {
            page: "cloud",
            type: "Edit",
            index: null,
            db: this.database
          });
        }
        if (this.sysData.type === "Master") {
          this.$store.dispatch("chpge/openModal", {
            page: "master",
            type: "Edit",
            index: null,
            db: this.database
          });
        }
        if (this.sysData.type === "httpServer") {
          this.$store.dispatch("chpge/openModal", {
            page: "httpServer",
            type: "Edit",
            index: null,
            db: this.database
          });
        } else if (
          this.sysData.type === "slave_E" ||
          this.sysData.type === "slave_S" ||
          this.sysData.type === "slave_SC"
        ) {
          this.$store.dispatch("chpge/openModal", {
            page: "slave",
            type: "Edit",
            index: null,
            db: this.database
          });
        } else if (this.sysData.type.includes("slot")) {
          this.$store.dispatch("chpge/openModal", {
            page: "slot",
            type: "Edit",
            index: null,
            db: this.database
          });
        }
      } else if (action == "delete") {
        if (this.sysData.type === "Master") {

          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeMaster", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }

        } else if (this.sysData.type === "Cloud") {


          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeCloud", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }





        } else if (this.sysData.type === "Https") {


          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeHttps", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }







        } else if (this.sysData.type === "httpServer") {

          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeHttpserver", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }




        } else if (
          this.sysData.type === "slave_E" ||
          this.sysData.type === "slave_S" ||
          this.sysData.type === "slave_SC"
        ) {


          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeSlave", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }




        } else if (this.sysData.type.includes("slot")) {
          const DialogObj = DialogUtility.confirm({
            title: this.dict.deleteConfirm[this.lang],
            locale: this.lang,
            position: "center center",
            Enabletrl: this.lang == "per" ? true : false,
            content: this.dict.deleteConfirmContent[this.lang],
            okButton: {
              text: this.dict.ok[this.lang], click: () => {
                this.$store
                  .dispatch("data/removeSlot", { id: this.sysData.id })
                  .then(
                    (data) => {
                      this.message = data.message;
                      this.successful = true;
                      this.getContConf();
                    },
                    (error) => {
                      this.message =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                      this.successful = false;
                      // alert(this.message);
                    }
                  );
                DialogObj.hide();
              }
            },
            cancelButton: { text: this.dict.cancel[this.lang] },
            showCloseIcon: false,
            closeOnEscape: false,
            animationSettings: { effect: "Zoom" }
          });
          if (this.lang == "per") {
            DialogObj.element.classList.add("e-control-rtl");
          } else {
            DialogObj.element.classList.remove("e-control-rtl");
            DialogObj.element.classList.add("e-control-ltr");
          }

        }
      }
    },
    getContConf() {
      this.$store.dispatch("data/getContConf").then(
        (data) => {
          // console.log("HW1: ", JSON.stringify(data));
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<style scoped>
.Https {
  position: relative;
  height: 200px;
  width: 100px;
  border: 2px solid hsla(200, 100%, 20%, 0.3);
  background-color: hsla(200, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}

.Https:hover {
  background-color: hsla(200, 100%, 60%, 0.3);
  cursor: pointer;
}

.Cloud {
  position: relative;
  height: 200px;
  width: 225px;
  background-image: url("../assets/images/cloud.svg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.Cloud:hover {
  background-image: url("../assets/images/cloud_hover.svg");
  cursor: pointer;
}

.Server {
  position: relative;
  height: 200px;
  width: 100px;
  border: 2px solid hsla(200, 100%, 20%, 0.3);
  background-color: hsla(200, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.Server:hover {
  background-color: hsla(200, 100%, 60%, 0.3);
  cursor: pointer;
}

.Master {
  position: relative;
  height: 200px;
  width: 100px;
  border: 2px solid hsla(0, 100%, 20%, 0.3);
  background-color: hsla(0, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.Master:hover {
  background-color: hsla(0, 100%, 60%, 0.3);
  cursor: pointer;
}

.slave_E {
  position: relative;
  height: 200px;
  width: 100px;
  border: 2px solid hsla(276, 100%, 20%, 0.3);
  background-color: hsla(276, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.slave_E:hover {
  background-color: hsla(276, 100%, 60%, 0.3);
  cursor: pointer;
}

.slave_SC,
.slave_S {
  position: relative;
  height: 100px;
  width: 200px;
  border: 2px solid hsla(276, 100%, 20%, 0.3);
  background-color: hsla(276, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.slave_SC:hover,
.slave_S:hover {
  background-color: hsla(276, 100%, 60%, 0.3);
  cursor: pointer;
}

.slot_DI8,
.slot_DI16,
.slot_DO8,
.slot_DO16,
.slot_DO4pb,
.slot_DO8pb,
.slot_DO16pb,
.slot_DI4DO4,
.slot_DI8DO8,
.slot_AI6,
.slot_AO6,
.slot_RTD4 {
  position: relative;
  height: 200px;
  width: 100px;
  border: 2px solid hsla(166, 100%, 20%, 0.3);
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.slot_DI8:hover,
.slot_DI16:hover,
.slot_DO8:hover,
.slot_DO16:hover,
.slot_DO4pb:hover,
.slot_DO8pb:hover,
.slot_DO16pb:hover,
.slot_DI4DO4:hover,
.slot_DI8DO8:hover,
.slot_AI6:hover,
.slot_AO6:hover,
.slot_RTD4:hover {
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}
</style>
