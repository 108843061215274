export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  let activeWorkbench = JSON.parse(localStorage.getItem('activeWorkbench'));
 
  if (user && user.accessToken) {
    return { 'x-access-token': user.accessToken, 'active-workbench': activeWorkbench? activeWorkbench.id:'',};       // for Node.js Express back-end
  } else {
    return {};
  }
}
