import axios from "axios";
import authHeader from "./auth-header";
import { host } from "../models/host";
import store from "@/store";

const API_URL = host + "/api/auth/";

class AuthService {

  login(user) {
    return axios.post(
      API_URL + "signin",
      user,
      {
        withCredentials: true // Set the withCredentials option here
      }
    );
  }

  extendLogin() {
    return axios.post(API_URL + "extendsignin",
      "extend",
      {
        headers: authHeader()
      }
    );
  }

  // createMaster(data) {
  //   return axios.post(API_URL + 'cont/master/create', 
  //   data,
  //   {
  //     headers: authHeader(),
  //   });
  // }
  captcha() {
    return axios.get(
      API_URL + "captcha",
      {
        withCredentials: true // Set the withCredentials option here
      }
    );
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("activeWorkbench");

  }

  register(user) {
    return axios.post(API_URL + "signup",
      user,
      {
        withCredentials: true // Set the withCredentials option here
      }
    );
  }

  edit(user) {
    return axios.post(API_URL + "edit", {
        username: user.username,
        email: user.email,
        password: user.password,
        roles: [user.role],
        floors: user.floor
      },
      {
        headers: authHeader()
      });
  }

  remove(user) {
    return axios.post(API_URL + "remove", {
        username: user.username,
        email: user.email,
        password: user.password,
        roles: [user.role]
      },
      {
        headers: authHeader()
      });
  }

  getAllUsers() {
    return axios.get(API_URL + "users", {
      headers: authHeader()
    });
  }

  editPanel(user) {
    return axios.post(API_URL + "editPanel", {
        username: user.username,
        selectedView: user.selectedView,
        selectedFloor: user.selectedFloor
      },
      {
        headers: authHeader()
      });
  }
}

export default new AuthService();
