<template>
  <div :dir="dict.dir[lang]" style="height:100%; width:100%;">
    <div v-if="dataReady" class="card-header" style="min-height: 40px;">
      <div class="row">
        <div  class="col-6" :class="lang === 'per' ? 'text-end' : 'text-start'">  
          <p class="mb-0">
            {{ myData.attribute.title}}
          </p>
        </div>
        <!-- <div class="col-8 "></div> -->
        <div class="col-6 ">
          <div class="d-flex w-100 justify-content-end">
            <div class="d-flex" style="gap:5px; width:50px;">
              <span v-if="!myData.status.enable" class="remove w-100 text-center" @click="changeTab" >
                <i v-if="activeTab==='tab1'" style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-gear" /></i>
                <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i> 
              </span>
            </div>
            <div class="form-check form-switch d-flex justify-content-center" style="width:50px">
              <input class="form-check-input" type="checkbox" value="" id="CBEn" v-model="myData.status.enable" @change="onChangeStatus()" :disabled="activeTab==='tab2'">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataReady"  class="card-body p-1 d-flex justify-content-center align-items-center"  style="height: calc(100% - 40px); width:100%; overflow: hidden; "> 

            
      <transition :name="dict.transition[lang]" mode="out-in">
        <div class="tab-content h-100 w-100" :key="activeTab">
          <div v-if="activeTab === 'tab1'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <div class="h-100 w-100 p-0" style="display:flex; flex-direction:column; ">
                <div style="min-height:100px;">
                  <div class="radiobox" style="height:70%">
                    <label class="radiobtn" v-for="opt in controlOptions" :key="opt.value" >
                      <input  class="radioinp" type="radio" v-model="myData.parameters.controlMode" :value="opt.value" :name="plotId" @change="changeControlMode"  />
                      <span v-if="opt.text==='Manual'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-hand-paper"/>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">{{dict.controlMode1[lang]}}</p>
                        </div>
                      </span>
                      <span v-if="opt.text==='Schedule'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <div style="display: flex;justify-content: center;">
                            <font-awesome-icon icon="fas fa-calendar-days"/>
                          </div>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">{{dict.controlMode2[lang]}}</p>
                        </div>
                      </span>

                    </label>
                  </div>
                  <div  class="radiobox" style="height:30%">
                    <i v-if="myData.parameters.controlMode ==='schedule'" ><font-awesome-icon icon="fas fa-lock" /></i>
                    <i v-else ><font-awesome-icon icon="fas fa-lock-open" /></i>
                    <div v-if="myData.parameters.controlMode==='schedule'">
                      <div v-if="pannel.scheduleTitle!=='  '">
                        {{pannel.scheduleTitle}}
                      </div>
                      <div v-else >
                        <font-awesome-icon icon="fas fa-calendar-days" fade style="color:red"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div  :style="{minHeight:pannel.sliderHeight+'px', height:'calc(100% - 100px)', borderRadius:'5px'}">
                  <PerfectScrollbar >
                    
                    <div v-if="myData.parameters.controlMode==='schedule' && !pannel.settingVis"  style="height:100%; display:flex; justify-content:center; align-items:center">
                      no active event
                    </div>
                    
                    <div v-else-if="myData.parameters.controlMode==='manual'"  style="height:100%; width:100%; padding:10px;">
                      <div  style="width:100%; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">  
                        <div class="row row-cols-1 row-cols-md-2 justify-content-between">
                          <div class="col col-md-6">
                            <div>
                              <label class="mb-1" for="mySelect">
                                {{dict.selSetting[lang]}}   
                              </label>
                              <select id="mySelect" v-model="pannel.selectedSetting" class="form-select" aria-label="Default select example" >
                                <option v-for="(option, index) in pannel.settingsOptions" :key="index" :value="option.value">{{ option.text }}</option>
                              </select>
                              
                            </div>
                          </div>
                          <div class="col col-md-6">
                            <label class="form-check-label" for="inputTypeWrapper">
                              {{dict.inputType[lang]}}   
                            </label>
                            <div id="inputTypeWrapper">
                              <div  class="form-check" :class="dict.dir[lang]">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  {{dict.inputType1[lang]}}   
                                </label>
                                <input class="form-check-input" :class="dict.dir[lang]" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="pannel.manual.intParam" value='true'>
                                
                              </div>
                              <div class="form-check" :class="dict.dir[lang]">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  {{dict.inputType2[lang]}} 
                                </label>
                                <input class="form-check-input" :class="dict.dir[lang]" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="pannel.manual.intParam" value='false'>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                        <div v-if="pannel.manual.intParam==='true'" class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_1[lang]"
                              label-for="inp_1"                                            
                              :invalid-feedback="errors.first('inp_1')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_1"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_1"
                                :state="(submitted)? (!errors.has('inp_1')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_2[lang]"
                              label-for="inp_2"                                            
                              :invalid-feedback="errors.first('inp_2')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_2"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_2"
                                :state="(submitted)? (!errors.has('inp_2')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_3[lang]"
                              label-for="inp_3"                                            
                              :invalid-feedback="errors.first('inp_3')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_3"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_3"
                                :state="(submitted)? (!errors.has('inp_3')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_4[lang]"
                              label-for="inp_4"                                            
                              :invalid-feedback="errors.first('inp_4')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_4"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_4"
                                :state="(submitted)? (!errors.has('inp_4')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                            
                          </div>
                        </div>
                        <div v-if="pannel.manual.intParam==='true'" class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_5[lang]"
                              label-for="inp_5"                                            
                              :invalid-feedback="errors.first('inp_5')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_5"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_5"
                                :state="(submitted)? (!errors.has('inp_5')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_6[lang]"
                              label-for="inp_6"                                            
                              :invalid-feedback="errors.first('inp_6')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_6"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_6"
                                :state="(submitted)? (!errors.has('inp_6')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_7[lang]"
                              label-for="inp_7"                                            
                              :invalid-feedback="errors.first('inp_7')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_7"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_7"
                                :state="(submitted)? (!errors.has('inp_7')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_8[lang]"
                              label-for="inp_8"                                            
                              :invalid-feedback="errors.first('inp_8')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_8"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_8"
                                :state="(submitted)? (!errors.has('inp_8')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 

                          </div>
                        </div>
                        <div v-if="pannel.manual.intParam==='true'" class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                          <div class="col">
                            <b-form-group
                              label-size="sm"
                              :label="dict.contDataName_9[lang]"
                              label-for="inp_9"                                            
                              :invalid-feedback="errors.first('inp_9')"
                              style="width:100%;"
                            >
                              <b-form-input
                                size="sm"
                                name="inp_9"
                                type="text"
                                :placeholder="dict.contDataPH[lang]"
                                v-model="pannel.manual.inputs.inp_9"
                                :state="(submitted)? (!errors.has('inp_9')): null"  
                                v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                style="width:100%"
                              ></b-form-input>
                            </b-form-group> 
                          </div>
                        </div>

                        <div class="row justify-content-end">
                          <div class="col col-md-6 col-lg-4 col-xl-3">
                            <button type="button" class="btn btn-primary btn-lg w-100 h-100" @click="saveData('manPanel')">{{dict.startBtn[lang]}}</button>
                          </div>
                        </div>
                      </div>
                      <div  style="margin-top:20px; width:100%; padding:10px; borderRadius:5px; display:flex; flex-wrap:wrap; gap:10px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">  
                        <div v-for="(item, i) in pannel.status.results" :key="i" class="widget"  @click="openResultModal(i)" >
                          <div class="w-100" style="padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">
                            <div class="text-center fw-bold">{{i+1}}</div>
                          </div>

                          <div class="w-100 mt-2">
                            <div style="font-size:12px"  class="w-100 text-center">Average of %Met %</div>
                            <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{ item.param_10.toFixed(2) }}</div>
                          </div>

                          <div class="w-100 mt-2">
                            <div style="font-size:12px"  class="w-100 text-center">Average of C %</div>
                            <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{ item.param_11.toFixed(2) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PerfectScrollbar >
                </div>
              </div>


            </div>
          </div>
          <div v-if="activeTab === 'tab2'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <div class="mga-tab h-100 p-0">
                <div class="mga-tab-header">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                    <li class="mga-tab-nav-item" @click="activeTabConf = 'tab1'">
                      <p  class="mga-tab-nav-link"
                        :class="{ active: activeTabConf === 'tab1' }"   
                        id="tab1-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                        >
                        {{dict.confTab1[lang]}}
                      </p>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabConf = 'tab2'">
                      <a  class="mga-tab-nav-link"
                        :class="{ active: activeTabConf === 'tab2' }"
                        id="tab2-tab"
                        data-toggle="tab"
                        href="#tab2"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="true"
                        >
                        {{dict.confTab2[lang]}}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="mga-tab-body">
                  <transition :name="dict.transition[lang]" mode="out-in">
                    <div class="tab-content h-100" :key="activeTabConf">
                      <div v-if="activeTabConf === 'tab1'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-table id="tableSchedule" :items="myData.parameters.schedules" :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small   ref="scheduleTable"  @row-clicked="onScheduleSelected"> 
                                  <template #cell(modify)="row" >
                                    <div style="display:flex; justify-content:flex-end;">
                                      <b-button pill size="sm" variant="outline-success" @click="editSchedule(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                      </b-button>
                                      <b-button pill size="sm" variant="outline-danger" @click="removeSchedule(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                      </b-button>
                                    </div>
                                  </template>
                                    <template #cell(isSelected)="row">
                                      <input
                                        style="pointer-events: none"
                                        type="checkbox"
                                        :checked="row.item._id === myData.parameters.selectedSchedule"
                                      >
<!--                                      <template v-if="row.item._id===myData.parameters.selectedSchedule">-->
<!--                                        <span aria-hidden="true">&check;</span>-->
<!--                                        <span class="sr-only"></span>-->
<!--                                      </template>-->
<!--                                      <template v-else>-->
<!--                                        <span aria-hidden="true">&nbsp;</span>-->
<!--                                        <span class="sr-only"></span>-->
<!--                                      </template>-->
                                    </template>
                                </b-table>
                                <div style="display:flex; justify-content: right; align-items: center;">
                                  <b-button   @click="addSchedule" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                      <i><font-awesome-icon icon="fas fa-plus" /></i>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-if="activeTabConf === 'tab2'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-table :items="myData.parameters.settings" :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small   ref="controllerTable"> 
                                  <template #cell(modify)="row" >
                                      <div style="display:flex; justify-content:flex-end;">
                                          <b-button pill size="sm" variant="outline-success" @click="editController(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                              <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                          </b-button>
                                          <b-button pill size="sm" variant="outline-danger" @click="removeController(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                          </b-button>
                                      </div>
                                  </template>
                                    <!-- <template #cell(isSelected)="row">
                                      <template v-if="JSON.stringify(row.item._id)===JSON.stringify(myData.parameters.selectedController)">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only"></span>
                                      </template>
                                      <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only"></span>
                                      </template>
                                    </template> -->
                                </b-table>
                                <div style="display:flex; justify-content: right; align-items: center;">
                                  <b-button   @click="addController" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                      <i><font-awesome-icon icon="fas fa-plus" /></i>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
          
    <b-modal
      :id="plotId+'_control'"
      size="lg"
      body-class="WCSCM-body-class"
      centered

      content-class="shadow"

      @shown="initControlModal"
      @hidden="handleControlModalReset"
      @ok="handleControlModalOk"
      >
      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <div><h5>{{dict.contModalTitle[lang]}}</h5></div>
        </div>
      </template>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="handleControlModalReset">{{
              dict.cancel[lang]
            }}</b-button>
            <b-button variant="primary" @click="handleControlModalOk">{{
              dict.ok[lang]
            }}</b-button>
          </div>
        </div>
      </template>
      <div v-show="cdb.show">
        <div :dir="dict.dir[lang]" class="panel_body">
          <div style="padding:10px">
            <b-form-group
              :label="dict.contTitle[lang]"
              label-for="title"                                            
              :invalid-feedback="errors.first('title')"
              style="width:100%; margin-bottom:10px"
            >
              <b-form-input
                name="title"
                type="text"
                :placeholder="dict.contTitlePH[lang]"
                v-model="cdb.title"
                :state="(submitted)? (!errors.has('title')): null"  
                v-validate="'required|min:2|max:20'"
                style="width:100%"
              ></b-form-input>
            </b-form-group>



            <!-- <div style="width:100%; padding: 0px 0px 10px 0px;">
              <input type="text" tabindex="1" id='txtin' />
            </div> -->
    

          </div>
          <div class="p-0">
            <div class="mga-tab h-100 p-0">
              <div class="mga-tab-header">
                <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                 
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab1'">
                    <p  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab1' }"
                      id="tab1-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                      >
                      {{dict.tab1[lang]}}
                    </p>
                  </li>
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab2'">
                    <a  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab2' }"
                      id="tab2-tab"
                      data-toggle="tab"
                      href="#tab2"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="true"
                      >
                      {{dict.tab2[lang]}}
                    </a>
                  </li>
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab3'">
                    <a  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab3' }"
                      id="tab2-tab"
                      data-toggle="tab"
                      href="#tab3"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="true"
                      >
                      {{dict.tab3[lang]}}
                    </a>
                  </li>
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab4'">
                    <a  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab4' }"
                      id="tab4-tab"
                      data-toggle="tab"
                      href="#tab4"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="true"
                      >
                      {{dict.tab4[lang]}}
                    </a>
                  </li>
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab5'">
                    <a  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab5' }"
                      id="tab5-tab"
                      data-toggle="tab"
                      href="#tab5"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="true"
                      >
                      {{dict.tab5[lang]}}
                    </a>
                  </li>
                  <li class="mga-tab-nav-item" @click="activeTabCM = 'tab6'">
                    <a  class="mga-tab-nav-link"
                      :class="{ active: activeTabCM === 'tab6' }"
                      id="tab6-tab"
                      data-toggle="tab"
                      href="#tab6"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="true"
                      >
                      {{dict.tab6[lang]}}
                    </a>
                  </li>
                </ul>
                </div>
                <div  class="mga-tab-body">
                  <transition :name="dict.transition[lang]" mode="out-in">
                    <div class="tab-content h-100" :key="activeTabCM">
                      <div v-show="activeTabCM === 'tab1'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_1[lang]"
                                    label-for="iqr_1"                                            
                                    :invalid-feedback="errors.first('iqr_1')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_1"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_1"
                                      :state="(submitted)? (!errors.has('iqr_1')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_2[lang]"
                                    label-for="iqr_2"                                            
                                    :invalid-feedback="errors.first('iqr_2')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_2"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_2"
                                      :state="(submitted)? (!errors.has('iqr_2')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_3[lang]"
                                    label-for="iqr_3"                                            
                                    :invalid-feedback="errors.first('iqr_3')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_3"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_3"
                                      :state="(submitted)? (!errors.has('iqr_3')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_4[lang]"
                                    label-for="iqr_4"                                            
                                    :invalid-feedback="errors.first('iqr_4')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_4"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_4"
                                      :state="(submitted)? (!errors.has('iqr_4')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_5[lang]"
                                    label-for="iqr_5"                                            
                                    :invalid-feedback="errors.first('iqr_5')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_5"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_5"
                                      :state="(submitted)? (!errors.has('iqr_5')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_6[lang]"
                                    label-for="iqr_6"                                            
                                    :invalid-feedback="errors.first('iqr_6')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_6"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_6"
                                      :state="(submitted)? (!errors.has('iqr_6')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_7[lang]"
                                    label-for="iqr_7"                                            
                                    :invalid-feedback="errors.first('iqr_7')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_7"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_7"
                                      :state="(submitted)? (!errors.has('iqr_7')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_8[lang]"
                                    label-for="iqr_8"                                            
                                    :invalid-feedback="errors.first('iqr_8')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_8"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_8"
                                      :state="(submitted)? (!errors.has('iqr_8')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_9[lang]"
                                    label-for="iqr_9"                                            
                                    :invalid-feedback="errors.first('iqr_9')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="iqr_9"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.IQR.iqr_9"
                                      :state="(submitted)? (!errors.has('iqr_9')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab2'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_1[lang]"
                                    label-for="min_1"                                            
                                    :invalid-feedback="errors.first('min_1')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_1"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_1"
                                      :state="(submitted)? (!errors.has('min_1')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_2[lang]"
                                    label-for="min_2"                                            
                                    :invalid-feedback="errors.first('min_2')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_2"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_2"
                                      :state="(submitted)? (!errors.has('min_2')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_3[lang]"
                                    label-for="min_3"                                            
                                    :invalid-feedback="errors.first('min_3')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_3"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_3"
                                      :state="(submitted)? (!errors.has('min_3')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_4[lang]"
                                    label-for="min_4"                                            
                                    :invalid-feedback="errors.first('min_4')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_4"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_4"
                                      :state="(submitted)? (!errors.has('min_4')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_5[lang]"
                                    label-for="min_5"                                            
                                    :invalid-feedback="errors.first('min_5')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_5"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_5"
                                      :state="(submitted)? (!errors.has('min_5')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_6[lang]"
                                    label-for="min_6"                                            
                                    :invalid-feedback="errors.first('min_6')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_6"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_6"
                                      :state="(submitted)? (!errors.has('min_6')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_7[lang]"
                                    label-for="min_7"                                            
                                    :invalid-feedback="errors.first('min_7')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_7"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_7"
                                      :state="(submitted)? (!errors.has('min_7')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_8[lang]"
                                    label-for="min_8"                                            
                                    :invalid-feedback="errors.first('min_8')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_8"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_8"
                                      :state="(submitted)? (!errors.has('min_8')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_9[lang]"
                                    label-for="min_9"                                            
                                    :invalid-feedback="errors.first('min_9')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="min_9"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MIN.min_9"
                                      :state="(submitted)? (!errors.has('min_9')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                              </div>
                           
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab3'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 

                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_1[lang]"
                                    label-for="max_1"                                            
                                    :invalid-feedback="errors.first('max_1')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_1"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_1"
                                      :state="(submitted)? (!errors.has('max_1')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_2[lang]"
                                    label-for="max_2"                                            
                                    :invalid-feedback="errors.first('max_2')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_2"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_2"
                                      :state="(submitted)? (!errors.has('max_2')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_3[lang]"
                                    label-for="max_3"                                            
                                    :invalid-feedback="errors.first('max_3')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_3"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_3"
                                      :state="(submitted)? (!errors.has('max_3')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_4[lang]"
                                    label-for="max_4"                                            
                                    :invalid-feedback="errors.first('max_4')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_4"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_4"
                                      :state="(submitted)? (!errors.has('max_4')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_5[lang]"
                                    label-for="max_5"                                            
                                    :invalid-feedback="errors.first('max_5')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_5"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_5"
                                      :state="(submitted)? (!errors.has('max_5')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_6[lang]"
                                    label-for="max_6"                                            
                                    :invalid-feedback="errors.first('max_6')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_6"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_6"
                                      :state="(submitted)? (!errors.has('max_6')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_7[lang]"
                                    label-for="max_7"                                            
                                    :invalid-feedback="errors.first('max_7')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_7"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_7"
                                      :state="(submitted)? (!errors.has('max_7')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_8[lang]"

                                    label-for="max_8"                                            
                                    :invalid-feedback="errors.first('max_8')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_8"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_8"
                                      :state="(submitted)? (!errors.has('max_8')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_9[lang]"
                                    label-for="max_9"                                            
                                    :invalid-feedback="errors.first('max_9')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="max_9"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.MAX.max_9"
                                      :state="(submitted)? (!errors.has('max_9')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab4'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_1[lang]"
                                    label-for="weight_1"                                            
                                    :invalid-feedback="errors.first('weight_1')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_1"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_1"
                                      :state="(submitted)? (!errors.has('weight_1')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_2[lang]"
                                    label-for="weight_2"                                            
                                    :invalid-feedback="errors.first('weight_2')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_2"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_2"
                                      :state="(submitted)? (!errors.has('weight_2')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_3[lang]"
                                    label-for="weight_3"                                            
                                    :invalid-feedback="errors.first('weight_3')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_3"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_3"
                                      :state="(submitted)? (!errors.has('weight_3')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_4[lang]"
                                    label-for="weight_4"                                            
                                    :invalid-feedback="errors.first('weight_4')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_4"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_4"
                                      :state="(submitted)? (!errors.has('weight_4')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_5[lang]"
                                    label-for="weight_5"                                            
                                    :invalid-feedback="errors.first('weight_5')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_5"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_5"
                                      :state="(submitted)? (!errors.has('weight_5')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_6[lang]"
                                    label-for="weight_6"                                            
                                    :invalid-feedback="errors.first('weight_6')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_6"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_6"
                                      :state="(submitted)? (!errors.has('weight_6')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                                
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_7[lang]"

                                    label-for="weight_7"                                            
                                    :invalid-feedback="errors.first('weight_7')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_7"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_7"
                                      :state="(submitted)? (!errors.has('weight_7')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_8[lang]"
                                    label-for="weight_8"                                            
                                    :invalid-feedback="errors.first('weight_8')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_8"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_8"
                                      :state="(submitted)? (!errors.has('weight_8')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_9[lang]"

                                    label-for="weight_9"                                            
                                    :invalid-feedback="errors.first('weight_9')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="weight_9"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.WEIGHT.weight_9"
                                      :state="(submitted)? (!errors.has('weight_9')): null"  
                                      v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab5'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-list-group  v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_10[lang]"
                                    label-for="SW1"                                            
                                    :invalid-feedback="errors.first('SW1')"
                                    style="width:100%;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="SW1"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.SWT.swt_1"
                                      :state="(submitted)? (!errors.has('SW1')): null"  
                                      v-validate="'required|decimal:30|min_value:0|max_value:1'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 

                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.contDataName_11[lang]"
                                    label-for="SW2"                                            
                                    :invalid-feedback="errors.first('SW2')"
                                    style="width:100%;"
                                  >
                                    <b-form-input 
                                      size="sm"
                                      name="SW2"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.SWT.swt_2"
                                      :state="(submitted)? (!errors.has('SW2')): null"  
                                      v-validate="'required|decimal:30|min_value:0|max_value:1'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-list-group>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab6'" class="h-100">
                        <div style="height:300px">
 
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100"> 
                                <b-list-group v-if="cdb.show" horizontal="md" style="gap:10px;"> 
                                  <b-form-group
                                    label-size="sm"
                                    :label="dict.resultCont[lang]"
                                    label-for="rescnt"                                            
                                    :invalid-feedback="errors.first('rescnt')"
                                    style="width:100%; padding: 0px 0px 10px;"
                                  >
                                    <b-form-input
                                      size="sm"
                                      name="rescnt"
                                      type="text"
                                      :placeholder="dict.contDataPH[lang]"
                                      v-model="cdb.data.present.rescnt"
                                      :state="(submitted)? (!errors.has('rescnt')): null"  
                                      v-validate="'required|decimal:30|min_value:0|max_value:1000000'"
                                      style="width:100%"
                                    ></b-form-input>
                                  </b-form-group> 
                                </b-list-group>

                                <div style="width:100%; padding: 0px 0px 10px 0px;">
                                  <input type="text" tabindex="1" id='msel1' />
                                </div>

                                <div style="width:100%; padding: 0px 0px 10px 0px;">
                                  <input type="text" tabindex="1" id='msel2' />
                                </div>

  
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_schedule'"
      size="lg"
      centered     
      hide-header-close
      content-class="shadow"
      :title="sdb.modalTitle"
      @shown="initScheduleModal"
      @hidden="handleScheduleModalReset"
      @ok="handleScheduleModalOk"
      >
      <PerfectScrollbar v-if="sdb.show">
        <!-- <div class="panel_body"> -->
        <div>
          <b-list-group flush >
            <b-list-group-item style="background-color:transparent;color:black; ">
              <b-form-group
                label="Schedule Title"
                label-for="title"                                            
                :invalid-feedback="errors.first('title')"
                style="width:100%;"
              >
                <b-form-input
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  v-model="sdb.title"  
                  :state="(submitted)? (!errors.has('title')): null"  
                  v-validate="'required|min:2|max:20'"
                  style="width:100%"
                ></b-form-input>
              </b-form-group>  

              <div class="mt-4">
                <div style="display: flex; justify-content: end; width:100%">
                  <b-button-group size="sm">
                  <b-button @click="sdb.TSI = 5">5m</b-button>
                  <b-button @click="sdb.TSI = 15">15m</b-button>
                  <b-button @click="sdb.TSI = 30">30m</b-button>
                  <b-button @click="sdb.TSI = 60">1h</b-button>
                  <b-button @click="sdb.TSI = 120">2h</b-button>
                  <b-button @click="sdb.TSI = 240">4h</b-button>
                  <b-button @click="sdb.TSI = 360">6h</b-button>
                  <b-button @click="sdb.TSI = 720">12h</b-button>
                  <b-button @click="sdb.TSI = 1440">24h</b-button>
                  </b-button-group>
                </div>



                <div id='container' >
                  <ejs-schedule id="Schedule"  height='450px' ref="scheduleObj" :selectedDate='sdb.selectedDate' :eventSettings='sdb.eventSettings' :dataBinding='onDataBinding' 
                  :currentView='sdb.currentView' :workDays='sdb.workDays'  :key='sdb.scheduleKey' :timeScale='sdb.timeScale'
                  :cellClick='oncellClick' :eventClick='onEventClick' :showQuickInfo='false'>
                    <e-views>
                      <e-view option='Week'></e-view>
                    </e-views>
                  </ejs-schedule>
                </div>
              </div>
                  
            </b-list-group-item>
          </b-list-group>
        </div>
      </PerfectScrollbar>
    </b-modal>
    
    <b-modal
      :id="plotId+'_scheduleEditor'"
      size="md"
      centered
      hide-header-close
      content-class="shadow"
      :title="scheduleRecordType+' Event'"
      @shown="initScheduleEditorModal"
      >
        <template #modal-footer >
          <div class="d-flex w-100 justify-content-between" >
            <div>
              <b-button v-if="scheduleRecordType==='Edit'" variant="danger" class="float-right" @click="handleScheduleEditorModalDelete" > Delete </b-button>
            </div>
            <div>
              <b-button variant="secondary"  style="margin-right:10px" @click="handleScheduleEditorModalReset" > Cancel </b-button>
              <b-button variant="primary"  @click="handleScheduleEditorModalOk" > Ok </b-button>
            </div>
          </div>
        </template>

        <div>
          <div class="e-float-input mb-3">
            <input type='text' required v-model="scheduleRecord.Subject"  />
            <span class="e-float-line"></span>
            <label class="e-float-text">Name</label>
          </div>

          <div>
            <input type="text" tabindex="1" id='ddl' />
          </div>

          <div id='sliderContA' ></div>
          <div id='sliderContB' ></div>

          <div class="d-flex mb-3" style="gap: 15px">
            <div id='startDTPcontainer' class="w-100">
              <input id='startDTP' type="text" />
            </div>

            <div id='endDTPcontainer' class="w-100">
              <input id='endDTP' type="text" />
            </div>
          </div>
           
          <ejs-recurrenceeditor locale="en" id="editor" ref="EditorObj" :change='onChangeRecurrenceeditor'></ejs-recurrenceeditor>
        </div>
    </b-modal>

    <b-modal
      :id="plotId+'_result'"
      size="xl"
      centered
      body-bg-variant="light"
      body-text-variant="dark"
      content-class="shadow"
    >

      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <div><h5>{{dict.result[lang]+' '+presentedResult}}</h5></div>
          <div><i><font-awesome-icon icon="fas fa-xmark" class="close" @click="handleResultModalReset"/></i></div>
        </div>
      </template>


      <div :dir="dict.dir[lang]">
        <div v-if="presResult && presResult.cluster2" style="width:100%; padding:10px; borderRadius:5px; display:flex; flex-wrap:wrap; gap:10px; ">  
          <div v-for="(item, i) in presResult.cluster2"  :key="i" style="width:205px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(17 60 252 / .3)" >
            <div class="w-100 mt-2">
              <div style="font-size:12px"  class="w-100 text-center">{{item.name}}</div>
              <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{item.value}}</div>
            </div>
          </div>
        </div>

        <div v-if="presResult && presResult.cluster1" style="width:100%; padding:10px; borderRadius:5px; display:flex; flex-wrap:wrap; gap:10px; ">  
          <div v-for="(item, i) in presResult.cluster1"  :key="i" style="width:205px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(252 17 232 / .3)" >
            <div class="w-100 mt-2">
              <div style="font-size:12px"  class="w-100 text-center">{{item.name}}</div>
              <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{item.value}}</div>
            </div>
          </div>
        </div>

        <div v-if="presResult && presResult.cluster3" style="width:100%; padding:10px; borderRadius:5px; display:flex; flex-wrap:wrap; gap:10px; ">  
          <div v-for="(item, i) in presResult.cluster3"  :key="i" style="width:205px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(17 252 33 / .3)" >
            <div class="w-100 mt-2">
              <div style="font-size:12px"  class="w-100 text-center">{{item.name}}</div>
              <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{item.value}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <template #modal-footer >
        <div class="d-flex w-100 justify-content-between" >
          <div>
            <b-button variant="secondary"  style="margin-right:10px"  > apply </b-button>
            <b-button variant="primary"   > download </b-button>
          </div>
        </div>
      </template> -->



      <!-- @click="handleExport" -->
      <template #modal-footer >
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end" >
          <div class="d-flex" style="gap: 10px" >
            <b-button variant="primary" >{{dict.download[lang]}}</b-button>
            <b-button variant="secondary" >{{dict.apply[lang]}}</b-button>
          </div>
        </div>
      </template>
        
            


          



      <!-- <b-table
        :items="[pannel.status.results[presentedResult]]"
        :fields="resultDataFields"
        responsive

        stacked="md"
        show-empty
        small
      >
      </b-table> -->




    </b-modal>
    

          


  </div>
</template>

<script>
/* eslint-disable */
  import myComponent_4 from "./myComponent_4.vue";
  import { v4 as uuidv4 } from 'uuid';

  import { ScheduleComponent, SchedulePlugin, Week, Month, Year,Agenda, Resize, DragAndDrop, ActionEventArgs,RecurrenceEditor } from '@syncfusion/ej2-vue-schedule';
  import { MultiSelect } from '@syncfusion/ej2-dropdowns';
    import { TextBox } from '@syncfusion/ej2-inputs';
  import { createElement } from '@syncfusion/ej2-base';
  import { enableRtl } from '@syncfusion/ej2-base';
  import { DropDownList } from '@syncfusion/ej2-dropdowns';
  import { Slider } from '@syncfusion/ej2-inputs';
  import { DateTimePicker  } from '@syncfusion/ej2-calendars';
  import { FormValidator } from '@syncfusion/ej2-inputs';
  import { isNullOrUndefined } from '@syncfusion/ej2-base';
  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  import RoundSlider from "vue-round-slider";

  export default {
    components: {
        myComponent_4, //transform: scale(.75);
        'ejs-schedule': ScheduleComponent,
        PerfectScrollbar,
        RoundSlider
    },
    props: {
      plotId: String,
      data: Object,
      editable: Boolean,
      resized: Number
    },
    provide: {
        schedule: [Week, Year, Month, Agenda,DragAndDrop,Resize]
    },
    data() {
      return {
        presentedResult: null,
        presResult : {
          cluster1:[],
          cluster2:[],
          cluster3:[],
        },
        activeTabCM: 'tab1',
        activeTabConf: 'tab1',  
        UC:0,
        isDisabled:true,

        status:{
          error: false,
          msg: 'no setting is selected'
        },
        waterMark : 'Select a datetime',

        typeList : [
          { text: 'text1', value: 'value1' },
          { text: 'text2', value: 'value2' },
          { text: 'text3', value: 'value3' }
        ],
        
        pannel:{
          settingVis : false,
          sliderHeight:100,
          settingsOptions:[],
          selectedSetting:null,
          scheduleTitle:'',
          manual:{
            inputs: {
              inp_1:'',
              inp_2:'',
              inp_3:'',
              inp_4:'',
              inp_5:'',
              inp_6:'',
              inp_7:'',
              inp_8:'',
              inp_9:'',
            },
            find: '0',
            intParam: 'false',
          },
          status:{
            results:[]
            // indicators:[
            //   { title:'وضعیت',
            //     value:'1',
            //     tag:'stat_1'},
            //   { title:'فصل کاری',
            //     value:'1',
            //     tag:'stat_2'},
            //   { title:'دمای آب برگشتی',
            //     value:'1',
            //     tag:'stat_3'},
            //   { title:'فشار آب',
            //     value:'1',
            //     tag:'stat_4'},
            //   { title:'میزان بازشدن ولو',
            //     value:'0',
            //     tag:'stat_5'},
            //   { title:'پمپ 1',
            //     value:'2',
            //     tag:'stat_6'},
            //   { title:'خطا پمپ 1',
            //     value:'0',
            //     tag:'stat_7'},
            //   { title:'پمپ 2',
            //     value:'2',
            //     tag:'stat_8'},
            //   { title:'خطا پمپ 2',
            //     value:'1',
            //     tag:'stat_9'},
            // ],
            // sliders:[
            //   { title:'rsliderA',
            //     value:'0',
            //     tag:'stat_10'},
            //   { title:'rsliderB',
            //     value:'0',
            //     tag:'stat_11'},
            // ]
          }
        },
        
        scheduleRecord: {
          Id: 1,
          Subject: '',
          StartTime: null,
          EndTime: null,
          IsAllDay: false,
          RecurrenceRule:null,
          parameters: {
            selectedSetting:null,
            param_1: 0,
            param_2: [],
          }
        },
        scheduleRecordType:null,

        myData : {
          datas:[],
          attribute:{
            title:'controller',
          },
          parameters:{
            enable:false,
            schedules:[],
            settings:[],
            selectedSchedule:'',
            activeEvent:'',
            controlMode:'',
            automatic:{},
            manual:{},
          },
          status:{}
        },
        activeTab : 'tab1',
        dataReady : false,

        controlOptions: [
          { text: 'Manual', value: 'manual', },
          { text: 'Schedule', value: 'schedule' },
        ],
        scheduleFields: [{ key: 'isSelected', label: '' },{key:'title', label: ''},{ key: 'modify', label: ''}],
        controllerFields: [{ key: 'isSelected', label: '' },{key:'title', label: ''},{ key: 'modify', label: ''}],
        modalData:{},
        cdb:{},
        sdb:{},

        submitted: false,
        successful: false,
        message: '',

        resultDataFields: [
          { key: 'param_1', label: 'param_1' },
          { key: 'param_2', label: 'param_2'},
          { key: 'param_3', label: 'param_3' },
          { key: 'param_4', label: 'param_4' },
          { key: 'param_5', label: 'param_5'},
          { key: 'param_6', label: 'param_6'},
          { key: 'param_7', label: 'param_7'},
          { key: 'param_8', label: 'param_8'},
          { key: 'param_9', label: 'param_9'}
        ],

        dict:{
          dir:{eng:'ltr',per:'rtl'},
          transition:{eng:'slide-fade-ltr',per:'slide-fade-rtl'},
          transition2:{eng:'slide2-fade-ltr',per:'slide2-fade-rtl'},
          title: {eng:'widget palette',per:'پالت ویجت'},
          tab1: {eng:'IQR',per:'IQR'},
          tab2: {eng:'minimum',per:'حد پایین'},
          tab3: {eng:'maximum',per:'حد بالا'},
          tab4: {eng:'distance calc. weights',per:'وزن‌های محاسبه فاصله'},
          tab5: {eng:'sorting weights',per:'وزن‌های مرتب‌سازی'},
          tab6: {eng:'presentation settings',per:'تنظیمات نمایش'},
          cancel: { eng: "Cancel", per: "لغو" },
          ok: { eng: "Ok", per: "قبول" },
          controlMode1: {eng:'User Triggered Operation',per:'فراخوانی توسط کاربر'},
          controlMode2: {eng:'Scheduled Operation',per:'فراخوانی زمانبندی‌شده'},
          selSetting: {eng:'Selected setting',per:'تنظیمات منتخب'},
          inputType: {eng:'Parameter definition',per:'نحوه تعریف پارامترها'},
          inputType1: {eng:'user defiened',per:'توسط کاربر'},
          inputType2: {eng:'get data from database',per:'دریافت پارامترها از پایگاه داده'},
          multiSel1: {eng:'parameters to calculate distance',per:'دریافت پارامترها از پایگاه داده'},
          multiSel2: {eng:'msel2',per:'دریافت پارامترها از پایگاه داده'},
          contTitle: {eng:'title',per:'عنوان'},
          contTitlePH: {eng:'enter title',per:'عنوان را وارد کنید'},
          contModalTitle: {eng:'new setting',per:'تنظیمات جدید'},
          contDataName_1: {eng:'Average of G-%CCS.1',per:'میانگین G-%CCS.1'},
          contDataName_2: {eng:'Average of G-%CCS.2',per:'میانگین G-%CCS.2'},
          contDataName_3: {eng:'Average of G-%FeO %',per:'میانگین G-%FeO %'},
          contDataName_4: {eng:'Average of G-%Porosity %',per:'میانگین G-%Porosity %'},
          contDataName_5: {eng:'Average of G-%S %',per:'میانگین G-%S %'},
          contDataName_6: {eng:'Average of G-MnO %',per:'میانگین G-MnO %'},
          contDataName_7: {eng:'Average of G-SiO2 %',per:'میانگین G-SiO2 %'},
          contDataName_8: {eng:'Average of G-Sieve 12.5-16 mm %',per:'میانگین G-Sieve 12.5-16 mm %'},
          contDataName_9: {eng:'Average of G-Sieve 8-12.5 mm %',per:'میانگین G-Sieve 8-12.5 mm %'},
          contDataName_10: {eng:'Average of %Met %',per:'میانگین %Met %'},
          contDataName_11: {eng:'Average of C %',per:'میانگین C %'},
          contDataName_12: {eng:'Y ( Met %_C %)',per:'Y ( Met %_C %)'},
          contDataName_13: {eng:'Distance',per:'فاصله'},
          contDataPH: {eng:'enter a number',per:'یک عدد وارد کنید'},
          resultCont: {eng:'number of presented results',per:'تعداد پاسخ‌های نمایش داده شده '},

          confTab1: {eng:'Schedule',per:'برنامه زمانی'},
          confTab2: {eng:'Settings',per:'تنظیمات'},

          startBtn: {eng:'find',per:'جستجو'},
          result:{eng:'result',per:'نتیجه'},
          apply:{eng:'apply',per:'اعمال'},
          download:{eng:'download',per:'دریافت'},
        },
      }
    },
    methods: {
      getData(){
        var dids=[];
        for (var d of this.myData.datas){
          dids.push(d._id)
        }
        if (dids.length > 0){
          this.$store.dispatch('panel/getData', {id:dids}).then(
            datas => {
            },
            error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
          )
        }
      },
      changeTab(){
        if (this.activeTab==='tab1')
          this.activeTab = 'tab2'
        else
          this.activeTab = 'tab1'
      },
      removeWidget() {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this widget')
        .then(value => {
          if (value===true){
            this.$store.dispatch('chpge/child2parent', {task: 'rmDashItem', item:this.plotId})
          }
        })
        .catch(err => {
        })
      },
      editWidget(){
        // console.log('editwidget',this.plotId)
        this.$root.$emit('bv::show::modal', this.plotId);
      },
      changeControlMode(){
        if (this.myData.parameters.controlMode === 'schedule'){
          this.updateSchPanel();
          this.updateStatus();
        }else if (this.myData.parameters.controlMode === 'manual'){
          this.updateManPanel();
        }
        // console.log('change control mode',this.myData.parameters.controlMode)
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        
        console.log('panel/setData1',sdata)
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );

      },
      onScheduleSelected(item) {
        this.myData.parameters.selectedSchedule = item._id
        this.$refs.scheduleTable.refresh();
        
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        console.log('panel/setData2',sdata)
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );
        
      },
      addController: function(type){
        this.modalData = {type:'Add', index:null}
        this.$root.$emit('bv::show::modal', this.plotId+'_control');
      },
      editController: function(index){
        this.modalData = {type:'Edit', index:index}
        this.$root.$emit('bv::show::modal', this.plotId+'_control');
      },
      addSchedule: function(type){
        this.modalData = {type:'Add', index:null}
        this.$root.$emit('bv::show::modal', this.plotId+'_schedule');
      },
      editSchedule: function(index){
        this.modalData = {type:'Edit', index:index}
        this.$root.$emit('bv::show::modal', this.plotId+'_schedule');
      },
      removeSchedule: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this schedule')
        .then(value => {
            if (value===true){

              var rmitem = this.myData.parameters.schedules[index]._id;
              this.myData.parameters.schedules.splice(index,1)
              
              if (JSON.stringify(this.myData.parameters.selectedSchedule)===JSON.stringify(rmitem)){
                var sclen = this.myData.parameters.schedules.length
                if (sclen>0){
                  if (index<sclen)
                    this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[index]._id
                  else if (index == sclen)
                    this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[sclen-1]._id 
                }
                else{
                  this.myData.parameters.selectedSchedule = null;
                }
              }

              var sdata = [{
                id:this.myData.datas[0]._id, 
                value:{
                  status: this.myData.status,
                  parameters: this.myData.parameters,
                  connections:this.myData.connections,
                  type:this.myData.type
                }
              }]
              console.log('panel/setData3',sdata)
              this.$store.dispatch('panel/setData', {data:sdata})
              .then(
                data => {
                },
                error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                }
              );
            }
        })
        .catch(err => {
        })
      },
      removeController: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this controller')
        .then(value => {
            if (value===true){

              var rmitem = this.myData.parameters.settings[index]._id;
              this.myData.parameters.settings.splice(index,1)
              
              // if (JSON.stringify(this.myData.parameters.selectedController)===JSON.stringify(rmitem)){
              //   var sclen = this.myData.parameters.settings.length
              //   if (sclen>0){
              //     if (index<sclen)
              //       this.myData.parameters.selectedController = this.myData.parameters.settings[index]._id
              //     else if (index == sclen)
              //       this.myData.parameters.selectedController = this.myData.parameters.settings[sclen-1]._id 
              //   }
              //   else{
              //     this.myData.parameters.selectedController = null;  
              //   }
              // }

              var sdata = [{
                id:this.myData.datas[0]._id, 
                value:{
                  status: this.myData.status,
                  parameters: this.myData.parameters,
                  connections:this.myData.connections,
                  type:this.myData.type
                }
              }]
              console.log('panel/setData4',sdata)
              this.$store.dispatch('panel/setData', {data:sdata})
              .then(
                data => {
                },
                error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                }
              );
            }
        })
        .catch(err => {
        })
      },
      onChangeStatus(state) {
        
        
        
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        console.log('panel/setData100',sdata)
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );
        
      },
      // control modal =============================================================
      initControlModal: function(){

        this.dict.contModalTitle = this.modalData.type==="Add"? {eng:'new setting',per:'تنظیمات جدید'} : {eng:'edit setting',per:'ویرایش تنظیمات '} 

        this.cdb = {
          show:false,
          modalTitle: this.modalData.type + ' Controller', 
          type: this.modalData.type,
          title:'',
          cid:'',
          data:{
            IQR:{
              iqr_1:'',
              iqr_2:'',
              iqr_3:'',
              iqr_4:'',
              iqr_5:'',
              iqr_6:'',
              iqr_7:'',
              iqr_8:'',
              iqr_9:''
            },
            MIN:{
              min_1:'',
              min_2:'',
              min_3:'',
              min_4:'',
              min_5:'',
              min_6:'',
              min_7:'',
              min_8:'',
              min_9:''
            },
            MAX:{
              max_1:'',
              max_2:'',
              max_3:'',
              max_4:'',
              max_5:'',
              max_6:'',
              max_7:'',
              max_8:'',
              max_9:''
            },
            WEIGHT:{
              weight_1:'',
              weight_2:'',
              weight_3:'',
              weight_4:'',
              weight_5:'',
              weight_6:'',
              weight_7:'',
              weight_8:'',
              weight_9:''
            },
            SWT:{
              swt_1:'',
              swt_2:'',
            },
            present:{
              rescnt:0,
              inpPars:[],
              presPars:[]
            }
          }
        }

        if (this.cdb.type==='Add'){
          this.cdb.title = '';
          this.cdb.cid = uuidv4();
        }
        else if (this.cdb.type==='Edit'){
          this.cdb.cid = this.myData.parameters.settings[this.modalData.index]._id
          this.cdb.title = this.myData.parameters.settings[this.modalData.index].title
          this.cdb.data = this.myData.parameters.settings[this.modalData.index].data
        }
        if (!this.cdb.data.present.presPars){
          this.cdb.data.present.presPars = []
        }
        // const textBoxObject = new TextBox({
        //   placeholder: 'Name of Scenario',
        //   value: this.cdb.title,
        //   floatLabelType: 'Auto',
        //   change: (args) => {
        //     this.cdb.title = args.value
        //     console.log('title',this.cdb.title)
        //   },
        // });

        // textBoxObject.appendTo('#txtin');
        this.cdb.show = true;
      },
      handleControlModalOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleControlModalSubmit()
      },
      handleControlModalSubmit() {
        // console.log('submit',this.modalData.type)
        this.message = '';
        this.submitted = true;
        this.$validator.validate().then(isValid => {
          if (isValid) {
            if (this.modalData.type==='Add')
              this.myData.parameters.settings.push({_id:this.cdb.cid, title:this.cdb.title, data:this.cdb.data}) 
            else if (this.modalData.type==='Edit')
              this.myData.parameters.settings[this.modalData.index] = {_id:this.cdb.cid, title:this.cdb.title, data:this.cdb.data} 
                
            var sdata = [{
              id:this.myData.datas[0]._id, 
              value:{
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections:this.myData.connections,
                type:this.myData.type
              }
            }]
            // console.log(sdata)
            console.log('panel/setData5',sdata)
            this.$store.dispatch('panel/setData', {data:sdata})
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId+'_control')
                })
              },
              error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              }
            );
          }
        });
      },
      handleControlModalReset(){
        this.submitted = null;
        this.successful = null;
        this.message= null;
        this.cdb.show = false;
        this.activeTabCM = 'tab1'
        this.$bvModal.hide(this.plotId+'_control');
      },
      // schedule modal =============================================================
      initScheduleModal: function(){
        this.sdb = {
          show:false,
          modalTitle: this.modalData.type + ' Schedule',
          type: this.modalData.type,
          title:'',
          sid:'',
          data : [],
          
          scheduleKey: 0,
          selectedDate: new Date(),
          currentView: 'Week',
          workDays: [1,2,3,4,5,6,7],
          eventSettings: { dataSource: []},
          timeScale: { 
            enable: true,
            interval: 360,
            slotCount: 3},
          TSI:360,
        }

        if (this.sdb.type==='Add')
        {
          this.sdb.title = '';
          this.sdb.sid = uuidv4();
          
          if (this.myData.parameters.schedules.length==0)
            this.myData.parameters.selectedSchedule = this.sdb.sid
        }
        else if (this.sdb.type==='Edit')
        {
          this.sdb.sid = this.myData.parameters.schedules[this.modalData.index]._id
          this.sdb.title = this.myData.parameters.schedules[this.modalData.index].title
          this.sdb.data = this.myData.parameters.schedules[this.modalData.index].data
        }
        
        // this.$refs.scheduleObj.eventSettings.dataSource = this.sdb.data
        this.sdb.eventSettings.dataSource = this.sdb.data
        this.sdb.scheduleKey = this.sdb.scheduleKey + 1;

        this.sdb.show = true;
      },
      handleScheduleModalOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleScheduleModalSubmit()
      },
      handleScheduleModalSubmit() {
        // console.log('submit',this.modalData.type)
        this.message = '';
        this.submitted = true;
        this.$validator.validate().then(isValid => {
          if (isValid) {
            if (this.modalData.type==='Add')
              this.myData.parameters.schedules.push({_id:this.sdb.sid, title:this.sdb.title, data:this.sdb.data}) 
            else if (this.modalData.type==='Edit')
              this.myData.parameters.schedules[this.modalData.index] = {_id:this.sdb.sid, title:this.sdb.title, data:this.sdb.data} 
                
            var sdata = [{
              id:this.myData.datas[0]._id, 
              value:{
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections:this.myData.connections,
                type:this.myData.type
              }
            }]
            // console.log(sdata)
            console.log('panel/setData6',sdata)
            this.$store.dispatch('panel/setData', {data:sdata})
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId+'_schedule')
                })
              },
              error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              }
            );
          }
        });
      },
      handleScheduleModalReset(){
        this.sdb.modalTitle = '',
        this.sdb.title = '',
        this.sdb.sid = '',
        this.sdb.data = [],
        this.submitted = null;
        this.successful = null;
        this.message= null;
      },
      onDataBinding(e){
        this.sdb.data = e.result
        // console.log('data binding',this.sdb.data)
      },
      onEventClick: function(args) {
        // console.log('event click',args.event)
        this.scheduleRecord.Subject = args.event.Subject;

        this.scheduleRecord.StartTime = args.event.StartTime;
        this.scheduleRecord.EndTime = args.event.EndTime;
        this.scheduleRecord.RecurrenceRule = args.event.RecurrenceRule;
        this.scheduleRecord.Id = args.event.Id;
        this.scheduleRecord.parameters = args.event.parameters;
        this.scheduleRecordType = 'Edit';
        this.$root.$emit('bv::show::modal', this.plotId+'_scheduleEditor');
      },
      oncellClick: function(args) {
        // console.log('cell click')
        this.scheduleRecord.Subject = '';
        this.scheduleRecord.StartTime = args.startTime;
        this.scheduleRecord.EndTime = args.endTime;
        this.scheduleRecord.RecurrenceRule = null;
        this.scheduleRecord.Id = uuidv4();
        this.scheduleRecord.parameters = {
          selectedSetting:null,
          param_1:0,
          param_2: [],
        };
        
        this.scheduleRecordType = 'Add';
        this.$root.$emit('bv::show::modal', this.plotId+'_scheduleEditor');
      },
      initScheduleEditorModal(){
        const startDateTimePicker = new DateTimePicker({
          value: this.scheduleRecord.StartTime, // Set the initial date
          placeholder: 'Start time',
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.StartTime = args.value;
            console.log('newDate:',this.scheduleRecord.StartTime)
          },
        });

        const endDateTimePicker = new DateTimePicker({
          value: this.scheduleRecord.EndTime, // Set the initial date
          placeholder: 'End time',
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.EndTime = args.value;
            console.log('newDate:',this.scheduleRecord.EndTime)
          },
        });

        var dropDownOptions = [{ text: 'disable', value: 'disable' }]
        for (var controller of this.myData.parameters.settings){
            dropDownOptions.push({ text: controller.title, value: controller._id })
        }

        const dropDownListObject = new DropDownList({
          placeholder: 'یکی از تنظیمات را انتخاب کنید', 
          value: this.scheduleRecord.parameters.selectedSetting,
          fields: { text: 'text', value: 'value' },
          dataSource: dropDownOptions,
          placeholder: "Setting",
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.parameters.selectedSetting = args.value;
            
            var newSliderA = null;
            var newSliderB = null;
            var indx = -1;
            if (this.scheduleRecord.parameters.selectedSetting){          
              indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting)
            }

            if (indx !== -1){
              var setting = this.myData.parameters.settings[indx].data;
              newSliderA = {fname:'param_1', type:setting.sliderA.type, min:setting.sliderA.min*1, max:setting.sliderA.max*1, step:setting.sliderA.step*1, mTicks:setting.sliderA.mTicks*1, MTicks:setting.sliderA.MTicks*1, element:'sliderA', container:'sliderContA', name:setting.sliderA.name}
              newSliderB = {fname:'param_2', type:setting.sliderB.type, min:setting.sliderB.min*1, max:setting.sliderB.max*1, step:setting.sliderB.step*1, mTicks:setting.sliderB.mTicks*1, MTicks:setting.sliderB.MTicks*1, element:'sliderB', container:'sliderContB', name:setting.sliderB.name}
            }
            const existingSliderA = document.getElementById('sliderA')? document.getElementById('sliderA').ej2_instances[0]:null;
            const existingSliderB = document.getElementById('sliderB')? document.getElementById('sliderB').ej2_instances[0]:null;

            if (existingSliderA && newSliderA){
              this.updateSlider(existingSliderA, newSliderA)
            }else if (!existingSliderA && newSliderA){
              this.createSlider(newSliderA)
            }else if (existingSliderA && !newSliderA){
              document.getElementById('sliderContA').children[0].remove();
            }

            if (existingSliderB && newSliderB){
              this.updateSlider(existingSliderB, newSliderB)
            }else if (!existingSliderB && newSliderB){
              this.createSlider(newSliderB)
            }else if (existingSliderB && !newSliderB){
              document.getElementById('sliderContB').children[0].remove();
            }
          },
        });

        var indx = -1;
        var sliders = []
        if (this.scheduleRecord.parameters.selectedSetting){          
          indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting)
        }
        if (indx !== -1){
          var setting = this.myData.parameters.settings[indx].data
          sliders.push({fname:'param_1', type:setting.sliderA.type, min:setting.sliderA.min*1, max:setting.sliderA.max*1, step:setting.sliderA.step*1, mTicks:setting.sliderA.mTicks*1, MTicks:setting.sliderA.MTicks*1, element:'sliderA', container:'sliderContA', name:setting.sliderA.name})
          sliders.push({fname:'param_2', type:setting.sliderB.type, min:setting.sliderB.min*1, max:setting.sliderB.max*1, step:setting.sliderB.step*1, mTicks:setting.sliderB.mTicks*1, MTicks:setting.sliderB.MTicks*1, element:'sliderB', container:'sliderContB', name:setting.sliderB.name})
        }
        
        for (var slider of sliders){
          this.createSlider(slider)
        }

        startDateTimePicker.appendTo('#startDTP');
        endDateTimePicker.appendTo('#endDTP');
        dropDownListObject.appendTo('#ddl');

        let recObject = this.$refs.EditorObj;
        recObject.setRecurrenceRule(this.scheduleRecord.RecurrenceRule);  
         
      },
      createSlider(slider){
        const sname = slider.fname;
        let container = createElement('div');
        let inputEle1 = createElement('div', { id:slider.element }   ) ;
        let lableEle = createElement('label', {className: 'custom-label1 mb-2 mt-2',
            attrs: { for: slider.element } , innerHTML:slider.name
        });
        container.appendChild(lableEle);
        container.appendChild(inputEle1);
        var sliderCont = document.getElementById(slider.container)
        sliderCont.appendChild(container)

        let _slider = new Slider({  
          min: slider.min,
          max: slider.max,
          step: slider.step,
          type: slider.type==='min-range'? 'MinRange':'Range',
          value: this.scheduleRecord.parameters[slider.fname].length !== 0 ? this.scheduleRecord.parameters[slider.fname] : 0,
          tooltip: {placement: "Before", isVisible: true},
          ticks: { placement: "Before", largeStep: slider.MTicks, smallStep: slider.mTicks, showSmallTicks: true },
          change: (args) => {
            this.scheduleRecord.parameters[sname] = args.value;
          },
        })
        _slider.appendTo('#'+slider.element);
      },
      updateSlider(existingSlider, newSlider){
        existingSlider.min = newSlider.min;
        existingSlider.max = newSlider.max;
        existingSlider.step = newSlider.step;
        existingSlider.value =  this.scheduleRecord.parameters[newSlider.fname].length !== 0 ? this.scheduleRecord.parameters[newSlider.fname] : 0;
        existingSlider.ticks = { placement: "Before", largeStep: newSlider.MTicks, smallStep: newSlider.mTicks, showSmallTicks: true };
        existingSlider.refresh();
      },
      handleScheduleEditorModalOk(){
        if (this.scheduleRecordType === 'Add'){
          // console.log(this.scheduleRecord)
          this.$refs.scheduleObj.addEvent([this.scheduleRecord]);
          this.$nextTick(() => {
            this.$bvModal.hide(this.plotId+'_scheduleEditor')
          })
        }
        else if (this.scheduleRecordType === 'Edit'){

          if (this.scheduleRecord.RecurrenceRule){
            this.$refs.scheduleObj.saveEvent(this.scheduleRecord,'EditSeries');
          }else{
            // console.log('EditOccurrence',this.scheduleRecord)
            this.$refs.scheduleObj.saveEvent(this.scheduleRecord);
          }
          this.$nextTick(() => {
            this.$bvModal.hide(this.plotId+'_scheduleEditor')
          })
        }
      },
      handleScheduleEditorModalReset(){
        
        this.scheduleRecord = {
          Id: null,
          Subject: '',
          StartTime: null,
          EndTime: null,
          IsAllDay: false,
          RecurrenceRule:null,
        },

        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId+'_scheduleEditor')
        })
      },
      handleScheduleEditorModalDelete(){
        if (this.scheduleRecord.RecurrenceRule){
          this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id,'DeleteSeries');
        }else{
          // console.log('EditOccurrence',this.scheduleRecord)
          this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id);
        }
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId+'_scheduleEditor')
        })
      },
      onChangeStartTime(arg){
        this.scheduleRecord.StartTime = arg.value;
        // console.log('newDate:',this.scheduleRecord.StartTime)
      },
      onChangeEndTime(arg){
        this.scheduleRecord.EndTime = arg.value;
        // console.log('newDate:',this.scheduleRecord.EndTime)
      },
      onChangeRecurrenceeditor(arg){
        this.scheduleRecord.RecurrenceRule = arg.value;
        // console.log('onChangeRecurrenceeditor:',this.scheduleRecord.RecurrenceRule)
      },
      //--------------------------------------------------



      
      
      

      
      updateAutoPanel(){
        console.log('autopanel')
        this.UC += 1;
        this.pannel.settingVis = true;
        // this.pannel.selectedSetting = this.myData.parameters.automatic.selectedSetting;
                
        // this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
        // for (var controller of this.myData.parameters.settings){
        //   this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
        // }

        // var indx = -1;
        // if (this.myData.parameters.automatic.selectedSetting){
        //   var indx = this.myData.parameters.settings.findIndex(x => x._id === this.myData.parameters.automatic.selectedSetting)
        // }

        

        


      },
      updateSchPanel(){
        this.UC += 1;
        var eventName = '';
        var scheduleName = '';
        var activeEvent = this.myData.parameters.activeEvent;
        this.pannel.settingVis = false;
        // this.pannel.rsliderA.visible = false;
        // this.pannel.rsliderB.visible = false;
        // console.log('activeEvent',activeEvent)
        
        var indx1 = -1;
        if (this.myData.parameters.selectedSchedule){
          var indx1 = this.myData.parameters.schedules.findIndex(x => x._id === this.myData.parameters.selectedSchedule)
        }
        if (indx1 !== -1){
          var schedule = this.myData.parameters.schedules[indx1];
          var scheduleName = schedule.title;
          if (activeEvent){
            var indx2 = schedule.data.findIndex(x => x.Id === activeEvent)
            if (indx2 != -1){
              var eventName = schedule.data[indx2].Subject;
              this.pannel.settingVis = true;
              // this.pannel.rsliderA.visible = true;
              // this.pannel.rsliderB.visible = true;

              var parameters = schedule.data[indx2].parameters;
              if (parameters){
                // console.log('parameters',parameters)
                this.pannel.selectedSetting = parameters.selectedSetting;
                  
                this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
                for (var controller of this.myData.parameters.settings){
                  this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
                }
                // console.log('parameters2',parameters.selectedSetting,this.myData.parameters.settings)

                var indx = -1;
                if (parameters.selectedSetting){
                  var indx = this.myData.parameters.settings.findIndex(x => x._id === parameters.selectedSetting)
                }

                // console.log('parameters3',indx)

                // this.pannel.rsliderA = {
                  
                //   key:'rsliderA_'+parameters.selectedSetting+'_'+this.UC,
                //   timeoutId:'',
                //   value: parameters.param_1?parameters.param_1:0,
                //   title: indx !== -1? this.myData.parameters.settings[indx].data.sliderA.name:'',
                //   visible: indx !== -1? this.myData.parameters.settings[indx].data.sliderA.status:true,
                //   min:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.min:null,
                //   max:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.max:null,
                //   step:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.step:null,
                //   MTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.MTicks:null,
                //   mTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.mTicks:null,
                //   type:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.type:null,
                //   disable:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.extSP:false,
                // }

                // this.pannel.rsliderB = {
                //   title:'رطوبت',
                //   key:'rsliderB_'+parameters.selectedSetting+'_'+this.UC,
                //   timeoutId:'',
                //   value: parameters.param_2?parameters.param_2:[],
                //   title: indx !== -1? this.myData.parameters.settings[indx].data.sliderB.name:'',
                //   visible: indx !== -1? this.myData.parameters.settings[indx].data.sliderB.status:false,
                //   min:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.min:null,
                //   max:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.max:null,
                //   step:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.step:null,
                //   MTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.MTicks:null,
                //   mTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.mTicks:null,
                //   type:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.type:null,
                //   disable:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.extSP:false,
                // }
              }
            }
          }
        }
        this.pannel.scheduleTitle = `${scheduleName} ${eventName? '/': ''} ${eventName}`;
        // console.log('this.pannel.scheduleTitle',this.pannel.scheduleTitle)
      },
      updateManPanel(){

        console.log("[[[[[[[[[[[[[[[[[[]]]]]]]]]]]]]]]]]]")
        console.log(this.myData.parameters.manual)

        Object.keys(this.pannel.manual.inputs).forEach(key => {
          if (this.myData.parameters.manual.inputs[key])
            this.pannel.manual.inputs[key] = this.myData.parameters.manual.inputs[key];
          else
            this.pannel.manual.inputs[key] = ''
        })

        this.pannel.manual.find = this.myData.parameters.manual.find? this.myData.parameters.manual.find: '0'
        this.pannel.manual.intParam = this.myData.parameters.manual.intParam? this.myData.parameters.manual.intParam: 'false'


        this.pannel.status.results = this.myData.parameters.status.results?this.myData.parameters.status.results:[]
        this.pannel.selectedSetting = this.myData.parameters.manual.selectedSetting? this.myData.parameters.manual.selectedSetting:null

        this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
        for (var controller of this.myData.parameters.settings){
          this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
        }

        var indx = -1;
        if (this.myData.parameters.manual.selectedSetting){
          var indx = this.myData.parameters.settings.findIndex(x => x._id === this.myData.parameters.manual.selectedSetting)
        }


        // var keys = Object.keys(this.myData.parameters.manual)

        // keys.forEach(key => {
        //   var value = this.myData.parameters.manual[key]
        //   var indx = this.pannel.manual.buttons.findIndex(x => x.port === key)
        //   if (indx != -1){
        //     this.pannel.manual.buttons[indx].value = value;
        //   }

        //   var indx = this.pannel.manual.valve.findIndex(x => x.port === key)
        //   if (indx != -1){
        //     this.pannel.manual.valve[indx].value = value;
        //   }
        // })

        // this.pannel.manual.valve.forEach(slider =>{
        //   this.UC += 1;

        //   var LRSA = JSON.stringify(this.pannel[slider.rslider])
        //   this.pannel[slider.rslider] = {
        //     key:this.pannel[slider.rslider].key,
        //     timeoutId:this.pannel[slider.rslider].timeoutId,
        //     value:slider.value,
        //     title: this.pannel[slider.rslider].title,
        //     visible: true,
        //     min:this.pannel[slider.rslider].min,
        //     max:this.pannel[slider.rslider].max,
        //     step:this.pannel[slider.rslider].step,
        //     MTicks:this.pannel[slider.rslider].MTicks,
        //     mTicks:this.pannel[slider.rslider].mTicks,
        //     disable:false,
        //   }
        //   var CRSA = JSON.stringify(this.pannel[slider.rslider])

        //   if (LRSA !== CRSA){
        //     console.log(LRSA,CRSA)
        //     this.pannel[slider.rslider].key = slider.rslider+'_'+this.myData.parameters.automatic.selectedSetting+'_'+this.UC
        //     this.pannel[slider.rslider].timeoutId = ''
        //   }

        //   console.log('.................s2',this.pannel[slider.rslider])

        // })
      },
      updateStatus(){
        this.pannel.status.results = this.myData.parameters.status.results
        // var keys = Object.keys(this.myData.parameters.status)
        // keys.forEach(key => {
        //   var value = this.myData.parameters.status[key]
        //   var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
        //   if (indx != -1){
        //     this.pannel.status.indicators[indx].value = value;
        //   }
        //   indx = this.pannel.status.sliders.findIndex(x => x.tag === key)
        //   if (indx != -1){
        //     this.pannel.status.sliders[indx].value = value;
        //   }
        // })
      },
      // changeStatus(key,value){
      //   this.myData.parameters.status[key] = value
      //   var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
      //   if (indx != -1){
      //     this.pannel.status.indicators[indx].value = value;
      //   }
      //   this.saveData('status')
      // },
      //---------------------------------------------------
      saveData(type){
        // console.log('save data',type);
        var update = false;
        if (type==='autoPanel' && (this.myData.parameters.controlMode === 'automatic')){
          console.log('lll')
          
          // var bc = JSON.stringify(this.myData.parameters.automatic)
          // this.myData.parameters.automatic = {
          //   param_1: this.pannel.rsliderA.value,
          //   param_2: this.pannel.rsliderB.value,
          //   selectedSetting: this.pannel.selectedSetting
          // }
          // var ac = JSON.stringify(this.myData.parameters.automatic)
          // if (ac !== bc)
          //   update = true;
          
        }else if (type==='manPanel' && (this.myData.parameters.controlMode === 'manual')){
          console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjj')
          this.myData.parameters.manual = {}
            this.myData.parameters.manual.intParam = this.pannel.manual.intParam
            this.myData.parameters.manual.selectedSetting = this.pannel.selectedSetting
            

// inputs: {
//               inp_1:'',
//               inp_2:'',
//               inp_3:'',
//               inp_4:'',
//               inp_5:'',
//               inp_6:'',
//               inp_7:'',
//               inp_8:'',
//               inp_9:'',
//             },
//             Object.keys(this.pannel.manual.inputs)
//             this.pannel.manual.inputs.
            this.myData.parameters.manual.inputs = JSON.parse(JSON.stringify(this.pannel.manual.inputs)) ;
            this.pannel.manual.find = '1'
            this.myData.parameters.manual.find = '1';

            console.log('this.myData',this.myData)
            console.log('this.pannel.manual.inputs',this.pannel.manual.inputs)
            
          update = true;
        }else if (type==='status' && (this.myData.parameters.controlMode === 'automatic')){
          update = true;
        }

        if (update){
          var sdata = [{
            id:this.myData.datas[0]._id, 
            value:{
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections:this.myData.connections,
              type:this.myData.type
            }
          }]
          console.log('panel/setData7',sdata)
          this.$store.dispatch('panel/setData', {data:sdata})
          .then(
            data => {
              // this.$nextTick(() => {
              //   this.$bvModal.hide(this.plotId+'_schedule')
              // })
            },
            error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            }
          );
        } 
      },
      openResultModal(i){
        this.presentedResult = i+1

        this.presResult = {
          cluster1:[],
          cluster2:[],
          cluster3:[],
        }

        var cluster1 = {param_1:'contDataName_1',param_2:'contDataName_2',param_3:'contDataName_3',param_4:'contDataName_4',param_5:'contDataName_5',
                         param_6:'contDataName_6',param_7:'contDataName_7',param_8:'contDataName_8',param_9:'contDataName_9'}
        
        var cluster2 = {param_10:'contDataName_10', param_11:'contDataName_11', param_12:'contDataName_12',distance:'contDataName_13'}

        var cluster3 = {set_1:'setpoint 1',set_2:'setpoint 2',set_3:'setpoint 3',set_4:'setpoint 4',set_5:'setpoint 5',
                        set_6:'setpoint 6',set_7:'setpoint 7',set_8:'setpoint 8',set_9:'setpoint 9',set_10:'setpoint 10',
                        set_11:'setpoint 11',set_12:'setpoint 12',set_13:'setpoint 13',set_14:'setpoint 14',set_15:'setpoint 15',
                        set_16:'setpoint 16',set_17:'setpoint 17',set_18:'setpoint 18',set_19:'setpoint 19',set_20:'setpoint 20',
                        set_21:'setpoint 21',set_22:'setpoint 22',set_23:'setpoint 23',set_24:'setpoint 24',set_25:'setpoint 25',
                        set_26:'setpoint 26',set_27:'setpoint 27',set_28:'setpoint 28',set_29:'setpoint 29',set_30:'setpoint 30',
                        set_31:'setpoint 31',set_32:'setpoint 32',set_33:'setpoint 33',set_34:'setpoint 34',set_35:'setpoint 35',
                        set_36:'setpoint 36',set_37:'setpoint 37',set_38:'setpoint 38',set_39:'setpoint 39',set_40:'setpoint 40',
                        set_41:'setpoint 41',set_42:'setpoint 42',set_43:'setpoint 43',set_44:'setpoint 44',set_45:'setpoint 45',
                        set_46:'setpoint 46',set_47:'setpoint 47',set_48:'setpoint 48',set_49:'setpoint 49',set_50:'setpoint 50',
                        set_51:'setpoint 51',set_52:'setpoint 52'
                      }

        Object.keys(cluster1).forEach(key=>{
          if (this.pannel.status.results[i][key]){
            this.presResult.cluster1.push({name:this.dict[cluster1[key]][this.lang], value:this.pannel.status.results[i][key].toFixed(2)})
          }
        })
        Object.keys(cluster2).forEach(key=>{
          if (this.pannel.status.results[i][key]){
            this.presResult.cluster2.push({name:this.dict[cluster2[key]][this.lang], value:this.pannel.status.results[i][key].toFixed(2)})
          }
        })
        Object.keys(cluster3).forEach(key=>{
          if (this.pannel.status.results[i][key]){
            this.presResult.cluster3.push({name:cluster3[key], value:this.pannel.status.results[i][key]})
          }
        })

        this.$root.$emit('bv::show::modal', this.plotId+'_result');
      },
      handleResultModalReset(){
        // this.successful = true;
        // this.message = '';
        this.$bvModal.hide(this.plotId+'_result')
      },
      // changeIntParam(){
      //   console.log(this.pannel.manual.intParam)
      // }
      
    },

    mounted(){

      if (this.data && this.data.datas && this.data.attribute){
        this.myData = this.data
      }
      // console.log('===========control myData: ', this.myData)
      this.getData()
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      TSI(){
        return this.sdb.TSI;
      },
      settings(){
        if (this.myData.parameters){
          return this.myData.parameters.settings;
        }
      },
      selectedSetting(){
        return this.pannel.selectedSetting;
      },
      lang(){
        return this.$store.state.chpge.lang;
      },
    },
    watch: {
      updatedData(newValue) {
        // console.log('newdata')
        if (this.myData.datas.length > 0){
          for (var Data of newValue){
            // console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
            if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)){
              var LMD = JSON.stringify(this.myData)
              
              this.myData = {...this.data,...Data.value}
              if (!this.myData['parameters']){
                this.myData['parameters'] = {   

                  schedules:[],
                  selectedSchedule:'',
                  activeEvent:'',
                  settings:[],
                  controlMode:'manual',
                  automatic:{},
                  manual:{},
                  status:{},
                  
                }
              }
              var CMD = JSON.stringify(this.myData)
              if (LMD!==CMD){

                if (this.myData.parameters.controlMode === 'automatic'){
                  
                  this.updateAutoPanel();
                  this.updateStatus();
                }else if (this.myData.parameters.controlMode === 'schedule'){
                  this.updateSchPanel();
                  this.updateStatus();
                }else if (this.myData.parameters.controlMode === 'manual'){
                  this.updateManPanel();
                }
                this.dataReady = true;

              }

              
            }
          }
        }
      },
      TSI(newValue) {
        // console.log('TSI',newValue)
        this.sdb.timeScale.interval = newValue;
        this.sdb.eventSettings.dataSource = this.sdb.data;
        this.sdb.scheduleKey = this.sdb.scheduleKey + 1;
        // console.log('TSI',newValue)
      },
      resized(){
        this.resizeSlider();
      },
      settings(newvalue){
        if (newvalue){
          this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
          for (var controller of this.myData.parameters.settings){
            this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
          }
        }

      },
      selectedSetting(newval){
        // console.log('a')
        this.saveData('manPanel')
      },
      activeTabCM(newVal) {
        if (newVal === 'tab6') {
          this.$nextTick(() => {

            const multiSelectObject1 = new MultiSelect({
              dataSource: this.typeList,
              value: this.cdb.data.present.inpPars,
              fields: { text: 'text', value: 'value' },
              placeholder: this.dict.multiSel1[this.lang],
              floatLabelType: 'Auto',
              enableRtl: this.lang==='per',
              change: (args) => {
                this.cdb.data.present.inpPars = args.value
              },
            });

            const multiSelectObject2 = new MultiSelect({
              dataSource: this.typeList,
              value: this.cdb.data.present.presPars,
              fields: { text: 'text', value: 'value' },
              placeholder: this.dict.multiSel2[this.lang],
              floatLabelType: 'Auto',
              enableRtl: this.lang==='per',
              change: (args) => {
                this.cdb.data.present.presPars = args.value
              },
            });
           
            setTimeout(() => {
              multiSelectObject1.appendTo('#msel1');
              multiSelectObject2.appendTo('#msel2');
            }, 500);
          });
        }
      },
    },
  };
</script>

<style scoped 
  src="@/assets/styles/ledStyle.css">
  
</style>

<style>
.disabled-div {
  pointer-events: none;
  /* Add any additional styling for a disabled appearance */
  opacity: 0.5; /* Example: reduce opacity for a disabled look */
}

  .component-main-holder {
    position: absolute;
    z-index: 7; 
    display: flex;
    justify-content: center;
    width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .component-hole {
    position: absolute;
    z-index: 8;
    /* background: #ffffff; */
    border-radius: 50%;
    /* left:  30%;
    right: 30%;
    bottom:30%;
    top: 30%; */
        left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .component-hole .text {
    display: flex;
    justify-content: center;
    font-size: 40px !important;
    font-weight: bold !important;
    color:#ffffff !important;
    font-family: Arial, Helvetica, sans-serif !important;
    margin-bottom: 0px;
  }
  .component-hole .note {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px !important;
    /* font-weight: bold !important; */
    color:#ffffff !important;
    /* font-family: Arial, Helvetica, sans-serif !important; */
  }



  .modal-footer {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .rs-custom.rs-bar {
    z-index: 4;
  }
  .rs-custom .rs-border {
    border-color: #ababab;
    margin-left: 18px;
    width: 5px;
  }
  .rs-custom .rs-border.rs-bold {
    width: 10px;
   
  }
  .rs-custom.selected .rs-border {
    border-color: #ff9500;
  }


  .rs-custom1.rs-bar {
    z-index: 3;
  }
  .rs-custom1 .rs-border {
    border-color: transparent;
    margin-left: 30px;
    width: 5px;
  }
  .rs-custom1 .rs-border.rs-bold {
    width: 30px;
    width: 30px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }

  .rs-custom1 .rs-border.rs-bold.mirrored {
    transform: translate(0, -50%) rotate(180deg) ;
    justify-content: end;
  }



  .rs-custom1.selected .rs-border {
    color:#ff9500;
  }


  .rs-pointer.rs-bar {
    z-index: 5;
  }
  .rs-pointer .rs-border {
    width: 0px;
    /* height: 25px !important; */
    position: absolute;
    top: 50%; 
    /* left: -30px; */
    transform: translate(0,-50%); 

    /* border-radius: 5px; */
    border: 1px solid rgb(4, 202, 169) !important;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }
  .rs-pointer .rs-border:before{
    content: "";
    position: absolute;
    bottom: 50%;
    right: -5px;
    border: 10px solid rgb(4, 202, 169);
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: translateY(50%);
  }
  .rs-value.rs-tooltip.rs-tooltip-text{
    color: rgb(4, 202, 169) !important;
    font-family: Segoe UI !important;
    font-size: 40px !important;
    font-weight: bold !important; 
  }
  span.rs-number {
    position: absolute;
    top: -12px;
    left: -26px;
    font-family: Segoe UI;
    font-size: 24px;
    font-weight: bold;
  }
</style>


<style scoped>

  .panel_container {
    width: 500px;
    height: 500px;
  }
  .panel_container .panel_header {
    /* padding: 10px; */
    height: 10%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-variant:small-caps;
    border-bottom: 2px solid rgb(210, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container .panel_body1 {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    /* border-bottom: 2px solid rgb(166, 41, 41); */
    display: flex;
    justify-content: space-between;
  }
  .panel_container  .panel_col {
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container  .panel_colf {
    height: 100%; 
    width: 100%;
  }
  .panel_container .panel_footer {
    transition: height 1s; 
    height:90%
  }
  .buttonHolder {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
  }
  .radioboxGroup {
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .radiobtnGroup {
    width: 100%;
    height: 50px;
    display: inline-block;
        margin:2px
  }
  .radiobtnGroup .radioinpGroup {
    display: none;
  }
  .radiobtnGroup .radiospanGroup:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }
  .radiobtnGroup .radiospanGroup:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtnGroup .radiospanGroup {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
    /* background-color: #ddd; */
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0);
    padding: 5px;

  }
  .radiobtnGroup input:checked + span{
    background-color:  hsl(25.5, 94.8%, 54.3%);
    color:rgb(255, 255, 255)
  }
  .radiobox {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }
  .radiobtn {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .radiobtn .radioinp {
    display: none;
  }

  .radiobtn .radiospan:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }

  .radiobtn .radiospan:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtn .radiospan {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    background-color: #ddd;
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0)
  }
  .radiobtn input:checked + span{
    background-color: #113cfc; 
    color:rgb(255, 255, 255)
  }
  .tab-content{
    height: 100%;

  }
  /* .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
{
    transform: translateX(10px);
    opacity: 0;
  } */
  .fcontainor{
    display:flex; 
    justify-content: space-between;
    flex-direction:row; 
    border: 1px solid #ced4da; 
    padding: 2%;
    border-radius: 0.5rem;
    margin-top: 2%;
  }
  .econtainor{
      border: 1px solid #ced4da; 
      padding: 10px;
      border-radius: 0.5rem;
      /* margin-top: 10px; */
  }



  .panel_body {
      /* padding: 0px 2% 0px 2%; */
      /* height: 68vh; */
      overflow-y: auto;
  }


</style>

<style scoped>

  .remove {
    cursor: pointer;
    /* color: rgb(212, 64, 27); */
    font-size: 18px;
  }
  .edit {
    cursor: pointer;
    color: rgb(9, 180, 210);
    font-size: 18px;
  }
  .slide-fade-enter-active, .slide-fade-leave-active, .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(100%);
  }


  .slide-fade-rtl-enter-active, .slide-fade-rtl-leave-active, .slide-fade-ltr-enter-active, .slide-fade-ltr-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-fade-rtl-enter, .slide-fade-rtl-leave-to {
    transform: translateX(100%);
  }
  .slide-fade-ltr-enter, .slide-fade-ltr-leave-to {
    transform: translateX(-100%);
  }
  
  .slide2-fade-rtl-enter-active, .slide2-fade-rtl-leave-active, .slide2-fade-ltr-enter-active, .slide2-fade-ltr-leave-active {
    transition: transform 0.3s ease;
  }
  .slide2-fade-rtl-enter, .slide2-fade-rtl-leave-to {
    transform: translateX(100%);
  }
  .slide2-fade-ltr-enter, .slide2-fade-ltr-leave-to {
    transform: translateX(-100%);
  }
  .cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;

  }
</style>

<style scoped>
  @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
  
  .wrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  /* .e-subject-container{
       width: 100% !important;
  } */
  .custom-event-editor .e-textlabel {
    padding-right: 15px;
    text-align: right;
  }
  .custom-event-editor td {
    padding: 7px;
    padding-right: 16px;
  }  

  .ps {
    height: 100%;
  }
</style>

  <style>
    .e-slider-container{
      padding:5px 10% 5px 10%;
    }

    .e-subject-container{
      width: 100% !important;
    }
    .custom-field-row{
      padding-bottom: 12px;
    }
    .custom-label1{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
    }
    .custom-label2{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
    }
    .custom-label3{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bolder;
    }
    .flex-container{   
      display: flex;
      padding-bottom: 12px;
      width: 100%;
      justify-content: space-between;
    }
    .field-container{
      width:30%;
    }
    .field-container-controller{
      width:48%;
      margin-bottom: 10px;
    }
    .panel_header {
      padding: 10px;
      height: 60px;
      font-size: 20px;
      color: rgb(0, 0, 0);
      font-variant:small-caps;
      border-bottom: 2px solid rgb(210, 210, 210);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .panel_col {
      height: 100%; 
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      height: 25px;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      font-size: 13px;
    }



    /* .e-input-group.e-control-wrapper.e-float-input {
      color: royalblue;

    }
    
    .e-float-text::after {
      color: royalblue;

    }

    .e-float-line::before {
        background: royalblue !important;
    }

    .e-float-line::after {
        background: royalblue !important;
    }

    .e-float-text.e-label-top{
      color: royalblue !important;

    } */
</style>

<style scoped>
  .led-red {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #940;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0 2px 12px;
  }
  .led-yellow {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #A90;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #660 0 -1px 9px, #DD0 0 2px 12px;
  }
  .led-green {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #690;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0 2px 12px;
  }

  .indicator{
    position: relative;
    display:flex;
    flex-direction: column;
    height:100%; 
    min-height:70px; 
    width:100%;
    padding:10px; 
    border-radius:5px; 
    box-shadow: 0 .125em .25em rgb(0 0 0 / .3)
  }
  .indicator-label{
    width:100%; 
    height:30%; 
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal !important;
    font-weight: 200;
    font-size: 12px;
  }
  .indicator-val{
    
    width:100%; 
    height:70%; 
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal !important;
    font-weight: 700;
    font-size: 18px;
  }

</style>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style scoped src="@/assets/styles/theme.css"></style>

<style>
    .WCSCM-body-class{
        padding: 0 !important ;
    }
</style>

<style scoped>
  .widget {
    overflow:hidden;
    width:200px; 
    height:200px; 
    display:flex; 
    flex-direction: column;
    justify-content:center; 
    align-items:center;
    border: 2px solid #ced4da;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  .widget:hover{
    border: 2px solid #113cfc;
  }
  .close {
    color:#dc3545;
    cursor:pointer;
  }
  .close :hover{
    color:#9b9b9b;
  }
  
</style>

<style scoped>
  .form-check.ltr {
    padding-left: 1.5em;
    padding-right: 0;
  }
  .form-check.rtl {
    padding-right: 1.5em;
    padding-left: 0;
  }

  .form-check .form-check-input.ltr  {
    float: left;
    margin-left: -1.5em;
  }
  .form-check .form-check-input.rtl  {
    float: right;
    margin-right: -1.5em;
  }
</style>
