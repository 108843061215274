<template>
  <div>
    <div :dir="dict.dir[lang]" :class="headerClasses">
      <div class="logo-src">
        <img src="@/assets/images/logo-persian.png" alt="megashid" height="40 %"
             style="margin-left:15px; margin-right:10px;">
      </div>
      <div class="header__content">
        <div class="header-left">
          <div>
            <div class="header-left">
              <ul class="header-megamenu nav" ng-reflect-ng-style="[object Object]" style="opacity: 1;">
                <li class="nav-item">
                  <b-dropdown id="dropdown-wb" ref="dropdown" variant="none" class="workbench-dropdown m-2">
                    <template #button-content>
                      <div v-if="workBenches.length>0">
                        {{ activeWorkbench.label }}
                        <!-- {{workBenches.filter(x=>(x.active))[0].label}} -->
                      </div>
                      <div v-else>
                        {{ dict.withoutWB[lang] }}
                      </div>

                    </template>
                    <b-dropdown-header>
                      <div class="background-container">
                        <div class="background-content">
                          {{ dict.selectWB[lang] }}
                        </div>
                      </div>
                    </b-dropdown-header>

                    <b-dropdown-item-button v-for="(item, index) in workBenches" :key="index"
                                            @click="item.status !== 'waiting'? changeWorkbench(item.id):showWaitingInv([item])"
                                            style="border-radius: inherit;">
                      <div v-if="item.status !== 'waiting'" class="dropdown-item-content">
                        <div style="width:20%">
                          <b-avatar
                            variant="info"
                            :text="`${item.label? item.label[0]:''} ${item.label? item.label[0]:''}`"
                          ></b-avatar>
                        </div>

                        <div style="width:20%">
                          <font-awesome-icon v-if="item.permission==='admin'" icon="fas fa-user-tie " size="lg" />
                          <font-awesome-icon v-else-if="item.permission==='user'" icon="fas fa-user-cog " size="lg" />
                          <font-awesome-icon v-else-if="item.permission==='guest'" icon="fas fa-user-lock " size="lg" />
                        </div>
                        <div style="width:60%">{{ item.label }}</div>
                        <!-- {{ item.status }} -->
                      </div>
                      <div v-else class="dropdown-item-content">
                        <div style="width:20%">
                          <font-awesome-icon v-if="item.permission==='admin'" icon="fas fa-user-tie " size="lg" />
                          <font-awesome-icon v-else-if="item.permission==='user'" icon="fas fa-user-cog " size="lg" />
                          <font-awesome-icon v-else-if="item.permission==='guest'" icon="fas fa-user-lock " size="lg" />
                        </div>
                        <div style="width:50%">{{ item.label }}</div>
                        <span class="badge rounded-pill bg-primary text-light"
                              style="width:30%">{{ dict.waiting[lang] }}</span>
                        <!-- {{ item.status }} -->
                      </div>
                    </b-dropdown-item-button>


                    <!--                    <b-dropdown-divider v-if="(currentUser && (currentUser.systemRole==='administrator')) || workBenches.findIndex(x=>(x.status==='creator'))===-1"></b-dropdown-divider>-->
                    <!--                    <b-dropdown-item-button v-if="(currentUser && (currentUser.systemRole==='administrator')) ||  workBenches.findIndex(x=>(x.status==='creator'))===-1"  @click="createWorkbench" style="border-radius: inherit;">-->
                    <!--                      <div class="dropdown-item-content">-->
                    <!--                        -->
                    <!--                        <div style="width:20%">-->
                    <!--                          <font-awesome-icon icon="fas fa-plus " size="lg" />-->
                    <!--                        </div>-->
                    <!--                        <div style="width:80%">{{dict.createWB[lang]}}</div>-->

                    <!--                      </div>-->
                    <!--                    </b-dropdown-item-button>-->

                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header-right">
          <b-dropdown id="dropdown-wb" ref="dropdown" variant="link" toggle-class="text-decoration-none" no-caret
                      class="language-dropdown m-2">
            <template #button-content>
              <div class="dropdown-item-content justify-content-center">

                <!-- <b-avatar variant="info" :src="dropdownLngItems.filter(x => (x.value===lang))[0].imageUrl"></b-avatar> -->

                <b-avatar
                  :text="dropdownLngItems.filter(x => (x.value===lang))[0].abrev"

                  class="rounded-circle shadow-10 bg-alternate avatar text-white"
                ></b-avatar>
              </div>
            </template>
            <b-dropdown-header>
              <div class="background-container">
                <div class="background-content" style="font-weight: lighter">
                  {{ dict.selectLang[lang] }}
                </div>
              </div>
            </b-dropdown-header>
            <b-dropdown-item-button v-for="(item, index) in dropdownLngItems" :key="index"
                                    @click="changeLanguage(item.value)" style="border-radius: inherit;">
              <!-- <div class="dropdown-item-content">
                <img :src="item.imageUrl" alt="Flag" height="30rem" style="margin-left: 0px; margin-right: 20px;">
                {{ item.label }}
              </div> -->

              <div class="dropdown-item-content">
                <div style="width:50%">
                  <b-avatar
                    :text="item.abrev"
                    class="rounded-circle shadow-10 bg-alternate avatar text-white"
                  ></b-avatar>
                  <!-- <img :src="item.imageUrl" alt="Flag" height="30rem"> -->
                </div>
                <div style="width:50%">{{ item[lang] }}</div>
              </div>

            </b-dropdown-item-button>
          </b-dropdown>

          <!-- profile -->

          <b-dropdown id="dropdown-wb" ref="dropdown" variant="link" toggle-class="text-decoration-none" no-caret
                      class="profile-dropdown m-2">
            <template #button-content>
              <div class="dropdown-item-content justify-content-center">
                <b-avatar
                  style="border: 1px dashed #0d6efd"
                  variant="info"
                  :src="avatarUrl"
                  :text="`${currentUser.fname? currentUser.fname[0]:'U'} ${currentUser.lname? currentUser.lname[0]:'N'}`"
                ></b-avatar>
              </div>
            </template>
            <b-dropdown-header>
              <div class="background-container">
                <div class="background-content">
                  <b-avatar
                    variant="info"
                    :src="avatarUrl"
                    :text="`${currentUser.fname? currentUser.fname[0]:'U'} ${currentUser.lname? currentUser.lname[0]:'N'}`"
                  ></b-avatar>
                  {{ `${currentUser.fname} ${currentUser.lname}` }}
                </div>
              </div>
            </b-dropdown-header>
            <b-dropdown-item-button @click="selectPage('profile')">
              <div class="dropdown-item-content">
                <div style="width:20%">
                  <font-awesome-icon icon="fas fa-user-circle " size="xl" />
                </div>
                <div style="width:80%">{{ dict.profile[lang] }}</div>
              </div>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click.prevent="logOut">
              <div class="dropdown-item-content">
                <div style="width:20%">
                  <font-awesome-icon icon="fas fa-sign-out " size="lg" />
                </div>
                <div style="width:80%">{{ dict.signout[lang] }}</div>
              </div>
            </b-dropdown-item-button>
          </b-dropdown>


          <!--
                  <b-navbar-nav v-if="!currentUser" class="ml-auto">
                    <b-nav-item @click="selectPage('signin',$event)">
                      <b-icon icon="box-arrow-in-right" aria-hidden="true" style="margin-bottom: 4px;"></b-icon>
                    </b-nav-item>
                  </b-navbar-nav > -->


        </div>
      </div>
      <div class="header__mobile-menu">
        <div @click="toggleSidebar(!sidebar)" class="hamburger w-100">
          <i v-if="!sidebar">
            <font-awesome-icon icon="fas fa-bars" size="lg" />
          </i>
          <i v-else>
            <font-awesome-icon icon="fas fa-close" size="lg" />
          </i>
        </div>
      </div>
      <div class="header__menu">
        <div @click="toggleHeaderMenu" class="hamburger w-100">
          <i>
            <font-awesome-icon icon="fas fa-ellipsis-vertical" size="lg" />
          </i>
        </div>
      </div>
    </div>

    <newWorkbenchModal />
    <invitedWorkbenchesModal />
  </div>
</template>

<script>
import { host } from "../models/host";
import newWorkbenchModal from "./headerModalNWB.vue";
import invitedWorkbenchesModal from "./headerModalInvited.vue";
import flagUSA from "@/assets/images/flagUSA.svg";
import flagIRAN from "@/assets/images/flagIRAN.svg";
import obama from "@/assets/images/obama.jpg";
import { mapGetters } from "vuex";
import { L10n, setCulture, enableRtl } from "@syncfusion/ej2-base";
import { persianLacale } from "@/lacale/persian";

export default {
  name: "header",
  components: {
    newWorkbenchModal,
    invitedWorkbenchesModal
  },
  data() {
    return {
      avatarUrl: null,
      iselectedPage: [],
      color0: "white",
      color1: "transparent",
      color2: "transparent",

      selectedScenario: null,

      dropdownWBItems: [],

      headerMenu: false,


      dropdownLngItems: [
        { eng: "English", per: "انگلیسی", label: "English", abrev: "En", imageUrl: flagUSA, value: "eng" },
        { eng: "Persian", per: "فارسی", label: "فارسی", abrev: "فا", imageUrl: flagIRAN, value: "per" }
      ],

      profile: { name: "احمدرضا شفیعی", imageUrl: obama },

      selectedWorkbench: "محرکه گستر آرشید",
      selectedView: null,
      optionsView: [{ text: "Please select an View", value: null },
        { text: "Map View", value: "Map" },
        { text: "List View", value: "List" }],

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        waiting: { eng: "waiting", per: "در انتظار" },
        profile: { eng: "profile settings", per: "تنظیمات پروفایل" },
        signout: { eng: "sign out", per: "خروج" },
        selectWB: { eng: "Workbench selection", per: "انتخاب میزکار" },
        withoutWB: { eng: "without Workbench", per: "بدون میزکار" },
        selectLang: { eng: "Language selection", per: "انتخاب زبان" },
        createWB: { eng: "create new workbench", per: "ایجاد میزکار جدید" }
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.data.user;
    },
    myworkBench() {
      return this.$store.state.data.workbench;
    },
    workBenches() {
      if (this.myworkBench) {
        return this.myworkBench.workbenches;
      } else
        return [];
    },
    activeWorkbench() {
      if (this.myworkBench) {
        return this.myworkBench.activeWorkbench;
      } else
        return {};
    },
    headerColor() {
      if (this.currentUser)
        return this.currentUser.theme.headerColor;
      else
        return "";
    },
    headerFont() {
      if (this.currentUser)
        return this.currentUser.theme.headerFont;
      else
        return "dark";
    },
    headerClasses() {
      const classes = {
        "header-shadow": true
      };

      // if (this.headerMenu){
      //   classes['header-menu-open'] = true
      //   console.log('update header menu')
      // }else{
      //   classes['header-menu-open'] = false

      // }

      classes["header-menu-open"] = this.headerMenu;


      if (this.dict.dir[this.lang] === "rtl")
        classes["header-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr")
        classes["header-ltr"] = true;

      if (this.headerColor) {
        classes[this.headerColor] = true;
      } else {
        classes["bg-default-header"] = true;
      }

      if (this.headerFont) {
        classes[this.headerFont + "-font"] = true;
      } else {
        classes["dark-font"] = true;
      }

      if (this.lang === "per") {
        classes["text-end"] = true;
      } else {
        classes["text-start"] = true;
      }
      return classes;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    sidebar() {
      return this.$store.state.chpge.sidebar;
    },
    updateAvatars() {
      return this.$store.state.data.avatarUpdated;
    }
  },
  mounted() {
    this.$store.dispatch("chpge/changeLanguage", this.$store.state.data.user.settings.default_language);
    this.getAvatar();

    // this.getWBdata()
    // this.getUserData()

  },
  watch: {
    workBenches(newValue) {
      var waitingWB = this.workBenches.filter(x => (x.status === "waiting"));
      if (waitingWB.length > 0) {
        this.$store.dispatch("chpge/parent2child", { task: "invitedWBs", items: waitingWB });
        this.$root.$emit("bv::show::modal", "modal-invite-user");
      }
    },
    updateAvatars() {
      this.getAvatar();
    }
  },
  methods: {
    getAvatar() {
      this.$store
        .dispatch("data/getUsersAvatar", { id: this.currentUser.id })
        .then((response) => {
          if (response != null) {
            this.avatarUrl = response;
          }
        });
    },
    host() {
      return this.host;
    },
    logOut() {
      this.$store.dispatch("panel/closeSse");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("data/removeUserData");
      this.$store.dispatch("chpge/changePage", "signin");

    },
    selectPage: function(page) {
      this.$store.dispatch("chpge/changePage", page);
    },
    changeLanguage(lang) {
      this.$store.dispatch("chpge/changeLanguage", lang);
      setCulture(lang);
      L10n.load({
        "per": persianLacale
      });

    },
    // changeWorkbench(id){
    //   console.log(id)
    //   this.workBenches.forEach(element => {
    //     if (element.id === id)
    //       element.active = true
    //     else
    //       element.active = false
    //   });
    //   console.log(this.workBenches)
    // },


    changeWorkbench(id) {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          var data = { id: id };
          // console.log(data)
          this.$store.dispatch("data/selectWB", data).then((response) => {
              this.getWBdata(this.activeWorkbench);
            this.getUserData()
            },
            error => {
              // this.message =
              //   (error.response && error.response.data && error.response.data.message) ||
              //   error.message ||
              //   error.toString();
            }
          );
        }
      });
    },
    getWBdata(activeWorkbench) {
      this.$store.dispatch("data/getWBdata").then(
        (response) => {
          this.$store.dispatch("panel/closeSse");
          this.$store.dispatch("panel/sseSubscription");
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      // }else{
      //   console.log("you don't have enough permission")
      // }
    },
    getUserData() {
      // console.log('getUserData')
      this.$store.dispatch("data/getUserData").then(
        (response) => {
          // console.log('============user data',response)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    showWaitingInv(waitingWB) {

      this.$store.dispatch("chpge/parent2child", { task: "invitedWBs", items: waitingWB });
      this.$root.$emit("bv::show::modal", "modal-invite-user");
    },
    createWorkbench() {
      // this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:'pubsub'})
      this.$root.$emit("bv::show::modal", "modal-new-workbench");
    },
    toggleSidebar(data) {
      this.$store.dispatch("chpge/lockSidebar", false);
      this.$store.dispatch("chpge/toggleSidebar", data);
    },
    toggleHeaderMenu() {
      this.headerMenu = !this.headerMenu;
    }
  }
};
</script>

<style scoped>
.header-rtl, .header-ltr {
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 10;
  transition: all .2s;
  position: fixed;
  width: 100%;
}

.header-rtl .logo-src, .header-ltr .logo-src {
  display: none;
}

.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.header-ltr .header__content {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 280px;
  height: 60px;
  transition: all .2s;
}

.header-rtl .header__content {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 280px;
  height: 60px;
  transition: all .2s;
}

.header-ltr .header__content .header-left {
  display: flex;
  align-items: center;
  padding: 0 0 0 1.5rem;
}

.header-rtl .header__content .header-left {
  display: flex;
  align-items: center;
  padding: 0 1.5rem 0 0;
}

.header-ltr .header__content .header-right {
  align-items: center;
  display: flex;
  margin-left: auto;
  padding: 0 1.5rem 0 0;
}

.header-rtl .header__content .header-right {
  align-items: center;
  display: flex;
  margin-right: auto;
  padding: 0 0 0 1.5rem;
}

.header__menu, .header__mobile-menu {
  display: none;
  padding: 0 1.5rem;
  height: 60px;
  width: 20%;
  align-items: center;
}

.dropdown-item-content {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.header__mobile-menu .hamburger, .header__menu .hamburger {
  color: #5b98e9;
  cursor: pointer;
  font-size: x-large;
  text-align: center;
}

.header__mobile-menu .hamburger :hover,
.header__menu .hamburger :hover {
  color: #9b9b9b;
}


@media (max-width: 991.98px) {
  .header-rtl, .header-ltr {
    justify-content: space-between;
    z-index: 25;
  }

  .header-rtl .logo-src, .header-ltr .logo-src {
    display: block;
    order: 2;
  }

  .header-rtl .header__content, .header-ltr .header__content {
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    position: absolute;
    left: 2%;
    width: 96%;
    height: 80px;
    top: 0;
    transition: all .2s;
    background: #ffffff;
    border-radius: 10px;
    justify-content: center;
    //padding: 0 10px !important;
    /* overflow: hidden; */
  }

  .header-ltr .header__content .header-left {
    padding-left: 0;
  }

  .header-rtl .header__content .header-left {
    padding-right: 0;
  }

  .header-ltr .header__content .header-right {
    padding-right: 0;
  }

  .header-rtl .header__content .header-right {
    padding-left: 0;
  }


  .header-menu-open .header__content {
    visibility: visible;
    opacity: 1;
    top: 65px;
  }

  .header-ltr .header__mobile-menu,
  .header-rtl .header__mobile-menu {
    display: flex;
    order: 1;
    width: 20%;
  }

  .header-ltr .header__menu,
  .header-rtl .header__menu {
    display: flex;
    order: 3;
  }
}
</style>

<style>
.workbench-dropdown {
  min-width: 20rem !important;
  padding: 0;
}

.workbench-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/abstract3.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.workbench-dropdown .dropdown-header .background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(58, 248, 0, 0.527); /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.workbench-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.workbench-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.workbench-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 20rem !important;
  padding: 0;
  border-radius: 10px;
}

.workbench-dropdown .dropdown-toggle {
  border: none; /* Remove the border */
  background-color: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light-font .workbench-dropdown .dropdown-toggle:hover {
  color: white;
}

.dark-font .workbench-dropdown .dropdown-toggle:hover {
  color: black;
}

.workbench-dropdown.show .dropdown-toggle {
  border: '1px solid #ced4da' !important;
  background-color: transparent;
  color: inherit
}

.workbench-dropdown .btn-check:focus + .btn,
.workbench-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}


.language-dropdown {
  min-width: 10rem !important;
  padding: 0;
}

.language-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/changeLang.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.language-dropdown .dropdown-header .background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77); /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.language-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.language-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.language-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 10rem !important;
  padding: 0;
  border-radius: 10px;
}

.language-dropdown .btn-check:focus + .btn,
.language-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}

.profile-dropdown {
  min-width: 20rem !important;
  padding: 0;
}

.profile-dropdown .dropdown-header .background-container {
  position: relative;
  background-image: url("../assets/images/3photo.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%; /* Adjust as needed */
  border-radius: inherit;
}

.profile-dropdown .dropdown-header .background-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Adjust opacity here */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: inherit;
}

.profile-dropdown .dropdown-header .background-content {
  position: relative;
  z-index: 1; /* Place content above pseudo-element overlay */
  padding: 20px;
  color: rgb(255, 255, 255) !important;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.profile-dropdown .dropdown-header {
  padding: 0px;
  background-color: aqua;
  height: 5rem;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.profile-dropdown .dropdown-menu {
  text-align: inherit;
  min-width: 20rem !important;
  padding: 0;
  border-radius: 10px;
}

.profile-dropdown .btn-check:focus + .btn,
.profile-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}


</style>

<style scoped
       src="@/assets/styles/theme.css">
</style>