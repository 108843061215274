<template>
  <div
    :dir="dict.dir[lang]"
    :class="{ 'text-end': lang == 'per', 'text-start': lang == 'eng' }"
  >
    <div :dir="dict.dir[lang]">
      <b-container fluid="md">
        <b-row class="my-5">
          <b-col cols="1"></b-col>
          <b-col cols="10" sm>
            <b-card no-body>
              <b-card-body>
                <div
                  style="display: flex; align-items: center; flex-wrap: wrap"
                >
                  <h4 style="flex: 90%">{{ title }}</h4>
                  <b-button
                    type="submit"
                    @click="handleRegister"
                    variant="outline-success"
                    class="mr-2"
                    style="
                        padding: 0px;
                        flex: 1%;
                        outline: none !important;
                        border: none;
                        box-shadow: none;
                      "
                  >
                    <b-icon
                      icon="check-square-fill"
                      font-scale="2"
                      class="border rounded p-1"
                      aria-hidden="true"
                    ></b-icon>
                  </b-button>
                  <b-button
                    type="reset"
                    @click="onReset"
                    variant="outline-danger"
                    class="mr-2"
                    style="
                        margin-left: 10px;
                        padding: 0px;
                        flex: 1%;
                        outline: none !important;
                        border: none;
                        box-shadow: none;
                      "
                  >
                    <b-icon
                      icon="x-square-fill"
                      font-scale="2"
                      class="border rounded p-1"
                      aria-hidden="true"
                    ></b-icon>
                  </b-button>
                </div>

                <div class="row mx-auto" id="slot_submit">
                  <div class="col-md-6 col-12 my-3">
                    <ejs-textbox
                      :value="slot.name"
                      :placeholder="dict.name[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      :minLength="3"
                      :maxLength="20"
                      floatLabelType="Auto"
                      v-model="slot.name"
                    ></ejs-textbox>
                  </div>
                  <div class="col-md-6 col-12 my-3">
                    <ejs-textbox
                      :value="slot.address"
                      :placeholder="dict.address[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      :min="1"
                      :max="15"
                      type="number"
                      floatLabelType="Auto"
                      v-model="slot.address"
                    ></ejs-textbox>

                  </div>
                  <div class="col-md-6 col-12 my-3">
                    <ejs-dropdownlist
                      :dataSource="channelsList"
                      :value="slot.channels"
                      floatLabelType="Auto"
                      :fields="{ text: lang, value: 'value' }"
                      :placeholder="dict.type[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      @change="(val) => {slot.channels = val.value}"
                      :enabled="type != 'Edit'"
                    ></ejs-dropdownlist>

                  </div>
                  <div class="col-md-6 col-12 my-3">
                    <label>{{ dict.startup_delay[lang] }}</label>
                    <ejs-slider
                      :limits="{ enabled: true, minStart: 0, minEnd: 60 }"
                      :showButtons="true"
                      v-model="slot.onDelay"
                      :step="1"
                      :enableRtl="lang=='per'"
                      :min="0"
                      :max="60"
                      :value="slot.onDelay"
                      :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                      :ticks="{ placement: 'After', largeStep: 10, showSmallTicks: false }"
                      :type="'MinRange'"></ejs-slider>

                  </div>
                </div>

              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { FormValidator } from "@syncfusion/ej2-inputs";
export default {
  name: "slotEdit",
  data() {
    return {
      dict: {
        enterName: { per: "نام را وارد کنید", eng: "Enter name" },
        enterAddress: { per: "نام را وارد کنید", eng: "Enter address" },
        enterType: { per: "نوع را وارد کنید", eng: "Enter type" },
        enterStartup_delay: { per: "تاخیر در شروع را وارد کنید", eng: "Enter startup delay" },
        name: { per: "نام", eng: "name" },
        address: { per: "آدرس", eng: "address" },
        type: { per: "نوع", eng: "type" },
        "Edit Slot": { per: "ویرایش اسلات", eng: "Edit Slot" },
        "َAdd Slot": { per: "ایجاد اسلات", eng: "َAdd Slot" },
        startup_delay: {
          per: "تاخیر در شروع", eng: "startup delay"
        },
        dir: { eng: "ltr", per: "rtl" }
      },
      slot: {
        parent: "",
        name: "",
        address: "",
        channels: null,
        onDelay: "0",
        position: "",
        id: "",
        type: ""
      },

      //    channelsList: [{ text: 'Select One', value: null },
      //                 {text:'4 channels',value:'4'},
      //                 {text:'8 channels',value:'8'},
      //                 {text:'16 channels',value:'16'},
      //                 ],

      channelsList: [{ eng: "Select One",per: "یک مورد انتخاب کنید", value: null },
        { per: "8 Channels Digital Input",eng: "8 Channels Digital Input", value: "slot_DI8" },
        { per: "16 Channels Digital Input",eng: "16 Channels Digital Input", value: "slot_DI16" },
        { per: "8 Channels Digital Output",eng: "8 Channels Digital Output", value: "slot_DO8" },
        { per: "16 Channels Digital Output",eng: "16 Channels Digital Output", value: "slot_DO16" },
        { per: "4 Channels Digital Output with Push-button",eng: "4 Channels Digital Output with Push-button", value: "slot_DO4pb" },
        { per: "8 Channels Digital Output with Push-button",eng: "8 Channels Digital Output with Push-button", value: "slot_DO8pb" },
        { per: "16 Channels Digital Output with Push-button",eng: "16 Channels Digital Output with Push-button", value: "slot_DO16pb" },
        { per: "4 Channels Digital Input + 4 Channels Digital Output",eng: "4 Channels Digital Input + 4 Channels Digital Output", value: "slot_DI4DO4" },
        { per: "8 Channels Digital Input + 8 Channels Digital Output",eng: "8 Channels Digital Input + 8 Channels Digital Output", value: "slot_DI8DO8" },
        { per: "4 Channels Analoge Input",eng: "4 Channels Analoge Input", value: "slot_AI6" },
        { per: "4 Channels Analoge Output",eng: "4 Channels Analoge Output", value: "slot_AO6" },
        { per: "4 Channels RTD",eng: "4 Channels RTD", value: "slot_RTD4" }
      ],

      submitted: false,
      successful: false,
      message: "",
      title: ""
    };
  },
  computed: {
    type() {
      return this.$store.state.chpge.type;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    db() {
      return this.$store.state.chpge.db;
    },
    index() {
      return this.$store.state.chpge.index;
    }
  },
  methods: {
    initModal() {
      this.title = this.type + " Slot";

      if (this.type === "Add") {
        this.slot.parent = { ptype: this.db.ptype, pid: this.db.pid };
      } else if (this.type === "Edit") {
        // this.slot.slaveId = this.db.id;
        this.slot.name = this.db.name;
        this.slot.address = this.db.address;
        this.slot.channels = this.db.channels;
        this.slot.onDelay = this.db.onDelay;
        this.slot.id = this.db.id;
        this.slot.position = this.db.position;
      }
    },
    onReset() {
      this.slot.name = null;
      this.slot.address = null;
      this.slot.id = null;
      this.slot.channels = null;
      this.slot.onDelay = null;
      this.$store.dispatch("chpge/changePage", "controllerSettings");
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#slot_submit").validate();
      if (isValid) {
        if (this.type === "Add") {
          this.slot.type = this.slot.channels;
          this.$store.dispatch("data/createSlot", this.slot).then(
            data => {

              this.message = data.message;
              this.successful = true;
              // alert(this.message);
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        } else if (this.type === "Edit") {
          this.slot.type = this.slot.channels;
          this.$store.dispatch("data/editSlot", this.slot).then(
            data => {

              this.message = data.message;
              this.successful = true;
              //alert(this.message)
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      }
    }
  },
  mounted() {
    this.initModal();
  }
};
</script>
