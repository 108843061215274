import { render, staticRenderFns } from "./widgetCtrlLighting.vue?vue&type=template&id=3f80fed8&scoped=true&"
import script from "./widgetCtrlLighting.vue?vue&type=script&lang=js&"
export * from "./widgetCtrlLighting.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/ledStyle.css?vue&type=style&index=0&id=3f80fed8&scoped=true&lang=css&"
import style1 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=1&lang=css&"
import style2 from "./widgetCtrlLighting.vue?vue&type=style&index=2&lang=css&"
import style3 from "./widgetCtrlLighting.vue?vue&type=style&index=3&id=3f80fed8&scoped=true&lang=css&"
import style4 from "./widgetCtrlLighting.vue?vue&type=style&index=4&id=3f80fed8&scoped=true&lang=css&"
import style5 from "./widgetCtrlLighting.vue?vue&type=style&index=5&id=3f80fed8&scoped=true&lang=css&"
import style6 from "./widgetCtrlLighting.vue?vue&type=style&index=6&lang=css&"
import style7 from "./widgetCtrlLighting.vue?vue&type=style&index=7&id=3f80fed8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f80fed8",
  null
  
)

export default component.exports