import DataService from "@/services/data.service";

const initialState = {
  processList: [],
  parent: {
    pipes: [],
    elements: []
  },
  paperSetting: {
    gridType: "dot",
    gridSize: 10,
    color: "#ff0000"
  },
  portSetting: {
    shape: "",
    type: "",
    position: { name: "right" },
    args: {},
    size: {
      x: 0,
      y: 0
    }
  },
  linkSetting: {
    color: [],
    router: "",
    width: ""
  },
  shapes: [],
  gallery: [],
  theElementPorts: [],
  currentElement: "",
  chosenElement: "",
  imgElement: {},
  clearWorkspace: false,
  processModalStatus: ""
};

export const processFlow = {
  state: initialState,
  getters: {
    paperWatcher: (state) => {
      return state.parent;
    },
    processWatcher: (state) => {
      return state.processModalStatus;
    },
    paperSettingWatcher: (state) => {
      return state.paperSetting;
    },
    portSettingWatcher: (state) => {
      return state.portSetting;
    },
    linkSettingWatcher: (state) => {
      return state.linkSetting;
    },
    theElementPortsWatcher: (state) => {
      return state.theElementPorts;
    },
    newElementWatcher: (state) => {
      return state.currentElement;
    },
    galleryRender: (state) => {
      return state.imgElement;
    },
    processListGetter: (state) => {
      return state.processList;
    }
  },
  mutations: {
    processStatusChanger(state, value) {
      state.processModalStatus = value;
    },
    selectElement(state, value) {
      state.currentElement = value;
    },
    imaCreator(state, value) {
      state.imgElement = value;
    },
    chooseElement(state, value) {
      state.chosenElement = value;
    },
    updateParent(state, value) {
      if (!Array.isArray(value)) {
        state.parent.elements.forEach((item) => {
          if (item.id == value.id) {
            item.attrs = value.attributes;
            item.name = value.attributes.type;
          }
        });
      }

    },
    updateParentPipe(state, value) {
      if (!Array.isArray(value)) {
        state.parent.pipes.forEach((item) => {
          if (item.id == value.id) {
            item.attrs = value.attributes;
            item.name = value.attributes.type;
          }
        });
      }

    },

    updateParentPipeEdit(state, value) {
      state.parent.pipes.push(value);
    },
    createParentElement(state, value) {
      state.parent.elements.push({
        name: value.attributes.type,
        attrs: value.attributes,
        id: value.attributes.id
      });
    },
    createParentPipes(state, value) {
      state.parent.pipes.push({
        name: value.attributes.type,
        attrs: value.attributes,
        id: value.attributes.id
      });
    },
    removeLinkFromParent(state, value) {
      state.parent.pipes.forEach((item) => {
        if (item.id == value)
          // console.log(item);
          state.parent.pipes.splice(state.parent.pipes.indexOf(item), 1);
      });
    },
    removeElementFromParent(state, value) {
      state.parent.elements.forEach((item) => {
        if (item.id == value)
          state.parent.elements.splice(state.parent.elements.indexOf(item), 1);
      });
    },
    updateClearWorkSpace(state, value) {
      state.clearWorkspace = value;
    },
    updateGallery(state, value) {
      state.gallery.push(value);
    },
    updatePaper(state, value) {
      state.paperSetting[value.name] = value.val;
    },
    updateLink(state, value) {
      state.linkSetting = value;
    },
    updateTheElementPorts(state, value) {
      state.theElementPorts = value;
    },
    updateProcessList(state, value) {
      state.processList = value;
    },
    clearParentElement(state){
      state.parent.elements=[]
      state.parent.pipes=[]
    }

  },
  actions: {
    createNew({ commit }, data) {
      return DataService.createNewProcess(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    editProcess({ commit }, data) {
      return DataService.editProcess(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    removeProcess({ commit }, data) {
      return DataService.removeProcess(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    getAllProcess({ commit }, data) {
      return DataService.getProcessList().then(
        (response) => {
          commit("updateProcessList", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    },
    getProcessById({ commit }, data) {
      return DataService.getProcessById(data).then(
        (response) => {
          // commit('getDataSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit('getDataFailure');
          return Promise.reject(error);
        }
      );
    }
  }
};
