import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user, allUsers:null }
  : { status: { loggedIn: false }, user: null, allUsers:null};

export const auth = {
  namespaced: true,
  state: initialState,

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    extendLogin({ commit }) {
      return AuthService.extendLogin().then(
        response => {
          commit('loginSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    captcha({ commit }) {
      return AuthService.captcha().then(
        captcha => {
          return Promise.resolve(captcha);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    edit({ commit }, user) {
      return AuthService.edit(user).then(
        response => {
          //commit('editSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          //commit('editFailure');
          return Promise.reject(error);
        }
      );
    },
    remove({ commit }, user) {
      return AuthService.remove(user).then(
        response => {
          //commit('editSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          //commit('editFailure');
          return Promise.reject(error);
        }
      );
    },
    getAllUsers({ commit }) {
      return AuthService.getAllUsers().then(
        response => {
          commit('getAllUsersSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('getAllUsersFailure');
          return Promise.reject(error);
        }
      );
    },

    // createWorkbench({ commit }, wb) {
    //   return AuthService.createWorkbench(wb).then(
    //     uwb => {
    //       commit('updateWorkbench', uwb.data);
    //       return Promise.resolve(uwb);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    // selectWorkbench({ commit }, wb) {
    //   return AuthService.selectWorkbench(wb).then(
    //     uwb => {
    //       commit('updateWorkbench', uwb.data);
    //       return Promise.resolve(uwb);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    selectFloor({ commit, state},selectedFloor) {
      commit('selectFloor',selectedFloor);

      return AuthService.editPanel(state.user).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    selectView({ commit, state },selectedView) {
      commit('selectView',selectedView);

      return AuthService.editPanel(state.user).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }



    
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      if (user.accessToken) {
        // var activeWorkbench = {};
        // if (user.workBenches.length>0){
        //   let indx = user.workBenches.findIndex(x=>(x.active))  

        //   if (indx !== -1){
        //     activeWorkbench = user.workBenches[indx] 
        //   }
        // }
        // user.activeWorkbench = activeWorkbench

        localStorage.setItem('user', JSON.stringify(user));
      }
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    getAllUsersSuccess(state, allUsers) {
      state.allUsers = allUsers;
    },
    getAllUsersFailure(state) {
      state.allUsers = null;
    },
    selectFloor(state,selectedFloor){
      state.user.selectedFloor = selectedFloor;
    },
    selectView(state,selectedView){
      state.user.selectedView = selectedView;
    },
    updateWorkbench(state,UWB){      
      var activeWorkbench = {};
      if (UWB.length>0){
        let indx = UWB.findIndex(x=>(x.active))  
        if (indx !== -1){
          activeWorkbench = UWB[indx]
        }
      }
      state.user.workBenches = UWB;
      state.user.activeWorkbench = activeWorkbench;
      localStorage.setItem('user', JSON.stringify(state.user));
    }
  }
};
