<template>
  <div id="show-body" :dir="dict.dir[lang]" style="height:100%; width:100%;">
    <div v-if="dataReady" class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-6" :class="lang === 'per' ? 'text-end' : 'text-start'">
          <p class="mb-0">
            {{ myData.attribute.title }}
          </p>
        </div>
        <!-- <div class="col-8 "></div> -->
        <div class="col-6 ">
          <div class="d-flex w-100 justify-content-end">
            <div class="d-flex" style="gap:5px; width:50px;">
              <span v-if="!myData.status.enable" class="remove w-100 text-center" @click="changeTab">
                <i v-if="activeTab==='tab1'" style="color:rgb(112, 112, 113)"><font-awesome-icon
                  icon="fas fa-gear" /></i>
                <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i>
              </span>
            </div>
            <div class="form-check form-switch d-flex justify-content-center" style="width:50px">
              <input class="form-check-input" type="checkbox" value="" id="CBEn" v-model="myData.status.enable"
                     @change="onChangeStatus()" :disabled="activeTab==='tab2'">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="toast-container position-fixed top-50 start-50 translate-middle p-3" style="z-index: 1100;">
      <Notification
        v-for="(notice,i) in  pannel.status.notices.filter(item => !item.shown)"
        :id="notice.id"
        :key="'mga_notification_'+i"
        :message="dict[notice.message][lang]"
        :type="notice.type"
        :title="dict[notice.type][lang]"
        timeout="15000000"
        @change="(value) => {handleNoticeChanges(notice.id,value)}"
      ></Notification>
    </div>

    <div v-if="dataReady" class="card-body p-1 d-flex justify-content-center align-items-center"
         style="height: calc(100% - 40px); width:100%; overflow: hidden; ">


      <transition :name="dict.transition[lang]" mode="out-in">
        <div class="tab-content h-100 w-100" :key="activeTab">
          <div v-if="activeTab === 'tab1'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <div class="h-100 w-100 p-0" style="display:flex; flex-direction:column; ">
                <div style="min-height:100px;">
                  <div class="radiobox" style="height:70%">
                    <label class="radiobtn" v-for="opt in controlOptions" :key="opt.value">
                      <input class="radioinp" type="radio" v-model="myData.parameters.controlMode" :value="opt.value"
                             :name="plotId" @change="changeControlMode" />
                      <span v-if="opt.text==='Manual'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-hand-paper" />
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">{{ dict.controlMode1[lang] }}</p>
                        </div>
                      </span>
                      <span v-if="opt.text==='Schedule'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <div style="display: flex;justify-content: center;">
                            <font-awesome-icon icon="fas fa-calendar-days" />
                          </div>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">{{ dict.controlMode2[lang] }}</p>
                        </div>
                      </span>

                    </label>
                  </div>
                  <div class="radiobox" style="height:30%">
                    <i v-if="myData.parameters.controlMode ==='schedule'">
                      <font-awesome-icon icon="fas fa-lock" />
                    </i>
                    <i v-else>
                      <font-awesome-icon icon="fas fa-lock-open" />
                    </i>
                    <div v-if="myData.parameters.controlMode==='schedule'">
                      <div v-if="pannel.scheduleTitle!=='  '">
                        {{ pannel.scheduleTitle }}
                      </div>
                      <div v-else>
                        <font-awesome-icon icon="fas fa-calendar-days" fade style="color:red" />
                      </div>
                    </div>
                  </div>
                </div>

                <div :style="{minHeight:pannel.sliderHeight+'px', height:'calc(100% - 100px)', borderRadius:'5px'}">
                  <PerfectScrollbar>

                    <div v-if="myData.parameters.controlMode==='schedule' && !pannel.settingVis"
                         style="height:100%; display:flex; justify-content:center; align-items:center">
                      {{dict.noEvent[lang]}}
                    </div>

                    <div v-else-if="myData.parameters.controlMode==='manual'"
                         style="height:100%; width:100%; padding:10px;">
                      <div
                        style="width:100%; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">
                        <div class="row row-cols-1 row-cols-md-2 justify-content-between">
                          <div class="col col-md-6">
                            <div>
                              <component
                                :is="'DropDownList'"
                                :id="'mga_SldSet_1'"
                                :key="'mga_SldSet_1'"
                                :dataSource="pannel.settingsOptions"
                                :value="pannel.selectedSetting"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.selSetting[lang]"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {pannel.selectedSetting = value}"
                                :validatorInstance="validator1"
                                :validatorRules="{ required: true }"
                              ></component>
                            </div>
                          </div>
                          <div class="col col-md-6">
                            <div>
                              <component
                                :is="'DropDownList'"
                                :id="'mga_SldSet_2'"
                                :key="'mga_SldSet_2'"
                                :dataSource="dataEntryOptions"
                                :value="pannel.manual.intParam"
                                :fields="{ text: lang, value: 'value' }"
                                :placeholder="dict.inputType[lang]"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {pannel.manual.intParam = value}"
                                :validatorInstance="validator1"
                                :validatorRules="{ required: true }"
                              ></component>
                            </div>
                          </div>


                          <!-- <div class="col col-md-6">
                            <label class="form-check-label" for="inputTypeWrapper">
                              {{dict.inputType[lang]}}
                            </label>
                            <div id="inputTypeWrapper">
                              <div  class="form-check" :class="dict.dir[lang]">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  {{dict.inputType1[lang]}}
                                </label>
                                <input class="form-check-input" :class="dict.dir[lang]" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="pannel.manual.intParam" value='true'>

                              </div>
                              <div class="form-check" :class="dict.dir[lang]">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  {{dict.inputType2[lang]}}
                                </label>
                                <input class="form-check-input" :class="dict.dir[lang]" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="pannel.manual.intParam" value='false'>
                              </div>
                            </div>
                          </div> -->
                        </div>

                        <div v-if="pannel.manual.intParam==='true'">
                          <div v-for="i in Math.floor((pannel.manual.inpPars.length-1)/4)+1" :key="'INP_R_'+i"
                               class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                            <div
                              v-for="j in  i<Math.floor((pannel.manual.inpPars.length-1)/4)+1? 4:(pannel.manual.inpPars.length%4>0? pannel.manual.inpPars.length%4:4)"
                              :key="'INP_F_'+(i*4+j-5)" class="col">
                              <component
                                :is="'TextBox'"
                                :key="'mga_inp_'+(4*i+j-5)"
                                :id="'mga_inp_'+(4*i+j-5)"
                                :value="pannel.manual.inputs[pannel.manual.inpPars[4*i+j-5]]"
                                :placeholder="pannel.manual.inpPars[4*i+j-5]"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {pannel.manual.inputs[pannel.manual.inpPars[4*i+j-5]] = value}"
                                :validatorInstance="validator1"
                                :validatorRules="{  required: true, range: [[0,1000000], 'The value should be between 0 and 1000000'] }"
                              ></component>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="row justify-content-end">
                          <div class="col col-md-6 col-lg-4 col-xl-3">
                            <button type="button" class="btn btn-primary btn-lg w-100 h-100" @click="saveData('manPanel')" :disabled="!myData.status.enable" >{{dict.startBtn[lang]}}</button>
                          </div>
                        </div> -->

                        <div class="row justify-content-end">
                          <div class="col col-md-6 col-lg-4 col-xl-3">
                            <button type="button" class="btn btn-primary btn-lg w-100 h-100" @click="search"
                                    :disabled="!myData.status.enable || pannel.manual.find==='1'">
                              <span v-if="pannel.manual.find==='1'">{{ dict.startBtn2[lang] }}</span>
                              <span v-else>{{ dict.startBtn1[lang] }}</span>
                              <span v-if="pannel.manual.find==='1'" class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                            </button>
                          </div>
                        </div>
                      </div>


                      <div v-if="pannel.status.results.length>0"
                           style="margin-top:20px; width:100%; borderRadius:5px; display:flex; flex-direction:column; gap:10px;">
                        <div v-for="i in Math.floor((pannel.status.results.length-1)/6)+1" :key="'RES_R_'+i"
                             class="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-2">
                          <div
                            v-for="j in  i<Math.floor((pannel.status.results.length-1)/6)+1? 6:(pannel.status.results.length%6>0? pannel.status.results.length%6:6)"
                            :key="'RES_C_'+(i*6+j-7)" class="col">
                            <div class="widget" @click="openResultModal(i*6+j-7)">
                              <div class="w-100"
                                   style="padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">
                                <div class="text-center fw-bold">{{ i * 6 + j - 6 }}</div>
                              </div>

                              <div v-for="(field, k) in pannel.manual.presPars1" :key="(i*6+j-7)+'_'+k"
                                   class="w-100 mt-2">
                                <div style="font-size:12px" class="w-100 text-center">{{ field }}</div>
                                <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">
                                  {{ pannel.status.results[i * 6 + j - 7][field] }}
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div  style="margin-top:20px; width:100%; padding:10px; borderRadius:5px; display:flex; flex-wrap:wrap; gap:10px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">
                        <div v-for="(item, i) in pannel.status.results" :key="i" class="widget"  @click="openResultModal(i)" >
                          <div class="w-100" style="padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3)">
                            <div class="text-center fw-bold">{{i+1}}</div>
                          </div>

                          <div v-for="(field, j) in pannel.manual.presPars1" :key="i+'_'+j" class="w-100 mt-2">
                            <div style="font-size:12px"  class="w-100 text-center">{{field}}</div>
                            <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">{{ item[field] }}</div>
                          </div>

                        </div>
                      </div> -->
                    </div>
                  </PerfectScrollbar>
                </div>
              </div>

            </div>
          </div>
          <div v-if="activeTab === 'tab2'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <div class="mga-tab h-100 p-0">
                <div class="mga-tab-header">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                    <li class="mga-tab-nav-item" @click="activeTabConf = 'tab1'">
                      <p class="mga-tab-nav-link"
                         :class="{ active: activeTabConf === 'tab1' }"
                         id="tab1-tab"
                         data-toggle="tab"
                         role="tab"
                         aria-controls="tab1"
                         aria-selected="true"
                      >
                        {{ dict.confTab1[lang] }}
                      </p>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabConf = 'tab2'">
                      <a class="mga-tab-nav-link"
                         :class="{ active: activeTabConf === 'tab2' }"
                         id="tab2-tab"
                         data-toggle="tab"
                         href="#tab2"
                         role="tab"
                         aria-controls="tab2"
                         aria-selected="true"
                      >
                        {{ dict.confTab2[lang] }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="mga-tab-body">
                  <transition :name="dict.transition[lang]" mode="out-in">
                    <div class="tab-content h-100" :key="activeTabConf">
                      <div v-if="activeTabConf === 'tab1'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100">
                                <b-table id="tableSchedule" :items="myData.parameters.schedules"
                                         :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small
                                         ref="scheduleTable" @row-clicked="onScheduleSelected">
                                  <template #cell(modify)="row">
                                    <div style="display:flex; justify-content:flex-end;">
                                      <b-button pill size="sm" variant="outline-success"
                                                @click="editSchedule(row.index,$event)" class="mr-2"
                                                style="outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                      </b-button>
                                      <b-button pill size="sm" variant="outline-danger"
                                                @click="removeSchedule(row.index,$event)" class="mr-2"
                                                style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                      </b-button>
                                    </div>
                                  </template>
                                  <template #cell(isSelected)="row">
                                    <input
                                      style="pointer-events: none"
                                      type="checkbox"
                                      :checked="row.item._id === myData.parameters.selectedSchedule"
                                    >
<!--                                    <template v-if="row.item._id===myData.parameters.selectedSchedule">-->
<!--                                      <span aria-hidden="true">&check;</span>-->
<!--                                      <span class="sr-only"></span>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                      <span aria-hidden="true">&nbsp;</span>-->
<!--                                      <span class="sr-only"></span>-->
<!--                                    </template>-->
                                  </template>
                                </b-table>
                                <div style="display:flex; justify-content: end; align-items: center;">
                                  <b-button @click="addSchedule" size="sm" variant="outline-danger"
                                            style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                    <i>
                                      <font-awesome-icon icon="fas fa-plus" />
                                    </i>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-if="activeTabConf === 'tab2'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  display:flex; flex-wrap:wrap; gap:10px; padding:10px ">
                              <div class="w-100">
                                <b-table :items="myData.parameters.settings" :fields="scheduleFields"
                                         thead-class="hidden_header" responsive="sm" small ref="controllerTable">
                                  <template #cell(modify)="row">
                                    <div style="display:flex; justify-content:flex-end;">
                                      <b-button pill size="sm" variant="outline-success"
                                                @click="editController(row.index,$event)" class="mr-2"
                                                style="outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                      </b-button>
                                      <b-button pill size="sm" variant="outline-danger"
                                                @click="removeController(row.index,$event)" class="mr-2"
                                                style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                      </b-button>
                                    </div>
                                  </template>
                                  <!-- <template #cell(isSelected)="row">
                                      <template v-if="JSON.stringify(row.item._id)===JSON.stringify(myData.parameters.selectedController)">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only"></span>
                                      </template>
                                      <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only"></span>
                                      </template>
                                    </template> -->
                                </b-table>
                                <div style="display:flex; justify-content: end; align-items: center;">
                                  <b-button @click="addController" size="sm" variant="outline-danger"
                                            style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                    <i>
                                      <font-awesome-icon icon="fas fa-plus" />
                                    </i>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <b-modal
      :id="plotId+'_control'"
      size="lg"
      body-class="WCSCM-body-class"
      centered

      content-class="shadow"

      @shown="initControlModal"
      @hidden="handleControlModalReset"
      @ok="handleControlModalOk"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <div><h5>{{ dict.contModalTitle[lang] }}</h5></div>
        </div>
      </template>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="handleControlModalReset">{{
                dict.cancel[lang]
              }}
            </b-button>
            <b-button variant="primary" @click="handleControlModalOk">{{
                dict.ok[lang]
              }}
            </b-button>
          </div>
        </div>
      </template>
      <div v-show="cdb.show">
        <div :dir="dict.dir[lang]" class="panel_body">
          <form id="control-body" class="form-horizontal" novalidate="">
            <div style="padding:10px">
              <div v-if="cdb.show" class="w-100">
                <component
                  :is="'TextBox'"
                  :id="'mga_CT'"
                  :value="cdb.title"
                  :placeholder="dict.contTitle[lang]"
                  :enableRtl="lang === 'per'"
                  @change="(value) => {cdb.title = value}"
                  :validatorInstance="cdb.validator"
                  :validatorRules="{  required: true, rangeLength:[[5,50], 'The Title should be between 5 and 50 characters'] }"
                ></component>
              </div>
            </div>
            <div class="p-0">
              <div class="mga-tab h-100 p-0">
                <div class="mga-tab-header">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">

                    <li class="mga-tab-nav-item" @click="activeTabCM = 'tab1'">
                      <p class="mga-tab-nav-link"
                         :class="{ active: activeTabCM === 'tab1' }"
                         id="tab1-tab"
                         data-toggle="tab"
                         role="tab"
                         aria-controls="tab1"
                         aria-selected="true"
                      >
                        {{ dict.tab1[lang] }}
                      </p>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabCM = 'tab2'">
                      <a class="mga-tab-nav-link"
                         :class="{ active: activeTabCM === 'tab2' }"
                         id="tab2-tab"
                         data-toggle="tab"
                         href="#tab2"
                         role="tab"
                         aria-controls="tab2"
                         aria-selected="true"
                      >
                        {{ dict.tab2[lang] }}
                      </a>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabCM = 'tab3'">
                      <a class="mga-tab-nav-link"
                         :class="{ active: activeTabCM === 'tab3' }"
                         id="tab2-tab"
                         data-toggle="tab"
                         href="#tab3"
                         role="tab"
                         aria-controls="tab3"
                         aria-selected="true"
                      >
                        {{ dict.tab3[lang] }}
                      </a>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabCM = 'tab4'">
                      <a class="mga-tab-nav-link"
                         :class="{ active: activeTabCM === 'tab4' }"
                         id="tab4-tab"
                         data-toggle="tab"
                         href="#tab4"
                         role="tab"
                         aria-controls="tab4"
                         aria-selected="true"
                      >
                        {{ dict.tab4[lang] }}
                      </a>
                    </li>
                    <li class="mga-tab-nav-item" @click="activeTabCM = 'tab5'">
                      <a class="mga-tab-nav-link"
                         :class="{ active: activeTabCM === 'tab5' }"
                         id="tab5-tab"
                         data-toggle="tab"
                         href="#tab5"
                         role="tab"
                         aria-controls="tab5"
                         aria-selected="true"
                      >
                        {{ dict.tab5[lang] }}
                      </a>
                    </li>
                    <!-- <li class="mga-tab-nav-item" @click="activeTabCM = 'tab6'">
                      <a  class="mga-tab-nav-link"
                        :class="{ active: activeTabCM === 'tab6' }"
                        id="tab6-tab"
                        data-toggle="tab"
                        href="#tab6"
                        role="tab"
                        aria-controls="tab6"
                        aria-selected="true"
                        >
                        {{dict.tab6[lang]}}
                      </a>
                    </li> -->
                  </ul>
                </div>
                <div class="mga-tab-body">
                  <transition :name="dict.transition[lang]" mode="out-in">
                    <div class="tab-content h-100" :key="activeTabCM">
                      <div v-show="activeTabCM === 'tab1'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%; padding:10px; display:flex; flex-direction:column; gap:10px; ">

                              <div :key="'FD_'+this.cdb.filterRender" v-if="cdb.show && cdb.data.FILTER.length>0"
                                   class="w-100 d-flex flex-column"
                                   style="gap:10px; padding-right: var(--bs-gutter-x, 0.75rem); padding-left: var(--bs-gutter-x, 0.75rem); margin-left: auto;">
                                <div v-for="i in cdb.data.FILTER.length" :key="'FILTER_R_'+i"
                                     class="w-100 row row-cols-1 row-cols-md-4 border-bottom">
                                  <div class="col col-md-4">
                                    <component
                                      :is="'DropDownList'"
                                      :key="'mga_FP_'+i"
                                      :id="'mga_FP_'+i"
                                      :dataSource="pannel.status.header"
                                      :value="cdb.data.FILTER[i-1].parameter"
                                      :fields="{ text: 'text', value: 'value' }"
                                      :placeholder="dict.filterData[lang]"
                                      :enableRtl="lang === 'per'"
                                      @change="(value) => {cdb.data.FILTER[i-1].parameter = value}"
                                      :validatorInstance="cdb.validator"
                                      :validatorRules="{ required: true }"
                                    ></component>
                                  </div>
                                  <div class="col col-md-4">
                                    <component
                                      :is="'DropDownList'"
                                      :key="'mga_FO_'+i"
                                      :id="'mga_FO_'+i"
                                      :dataSource="operatorList"
                                      :value="cdb.data.FILTER[i-1].operator"
                                      :fields="{ text: 'text', value: 'value' }"
                                      :placeholder="dict.filterOperator[lang]"
                                      :enableRtl="lang === 'per'"
                                      @change="(value) => {cdb.data.FILTER[i-1].operator = value}"
                                      :validatorInstance="cdb.validator"
                                      :validatorRules="{ required: true }"
                                    ></component>
                                  </div>
                                  <div class="col col-md-3">
                                    <component
                                      :is="'TextBox'"
                                      :key="'mga_FV_'+i"
                                      :id="'mga_FV_'+i"
                                      :value="cdb.data.FILTER[i-1].value"
                                      :placeholder="dict.filterValue[lang]"
                                      :enableRtl="lang === 'per'"
                                      @change="(value) => {cdb.data.FILTER[i-1].value = value}"
                                      :validatorInstance="cdb.validator"
                                      :validatorRules="{  required: true, range: [[0,1000000], 'The value should be between 0 and 1000000'] }"
                                    ></component>
                                  </div>

                                  <div class="col col-md-1 p-0">
                                    <div class="w-100 h-100 d-flex justify-content-end align-items-center">
                                      <b-button @click="removeFilter(cdb.data.FILTER[i-1].id)" size="sm"
                                                variant="outline-danger"
                                                style="width:20px; height:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                        <i>
                                          <font-awesome-icon icon="fas fa-minus" />
                                        </i>
                                      </b-button>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div style="display:flex; justify-content: end; align-items: center;">
                                <b-button @click="addFilter" size="sm" variant="outline-danger"
                                          style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                  <i>
                                    <font-awesome-icon icon="fas fa-plus" />
                                  </i>
                                </b-button>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>

                      <div v-show="activeTabCM === 'tab2'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%;  padding:10px; display:flex; flex-direction:column; gap:10px">
                              <div v-if="cdb.show && cdb.data.present" class="w-100">
                                <component
                                  :is="'MultiSelect'"
                                  :id="'mga_IMS'"
                                  :dataSource="pannel.status.header"
                                  :fields="{ text: 'text', value: 'value' }"
                                  :value="cdb.data.present.inpPars"
                                  :placeholder="dict.multiSel1[this.lang]"
                                  :enableRtl="lang === 'per'"
                                  @change="(value) => {cdb.data.present.inpPars = value}"
                                  :validatorInstance="cdb.validator"
                                  :validatorRules="{required: true}"
                                ></component>
                              </div>

                              <div v-if="cdb.show && cdb.data.present.inpPars.length>0" class="w-100">
                                <div v-for="i in Math.floor((cdb.data.present.inpPars.length-1)/3)+1"
                                     :key="'WEIGHT_R_'+i" class="w-100 row row-cols-1 row-cols-md-3">
                                  <div
                                    v-for="j in i<Math.floor((cdb.data.present.inpPars.length-1)/3)+1? 3:(cdb.data.present.inpPars.length%3>0? cdb.data.present.inpPars.length%3:3)"
                                    :key="'WEIGHT_C_'+(i*3+j-3)" class="col">
                                    <component
                                      :is="'TextBox'"
                                      :key="'mga_WTB'+(3*i+j-3)"
                                      :id="'mga_WTB'+(3*i+j-3)"
                                      :value="cdb.data.WEIGHT[cdb.data.present.inpPars[3*i+j-4]]"
                                      :placeholder="`${dict.weights[lang]} ${cdb.data.present.inpPars[3*i+j-4]}`"
                                      :enableRtl="lang === 'per'"
                                      @change="(value) => {cdb.data.WEIGHT[cdb.data.present.inpPars[3*i+j-4]] = value}"
                                      :validatorInstance="cdb.validator"
                                      :validatorRules="{  required: true, range: [[0,1000000], 'The value should be between 0 and 1000000'] }"
                                    ></component>
                                  </div>
                                </div>
                              </div>


                              <!-- <div v-if="cdb.show && cdb.data.present.inpPars.length>0" class="w-100">
                                  <div class="d-flex justify-content-between"  style="color:#113cfc; border-bottom: 1px solid rgb(17, 60, 252); margin-bottom:10px; padding:5px 0">
                                    {{dict.weights[lang]}}
                                  </div>
                                  <b-list-group v-for="i in Math.floor((cdb.data.present.inpPars.length-1)/3)+1" :key="'WEIGHT_R_'+i"  horizontal="md" style="gap:10px;">
                                    <b-form-group
                                      v-for="j in  i<Math.floor((cdb.data.present.inpPars.length-1)/3)+1? 3:(cdb.data.present.inpPars.length%3>0? cdb.data.present.inpPars.length%3:3)" :key="'WEIGHT_F_'+(i*3+j-3)"
                                      label-size="sm"
                                      :label="cdb.data.present.inpPars[3*i+j-4]"
                                      :label-for="'weight_'+(3*i+j-4)"
                                      :invalid-feedback="errors.first('weight_'+(3*i+j-4))"
                                      style="width:100%; font-size:6px;"
                                    >
                                      <b-form-input
                                        size="sm"
                                        :name="'weight_'+(3*i+j-4)"
                                        type="text"
                                        :placeholder="dict.contDataPH[lang]"
                                        v-model="cdb.data.WEIGHT[cdb.data.present.inpPars[3*i+j-4]]"
                                        :state="(submitted)? (!errors.has('weight_'+(3*i+j-4))): null"
                                        v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                        style="width:100%"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-list-group>
                                </div> -->

                              <!-- <div v-if="cdb.show && cdb.data.present.inpPars.length>0" class="w-100">
                                  <div class="d-flex justify-content-between"  style="color:#113cfc; border-bottom: 1px solid rgb(17, 60, 252); margin-bottom:10px; padding:5px 0">
                                    {{dict.IQR[lang]}}
                                    <div class="form-check" :class="dict.dir[lang]">
                                      <label class="form-check-label" for="flexRadioDefault2">
                                        {{dict.auto[lang]}}
                                      </label>
                                      <input class="form-check-input" :class="dict.dir[lang]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" v-model="cdb.data.distance.autoIQR" >
                                    </div>
                                  </div>
                                  <b-list-group v-for="i in Math.floor((cdb.data.present.inpPars.length-1)/3)+1" :key="'IQR_R_'+i"  horizontal="md" style="gap:10px;">
                                    <b-form-group
                                      v-for="j in  i<Math.floor((cdb.data.present.inpPars.length-1)/3)+1? 3:(cdb.data.present.inpPars.length%3>0? cdb.data.present.inpPars.length%3:3)" :key="'IQR_F_'+(i*3+j-3)"
                                      label-size="sm"
                                      :label="cdb.data.present.inpPars[3*i+j-4]"
                                      :label-for="'iqr_'+(3*i+j-4)"
                                      :invalid-feedback="errors.first('iqr_'+(3*i+j-4))"
                                      style="width:100%;"
                                    >
                                      <b-form-input
                                        size="sm"
                                        :name="'iqr_'+(3*i+j-4)"
                                        type="text"
                                        :placeholder="dict.contDataPH[lang]"
                                        v-model="cdb.data.IQR[cdb.data.present.inpPars[3*i+j-4]]"
                                        :state="(submitted)? (!errors.has('iqr_'+(3*i+j-4))): null"
                                        v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                        style="width:100%"
                                        :disabled="cdb.data.distance.autoIQR"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-list-group>
                                </div>

                                <div v-if="cdb.show && cdb.data.present.inpPars.length>0" class="w-100">
                                  <div class="d-flex justify-content-between"  style="color:#113cfc; border-bottom: 1px solid rgb(17, 60, 252); margin-bottom:10px; padding:5px 0">
                                    {{dict.minimum[lang]}}
                                    <div class="form-check" :class="dict.dir[lang]">
                                      <label class="form-check-label" for="flexRadioDefault2">
                                        {{dict.auto[lang]}}
                                      </label>
                                      <input class="form-check-input" :class="dict.dir[lang]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" v-model="cdb.data.distance.autoMIN" >
                                    </div>
                                  </div>
                                  <b-list-group v-for="i in Math.floor((cdb.data.present.inpPars.length-1)/3)+1" :key="'MIN_R_'+i"  horizontal="md" style="gap:10px;">
                                    <b-form-group
                                      v-for="j in  i<Math.floor((cdb.data.present.inpPars.length-1)/3)+1? 3:(cdb.data.present.inpPars.length%3>0? cdb.data.present.inpPars.length%3:3)" :key="'MIN_F_'+(i*3+j-3)"
                                      label-size="sm"
                                      :label="cdb.data.present.inpPars[3*i+j-4]"
                                      :label-for="'min_'+(3*i+j-4)"
                                      :invalid-feedback="errors.first('min_'+(3*i+j-4))"
                                      style="width:100%;"
                                    >
                                      <b-form-input
                                        size="sm"
                                        :name="'min_'+(3*i+j-4)"
                                        type="text"
                                        :placeholder="dict.contDataPH[lang]"
                                        v-model="cdb.data.MIN[cdb.data.present.inpPars[3*i+j-4]]"
                                        :state="(submitted)? (!errors.has('min_'+(3*i+j-4))): null"
                                        v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                        style="width:100%"
                                        :disabled="cdb.data.distance.autoMIN"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-list-group>
                                </div>

                                <div v-if="cdb.show && cdb.data.present.inpPars.length>0" class="w-100">
                                  <div class="d-flex justify-content-between"  style="color:#113cfc; border-bottom: 1px solid rgb(17, 60, 252); margin-bottom:10px; padding:5px 0">
                                    {{dict.maximum[lang]}}
                                    <div class="form-check" :class="dict.dir[lang]">
                                      <label class="form-check-label" for="flexRadioDefault2">
                                        {{dict.auto[lang]}}
                                      </label>
                                      <input class="form-check-input" :class="dict.dir[lang]" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" v-model="cdb.data.distance.autoMAX" >
                                    </div>
                                  </div>
                                  <b-list-group v-for="i in Math.floor((cdb.data.present.inpPars.length-1)/3)+1" :key="'MAX_R_'+i"  horizontal="md" style="gap:10px;">
                                    <b-form-group
                                      v-for="j in  i<Math.floor((cdb.data.present.inpPars.length-1)/3)+1? 3:(cdb.data.present.inpPars.length%3>0? cdb.data.present.inpPars.length%3:3)" :key="'MAX_F_'+(i*3+j-3)"
                                      label-size="sm"
                                      :label="cdb.data.present.inpPars[3*i+j-4]"
                                      :label-for="'max_'+(3*i+j-4)"
                                      :invalid-feedback="errors.first('max_'+(3*i+j-4))"
                                      style="width:100%;"
                                    >
                                      <b-form-input
                                        size="sm"
                                        :name="'max_'+(3*i+j-4)"
                                        type="text"
                                        :placeholder="dict.contDataPH[lang]"
                                        v-model="cdb.data.MAX[cdb.data.present.inpPars[3*i+j-4]]"
                                        :state="(submitted)? (!errors.has('max_'+(3*i+j-4))): null"
                                        v-validate="'required|decimal:30|min_value:-1000|max_value:1000'"
                                        style="width:100%"
                                        :disabled="cdb.data.distance.autoMAX"

                                      ></b-form-input>
                                    </b-form-group>
                                  </b-list-group>
                                </div> -->


                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab3'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div style="height:100%; padding:10px; display:flex; flex-direction:column; gap:10px ">

                              <div v-if="cdb.show && cdb.data.present" class="w-100">
                                <component
                                  :is="'MultiSelect'"
                                  :id="'mga_SMS'"
                                  :dataSource="pannel.status.header"
                                  :fields="{ text: 'text', value: 'value' }"
                                  :value="cdb.data.present.sortPars"
                                  :placeholder="dict.multiSel2[this.lang]"
                                  :enableRtl="lang === 'per'"
                                  @change="(value) => {cdb.data.present.sortPars = value}"
                                  :validatorInstance="cdb.validator"
                                  :validatorRules="{required: true}"
                                ></component>
                              </div>

                              <div v-if="cdb.show && cdb.data.present.sortPars.length>0" class="w-100"
                                   style="height:100%; display:flex; flex-direction:column; gap:10px ">
                                <div v-for="i in Math.floor((cdb.data.present.sortPars.length-1)/3)+1"
                                     :key="'SORT_R_'+i" class="w-100 row row-cols-1 row-cols-md-3">
                                  <div
                                    v-for="j in i<Math.floor((cdb.data.present.sortPars.length-1)/3)+1? 3:(cdb.data.present.sortPars.length%3>0? cdb.data.present.sortPars.length%3:3)"
                                    :key="'SORT_F_'+(i*3+j-3)" class="col">
                                    <component
                                      :is="'TextBox'"
                                      :key="'mga_STB'+(3*i+j-3)"
                                      :id="'mga_STB'+(3*i+j-3)"
                                      :value="cdb.data.SWT[cdb.data.present.sortPars[3*i+j-4]]"
                                      :placeholder="`${dict.weights[lang]} ${cdb.data.present.sortPars[3*i+j-4]}`"
                                      :enableRtl="lang === 'per'"
                                      @change="(value) => {cdb.data.SWT[cdb.data.present.sortPars[3*i+j-4]] = value}"
                                      :validatorInstance="cdb.validator"
                                      :validatorRules="{  required: true, range: [[0,1000000], 'The value should be between 0 and 1000000'] }"
                                    ></component>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab4'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div v-if="cdb.show && cdb.data.present" class="w-100"
                                 style="height:100%;  padding:10px; display:flex; flex-direction:column; gap:10px ">
                              <component
                                :is="'TextBox'"
                                :id="'mga_PTB1'"
                                :value="cdb.data.present.rescnt"
                                :placeholder="dict.resultCont[lang]"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {cdb.data.present.rescnt = value}"
                                :validatorInstance="cdb.validator"
                                :validatorRules="{  required: true, range: [[0,1000000], 'The value should be between 0 and 1000000'] }"
                              ></component>

                              <component
                                :is="'MultiSelect'"
                                :id="'mga_PMS1'"
                                :dataSource="[...this.pannel.status.header,'distance','sort']"
                                :fields="{ text: 'text', value: 'value' }"
                                :value="cdb.data.present.presPars1"
                                :placeholder="dict.MSPres1[this.lang]"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {cdb.data.present.presPars1 = value}"
                                :validatorInstance="cdb.validator"
                                :validatorRules="{required: true}"
                              ></component>

                              <component
                                :is="'MultiSelect'"
                                :id="'mga_PMS2'"
                                :dataSource="[...this.pannel.status.header,'distance','sort']"
                                :fields="{ text: 'text', value: 'value' }"
                                :value="cdb.data.present.presPars2"
                                :placeholder="`${dict.MSPresG[this.lang]} (${dict.MSPres2[this.lang]})`"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {cdb.data.present.presPars2 = value}"
                                :validatorInstance="cdb.validator"
                                :validatorRules="{required: true}"
                              ></component>

                              <component
                                :is="'MultiSelect'"
                                :id="'mga_PMS3'"
                                :dataSource="[...this.pannel.status.header,'distance','sort']"
                                :fields="{ text: 'text', value: 'value' }"
                                :value="cdb.data.present.presPars3"
                                :placeholder="`${dict.MSPresG[this.lang]} (${dict.MSPres3[this.lang]})`"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {cdb.data.present.presPars3 = value}"
                                :validatorInstance="cdb.validator"
                                :validatorRules="{required: true}"
                              ></component>

                              <component
                                :is="'MultiSelect'"
                                :id="'mga_PMS4'"
                                :dataSource="[...this.pannel.status.header,'distance','sort']"
                                :fields="{ text: 'text', value: 'value' }"
                                :value="cdb.data.present.presPars4"
                                :placeholder="`${dict.MSPresG[this.lang]} (${dict.MSPres4[this.lang]})`"
                                :enableRtl="lang === 'per'"
                                @change="(value) => {cdb.data.present.presPars4 = value}"
                                :validatorInstance="cdb.validator"
                                :validatorRules="{required: true}"
                              ></component>
                            </div>

                          </PerfectScrollbar>
                        </div>
                      </div>
                      <div v-show="activeTabCM === 'tab5'" class="h-100">
                        <div style="height:300px">
                          <PerfectScrollbar style="padding:0 10px 0 0 !important">
                            <div v-if="cdb.show && cdb.data.system" class="w-100"
                                 style="height:100%;  padding:10px; display:flex; flex-direction:column; gap:10px ">
                              <div class="w-100 row row-cols-1 row-cols-md-2">
                                <div class="col">
                                  <component
                                    :is="'TextBox'"
                                    :id="'mga_STTB1'"
                                    :value="cdb.data.system.tiggerValue"
                                    :placeholder="dict.tiggerValue[lang]"
                                    :enableRtl="lang === 'per'"
                                    @change="(value) => {cdb.data.system.tiggerValue = value}"
                                    :validatorInstance="cdb.validator"
                                    :validatorRules="{  required: true}"
                                  ></component>
                                </div>
                                <div class="col">
                                  <component
                                    :is="'TextBox'"
                                    :id="'mga_STTB2'"
                                    :value="cdb.data.system.dataValid"
                                    :placeholder="dict.dataValid[lang]"
                                    :enableRtl="lang === 'per'"
                                    @change="(value) => {cdb.data.system.dataValid = value}"
                                    :validatorInstance="cdb.validator"
                                    :validatorRules="{  required: true}"
                                  ></component>
                                </div>
                              </div>
                              <div class="w-100 row row-cols-1 row-cols-md-2">
                                <div class="col">
                                  <component
                                    :is="'TextBox'"
                                    :id="'mga_STTB3'"
                                    :value="cdb.data.system.normalValue"
                                    :placeholder="dict.normalValue[lang]"
                                    :enableRtl="lang === 'per'"
                                    @change="(value) => {cdb.data.system.normalValue = value}"
                                    :validatorInstance="cdb.validator"
                                    :validatorRules="{  required: true}"
                                  ></component>
                                </div>
                                <div class="col">
                                  <component
                                    :is="'TextBox'"
                                    :id="'mga_STTB4'"
                                    :value="cdb.data.system.waitInterval"
                                    :placeholder="dict.waitInterval[lang]"
                                    :enableRtl="lang === 'per'"
                                    @change="(value) => {cdb.data.system.waitInterval = value}"
                                    :validatorInstance="cdb.validator"
                                    :validatorRules="{  required: true}"
                                  ></component>
                                </div>
                              </div>
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_schedule'"
      size="lg"
      centered
      hide-header-close
      content-class="shadow"
      :title="sdb.modalTitle"
      @shown="initScheduleModal"
      @hidden="handleScheduleModalReset"
      @ok="handleScheduleModalOk"
    >
      <PerfectScrollbar v-if="sdb.show">
        <div>
          <b-list-group flush>
            <b-list-group-item style="background-color:transparent;color:black; ">
              <b-form-group
                label="Schedule Title"
                label-for="title"
                :invalid-feedback="errors.first('title')"
                style="width:100%;"
              >
                <b-form-input
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  v-model="sdb.title"
                  :state="(submitted)? (!errors.has('title')): null"
                  v-validate="'required|min:2|max:20'"
                  style="width:100%"
                ></b-form-input>
              </b-form-group>

              <div class="mt-4">
                <div style="display: flex; justify-content: end; width:100%">
                  <b-button-group size="sm">
                    <b-button @click="sdb.TSI = 5">5m</b-button>
                    <b-button @click="sdb.TSI = 15">15m</b-button>
                    <b-button @click="sdb.TSI = 30">30m</b-button>
                    <b-button @click="sdb.TSI = 60">1h</b-button>
                    <b-button @click="sdb.TSI = 120">2h</b-button>
                    <b-button @click="sdb.TSI = 240">4h</b-button>
                    <b-button @click="sdb.TSI = 360">6h</b-button>
                    <b-button @click="sdb.TSI = 720">12h</b-button>
                    <b-button @click="sdb.TSI = 1440">24h</b-button>
                  </b-button-group>
                </div>

                <div id="container">
                  <ejs-schedule id="Schedule" height="450px" ref="scheduleObj" :selectedDate="sdb.selectedDate"
                                :eventSettings="sdb.eventSettings" :dataBinding="onDataBinding"
                                :currentView="sdb.currentView" :workDays="sdb.workDays" :key="sdb.scheduleKey"
                                :timeScale="sdb.timeScale"
                                :cellClick="oncellClick" :eventClick="onEventClick" :showQuickInfo="false">
                    <e-views>
                      <e-view option="Week"></e-view>
                    </e-views>
                  </ejs-schedule>
                </div>
              </div>

            </b-list-group-item>
          </b-list-group>
        </div>
      </PerfectScrollbar>
    </b-modal>

    <b-modal
      :id="plotId+'_scheduleEditor'"
      size="md"
      centered
      hide-header-close
      content-class="shadow"
      :title="scheduleRecordType+' Event'"
      @shown="initScheduleEditorModal"
    >
      <template #modal-footer>
        <div class="d-flex w-100 justify-content-between">
          <div>
            <b-button v-if="scheduleRecordType==='Edit'" variant="danger" class="float-right"
                      @click="handleScheduleEditorModalDelete"> Delete
            </b-button>
          </div>
          <div>
            <b-button variant="secondary" style="margin-right:10px" @click="handleScheduleEditorModalReset"> Cancel
            </b-button>
            <b-button variant="primary" @click="handleScheduleEditorModalOk"> Ok</b-button>
          </div>
        </div>
      </template>

      <div>
        <div class="e-float-input mb-3">
          <input type="text" required v-model="scheduleRecord.Subject" />
          <span class="e-float-line"></span>
          <label class="e-float-text">Name</label>
        </div>

        <div>
          <input type="text" tabindex="1" id="ddl" />
        </div>

        <div id="sliderContA"></div>
        <div id="sliderContB"></div>

        <div class="d-flex mb-3" style="gap: 15px">
          <div id="startDTPcontainer" class="w-100">
            <input id="startDTP" type="text" />
          </div>

          <div id="endDTPcontainer" class="w-100">
            <input id="endDTP" type="text" />
          </div>
        </div>

        <ejs-recurrenceeditor locale="en" id="editor" ref="EditorObj" :change="onChangeRecurrenceeditor"></ejs-recurrenceeditor>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_result'"
      size="xl"
      centered

      body-text-variant="dark"
      content-class="shadow"
    >

      <template #modal-header>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
          <div><h5>{{ dict.result[lang] + " " + presentedResult }}</h5></div>
          <div><i>
            <font-awesome-icon icon="fas fa-xmark" class="close" @click="handleResultModalReset" />
          </i></div>
        </div>
      </template>

      <div :dir="dict.dir[lang]" style="width:100%; display:flex; flex-direction:column; gap:10px">
        <div v-if="presResult && presResult.cluster1"
             style="width:100%; display:flex; flex-direction:column; gap:10px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3);">
          <div style="width:100%; color: rgba(0, 0, 0, 0.54);  font-size: 13px; font-weight: 400;">
            {{ dict.MSPres2[lang] }}
          </div>
          <div v-for="i in Math.floor((presResult.cluster1.length-1)/4)+1" :key="'PRC1_R_'+i"
               class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div
              v-for="j in  i<Math.floor((presResult.cluster1.length-1)/4)+1? 4:(presResult.cluster1.length%4>0? presResult.cluster1.length%4:4)"
              :key="'PRC1_C_'+(i*4+j-5)" class="col">
              <div
                style="width:100%; height:100%; padding:10px; borderRadius:5px; border: 1px dashed rgb(219 219 219); display: flex; flex-direction: column; justify-content: space-between;">
                <div style="font-size:12px" class="w-100 text-center">{{ presResult.cluster1[4 * i + j - 5].name }}
                </div>
                <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">
                  {{ presResult.cluster1[4 * i + j - 5].value }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="presResult && presResult.cluster2"
             style="width:100%; display:flex; flex-direction:column; gap:10px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3);">
          <div style="width:100%; color: rgba(0, 0, 0, 0.54);  font-size: 13px; font-weight: 400;">
            {{ dict.MSPres3[lang] }}
          </div>
          <div v-for="i in Math.floor((presResult.cluster2.length-1)/4)+1" :key="'PRC2_R_'+i"
               class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div
              v-for="j in  i<Math.floor((presResult.cluster2.length-1)/4)+1? 4:(presResult.cluster2.length%4>0? presResult.cluster2.length%4:4)"
              :key="'PRC2_C_'+(i*4+j-5)" class="col">
              <div
                style="width:100%; height:100%; padding:10px; borderRadius:5px; border: 1px dashed rgb(219 219 219); display: flex; flex-direction: column; justify-content: space-between;">
                <div style="font-size:12px" class="w-100 text-center">{{ presResult.cluster2[4 * i + j - 5].name }}
                </div>
                <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">
                  {{ presResult.cluster2[4 * i + j - 5].value }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="presResult && presResult.cluster3"
             style="width:100%; display:flex; flex-direction:column; gap:10px; padding:10px; borderRadius:5px; boxShadow: 0 .125em .25em rgb(0 0 0 / .3);">
          <div style="width:100%; color: rgba(0, 0, 0, 0.54);  font-size: 13px; font-weight: 400;">
            {{ dict.MSPres4[lang] }}
          </div>
          <div v-for="i in Math.floor((presResult.cluster3.length-1)/4)+1" :key="'PRC3_R_'+i"
               class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div
              v-for="j in  i<Math.floor((presResult.cluster3.length-1)/4)+1? 4:(presResult.cluster3.length%4>0? presResult.cluster3.length%4:4)"
              :key="'PRC3_C_'+(i*4+j-5)" class="col">
              <div
                style="width:100%; height:100%; padding:10px; borderRadius:5px; border: 1px dashed rgb(219 219 219); display: flex; flex-direction: column; justify-content: space-between;">
                <div style="font-size:12px" class="w-100 text-center">{{ presResult.cluster3[4 * i + j - 5].name }}
                </div>
                <div style="font-size:17px" class="w-100 text-center fw-bold mt-1">
                  {{ presResult.cluster3[4 * i + j - 5].value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary"
                      @click="downloadSelectedResult(presResult.cluster1,presResult.cluster2,presResult.cluster3)">
              {{ dict.download[lang] }}
            </b-button>
            <b-button variant="secondary" @click="applySelectedResult(presResult.cluster3)">{{ dict.apply[lang] }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
// import myComponent_4 from "./myComponent_4.vue";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

import {
  ScheduleComponent,
  SchedulePlugin,
  Week,
  Month,
  Year,
  Agenda,
  Resize,
  DragAndDrop,
  ActionEventArgs,
  RecurrenceEditor
} from "@syncfusion/ej2-vue-schedule";
import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import { TextBox } from "@syncfusion/ej2-inputs";
import { createElement } from "@syncfusion/ej2-base";
import { enableRtl } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Slider } from "@syncfusion/ej2-inputs";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import RoundSlider from "vue-round-slider";

export default {
  components: {
    // myComponent_4, //transform: scale(.75);
    "ejs-schedule": ScheduleComponent,
    PerfectScrollbar,
    RoundSlider
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number
  },
  provide: {
    schedule: [Week, Year, Month, Agenda, DragAndDrop, Resize]
  },
  data() {
    return {
      dynamicComponentNames: [],
      presentedResult: null,
      presResult: {
        cluster1: [],
        cluster2: [],
        cluster3: []
      },
      activeTabCM: "tab1",
      activeTabConf: "tab1",
      UC: 0,
      isDisabled: true,

      status: {
        error: false,
        msg: "no setting is selected"
      },
      waterMark: "Select a datetime",

      typeList: [
        { text: "text1", value: "value1" },
        { text: "text2", value: "value2" },
        { text: "text3", value: "value3" }
      ],


      validator1: null,

      pannel: {
        settingVis: false,
        sliderHeight: 100,
        settingsOptions: [],
        selectedSetting: null,
        scheduleTitle: "",
        manual: {
          appliedRes: { apply: false, result: {} },
          inpPars: [],
          inputs: {},
          presPars1: [],
          presPars2: [],
          presPars3: [],
          presPars4: [],
          find: "0",
          intParam: "false"
        },
        status: {
          results: [],
          header: [],
          notices: [
            // {id:'id_1', type:'danger', message:'notice_1',shown:false},
            // {id:'id_2', type:'warning', message:'notice_2',shown:false},
            // {id:'id_3', type:'info', message:'notice_3',shown:false},
            // {id:'id_4', type:'success', message:'notice_4'},
            // {id:'id_5', type:'warning', message:'notice_5'},
            // {id:'id_6', type:'info', message:'notice_6'},
            // {id:'id_7', type:'success', message:'notice_7'},
            // {id:'id_8', type:'success', message:'notice_8'},
          ]
        }
      },

      scheduleRecord: {
        Id: 1,
        Subject: "",
        StartTime: null,
        EndTime: null,
        IsAllDay: false,
        RecurrenceRule: null,
        parameters: {
          selectedSetting: null,
          param_1: 0,
          param_2: []
        }
      },
      scheduleRecordType: null,

      myData: {
        datas: [],
        attribute: {
          title: "controller"
        },
        parameters: {
          enable: false,
          schedules: [],
          settings: [],
          selectedSchedule: "",
          activeEvent: "",
          controlMode: "",
          automatic: {},
          manual: {}
        },
        status: {}
      },
      activeTab: "tab1",
      dataReady: false,

      controlOptions: [
        { text: "Manual", value: "manual" },
        { text: "Schedule", value: "schedule" }
      ],

      dataEntryOptions: [
        { eng: "user defiened", per: "توسط کاربر", value: "true" },
        { eng: "get data from database", per: "دریافت پارامترها از پایگاه داده", value: "false" }
      ],

      scheduleFields: [{ key: "isSelected", label: "" }, { key: "title", label: "" }, { key: "modify", label: "" }],
      controllerFields: [{ key: "isSelected", label: "" }, { key: "title", label: "" }, { key: "modify", label: "" }],
      modalData: {},
      cdb: {},
      sdb: {},
      submittedForSearch: false,

      submitted: false,
      successful: false,
      message: "",

      resultDataFields: [
        { key: "param_1", label: "param_1" },
        { key: "param_2", label: "param_2" },
        { key: "param_3", label: "param_3" },
        { key: "param_4", label: "param_4" },
        { key: "param_5", label: "param_5" },
        { key: "param_6", label: "param_6" },
        { key: "param_7", label: "param_7" },
        { key: "param_8", label: "param_8" },
        { key: "param_9", label: "param_9" }
      ],

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        noEvent: { eng: "no active event", per: "هیچ رویداد فعالی وجود ندارد." },
        transition2: { eng: "slide2-fade-ltr", per: "slide2-fade-rtl" },
        title: { eng: "widget palette", per: "پالت ویجت" },
        tab1: { eng: "Pre-filter settings", per: "تنظیمات پیش فیلتر" },
        tab2: { eng: "Euclidean distance calculation", per: "محاسبه فاصله اقلیدسی" },
        tab3: { eng: "Sorting parameters", per: "پارامترهای مرتب‌سازی" },
        tab4: { eng: "Presentation settings", per: "تنظیمات نمایش" },
        tab5: { eng: "system settings", per: "تنظیمات سیستم" },

        IQR: { eng: "IQR", per: "IQR" },
        minimum: { eng: "minimum", per: "حد پایین" },
        maximum: { eng: "maximum", per: "حد بالا" },
        weights: { eng: "weight for", per: "وزن‌" },


        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        controlMode1: { eng: "User Triggered Operation", per: "فراخوانی توسط کاربر" },
        controlMode2: { eng: "Scheduled Operation", per: "فراخوانی زمانبندی‌شده" },
        selSetting: { eng: "Selected setting", per: "تنظیمات منتخب" },
        inputType: { eng: "Daily data entry method", per: "نحوه ورود داده‌های روزانه" },
        inputType1: { eng: "user defiened", per: "توسط کاربر" },
        inputType2: { eng: "get data from database", per: "دریافت پارامترها از پایگاه داده" },
        multiSel1: {
          eng: "selected data to calculate Euclidean distance",
          per: "داده‌های منتخب برای محاسبه فاصله اقلیدسی"
        },
        multiSel2: {
          eng: "selected data to calculate sorting parameter",
          per: "داده‌های منتخب برای محاسبه پارامتر مرتب‌‌‌سازی "
        },
        filterData: { eng: "selected data to filter dataset", per: "داده‌ منتخب برای فیلتر دیتاست " },
        filterOperator: { eng: "Operator", per: "عملگر " },
        filterValue: { eng: "Value", per: "مقدار " },

        MSPres1: { eng: "data to present on result cards", per: "داده‌های قابل نمایش روی کارت‌های نتایج" },
        MSPresG: { eng: "data to present on result modal", per: "داده‌های قابل نمایش در مدال نتایج" },
        MSPres2: { eng: "Search Parameters ", per: "پارامترهای جستجو" },
        MSPres3: { eng: "Iron Ore Pellet (IOP) parameters", per: "پارامترهای گندله" },
        MSPres4: { eng: "Control System Parameters", per: "تنظیمات سیستم کنترل" },

        contTitle: { eng: "title", per: "عنوان" },
        contTitlePH: { eng: "Title", per: "عنوان را وارد کنید" },
        contModalTitle: { eng: "new setting", per: "تنظیمات جدید" },
        contDataName_1: { eng: "Average of G-%CCS.1", per: "میانگین G-%CCS.1" },
        contDataName_2: { eng: "Average of G-%CCS.2", per: "میانگین G-%CCS.2" },
        contDataName_3: { eng: "Average of G-%FeO %", per: "میانگین G-%FeO %" },
        contDataName_4: { eng: "Average of G-%Porosity %", per: "میانگین G-%Porosity %" },
        contDataName_5: { eng: "Average of G-%S %", per: "میانگین G-%S %" },
        contDataName_6: { eng: "Average of G-MnO %", per: "میانگین G-MnO %" },
        contDataName_7: { eng: "Average of G-SiO2 %", per: "میانگین G-SiO2 %" },
        contDataName_8: { eng: "Average of G-Sieve 12.5-16 mm %", per: "میانگین G-Sieve 12.5-16 mm %" },
        contDataName_9: { eng: "Average of G-Sieve 8-12.5 mm %", per: "میانگین G-Sieve 8-12.5 mm %" },
        contDataName_10: { eng: "Average of %Met %", per: "میانگین %Met %" },
        contDataName_11: { eng: "Average of C %", per: "میانگین C %" },
        contDataName_12: { eng: "Y ( Met %_C %)", per: "Y ( Met %_C %)" },
        contDataName_13: { eng: "Distance", per: "فاصله" },
        contDataPH: { eng: "enter a number", per: "یک عدد وارد کنید" },
        resultCont: { eng: "number of presented results", per: "تعداد پاسخ‌های نمایش داده شده " },
        auto: { eng: "automatic calculation", per: "محاسبه خودکار" },


        confTab1: { eng: "Schedule", per: "برنامه زمانی" },
        confTab2: { eng: "Settings", per: "تنظیمات" },

        startBtn1: { eng: "search", per: "جستجو" },
        startBtn2: { eng: "searching...", per: "در جستجو ..." },
        result: { eng: "result", per: "نتیجه" },
        apply: { eng: "apply", per: "اعمال" },
        download: { eng: "download", per: "دریافت" },

        greater: { eng: "Greater than", per: "بزرگتر از" },
        less: { eng: "Less than", per: "کوچکتر از" },
        equal: { eng: "Equal to", per: "برابر با" },
        greaterEqual: { eng: "Greater than or equal to", per: "بزرگتر از یا برابر با" },
        lessEqual: { eng: "Less than or equal to", per: "کوچکتر از یا برابر با" },
        tiggerValue: {
          eng: "The output value of the trigger port under triggered conditions",
          per: "مقدار خروجی تحریک در شرایط تحریک "
        },
        normalValue: {
          eng: "The output value of the trigger port under normal conditions",
          per: "مقدار خروجی تحریک در شرایط عادی"
        },
        waitInterval: {
          eng: "The maximum time required for data to be made available on the daily data input port.",
          per: "حداکثر زمان مورد نیاز برای فراهم شدن داده روی پورت ورودی داده روزانه"
        },
        dataValid: {
          eng: "The value for data availability on the (daily data validity) input",
          per: "مقدار مشخص‌کننده قرار‌گرفتن داده روی ورودی (اعتبار داده روزانه) "
        },

        notice_0: {
          eng: "in order to start analysis you have to enable analyser once",
          per: "به منظور شروع تحلیل ابتدا باید تحلیلگر را یک مرتبه خاموش و روشن کنید "
        },
        notice_1: {
          eng: "In the calculations, the IQR value was zero. The value was rounded to zero for further calculations",
          per: "در محاسبات مقدار IQR صفر بدست آمد. برای انجام محاسبات مقدار به صفر گرد شد."
        },
        notice_2: {
          eng: "In the calculations, the difference between the maximum and minimum value of one of the search parameters was found to be zero. To continue the calculations, the value of this parameter was considered zero",
          per: "در محاسبات تفاضل مقدار بیشینه و کمینه یکی از پارامترهای جستجو صفر بدست آمد. برای ادامه محاسبات مقدار این پارامتر صفر لحاظ شد"
        },
        notice_3: {
          eng: "In the calculations, the difference between the maximum and minimum value of one of the sorting parameters was found to be zero. To continue the calculations, the value of this parameter was considered zero",
          per: "در محاسبات تفاضل مقدار بیشینه و کمینه یکی از پارامترهای مرتب‌سازی صفر بدست آمد. برای ادامه محاسبات این پارامتر صفر لحاظ شد"
        },
        notice_4: {
          eng: "The daily data was not received correctly from the server",
          per: "داده‌های روزانه به‌درستی از سرور دریافت نشد"
        },
        notice_5: {
          eng: "Daily data could not be received from the server",
          per: "داده‌های روزانه از سرور دریافت نشد"
        },
        notice_6: {
          eng: "The fields of recieved daily data is not equal to selected fields for analysis",
          per: "فیلدهای داده روزانه دریافتی با فیلدهای انتخاب شده برای تحلیل یکسان نیست "
        },
        notice_7: { eng: "The analysis was completed successfully", per: "عملیات با موفقیت انجام شد " },
        notice_8: {
          eng: "At least two of the set system parameters are equal",
          per: "حداقل دو عدد از پارامترهای سیستمی تنظیم شده برابر هستند"
        },

        danger: { eng: "Alarm", per: "هشدار " },
        warning: { eng: "Warning", per: "اخطار " },
        info: { eng: "Information", per: "اطلاعات" },
        success: { eng: "Sucess", per: "موفقیت در انجام" }
      },

      operatorList: []
    };
  },
  methods: {
    getData() {
      var dids = [];
      for (var d of this.myData.datas) {
        dids.push(d._id);
      }
      if (dids.length > 0) {
        this.$store.dispatch("panel/getData", { id: dids }).then(
          datas => {

          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    changeTab() {
      if (this.activeTab === "tab1")
        this.activeTab = "tab2";
      else
        this.activeTab = "tab1";
    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    changeControlMode() {
      if (this.myData.parameters.controlMode === "schedule") {
        this.updateSchPanel();
        this.updateStatus();
      } else if (this.myData.parameters.controlMode === "manual") {
        this.updateManPanel();
      }
      // console.log('change control mode',this.myData.parameters.controlMode)
      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];

      console.log("panel/setData1", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );

    },
    onScheduleSelected(item) {
      this.myData.parameters.selectedSchedule = item._id;
      this.$refs.scheduleTable.refresh();

      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];
      console.log("panel/setData2", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );

    },


    addController: function(type) {
      this.modalData = { type: "Add", index: null };
      this.$root.$emit("bv::show::modal", this.plotId + "_control");
    },
    editController: function(index) {
      this.modalData = { type: "Edit", index: index };
      this.$root.$emit("bv::show::modal", this.plotId + "_control");
    },
    addSchedule: function(type) {
      this.modalData = { type: "Add", index: null };
      this.$root.$emit("bv::show::modal", this.plotId + "_schedule");
    },
    editSchedule: function(index) {
      this.modalData = { type: "Edit", index: index };
      this.$root.$emit("bv::show::modal", this.plotId + "_schedule");
    },
    removeSchedule: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this schedule")
        .then(value => {
          if (value === true) {

            var rmitem = this.myData.parameters.schedules[index]._id;
            this.myData.parameters.schedules.splice(index, 1);

            if (JSON.stringify(this.myData.parameters.selectedSchedule) === JSON.stringify(rmitem)) {
              var sclen = this.myData.parameters.schedules.length;
              if (sclen > 0) {
                if (index < sclen)
                  this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[index]._id;
                else if (index == sclen)
                  this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[sclen - 1]._id;
              } else {
                this.myData.parameters.selectedSchedule = null;
              }
            }

            var sdata = [{
              id: this.myData.datas[0]._id,
              value: {
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections: this.myData.connections,
                type: this.myData.type
              }
            }];
            console.log("panel/setData3", sdata);
            this.$store.dispatch("panel/setData", { data: sdata })
              .then(
                data => {
                },
                error => {
                  var message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
          }
        })
        .catch(err => {
        });
    },
    removeController: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this controller")
        .then(value => {
          if (value === true) {

            var rmitem = this.myData.parameters.settings[index]._id;
            this.myData.parameters.settings.splice(index, 1);

            // if (JSON.stringify(this.myData.parameters.selectedController)===JSON.stringify(rmitem)){
            //   var sclen = this.myData.parameters.settings.length
            //   if (sclen>0){
            //     if (index<sclen)
            //       this.myData.parameters.selectedController = this.myData.parameters.settings[index]._id
            //     else if (index == sclen)
            //       this.myData.parameters.selectedController = this.myData.parameters.settings[sclen-1]._id
            //   }
            //   else{
            //     this.myData.parameters.selectedController = null;
            //   }
            // }

            var sdata = [{
              id: this.myData.datas[0]._id,
              value: {
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections: this.myData.connections,
                type: this.myData.type
              }
            }];
            console.log("panel/setData4", sdata);
            this.$store.dispatch("panel/setData", { data: sdata })
              .then(
                data => {
                },
                error => {
                  var message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
          }
        })
        .catch(err => {
        });
    },
    onChangeStatus(state) {
      this.pannel.manual.find = '0'
      this.myData.parameters.manual.find = this.pannel.manual.find;

      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];
      console.log("panel/setData100", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    // control modal =============================================================
    initControlModal: function() {

      this.dict.contModalTitle = this.modalData.type === "Add" ? {
        eng: "new setting",
        per: "تنظیمات جدید"
      } : { eng: "edit setting", per: "ویرایش تنظیمات " };

      this.cdb = {
        validator: new FormValidator("#control-body", {
          rules: {},
          customPlacement: (element, error) => {
            console.log("element", element);
            element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
          }
          // customPlacement: (element, error) => {
          //   const errorContainerId = `error-${error.getAttribute("for")}`;
          //   const errorContainer = document.getElementById(errorContainerId);
          //   if (errorContainer) {
          //     errorContainer.appendChild(error);
          //   }
          // }
        }),
        show: false,
        modalTitle: this.modalData.type + " Controller",
        type: this.modalData.type,
        title: "",
        cid: "",
        filterRender: 0,
        data: {
          distance: {
            autoIQR: true,
            autoMIN: true,
            autoMAX: true
          },
          IQR: {},
          MIN: {},
          MAX: {},
          WEIGHT: {},
          SWT: {},
          FILTER: [],
          present: {
            rescnt: 0,
            inpPars: [],
            sortPars: [],
            presPars1: [],
            presPars2: [],
            presPars3: [],
            presPars4: []
          },
          system: {
            tiggerValue: 0,
            normalValue: 0,
            dataValid: 0,
            waitInterval: 0
          }
        }
      };

      if (this.cdb.type === "Add") {
        this.cdb.title = "";
        this.cdb.cid = uuidv4();
      } else if (this.cdb.type === "Edit") {
        this.cdb.cid = JSON.parse(JSON.stringify(this.myData.parameters.settings[this.modalData.index]._id));
        this.cdb.title = JSON.parse(JSON.stringify(this.myData.parameters.settings[this.modalData.index].title));
        this.cdb.data = JSON.parse(JSON.stringify(this.myData.parameters.settings[this.modalData.index].data));
      }


      this.operatorList = [
        { text: this.dict.greater[this.lang], value: "greater" },
        { text: this.dict.less[this.lang], value: "less" },
        { text: this.dict.equal[this.lang], value: "equal" },
        { text: this.dict.greaterEqual[this.lang], value: "greaterEqual" },
        { text: this.dict.lessEqual[this.lang], value: "lessEqual" }
      ];


      this.cdb.show = true;
    },
    handleControlModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleControlModalSubmit();
    },
    handleControlModalSubmit() {
      // console.log('submit',this.modalData.type)
      this.message = "";
      this.submitted = true;
      var isValid = this.cdb.validator.validate();
      if (isValid) {
        console.log("isValid", isValid);


        for (var ds of ["IQR", "MIN", "MAX", "WEIGHT"]) {
          this.cdb.data[ds] = Object.keys(this.cdb.data[ds])
            .filter(key => this.cdb.data.present.inpPars.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.cdb.data[ds][key];
              return obj;
            }, {});
        }

        for (var ds of ["SWT"]) {
          this.cdb.data[ds] = Object.keys(this.cdb.data[ds])
            .filter(key => this.cdb.data.present.sortPars.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.cdb.data[ds][key];
              return obj;
            }, {});
        }

        if (this.modalData.type === "Add")
          this.myData.parameters.settings.push({ _id: this.cdb.cid, title: this.cdb.title, data: this.cdb.data });
        else if (this.modalData.type === "Edit")
          this.myData.parameters.settings[this.modalData.index] = {
            _id: this.cdb.cid,
            title: this.cdb.title,
            data: this.cdb.data
          };

        this.saveData("manPanel");
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_control");
        });


      }
      // });
    },
    handleControlModalReset() {
      this.submitted = null;
      this.successful = null;
      this.message = null;
      this.cdb.show = false;
      this.cdb = {};
      this.activeTabCM = "tab1";
      console.log("handleControlModalReset", this.cdb);
      this.$bvModal.hide(this.plotId + "_control");

    },
    addFilter: function(type) {
      this.cdb.data.FILTER.push(
        {
          id: uuidv4(),
          parameter: "",
          operator: "",
          value: ""
        }
      );
    },
    removeFilter: function(item) {
      var indx = -1;
      if (this.cdb.data.FILTER && item) {
        indx = this.cdb.data.FILTER.findIndex(x => x.id === item);
      }
      if (indx != -1) {
        this.cdb.data.FILTER.splice(indx, 1);
        this.cdb.filterRender += 1;
      }
    },

    // schedule modal =============================================================
    initScheduleModal: function() {
      this.sdb = {
        show: false,
        modalTitle: this.modalData.type + " Schedule",
        type: this.modalData.type,
        title: "",
        sid: "",
        data: [],

        scheduleKey: 0,
        selectedDate: new Date(),
        currentView: "Week",
        workDays: [1, 2, 3, 4, 5, 6, 7],
        eventSettings: { dataSource: [] },
        timeScale: {
          enable: true,
          interval: 360,
          slotCount: 3
        },
        TSI: 360
      };

      if (this.sdb.type === "Add") {
        this.sdb.title = "";
        this.sdb.sid = uuidv4();

        if (this.myData.parameters.schedules.length == 0)
          this.myData.parameters.selectedSchedule = this.sdb.sid;
      } else if (this.sdb.type === "Edit") {
        this.sdb.sid = this.myData.parameters.schedules[this.modalData.index]._id;
        this.sdb.title = this.myData.parameters.schedules[this.modalData.index].title;
        this.sdb.data = this.myData.parameters.schedules[this.modalData.index].data;
      }

      // this.$refs.scheduleObj.eventSettings.dataSource = this.sdb.data
      this.sdb.eventSettings.dataSource = this.sdb.data;
      this.sdb.scheduleKey = this.sdb.scheduleKey + 1;

      this.sdb.show = true;
    },
    handleScheduleModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleScheduleModalSubmit();
    },
    handleScheduleModalSubmit() {
      // console.log('submit',this.modalData.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.modalData.type === "Add")
            this.myData.parameters.schedules.push({ _id: this.sdb.sid, title: this.sdb.title, data: this.sdb.data });
          else if (this.modalData.type === "Edit")
            this.myData.parameters.schedules[this.modalData.index] = {
              _id: this.sdb.sid,
              title: this.sdb.title,
              data: this.sdb.data
            };

          var sdata = [{
            id: this.myData.datas[0]._id,
            value: {
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections: this.myData.connections,
              type: this.myData.type
            }
          }];
          // console.log(sdata)
          console.log("panel/setData6", sdata);
          this.$store.dispatch("panel/setData", { data: sdata })
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId + "_schedule");
                });
              },
              error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
        }
      });
    },
    handleScheduleModalReset() {
      this.sdb.modalTitle = "",
        this.sdb.title = "",
        this.sdb.sid = "",
        this.sdb.data = [],
        this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    onDataBinding(e) {
      this.sdb.data = e.result;
      // console.log('data binding',this.sdb.data)
    },
    onEventClick: function(args) {
      // console.log('event click',args.event)
      this.scheduleRecord.Subject = args.event.Subject;

      this.scheduleRecord.StartTime = args.event.StartTime;
      this.scheduleRecord.EndTime = args.event.EndTime;
      this.scheduleRecord.RecurrenceRule = args.event.RecurrenceRule;
      this.scheduleRecord.Id = args.event.Id;
      this.scheduleRecord.parameters = args.event.parameters;
      this.scheduleRecordType = "Edit";
      this.$root.$emit("bv::show::modal", this.plotId + "_scheduleEditor");
    },
    oncellClick: function(args) {
      // console.log('cell click')
      this.scheduleRecord.Subject = "";
      this.scheduleRecord.StartTime = args.startTime;
      this.scheduleRecord.EndTime = args.endTime;
      this.scheduleRecord.RecurrenceRule = null;
      this.scheduleRecord.Id = uuidv4();
      this.scheduleRecord.parameters = {
        selectedSetting: null,
        param_1: 0,
        param_2: []
      };

      this.scheduleRecordType = "Add";
      this.$root.$emit("bv::show::modal", this.plotId + "_scheduleEditor");
    },
    initScheduleEditorModal() {
      const startDateTimePicker = new DateTimePicker({
        value: this.scheduleRecord.StartTime, // Set the initial date
        placeholder: "Start time",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.StartTime = args.value;
          console.log("newDate:", this.scheduleRecord.StartTime);
        }
      });

      const endDateTimePicker = new DateTimePicker({
        value: this.scheduleRecord.EndTime, // Set the initial date
        placeholder: "End time",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.EndTime = args.value;
          console.log("newDate:", this.scheduleRecord.EndTime);
        }
      });

      var dropDownOptions = [{ text: "disable", value: "disable" }];
      for (var controller of this.myData.parameters.settings) {
        dropDownOptions.push({ text: controller.title, value: controller._id });
      }

      const dropDownListObject = new DropDownList({
        placeholder: "یکی از تنظیمات را انتخاب کنید",
        value: this.scheduleRecord.parameters.selectedSetting,
        fields: { text: "text", value: "value" },
        dataSource: dropDownOptions,
        placeholder: "Setting",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.parameters.selectedSetting = args.value;

          var newSliderA = null;
          var newSliderB = null;
          var indx = -1;
          if (this.scheduleRecord.parameters.selectedSetting) {
            indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting);
          }

          if (indx !== -1) {
            var setting = this.myData.parameters.settings[indx].data;
            newSliderA = {
              fname: "param_1",
              type: setting.sliderA.type,
              min: setting.sliderA.min * 1,
              max: setting.sliderA.max * 1,
              step: setting.sliderA.step * 1,
              mTicks: setting.sliderA.mTicks * 1,
              MTicks: setting.sliderA.MTicks * 1,
              element: "sliderA",
              container: "sliderContA",
              name: setting.sliderA.name
            };
            newSliderB = {
              fname: "param_2",
              type: setting.sliderB.type,
              min: setting.sliderB.min * 1,
              max: setting.sliderB.max * 1,
              step: setting.sliderB.step * 1,
              mTicks: setting.sliderB.mTicks * 1,
              MTicks: setting.sliderB.MTicks * 1,
              element: "sliderB",
              container: "sliderContB",
              name: setting.sliderB.name
            };
          }
          const existingSliderA = document.getElementById("sliderA") ? document.getElementById("sliderA").ej2_instances[0] : null;
          const existingSliderB = document.getElementById("sliderB") ? document.getElementById("sliderB").ej2_instances[0] : null;

          if (existingSliderA && newSliderA) {
            this.updateSlider(existingSliderA, newSliderA);
          } else if (!existingSliderA && newSliderA) {
            this.createSlider(newSliderA);
          } else if (existingSliderA && !newSliderA) {
            document.getElementById("sliderContA").children[0].remove();
          }

          if (existingSliderB && newSliderB) {
            this.updateSlider(existingSliderB, newSliderB);
          } else if (!existingSliderB && newSliderB) {
            this.createSlider(newSliderB);
          } else if (existingSliderB && !newSliderB) {
            document.getElementById("sliderContB").children[0].remove();
          }
        }
      });

      var indx = -1;
      var sliders = [];
      if (this.scheduleRecord.parameters.selectedSetting) {
        indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting);
      }
      if (indx !== -1) {
        var setting = this.myData.parameters.settings[indx].data;
        sliders.push({
          fname: "param_1",
          type: setting.sliderA.type,
          min: setting.sliderA.min * 1,
          max: setting.sliderA.max * 1,
          step: setting.sliderA.step * 1,
          mTicks: setting.sliderA.mTicks * 1,
          MTicks: setting.sliderA.MTicks * 1,
          element: "sliderA",
          container: "sliderContA",
          name: setting.sliderA.name
        });
        sliders.push({
          fname: "param_2",
          type: setting.sliderB.type,
          min: setting.sliderB.min * 1,
          max: setting.sliderB.max * 1,
          step: setting.sliderB.step * 1,
          mTicks: setting.sliderB.mTicks * 1,
          MTicks: setting.sliderB.MTicks * 1,
          element: "sliderB",
          container: "sliderContB",
          name: setting.sliderB.name
        });
      }

      for (var slider of sliders) {
        this.createSlider(slider);
      }

      startDateTimePicker.appendTo("#startDTP");
      endDateTimePicker.appendTo("#endDTP");
      dropDownListObject.appendTo("#ddl");

      let recObject = this.$refs.EditorObj;
      recObject.setRecurrenceRule(this.scheduleRecord.RecurrenceRule);

    },
    createSlider(slider) {
      const sname = slider.fname;
      let container = createElement("div");
      let inputEle1 = createElement("div", { id: slider.element });
      let lableEle = createElement("label", {
        className: "custom-label1 mb-2 mt-2",
        attrs: { for: slider.element }, innerHTML: slider.name
      });
      container.appendChild(lableEle);
      container.appendChild(inputEle1);
      var sliderCont = document.getElementById(slider.container);
      sliderCont.appendChild(container);

      let _slider = new Slider({
        min: slider.min,
        enableRtl:this.lang=='per',
        max: slider.max,
        step: slider.step,
        type: slider.type === "min-range" ? "MinRange" : "Range",
        value: this.scheduleRecord.parameters[slider.fname].length !== 0 ? this.scheduleRecord.parameters[slider.fname] : 0,
        tooltip: { placement: "Before", isVisible: true },
        ticks: { placement: "Before", largeStep: slider.MTicks, smallStep: slider.mTicks, showSmallTicks: true },
        change: (args) => {
          this.scheduleRecord.parameters[sname] = args.value;
        }
      });
      _slider.appendTo("#" + slider.element);
    },
    updateSlider(existingSlider, newSlider) {
      existingSlider.min = newSlider.min;
      existingSlider.max = newSlider.max;
      existingSlider.step = newSlider.step;
      existingSlider.value = this.scheduleRecord.parameters[newSlider.fname].length !== 0 ? this.scheduleRecord.parameters[newSlider.fname] : 0;
      existingSlider.ticks = {
        placement: "Before",
        largeStep: newSlider.MTicks,
        smallStep: newSlider.mTicks,
        showSmallTicks: true
      };
      existingSlider.refresh();
    },
    handleScheduleEditorModalOk() {
      if (this.scheduleRecordType === "Add") {
        // console.log(this.scheduleRecord)
        this.$refs.scheduleObj.addEvent([this.scheduleRecord]);
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
      } else if (this.scheduleRecordType === "Edit") {

        if (this.scheduleRecord.RecurrenceRule) {
          this.$refs.scheduleObj.saveEvent(this.scheduleRecord, "EditSeries");
        } else {
          // console.log('EditOccurrence',this.scheduleRecord)
          this.$refs.scheduleObj.saveEvent(this.scheduleRecord);
        }
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
      }
    },
    handleScheduleEditorModalReset() {

      this.scheduleRecord = {
        Id: null,
        Subject: "",
        StartTime: null,
        EndTime: null,
        IsAllDay: false,
        RecurrenceRule: null
      },

        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
    },
    handleScheduleEditorModalDelete() {
      if (this.scheduleRecord.RecurrenceRule) {
        this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id, "DeleteSeries");
      } else {
        // console.log('EditOccurrence',this.scheduleRecord)
        this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id);
      }
      this.$nextTick(() => {
        this.$bvModal.hide(this.plotId + "_scheduleEditor");
      });
    },
    onChangeStartTime(arg) {
      this.scheduleRecord.StartTime = arg.value;
      // console.log('newDate:',this.scheduleRecord.StartTime)
    },
    onChangeEndTime(arg) {
      this.scheduleRecord.EndTime = arg.value;
      // console.log('newDate:',this.scheduleRecord.EndTime)
    },
    onChangeRecurrenceeditor(arg) {
      this.scheduleRecord.RecurrenceRule = arg.value;
      // console.log('onChangeRecurrenceeditor:',this.scheduleRecord.RecurrenceRule)
    },
    //--------------------------------------------------
    updateAutoPanel() {
      this.UC += 1;
      this.pannel.settingVis = true;
      // this.pannel.selectedSetting = this.myData.parameters.automatic.selectedSetting;

      // this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }]
      // for (var controller of this.myData.parameters.settings){
      //   this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
      // }

      // var indx = -1;
      // if (this.myData.parameters.automatic.selectedSetting){
      //   var indx = this.myData.parameters.settings.findIndex(x => x._id === this.myData.parameters.automatic.selectedSetting)
      // }


    },
    updateSchPanel() {
      this.UC += 1;
      var eventName = "";
      var scheduleName = "";
      var activeEvent = this.myData.parameters.activeEvent;
      this.pannel.settingVis = false;
      // this.pannel.rsliderA.visible = false;
      // this.pannel.rsliderB.visible = false;
      // console.log('activeEvent',activeEvent)

      var indx1 = -1;
      if (this.myData.parameters.selectedSchedule) {
        var indx1 = this.myData.parameters.schedules.findIndex(x => x._id === this.myData.parameters.selectedSchedule);
      }
      if (indx1 !== -1) {
        var schedule = this.myData.parameters.schedules[indx1];
        var scheduleName = schedule.title;
        if (activeEvent) {
          var indx2 = schedule.data.findIndex(x => x.Id === activeEvent);
          if (indx2 != -1) {
            var eventName = schedule.data[indx2].Subject;
            this.pannel.settingVis = true;
            // this.pannel.rsliderA.visible = true;
            // this.pannel.rsliderB.visible = true;

            var parameters = schedule.data[indx2].parameters;
            if (parameters) {
              // console.log('parameters',parameters)
              this.pannel.selectedSetting = parameters.selectedSetting;

              this.pannel.settingsOptions = [];
              for (var controller of this.myData.parameters.settings) {
                this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
              }
              // console.log('parameters2',parameters.selectedSetting,this.myData.parameters.settings)

              var indx = -1;
              if (parameters.selectedSetting) {
                var indx = this.myData.parameters.settings.findIndex(x => x._id === parameters.selectedSetting);
              }

              // console.log('parameters3',indx)

              // this.pannel.rsliderA = {

              //   key:'rsliderA_'+parameters.selectedSetting+'_'+this.UC,
              //   timeoutId:'',
              //   value: parameters.param_1?parameters.param_1:0,
              //   title: indx !== -1? this.myData.parameters.settings[indx].data.sliderA.name:'',
              //   visible: indx !== -1? this.myData.parameters.settings[indx].data.sliderA.status:true,
              //   min:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.min:null,
              //   max:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.max:null,
              //   step:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.step:null,
              //   MTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.MTicks:null,
              //   mTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.mTicks:null,
              //   type:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.type:null,
              //   disable:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.extSP:false,
              // }

              // this.pannel.rsliderB = {
              //   title:'رطوبت',
              //   key:'rsliderB_'+parameters.selectedSetting+'_'+this.UC,
              //   timeoutId:'',
              //   value: parameters.param_2?parameters.param_2:[],
              //   title: indx !== -1? this.myData.parameters.settings[indx].data.sliderB.name:'',
              //   visible: indx !== -1? this.myData.parameters.settings[indx].data.sliderB.status:false,
              //   min:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.min:null,
              //   max:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.max:null,
              //   step:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.step:null,
              //   MTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.MTicks:null,
              //   mTicks:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.mTicks:null,
              //   type:indx !== -1? this.myData.parameters.settings[indx].data.sliderA.type:null,
              //   disable:indx !== -1? this.myData.parameters.settings[indx].data.sliderB.extSP:false,
              // }
            }
          }
        }
      }
      this.pannel.scheduleTitle = `${scheduleName} ${eventName ? "/" : ""} ${eventName}`;
      // console.log('this.pannel.scheduleTitle',this.pannel.scheduleTitle)
    },
    updateManPanel() {

      console.log("[[[[[[[[[[[[[[[[[[]]]]]]]]]]]]]]]]]]");
      // console.log(this.myData.status)

      Object.keys(this.pannel.manual.inputs).forEach(key => {
        if (this.myData.parameters.manual.inputs[key])
          this.pannel.manual.inputs[key] = this.myData.parameters.manual.inputs[key];
        else
          this.pannel.manual.inputs[key] = "";
      });

      this.pannel.manual.find = this.myData.parameters.manual.find ? this.myData.parameters.manual.find : "0";
      this.pannel.manual.intParam = this.myData.parameters.manual.intParam ? this.myData.parameters.manual.intParam : "false";

      // this.pannel.status.notices = this.myData.parameters.status.notices?this.myData.parameters.status.notices:[]
      if (!this.pannel.status.notices)
        this.pannel.status.notices = [];

      if (this.myData.parameters.status.notices && this.myData.parameters.status.notices.length > 0) {
        var availabeIds = this.pannel.status.notices.map(function(item) {
          return item.id;
        });
        // console.log('>>>>>>>>>>>>>>1',availabeIds)
        this.myData.parameters.status.notices.forEach(notice => {
          console.log(">>>>>>>>>>>>>>2", notice.id);
          if (notice.id && !availabeIds.includes(notice.id)) {

            this.pannel.status.notices.push({ ...notice, shown: false });
            // console.log('>>>>>>>>>>>>>>3',notice)
          }
        });
        console.log(">>>>>>>>>>>>>>4", this.pannel.status.notices);
      }


      this.pannel.status.results = this.myData.parameters.status.results1 ? this.myData.parameters.status.results1 : [];
      this.pannel.selectedSetting = this.myData.parameters.manual.selectedSetting ? this.myData.parameters.manual.selectedSetting : null;
      this.pannel.status.header = this.myData.parameters.status.header ? this.myData.parameters.status.header : [];

      this.pannel.settingsOptions = [];
      for (var controller of this.myData.parameters.settings) {
        this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
      }

      var indx = -1;
      if (this.myData.parameters.manual.selectedSetting) {
        var indx = this.myData.parameters.settings.findIndex(x => x._id === this.myData.parameters.manual.selectedSetting);
      }
      if (indx != -1) {
        this.pannel.manual.inpPars = this.myData.parameters.settings[indx].data.present.inpPars;
      }

      console.log("this.pannel.manual.inpPars", this.pannel.manual.inpPars);


      // var keys = Object.keys(this.myData.parameters.manual)

      // keys.forEach(key => {
      //   var value = this.myData.parameters.manual[key]
      //   var indx = this.pannel.manual.buttons.findIndex(x => x.port === key)
      //   if (indx != -1){
      //     this.pannel.manual.buttons[indx].value = value;
      //   }

      //   var indx = this.pannel.manual.valve.findIndex(x => x.port === key)
      //   if (indx != -1){
      //     this.pannel.manual.valve[indx].value = value;
      //   }
      // })

      // this.pannel.manual.valve.forEach(slider =>{
      //   this.UC += 1;

      //   var LRSA = JSON.stringify(this.pannel[slider.rslider])
      //   this.pannel[slider.rslider] = {
      //     key:this.pannel[slider.rslider].key,
      //     timeoutId:this.pannel[slider.rslider].timeoutId,
      //     value:slider.value,
      //     title: this.pannel[slider.rslider].title,
      //     visible: true,
      //     min:this.pannel[slider.rslider].min,
      //     max:this.pannel[slider.rslider].max,
      //     step:this.pannel[slider.rslider].step,
      //     MTicks:this.pannel[slider.rslider].MTicks,
      //     mTicks:this.pannel[slider.rslider].mTicks,
      //     disable:false,
      //   }
      //   var CRSA = JSON.stringify(this.pannel[slider.rslider])

      //   if (LRSA !== CRSA){
      //     console.log(LRSA,CRSA)
      //     this.pannel[slider.rslider].key = slider.rslider+'_'+this.myData.parameters.automatic.selectedSetting+'_'+this.UC
      //     this.pannel[slider.rslider].timeoutId = ''
      //   }

      //   console.log('.................s2',this.pannel[slider.rslider])

      // })
    },
    updateStatus() {
      this.pannel.status.results = this.myData.parameters.status.results1;
      // var keys = Object.keys(this.myData.parameters.status)
      // keys.forEach(key => {
      //   var value = this.myData.parameters.status[key]
      //   var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
      //   if (indx != -1){
      //     this.pannel.status.indicators[indx].value = value;
      //   }
      //   indx = this.pannel.status.sliders.findIndex(x => x.tag === key)
      //   if (indx != -1){
      //     this.pannel.status.sliders[indx].value = value;
      //   }
      // })
    },
    // changeStatus(key,value){
    //   this.myData.parameters.status[key] = value
    //   var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
    //   if (indx != -1){
    //     this.pannel.status.indicators[indx].value = value;
    //   }
    //   this.saveData('status')
    // },
    //---------------------------------------------------
    search() {
      this.submittedForSearch = true;
      var isValid = this.validator1.validate();
      if (isValid) {
        this.submittedForSearch = false;
        this.pannel.manual.find = "1";
        this.saveData("manPanel");
      }
    },
    saveData(type) {
      // console.log('save data',type);
      var update = false;
      if (type === "autoPanel" && (this.myData.parameters.controlMode === "automatic")) {
        console.log("lll");

        // var bc = JSON.stringify(this.myData.parameters.automatic)
        // this.myData.parameters.automatic = {
        //   param_1: this.pannel.rsliderA.value,
        //   param_2: this.pannel.rsliderB.value,
        //   selectedSetting: this.pannel.selectedSetting
        // }
        // var ac = JSON.stringify(this.myData.parameters.automatic)
        // if (ac !== bc)
        //   update = true;

      } else if (type === "manPanel" && (this.myData.parameters.controlMode === "manual")) {
        console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjj");
        this.myData.parameters.manual = {};
        this.myData.parameters.manual.intParam = this.pannel.manual.intParam;
        this.myData.parameters.manual.selectedSetting = this.pannel.selectedSetting;

        var indx = -1;
        if (this.pannel.selectedSetting) {
          var indx = this.myData.parameters.settings.findIndex(x => x._id === this.pannel.selectedSetting);
        }
        if (indx != -1) {
          this.pannel.manual.inpPars = this.myData.parameters.settings[indx].data.present.inpPars;
          this.pannel.manual.presPars1 = this.myData.parameters.settings[indx].data.present.presPars1;
          this.pannel.manual.presPars2 = this.myData.parameters.settings[indx].data.present.presPars2;
          this.pannel.manual.presPars3 = this.myData.parameters.settings[indx].data.present.presPars3;
          this.pannel.manual.presPars4 = this.myData.parameters.settings[indx].data.present.presPars4;
        }

        console.log("this.pannel.manual.inpPars", this.pannel.manual.inpPars);

        this.pannel.manual.inputs = Object.keys(this.pannel.manual.inputs)
          .filter(key => this.pannel.manual.inpPars.includes(key))
          .reduce((obj, key) => {
            obj[key] = this.pannel.manual.inputs[key];
            return obj;
          }, {});

        this.myData.parameters.manual.inputs = JSON.parse(JSON.stringify(this.pannel.manual.inputs));
        this.myData.parameters.manual.find = this.pannel.manual.find;
        this.myData.parameters.manual.appliedRes = this.pannel.manual.appliedRes;

        console.log("this.myData", this.myData);
        console.log("this.pannel.manual.inputs", this.pannel.manual.inputs);

        update = true;
      } else if (type === "status" && (this.myData.parameters.controlMode === "automatic")) {
        update = true;
      }

      if (update) {
        var sdata = [{
          id: this.myData.datas[0]._id,
          value: {
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections: this.myData.connections,
            type: this.myData.type
          }
        }];
        console.log("panel/setData7", sdata);
        this.$store.dispatch("panel/setData", { data: sdata })
          .then(
            data => {
              // this.$nextTick(() => {
              //   this.$bvModal.hide(this.plotId+'_schedule')
              // })
            },
            error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
      }
    },
    openResultModal(i) {
      this.presentedResult = i + 1;

      this.presResult = {
        cluster1: [],
        cluster2: [],
        cluster3: []
      };

      if (this.pannel.manual.presPars2) {
        this.pannel.manual.presPars2.forEach(key => {
          if (this.pannel.status.results[i][key]) {
            this.presResult.cluster1.push({ name: key, value: this.pannel.status.results[i][key] });
          }
        });
      }

      if (this.pannel.manual.presPars3) {
        this.pannel.manual.presPars3.forEach(key => {
          if (this.pannel.status.results[i][key]) {
            this.presResult.cluster2.push({ name: key, value: this.pannel.status.results[i][key] });
          }
        });
      }

      if (this.pannel.manual.presPars4) {
        this.pannel.manual.presPars4.forEach(key => {
          if (this.pannel.status.results[i][key]) {
            this.presResult.cluster3.push({ name: key, value: this.pannel.status.results[i][key] });
          }
        });
      }

      this.$root.$emit("bv::show::modal", this.plotId + "_result");
    },
    handleResultModalReset() {
      // this.successful = true;
      // this.message = '';
      this.$bvModal.hide(this.plotId + "_result");
    },
    // changeIntParam(){
    //   console.log(this.pannel.manual.intParam)
    // }

    createAndRegisterDynamicComponent(type) {
      console.log("dynamicComponentNames");
      const template = `
          <div>
            <input type="text" tabindex="1" :id="this.id" :name="this.id" />
            <div :id="'error-' + id" class="error-container"></div>
          </div>
        `;
      const data = () => ({
        cid: ""
      });
      const computed = {};
      const methods = {
        handleChange(args) {
          this.$emit("change", args.value);
        },
        initMultiSelect() {

          if (type === "MultiSelect") {
            this.element = new MultiSelect({
              dataSource: this.dataSource,
              value: this.value,
              fields: this.fields,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange
            });
          } else if (type === "DropDownList") {
            this.element = new DropDownList({
              dataSource: this.dataSource,
              value: this.value,
              fields: this.fields,
              enabled: this.enabled,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange
            });
          } else if (type === "TextBox") {
            this.element = new TextBox({
              value: this.value,
              placeholder: this.placeholder,
              floatLabelType: this.floatLabelType,
              enableRtl: this.enableRtl,
              change: this.handleChange,
              enabled: this.readOnly,
              showClearButton: true,
              htmlAttributes: { type: this.inputType }
            });
          }
          this.element.appendTo(`#${this.id}`);
        },
        addValidator() {
          this.validatorInstance.addRules(`${this.id}`, this.validatorRules);
          // console.log('this.validatorInstance',JSON.stringify(this.validatorInstance.properties))
        },
        removeValidator() {
          this.validatorInstance.removeRules(this.id);
          // console.log('this.validatorInstance',JSON.stringify(this.validatorInstance.properties))
        }
      };
      const mounted = function() {
        this.initMultiSelect();
        this.addValidator();
      };
      const beforeDestroy = function() {
        // console.log('destroy element', this.id)
        this.removeValidator();
      };
      const props = {
        id: {
          type: String,
          required: true
        },
        dataSource: {
          type: Array,
          required: type === "TextBox" ? false : true
        },
        value: {
          value: [Number, String, Array],
          required: true
        },
        fields: {
          type: Object,
          required: type === "TextBox" ? false : true
        },
        placeholder: {
          type: String,
          default: "Enter value"
        },
        floatLabelType: {
          type: String,
          default: "Auto"
        },
        enableRtl: {
          type: Boolean,
          default: false
        },
        validatorInstance: {
          type: Object
          // required: true
        },
        validatorRules: {
          type: Object
          // required: true
        },
        readOnly: {
          type: Boolean,
          default: true
        },
        enabled: {
          type: Boolean,
          default: true
        },
        inputType: {
          type: String,
          default: 'text'
        },
      };

      // Directly use Vue.extend to create the dynamic component
      const DynamicComponent = Vue.extend({
        template,
        data,
        computed,
        methods,
        mounted,
        beforeDestroy,
        props
      });

      Vue.component(type, DynamicComponent);
      this.dynamicComponentNames.push(type);
    },
    createAndRegisterNotificationComponent() {
      const template = `
          <div class="toast"
            :class="{
              show: isShown,
              'bg-warning': type==='warning',
              'bg-success': type==='success',
              'bg-danger' : type==='danger',
              'bg-info' : type==='info',
              'text-white':  type==='success' || type==='danger',
              'text-dark':  type==='warning' || type==='info',
            }"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div class="toast-header justify-content-between">
              <strong>{{ title }}</strong>
              <div class="d-flex">
                <small>{{ time }}</small>
                <button type="button" class="btn-close" style="margin: 0; margin-inline-start: 12px;" @click="hideToast" aria-label="Close"></button>
              </div>
            </div>
            <div class="toast-body">
              {{ message }}
            </div>
          </div>
        `;
      const data = () => ({
        isShown: false
      });
      const watch = {
        message: {
          handler() {
            this.showToast();
          },
          immediate: true
        }
      };
      const methods = {
        showToast() {
          this.isShown = true;
          this.$emit("change", true);
          setTimeout(this.hideToast, this.timeout); // Hide after 3 seconds
        },
        hideToast() {
          this.isShown = false;
          this.$emit("change", false);
        }
      };
      const props = {
        id: {
          type: String,
          required: true
        },
        title: {
          type: String,
          default: "Notice"
        },
        message: {
          type: String,
          required: true
        },
        time: {
          type: String,
          default: "just now"
        },
        type: {
          type: String,
          default: "warning"
        },
        timeout: {
          type: [Number, String],
          default: 5000
        }

      };

      // Directly use Vue.extend to create the dynamic component
      const DynamicComponent = Vue.extend({
        template,
        data,
        watch,
        methods,
        props
      });

      Vue.component("Notification", DynamicComponent);
      this.dynamicComponentNames.push("Notification");
    },


    downloadSelectedResult(sheet1, sheet2, sheet3) {
      var wb = XLSX.utils.book_new();

      var ws1 = XLSX.utils.json_to_sheet(sheet1);
      var ws2 = XLSX.utils.json_to_sheet(sheet2);
      var ws3 = XLSX.utils.json_to_sheet(sheet3);


      XLSX.utils.book_append_sheet(wb, ws1, "sheet1");
      XLSX.utils.book_append_sheet(wb, ws2, "sheet2");
      XLSX.utils.book_append_sheet(wb, ws3, "sheet3");

      XLSX.writeFile(wb, "result.xlsx");

    },
    applySelectedResult(result) {
      var resObj = {};
      result.forEach(item => {
        resObj[item.name] = item.value;
      });


      this.pannel.manual.appliedRes = {
        apply: true,
        result: resObj
      };
      this.saveData("manPanel");
    },
    handleNoticeChanges(id, value) {
      if (!value) {
        var indx = -1;
        if (this.pannel.status.notices) {
          var indx = this.pannel.status.notices.findIndex(x => x.id === id);
        }
        if (indx != -1) {
          this.pannel.status.notices[indx].shown = true;
          // this.pannel.status.notices.splice(indx,1)
        }

        // console.log('this.pannel.status.notices',this.pannel.status.notices,this.pannel.status.notices.filter(item => !item.shown))
      }
      // console.log('change in notice',id,value)
    }


  },

  mounted() {

    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
    }
    // console.log('===========control myData: ', this.myData)
    this.getData();
    this.createAndRegisterDynamicComponent("MultiSelect");
    this.createAndRegisterDynamicComponent("DropDownList");
    this.createAndRegisterDynamicComponent("TextBox");
    this.createAndRegisterNotificationComponent();

    this.validator1 = new FormValidator("#show-body", {
      rules: {},
      customPlacement: (element, error) => {
        element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
      }
      // customPlacement: (element, error) => {
      //   const errorContainerId = `error-${error.getAttribute("for")}`;
      //   const errorContainer = document.getElementById(errorContainerId);
      //   if (errorContainer) {
      //     errorContainer.appendChild(error);
      //   }
      // }
    });


  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    TSI() {
      return this.sdb.TSI;
    },
    settings() {
      if (this.myData.parameters) {
        return this.myData.parameters.settings;
      }
    },
    selectedSetting() {
      return this.pannel.selectedSetting;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    myvalidator() {
      return this.validator1;

    }

  },
  watch: {
    updatedData(newValue) {
      // console.log('newdata')
      if (this.myData.datas.length > 0) {
        for (var Data of newValue) {
          // console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
          if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)) {
            var LMD = JSON.stringify(this.myData);

            this.myData = { ...this.data, ...Data.value };
            if (!this.myData["parameters"]) {
              this.myData["parameters"] = {

                schedules: [],
                selectedSchedule: "",
                activeEvent: "",
                settings: [],
                controlMode: "manual",
                automatic: {},
                manual: {},
                status: {}

              };
            }
            var CMD = JSON.stringify(this.myData);
            if (LMD !== CMD) {


              if (this.myData.parameters.controlMode === "automatic") {

                this.updateAutoPanel();
                this.updateStatus();
              } else if (this.myData.parameters.controlMode === "schedule") {
                this.updateSchPanel();
                this.updateStatus();
              } else if (this.myData.parameters.controlMode === "manual") {
                this.updateManPanel();
              }
              this.dataReady = true;
            }
          }
        }
      }
    },
    TSI(newValue) {
      // console.log('TSI',newValue)
      this.sdb.timeScale.interval = newValue;
      this.sdb.eventSettings.dataSource = this.sdb.data;
      this.sdb.scheduleKey = this.sdb.scheduleKey + 1;
      // console.log('TSI',newValue)
    },
    resized() {
      // this.resizeSlider();
    },
    settings(newvalue) {
      if (newvalue) {
        this.pannel.settingsOptions = [];
        for (var controller of this.myData.parameters.settings) {
          this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
        }
      }

    },
    selectedSetting(newval) {
      this.saveData("manPanel");
    },
    myvalidator(newval) {
      console.log("myvalidator", newval);
    }

  }
};
</script>

<style scoped
       src="@/assets/styles/ledStyle.css">

</style>

<style>
.disabled-div {
  pointer-events: none;
  /* Add any additional styling for a disabled appearance */
  opacity: 0.5; /* Example: reduce opacity for a disabled look */
}

.component-main-holder {
  position: absolute;
  z-index: 7;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.component-hole {
  position: absolute;
  z-index: 8;
  /* background: #ffffff; */
  border-radius: 50%;
  /* left:  30%;
    right: 30%;
    bottom:30%;
    top: 30%; */
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.component-hole .text {
  display: flex;
  justify-content: center;
  font-size: 40px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-bottom: 0px;
}

.component-hole .note {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px !important;
  /* font-weight: bold !important; */
  color: #ffffff !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
}


.modal-footer {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.rs-custom.rs-bar {
  z-index: 4;
}

.rs-custom .rs-border {
  border-color: #ababab;
  margin-left: 18px;
  width: 5px;
}

.rs-custom .rs-border.rs-bold {
  width: 10px;

}

.rs-custom.selected .rs-border {
  border-color: #ff9500;
}


.rs-custom1.rs-bar {
  z-index: 3;
}

.rs-custom1 .rs-border {
  border-color: transparent;
  margin-left: 30px;
  width: 5px;
}

.rs-custom1 .rs-border.rs-bold {
  width: 30px;
  width: 30px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: bold;
}

.rs-custom1 .rs-border.rs-bold.mirrored {
  transform: translate(0, -50%) rotate(180deg);
  justify-content: end;
}


.rs-custom1.selected .rs-border {
  color: #ff9500;
}


.rs-pointer.rs-bar {
  z-index: 5;
}

.rs-pointer .rs-border {
  width: 0px;
  /* height: 25px !important; */
  position: absolute;
  top: 50%;
  /* left: -30px; */
  transform: translate(0, -50%);

  /* border-radius: 5px; */
  border: 1px solid rgb(4, 202, 169) !important;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: bold;
}

.rs-pointer .rs-border:before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: -5px;
  border: 10px solid rgb(4, 202, 169);
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: translateY(50%);
}

.rs-value.rs-tooltip.rs-tooltip-text {
  color: rgb(4, 202, 169) !important;
  font-family: Segoe UI !important;
  font-size: 40px !important;
  font-weight: bold !important;
}

span.rs-number {
  position: absolute;
  top: -12px;
  left: -26px;
  font-family: Segoe UI;
  font-size: 24px;
  font-weight: bold;
}
</style>


<style scoped>

.panel_container {
  width: 500px;
  height: 500px;
}

.panel_container .panel_header {
  /* padding: 10px; */
  height: 10%;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-variant: small-caps;
  border-bottom: 2px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_container .panel_body1 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  /* border-bottom: 2px solid rgb(166, 41, 41); */
  display: flex;
  justify-content: space-between;
}

.panel_container .panel_col {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_container .panel_colf {
  height: 100%;
  width: 100%;
}

.panel_container .panel_footer {
  transition: height 1s;
  height: 90%
}

.buttonHolder {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(150, 150, 150, 0.368);
  border-radius: 10px;
}

.radioboxGroup {
  margin: 20px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radiobtnGroup {
  width: 100%;
  height: 50px;
  display: inline-block;
  margin: 2px
}

.radiobtnGroup .radioinpGroup {
  display: none;
}

.radiobtnGroup .radiospanGroup:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtnGroup .radiospanGroup:before {
  background-color: rgb(198, 22, 22);
}

.radiobtnGroup .radiospanGroup {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(150, 150, 150, 0.368);
  border-radius: 10px;
  /* background-color: #ddd; */
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 5px;

}

.radiobtnGroup input:checked + span {
  background-color: hsl(25.5, 94.8%, 54.3%);
  color: rgb(255, 255, 255)
}

.radiobox {
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.radiobtn {
  width: 50%;
  height: 100%;
  display: inline-block;
}

.radiobtn .radioinp {
  display: none;
}

.radiobtn .radiospan:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtn .radiospan:before {
  background-color: rgb(198, 22, 22);
}

.radiobtn .radiospan {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0)
}

.radiobtn input:checked + span {
  background-color: #113cfc;
  color: rgb(255, 255, 255)
}

.tab-content {
  height: 100%;

}

/* .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
{
    transform: translateX(10px);
    opacity: 0;
  } */
.fcontainor {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #ced4da;
  padding: 2%;
  border-radius: 0.5rem;
  margin-top: 2%;
}

.econtainor {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 0.5rem;
  /* margin-top: 10px; */
}


.panel_body {
  /* padding: 0px 2% 0px 2%; */
  /* height: 68vh; */
  overflow-y: auto;
}


</style>

<style scoped>

.remove {
  cursor: pointer;
  /* color: rgb(212, 64, 27); */
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active, .slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100%);
}


.slide-fade-rtl-enter-active, .slide-fade-rtl-leave-active, .slide-fade-ltr-enter-active, .slide-fade-ltr-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-rtl-enter, .slide-fade-rtl-leave-to {
  transform: translateX(100%);
}

.slide-fade-ltr-enter, .slide-fade-ltr-leave-to {
  transform: translateX(-100%);
}

.slide2-fade-rtl-enter-active, .slide2-fade-rtl-leave-active, .slide2-fade-ltr-enter-active, .slide2-fade-ltr-leave-active {
  transition: transform 0.3s ease;
}

.slide2-fade-rtl-enter, .slide2-fade-rtl-leave-to {
  transform: translateX(100%);
}

.slide2-fade-ltr-enter, .slide2-fade-ltr-leave-to {
  transform: translateX(-100%);
}

.cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2em;

}
</style>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

/* .e-subject-container{
       width: 100% !important;
  } */
.custom-event-editor .e-textlabel {
  padding-right: 15px;
  text-align: right;
}

.custom-event-editor td {
  padding: 7px;
  padding-right: 16px;
}

.ps {
  height: 100%;
}
</style>

<style>
.e-slider-container {
  padding: 5px 10% 5px 10%;
}

.e-subject-container {
  width: 100% !important;
}

.custom-field-row {
  padding-bottom: 12px;
}

.custom-label1 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
}

.custom-label2 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  margin-top: 20px;
}

.custom-label3 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.flex-container {
  display: flex;
  padding-bottom: 12px;
  width: 100%;
  justify-content: space-between;
}

.field-container {
  width: 30%;
}

.field-container-controller {
  width: 48%;
  margin-bottom: 10px;
}

.panel_header {
  padding: 10px;
  height: 60px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-variant: small-caps;
  border-bottom: 2px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_col {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  height: 25px;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  font-size: 13px;
}


/* .e-input-group.e-control-wrapper.e-float-input {
      color: royalblue;

    }

    .e-float-text::after {
      color: royalblue;

    }

    .e-float-line::before {
        background: royalblue !important;
    }

    .e-float-line::after {
        background: royalblue !important;
    }

    .e-float-text.e-label-top{
      color: royalblue !important;

    } */
</style>

<style scoped>
.led-red {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #940;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0 2px 12px;
}

.led-yellow {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #A90;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #660 0 -1px 9px, #DD0 0 2px 12px;
}

.led-green {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #690;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0 2px 12px;
}

.indicator {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 70px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 .125em .25em rgb(0 0 0 / .3)
}

.indicator-label {
  width: 100%;
  height: 30%;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal !important;
  font-weight: 200;
  font-size: 12px;
}

.indicator-val {

  width: 100%;
  height: 70%;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal !important;
  font-weight: 700;
  font-size: 18px;
}

</style>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
<style scoped src="@/assets/styles/theme.css"></style>

<style>
.WCSCM-body-class {
  padding: 0 !important;
}
</style>

<style scoped>
.widget {
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.widget:hover {

  box-shadow: 0 .125em .25em #113cfc;
  /* box-shadow: rgba(148, 55, 255, 0.24) 0px 3px 8px; */
  /* box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
  border: none;
}

.close {
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}

</style>

<style scoped>
.form-check.ltr {
  padding-left: 1.5em;
  padding-right: 0;
}

.form-check.rtl {
  padding-right: 1.5em;
  padding-left: 0;
}

.form-check .form-check-input.ltr {
  float: left;
  margin-left: -1.5em;
}

.form-check .form-check-input.rtl {
  float: right;
  margin-right: -1.5em;
}
</style>
