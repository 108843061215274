<template>
  <div style="height:100%; width:100%;">
    <div v-if="dataReady" class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-2 ">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" value="" id="CBEn" v-model="myData.status.enable"
                   @change="onChangeStatus()">
          </div>
        </div>
        <div class="col-8 ">
          <p class="mb-0">
            {{ myData.attribute.title }}
          </p>
        </div>
        <div class="col-2 text-center">
          <div class="d-flex" style="gap:5px;">
            <span v-if="!myData.status.enable" class="remove w-100" @click="changeTab">
              <i v-if="activeTab==='tab1'" style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-gear" /></i>
              <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i> 
            </span>
            <span v-if="editable" class="remove w-100 text-danger" @click="removeWidget">
              <i><font-awesome-icon icon="fas fa-xmark" /></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataReady" class="card-body p-1 d-flex justify-content-center align-items-center"
         style="height: calc(100% - 40px); width:100%; overflow: hidden;">
      <transition name="slide-fade" mode="out-in">
        <div class="tab-content h-100 w-100" :key="activeTab">
          <div v-if="activeTab === 'tab1'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <div class="h-100 w-100 p-2" style="display:flex; flex-direction:column; gap:10px;">
                <div style="height:25%;">
                  <div class="radiobox" style="height:70%">
                    <label class="radiobtn" v-for="opt in controlOptions" :key="opt.value">
                      <input class="radioinp" type="radio" v-model="myData.parameters.controlMode" :value="opt.value"
                             :name="plotId" @change="changeControlMode" />
                      <span v-if="opt.text==='Manual'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-hand-paper" />
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">دستی</p>
                        </div>
                      </span>
                      <span v-if="opt.text==='Automatic'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-magic" />
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">خودکار</p>
                        </div>
                      </span>
                      <span v-if="opt.text==='Schedule'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <div style="display: flex;justify-content: center;">
                            <font-awesome-icon icon="fas fa-calendar-days" />
                          </div>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">زمانبندی</p>
                        </div>
                      </span>
                    </label>
                  </div>
                  <div class="radiobox" style="height:30%">
                    <i v-if="myData.parameters.controlMode ==='schedule'">
                      <font-awesome-icon icon="fas fa-lock" />
                    </i>
                    <i v-else>
                      <font-awesome-icon icon="fas fa-lock-open" />
                    </i>
                    <div v-if="myData.parameters.controlMode==='schedule'">
                      <div v-if="pannel.scheduleTitle!=='  '">
                        {{ pannel.scheduleTitle }}
                      </div>
                      <div v-else>
                        <font-awesome-icon icon="fas fa-calendar-days" fade style="color:red" />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="height:75%;">
                  <div v-if="myData.parameters.controlMode!=='manual' && pannel.settingVis" style="height:100%; ">
                    <div class="VPD" style="height:100%; display:flex; flex-direction:column; gap:10px;">


                      <div style="height:20%; width:100%; display:flex; gap:10px;">
                        <div class="indicator">
                          <i class="indicator-label">تنظیمات</i>
                          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
                            <b-form-select
                              :disabled="myData.parameters.controlMode==='schedule' || myData.status.enable"
                              name="autoSetting"
                              v-model="pannel.selectedSetting"
                              :options="pannel.settingsOptions"

                              :state="(submitted)? (!errors.has('autoSetting')): null"
                              v-validate="'required'"
                              style="width:100%; font-size:small;"
                            ></b-form-select>
                          </div>
                        </div>

                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[7].title }}</i>
                          <i id="tooltip-0" v-if="pannel.status.indicators[7].value==='1'"
                             class="indicator-val text-danger">
                            <font-awesome-icon icon="fas fa-triangle-exclamation" size="xl" />
                          </i>
                          <i v-else-if="pannel.status.indicators[7].value==='0'" class="indicator-val text-success">
                            <font-awesome-icon icon="fas fa-circle-check" fade size="xl" />
                          </i>
                          <b-tooltip target="tooltip-0" title="Tooltip title" triggers="hover" placement="bottom"
                                     variant="info">
                            there is a fault in system; please check it
                          </b-tooltip>
                        </div>
                      </div>

                      <div style="height:60%; width:100%; display:flex; gap:10px;">
                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[0].title }}</i>
                          <div class="d-flex p-1 h-100">
                            <div style="width:100%">
                              <div class="tk" :style="tankLevelStyleA">
                                <div class="d-flex flex-column w-100 h-100" style="gap:20px">
                                  <i v-if="pannel.status.indicators[0].value==='0'" class="indicator-val text-danger">بحرانی</i>
                                  <i v-if="pannel.status.indicators[0].value==='1'" class="indicator-val text-success">نرمال</i>
                                  <i v-if="pannel.status.indicators[0].value==='2'" class="indicator-val text-danger">سرریز</i>
                                  <i
                                    v-if="pannel.status.indicators[0].value==='0'||pannel.status.indicators[0].value==='2'"
                                    class="indicator-val text-warning">
                                    <font-awesome-icon icon="fas fa-triangle-exclamation" fade size="xl" />
                                  </i>
                                </div>
                                <div class="lq"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[1].title }}</i>
                          <div class="d-flex p-1 h-100">
                            <div style="width:100%">
                              <div class="tk" :style="tankLevelStyleB">
                                <div class="d-flex flex-column w-100 h-100" style="gap:20px">
                                  <i v-if="pannel.status.indicators[1].value==='0'" class="indicator-val text-danger">بحرانی</i>
                                  <i v-if="pannel.status.indicators[1].value==='1'" class="indicator-val text-success">نرمال</i>
                                  <i v-if="pannel.status.indicators[1].value==='2'" class="indicator-val text-danger">سرریز</i>
                                  <i
                                    v-if="pannel.status.indicators[1].value==='0'||pannel.status.indicators[1].value==='2'"
                                    class="indicator-val text-warning">
                                    <font-awesome-icon icon="fas fa-triangle-exclamation" fade size="xl" />
                                  </i>
                                </div>
                                <div class="lq"></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[2].title }}</i>
                          <div class="d-flex p-1 h-100">
                            <div style="width:100%">
                              <div class="tk" :style="tankLevelStyleC">
                                <div class="d-flex flex-column w-100 h-100" style="gap:20px">
                                  <i v-if="pannel.status.indicators[2].value==='0'" class="indicator-val text-success">نرمال</i>
                                  <i v-if="pannel.status.indicators[2].value==='1'" class="indicator-val text-success">نرمال</i>
                                  <i v-if="pannel.status.indicators[2].value==='2'" class="indicator-val text-danger">بحرانی</i>
                                  <i v-if="pannel.status.indicators[2].value==='2'" class="indicator-val text-warning">
                                    <font-awesome-icon icon="fas fa-triangle-exclamation" fade size="xl" />
                                  </i>
                                </div>
                                <div class="lq"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="height:20%; width:100%; display:flex; gap:10px;">
                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[3].title }}</i>
                          <div class="d-flex h-100" style="gap:5px;">
                            <div class="d-flex justify-content-center align-items-center w-100">
                              <button v-if="pannel.status.indicators[3].value==='2'" type="button"
                                      class="btn btn-secondary btn-sm" @click="changeStatus('stat_3','0')">reset
                              </button>
                            </div>

                            <i v-if="pannel.status.indicators[3].value==='1'" class="indicator-val text-success"
                               style="width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" spin size="lg" />
                            </i>
                            <i v-else-if="pannel.status.indicators[3].value==='0'" class="indicator-val"
                               style="color:#c7c7c7; width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" size="lg" />
                            </i>
                            <i v-else-if="pannel.status.indicators[3].value==='2'" class="indicator-val text-danger"
                               style="width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" fade size="lg" />
                            </i>

                            <div class="d-flex justify-content-center align-items-center w-100">
                              <span id="tooltip-1" class="badge bg-danger text-light" style="width:30px; height:20px;">{{ pannel.status.indicators[4].value }}</span>
                              <b-tooltip target="tooltip-1" title="Tooltip title" triggers="hover" placement="bottom"
                                         variant="info">
                                number of faults occurred in pump 1
                              </b-tooltip>
                            </div>
                          </div>
                        </div>

                        <div class="indicator">
                          <i class="indicator-label">{{ pannel.status.indicators[5].title }}</i>
                          <div class="d-flex h-100" style="gap:5px;">
                            <div class="d-flex justify-content-center align-items-center w-100">
                              <button v-if="pannel.status.indicators[5].value==='2'" type="button"
                                      class="btn btn-secondary btn-sm" @click="changeStatus('stat_5','0')">reset
                              </button>
                            </div>

                            <i v-if="pannel.status.indicators[5].value==='1'" class="indicator-val text-success"
                               style="width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" spin size="lg" />
                            </i>
                            <i v-else-if="pannel.status.indicators[5].value==='0'" class="indicator-val"
                               style="color:#c7c7c7; width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" size="lg" />
                            </i>
                            <i v-else-if="pannel.status.indicators[5].value==='2'" class="indicator-val text-danger"
                               style="width:100%; height:100%;">
                              <font-awesome-icon icon="fas fa-cog" fade size="lg" />
                            </i>

                            <div class="d-flex justify-content-center align-items-center w-100">
                              <span id="tooltip-2" class="badge bg-danger text-light" style="width:30px; height:20px;">{{ pannel.status.indicators[6].value }}</span>
                              <b-tooltip target="tooltip-2" title="Tooltip title" triggers="hover" placement="bottom"
                                         variant="info">
                                number of faults occurred in pump 2
                              </b-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div v-if="myData.parameters.controlMode==='schedule' && !pannel.settingVis"
                       style="height:100%; display:flex; justify-content:center; align-items:center">
                    no active event
                  </div>
                  <div v-else-if="myData.parameters.controlMode==='manual'"
                       style="height:100%; width:100%; display:flex; justify-content:center; align-items:center;">
                    <div v-for="(button,i) in pannel.manual.buttons" :key="i" style="width:50%;">
                      <div style="display:flex; flex-direction:column; justify-content:center; align-items:center;">
                        <label
                          style="display:flex; justify-content:center; align-items:center">{{ button.title }}</label>
                        <label class="mga-switch" style="transform: scale(2); transform-origin: top center;">
                          <input type="checkbox" v-model="button.value" true-value="1" false-value="0"
                                 @change="saveData('manPanel')">
                          <i></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'tab2'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <b-tabs card>
                <b-tab title="Schedules">


                  <b-table id="tableSchedule" :items="myData.parameters.schedules" :fields="scheduleFields"
                           thead-class="hidden_header" responsive="sm" small ref="scheduleTable"
                           @row-clicked="onScheduleSelected">
                    <template #cell(modify)="row">
                      <div style="display:flex; justify-content:flex-end;">
                        <b-button pill size="sm" variant="outline-success" @click="editSchedule(row.index,$event)"
                                  class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button pill size="sm" variant="outline-danger" @click="removeSchedule(row.index,$event)"
                                  class="mr-2"
                                  style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                        </b-button>
                      </div>
                    </template>
                    <template #cell(isSelected)="row">
                      <input
                        style="pointer-events: none"
                        type="checkbox"
                        :checked="row.item._id === myData.parameters.selectedSchedule"
                      >
                      <!--                        <template v-if="row.item._id===myData.parameters.selectedSchedule">-->
                      <!--                          <span aria-hidden="true">&check;</span>-->
                      <!--                          <span class="sr-only"></span>-->
                      <!--                        </template>-->
                      <!--                        <template v-else>-->
                      <!--                          <span aria-hidden="true">&nbsp;</span>-->
                      <!--                          <span class="sr-only"></span>-->
                      <!--                        </template>-->
                    </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button @click="addSchedule" size="sm" variant="outline-danger"
                              style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                      <i>
                        <font-awesome-icon icon="fas fa-plus" />
                      </i>
                    </b-button>
                  </div>
                </b-tab>
                <b-tab title="Settings">
                  <b-table :items="myData.parameters.settings" :fields="scheduleFields" thead-class="hidden_header"
                           responsive="sm" small ref="controllerTable">
                    <template #cell(modify)="row">
                      <div style="display:flex; justify-content:flex-end;">
                        <b-button pill size="sm" variant="outline-success" @click="editController(row.index,$event)"
                                  class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button pill size="sm" variant="outline-danger" @click="removeController(row.index,$event)"
                                  class="mr-2"
                                  style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button @click="addController" size="sm" variant="outline-danger"
                              style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                      <i>
                        <font-awesome-icon icon="fas fa-plus" />
                      </i>
                    </b-button>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <b-modal
      :id="plotId+'_control'"
      size="md"
      centered
      hide-header-close
      content-class="shadow"
      :title="cdb.modalTitle"
      @shown="initControlModal"
      @hidden="handleControlModalReset"
      @ok="handleControlModalOk"
    >
      <div v-if="cdb.show">
        <div class="panel_body">
          <b-list-group flush>
            <b-form-group
              label="Controller Title"
              label-for="title"
              :invalid-feedback="errors.first('title')"
              style="width:100%; margin-bottom:10px"
            >
              <b-form-input
                name="title"
                type="text"
                placeholder="Enter title"
                v-model="cdb.title"
                :state="(submitted)? (!errors.has('title')): null"
                v-validate="'required|min:2|max:20'"
                style="width:100%"
              ></b-form-input>
            </b-form-group>


            <div class="econtainor"
                 :style=" {backgroundColor: cdb.data.pump1.status?'transparent':'#e7ebef', width:'100%', marginBottom:'10px'}">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" value="" id="pump1" v-model="cdb.data.pump1.status">
                <label class="form-check-label" for="pump1">
                  پمپ 1
                </label>
              </div>

              <b-list-group horizontal="md" style="gap:10px;">
                <b-form-group
                  label-size="sm"
                  label="man/auto input"
                  label-for="pump1_mode"
                  style="width:100%;"
                >
                  <div id="pump1_mode" style="width:100%; display:flex; gap:10px">
                    <div v-for="(option,i) in inOptions" :key="plotId+'_pump1_mode_option_'+i" style="width:100%">
                      <input type="radio" class="btn-check" name="options" :id="plotId+'_pump1_mode_option_'+i"
                             autocomplete="off" :checked="cdb.data.pump1.mode===option.value"
                             @change="cdb.data.pump1.mode=option.value" :disabled="!cdb.data.pump1.status">
                      <label style="width:100%"
                             :class="{btn:true, 'btn-primary':(cdb.data.pump1.mode===option.value), 'btn-secondary':(cdb.data.pump1.mode!==option.value)}"
                             :for="plotId+'_pump1_mode_option_'+i">
                        <!-- {{option.text}} -->
                        <font-awesome-icon v-if="option.text==='disable'" icon="fas fa-xmark" rotation="90" />
                        <font-awesome-icon v-if="option.text==='normal'" icon="fas fa-equals" rotation="90" />
                        <font-awesome-icon v-if="option.text==='reverse'" icon="fas fa-not-equal" rotation="90" />
                      </label>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-size="sm"
                  label="protection input"
                  label-for="pump1_protection"
                  style="width:100%;"
                >
                  <div id="pump1_protection" style="width:100%; display:flex; gap:10px">
                    <div v-for="(option,i) in inOptions" :key="plotId+'_pump1_protection_option_'+i" style="width:100%">
                      <input type="radio" class="btn-check" name="options" :id="plotId+'_pump1_protection_option_'+i"
                             autocomplete="off" :checked="cdb.data.pump1.protection===option.value"
                             @change="cdb.data.pump1.protection=option.value" :disabled="!cdb.data.pump1.status">
                      <label style="width:100%"
                             :class="{btn:true, 'btn-primary':(cdb.data.pump1.protection===option.value), 'btn-secondary':(cdb.data.pump1.protection!==option.value)}"
                             :for="plotId+'_pump1_protection_option_'+i">
                        <!-- {{option.text}} -->
                        <font-awesome-icon v-if="option.text==='disable'" icon="fas fa-xmark" rotation="90" />
                        <font-awesome-icon v-if="option.text==='normal'" icon="fas fa-equals" rotation="90" />
                        <font-awesome-icon v-if="option.text==='reverse'" icon="fas fa-not-equal" rotation="90" />
                      </label>
                    </div>
                  </div>
                </b-form-group>
              </b-list-group>
            </div>

            <div class="econtainor"
                 :style=" {backgroundColor: cdb.data.pump2.status?'transparent':'#e7ebef', width:'100%', marginBottom:'10px'}">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" value="" id="pump2" v-model="cdb.data.pump2.status">
                <label class="form-check-label" for="pump2">
                  پمپ 2
                </label>
              </div>

              <b-list-group horizontal="md" style="gap:10px;">
                <b-form-group
                  label-size="sm"
                  label="man/auto input"
                  label-for="pump2_mode"
                  style="width:100%;"
                >
                  <div id="pump2_mode" style="width:100%; display:flex; gap:10px">
                    <div v-for="(option,i) in inOptions" :key="plotId+'_pump2_mode_option_'+i" style="width:100%">
                      <input type="radio" class="btn-check" name="options" :id="plotId+'_pump2_mode_option_'+i"
                             autocomplete="off" :checked="cdb.data.pump2.mode===option.value"
                             @change="cdb.data.pump2.mode=option.value" :disabled="!cdb.data.pump2.status">
                      <label style="width:100%"
                             :class="{btn:true, 'btn-primary':(cdb.data.pump2.mode===option.value), 'btn-secondary':(cdb.data.pump2.mode!==option.value)}"
                             :for="plotId+'_pump2_mode_option_'+i">
                        <!-- {{option.text}} -->
                        <font-awesome-icon v-if="option.text==='disable'" icon="fas fa-xmark" rotation="90" />
                        <font-awesome-icon v-if="option.text==='normal'" icon="fas fa-equals" rotation="90" />
                        <font-awesome-icon v-if="option.text==='reverse'" icon="fas fa-not-equal" rotation="90" />
                      </label>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-size="sm"
                  label="protection input"
                  label-for="pump2_protection"
                  style="width:100%;"
                >
                  <div id="pump2_protection" style="width:100%; display:flex; gap:10px">
                    <div v-for="(option,i) in inOptions" :key="plotId+'_pump2_protection_option_'+i" style="width:100%">
                      <input type="radio" class="btn-check" name="options" :id="plotId+'_pump2_protection_option_'+i"
                             autocomplete="off" :checked="cdb.data.pump2.protection===option.value"
                             @change="cdb.data.pump2.protection=option.value" :disabled="!cdb.data.pump2.status">
                      <label style="width:100%"
                             :class="{btn:true, 'btn-primary':(cdb.data.pump2.protection===option.value), 'btn-secondary':(cdb.data.pump2.protection!==option.value)}"
                             :for="plotId+'_pump2_protection_option_'+i">
                        <!-- {{option.text}} -->
                        <font-awesome-icon v-if="option.text==='disable'" icon="fas fa-xmark" rotation="90" />
                        <font-awesome-icon v-if="option.text==='normal'" icon="fas fa-equals" rotation="90" />
                        <font-awesome-icon v-if="option.text==='reverse'" icon="fas fa-not-equal" rotation="90" />
                      </label>
                    </div>
                  </div>
                </b-form-group>
              </b-list-group>
            </div>

            <div class="econtainor" :style=" {backgroundColor: 'transparent', width:'100%', marginBottom:'10px'}">
              <label class="form-check-label" for="CBM">
                عملکرد پمپ ها
              </label>

              <b-list-group horizontal="md" style="gap:10px;">
                <b-form-group
                  label-size="sm"
                  label="maximum number of faults"
                  label-for="pumps_maxFaultCnt"
                  :invalid-feedback="errors.first('pumps_maxFaultCnt')"
                  style="width:100%;"
                >
                  <b-form-input

                    size="sm"
                    name="pumps_maxFaultCnt"
                    type="text"
                    placeholder="enter a number"
                    v-model="cdb.data.pumps.maxFaultCnt"
                    :state="(submitted)? (!errors.has('pumps_maxFaultCnt')): null"
                    v-validate="'required|decimal:10|min_value:0|max_value:100000'"
                    style="width:100%"
                  ></b-form-input>
                </b-form-group>
              </b-list-group>
            </div>

            <div class="econtainor"
                 :style=" {backgroundColor: cdb.data.protection2.status?'transparent':'#e7ebef', width:'100%', marginBottom:'10px'}">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" value="" id="CBM" v-model="cdb.data.protection2.status">
                <label class="form-check-label" for="CBM">
                  حفاظت کارکرد
                </label>
              </div>

              <b-list-group horizontal="md" style="gap:10px;">
                <div class="b-form-group w-100">
                  <label for="greaseInput" class="d-block col-form-label-sm">پمپ 1</label>
                  <input
                    type="text"
                    id="maxOperTimeP1"
                    :class="{'form-control':true, 'form-control-sm':true, 'is-invalid':submitted && errors.has('maxOperTimeP1'), 'is-valid':submitted && !errors.has('maxOperTimeP1')}"
                    v-model="cdb.data.protection2.maxOperTimeP1"
                    placeholder="Enter time interval (s)"
                    :disabled="cdb.data.protection2.status? false:true"
                    name="maxOperTimeP1"
                    v-validate="'required|decimal:10|min_value:0|max_value:100000'"
                  >
                  <div v-if="submitted && errors.has('maxOperTimeP1')" class="invalid-feedback" role="alert">max.
                    operation time of pump 1 is required!
                  </div>
                </div>

                <div class="b-form-group w-100">
                  <label for="greaseInput" class="d-block col-form-label-sm">پمپ 2</label>
                  <input
                    type="text"
                    id="maxOperTimeP2"
                    :class="{'form-control':true, 'form-control-sm':true, 'is-invalid':submitted && errors.has('maxOperTimeP2'), 'is-valid':submitted && !errors.has('maxOperTimeP2')}"
                    v-model="cdb.data.protection2.maxOperTimeP2"
                    placeholder="Enter time interval (s)"
                    :disabled="cdb.data.protection2.status? false:true"
                    name="maxOperTimeP2"
                    v-validate="'required|decimal:10|min_value:0|max_value:100000'"
                  >
                  <div v-if="submitted && errors.has('maxOperTimeP2')" class="invalid-feedback" role="alert">max.
                    operation time of pump 2 is required!
                  </div>
                </div>

              </b-list-group>
            </div>

            <div class="econtainor"
                 :style=" {backgroundColor: cdb.data.protection1.status?'transparent':'#e7ebef', width:'100%'}">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" value="" id="CBM" v-model="cdb.data.protection1.status">
                <label class="form-check-label" for="CBM">
                  پیشگیری از قفل شدن موتور
                </label>
              </div>

              <b-list-group horizontal="md" style="gap:10px;">
                <b-form-group
                  label-size="sm"
                  label="maximum rest time (day)"
                  label-for="pumps_maxRestTime"
                  :invalid-feedback="errors.first('pumps_maxRestTime')"
                  style="width:100%;"
                >
                  <b-form-input
                    :disabled="cdb.data.protection1.status? false:true"
                    size="sm"
                    name="pumps_maxRestTime"
                    type="text"
                    placeholder="enter the time (days)"
                    v-model="cdb.data.protection1.maxRestTime"
                    :state="(submitted)? (!errors.has('pumps_maxRestTime')): null"
                    v-validate="'required|decimal:10|min_value:0|max_value:100000'"
                    style="width:100%"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-size="sm"
                  label="operation time (s)"
                  label-for="pumps_operTime"
                  :invalid-feedback="errors.first('pumps_operTime')"
                  style="width:100%;"
                >
                  <b-form-input
                    :disabled="cdb.data.protection1.status? false:true"
                    size="sm"
                    name="pumps_operTime"
                    type="text"
                    placeholder="enter the time (s)"
                    v-model="cdb.data.protection1.operTime"
                    :state="(submitted)? (!errors.has('pumps_operTime')): null"
                    v-validate="'required|decimal:10|min_value:0|max_value:100000'"
                    style="width:100%"
                  ></b-form-input>
                </b-form-group>
              </b-list-group>
            </div>

          </b-list-group>
        </div>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_schedule'"
      size="lg"
      centered
      hide-header-close
      content-class="shadow"
      :title="sdb.modalTitle"
      @shown="initScheduleModal"
      @hidden="handleScheduleModalReset"
      @ok="handleScheduleModalOk"
    >
      <PerfectScrollbar v-if="sdb.show">
        <!-- <div class="panel_body"> -->
        <div>
          <b-list-group flush>
            <b-list-group-item style="background-color:transparent;color:black; ">
              <b-form-group
                label="Schedule Title"
                label-for="title"
                :invalid-feedback="errors.first('title')"
                style="width:100%;"
              >
                <b-form-input
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  v-model="sdb.title"
                  :state="(submitted)? (!errors.has('title')): null"
                  v-validate="'required|min:2|max:20'"
                  style="width:100%"
                ></b-form-input>
              </b-form-group>

              <div class="mt-4">
                <div style="display: flex; justify-content: end; width:100%">
                  <b-button-group size="sm">
                    <b-button @click="sdb.TSI = 5">5m</b-button>
                    <b-button @click="sdb.TSI = 15">15m</b-button>
                    <b-button @click="sdb.TSI = 30">30m</b-button>
                    <b-button @click="sdb.TSI = 60">1h</b-button>
                    <b-button @click="sdb.TSI = 120">2h</b-button>
                    <b-button @click="sdb.TSI = 240">4h</b-button>
                    <b-button @click="sdb.TSI = 360">6h</b-button>
                    <b-button @click="sdb.TSI = 720">12h</b-button>
                    <b-button @click="sdb.TSI = 1440">24h</b-button>
                  </b-button-group>
                </div>


                <div id="container">
                  <ejs-schedule id="Schedule" height="450px" ref="scheduleObj" :selectedDate="sdb.selectedDate"
                                :eventSettings="sdb.eventSettings" :dataBinding="onDataBinding"
                                :currentView="sdb.currentView" :workDays="sdb.workDays" :key="sdb.scheduleKey"
                                :timeScale="sdb.timeScale"
                                :cellClick="oncellClick" :eventClick="onEventClick" :showQuickInfo="false">
                    <e-views>
                      <e-view option="Week"></e-view>
                    </e-views>
                  </ejs-schedule>
                </div>
              </div>

            </b-list-group-item>
          </b-list-group>
        </div>
      </PerfectScrollbar>
    </b-modal>

    <b-modal
      :id="plotId+'_scheduleEditor'"
      size="md"
      centered
      hide-header-close
      content-class="shadow"
      :title="scheduleRecordType+' Event'"
      @shown="initScheduleEditorModal"
    >
      <template #modal-footer>
        <div class="d-flex w-100 justify-content-between">
          <div>
            <b-button v-if="scheduleRecordType==='Edit'" variant="danger" class="float-right"
                      @click="handleScheduleEditorModalDelete"> Delete
            </b-button>
          </div>
          <div>
            <b-button variant="secondary" style="margin-right:10px" @click="handleScheduleEditorModalReset"> Cancel
            </b-button>
            <b-button variant="primary" @click="handleScheduleEditorModalOk"> Ok</b-button>
          </div>
        </div>
      </template>

      <div>
        <div class="e-float-input mb-3">
          <input type="text" required v-model="scheduleRecord.Subject" />
          <span class="e-float-line"></span>
          <label class="e-float-text">Name</label>
        </div>

        <div>
          <input type="text" tabindex="1" id="ddl" />
        </div>

        <div class="d-flex mb-3" style="gap: 15px">
          <div id="startDTPcontainer" class="w-100">
            <input id="startDTP" type="text" />
          </div>

          <div id="endDTPcontainer" class="w-100">
            <input id="endDTP" type="text" />
          </div>
        </div>

        <ejs-recurrenceeditor locale="en" id="editor" ref="EditorObj" :change="onChangeRecurrenceeditor"></ejs-recurrenceeditor>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import myComponent_4 from "./myComponent_4.vue";
import { v4 as uuidv4 } from "uuid";

import {
  ScheduleComponent,
  SchedulePlugin,
  Week,
  Month,
  Year,
  Agenda,
  Resize,
  DragAndDrop,
  ActionEventArgs,
  RecurrenceEditor
} from "@syncfusion/ej2-vue-schedule";
import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import { createElement } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
// import { Slider } from '@syncfusion/ej2-inputs';
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
// import RoundSlider from "vue-round-slider";

export default {
  components: {
    myComponent_4, //transform: scale(.75);
    "ejs-schedule": ScheduleComponent,
    PerfectScrollbar

  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number
  },
  provide: {
    schedule: [Week, Year, Month, Agenda, DragAndDrop, Resize]
  },
  data() {
    return {
      UC: 0,
      isDisabled: true,

      status: {
        error: false,
        msg: "no setting is selected"
      },
      waterMark: "Select a datetime",

      pannel: {
        settingVis: false,
        settingsOptions: [],
        selectedSetting: null,
        scheduleTitle: "",
        manual: {
          buttons: [
            {
              title: "پمپ 1",
              value: "0",
              port: "port1"
            },
            {
              title: "پمپ 2",
              value: "0",
              port: "port2"
            }
          ]
        },
        status: {
          indicators: [
            {
              title: "مخزن اصلی",
              value: "2",
              tag: "stat_1"
            },
            {
              title: "مخزن مصرفی روزانه",
              value: "1",
              tag: "stat_2"
            },
            {
              title: "مخزن سرریز",
              value: "2",
              tag: "stat_3"
            },
            {
              title: "پمپ 1",
              value: "2",
              tag: "stat_4"
            },
            {
              title: "خطا پمپ 1",
              value: "0",
              tag: "stat_5"
            },
            {
              title: "پمپ 2",
              value: "1",
              tag: "stat_6"
            },
            {
              title: "خطا پمپ 2",
              value: "1",
              tag: "stat_7"
            },
            {
              title: "وضعیت",
              value: "1",
              tag: "stat_8"
            }
          ]
        }
      },

      tankLevelA: 100,

      scheduleRecord: {
        Id: 1,
        Subject: "",
        StartTime: null,
        EndTime: null,
        IsAllDay: false,
        RecurrenceRule: null,
        parameters: {
          selectedSetting: null,
          param_1: []

        }
      },
      scheduleRecordType: null,

      myData: {
        datas: [],
        attribute: {
          title: "controller"
        },
        parameters: {
          enable: false,
          schedules: [],
          settings: [],
          selectedSchedule: "",
          activeEvent: "",
          controlMode: "",
          automatic: {},
          manual: {}

        },
        status: {}
      },
      activeTab: "tab1",
      dataReady: false,

      groups: [],

      inOptions: [
        { text: "disable", value: "0" },
        { text: "normal", value: "1" },
        { text: "reverse", value: "2" }
      ],
      controlOptions: [
        { text: "Manual", value: "manual" },
        { text: "Automatic", value: "automatic" },
        { text: "Schedule", value: "schedule" }
      ],
      scheduleFields: [{ key: "isSelected", label: "" }, { key: "title", label: "" }, { key: "modify", label: "" }],
      controllerFields: [{ key: "isSelected", label: "" }, { key: "title", label: "" }, { key: "modify", label: "" }],
      modalData: {},
      cdb: {},
      sdb: {},
      submitted: false,
      successful: false,
      message: ""
    };
  },
  methods: {
    getData() {
      var dids = [];
      for (var d of this.myData.datas) {
        dids.push(d._id);
      }
      if (dids.length > 0) {
        this.$store.dispatch("panel/getData", { id: dids }).then(
          datas => {
          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    changeTab() {
      if (this.activeTab === "tab1")
        this.activeTab = "tab2";
      else
        this.activeTab = "tab1";
    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    changeControlMode() {
      if (this.myData.parameters.controlMode === "automatic") {
        this.updateAutoPanel();
        this.updateStatus();
      } else if (this.myData.parameters.controlMode === "schedule") {
        this.updateSchPanel();
        this.updateStatus();
      } else if (this.myData.parameters.controlMode === "manual") {
        this.updateManPanel();
      }
      // console.log('change control mode',this.myData.parameters.controlMode)
      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];

      console.log("panel/setData1", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );

    },
    onScheduleSelected(item) {
      this.myData.parameters.selectedSchedule = item._id;
      this.$refs.scheduleTable.refresh();

      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];
      console.log("panel/setData2", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );

    },
    addController: function(type) {
      this.modalData = { type: "Add", index: null };
      this.$root.$emit("bv::show::modal", this.plotId + "_control");
    },
    editController: function(index) {
      this.modalData = { type: "Edit", index: index };
      this.$root.$emit("bv::show::modal", this.plotId + "_control");
    },
    addSchedule: function(type) {
      this.modalData = { type: "Add", index: null };
      this.$root.$emit("bv::show::modal", this.plotId + "_schedule");
    },
    editSchedule: function(index) {
      this.modalData = { type: "Edit", index: index };
      this.$root.$emit("bv::show::modal", this.plotId + "_schedule");
    },
    removeSchedule: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this schedule")
        .then(value => {
          if (value === true) {

            var rmitem = this.myData.parameters.schedules[index]._id;
            this.myData.parameters.schedules.splice(index, 1);

            if (JSON.stringify(this.myData.parameters.selectedSchedule) === JSON.stringify(rmitem)) {
              var sclen = this.myData.parameters.schedules.length;
              if (sclen > 0) {
                if (index < sclen)
                  this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[index]._id;
                else if (index == sclen)
                  this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[sclen - 1]._id;
              } else {
                this.myData.parameters.selectedSchedule = null;
              }
            }

            var sdata = [{
              id: this.myData.datas[0]._id,
              value: {
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections: this.myData.connections,
                type: this.myData.type
              }
            }];
            console.log("panel/setData3", sdata);
            this.$store.dispatch("panel/setData", { data: sdata })
              .then(
                data => {
                },
                error => {
                  var message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
          }
        })
        .catch(err => {
        });
    },
    removeController: function(index) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this controller")
        .then(value => {
          if (value === true) {

            var rmitem = this.myData.parameters.settings[index]._id;
            this.myData.parameters.settings.splice(index, 1);
            var sdata = [{
              id: this.myData.datas[0]._id,
              value: {
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections: this.myData.connections,
                type: this.myData.type
              }
            }];
            console.log("panel/setData4", sdata);
            this.$store.dispatch("panel/setData", { data: sdata })
              .then(
                data => {
                },
                error => {
                  var message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
          }
        })
        .catch(err => {
        });
    },
    onChangeStatus(state) {
      var sdata = [{
        id: this.myData.datas[0]._id,
        value: {
          status: this.myData.status,
          parameters: this.myData.parameters,
          connections: this.myData.connections,
          type: this.myData.type
        }
      }];
      console.log("panel/setData100", sdata);
      this.$store.dispatch("panel/setData", { data: sdata })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    // control modal =============================================================
    initControlModal: function() {
      this.cdb = {
        show: false,
        modalTitle: this.modalData.type + " Controller",
        type: this.modalData.type,
        title: "",
        cid: "",
        data: {
          pump1: {
            status: false,
            mode: "0",
            protection: "0"
          },
          pump2: {
            status: false,
            mode: "0",
            protection: "0"
          },
          pumps: {
            maxFaultCnt: "0"
          },
          protection1: {
            status: false,
            maxRestTime: "",
            operTime: ""
          },
          protection2: {
            status: false,
            maxOperTimeP1: "",
            maxOperTimeP2: ""
          }
        }
      };
      if (this.cdb.type === "Add") {
        this.cdb.title = "";
        this.cdb.cid = uuidv4();
      } else if (this.cdb.type === "Edit") {
        this.cdb.cid = this.myData.parameters.settings[this.modalData.index]._id;
        this.cdb.title = this.myData.parameters.settings[this.modalData.index].title;
        this.cdb.data = this.myData.parameters.settings[this.modalData.index].data;
      }
      this.cdb.show = true;
    },
    handleControlModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleControlModalSubmit();
    },
    handleControlModalSubmit() {
      // console.log('submit',this.modalData.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.modalData.type === "Add")
            this.myData.parameters.settings.push({ _id: this.cdb.cid, title: this.cdb.title, data: this.cdb.data });
          else if (this.modalData.type === "Edit")
            this.myData.parameters.settings[this.modalData.index] = {
              _id: this.cdb.cid,
              title: this.cdb.title,
              data: this.cdb.data
            };

          var sdata = [{
            id: this.myData.datas[0]._id,
            value: {
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections: this.myData.connections,
              type: this.myData.type
            }
          }];
          // console.log(sdata)
          console.log("panel/setData5", sdata);
          this.$store.dispatch("panel/setData", { data: sdata })
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId + "_control");
                });
              },
              error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
        }
      });
    },
    handleControlModalReset() {
      this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    // schedule modal =============================================================
    initScheduleModal: function() {
      this.sdb = {
        show: false,
        modalTitle: this.modalData.type + " Schedule",
        type: this.modalData.type,
        title: "",
        sid: "",
        data: [],

        scheduleKey: 0,
        selectedDate: new Date(),
        currentView: "Week",
        workDays: [1, 2, 3, 4, 5, 6, 7],
        eventSettings: { dataSource: [] },
        timeScale: {
          enable: true,
          interval: 360,
          slotCount: 3
        },
        TSI: 360
      };
      if (this.sdb.type === "Add") {
        this.sdb.title = "";
        this.sdb.sid = uuidv4();

        if (this.myData.parameters.schedules.length == 0)
          this.myData.parameters.selectedSchedule = this.sdb.sid;
      } else if (this.sdb.type === "Edit") {
        this.sdb.sid = this.myData.parameters.schedules[this.modalData.index]._id;
        this.sdb.title = this.myData.parameters.schedules[this.modalData.index].title;
        this.sdb.data = this.myData.parameters.schedules[this.modalData.index].data;
      }
      this.sdb.eventSettings.dataSource = this.sdb.data;
      this.sdb.scheduleKey = this.sdb.scheduleKey + 1;
      this.sdb.show = true;
    },
    handleScheduleModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleScheduleModalSubmit();
    },
    handleScheduleModalSubmit() {
      // console.log('submit',this.modalData.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.modalData.type === "Add")
            this.myData.parameters.schedules.push({ _id: this.sdb.sid, title: this.sdb.title, data: this.sdb.data });
          else if (this.modalData.type === "Edit")
            this.myData.parameters.schedules[this.modalData.index] = {
              _id: this.sdb.sid,
              title: this.sdb.title,
              data: this.sdb.data
            };

          var sdata = [{
            id: this.myData.datas[0]._id,
            value: {
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections: this.myData.connections,
              type: this.myData.type
            }
          }];
          // console.log(sdata)
          console.log("panel/setData6", sdata);
          this.$store.dispatch("panel/setData", { data: sdata })
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId + "_schedule");
                });
              },
              error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
        }
      });
    },
    handleScheduleModalReset() {
      this.sdb.modalTitle = "",
        this.sdb.title = "",
        this.sdb.sid = "",
        this.sdb.data = [],
        this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    onDataBinding(e) {
      this.sdb.data = e.result;
      // console.log('data binding',this.sdb.data)
    },
    onEventClick: function(args) {
      // console.log('event click',args.event)
      this.scheduleRecord.Subject = args.event.Subject;

      this.scheduleRecord.StartTime = args.event.StartTime;
      this.scheduleRecord.EndTime = args.event.EndTime;
      this.scheduleRecord.RecurrenceRule = args.event.RecurrenceRule;
      this.scheduleRecord.Id = args.event.Id;
      this.scheduleRecord.parameters = args.event.parameters;
      this.scheduleRecordType = "Edit";
      this.$root.$emit("bv::show::modal", this.plotId + "_scheduleEditor");
    },
    oncellClick: function(args) {
      // console.log('cell click')
      this.scheduleRecord.Subject = "";
      this.scheduleRecord.StartTime = args.startTime;
      this.scheduleRecord.EndTime = args.endTime;
      this.scheduleRecord.RecurrenceRule = null;
      this.scheduleRecord.Id = uuidv4();
      this.scheduleRecord.parameters = {
        selectedSetting: null,
        param_1: []

      };

      this.scheduleRecordType = "Add";
      this.$root.$emit("bv::show::modal", this.plotId + "_scheduleEditor");
    },
    initScheduleEditorModal() {
      const startDateTimePicker = new DateTimePicker({
        value: this.scheduleRecord.StartTime, // Set the initial date
        placeholder: "Start time",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.StartTime = args.value;
          console.log("newDate:", this.scheduleRecord.StartTime);
        }
      });

      const endDateTimePicker = new DateTimePicker({
        value: this.scheduleRecord.EndTime, // Set the initial date
        placeholder: "End time",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.EndTime = args.value;
          console.log("newDate:", this.scheduleRecord.EndTime);
        }
      });

      var dropDownOptions = [{ text: "disable", value: "disable" }];
      for (var controller of this.myData.parameters.settings) {
        dropDownOptions.push({ text: controller.title, value: controller._id });
      }

      const dropDownListObject = new DropDownList({
        placeholder: "یکی از تنظیمات را انتخاب کنید",
        value: this.scheduleRecord.parameters.selectedSetting,
        fields: { text: "text", value: "value" },
        dataSource: dropDownOptions,
        placeholder: "Setting",
        floatLabelType: "Auto",
        change: (args) => {
          this.scheduleRecord.parameters.selectedSetting = args.value;
        }
      });
      startDateTimePicker.appendTo("#startDTP");
      endDateTimePicker.appendTo("#endDTP");
      dropDownListObject.appendTo("#ddl");
      let recObject = this.$refs.EditorObj;
      recObject.setRecurrenceRule(this.scheduleRecord.RecurrenceRule);
    },
    handleScheduleEditorModalOk() {
      if (this.scheduleRecordType === "Add") {
        // console.log(this.scheduleRecord)
        this.$refs.scheduleObj.addEvent([this.scheduleRecord]);
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
      } else if (this.scheduleRecordType === "Edit") {

        if (this.scheduleRecord.RecurrenceRule) {
          this.$refs.scheduleObj.saveEvent(this.scheduleRecord, "EditSeries");
        } else {
          // console.log('EditOccurrence',this.scheduleRecord)
          this.$refs.scheduleObj.saveEvent(this.scheduleRecord);
        }
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
      }
    },
    handleScheduleEditorModalReset() {

      this.scheduleRecord = {
        Id: null,
        Subject: "",
        StartTime: null,
        EndTime: null,
        IsAllDay: false,
        RecurrenceRule: null
      },

        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId + "_scheduleEditor");
        });
    },
    handleScheduleEditorModalDelete() {
      if (this.scheduleRecord.RecurrenceRule) {
        this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id, "DeleteSeries");
      } else {
        // console.log('EditOccurrence',this.scheduleRecord)
        this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id);
      }
      this.$nextTick(() => {
        this.$bvModal.hide(this.plotId + "_scheduleEditor");
      });
    },
    onChangeStartTime(arg) {
      this.scheduleRecord.StartTime = arg.value;
    },
    onChangeEndTime(arg) {
      this.scheduleRecord.EndTime = arg.value;
    },
    onChangeRecurrenceeditor(arg) {
      this.scheduleRecord.RecurrenceRule = arg.value;
      // console.log('onChangeRecurrenceeditor:',this.scheduleRecord.RecurrenceRule)
    },
    //--------------------------------------------------
    updateAutoPanel() {
      this.pannel.settingVis = true;
      this.pannel.selectedSetting = this.myData.parameters.automatic.selectedSetting;
      this.pannel.settingsOptions = [{ text: "select a setting", value: null, disabled: true }];
      for (var controller of this.myData.parameters.settings) {
        this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
      }
    },
    updateSchPanel() {
      var eventName = "";
      var scheduleName = "";
      var activeEvent = this.myData.parameters.activeEvent;
      this.pannel.settingVis = false;

      var indx1 = -1;
      if (this.myData.parameters.selectedSchedule) {
        var indx1 = this.myData.parameters.schedules.findIndex(x => x._id === this.myData.parameters.selectedSchedule);
      }
      if (indx1 !== -1) {
        var schedule = this.myData.parameters.schedules[indx1];
        var scheduleName = schedule.title;
        if (activeEvent) {
          var indx2 = schedule.data.findIndex(x => x.Id === activeEvent);
          if (indx2 != -1) {
            var eventName = schedule.data[indx2].Subject;
            this.pannel.settingVis = true;

            var parameters = schedule.data[indx2].parameters;
            if (parameters) {
              this.pannel.selectedSetting = parameters.selectedSetting;
              this.pannel.settingsOptions = [{ text: "select a setting", value: null, disabled: true }];
              for (var controller of this.myData.parameters.settings) {
                this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
              }
            }
          }
        }
      }
      this.pannel.scheduleTitle = `${scheduleName} ${eventName ? "/" : ""} ${eventName}`;
    },
    updateManPanel() {
      var keys = Object.keys(this.myData.parameters.manual);
      keys.forEach(key => {
        var value = this.myData.parameters.manual[key];
        var indx = this.pannel.manual.buttons.findIndex(x => x.port === key);
        if (indx != -1) {
          this.pannel.manual.buttons[indx].value = value;
        }
      });
    },
    updateStatus() {
      var keys = Object.keys(this.myData.parameters.status);
      keys.forEach(key => {
        var value = this.myData.parameters.status[key];
        var indx = this.pannel.status.indicators.findIndex(x => x.tag === key);
        if (indx != -1) {
          this.pannel.status.indicators[indx].value = value;
        }
      });
    },
    changeStatus(key, value) {
      this.myData.parameters.status[key] = value;
      var indx = this.pannel.status.indicators.findIndex(x => x.tag === key);
      if (indx != -1) {
        this.pannel.status.indicators[indx].value = value;
      }
      this.saveData("status");
    },
    //---------------------------------------------------
    saveData(type) {
      var update = false;
      if (type === "autoPanel" && (this.myData.parameters.controlMode === "automatic")) {
        var bc = JSON.stringify(this.myData.parameters.automatic);
        this.myData.parameters.automatic = {
          selectedSetting: this.pannel.selectedSetting
        };
        var ac = JSON.stringify(this.myData.parameters.automatic);
        if (ac !== bc)
          update = true;
      } else if (type === "manPanel" && (this.myData.parameters.controlMode === "manual")) {
        this.myData.parameters.manual = {};
        for (var button of this.pannel.manual.buttons) {
          this.myData.parameters.manual[button.port] = button.value;
        }
        update = true;
      } else if (type === "status" && (this.myData.parameters.controlMode === "automatic")) {
        update = true;
      }

      if (update) {
        var sdata = [{
          id: this.myData.datas[0]._id,
          value: {
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections: this.myData.connections,
            type: this.myData.type
          }
        }];
        console.log("panel/setData7", sdata);
        this.$store.dispatch("panel/setData", { data: sdata })
          .then(
            data => {
            },
            error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
      }
    },
    tankLevel(index) {
      if (this.pannel.status.indicators[index].value === "0") {
        return {
          "--tank-level": 0.1,
          "--tank-color": "red"
        };
      } else if (this.pannel.status.indicators[index].value === "1") {
        return {
          "--tank-level": 0.5,
          "--tank-color": "green"
        };
      } else if (this.pannel.status.indicators[index].value === "2") {
        return {
          "--tank-level": 0.9,
          "--tank-color": "red"
        };
      }
    },
    ovtankLevel(index) {
      if (this.pannel.status.indicators[index].value === "0") {
        return {
          "--tank-level": 0.1,
          "--tank-color": "green"
        };
      } else if (this.pannel.status.indicators[index].value === "1") {
        return {
          "--tank-level": 0.5,
          "--tank-color": "green"
        };
      } else if (this.pannel.status.indicators[index].value === "2") {
        return {
          "--tank-level": 0.9,
          "--tank-color": "red"
        };
      }
    }
  },

  mounted() {

    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
    }
    // console.log('===========control myData: ', this.myData)
    this.getData();
  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    TSI() {
      return this.sdb.TSI;
    },
    settings() {
      if (this.myData.parameters) {
        return this.myData.parameters.settings;
      }
    },
    selectedSetting() {
      return this.pannel.selectedSetting;
    },
    tankLevelStyleA() {
      return this.tankLevel(0);
    },
    tankLevelStyleB() {
      return this.tankLevel(1);
    },
    tankLevelStyleC() {
      return this.ovtankLevel(2);
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    updatedData(newValue) {
      // console.log('newdata')
      if (this.myData.datas.length > 0) {
        for (var Data of newValue) {
          // console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
          if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)) {
            var LMD = JSON.stringify(this.myData);

            this.myData = { ...this.data, ...Data.value };
            if (!this.myData["parameters"]) {
              this.myData["parameters"] = {

                schedules: [],
                selectedSchedule: "",
                activeEvent: "",
                settings: [],
                controlMode: "manual",
                automatic: {},
                manual: {},
                status: {}

              };
            }
            var CMD = JSON.stringify(this.myData);
            if (LMD !== CMD) {

              if (this.myData.parameters.controlMode === "automatic") {

                this.updateAutoPanel();
                this.updateStatus();
              } else if (this.myData.parameters.controlMode === "schedule") {
                this.updateSchPanel();
                this.updateStatus();
              } else if (this.myData.parameters.controlMode === "manual") {
                this.updateManPanel();
              }
              this.dataReady = true;

            }


          }
        }
      }
    },
    TSI(newValue) {
      // console.log('TSI',newValue)
      this.sdb.timeScale.interval = newValue;
      this.sdb.eventSettings.dataSource = this.sdb.data;
      this.sdb.scheduleKey = this.sdb.scheduleKey + 1;
      // console.log('TSI',newValue)
    },
    resized() {
    },
    settings(newvalue) {
      if (newvalue) {
        this.pannel.settingsOptions = [{ text: "select a setting", value: null, disabled: true }];
        for (var controller of this.myData.parameters.settings) {
          this.pannel.settingsOptions.push({ text: controller.title, value: controller._id });
        }
      }

    },
    selectedSetting(newval) {
      // console.log('a')
      this.saveData("autoPanel");
    }
  }
};
</script>

<style scoped
       src="@/assets/styles/ledStyle.css">

</style>

<style>
.disabled-div {
  pointer-events: none;
  /* Add any additional styling for a disabled appearance */
  opacity: 0.5; /* Example: reduce opacity for a disabled look */
}

.component-main-holder {
  position: absolute;
  z-index: 7;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.component-hole {
  position: absolute;
  z-index: 8;
  /* background: #ffffff; */
  border-radius: 50%;
  /* left:  30%;
  right: 30%;
  bottom:30%;
  top: 30%; */
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.component-hole .text {
  display: flex;
  justify-content: center;
  font-size: 40px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-bottom: 0px;
}

.component-hole .note {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px !important;
  /* font-weight: bold !important; */
  color: #ffffff !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
}


.modal-footer {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.rs-custom.rs-bar {
  z-index: 4;
}

.rs-custom .rs-border {
  border-color: #ababab;
  margin-left: 18px;
  width: 5px;
}

.rs-custom .rs-border.rs-bold {
  width: 10px;

}

.rs-custom.selected .rs-border {
  border-color: #ff9500;
}


.rs-custom1.rs-bar {
  z-index: 3;
}

.rs-custom1 .rs-border {
  border-color: transparent;
  margin-left: 30px;
  width: 5px;
}

.rs-custom1 .rs-border.rs-bold {
  width: 30px;
  width: 30px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: bold;
}

.rs-custom1 .rs-border.rs-bold.mirrored {
  transform: translate(0, -50%) rotate(180deg);
  justify-content: end;
}


.rs-custom1.selected .rs-border {
  color: #ff9500;
}


.rs-pointer.rs-bar {
  z-index: 5;
}

.rs-pointer .rs-border {
  width: 0px;
  /* height: 25px !important; */
  position: absolute;
  top: 50%;
  /* left: -30px; */
  transform: translate(0, -50%);

  /* border-radius: 5px; */
  border: 1px solid rgb(4, 202, 169) !important;
  font-family: Segoe UI;
  font-size: 10px;
  font-weight: bold;
}

.rs-pointer .rs-border:before {
  content: "";
  position: absolute;
  bottom: 50%;
  right: -5px;
  border: 10px solid rgb(4, 202, 169);
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  transform: translateY(50%);
}

.rs-value.rs-tooltip.rs-tooltip-text {
  color: rgb(4, 202, 169) !important;
  font-family: Segoe UI !important;
  font-size: 40px !important;
  font-weight: bold !important;
}

span.rs-number {
  position: absolute;
  top: -12px;
  left: -26px;
  font-family: Segoe UI;
  font-size: 24px;
  font-weight: bold;
}
</style>


<style scoped>

.panel_container {
  width: 500px;
  height: 500px;
}

.panel_container .panel_header {
  /* padding: 10px; */
  height: 10%;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-variant: small-caps;
  border-bottom: 2px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_container .panel_body1 {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  /* border-bottom: 2px solid rgb(166, 41, 41); */
  display: flex;
  justify-content: space-between;
}

.panel_container .panel_col {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_container .panel_colf {
  height: 100%;
  width: 100%;
}

.panel_container .panel_footer {
  transition: height 1s;
  height: 90%
}

.buttonHolder {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(150, 150, 150, 0.368);
  border-radius: 10px;
}

.radioboxGroup {
  margin: 20px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radiobtnGroup {
  width: 100%;
  height: 50px;
  display: inline-block;
  margin: 2px
}

.radiobtnGroup .radioinpGroup {
  display: none;
}

.radiobtnGroup .radiospanGroup:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtnGroup .radiospanGroup:before {
  background-color: rgb(198, 22, 22);
}

.radiobtnGroup .radiospanGroup {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(150, 150, 150, 0.368);
  border-radius: 10px;
  /* background-color: #ddd; */
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 5px;

}

.radiobtnGroup input:checked + span {
  background-color: hsl(25.5, 94.8%, 54.3%);
  color: rgb(255, 255, 255)
}

.radiobox {
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.radiobtn {
  width: 50%;
  height: 100%;
  display: inline-block;
}

.radiobtn .radioinp {
  display: none;
}

.radiobtn .radiospan:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtn .radiospan:before {
  background-color: rgb(198, 22, 22);
}

.radiobtn .radiospan {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0)
}

.radiobtn input:checked + span {
  background-color: hsl(0, 0%, 0%);
  color: rgb(255, 255, 255)
}

.tab-content {
  height: 100%;

}

/* .slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateX(10px);
  opacity: 0;
} */
.fcontainor {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #ced4da;
  padding: 2%;
  border-radius: 0.5rem;
  margin-top: 2%;
}

.econtainor {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 0.5rem;
  /* margin-top: 10px; */
}


.panel_body {
  /* padding: 0px 2% 0px 2%; */
  /* height: 68vh; */
  overflow-y: auto;
}


</style>

<style scoped>

.remove {
  cursor: pointer;
  /* color: rgb(212, 64, 27); */
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}

.cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2em;

}
</style>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';

.wrapper {
  max-width: 250px;
  margin: 0 auto;
}

/* .e-subject-container{
     width: 100% !important;
} */
.custom-event-editor .e-textlabel {
  padding-right: 15px;
  text-align: right;
}

.custom-event-editor td {
  padding: 7px;
  padding-right: 16px;
}
</style>

<style>
.e-slider-container {
  padding: 5px 10% 5px 10%;
}

.e-subject-container {
  width: 100% !important;
}

.custom-field-row {
  padding-bottom: 12px;
}

.custom-label1 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
}

.custom-label2 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  margin-top: 20px;
}

.custom-label3 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.flex-container {
  display: flex;
  padding-bottom: 12px;
  width: 100%;
  justify-content: space-between;
}

.field-container {
  width: 30%;
}

.field-container-controller {
  width: 48%;
  margin-bottom: 10px;
}

.panel_header {
  padding: 10px;
  height: 60px;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-variant: small-caps;
  border-bottom: 2px solid rgb(210, 210, 210);
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel_col {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  height: 25px;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  font-size: 13px;
}


/* .e-input-group.e-control-wrapper.e-float-input {
  color: royalblue;

}

.e-float-text::after {
  color: royalblue;

}

.e-float-line::before {
    background: royalblue !important;
}

.e-float-line::after {
    background: royalblue !important;
}

.e-float-text.e-label-top{
  color: royalblue !important;

} */
</style>

<style scoped>
.led-red {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #940;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0 2px 12px;
}

.led-yellow {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #A90;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #660 0 -1px 9px, #DD0 0 2px 12px;
}

.led-green {
  /* margin: 20px auto; */
  width: 12px;
  height: 12px;
  background-color: #690;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0 2px 12px;
}

.indicator {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 .125em .25em rgb(0 0 0 / .3);
  justify-content: space-between;
}

.indicator-label {
  width: 100%;
  height: 30%;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal !important;
  font-weight: 200;
  font-size: 12px;
}

.indicator-val {

  width: 100%;
  /* height:70%;  */
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal !important;
  font-weight: 700;
  font-size: 18px;
}

</style>

<style scoped>
.tk { /*Liquid Section*/
  --tank-level: 65%;
  --tank-color: 'red';
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-top:50px; */
  margin: 0 auto;
  background: var(--tank-color);
  border-radius: 100%/30px;
  border: 1px solid #000;
  text-align: center;
  z-index: 1;
  overflow: hidden;

}

.lq {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height:60%; */
  z-index: -1;
  background: rgb(173, 173, 173);
  height: calc(30px + (1 - var(--tank-level)) * calc(100% - 30px));
  /* top:-2px; */
  border-radius: 100%/30px;
  border-bottom: 1px solid #000;
}

.tk:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  z-index: -1;
  /* background:rgba(150, 44, 44, 0.8); */
  border: 1px solid #000;
  border-radius: 100%; /*makes circle at top*/
}
</style>