<template>
  <div style="height: 100%; width: 100%">
    <div class="card-header" style="min-height: 40px">
      <div class="row">
        <div class="col-8">
          <p class="mb-0">
            {{ myData.attribute.title }}
          </p>
        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="edit" @click="editWidget">
            <i><font-awesome-icon icon="fas fa-pencil" /></i>
          </span>
        </div>
<!--        <div class="col-2 text-center" v-if="editable">-->
<!--          <span class="remove" @click="removeWidget">-->
<!--            <i><font-awesome-icon icon="fas fa-xmark" /></i>-->
<!--          </span>-->
<!--        </div>-->
      </div>
    </div>

    <div
      class="card-body p-1 d-flex justify-content-center align-items-center"
      style="height: calc(100% - 40px); width: 100%"
    >
      <div :ref="plotId" :id="plotId" style="height: 100%; width: 100%"></div>
    </div>

    <b-modal
      :id="plotId"
      size="lg"
      centered
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="p-3">
            <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
              <li
                v-for="(item, index) in tabs"
                class="mga-tab-nav-item"
                @click="activeTab = item.value"
                :key="index"
              >
                <a
                  class="mga-tab-nav-link"
                  :class="{ active: activeTab === item.value }"
                  :id="item.name"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ item[lang] }}
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'tab1'" class="h-100">
                  <div fluid class="container-fluid p-0 h-100">
                    <b-table
                      ref="table1"
                      :items="myData.datas"
                      :fields="groupFields"
                      thead-class="hidden_header"
                      responsive="sm"
                      small
                    >
                      <template #cell(modify)="row">
                        <div style="display: flex; justify-content: flex-end">
                          <b-button
                            pill
                            size="sm"
                            variant="outline-danger"
                            @click="removeItem(row.index)"
                            class="mr-2"
                            style="
                              margin-inline: 10px;
                              outline: none !important;
                              border: none;
                              box-shadow: none;
                            "
                          >
                            <b-icon
                              icon="trash-fill"
                              aria-hidden="true"
                            ></b-icon>
                          </b-button>
                        </div>
                      </template>
                      <template #cell(color)="row">
                        <div class="d-flex align-items-center p-2">
                          <ejs-textbox
                            :value="row.item.color"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            v-model="row.item.color"
                            type="color"
                          ></ejs-textbox>
                        </div>
                      </template>

                      <template #cell(title)="row">
                        <div class="d-flex align-items-center p-2">
                          <ejs-textbox
                            :value="row.item.title"
                            :enableRtl="lang === 'per'"
                            floatLabelType="Auto"
                            v-model="row.item.title"
                          ></ejs-textbox>
                        </div>
                      </template>

                      <!-- <template #cell(mode)="row" >
                        <select id="mySelect" v-model="row.item.mode" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in modeList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template>
                      <template #cell(shape)="row" >
                        <select id="mySelect" v-model="row.item.shape" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in shapeList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template>
                      <template #cell(dash)="row" >
                        <select id="mySelect" v-model="row.item.dash" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in dashList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template> -->
                    </b-table>
                    <div
                      style="
                        display: flex;
                        justify-content: right;
                        align-items: center;
                      "
                    >
                      <b-button
                        @click="addItem"
                        size="sm"
                        variant="outline-danger"
                        style="
                          width: 20px;
                          padding: 0px;
                          outline: none !important;
                          border: none;
                          box-shadow: none;
                        "
                      >
                        <i>
                          <font-awesome-icon icon="fas fa-plus" />
                        </i>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'tab2'" class="h-100">
                  <div fluid class="container-fluid p-0 h-100">
                    <div class="row p-2">
                      <div class="col-12 col-md-6">
                        <ejs-textbox
                          :value="myData.attribute.title"
                          :placeholder=" dict.title[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.title"
                        ></ejs-textbox>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-textbox
                          :value="myData.attribute.fontColor"
                          :placeholder=" dict.font[lang]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          type="color"
                          v-model="myData.attribute.fontColor"
                        ></ejs-textbox>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-dropdownlist
                          :placeholder="dict.showlegend[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="showlegendList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute.showlegend"
                          @change="(val)=>{myData.attribute.showlegend=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-dropdownlist
                          :placeholder="dict.textinfo[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="textinfoList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute.textinfo"
                          @change="(val)=>{myData.attribute.textinfo=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-dropdownlist
                          :placeholder="dict.insidetextorientation[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="insidetextorientationList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute.insidetextorientation"
                          @change="(val)=>{myData.attribute.insidetextorientation=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-dropdownlist
                          :placeholder="dict.textposition[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="textinfoList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute.textposition"
                          @change="(val)=>{myData.attribute.textposition=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-12 col-md-6">
                        <ejs-dropdownlist
                          :placeholder="dict.hole[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="holeList"
                          floatLabelType="Auto"
                          :fields="{ text: lang, value: 'value' }"
                          v-model="myData.attribute.hole"
                          @change="(val)=>{myData.attribute.hole=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>
    <dataPickerModal :plotId="plotId" />
  </div>
</template>

<script>
/* eslint-disable */

import Plotly from "plotly.js-dist/plotly";
import dataPickerModal from "./widgetPickDataModal.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  name: "CustomPath",
  components: {
    dataPickerModal
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number
  },
  data() {
    return {
      activeTab: "tab1", // Set the initial active tab
      modalTitle: { eng: "Edit Pie Chart", per: "ویرایش چارت" },
      tabs:[
        {per:'داده',eng:'data',value:'tab1'},
        {per:'ویژگی',eng:'attribute',value:'tab2'},
      ],
      myData: {
        datas: [],
        attribute: {
          title: "pie chart",
          textinfo: "label+percent",
          textposition: "inside",
          insidetextorientation: "auto",
          hole: "0",
          showlegend: false
        }
      },


      myDataTmp: {},

      groupFields: [
        { key: "name", label: "" },
        { key: "color", label: "" },
        { key: "title", label: "" },

        { key: "modify", label: "" }],

      submitted: false,
      successful: false,
      message: "",

      chartData: { dids: [], datasets: [] },

      textinfoList: [
        { per: "فقط درصد", eng: "percent", value: "percent" },
        { per: "فقط مقدار", eng: "value", value: "value" },
        { per: "مقدار به همراه عنوان", eng: "label and value", value: "label+value" },
        { per: "عنوان به همراه درصد", eng: "label and percent", value: "label+percent" },
        { per: "همه موارد", eng: "label and percent and value", value: "label+percent+value" }
      ],
      textpositionList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "درون", eng: "inside", value: "inside" },
        { per: "بیرون", eng: "outside", value: "outside" },
        { per: "پیش فرض", eng: "auto", value: "auto" },
        { per: "هیچکدام", eng: "none", value: "none" }
      ],
      insidetextorientationList: [
        { per: "افقی", eng: "horizontal", value: "horizontal" },
        { per: "شعاعی", eng: "radial", value: "radial" },
        { per: "مماس", eng: "tangential", value: "tangential" },
        { per: "پیش فرض", eng: "auto", value: "auto" }
      ],
      holeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "هیچکدام", eng: "none", value: "0" },
        { per: "0.2", eng: "0.2", value: "0.2" },
        { per: "0.4", eng: "0.4", value: "0.4" },
        { per: "0.6", eng: "0.6", value: "0.6" }
      ],
      showlegendList: [
        { per: "نمایش داده شود", eng: "show", value: "true" },
        { per: "نمایش داده نشود", eng: "don't show", value: "false" }
      ],


      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "title", per: "عنوان" },
        tab1: { eng: "user profile", per: "مشخصات کاربر" },
        tab2: { eng: "security settings", per: "تنظیمات امنیتی" },
        mode: { eng: "chart mode", per: "نوع ترسیم" },
        shape: { eng: "chart shape", per: "نحوه ترسیم" },
        dash: { eng: "chart dash", per: "نوع خط" },
        grid: { eng: "grid color", per: "رنگ گرید" },
        line: { eng: "line color", per: "رنگ خطوط" },
        font: { eng: "font color", per: "رنگ نوشتار" },
        textinfo: { eng: "text info", per: "اطلاعات متن" },
        textposition: { eng: "text position", per: "موقعیت متن" },
        insidetextorientation: { eng: "inside text orientation", per: "جهت گیری داخل متن" },
        hole: { eng: "hole size", per: "ضخامت دایره" },
        showlegend: { eng: "legend", per: "شرح" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        data: { eng: "data", per: "داده" },
        attribute: { eng: "attribute", per: "ویژگی" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        }
      },


      layout: {
        legend: true,
        margin: {
          l: 5,
          r: 5,
          b: 5,
          t: 5,
          pad: 4
        },
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        font: { color: "#000000" },
        modebar: { bgcolor: "transparent", color: "rgb(14, 186, 186)", activecolor: "rgb(10, 139, 139)" },
        showlegend: false
        // xaxis: {
        //   automargin: true,
        //   gridcolor:"#02113e",
        //   linecolor:"#02113e",
        //   autorange: true,
        //   rangeselector: {bgcolor: 'rgb(14, 186, 186)',
        //                   buttons: [
        //                             {
        //                               count: 1,
        //                               label: '1m',
        //                               step: 'minute',
        //                               stepmode: 'backward',
        //                               font: { color: 'red' },
        //                             },
        //                             {
        //                               count: 1,
        //                               label: '1h',
        //                               step: 'hour',
        //                               stepmode: 'backward'
        //                             },
        //                             {
        //                               count: 1,
        //                               label: '1d',
        //                               step: 'day',
        //                               stepmode: 'backward'
        //                             },
        //                             {step: 'all'}
        //   ]},

        //         // rangeslider: {range: ['2022-12-19', '2022-12-21']},
        //         //               type: 'date'
        // },

        // yaxis: {
        //   gridcolor:"#02113e",
        //   linecolor:"#02113e",
        //   autorange: true,
        //   // range: [86.8700008333, 138.870004167],
        //   type: 'linear',
        //   automargin: true,
        // },

      },

      config: {
        useResizeHandler: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true,
        modeBarButtonsToRemove: ["toImage", "sendDataToCloud"],
        modeBarButtonsToAdd: [{
          name: "downloadCsv", title: "Download data as csv", icon: Plotly.Icons.disk, click: function(e) {
            for (var trace of e.data) {
              if (!(trace.hasOwnProperty("visible") && (trace.visible != true))) {
                var chart_title = trace.name;
                var csvData = [];
                // csvData.push([trace.name,null]);
                csvData.push(["time", "value"]);
                for (var i = 0; i < trace.x.length; i++) {
                  csvData.push([trace.x[i], trace.y[i]]);
                }
                var csvString = csvData.map(e => e.map(a => "\"" + ((a || "").toString().replace(/"/gi, "\"\"")) + "\"").join(",")).join("\r\n"); //quote all fields, escape quotes by doubling them.
                var universalBOM = "\uFEFF";
                var link = window.document.createElement("a");
                link.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csvString));
                link.setAttribute("download", chart_title + ".csv");
                // link.setAttribute('download', 'example.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          }
        }]
      }
    };

  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }

  },
  watch: {
    updatedData(newValue) {
      var updte = false;
      for (var Data of newValue) {
        for (var dindx = 0; dindx < this.chartData.datasets[0].dids.length; dindx++) {
          if (JSON.stringify(Data.id) === JSON.stringify(this.chartData.datasets[0].dids[dindx])) {
            this.chartData.datasets[0].values[dindx] = Math.abs(parseFloat(Data.value));
            updte = true;
          }
        }
      }
      if (updte) {
        Plotly.update(this.plotId, { values: [this.chartData.datasets[0].values] });
      }
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === this.plotId)) {
        if (newValue.item.type === "data") {

          this.myData.datas.push({ ...newValue.item, color: "#000000", title: newValue.title });
        }
      }
    },
    resized() {
      this.resizeChart();
    },
    data(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (newValue) {
          this.myData = JSON.parse(JSON.stringify(newValue));
          // this.$refs.table1.refresh();
          this.selChart();
        }
      }
    },
    myData(newvalue) {
    }
  },
  methods: {
    removeWidget() {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });

            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },

    selChart(index) {
      var color = ["rgb(231, 43, 190)", "rgb(120, 120, 224)", "rgb(72, 200, 74)", "rgb(216, 192, 39)"];
      this.chartData = { dids: [], datasets: [] };
      if (this.myData && this.myData.datas) {
        this.chartData.datasets = [{
          type: "pie",
          textinfo: this.myData.attribute.textinfo,  //'label+percent','label+value' , 'percent' , 'value' , 'percent+value'
          insidetextorientation: this.myData.attribute.insidetextorientation,  //"horizontal" | "radial" | "tangential" | "auto"
          textposition: this.myData.attribute.textposition,  //'inside' , 'outside', 'auto', 'none'
          automargin: true,
          hole: this.myData.attribute.hole,
          dids: [],
          values: [],
          labels: [],
          marker: {
            colors: []
          }
        }];

        for (var d of this.myData.datas) {
          this.chartData.dids.push(d._id);
          this.chartData.datasets[0].dids.push(d._id);
          this.chartData.datasets[0].labels.push(d.title);
          this.chartData.datasets[0].marker.colors.push(d.color);
          this.chartData.datasets[0].values.push(1);
        }


        if (this.chartData.dids.length > 0)
          this.charthandler();
        else
          this.plotChart();


        this.charthandler();
      }
    },

    charthandler() {
      this.$store.dispatch("panel/getData", { id: this.chartData.dids }).then(
        // this.$store.dispatch('data/getChartData',{dids:this.chartData.dids}).then(
        charts => {
          // console.log('charts pie data',charts)
          this.plotChart();
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    plotChart() {
      this.layout.font.color = this.myData.attribute.fontColor;
      this.layout.showlegend = this.myData.attribute.showlegend === "true" ? true : false;

      Plotly.newPlot(this.$refs[this.plotId], this.chartData.datasets, this.layout, this.config);

      var myPlot = document.getElementById(this.plotId);
      var iiiddd = this.plotId;
      var abc = 0;
      myPlot.on("plotly_afterplot", function() {
        abc += 1;
        if (abc === 1) {
          var update = {
            autosize: true
          };
          Plotly.relayout(iiiddd, update);
        }
      });
    },
    initModal: function() {
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    addItem: function(type) {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: this.plotId,
        filter: {
          dataType: ["Number", "Object"],
          usedBy: [],
          parents: [],
          archive: null,
          page: 1,
          perPage: 5000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + this.plotId);
    },
    removeItem: function(index) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.myData.datas.splice(index, 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    resizeChart() {
      var update = {
        autosize: true
      };
      Plotly.relayout(this.plotId, update);
    }
  },
  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
    }
    this.selChart();


    // if (this.data){
    //   this.myData = this.data
    //   this.selChart()
    // }
    // console.log('myData pie', this.myData)
  }
};
</script>


<style scoped>
.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);

}


</style>

<style>
.my-body-class {
  padding: 0px !important;
  /* Add any other custom styles you want */
}
</style>


