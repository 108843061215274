<template>
  <div style="height:100%; width:100%;">
    <div v-if="dataReady" class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-2 ">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" value="" id="CBEn" v-model="myData.status.enable" @change="onChangeStatus()">
          </div>

          <!-- <div class="d-flex justify-content-center align-items-center h-100">
            <div v-if="status.error"  class="led-red" :title="status.msg"></div>
            <div v-else class="led-green" title="running"></div>
          </div> -->

          
          
          
        </div>

        <div class="col-8 ">
          <p class="mb-0">
            {{ myData.attribute.title}}
          </p>
        </div>


        

        <div  class="col-2 text-center">
          <div class="d-flex" style="gap:5px;">
            <span v-if="!myData.status.enable" class="remove w-100" @click="changeTab" >
              <i v-if="activeTab==='tab1'" style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-gear" /></i>
              <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i> 
            </span>
            <span v-if="editable" class="remove w-100 text-danger" @click="removeWidget">
              <i><font-awesome-icon icon="fas fa-xmark" /></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataReady"  class="card-body p-1 d-flex justify-content-center align-items-center"  style="height: calc(100% - 40px); width:100%; overflow: hidden;">
      <transition name="slide-fade" mode="out-in">
        <div class="tab-content h-100 w-100" :key="activeTab">
          <div v-if="activeTab === 'tab1'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">

              <div class="h-100 w-100 p-2" style="display:flex; flex-direction:column; gap:10px;">
                <div style="height:25%;">
                  <div class="radiobox" style="height:70%">
                    <label class="radiobtn" v-for="opt in controlOptions" :key="opt.value" >
                      <input  class="radioinp" type="radio" v-model="myData.parameters.controlMode" :value="opt.value" :name="plotId" @change="changeControlMode"  />
                      <span v-if="opt.text==='Manual'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-hand-paper"/>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">دستی</p>
                        </div>
                      </span>
                      
                      
                      
                      <span v-if="opt.text==='Automatic'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <font-awesome-icon icon="fas fa-magic"/>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">خودکار</p>
                        </div>
                      </span>
                      
                      <span v-if="opt.text==='Schedule'" class="radiospan">
                        <div style="display: flex;flex-direction: column;">
                          <div style="display: flex;justify-content: center;">
                            <font-awesome-icon icon="fas fa-calendar-days"/>
                          </div>
                          <p style="margin: 10px 0px 0px 0px; font-size: small;">زمانبندی</p>
                        </div>
                      </span>

                    </label>
                  </div>
                  <div  class="radiobox" style="height:30%">
                    <i v-if="myData.parameters.controlMode ==='schedule'" ><font-awesome-icon icon="fas fa-lock" /></i>
                    <i v-else ><font-awesome-icon icon="fas fa-lock-open" /></i>
                    <div v-if="myData.parameters.controlMode==='schedule'">
                      <div v-if="pannel.scheduleTitle!=='  '">
                        {{pannel.scheduleTitle}}
                      </div>
                      <div v-else >
                        <font-awesome-icon icon="fas fa-calendar-days" fade style="color:red"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="myData.parameters.controlMode!=='manual'" style="height:15%; padding:10px; border-radius:5px; box-shadow: 0 .125em .25em rgb(0 0 0 / .3); display:flex; justify-content: space-evenly; align-items: center;">
                  <div v-for="(indicator,i) in pannel.status.indicators" :key="i" :style="{width:100/pannel.status.indicators.length+'%'}">
                    <div class="text-center"><i><font-awesome-icon icon="fas fa-power-off" size="lg" :style="parseInt(indicator.value)===1? {color:'rgb(255 149 0'}:{color:'#a6a6a6'}" /></i></div>
                    <div class="text-center" style="font-size:small; color:black"><a>{{indicator.title}}</a></div>
                  </div>
                </div>
                
                <div  :style="{height:myData.parameters.controlMode==='manual'? '75%':'60%', padding:'10px', borderRadius:'5px', boxShadow: '0 .125em .25em rgb(0 0 0 / .3)'}">
                  <div v-if="myData.parameters.controlMode!=='manual' && pannel.settingVis" style="height:100%; ">
                    <b-form-group 
                      style="display:flex; height:40px;"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      content-cols-sm="8"
                      content-cols-lg="9"
                      label-size="sm"
                      label= "settings"
                      label-for="autoSetting"
                      :invalid-feedback="errors.first('autoSetting')"
                    >
                      <b-form-select 
                       
                        :disabled="myData.parameters.controlMode==='schedule' || myData.status.enable"  
                        name="autoSetting"
                        v-model="pannel.selectedSetting"
                        :options="pannel.settingsOptions"
                  
                        :state="(submitted)? (!errors.has('autoSetting')): null"  
                        v-validate="'required'"
                        style="width:100%; font-size:small;"
                      ></b-form-select>
                    </b-form-group>
                    
                    <div style="height:calc(100% - 40px); display:flex; gap:10px;" >
                      <div v-if="pannel.rsliderA.visible" style="height:100%; width:100%;">
                        <div class="d-flex justify-content-center" style="height:25px; margin-bottom:5px">{{pannel.rsliderA.title}}</div>
                        <div  ref="rsliderCont1" style="height:100%; display: flex; justify-content: center;">
                          <round-slider 
                            :key="pannel.rsliderA.key"
                            id='rsliderA'
                            ref="rsliderA"
                            dir="ltr"
                            animation = false
                            :min="pannel.rsliderA.min"
                            :max="pannel.rsliderA.max"
                            step="1"
                            startAngle="-45" 
                            endAngle="225"   
                            :radius="pannel.sliderHeight"
                            width="12"
                            handleSize="24"
                            startValue="1"
                            rangeColor="#ff9500"
                            pathColor="#c4c4c4"
                            borderWidth="0"
                            lineCap="round"
                            svgMode= true
                            showTooltip= false
                            editableTooltip = false
                            sliderType= "range"
                            :disabled = "myData.parameters.controlMode==='schedule'"
                            :create="createTicksA"
                            :value-change="updateTickColorA"
                          ></round-slider>
                        </div>
                      </div>
                      <div v-if="pannel.rsliderB.visible" style="height:100%; width:100%;">
                        <div class="d-flex justify-content-center" style="height:25px; margin-bottom:5px">{{pannel.rsliderB.title}}</div>
                        <div ref="rsliderCont2" style="height:100%; display: flex; justify-content: center;">
                          <round-slider 
                            :key="pannel.rsliderB.key"
                            id='rsliderB'
                            ref="rsliderB"
                            dir="ltr"
                            animation = false
                            :min="pannel.rsliderB.min"
                            :max="pannel.rsliderB.max"
                            step="1"
                            startAngle="-45" 
                            endAngle="225"   
                            :radius="pannel.sliderHeight"
                            width="12"
                            handleSize="24"
                            startValue="1"
                            rangeColor="#ff9500"
                            pathColor="#c4c4c4"
                            borderWidth="0"
                            lineCap="round"
                            svgMode= true
                            showTooltip= false
                            editableTooltip = false
                            sliderType= "range"
                            :disabled = "myData.parameters.controlMode==='schedule' || pannel.rsliderB.disable"
                            :create="createTicksB"
                            :value-change="updateTickColorB"
                          ></round-slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="myData.parameters.controlMode==='schedule' && !pannel.settingVis"  style="height:100%; display:flex; justify-content:center; align-items:center">
                    no active event
                  </div>
                  <div v-else-if="myData.parameters.controlMode==='manual'"  style="height:100%; width:100%; display:flex; justify-content:center; align-items:center;">
                    <div v-for="(button,i) in pannel.manual.buttons" :key="i" style="width:50%;">
                      <div style="display:flex; flex-direction:column; justify-content:center; align-items:center;">
                        <label style="display:flex; justify-content:center; align-items:center">{{button.title}}</label>
                        <label class="mga-switch" style="transform: scale(2); transform-origin: top center;">
                          <input type="checkbox" v-model="button.value" true-value="1" false-value="0" @change="saveData('manPanel')"  >
                          <i></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'tab2'" class="h-100 ">
            <div fluid class="container-fluid p-0 h-100 ">
              <b-tabs card>
                <b-tab title="Schedules">
                
                                  
                  <b-table id="tableSchedule" :items="myData.parameters.schedules" :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small   ref="scheduleTable"  @row-clicked="onScheduleSelected"> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editSchedule(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removeSchedule(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                      <template #cell(isSelected)="row">
                        <template v-if="row.item._id===myData.parameters.selectedSchedule">
                          <span aria-hidden="true">&check;</span>
                          <span class="sr-only"></span>
                        </template>
                        <template v-else>
                          <span aria-hidden="true">&nbsp;</span>
                          <span class="sr-only"></span>
                        </template>
                      </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="addSchedule" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>
                </b-tab>
                <b-tab title="Settings">
                  <b-table :items="myData.parameters.settings" :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small   ref="controllerTable"> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editController(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removeController(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                      <!-- <template #cell(isSelected)="row">
                        <template v-if="JSON.stringify(row.item._id)===JSON.stringify(myData.parameters.selectedController)">
                          <span aria-hidden="true">&check;</span>
                          <span class="sr-only"></span>
                        </template>
                        <template v-else>
                          <span aria-hidden="true">&nbsp;</span>
                          <span class="sr-only"></span>
                        </template>
                      </template> -->
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="addController" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>
                </b-tab> 
              </b-tabs>
            </div>
          </div>
        </div>
      </transition>
    </div>
          
    <b-modal
      :id="plotId+'_control'"
      size="lg"
      centered
      hide-header-close
      content-class="shadow"
      :title="cdb.modalTitle"
      @shown="initControlModal"
      @hidden="handleControlModalReset"
      @ok="handleControlModalOk"
      >
      <div v-if="cdb.show">
        <div class="panel_body">
          <b-list-group flush >
            <b-form-group
              label="Controller Title"
              label-for="title"                                            
              :invalid-feedback="errors.first('title')"
              style="width:100%;"
            >
              <b-form-input
                name="title"
                type="text"
                placeholder="Enter title"
                v-model="cdb.title"
                :state="(submitted)? (!errors.has('title')): null"  
                v-validate="'required|min:2|max:20'"
                style="width:100%"
              ></b-form-input>
            </b-form-group>

            <b-list-group horizontal="md" style="gap:10px; padding:10px 0 10px 0;">
              <div v-if="cdb.options.moduleA.visible"  class="econtainor" :style=" {backgroundColor: cdb.data.moduleA.status?'transparent':'#e7ebef', width:'100%'}"> 

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" value="" id="CBA1" v-model="cdb.data.moduleA.status">
                  <label class="form-check-label" for="CBA1">
                    {{cdb.options.moduleA.title}}
                  </label>
                </div>

                <div>
                  <b-form-group
                    label-size="sm"
                    label="parameter's name"
                    label-for="nameA"                                            
                    :invalid-feedback="errors.first('nameA')"
                    style="width:100%;"
                  >
                    <b-form-input
                      :disabled="cdb.data.moduleA.status? false:true"  
                      size="sm"
                      name="nameA"
                      type="text"
                      placeholder="Enter a number"
                      v-model="cdb.data.moduleA.name"
                      :state="(submitted)? (!errors.has('nameA')): null"  
                      v-validate="'required|min:2|max:40'"
                      style="width:100%"
                    ></b-form-input>
                  </b-form-group> 

                  <a style="font-size: small;">parameter's range</a>
                  <div class="fcontainor" style="width:100%">   
                    <b-form-group
                      label-size="sm"
                      label="min"
                      label-for="hysLA"                                            
                      :invalid-feedback="errors.first('hysLA')"
                      style="width:48%;"
                    >
                      <b-form-input
                        :disabled="cdb.data.moduleA.status? false:true"  
                        size="sm"
                        name="hysLA"
                        type="text"
                        placeholder="Enter a number"
                        v-model="cdb.data.moduleA.min"
                        :state="(submitted)? (!errors.has('hysLA')): null"  
                        v-validate="'required|decimal:10|min_value:-1000|max_value:1000'"
                        style="width:100%"
                      ></b-form-input>
                    </b-form-group> 

                    <b-form-group
                      label-size="sm"
                      label="max"
                      label-for="hysUA"                                            
                      :invalid-feedback="errors.first('hysUA')"
                      style="width:48%;"
                    >
                      <b-form-input
                        :disabled="cdb.data.moduleA.status? false:true"  
                        size="sm"
                        name="hysUA"
                        type="text"
                        placeholder="Enter a number"
                        v-model="cdb.data.moduleA.max"
                        :state="(submitted)? (!errors.has('hysUA')): null"  
                        v-validate="'required|decimal:10|min_value:-1000|max_value:1000'"
                        style="width:100%"
                      ></b-form-input>
                    </b-form-group> 
                  </div>
                </div>
              </div>

              <div v-if="cdb.options.moduleB.visible"  class="econtainor" :style=" {backgroundColor: cdb.data.moduleB.status?'transparent':'#e7ebef', width:'100%'}"> 
                  <div class="d-flex justify-content-between">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" value="" id="CBB1" v-model="cdb.data.moduleB.status">
                      <label class="form-check-label" for="CBB1">
                        {{cdb.options.moduleB.title}}
                      </label>
                    </div>
                    
                    <!-- <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" value="" id="CBB2" v-model="cdb.data.moduleB.extSP" :disabled="cdb.data.moduleB.status? false:true">
                      <label class="form-check-label" for="CBB2">
                        External setpoint
                      </label>
                    </div> -->

                  </div>
                  
                  <div>
                    <b-form-group
                      label-size="sm"
                      label="parameter's name"
                      label-for="nameB"                                            
                      :invalid-feedback="errors.first('nameB')"
                      style="width:100%;"
                    >
                      <b-form-input
                        :disabled="cdb.data.moduleB.status? false:true"  
                        size="sm"
                        name="nameB"
                        type="text"
                        placeholder="Enter a name"
                        v-model="cdb.data.moduleB.name"
                        :state="(submitted)? (!errors.has('nameB')): null"  
                        v-validate="'required|min:2|max:40'"
                        style="width:100%"
                      ></b-form-input>
                    </b-form-group> 
        
                    <a style="font-size: small;">parameter's range</a>
                    
                    <div class="fcontainor" style="width:100%">   
                      <b-form-group
                        label-size="sm"
                        label="min"
                        label-for="hysLB"                                            
                        :invalid-feedback="errors.first('hysLB')"
                        style="width:48%;"
                      >
                        <b-form-input
                          :disabled="cdb.data.moduleB.status? false:true"  
                          size="sm"
                          name="hysLB"
                          type="text"
                          placeholder="Enter a number"
                          v-model="cdb.data.moduleB.min"
                          :state="(submitted)? (!errors.has('hysLB')): null"  
                          v-validate="'required|decimal:10|min_value:-1000|max_value:1000'"
                          style="width:100%"
                        ></b-form-input>
                      </b-form-group> 

                      <b-form-group
                        label-size="sm"
                        label="max"
                        label-for="hysUB"                                            
                        :invalid-feedback="errors.first('hysUB')"
                        style="width:48%;"
                      >
                        <b-form-input
                          :disabled="cdb.data.moduleB.status? false:true"  
                          size="sm"
                          name="hysUB"
                          type="text"
                          placeholder="Enter a number"
                          v-model="cdb.data.moduleB.max"
                          :state="(submitted)? (!errors.has('hysUB')): null"  
                          v-validate="'required|decimal:10|min_value:-1000|max_value:1000'"
                          style="width:100%"
                        ></b-form-input>
                      </b-form-group> 
                    </div>
                </div>
              </div>
            </b-list-group>

            <div v-if="cdb.options.moduleC.visible"  class="econtainor" :style=" {backgroundColor: cdb.data.moduleC.status?'transparent':'#e7ebef', width:'100%'}"> 
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" value="" id="CBM" v-model="cdb.data.moduleC.status">
                <label class="form-check-label" for="CBM">
                  {{cdb.options.moduleC.title}}
                </label>
              </div>
 
              <!-- <div style="display:flex">
                <div class="fcontainor" style="width:100%">   
                  <b-form-group
                    label-size="sm"
                    label="on time"
                    label-for="tOn"                                            
                    :invalid-feedback="errors.first('tOn')"
                    style="width:48%;"
                  >
                    <b-form-input
                      :disabled="cdb.data.moduleC.status? false:true"  
                      size="sm"
                      name="tOn"
                      type="text"
                      placeholder="seconds"
                      v-model="cdb.data.moduleC.onTime"
                      :state="(submitted)? (!errors.has('tOn')): null"  
                      v-validate="'required|decimal:10|min_value:1|max_value:100000'"
                      style="width:100%"
                    ></b-form-input>
                  </b-form-group> 

                  <b-form-group
                    label-size="sm"
                    label="off time"
                    label-for="tOff"                                            
                    :invalid-feedback="errors.first('tOff')"
                    style="width:48%;"
                  >
                    <b-form-input
                      :disabled="cdb.data.moduleC.status? false:true"  
                      size="sm"
                      name="tOff"
                      type="text"
                      placeholder="seconds"
                      v-model="cdb.data.moduleC.offTime"
                      :state="(submitted)? (!errors.has('tOff')): null"  
                      v-validate="'required|decimal:10|min_value:1|max_value:100000'"
                      style="width:100%"
                    ></b-form-input>
                  </b-form-group> 
                </div>
              </div> -->
            </div>

          </b-list-group>
        </div>
      </div>
    </b-modal>

    <b-modal
      :id="plotId+'_schedule'"
      size="lg"
      centered     
      hide-header-close
      content-class="shadow"
      :title="sdb.modalTitle"
      @shown="initScheduleModal"
      @hidden="handleScheduleModalReset"
      @ok="handleScheduleModalOk"
      >
      <PerfectScrollbar v-if="sdb.show">
        <!-- <div class="panel_body"> -->
        <div>
          <b-list-group flush >
            <b-list-group-item style="background-color:transparent;color:black; ">
              <b-form-group
                label="Schedule Title"
                label-for="title"                                            
                :invalid-feedback="errors.first('title')"
                style="width:100%;"
              >
                <b-form-input
                  name="title"
                  type="text"
                  placeholder="Enter title"
                  v-model="sdb.title"  
                  :state="(submitted)? (!errors.has('title')): null"  
                  v-validate="'required|min:2|max:20'"
                  style="width:100%"
                ></b-form-input>
              </b-form-group>  

              <div class="mt-4">
                <div style="display: flex; justify-content: end; width:100%">
                  <b-button-group size="sm">
                  <b-button @click="sdb.TSI = 5">5m</b-button>
                  <b-button @click="sdb.TSI = 15">15m</b-button>
                  <b-button @click="sdb.TSI = 30">30m</b-button>
                  <b-button @click="sdb.TSI = 60">1h</b-button>
                  <b-button @click="sdb.TSI = 120">2h</b-button>
                  <b-button @click="sdb.TSI = 240">4h</b-button>
                  <b-button @click="sdb.TSI = 360">6h</b-button>
                  <b-button @click="sdb.TSI = 720">12h</b-button>
                  <b-button @click="sdb.TSI = 1440">24h</b-button>
                  </b-button-group>
                </div>



                <div id='container' >
                  <ejs-schedule id="Schedule"  height='450px' ref="scheduleObj" :selectedDate='sdb.selectedDate' :eventSettings='sdb.eventSettings' :dataBinding='onDataBinding' 
                  :currentView='sdb.currentView' :workDays='sdb.workDays'  :key='sdb.scheduleKey' :timeScale='sdb.timeScale'
                  :cellClick='oncellClick' :eventClick='onEventClick' :showQuickInfo='false'>
                    <e-views>
                      <e-view option='Week'></e-view>
                    </e-views>
                  </ejs-schedule>
                </div>
              </div>
                  
            </b-list-group-item>
          </b-list-group>
        </div>
      </PerfectScrollbar>
    </b-modal>
    
    <b-modal
      :id="plotId+'_scheduleEditor'"
      size="md"
      centered
      hide-header-close
      content-class="shadow"
      :title="scheduleRecordType+' Event'"
      @shown="initScheduleEditorModal"
      >
        <template #modal-footer >
          <div class="d-flex w-100 justify-content-between" >
            <div>
              <b-button v-if="scheduleRecordType==='Edit'" variant="danger" class="float-right" @click="handleScheduleEditorModalDelete" > Delete </b-button>
            </div>
            <div>
              <b-button variant="secondary"  style="margin-right:10px" @click="handleScheduleEditorModalReset" > Cancel </b-button>
              <b-button variant="primary"  @click="handleScheduleEditorModalOk" > Ok </b-button>
            </div>
          </div>
        </template>

        <div>
          <div class="e-float-input mb-3">
            <input type='text' required v-model="scheduleRecord.Subject"  />
            <span class="e-float-line"></span>
            <label class="e-float-text">Name</label>
          </div>

          <div>
            <input type="text" tabindex="1" id='ddl' />
          </div>

          <div id='sliderContA' ></div>
          <div id='sliderContB' ></div>

          <div class="d-flex mb-3" style="gap: 15px">
            <div id='startDTPcontainer' class="w-100">
              <input id='startDTP' type="text" />
            </div>

            <div id='endDTPcontainer' class="w-100">
              <input id='endDTP' type="text" />
            </div>
          </div>
           
          <ejs-recurrenceeditor locale="en" id="editor" ref="EditorObj" :change='onChangeRecurrenceeditor'></ejs-recurrenceeditor>
        </div>
    </b-modal>
          


  </div>
</template>

<script>
/* eslint-disable */
  import myComponent_4 from "./myComponent_4.vue";
  import { v4 as uuidv4 } from 'uuid';

  import { ScheduleComponent, SchedulePlugin, Week, Month, Year,Agenda, Resize, DragAndDrop, ActionEventArgs,RecurrenceEditor } from '@syncfusion/ej2-vue-schedule';
  import { MultiSelect } from '@syncfusion/ej2-dropdowns';
  import { createElement } from '@syncfusion/ej2-base';
  import { DropDownList } from '@syncfusion/ej2-dropdowns';
  import { Slider } from '@syncfusion/ej2-inputs';
  import { DateTimePicker  } from '@syncfusion/ej2-calendars';
  import { FormValidator } from '@syncfusion/ej2-inputs';
  import { isNullOrUndefined } from '@syncfusion/ej2-base';
  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  import RoundSlider from "vue-round-slider";

  export default {
    components: {
        myComponent_4, //transform: scale(.75);
        'ejs-schedule': ScheduleComponent,
        PerfectScrollbar,
        RoundSlider
    },
    props: {
      plotId: String,
      data: Object,
      editable: Boolean,
      resized: Number
    },
    provide: {
        schedule: [Week, Year, Month, Agenda,DragAndDrop,Resize]
    },
    data() {
      return {
        UC:0,
        isDisabled:true,

        status:{
          error: false,
          msg: 'no setting is selected'
        },
        waterMark : 'Select a datetime',
        
        pannel:{
          settingVis : false,
          sliderHeight:100,
          rsliderA:{
            title:'',
            key:0,
            timeoutId:'',
            visible:false,
            value:[],
            min:10,
            max:55,
            disable:false
          },
          rsliderB:{
            title:'',
            key:0,
            timeoutId:'',
            visible:false,
            value:[],
            min:10,
            max:55,
            disable:false
          },
          settingsOptions:[],
          selectedSetting:null,
          scheduleTitle:'',
          manual:{
            buttons:[
              { title:'فیدر خروجی',
                value:'0',
                port:'port1'},
            ]
          },
          status:{
            indicators:[
              { title:'وضعیت خروجی',
                value:'1',
                tag:'stat_1'},
              { title:'خطا',
                value:'0',
                tag:'stat_2'},
            ],
            sliders:[
              { title:'rsliderA',
                value:'15',
                tag:'stat_3'},
              { title:'rsliderB',
                value:'15',
                tag:'stat_4'},
            ]
          }
        },
        
        scheduleRecord: {
          Id: 1,
          Subject: '',
          StartTime: null,
          EndTime: null,
          IsAllDay: false,
          RecurrenceRule:null,
          parameters: {
            selectedSetting:null,
            param_1: [],
            param_2: [],
          }
        },
        scheduleRecordType:null,

        myData : {
          datas:[],
          attribute:{
            title:'controller',
          },
          parameters:{
            enable:false,
            schedules:[],
            settings:[],
            selectedSchedule:'',
            activeEvent:'',
            controlMode:'',
            automatic:{},
            manual:{},
            
          },
          status:{}
        },
        activeTab : 'tab1',
        dataReady : false,

        groups:[],

        

        controlOptions: [
          { text: 'Manual', value: 'manual', },
          { text: 'Automatic', value: 'automatic' },
          { text: 'Schedule', value: 'schedule' },
        ],
        scheduleFields: [{ key: 'isSelected', label: '' },{key:'title', label: ''},{ key: 'modify', label: ''}],
        controllerFields: [{ key: 'isSelected', label: '' },{key:'title', label: ''},{ key: 'modify', label: ''}],
        modalData:{},
        cdb:{},
        sdb:{},

        options:{
          moduleA:{
            visible:false,
            title:'کنترل دما',
          },
          moduleB:{
            visible:false,
            title:'کنترل رطوبت',
          },
          moduleC:{
            visible:true,
            title:'آبیاری',
          },
          
        },
        submitted: false,
        successful: false,
        message: '',
      }
    },
    methods: {
      getData(){
        var dids=[];
        for (var d of this.myData.datas){
          dids.push(d._id)
        }
        if (dids.length > 0){
          this.$store.dispatch('panel/getData', {id:dids}).then(
            datas => {
            },
            error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
          )
        }
      },
      changeTab(){
        if (this.activeTab==='tab1')
          this.activeTab = 'tab2'
        else
          this.activeTab = 'tab1'
      },
      removeWidget() {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this widget')
        .then(value => {
          if (value===true){
            this.$store.dispatch('chpge/child2parent', {task: 'rmDashItem', item:this.plotId})
          }
        })
        .catch(err => {
        })
      },
      editWidget(){
        // console.log('editwidget',this.plotId)
        this.$root.$emit('bv::show::modal', this.plotId);
      },
      changeControlMode(){
        if (this.myData.parameters.controlMode === 'automatic'){
          this.updateAutoPanel();
          this.updateStatus();
        }else if (this.myData.parameters.controlMode === 'schedule'){
          this.updateSchPanel();
          this.updateStatus();
        }else if (this.myData.parameters.controlMode === 'manual'){
          this.updateManPanel();
        }
        // console.log('change control mode',this.myData.parameters.controlMode)
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );

      },
      onScheduleSelected(item) {
        this.myData.parameters.selectedSchedule = item._id
        this.$refs.scheduleTable.refresh();
        
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );
        
      },
      addController: function(type){
        this.modalData = {type:'Add', index:null}
        this.$root.$emit('bv::show::modal', this.plotId+'_control');
      },
      editController: function(index){
        this.modalData = {type:'Edit', index:index}
        this.$root.$emit('bv::show::modal', this.plotId+'_control');
      },
      addSchedule: function(type){
        this.modalData = {type:'Add', index:null}
        this.$root.$emit('bv::show::modal', this.plotId+'_schedule');
      },
      editSchedule: function(index){
        this.modalData = {type:'Edit', index:index}
        this.$root.$emit('bv::show::modal', this.plotId+'_schedule');
      },
      removeSchedule: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this schedule')
        .then(value => {
            if (value===true){

              var rmitem = this.myData.parameters.schedules[index]._id;
              this.myData.parameters.schedules.splice(index,1)
              
              if (JSON.stringify(this.myData.parameters.selectedSchedule)===JSON.stringify(rmitem)){
                var sclen = this.myData.parameters.schedules.length
                if (sclen>0){
                  if (index<sclen)
                    this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[index]._id
                  else if (index == sclen)
                    this.myData.parameters.selectedSchedule = this.myData.parameters.schedules[sclen-1]._id 
                }
                else{
                  this.myData.parameters.selectedSchedule = null;
                }
              }

              var sdata = [{
                id:this.myData.datas[0]._id, 
                value:{
                  status: this.myData.status,
                  parameters: this.myData.parameters,
                  connections:this.myData.connections,
                  type:this.myData.type
                }
              }]
              this.$store.dispatch('panel/setData', {data:sdata})
              .then(
                data => {
                },
                error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                }
              );
            }
        })
        .catch(err => {
        })
      },
      removeController: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this controller')
        .then(value => {
            if (value===true){

              var rmitem = this.myData.parameters.settings[index]._id;
              this.myData.parameters.settings.splice(index,1)
              
              // if (JSON.stringify(this.myData.parameters.selectedController)===JSON.stringify(rmitem)){
              //   var sclen = this.myData.parameters.settings.length
              //   if (sclen>0){
              //     if (index<sclen)
              //       this.myData.parameters.selectedController = this.myData.parameters.settings[index]._id
              //     else if (index == sclen)
              //       this.myData.parameters.selectedController = this.myData.parameters.settings[sclen-1]._id 
              //   }
              //   else{
              //     this.myData.parameters.selectedController = null;  
              //   }
              // }

              var sdata = [{
                id:this.myData.datas[0]._id, 
                value:{
                  status: this.myData.status,
                  parameters: this.myData.parameters,
                  connections:this.myData.connections,
                  type:this.myData.type
                }
              }]
              this.$store.dispatch('panel/setData', {data:sdata})
              .then(
                data => {
                },
                error => {
                var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                }
              );
            }
        })
        .catch(err => {
        })
      },
      onChangeStatus(state) {
        
        
        
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );
        
      },
      // control modal =============================================================
      initControlModal: function(){

        this.cdb = {
          show:false,
          modalTitle: this.modalData.type + ' Controller',
          type: this.modalData.type,
          options: this.options,
          refPAList: [],
          refNAList: [],
          refPBList: [],
          refNBList: [],

          title:'',
          cid:'',
          data:{
            moduleA:{
              status:false,
              extSP:false,
              max:'',
              min:'',
              name:'',
            },
            moduleB:{
              status:false,
              extSP:false,
              max:'',
              min:'',
              name:'',
            },
            moduleC:{
              status:false,
            }

            // moduleC:{
            //   status:false,
            // },
            // protection:{
            // }
          }

        }

        if (this.cdb.type==='Add')
        {
          this.cdb.title = '';
          this.cdb.cid = uuidv4();
        }
        else if (this.cdb.type==='Edit')
        {
          this.cdb.cid = this.myData.parameters.settings[this.modalData.index]._id
          this.cdb.title = this.myData.parameters.settings[this.modalData.index].title
          this.cdb.data = this.myData.parameters.settings[this.modalData.index].data
        }
        this.cdb.show = true;
      },
      handleControlModalOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleControlModalSubmit()
      },
      handleControlModalSubmit() {
        // console.log('submit',this.modalData.type)
        this.message = '';
        this.submitted = true;
        this.$validator.validate().then(isValid => {
          if (isValid) {
            if (this.modalData.type==='Add')
              this.myData.parameters.settings.push({_id:this.cdb.cid, title:this.cdb.title, data:this.cdb.data}) 
            else if (this.modalData.type==='Edit')
              this.myData.parameters.settings[this.modalData.index] = {_id:this.cdb.cid, title:this.cdb.title, data:this.cdb.data} 
                
            var sdata = [{
              id:this.myData.datas[0]._id, 
              value:{
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections:this.myData.connections,
                type:this.myData.type
              }
            }]
            // console.log(sdata)
            this.$store.dispatch('panel/setData', {data:sdata})
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId+'_control')
                })
              },
              error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              }
            );
          }
        });
      },
      handleControlModalReset(){
        this.submitted = null;
        this.successful = null;
        this.message= null;
      },
      // schedule modal =============================================================
      initScheduleModal: function(){


        this.sdb = {
          show:false,
          modalTitle: this.modalData.type + ' Schedule',
          type: this.modalData.type,
          title:'',
          sid:'',
          data : [],
          
          scheduleKey: 0,
          selectedDate: new Date(),
          currentView: 'Week',
          workDays: [1,2,3,4,5,6,7],
          eventSettings: { dataSource: []},
          timeScale: { 
            enable: true,
            interval: 360,
            slotCount: 3},
          TSI:360,
        }

        

        if (this.sdb.type==='Add')
        {
          this.sdb.title = '';
          this.sdb.sid = uuidv4();
          
          if (this.myData.parameters.schedules.length==0)
            this.myData.parameters.selectedSchedule = this.sdb.sid
        }
        else if (this.sdb.type==='Edit')
        {
          this.sdb.sid = this.myData.parameters.schedules[this.modalData.index]._id
          this.sdb.title = this.myData.parameters.schedules[this.modalData.index].title
          this.sdb.data = this.myData.parameters.schedules[this.modalData.index].data
        }
        
        // this.$refs.scheduleObj.eventSettings.dataSource = this.sdb.data
        this.sdb.eventSettings.dataSource = this.sdb.data
        this.sdb.scheduleKey = this.sdb.scheduleKey + 1;

        this.sdb.show = true;
      },
      handleScheduleModalOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleScheduleModalSubmit()
      },
      handleScheduleModalSubmit() {
        // console.log('submit',this.modalData.type)
        this.message = '';
        this.submitted = true;
        this.$validator.validate().then(isValid => {
          if (isValid) {
            if (this.modalData.type==='Add')
              this.myData.parameters.schedules.push({_id:this.sdb.sid, title:this.sdb.title, data:this.sdb.data}) 
            else if (this.modalData.type==='Edit')
              this.myData.parameters.schedules[this.modalData.index] = {_id:this.sdb.sid, title:this.sdb.title, data:this.sdb.data} 
                
            var sdata = [{
              id:this.myData.datas[0]._id, 
              value:{
                status: this.myData.status,
                parameters: this.myData.parameters,
                connections:this.myData.connections,
                type:this.myData.type
              }
            }]
            // console.log(sdata)
            this.$store.dispatch('panel/setData', {data:sdata})
            .then(
              data => {
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId+'_schedule')
                })
              },
              error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              }
            );
          }
        });
      },
      handleScheduleModalReset(){
        this.sdb.modalTitle = '',
        this.sdb.title = '',
        this.sdb.sid = '',
        this.sdb.data = [],
        this.submitted = null;
        this.successful = null;
        this.message= null;
      },
      onDataBinding(e){
        this.sdb.data = e.result
        // console.log('data binding',this.sdb.data)
      },
      onEventClick: function(args) {
        // console.log('event click',args.event)
        this.scheduleRecord.Subject = args.event.Subject;

        this.scheduleRecord.StartTime = args.event.StartTime;
        this.scheduleRecord.EndTime = args.event.EndTime;
        this.scheduleRecord.RecurrenceRule = args.event.RecurrenceRule;
        this.scheduleRecord.Id = args.event.Id;
        this.scheduleRecord.parameters = args.event.parameters;
        this.scheduleRecordType = 'Edit';
        this.$root.$emit('bv::show::modal', this.plotId+'_scheduleEditor');
      },
      oncellClick: function(args) {
        // console.log('cell click')
        this.scheduleRecord.Subject = '';
        this.scheduleRecord.StartTime = args.startTime;
        this.scheduleRecord.EndTime = args.endTime;
        this.scheduleRecord.RecurrenceRule = null;
        this.scheduleRecord.Id = uuidv4();
        this.scheduleRecord.parameters = {
          selectedSetting:null,
          param_1:[],
          param_2:[],
        };
        
        this.scheduleRecordType = 'Add';
        this.$root.$emit('bv::show::modal', this.plotId+'_scheduleEditor');
      },
      initScheduleEditorModal(){
        const startDateTimePicker = new DateTimePicker({
          value: this.scheduleRecord.StartTime, // Set the initial date
          placeholder: 'Start time',
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.StartTime = args.value;
          },
        });

        const endDateTimePicker = new DateTimePicker({
          value: this.scheduleRecord.EndTime, // Set the initial date
          placeholder: 'End time',
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.EndTime = args.value;
          },
        });

        var dropDownOptions = [{ text: 'disable', value: 'disable' }]
        for (var controller of this.myData.parameters.settings){
            dropDownOptions.push({ text: controller.title, value: controller._id })
        }

        const dropDownListObject = new DropDownList({
          placeholder: 'یکی از تنظیمات را انتخاب کنید', 
          value: this.scheduleRecord.parameters.selectedSetting,
          fields: { text: 'text', value: 'value' },
          dataSource: dropDownOptions,
          placeholder: "Setting",
          floatLabelType: 'Auto',
          change: (args) => {
            this.scheduleRecord.parameters.selectedSetting = args.value;
            
            var newSliderA = null;
            var newSliderB = null;
            var indx = -1;
            if (this.scheduleRecord.parameters.selectedSetting){          
              indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting)
            }
            if (indx !== -1){
              var setting = this.myData.parameters.settings[indx].data;
              newSliderA = setting.moduleA.status? {fname:'param_1', min:setting.moduleA.min, max:setting.moduleA.max, element:'sliderA', container:'sliderContA', name:setting.moduleA.name}:null
              newSliderB = setting.moduleB.status? {fname:'param_2', min:setting.moduleB.min, max:setting.moduleB.max, element:'sliderB', container:'sliderContB', name:setting.moduleB.name}:null
            }

            const existingSliderA = document.getElementById('sliderA')? document.getElementById('sliderA').ej2_instances[0]:null;
            const existingSliderB = document.getElementById('sliderB')? document.getElementById('sliderB').ej2_instances[0]:null;

            if (existingSliderA && newSliderA){
              this.updateSlider(existingSliderA, newSliderA)
            }else if (!existingSliderA && newSliderA){
              this.createSlider(newSliderA)
            }else if (existingSliderA && !newSliderA){
              document.getElementById('sliderContA').children[0].remove();
            }

            if (existingSliderB && newSliderB){
              this.updateSlider(existingSliderB, newSliderB)
            }else if (!existingSliderB && newSliderB){
              this.createSlider(newSliderB)
            }else if (existingSliderB && !newSliderB){
              document.getElementById('sliderContB').children[0].remove();
            }
          },
        });

        var indx = -1;
        var sliders = []
        if (this.scheduleRecord.parameters.selectedSetting){          
          indx = this.myData.parameters.settings.findIndex(x => x._id === this.scheduleRecord.parameters.selectedSetting)
        }
        // console.log('new selectedSetting:',this.scheduleRecord.parameters.selectedSetting)
        // console.log('indx',indx)
        if (indx !== -1){
          var setting = this.myData.parameters.settings[indx].data
          // console.log('setting',typeof setting.moduleA.status)
          if (setting.moduleA.status)
            sliders.push({fname:'param_1', min:setting.moduleA.min, max:setting.moduleA.max, element:'sliderA', container:'sliderContA', name:setting.moduleA.name})
          if (setting.moduleB.status)
            sliders.push({fname:'param_2', min:setting.moduleB.min, max:setting.moduleB.max, element:'sliderB', container:'sliderContB', name:setting.moduleB.name})
        }
        // console.log('sliders',sliders)
        
        for (var slider of sliders){
          this.createSlider(slider)
        }

        startDateTimePicker.appendTo('#startDTP');
        endDateTimePicker.appendTo('#endDTP');
        dropDownListObject.appendTo('#ddl');

        let recObject = this.$refs.EditorObj;
        recObject.setRecurrenceRule(this.scheduleRecord.RecurrenceRule);  
          



      },
      createSlider(slider){
        const sname = slider.fname;
        let container = createElement('div');
        let inputEle1 = createElement('div', { id:slider.element }   ) ;
        let lableEle = createElement('label', {className: 'custom-label1 mb-2 mt-2',
            attrs: { for: slider.element } , innerHTML:slider.name
        });
        container.appendChild(lableEle);
        container.appendChild(inputEle1);
        var sliderCont = document.getElementById(slider.container)
        sliderCont.appendChild(container)

        let _slider = new Slider({  
          min: slider.min,
          max: slider.max,
          type: 'Range',  //MinRange
          value: this.scheduleRecord.parameters[slider.fname].length !== 0 ? this.scheduleRecord.parameters[slider.fname] : [0, 0],
          tooltip: {placement: "Before", isVisible: true},
          ticks: { placement: "Before", largeStep: (slider.max-slider.min)/5 },
          change: (args) => {
            this.scheduleRecord.parameters[sname] = args.value;
          },
        })
        _slider.appendTo('#'+slider.element);
      },
      updateSlider(existingSlider, newSlider){
        existingSlider.min = newSlider.min;
        existingSlider.max = newSlider.max;
        existingSlider.value =  this.scheduleRecord.parameters[newSlider.fname].length !== 0 ? this.scheduleRecord.parameters[newSlider.fname] : [0, 0];
        existingSlider.ticks = { placement: "Before", largeStep: (newSlider.max-newSlider.min)/5 };
        existingSlider.refresh();
      },
      handleScheduleEditorModalOk(){
        if (this.scheduleRecordType === 'Add'){
          // console.log(this.scheduleRecord)
          this.$refs.scheduleObj.addEvent([this.scheduleRecord]);
          this.$nextTick(() => {
            this.$bvModal.hide(this.plotId+'_scheduleEditor')
          })
        }
        else if (this.scheduleRecordType === 'Edit'){

          if (this.scheduleRecord.RecurrenceRule){
            this.$refs.scheduleObj.saveEvent(this.scheduleRecord,'EditSeries');
          }else{
            // console.log('EditOccurrence',this.scheduleRecord)
            this.$refs.scheduleObj.saveEvent(this.scheduleRecord);
          }
          this.$nextTick(() => {
            this.$bvModal.hide(this.plotId+'_scheduleEditor')
          })
        }
      },
      handleScheduleEditorModalReset(){
        
        this.scheduleRecord = {
          Id: null,
          Subject: '',
          StartTime: null,
          EndTime: null,
          IsAllDay: false,
          RecurrenceRule:null,
        },

        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId+'_scheduleEditor')
        })
      },
      handleScheduleEditorModalDelete(){
        if (this.scheduleRecord.RecurrenceRule){
          this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id,'DeleteSeries');
        }else{
          // console.log('EditOccurrence',this.scheduleRecord)
          this.$refs.scheduleObj.deleteEvent(this.scheduleRecord.Id);
        }
        this.$nextTick(() => {
          this.$bvModal.hide(this.plotId+'_scheduleEditor')
        })
      },
      onChangeStartTime(arg){
        this.scheduleRecord.StartTime = arg.value;
        // console.log('newDate:',this.scheduleRecord.StartTime)
      },
      onChangeEndTime(arg){
        this.scheduleRecord.EndTime = arg.value;
        // console.log('newDate:',this.scheduleRecord.EndTime)
      },
      onChangeRecurrenceeditor(arg){
        this.scheduleRecord.RecurrenceRule = arg.value;
        // console.log('onChangeRecurrenceeditor:',this.scheduleRecord.RecurrenceRule)
      },
      //--------------------------------------------------
      createTicksA() {
        this.createTicks('rsliderA') 
      },
      updateTickColorA() {
        this.updateTickColor('rsliderA') 
      },
      createTicksB() {
        this.createTicks('rsliderB') 
      },
      updateTickColorB() {
        this.updateTickColor('rsliderB') 
      },
      createTicks(name) {
        this.resizeSlider();
        const ali = this.$refs[name].instance;
        // console.log('ali',ali)
        var o = ali.options;
        
        let val = `${this.pannel[name].value[0]},${this.pannel[name].value[1]}`;
        // console.log('0000000000000000000000000000000',val)
        ali.setValue(val)
        var tickInterval = o.step;
        for (var i = o.min; i <= o.max; i += tickInterval) {
          i = ali._round(i);
          var angle = ali._valueToAngle(i);
          var numberTag = ali._addSeperator(angle, "rs-custom");
          var number = numberTag.children();
          number.css({
            "margin-top": ali._border(true) / -2,
          });
          if (i % 5 === 0) number.addClass("rs-bold");
          if (i % 5 === 0){
            var angle = ali._valueToAngle(i);
            var numberTag = ali._addSeperator(angle, "rs-custom1");
            var number = numberTag.children();
            number.css({
              "margin-top": ali._border(true) / -2,
            });
            number
              .addClass("rs-bold")
              .html(i)
            var myAngle = angle
            while (myAngle>180){
              myAngle=myAngle-360
            }
            if (myAngle>90 || myAngle<-90)
              number.addClass('mirrored');
          }
        }
        this.updateTickColor(name);

        var value = 0;
        var indx = this.pannel.status.sliders.findIndex(x => x.title === name)
        if (indx != -1)
          value = this.pannel.status.sliders[indx].value;

        var angle = ali._valueToAngle(value);
        var numberTag = ali._addSeperator(angle, "rs-pointer");
        var number = numberTag.children();
        number.css({
          "margin-top": ali._border(true) / -2,
        });
  
        var numberTag = ali._addSeperator(0, "rs-value rs-tooltip rs-tooltip-text");
        numberTag.removeClass("rs-bar rs-transition")
        // console.log('numberTag',numberTag)
        numberTag.html(value);
       
      },
      sliderUpdate(name){
        if (Object.keys(this.$refs).includes(name)){
          if (this.$refs[name]){
            var indx = this.pannel.status.sliders.findIndex(x => x.title === name)
            if (indx != -1){
              // console.log('sliderUpdate',name,Object.keys(this.$refs),this.$refs[name])
              var value = this.pannel.status.sliders[indx].value;
              const ali = this.$refs[name].instance;
              const pointer = ali.control.find(".rs-pointer")[0];
              var angle = ali._valueToAngle(value);
              pointer.style.transform = `rotate(${angle}deg)`

              const valueEl = ali.control.find(".rs-value")[0];
              valueEl.innerHTML = value;
              // console.log(value)
            }
          }
        }
      },
      updateTickColor(name) {
        const ali = this.$refs[name].instance;
        const o = ali.options;
        var tickInterval = o.step;
        var value = o.value.split(',');
        
        const ticksCountMin = (value[0] - o.min) / tickInterval - 1;
        const ticksCountMax = (value[1] - o.min) / tickInterval + 1;
       
        const ticks = ali.control.find(".rs-custom");
        const digits = ali.control.find(".rs-custom1");

        ticks.map((i, e) =>
          e.classList[((i < ticksCountMax) && (i > ticksCountMin)) ? "add" : "remove"]("selected")
        );
        digits.map((i, e) =>{
          var x = i*5; 
          if (o.min%5 !== 0)
            x += (5 - o.min%5);
          e.classList[((x < ticksCountMax) && (x > ticksCountMin)) ? "add" : "remove"]("selected")
          }
        );
      
        if (this.pannel[name].timeoutId) {
          clearTimeout(this.pannel[name].timeoutId);
        }
        this.pannel[name].timeoutId = setTimeout(() => {
          this.pannel[name].value = value
          // console.log('b')
          this.saveData('autoPanel')
        }, 500); 

      },
      resizeSlider(){
        // console.log('resize slider')
        const divElement = this.$refs.rsliderCont1;
        if (divElement) {
          this.pannel.sliderHeight = divElement.clientHeight/2-15;
           // console.log('this.sliderHeight',this.pannel.sliderHeight)
        }
      },
      updateAutoPanel(){
        this.UC += 1;
        this.pannel.settingVis = true;
        this.pannel.selectedSetting = this.myData.parameters.automatic.selectedSetting;
                
        this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
        for (var controller of this.myData.parameters.settings){
          this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
        }

        var indx = -1;
        if (this.myData.parameters.automatic.selectedSetting){
          var indx = this.myData.parameters.settings.findIndex(x => x._id === this.myData.parameters.automatic.selectedSetting)
        }

        var LRSA = JSON.stringify(this.pannel.rsliderA)
        this.pannel.rsliderA = {
          key:this.pannel.rsliderA.key,
          timeoutId:this.pannel.rsliderA.timeoutId,
          value:this.myData.parameters.automatic.param_1?this.myData.parameters.automatic.param_1:[],
          title: indx !== -1? this.myData.parameters.settings[indx].data.moduleA.name:'',
          visible: indx !== -1? this.myData.parameters.settings[indx].data.moduleA.status:false,
          min:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.min:null,
          max:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.max:null,
          disable:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.extSP:false,
        }
        var CRSA = JSON.stringify(this.pannel.rsliderA)

        if (LRSA !== CRSA){
          this.pannel.rsliderA.key = 'rsliderA_'+this.myData.parameters.automatic.selectedSetting+'_'+this.UC
          this.pannel.rsliderA.timeoutId = ''
        }


        var LRSB = JSON.stringify(this.pannel.rsliderB)
        this.pannel.rsliderB = {
          key:this.pannel.rsliderB.key,
          timeoutId:this.pannel.rsliderB.timeoutId,
          value:this.myData.parameters.automatic.param_2?this.myData.parameters.automatic.param_2:[],
          title: indx !== -1? this.myData.parameters.settings[indx].data.moduleB.name:'',
          visible: indx !== -1? this.myData.parameters.settings[indx].data.moduleB.status:false,
          min:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.min:null,
          max:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.max:null,
          disable:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.extSP:false,
        }
        var CRSB = JSON.stringify(this.pannel.rsliderB)

        if (LRSB !== CRSB){
          this.pannel.rsliderB.key = 'rsliderB_'+this.myData.parameters.automatic.selectedSetting+'_'+this.UC
          this.pannel.rsliderB.timeoutId = ''
        }


      },
      updateSchPanel(){
        this.UC += 1;
        var eventName = '';
        var scheduleName = '';
        var activeEvent = this.myData.parameters.activeEvent;
        this.pannel.settingVis = false;
        this.pannel.rsliderA.visible = false;
        this.pannel.rsliderB.visible = false;
        // console.log('activeEvent',activeEvent)
        
        var indx1 = -1;
        if (this.myData.parameters.selectedSchedule){
          var indx1 = this.myData.parameters.schedules.findIndex(x => x._id === this.myData.parameters.selectedSchedule)
        }
        if (indx1 !== -1){
          var schedule = this.myData.parameters.schedules[indx1];
          var scheduleName = schedule.title;
          if (activeEvent){
            var indx2 = schedule.data.findIndex(x => x.Id === activeEvent)
            if (indx2 != -1){
              var eventName = schedule.data[indx2].Subject;
              this.pannel.settingVis = true;
              this.pannel.rsliderA.visible = true;
              this.pannel.rsliderB.visible = true;

              var parameters = schedule.data[indx2].parameters;
              if (parameters){
                // console.log('parameters',parameters)
                this.pannel.selectedSetting = parameters.selectedSetting;
                  
                this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
                for (var controller of this.myData.parameters.settings){
                  this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
                }
                // console.log('parameters2',parameters.selectedSetting,this.myData.parameters.settings)

                var indx = -1;
                if (parameters.selectedSetting){
                  var indx = this.myData.parameters.settings.findIndex(x => x._id === parameters.selectedSetting)
                }

                // console.log('parameters3',indx)

                this.pannel.rsliderA = {
                  
                  key:'rsliderA_'+parameters.selectedSetting+'_'+this.UC,
                  timeoutId:'',
                  value: parameters.param_1?parameters.param_1:[],
                  title: indx !== -1? this.myData.parameters.settings[indx].data.moduleA.name:'',
                  visible: indx !== -1? this.myData.parameters.settings[indx].data.moduleA.status:false,
                  min:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.min:null,
                  max:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.max:null,
                  disable:indx !== -1? this.myData.parameters.settings[indx].data.moduleA.extSP:false,
                }

                this.pannel.rsliderB = {
                  title:'رطوبت',
                  key:'rsliderB_'+parameters.selectedSetting+'_'+this.UC,
                  timeoutId:'',
                  value: parameters.param_2?parameters.param_2:[],
                  title: indx !== -1? this.myData.parameters.settings[indx].data.moduleB.name:'',
                  visible: indx !== -1? this.myData.parameters.settings[indx].data.moduleB.status:false,
                  min:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.min:null,
                  max:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.max:null,
                  disable:indx !== -1? this.myData.parameters.settings[indx].data.moduleB.extSP:false,
                }
              }
            }
          }
        }
        this.pannel.scheduleTitle = `${scheduleName} ${eventName? '/': ''} ${eventName}`;
        // console.log('this.pannel.scheduleTitle',this.pannel.scheduleTitle)
      },
      updateManPanel(){
        var keys = Object.keys(this.myData.parameters.manual)
        keys.forEach(key => {
          var value = this.myData.parameters.manual[key]
          var indx = this.pannel.manual.buttons.findIndex(x => x.port === key)
          if (indx != -1){
            this.pannel.manual.buttons[indx].value = value;
          }
        })
      },
      updateStatus(){
        var keys = Object.keys(this.myData.parameters.status)
        keys.forEach(key => {
          var value = this.myData.parameters.status[key]
          var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
          if (indx != -1){
            this.pannel.status.indicators[indx].value = value;
          }
          indx = this.pannel.status.sliders.findIndex(x => x.tag === key)
          if (indx != -1){
            this.pannel.status.sliders[indx].value = value;
          }
        })
        if (this.pannel.rsliderA.visible)
          this.sliderUpdate('rsliderA');
        if (this.pannel.rsliderB.visible)
          this.sliderUpdate('rsliderB')
      },
      //---------------------------------------------------
      saveData(type){
        // console.log('save data',type);
        var update = false;
        if (type==='autoPanel' && (this.myData.parameters.controlMode === 'automatic')){
          var bc = JSON.stringify(this.myData.parameters.automatic)
          this.myData.parameters.automatic = {
            param_1: this.pannel.rsliderA.value,
            param_2: this.pannel.rsliderB.value,
            selectedSetting: this.pannel.selectedSetting
          }
          var ac = JSON.stringify(this.myData.parameters.automatic)
          if (ac !== bc)
            update = true;
          
        }else if (type==='manPanel' && (this.myData.parameters.controlMode === 'manual')){
          this.myData.parameters.manual = {}
          for (var button of this.pannel.manual.buttons){
            this.myData.parameters.manual[button.port] = button.value
          }
          update = true;
        }

        if (update){
          var sdata = [{
            id:this.myData.datas[0]._id, 
            value:{
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections:this.myData.connections,
              type:this.myData.type
            }
          }]
          console.log('panel/setData7',sdata)
          this.$store.dispatch('panel/setData', {data:sdata})
          .then(
            data => {
              // this.$nextTick(() => {
              //   this.$bvModal.hide(this.plotId+'_schedule')
              // })
            },
            error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            }
          );
        } 
      },
    },

    mounted(){

      if (this.data && this.data.datas && this.data.attribute){
        this.myData = this.data
      }
      // console.log('===========control myData: ', this.myData)
      this.getData()
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      TSI(){
        return this.sdb.TSI;
      },
      settings(){
        if (this.myData.parameters){
          return this.myData.parameters.settings;
        }
      },
      selectedSetting(){
        return this.pannel.selectedSetting;
      },
      lang() {
        return this.$store.state.chpge.lang;
      }
    },
    watch: {
      updatedData(newValue) {
        // console.log('newdata')
        if (this.myData.datas.length > 0){
          for (var Data of newValue){
            // console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
            if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)){
              var LMD = JSON.stringify(this.myData)
              
              this.myData = {...this.data,...Data.value}
              if (!this.myData['parameters']){
                this.myData['parameters'] = {   

                  schedules:[],
                  selectedSchedule:'',
                  activeEvent:'',
                  settings:[],
                  controlMode:'manual',
                  automatic:{},
                  manual:{},
                  status:{},
                  
                }
              }
              var CMD = JSON.stringify(this.myData)
              if (LMD!==CMD){

                if (this.myData.parameters.controlMode === 'automatic'){
                  
                  this.updateAutoPanel();
                  this.updateStatus();
                }else if (this.myData.parameters.controlMode === 'schedule'){
                  this.updateSchPanel();
                  this.updateStatus();
                }else if (this.myData.parameters.controlMode === 'manual'){
                  this.updateManPanel();
                }
                this.dataReady = true;

              }

              
            }
          }
        }
      },
      TSI(newValue) {
        // console.log('TSI',newValue)
        this.sdb.timeScale.interval = newValue;
        this.sdb.eventSettings.dataSource = this.sdb.data;
        this.sdb.scheduleKey = this.sdb.scheduleKey + 1;
        // console.log('TSI',newValue)
      },
      resized(){
        this.resizeSlider();
      },
      settings(newvalue){
        if (newvalue){
          this.pannel.settingsOptions = [{ text: 'select a setting', value: null, disabled: true }] 
          for (var controller of this.myData.parameters.settings){
            this.pannel.settingsOptions.push({ text: controller.title, value: controller._id })
          }
        }

      },
      selectedSetting(newval){
        // console.log('a')
        this.saveData('autoPanel')
      },
    },
  };
</script>

<style scoped 
  src="@/assets/styles/ledStyle.css">
  
</style>

<style>
.disabled-div {
  pointer-events: none;
  /* Add any additional styling for a disabled appearance */
  opacity: 0.5; /* Example: reduce opacity for a disabled look */
}

  .component-main-holder {
    position: absolute;
    z-index: 7; 
    display: flex;
    justify-content: center;
    width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .component-hole {
    position: absolute;
    z-index: 8;
    /* background: #ffffff; */
    border-radius: 50%;
    /* left:  30%;
    right: 30%;
    bottom:30%;
    top: 30%; */
        left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .component-hole .text {
    display: flex;
    justify-content: center;
    font-size: 40px !important;
    font-weight: bold !important;
    color:#ffffff !important;
    font-family: Arial, Helvetica, sans-serif !important;
    margin-bottom: 0px;
  }
  .component-hole .note {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px !important;
    /* font-weight: bold !important; */
    color:#ffffff !important;
    /* font-family: Arial, Helvetica, sans-serif !important; */
  }



  .modal-footer {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .rs-custom.rs-bar {
    z-index: 4;
  }
  .rs-custom .rs-border {
    border-color: #ababab;
    margin-left: 18px;
    width: 5px;
  }
  .rs-custom .rs-border.rs-bold {
    width: 10px;
   
  }
  .rs-custom.selected .rs-border {
    border-color: #ff9500;
  }


  .rs-custom1.rs-bar {
    z-index: 3;
  }
  .rs-custom1 .rs-border {
    border-color: transparent;
    margin-left: 30px;
    width: 5px;
  }
  .rs-custom1 .rs-border.rs-bold {
    width: 30px;
    width: 30px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }

  .rs-custom1 .rs-border.rs-bold.mirrored {
    transform: translate(0, -50%) rotate(180deg) ;
    justify-content: end;
  }



  .rs-custom1.selected .rs-border {
    color:#ff9500;
  }


  .rs-pointer.rs-bar {
    z-index: 5;
  }
  .rs-pointer .rs-border {
    width: 0px;
    /* height: 25px !important; */
    position: absolute;
    top: 50%; 
    /* left: -30px; */
    transform: translate(0,-50%); 

    /* border-radius: 5px; */
    border: 1px solid rgb(4, 202, 169) !important;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }
  .rs-pointer .rs-border:before{
    content: "";
    position: absolute;
    bottom: 50%;
    right: -5px;
    border: 10px solid rgb(4, 202, 169);
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: translateY(50%);
  }
  .rs-value.rs-tooltip.rs-tooltip-text{
    color: rgb(4, 202, 169) !important;
    font-family: Segoe UI !important;
    font-size: 40px !important;
    font-weight: bold !important; 
  }
  span.rs-number {
    position: absolute;
    top: -12px;
    left: -26px;
    font-family: Segoe UI;
    font-size: 24px;
    font-weight: bold;
  }
</style>


<style scoped>

  .panel_container {
    width: 500px;
    height: 500px;
  }
  .panel_container .panel_header {
    /* padding: 10px; */
    height: 10%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-variant:small-caps;
    border-bottom: 2px solid rgb(210, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container .panel_body1 {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    /* border-bottom: 2px solid rgb(166, 41, 41); */
    display: flex;
    justify-content: space-between;
  }
  .panel_container  .panel_col {
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container  .panel_colf {
    height: 100%; 
    width: 100%;
  }
  .panel_container .panel_footer {
    transition: height 1s; 
    height:90%
  }
  .buttonHolder {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
  }
  .radioboxGroup {
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .radiobtnGroup {
    width: 100%;
    height: 50px;
    display: inline-block;
        margin:2px
  }
  .radiobtnGroup .radioinpGroup {
    display: none;
  }
  .radiobtnGroup .radiospanGroup:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }
  .radiobtnGroup .radiospanGroup:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtnGroup .radiospanGroup {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
    /* background-color: #ddd; */
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0);
    padding: 5px;

  }
  .radiobtnGroup input:checked + span{
    background-color:  hsl(25.5, 94.8%, 54.3%);
    color:rgb(255, 255, 255)
  }
  .radiobox {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }
  .radiobtn {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .radiobtn .radioinp {
    display: none;
  }

  .radiobtn .radiospan:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }

  .radiobtn .radiospan:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtn .radiospan {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    background-color: #ddd;
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0)
  }
  .radiobtn input:checked + span{
    background-color: hsl(0, 0%, 0%); 
    color:rgb(255, 255, 255)
  }
  .tab-content{
    height: 100%;

  }
  /* .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
{
    transform: translateX(10px);
    opacity: 0;
  } */
  .fcontainor{
    display:flex; 
    justify-content: space-between;
    flex-direction:row; 
    border: 1px solid #ced4da; 
    padding: 2%;
    border-radius: 0.5rem;
    margin-top: 2%;
  }
  .econtainor{
      border: 1px solid #ced4da; 
      padding: 10px;
      border-radius: 0.5rem;
      /* margin-top: 10px; */
  }



  .panel_body {
      /* padding: 0px 2% 0px 2%; */
      /* height: 68vh; */
      overflow-y: auto;
  }


</style>

<style scoped>

  .remove {
    cursor: pointer;
    /* color: rgb(212, 64, 27); */
    font-size: 18px;
  }
  .edit {
    cursor: pointer;
    color: rgb(9, 180, 210);
    font-size: 18px;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100%);
  }
  .cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;

  }
</style>

<style scoped>
  @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
  
  .wrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  /* .e-subject-container{
       width: 100% !important;
  } */
  .custom-event-editor .e-textlabel {
    padding-right: 15px;
    text-align: right;
  }
  .custom-event-editor td {
    padding: 7px;
    padding-right: 16px;
  }  
</style>

  <style>
    .e-slider-container{
      padding:5px 10% 5px 10%;
    }

    .e-subject-container{
      width: 100% !important;
    }
    .custom-field-row{
      padding-bottom: 12px;
    }
    .custom-label1{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
    }
    .custom-label2{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
    }
    .custom-label3{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bolder;
    }
    .flex-container{   
      display: flex;
      padding-bottom: 12px;
      width: 100%;
      justify-content: space-between;
    }
    .field-container{
      width:30%;
    }
    .field-container-controller{
      width:48%;
      margin-bottom: 10px;
    }
    .panel_header {
      padding: 10px;
      height: 60px;
      font-size: 20px;
      color: rgb(0, 0, 0);
      font-variant:small-caps;
      border-bottom: 2px solid rgb(210, 210, 210);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .panel_col {
      height: 100%; 
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      height: 25px;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      font-size: 13px;
    }



    /* .e-input-group.e-control-wrapper.e-float-input {
      color: royalblue;

    }
    
    .e-float-text::after {
      color: royalblue;

    }

    .e-float-line::before {
        background: royalblue !important;
    }

    .e-float-line::after {
        background: royalblue !important;
    }

    .e-float-text.e-label-top{
      color: royalblue !important;

    } */
</style>

<style scoped>
  .led-red {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #940;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0 2px 12px;
  }
  .led-yellow {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #A90;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #660 0 -1px 9px, #DD0 0 2px 12px;
  }
  .led-green {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #690;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0 2px 12px;
  }

</style>