<template>
  <div>
    <b-modal
      id="modal-map-pub-json-item"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle2"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card no-body> -->
          <b-list-group class="gap-3" horizontal="md">
            {{data.key}}
            <b-form-group
              label="Key"
              label-for="key"
              :invalid-feedback="errors.first('key')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="key"
                type="text"
                placeholder="Enter key"
                v-model="data.key"
                :state="submitted ? !errors.has('key') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Type"
              label-for="dataType"
              :invalid-feedback="errors.first('type')"
              style="width: 100%;"
            >
              <b-form-select
                style="
                  font-variant: small-caps;
                  width: 100%;
                  height: 100%;
                "
                name="type"
                size="lg"
                v-model="data.type"
                :options="typeList"
                class="form-control"
                :state="submitted ? !errors.has('type') : null"
                v-validate="'required'"
              ></b-form-select>
            </b-form-group>
          </b-list-group>
          <b-list-group horizontal="md" class="gap-3">

            <b-form-group
              v-if="data.type=='array'"
              label="index"
              label-for="index"
              :invalid-feedback="errors.first('index')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="index"
                type="number"
                placeholder="Enter index"
                v-model="data.index"
                :state="submitted ? !errors.has('index') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <div class="d-flex align-items-center" v-if="data.type!='arrayOfObject'">
              <ejs-textbox
                :value="data.data.name"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="data.data.name"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"><span
                class="e-icons e-edit-6 "></span>
              </b-button>
            </div>

          </b-list-group>
        </b-form>


        <div class="bg-white mt-5" v-if="data.type=='arrayOfObject'">
          <b-tabs v-model="tabIndex" small card>
            <b-tab title="identifiers">
              <b-row
                align-h="between"
                style="background-color: #fae8ff;"
                class="g-0 p-2 border border-top-0 border-bottom-0"
              >
                <b-table
                  :items="listGenerator"
                  :fields="dynamicFields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  stacked="md"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  show-empty
                  @filtered="onFiltered"
                  small
                  ref="mapSubJsonDataTable"
                >
                  <template #cell(modify)="row">
                    <div style="display: flex; justify-content: flex-end">
                      <b-button
                        pill
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        style="
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                        @click="editItem(row)"
                      >
                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button
                        pill
                        @click="deleteItem(row)"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        style="
                      margin-inline: 10px;
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                      >
                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                      </b-button>
                    </div>
                  </template>
                  <template #cell(value)="row">
                    <p v-if="row.item.type!='data'">
                      {{ row.item.value }}
                    </p>
                    <p v-else>
                      {{ row.item.value.name }}
                    </p>
                  </template>
                  <template #cell(data)="row">
                    <i v-if="row.item.data">{{ row.item.data.name }}</i>
                  </template>
                </b-table>
                <b-col class="my-1">
                  <div @click="openidenModal" style="font-size: 24px; display: flex; justify-content: end">
                    <i class="reset">
                      <font-awesome-icon icon="fas fa-plus-square" />
                    </i>
                  </div>
                </b-col>
              </b-row>

            </b-tab>
            <b-tab title="values" :disabled="list.length<=0">
              <b-row
                align-h="between"
                style="background-color: #fae8ff;"
                class="g-0 p-2 border border-top-0 border-bottom-0"
              >
                <b-table
                  :items="data.datas"
                  :fields="this.type=='Edit'?list[0]:list"
                  :current-page="currentPage"
                  :per-page="perPage"
                  stacked="md"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  show-empty
                  @filtered="onFiltered"
                  small
                  ref="mapSubJsonDataTable"
                >
                  <template #cell(modify)="row">
                    <div style="display: flex; justify-content: flex-end">
                      <b-button
                        pill
                        size="sm"
                        variant="outline-success"
                        class="mr-2"
                        style="
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                        @click="editValuesItem(row)"
                      >
                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                      </b-button>
                      <b-button
                        pill
                        @click="deleteValuesItem(row)"
                        size="sm"
                        variant="outline-danger"
                        class="mr-2"
                        style="
                      margin-inline: 10px;
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                      >
                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                      </b-button>
                    </div>
                  </template>
                  <template #cell(value)="row">
                    <p v-if="row.item.type!='data'">
                      {{ row.item.value }}
                    </p>
                    <p v-else>
                      {{ row.item.value.name }}
                    </p>
                  </template>
                  <template #cell(data)="row">
                    <i v-if="row.item.data">{{ row.item.data.name }}</i>
                  </template>
                </b-table>
                <b-col class="my-1">
                  <div @click="openSetValModal"
                       style="font-size: 24px; display: flex; justify-content: end">
                    <i class="reset">
                      <font-awesome-icon icon="fas fa-plus-square" />
                    </i>
                  </div>
                </b-col>
              </b-row>

            </b-tab>
          </b-tabs>


        </div>

      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>


    <b-modal
      id="modal-identifier-setValue"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      :title="setValTitleModal"
      @hidden="resetIdenModal"
      @shown="initIdenModal"
      content-class="shadow"
      @ok="submitValueForRow"
    >
      <div>
        <div class="my-3 row">
          <div class="col-md-3 col-12"
               v-for="(item,index) in listGenerator.filter((item)=>item.type=='data' && (item.operation!='latest' &&item.operation!='earliest'))"
               :key="index">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="selectedRow.item[item.key]"
                :placeholder="item.key"
                :enableRtl="lang == 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="selectedRow.item[item.key]"
              ></ejs-textbox>
              <b-button @click="pickData(item._id)" class="btn-sm" variant="primary"><span
                class="e-icons e-edit-6 "></span>
              </b-button>
            </div>
<!--            <b-form-group-->
<!--              :label="item.key"-->
<!--              label-for="data"-->
<!--              style="width: 100%; padding: 0px 10px 10px 0px"-->
<!--            >-->
<!--              <b-input-group style="flex-wrap: inherit">-->
<!--                <b-form-input-->
<!--                  disabled-->
<!--                  name="data"-->
<!--                  type="text"-->
<!--                  v-model="selectedRow.item[item.key]"-->
<!--                  placeholder="Select a data"-->
<!--                  v-validate="'required|min:1|max:200'"-->
<!--                  style="width: 100%"-->
<!--                ></b-form-input>-->

<!--                <b-input-group-append>-->
<!--                  <b-button @click="pickData(item._id)">Select</b-button>-->
<!--                </b-input-group-append>-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->
          </div>

        </div>

        <div class="row">
          <div class="col-md-3 col-12"
               v-for="(item,index) in listGenerator.filter((item)=>item.type=='identifier' && (item.operation!='latest' &&item.operation!='earliest'))"
               :key="index">
            <b-form-group
              :label="item.key"
              label-for="value"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="value"
                type="text"
                v-model="selectedRow.item[item.key]"
                placeholder="Enter value"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import dataPickerModal from "./setContModalDataPicker.vue";
import { v4, v4 as uuidv4 } from "uuid";

export default {
  name: "setGetPostMappingJson",
  components: {
    dataPickerModal
    // alertEditorModal
  },
  data() {
    return {
      dynamicFields: [
        {
          key: "key",
          label: "key",
          sortable: true
        },
        {
          key: "type",
          label: "type",
          sortable: true
        },
        {
          key: "modify"
        }
      ],
      list: [
        {
          key: "modify"
        }
      ],
      selectedRow: { item: {} },
      idenTitleModal: "",
      setValTitleModal: "",
      currentRowId: "",
      datePickType: "",
      tabIndex: "identifiers",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "key",
      sortDesc: true,
      sortDirection: "asc",
      modalTitle: "",
      modalTitle2: "",
      type: "",
      to: "",
      data: {
        _id: "",
        key: "",
        index: "",
        type: null,
        data: {
          _id: "",
          name: "",
          type: "",
          dataType: ""
        },
        identifiers: [],
        datas: []
      },
      dict:{
        pickData: {per:'انتخاب داده',eng:"Pick Data"},
      },
      intervalList: [{ text: "Select One", value: null },
        { text: "none", value: "none" },
        { text: "2 sec", value: "2_s" },
        { text: "5 sec", value: "5_s" },
        { text: "10 sec", value: "10_s" },
        { text: "15 sec", value: "15_s" },
        { text: "30 sec", value: "30_s" },
        { text: "1 min", value: "1_m" },
        { text: "3 min", value: "3_m" },
        { text: "5 min", value: "5_m" },
        { text: "15 min", value: "15_m" },
        { text: "30 min", value: "30_m" },
        { text: "45 min", value: "45_m" },
        { text: "1 hour", value: "1_h" }
      ],
      operationsList: [{ text: "Select One", value: null },
        { text: "equal", value: "equal" },
        { text: "greater", value: "greater" },
        { text: "less", value: "less" },
        { text: "greaterEqual", value: "greaterEqual" },
        { text: "lessEqual", value: "lessEqual" },
        { text: "latest", value: "latest" },
        { text: "earliest", value: "earliest" }
      ],
      typeList: [{ text: "Select One", value: null },
        { text: "none", value: "none" },
        { text: "array", value: "array" },
        { text: "array of object", value: "arrayOfObject" },
        { text: "property", value: "property" }
      ],
      keyList: [{ text: "Select One", value: null },
        { text: "data", value: "data" },
        { text: "identifier", value: "identifier" }
      ],
      submitted: false,
      successful: false,
      message: "",
      idenValues: {
        key: "",
        operation: null,
        value: "",
        type: "",
        _id: v4()
      },
      idenMode: "",
      setValMode: ""
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    listGenerator() {
      if (this.type != "Edit") {
        if (this.list.length > 0) {
          return this.list.filter((item) => item.key != "modify");
        } else return this.list;
      } else {
        if (this.list.length > 0) {
          return this.list[0].filter((item) => item.key != "modify");
        } else return this.list[0];
      }
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if ((newValue.task === "pickData") && (newValue.to === "getPostMap")) {
        if (this.data.type != "arrayOfObject")
          this.data.data = newValue.item;
        else if (this.data.type == "arrayOfObject") {
          this.data.identifiers[this.selectedRow.index].find(x => x._id === this.currentRowId).value = newValue.item;
          this.selectedRow.item = {};
          this.data.datas[this.selectedRow.index] = {};

          this.data.identifiers[this.selectedRow.index].forEach(id => {
            this.selectedRow.item[id.key] = Object(id.value).hasOwnProperty("name") ? id.value.name : id.value;
          });

          this.data.datas[this.selectedRow.index] = this.selectedRow.item;

          this.$nextTick(() => {
            this.data.datas = [...this.data.datas];
          });
        }
      }
    },
    parent2child(newValue, oldValue) {
      if (newValue.task != "pickData")
        this.type = newValue.type;
      else this.datePickType = newValue.type;
      if (newValue.task == "editMapJson2" && newValue.from.type == "cloudSub") {
        this.modalTitle2 = newValue.type + " Item";
        this.to = newValue.from;
      }

      if (newValue.task === "editMapJson2" && newValue.type == "Edit") {
        this.type = newValue.type
        this.data = newValue.item;
        this.list = newValue.item.identifiers;
        this.data.datas = [];
        newValue.item.identifiers.forEach((item, index) => {
          const a = {};
          item.forEach(val => {
            a[val.key] = typeof val.value != "object" ? val.value : val.value["name"];

          });
          this.data.datas.push(a);
        });
        this.to = newValue.from;
      }
    },

    "data.type"(val) {
      if (this.type != "Edit") {
        this.data.identifiers = [];
        this.data.data = {
          _id: "",
          name: "",
          type: "",
          dataType: ""
        };
        this.data.datas = [];
        this.data.index = "";
        this.list = [];
      }
    }
  },
  methods: {
    openSetValModal() {
      this.selectedRow = { item: {} };
      this.$bvModal.show("modal-identifier-setValue");
      this.setValTitleModal = "Add Item";
    },
    initModal: function() {
      this.modalTitle2 = this.type + " Item";
      if (this.type == "New") {
        this.data = {
          _id: "",
          key: "",
          index: "",
          type: null,
          data: {
            _id: "",
            name: "",
            type: "",
            dataType: ""
          },
          identifiers: [],
          datas: []
        };
      }
    },
    resetModal: function() {
      this.data = {
        _id: "",
        key: "",
        index: "",
        type: null,
        data: {
          _id: "",
          name: "",
          type: "",
          dataType: ""
        },
        identifiers: [],
        datas: []
      };

      this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.type === "New")
            this.data._id = uuidv4();

          this.$store.dispatch("chpge/child2parent", {
            task: "editMapJson/identify",
            to: this.to,
            type: this.type,
            item: JSON.parse(JSON.stringify(this.data))
          });
          this.$bvModal.hide("modal-map-pub-json-item");
        }
      });
    },
    pickData(id) {

      this.currentRowId = id;
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];


      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: { dataType: ["!complex"], usedBy: [], parents: filterParents, archive: null, withChild: false }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openidenModal() {
      this.idenTitleModal = "Add Item";
      this.idenValues = {
        key: "",
        operation: null,
        value: "",
        type: "",
        _id: v4()
      };
      this.$bvModal.show("modal-identifier");
    },
    submitIdenList() {
      // this.data.identifiers.push(this.idenValues);
      if (this.type != "Edit") {
        if (this.idenTitleModal != "Edit Item") {
          let items = this.list.filter(i => i.key !== "modify");

          items.push(this.idenValues);

          items.push(this.dynamicFields[2]);

          this.list = [...items];
        } else {
          // console.log(this.idenValues);
        }
        this.idenValues = {
          key: "",
          operation: null,
          value: "",
          type: "",
          _id: v4()
        };
      } else {
        // console.log(this.idenValues);
      }
      this.$bvModal.hide("modal-identifier");
    },

    editItem(value) {
      this.idenTitleModal = "Edit Item";
      this.idenValues = value.item;
      this.$bvModal.show("modal-identifier");
    },
    deleteItem(value) {
      var Index = this.list.indexOf(value.item, 1);
      if (this.type != "Edit") {
        this.list.splice(Index, 1);
      } else {
        this.data.identifiers.forEach((item) => {
          item.splice(item.indexOf(value.item), 1);
        });
        this.list.forEach((item) => {
          item.splice(item.indexOf(value.item), 1);
        });
      }
    },
    submitValueForRow(val) {
      let find = this.data.identifiers[this.selectedRow.index];

      Object.entries(this.selectedRow.item).forEach(([key, value]) => {
        const foundedIdentifier = find.find(x => x.key === key);

        if (foundedIdentifier.type !== "data")
          foundedIdentifier.value = value;
      });

      // let temp = structuredClone(this.list);
      //
      // temp[this.selectedRow.index] = { ...this.selectedRow };
      //
      // this.list = [...temp];
      if (this.setValTitleModal == "Add Item") {
        let newData = {};
        this.list.forEach((item) => {
          if (item.key != "modify")
            newData[item.key] = item.value["name"] || item.value;
        });
        this.data.identifiers.push(this.list);
        this.data.datas.push(newData);
      } else {
        // console.log(val);
      }
    },
    resetIdenModal() {
      if (this.type != "Edit") {
        this.data._id = "";
        this.idenValues = {
          key: "",
          operation: null,
          value: "",
          type: "",
          _id: v4()
        };
      }
    },
    initIdenModal() {
      if (this.type != "Edit") {
        this.idenTitleModal = "New Item";
        this.idenValues = {
          key: "",
          operation: null,
          value: "",
          type: "",
          _id: v4()
        };
      }
    },
    editValuesItem(val) {
      this.selectedRow = val;
      this.setValTitleModal = "Edit Item";
      this.$bvModal.show("modal-identifier-setValue");
    },
    deleteValuesItem(val) {
      this.data.datas.splice(this.data.datas.indexOf(val.item));
    }
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>



