<template>
    <div>
        <b-modal
        id="modal-setDashGroup"
        size="lg"
        centered
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div>
            <b-container fluid="md">
                <div style="padding: 0px 20px 0px 20px;">
                    <b-list-group flush>
                        <b-list-group-item style="background-color:transparent;color:black;">
                            <b-form-group
                                label="Chart Title"
                                label-for="title"                                            
                                :invalid-feedback="errors.first('title')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-input
                                    name="title"
                                    type="text"
                                    placeholder="Enter title"
                                    v-model="group.name"
                                    :state="(submitted)? (!errors.has('title')): null"  
                                    v-validate="'required|min:2|max:20'"
                                    style="width:100%"
                                ></b-form-input>
                            </b-form-group>  

                            <b-form-group
                                label-for="Feeders"
                                :invalid-feedback="errors.first('members')"
                            >
                                <b-form-select
                                    multiple
                                    :select-size="5"
                                    style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                    name="members"
                                    size="lg"
                                    v-model="group.members"
                                    :options="recordList"
                                    :state="(submitted)? (!errors.has('members')): null"  
                                    v-validate="'required'"
                                ></b-form-select>
                            </b-form-group>  
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-container>
        </div>
        </b-modal>
    </div>
</template>




<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        props: {
            pid: String
        },
        
        data() {
            return {
                modalTitle:'',
                group:{
                    name:'',
                    members:[],
                    id:''
                },
                id:'',
                groups:[],
                type:'',
                recordList: [],
                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='data_modal'){
                    this.db = newValue.data
                    this.type = newValue.type
                    if (this.type === 'Edit'){
                        this.db.index = newValue.index
                    }
                }
                // this.component.data[newValue.index].did = newValue.id
            }
        },
        methods: {
            initModal: function(){
                this.modalTitle = this.type + ' Chart'

                this.group.name    = '';
                this.group.members = [];

                if (this.type==='Edit'){
                    this.group.id      = this.db.id;
                    this.group.name    = this.db.name;
                    this.group.members = this.db.members;
                }
                this.getRecordSettings()
            },
            resetModal: function(){
                this.recordList = [],
                this.group.id='',
                this.group.name='',
                this.group.members = [],
                
                // this.groups=[],
                this.id=''
                this.type=''

                this.submitted = null;
                this.successful = null;
                this.message= null;

                // this.$store.dispatch('chpge/parent2child', {task: 'close_group_modal'})
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if (this.type==='Add'){
                            this.$store.dispatch('chpge/child2parent', {task: 'component_modal', type:'Add', data: this.group})
                        }else if (this.type==='Edit'){
                            this.$store.dispatch('chpge/child2parent', {task: 'component_modal', type:'Edit', data: this.group, index:this.db.index})
                        }
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal-setDashGroup')
                        })
                    }
                });
            },
            getRecordSettings() {
                this.$store.dispatch('data/getRecordSettings').then(
                    data => {
                        for (var d of data.data){
                            this.recordList.push({ value: d._id, text: d.dname})
                        }
                        
                        // console.log(JSON.stringify(this.equipment))
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .panel_header {
        padding: 10px;
        height: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-variant:small-caps;
        border-bottom: 2px solid rgb(210, 210, 210);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .panel_col {
        height: 100%; 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>