<template>
    <div style="padding: 1.5rem;	margin-right: 0;	margin-left: 0;	border-width: .2rem;">
        <b-card no-body>
          <b-card-body>
            <!-- thead-class="small_header" tbody-class="bold_body" -->
            <b-table :items="scenarios" :fields="scenariosFields" responsive="sm" small>
                <template #cell(modify)="row" >
                    <div style="display:flex; justify-content:flex-end;">
                        <b-button pill size="sm" variant="outline-success" @click="editScenario(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                        </b-button>
                        <b-button pill size="sm" variant="outline-danger" @click="removeScenario(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                        </b-button>
                    </div>
                </template>
            </b-table>
                                    
            <div style="display: flex; flex-direction: row; justify-content: flex-end; height:30px;">
                <b-button pill size="sm" type="submit" variant="primary" @click="addScenario" style="vertical-align: middle;  margin-left:20px;">
                    <b-icon icon="plus" aria-hidden="true" style="vertical-align: middle;"></b-icon>
                </b-button>
            </div>

          </b-card-body>
        </b-card>
    </div>
</template>

<script>

export default {
    name: 'scenarioSettings',
    data() {
        return {

        scenariosFields: ['name','type',{ key: 'floor.floorTitle', label: 'floor' },{ key: 'modify', label: '' }],
        scenarios:[],

        successful: false,
        message: ''
        };
    },
    mounted() {
        this.getScenarios();
    },
    methods: {
        addScenario: function(){
            this.$store.dispatch('chpge/openModal',{
                page:'scenario',
                type:'Add',
                index:null,
                db:this.scenarios
            })
        },
        editScenario: function(index,event){
            this.$store.dispatch('chpge/openModal',{
                page:'scenario',
                type:'Edit',
                index:index,
                db:this.scenarios
            })
        },
        removeScenario: function(index,event){
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
            .then(value => {
                if (value===true){
                    this.$store.dispatch('data/removeScenario', this.scenarios[index].uuid).then(
                        data => {
                            
                        this.message = data.message;
                        this.successful = true;
                        this.getScenarios();
                        },
                        error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.successful = false;
                        alert(this.message)
                        }
                    );
                }
            })
            .catch(err => {
            // An error occurred
            })
        },
        getScenarios() {
            this.$store.dispatch('data/getScenarios').then(
                data => {
                    this.scenarios = data
                    // console.log(JSON.stringify(this.groups))
                },
                error => {
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                        // this.successful = false;
                }
            );
        },
    }
};
</script>

<style scoped>
/* .hidden_header {
  display: none;
}
.small_header{
    font-size: x-small;
}
.bold_body{
    font-weight: bold;
    font-size: large;
    text-align: left;
    text-indent: 10px;
}
.td-class-1{
    width: 100px;
    text-align: left;
    font-weight: bold;
    font-size: large;
} */
</style>