<template>
  <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <div v-if="mapinit" :ref="plotId" :id="plotId"
         :style="{height : myData.attribute.mapHeight+'px', width : myData.attribute.mapWidth+'px'}"></div>


    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">

              <li v-for="(tab, index) in tabList" :key="index" class="nav-item" @click="activeTab = tab">
                <p class="nav-link"
                   :class="{ active: activeTab === tab }"
                   :id=tab
                   data-toggle="tab"
                   role="tab"
                   aria-controls="tab1"
                   aria-selected="true"
                >
                  {{ dict[tab][lang] }}
                </p>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3 " style="overflow: hidden;">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'data'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 " :key="reRender">
                    <div class="row mx-auto">
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lat[lang]"
                          :value="myData.attribute.center[0]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[0]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lng[lang]"
                          :value="myData.attribute.center[1]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[1]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-dropdownlist
                          :placeholder="dict.mapTile[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="tileIdList"
                          floatLabelType="Auto"
                          :fields="{ text: 'text', value: 'value' }"
                          v-model="myData.attribute.tileId"
                          @change="(val)=>{myData.attribute.tileId=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.minZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.minZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.minZoom"
                          v-model="myData.attribute.minZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.maxZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.maxZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxZoom"
                          v-model="myData.attribute.maxZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.nativeZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxNativeZoom"
                          v-model="myData.attribute.maxNativeZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.viewZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.viewZoom"
                          v-model="myData.attribute.viewZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'basic'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 ">
                    <b-table ref="table1" :items="myData.datas" :fields="groupFields" thead-class="hidden_header"
                             responsive="sm" small>
                      <template #cell(modify)="row">
                        <div style="display:flex; justify-content:flex-end;">
                          <b-button pill size="sm" variant="outline-danger" @click="removeItem(row.index)" class="mr-2"
                                    style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                          </b-button>
                        </div>
                      </template>
                      <!-- <template #cell(color)="row" >
                        <div class="d-flex align-items-center p-2">
                          <input type="color" v-model="row.item.color" class="w-100"/>
                        </div>
                      </template> -->

                      <!-- <template #cell(title)="row" >
                        <div class="d-flex align-items-center p-2">
                          <input type="text" v-model="row.item.title" class="w-100"/>
                        </div>
                      </template> -->

                      <!-- <template #cell(mode)="row" >
                        <select id="mySelect" v-model="row.item.mode" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in modeList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template>
                      <template #cell(shape)="row" >
                        <select id="mySelect" v-model="row.item.shape" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in shapeList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template>
                      <template #cell(dash)="row" >
                        <select id="mySelect" v-model="row.item.dash" class="form-select" aria-label="Default select example" >
                          <option v-for="(option, index) in dashList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                      </template> -->


                    </b-table>
                    <div style="display:flex; justify-content: right; align-items: center;">
                      <b-button @click="addItem" size="sm" variant="outline-danger"
                                style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i>
                          <font-awesome-icon icon="fas fa-plus" />
                        </i>
                      </b-button>
                    </div>
                  </div>
                </div>

              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      id="modal-widget-pick-map"
      size="lg"
      centered
      body-class="MWS-body-class"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initPickMapModal"
      :dir="dict.dir[lang]"
      @hidden="resetPickMapModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ pickMap.modalTitle[lang] }}</h5>
        <i>
          <font-awesome-icon icon="fas fa-xmark" class="close" @click="pickMapHandleClose" />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!-- <b-form-group>
              <b-form-radio-group
                  v-model="type"
                  class="d-flex"
                  style="justify-content:space-around"
              >
                  <b-form-radio value="data">Data</b-form-radio>
                  <b-form-radio value="config" class="ml-5">Config</b-form-radio>
              </b-form-radio-group>
          </b-form-group> -->

          <b-card>
            <b-row align-h="between" class="mb-2">
              <b-col lg="4" sm="6" md="5" class="my-1">
                <ejs-textbox
                  :value="pickMap.filter"
                  :placeholder="dict.search[lang]"
                  :enableRtl="lang === 'per'"
                  floatLabelType="Auto"
                  :showClearButton="true"
                  v-model="pickMap.filter"
                ></ejs-textbox>
              </b-col>
            </b-row>

            <b-table
              :items="pickMap.items"
              :fields="pickMap.fields"
              :current-page="pickMap.currentPage"
              :per-page="pickMap.perPage"
              :filter="pickMap.filter"
              :filter-included-fields="pickMap.filterOn"
              :sort-by.sync="pickMap.sortBy"
              :sort-desc.sync="pickMap.sortDesc"
              :sort-direction="pickMap.sortDirection"
              stacked="md"
              show-empty
              small
              hover
              @filtered="pickMapOnFiltered"
              @row-clicked="pickMapOnRowClicked"
            >
              <template #head(title)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(type)="row">
                {{ row.field[lang] }}
              </template>
            </b-table>

            <b-row align-h="between">
              <b-col lg="3" sm="4" md="4" class="my-1">
                <ejs-dropdownlist
                  :dataSource="pickMap.pageOptions"
                  :fields="{ text: lang, value: 'value' }"
                  v-model="pickMap.perPage"
                  @change="(val)=>{pickMap.perPage=val.value}"
                ></ejs-dropdownlist>
              </b-col>

              <b-col lg="7" sm="8" md="8" class="my-1">
                <b-pagination
                  v-model="pickMap.currentPage"
                  :total-rows="pickMap.totalRows"
                  :per-page="pickMap.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>

        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { latLng } from "leaflet";
import L from "leaflet";
import { DialogUtility } from "@syncfusion/ej2-popups";

// import light1Off from '../assets/images/light1-off.png';
// import light1On from  '../assets/images/light1-on.png';
// import light2Off from '../assets/images/light2-off.png';
// import light2On from  '../assets/images/light2-on.png';
// import light3Off from '../assets/images/light3-off.png';
// import light3On from  '../assets/images/light3-on.png';
// import light4Off from '../assets/images/light4-off.png';
// import light4On from  '../assets/images/light4-on.png';

export default {
  name: "CustomPath",
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      reRender: 0,
      activeTab: "data", // Set the initial active tab
      tabList: ["data", "basic"],

      mapinit: false,

      hhh: 300,

      modalTitle: { eng: "Edit layers widget", per: "ویرایش ویجت لایه ها" },
      myData: {
        datas: [],
        attribute: {
          center: [0, 0],
          minZoom: 1,
          maxZoom: 25,
          maxNativeZoom: 22,
          tileId: 1,
          viewZoom: 18,
          mapHeight: 100,
          mapWidth: 100
        }
      },
      watchlist: [],
      groupFields: [
        { key: "name", label: "" },
        // { key: 'type', label: ''},
        { key: "modify", label: "" }
      ],
      myDataTmp: { datas: [] },

      activeZone: null,
      carousel: false,
      schedule: false,
      group: false,
      control: false,
      carouselData: [],
      levels: null,
      markerLayer: null,
      markers: [],
      mymap: [],
      popup: L.popup(),

      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: ""

      },

      tileIdList: [{ text: "Select One", value: null },
        { text: "outdoors", value: 0 },
        { text: "streets", value: 1 },
        { text: "dark", value: 2 },
        { text: "light", value: 3 },
        { text: "satellite", value: 4 },
        { text: "satellite streets", value: 5 },
        { text: "navigation day", value: 6 },
        { text: "navigation night", value: 7 }],

      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],

      equipments: [],

      pickMap: {
        to: "",
        type: "data",
        items: [],
        fields: [
          { key: "type", eng: "type", per: "نوع", label: "type", sortable: true, sortDirection: "asc" },
          { key: "title", eng: "name", per: "نام", label: "name", sortable: true, sortDirection: "asc" }
          // { key: '_id', label: 'id', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "name",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        filterData: {},
        modalTitle: { eng: "select layers", per: "انتخاب لایه ها" }
      },

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        search: { per: "جستجو", eng: "search" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        lat: { eng: "latitude", per: "عرض جغرافیایی" },
        lng: { eng: "longitude", per: "طول جغرافیایی" },
        mapTile: { eng: "map tile", per: "تایل نقشه" },
        minZoom: { eng: "Minimum Zoom", per: "حداقل بزرگنمایی" },
        maxZoom: { eng: "Maximum Zoom", per: "حداکثر بزرگنمایی" },
        nativeZoom: { eng: "Max. Native Zoom", per: "حداکثر بزرگنمایی نقشه" },
        viewZoom: { eng: "View Zoom", per: "بزرگنمایی نقشه" }
      },

      submitted: false,

      successful: false,
      message: "null"
    };

  },
  methods: {
    setupLeafletMap: function() {
      this.mymap = L.map(this.plotId);
      this.map.center = this.myData.attribute.center;
      this.map.minZoom = this.myData.attribute.minZoom;
      this.map.maxZoom = this.myData.attribute.maxZoom;
      this.map.maxNativeZoom = this.myData.attribute.maxNativeZoom;
      this.map.tileId = this.myData.attribute.tileId;
      this.map.viewZoom = this.myData.attribute.viewZoom;

      this.createMap();
      if (this.myData.datas.length > 0) {
        for (var i = 0; i < this.myData.datas.length; i++) {
          this.getfloorMap(this.myData.datas[i].id);
        }
      }
    },
    zoneClick: function(e) {

      if (e.target.options.onClick.type === "page") {
      }

      this.$store.dispatch("chpge/changeDashPage", e.target.options.onClick.value);
    },
    zoneOver: function(e) {
      e.target.setStyle({ fillColorPrev: e.target.options.fillColor });
      e.target.setStyle({ fillColor: "#96ceb4" });
    },
    zoneLeft: function(e) {
      e.target.setStyle({ fillColor: e.target.options.fillColorPrev });
    },
    onMapZoom: function(e) {
      var zoomlevel = this.mymap.getZoom();
      if (zoomlevel > 21) {
        this.addMarkers();
      } else {
        this.removeMarkers();
      }
    },
    addMarkers: function() {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].addLayer(this.markers[j]);
        }
      }
    },
    removeMarkers: function(spaces) {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].removeLayer(this.markers[j]);
        }
      }
    },
    createMap: function() {
      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      // var mapOptions = {
      //   minZoom: this.map.minZoom,
      //   maxZoom: this.map.maxZoom,
      //   maxNativeZoom: this.map.maxNativeZoom,
      //   attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
      //   id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //   accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      // };
      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);

      L.tileLayer.wms("https://name.isfahan.ir/saeeserver/wms", {
        layers: "monam:boostan,monam:roads,monam:water",
        format: "image/png",
        transparent: true,
        version: "1.3.0",
        tiled: true,
        crs: L.CRS.EPSG32639,
        uppercase: true,
        styles: "",
        attribution: false,
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom
      }).addTo(this.mymap);


      // var pointList=[];
      // for (var j = 0; j < floor.floorPoints.length; j++){
      //     pointList[j] = latLng(floor.floorPoints[j].lat,floor.floorPoints[j].lng);
      // }

      // var geometry = new L.Polygon(pointList, floor.floorOptions);
      // this.levels = new L.layerGroup;
      // this.levels.addLayer(geometry);

      // floor.floorZones.sort((a,b)=>(+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0))

      // for (var zone of floor.floorZones){
      //   var pointList=[];
      //   for (var j = 0; j < zone.zonePoints.length; j++){
      //       pointList[j] = latLng(zone.zonePoints[j].lat,zone.zonePoints[j].lng);
      //   }
      //   zone.zoneOptions['_id']={floor:floor._id, zone:zone._id};

      //   var geometry = new L.Polygon(pointList, zone.zoneOptions);
      //   if (zone.zoneOptions.type==="lightZone")// || space.properties.stairs!=="undefined")
      //   {
      //     geometry.on('click', this.zoneClick);
      //     geometry.on('mouseover', this.zoneOver);
      //     geometry.on('mouseout',  this.zoneLeft);
      //   }
      //   this.levels.addLayer(geometry);
      // }
      // this.levels.addTo(this.mymap);
    },
    createMarkers: function(layer) {

      this.markerLayer = new L.layerGroup;
      this.markerLayer.addTo(this.mymap);


      var markerList = layer.details.markers;

      // var locations = [
      //   {lat:51.5, lng:-0.09, popup:"Marker 1"},
      //   {lat:51.51, lng:-0.1, popup:"Marker 2"},
      //   {lat:51.49, lng:-0.08, popup:"Marker 3"}
      // ]

      // locations.forEach((location) => {

      //   var marker = L.marker([location.lat,location.lng]).bindPopup(location.popup).openPopup();
      //   this.markerLayer.addLayer(marker)

      // });

      markerList.forEach((item) => {
        if (item.variableIcon.enable) {
          this.watchlist.push({
            icon: item.id,
            data: item.variableIcon.assignedData.id,
            values: item.variableIcon.shapes
          });
        }
        // else{
        if (item.onClick && !item.onClick.enable) {
          let createIcon;
          let iconDef = (item.variableIcon.enable == true && item.variableIcon.defaultValue.icon != "") ? item.variableIcon.defaultValue.icon.address : item.constantIcon.properties.icon;
          if (item.variableIcon.enable == true && item.variableIcon.defaultValue.icon != "") {
            createIcon = {
              iconUrl: item.variableIcon.defaultValue.icon.address,
              iconSize: [item.properties.width, item.properties.height],
              iconAnchor: [item.properties.width / 2, item.properties.height],
              popupAnchor: [0, -30]
            };
          } else {
            iconDef = null;
          }

          var marker = L.marker([item.properties.lat, item.properties.lng], {
            icon: L.icon(createIcon || item.constantIcon.properties.icon),
            draggable: false,
            id: item.id
          }).bindPopup(item.name).openPopup();
        } else {
          var marker = L.marker([item.properties.lat, item.properties.lng], {
            icon: L.icon(item.constantIcon.properties.icon),
            draggable: false,
            id: item.id
          }).on("click", (e) => {
            this.$store.dispatch("chpge/changeDashPage", item.onClick.value);
          }).bindPopup(item.name).openPopup();
        }


        this.markerLayer.addLayer(marker);
        // }
      });
      if (this.watchlist.length > 0) {
        var datas = [];
        this.watchlist.forEach((item) => {
          datas.push(item.data);
        });
        this.sendDataSSE(datas);
      }


    },
    createSpaces: function(floor) {
      // this.mymap.setView(this.map.center, this.map.viewZoom);
      // var layers=[];
      // this.mymap.eachLayer(function ( layer) {
      //     layers.push(layer)
      // });
      // layers.forEach((item)=>this.mymap.removeLayer(item))

      // var mapOptions = {
      //                 minZoom: this.map.minZoom,
      //                 maxZoom: this.map.maxZoom,
      //                 maxNativeZoom: this.map.maxNativeZoom,
      //                 attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      //                 id: 'mapbox/' + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //                 accessToken: 'pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg'
      // }

      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",mapOptions).addTo(this.mymap);

      var pointList = [];
      for (var j = 0; j < floor.details.floorPoints.length; j++) {
        pointList[j] = latLng(floor.details.floorPoints[j].lat, floor.details.floorPoints[j].lng);
      }

      var geometry = new L.Polygon(pointList, floor.details.floorOptions);
      this.levels = new L.layerGroup;
      this.levels.addLayer(geometry);

      floor.details.floorZones.sort((a, b) => (+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0));

      for (var zone of floor.details.floorZones) {
        var pointList = [];
        for (var j = 0; j < zone.zonePoints.length; j++) {
          pointList[j] = latLng(zone.zonePoints[j].lat, zone.zonePoints[j].lng);
        }
        zone.zoneOptions["_id"] = { floor: floor._id, zone: zone._id };

        var geometry = new L.Polygon(pointList, zone.zoneOptions);
        if (zone.zoneOptions.onClick.type) {
          geometry.on("click", this.zoneClick);
          geometry.on("mouseover", this.zoneOver);
          geometry.on("mouseout", this.zoneLeft);
        }
        this.levels.addLayer(geometry);
      }
      this.levels.addTo(this.mymap);
    },
    getfloorMap(floor) {
      this.$store.dispatch("data/getSingleMapLayer", { id: floor }).then(
        data => {
          if (data._id === floor)
            // console.log('====>>> layer',data)
            if (data.type === "indoormap")
              this.createSpaces(data);
            else if (data.type === "marker")
              this.createMarkers(data);
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    initModal: function() {
      // this.$refs.table1.refresh();
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
      this.reRender += 1;
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    resize() {
      const divElement = this.$refs.mainCont;
      if (divElement) {
        this.myData.attribute.mapHeight = divElement.clientHeight - 58;
        this.myData.attribute.mapWidth = divElement.clientWidth - 16;
      }
      // setTimeout(() => {
      //   this.setupLeafletMap();
      // }, "1000");

    },
    addItem: function(type) {
      // this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:this.plotId, filter:{dataType:['Number'], usedBy:[],parents:[],archive:null}})
      this.$root.$emit("bv::show::modal", "modal-widget-pick-map");
    },
    removeItem: function(index) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.myData.datas.splice(index, 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    initPickMapModal: function() {
      this.getAllMaps();
    },
    resetPickMapModal: function() {
      this.pickMap.items = [];
    },
    // getData(id) {
    //   this.$store.dispatch("panel/getData", { id: [id] }).then(
    //     data => {
    //     },
    //     error => {
    //       this.message =
    //         (error.response && error.response.data && error.response.data.message) ||
    //         error.message ||
    //         error.toString();
    //     }
    //   );
    //
    // },


    getAllMaps() {
      this.$store.dispatch("data/getAllMaps").then(
        data => {
          // console.log("all data: ",JSON.stringify(data))
          this.pickMap.items = data.message;
          // this.pickMap.items = data.message.filter((item)=>item.type=='indoormap');
          this.pickMap.totalRows = this.pickMap.items.length;
          // this.$refs.curTable.refresh();
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    sendDataSSE(datas) {
      this.$store.dispatch("data/sendDataForSSE", { id: datas });
    },
    pickMapOnFiltered(filteredItems) {
      this.pickMap.totalRows = filteredItems.length;
      this.pickMap.currentPage = 1;
    },
    pickMapOnRowClicked(item, index, event) {
      this.myData.datas.push({ type: item.type, id: item._id, name: item.title });
      this.$bvModal.hide("modal-widget-pick-map");
    },
    pickMapHandleClose() {
      this.$bvModal.hide("modal-widget-pick-map");
    }
  },
  mounted() {
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
    }


    setTimeout(() => {
      this.resize();
      this.mapinit = true;
      setTimeout(() => {
        this.setupLeafletMap();
      }, "50");
    }, "200");
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    updatedData() {
      return this.$store.state.panel.data;
    }
  },
  watch: {
    resized() {
      this.resize();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.plotId);
      }
    },
    updatedData(newValue) {
      for (var iData of newValue) {
        for (var wData of this.watchlist)
          if ((JSON.stringify(iData.id) === JSON.stringify(wData.data))) {
            for (var val of wData.values) {

              if (val.value == iData.value) {
                var selIcon = val.icon;
                // console.log("====> val", selIcon.address);

                this.markerLayer.eachLayer(function(marker) {
                  if (marker.options.id === wData.icon) {

                    // console.log('=====> marker: ',marker.options.icon.options)


                    let newIcon = L.icon({
                      iconUrl: selIcon.address,
                      iconSize: marker.options.icon.options.iconSize,
                      iconAnchor: marker.options.icon.options.iconAnchor,
                      popupAnchor: marker.options.icon.options.popupAnchor,
                      shadowAnchor: marker.options.icon.options.shadowAnchor
                    });
                    marker.setIcon(newIcon);
                    // console.log("=====> newIcon: ", newIcon);
                    // marker.setIcon
                  }
                });
                break;
              }

              // } else if ((typeof val.value == "object")) {
              //
              //   if ((Number(iData.value) >= Number(val.value[0])) && (Number(iData.value) <= Number(val.value[1]))) {
              //     var selIcon = val.icon;
              //
              //     this.markerLayer.eachLayer(function(marker) {
              //       if (marker.options.id === wData.icon) {
              //
              //         let newIcon = L.icon({
              //           iconUrl: selIcon.address,
              //           iconSize: marker.options.icon.options.iconSize,
              //           iconAnchor: marker.options.icon.options.iconAnchor,
              //           popupAnchor: marker.options.icon.options.popupAnchor,
              //           shadowAnchor: marker.options.icon.options.shadowAnchor
              //         });
              //         marker.setIcon(newIcon);
              //         // console.log("=====> newIcon: ", newIcon);
              //
              //         // marker.setIcon
              //
              //       }
              //     });
              //     break;
              //   }
              //
              // }
            }


          }
      }
    }
  }
};
</script>

<style scoped>
.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}


</style>

<style>
.leaflet-control-attribution {
  display: none;

}
</style>






