<script>
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import { v4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "setContHttpServerStatusJson",
  components: { dataPickerModal },
  props: { isHttp: { default: false } },
  data() {
    return {
      reRender: 0,
      validator: null,
      modalTitle: "",
      options: [
        { text: "select one condition", value: null },
        { text: "Connection established", value: "Connection established" },
        {
          text: "Data by the selected keys did not exist.",
          value: "Data by the selected keys did not exist."
        },
        {
          text: "Datas recieved successfully",
          value: "Datas recieved successfully"
        },
        {
          text: "connection could not be established",
          value: "connection could not be established"
        },
        {
          text: "unauthorized on second URL",
          value: "unauthorized on second URL"
        },
        {
          text: "unauthorized on first URL",
          value: "unauthorized on first URL"
        }
      ],
      info: {
        _id: v4(),
        condition: null,
        data: "",
        value: ""
      },
      submitted: false,
      dict: {
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        condition: { eng: "condition", per: "شرط" },
        name: { eng: "name", per: "نام" },
        value: { eng: "value", per: "مقدار" },
        pickData: {per:'انتخاب داده',eng:"Pick Data"},
      },
      type:''
    };
  },
  methods: {
    initModal() {
      setTimeout(() => {
        this.validator = new FormValidator("#stat_add", {
          rules: {},
          customPlacement: (element, error) => {
            element.parentElement.parentElement.parentElement.insertBefore(
              error,
              element.parentElement.parentElement.nextSibling
            );
          }
        });
        this.reRender += 1;
      }, 1000);
      if (this.type == "New") {
        this.info = {
          condition: null,
          data: "",
          value: "",
          _id: v4()
        };
      }
      this.submitted = false;

      if (this.isHttp == true) {
        this.options = [
          { text: "select one condition", value: null },
          { text: "API IS NOT AVAILABLE.", value: "API IS NOT AVAILABLE." },
          {
            text: "Data by the selected keys did not exist.",
            value: "Data by the selected keys did not exist."
          },
          {
            text: "Datas posted successFully",
            value: "Datas posted successFully"
          },
          {
            text: "Datas did not posted completely",
            value: "Datas did not posted completely"
          },
          {
            text: "Token generated",
            value: "Token generated"
          },
          {
            text: "Connection established",
            value: "Connection established"
          },
          {
            text: "Data getted successfully",
            value: "Data getted successfully"
          }
        ];
      }


    },
    resetModal() {
      this.reRender = 0;
      this.$bvModal.hide("modal-httpServer-status-json-item");
      this.isHttp = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    pickData() {
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];

      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "httpStatus",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          page:1,
          perPage:2000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    handleSubmit() {
      const isValid = this.validator.validate();
      if (isValid) {
        this.$store.dispatch("chpge/child2parent", {
          task: "addHttpServerStat",
          from: { type: "JsonHttp", map: "JSON", extra: [] },
          type: this.type,
          item: this.info
        });

        this.$nextTick(() => {
          this.$bvModal.hide("modal-httpServer-status-json-item");
        });

      }
    }
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if (newValue.task == "pickData" && newValue.to == "httpStatus") {
        this.info.data = newValue.item;
      }
    },
    parent2child(newValue, oldValue) {
      if (newValue.task === "editStatusJson") {
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Item";
        if (this.type == "Edit") {
          this.info = newValue.item;
        }
        // this.data._id = (newValue.item._id || newValue.item._id === 0) ? newValue.item._id : "";
        // this.data.topic = newValue.item.topic ? newValue.item.topic : "";
        // this.data.key = newValue.item.key ? newValue.item.key : null;
        // this.data.value = newValue.item.value ? newValue.item.value : null;
        // this.data.timeout = newValue.item.timeout ? newValue.item.timeout : "";
      }
    }
  }
};
</script>

<template>
  <div>
    <b-modal
      id="modal-httpServer-status-json-item"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      :title="type"
      @shown="initModal"
      @hidden="resetModal"
      :dir="dict.dir[lang]"
      @ok="handleOk"
      @cancel="resetModal"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="parent2child.type == 'New'">
            {{ dict.titleTranslateAdd[lang] }}
          </h5>
          <h5 v-if="parent2child.type == 'Edit'">
            {{ dict.titleTranslateEdit[lang] }}
          </h5>
        </div>
      </template>
      <div id="stat_add" :dir="dict.dir[lang]">
        <div class="row" :key="reRender">
          <div class="col-md-4 col-12">
            <component
              :is="'DropDownList'"
              :id="'stat_conditions'"
              :key="reRender+'_conditions'"
              :dataSource="options"
              :value="info.condition"
              :fields="{ text: 'text', value: 'value' }"
              :placeholder="dict.condition[lang]"
              :enableRtl="lang === 'per'"
              :validatorInstance="validator"
              :validatorRules="{ required: true }"
              @change="(value) => {info.condition = value}"
            ></component>
          </div>

          <div class="col-md-4 col-12">
            <component
              :is="'TextBox'"
              :id="'stat_name'"
              :value="info.value"
              :key="reRender+'_name'"
              :title="dict.value[lang]"
              :placeholder="dict.value[lang]"
              :enableRtl="lang === 'per'"
              :validatorInstance="validator"
              :validatorRules="{ required: true }"
              @change="
                (val) => {
                  info.value = val;
                }
              "
            ></component>
          </div>
          <div class="col-md-4 col-12">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="info.data['name']"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="info.data['name']"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"><span class="e-icons e-edit-6 "></span>
              </b-button>
            </div>
<!--            <b-form-group label="data">-->
<!--              <b-input-group style="flex-wrap: inherit">-->
<!--                <b-form-input-->
<!--                  disabled-->
<!--                  name="data"-->
<!--                  type="text"-->
<!--                  v-model="info.data['name']"-->
<!--                  placeholder="Select a data"-->
<!--                  v-validate="'required|min:1|max:200'"-->
<!--                ></b-form-input>-->

<!--                <b-input-group-append>-->
<!--                  <b-button @click="pickData">Select</b-button>-->
<!--                </b-input-group-append>-->
<!--              </b-input-group>-->
<!--            </b-form-group>-->
          </div>
        </div>

      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>
  </div>
</template>

<style scoped></style>
