<template>
  <div>
    <b-modal
      id="modal-data-manager"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      scrollable
      content-class="shadow"
      :title="dict[parent2child.type][lang]"
      @shown="initialtime"
      @hidden="resetModal"
      @ok="handleOk"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
    >
      <div :dir="dict.dir[lang]" id="data_mnager_add">
        <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
          <li
            v-for="(item, index) in rendertabList"
            class="mga-tab-nav-item"
            @click="activeTab = item.value"
            :key="index"
          >
            <a
              class="mga-tab-nav-link"
              :class="{ active: activeTab === item.value }"
              :id="item.name"
              data-toggle="tab"
              role="tab"
              aria-controls="tab1"
              aria-selected="true"
            >
              {{ dict[item.value][lang] }}
            </a>
          </li>
        </ul>

        <div v-show="activeTab == 'definition'">
          <div class="px-3 row mt-4">
            <div class="col-md-6 col-12">
              <ejs-textbox
                :autocomplete="'off'"
                :value="data.name"
                :placeholder="dict.dataName[lang]"
                :enableRtl="lang === 'per'"
                v-model="data.name"
                :required="true"
              >
              </ejs-textbox>
            </div>
            <div class="col-md-6 col-12">
              <ejs-dropdownlist
                @change="
                  (val) => {
                    data.type = val.value;
                  }
                "
                :value="data.type"
                :dataSource="typeList"
                :enabled="data.type ? false : true"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.dataType[lang]"
                :enableRtl="lang === 'per'"
                :required="true"
              ></ejs-dropdownlist>
            </div>
          </div>
        </div>

        <div v-show="activeTab == 'values'">
          <div class="card mt-2 mx-auto" v-if="isClass || isObject">
            <div class="border px-0">
              <ejs-toolbar :items="toolbarForObj"></ejs-toolbar>
              <div class="row border-bottom border-top mx-0 py-1" v-for="(data1,index) in valuesGenerator " :key="index"
                   style="font-size: 13px">

                <div class="col-md-1 col-12 pt-4">
                  <ejs-checkbox :value="data1.id" @change="selectAllData($event,data1.id)"></ejs-checkbox>
                </div>
                <div class="col-md-4 col-12 pt-3">
                  <ejs-textbox
                    :autocomplete="'off'"
                    :enabled="isClass ? false : true"
                    :placeholder="dict.dataName[lang]"
                    :enableRtl="lang === 'per'"
                    :required="true"
                    :value="data1.name"
                    @input="updateData($event, 'name', data1.id)"
                  >
                  </ejs-textbox>
                </div>
                <div class="col-md-4 col-12 pt-3">
                  <ejs-dropdownlist
                    :dataSource="typeList2"
                    :enabled="isClass ? false : true"
                    :fields="{ text: lang, value: 'value' }"
                    :placeholder="dict.dataType[lang]"
                    :enableRtl="lang === 'per'"
                    :value="data1.type"
                    @change="updateData($event, 'type', data1.id)"
                  ></ejs-dropdownlist>
                </div>
                <div class="col-md-3 col-12 pt-4">
                   <span
                     v-if="!isClass"
                     class="e-icons e-delete-1 text-danger"
                     style="font-size: 17px"
                     @click="deleteRow(data1)"
                   ></span>
                </div>
              </div>
              <ejs-pager
                style="border: unset!important;"
                :pageSize="5"
                v-model="valCurPage"
                :pageSizes="[5,10,20,50]"
                :locale="lang"
                :enableRtl="lang == 'per'"
                @click="getValValue"
                :totalRecordsCount="data.values.length"
              ></ejs-pager>
            </div>

          </div>
        </div>

        <div class="mt-4 px-3" v-show="activeTab == 'Archive'">
          <div v-if="!isClass && !isObject">
            <div class="form-check col-md-4 form-switch mt-4 mb-2 mx-2">
              <input
                v-model="data.archive.enable"
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
              <label class="form-check-label" for="flexSwitchCheckChecked">{{
                  dict.archiveData[lang]
                }}</label>
            </div>
            <b-collapse v-model="data.archive.enable">
              <div class="row mx-auto">
                <div class="col-md-5 col-12">
                  <ejs-dropdownlist
                    :id="'archive_interval'"
                    :dataSource="intervalList"
                    :value="data.archive.interval"
                    :validatorInstance="validator"
                    :enabled="true"
                    :fields="{ text: lang, value: 'value' }"
                    :placeholder="dict.dataType[lang]"
                    :enableRtl="lang === 'per'"
                    @change="
                      (val) => {
                        data.archive.interval = val.value;
                      }
                    "
                    :validatorRules="{ required: true }"
                  ></ejs-dropdownlist>
                </div>
                <div
                  class="
                    col-md-7
                    d-flex
                    align-items-center
                    justify-content-between
                    col-12
                  "
                >
                  <div class="form-check form-switch mt-2 mb-2 mx-2">
                    <input
                      v-model="data.archive.uponChange"
                      class="form-check-input"
                      type="checkbox"
                      :disabled="!data.archive.enable"
                      role="switch"
                      id="ii"
                    />
                    <label
                      class="form-check-label"
                      for="ii"
                    >{{ dict.upon[lang] }}</label
                    >
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div v-if="isObject || isClass">
            <div class="d-flex justify-content-end gap-3 my-3">
              <b-button variant="primary" class="btn-sm" @click="()=>{$bvModal.show('modal-upload-excelArchive')}">
                {{ dict.uploadFile[lang] }}
              </b-button>
              <b-button variant="primary" class="btn-sm" @click="()=>{$bvModal.show('modal-download-excelArchive')}">
                {{ dict.download[lang] }}
              </b-button>
            </div>
            <div class="border px-2">
              <div class="row border-bottom mx-0 py-1" v-for="(data1,index) in archiveGenerator " :key="index"
                   style="font-size: 13px">
                <div class="col-md-3 col-12">
                  <p class="mt-3">{{ data1.name }}</p>
                </div>
                <div class="col-md-3 col-12 pt-3">
                  <ejs-switch
                    :enableRtl="lang == 'per'"
                    v-model="data1.enable"
                    :checked="data1.enable"
                    @change="updateSwitch($event,'enable',data1.id)"
                  />
                  <span class="mx-2">{{ dict.ArchivesEnable[lang] }}</span>
                </div>
                <div class="col-md-3 col-12 pt-3">
                  <ejs-switch
                    :enableRtl="lang == 'per'"
                    :checked="data1.uponChange"
                    v-model="data1.uponChange"
                    @change="updateSwitch($event,'uponChange',data1.id)"
                  />
                  <span class="mx-2">{{ dict.upon[lang] }}</span>
                </div>
                <div class="col-md-3 col-12">
                  <ejs-dropdownlist
                    :dataSource="intervalList"
                    :fields="{ text: lang, value: 'value' }"
                    :placeholder="dict.interval[lang]"
                    :enableRtl="lang === 'per'"
                    :enabled="data1.enable"
                    :value="data1.interval"
                    v-model="data1.interval"
                    :required="data1.enable"
                  ></ejs-dropdownlist>
                </div>
              </div>
              <ejs-pager
                style="border: unset!important;"
                :pageSize="5"
                v-model="curPage"
                :pageSizes="[5,10,20,50]"
                :locale="lang"
                :enableRtl="lang == 'per'"
                @click="getVal"
                :totalRecordsCount="data.values.length"
              ></ejs-pager>
            </div>
          </div>
        </div>
        <div
          class="mt-4 px-3"
          v-show="activeTab == 'generation'"
        >
          <div class="form-check col-md-4 form-switch mt-4 mb-2 mx-2">
            <input
              v-model="data.generation.enable"
              :value="data.generation.enable"
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">
              {{ dict.activeGenerate[lang] }}
            </label>
          </div>

          <!-- <b-collapse v-model="data.generation.enable"> -->
          <b-card>
            <div>
              <!--              <ejs-textbox-->
              <!--                :value="data.generation.formula"-->
              <!--                :enabled="!data.generation.enable ? false : true"-->
              <!--                :enableRtl="lang === 'per'"-->
              <!--                title="v1*sin(2*pi*v2*t)"-->
              <!--                :placeholder="dict.formulla[lang]"-->
              <!--                :required="data.generation.enable ? true : false"-->
              <!--                :maxLength="200"-->
              <!--                :minLength="1"-->
              <!--                v-model="data.generation.formula"-->
              <!--              ></ejs-textbox>-->
            </div>

            <b-collapse v-model="data.generation.enable">

              <ejs-textbox
                :autocomplete="'off'"
                :placeholder="dict.formulla[lang]"
                :enableRtl="lang == 'per'"
                :required="data.generation.enable"
                floatLabelType="Auto"
                v-model="data.generation.formula"
              ></ejs-textbox>

              <ejs-grid
                class="mt-4"
                ref="generationTabel"
                :width="'auto'"
                :locale="lang"
                height="100%"
                :allowPaging="true"
                :toolbar="generateTool"
                :enableAdaptiveUI="screenWidth<=600"
                :enableRtl="lang == 'per'"
                :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                :toolbarClick="toolbarClicks"
                :dataSource="data.generation.vars"
              >
                <e-columns>
                  <e-column
                    v-for="(item, index) in tableFields"
                    :key="index"
                    :field="item.key"
                    :width="screenWidth<=600?'100':item.width"
                    :headerText="item[lang]"
                    :template="item.hasTemplate ? item.key : null"
                  ></e-column>
                </e-columns>

                <template v-slot:modify="{data}">
                  <div class="d-flex">
                    <!--                    <i @click="moveItemUp(data)">-->
                    <!--                      <font-awesome-icon-->
                    <!--                        icon="fas fa-arrow-alt-circle-up"-->
                    <!--                        class="arrow-up"-->
                    <!--                      />-->
                    <!--                    </i>-->
                    <span
                      @click="removeData(data)"
                      style="font-size: 17px"
                      v-permission="'event_type_remove'"
                      class="e-icons e-delete-1 text-danger"></span>
                  </div>
                </template>
              </ejs-grid>
            </b-collapse>
          </b-card>
          <!-- </b-collapse> -->
        </div>
        <!-- </b-container> -->
      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>
    <archives-data-to-show :id="data._id" />
    <b-modal
      id="modal-upload-excel"
      size="md"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      @hidden="file = ''"
      hide-footer
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.excelTitleModal[lang] }}</h5>
        </div>
      </template>

      <div>
        <div class="d-flex justify-content-start align-items-center">
          <div class="form-group mb-2 col-md-10">
            <ejs-uploader
              style="border: unset!important;"
              :placeholder="dict.uploadFile[lang]"
              :enableRtl="lang == 'per'"
              @selected="getFileFromInput"
              :multiple="false"
              allowedExtensions=".xls,.xlsx"
            ></ejs-uploader>
          </div>
          <b-button
            :disabled="!file"
            @click="readXslxForValue"
            size="sm"
            class="mx-1 mt-4 col-md"
            variant="outline-primary"
          >
            {{ dict.upload[lang] }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-upload-excelArchive"
      size="md"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      @hidden="file = ''"
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.archivesTitleModal[lang] }}</h5>
        </div>
      </template>

      <div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="form-group mb-2 col-md-10">
            <ejs-uploader
              style="border: unset!important;"
              :placeholder="dict.uploadFile[lang]"
              :enableRtl="lang == 'per'"
              @selected="getFileFromInput"
              :multiple="false"
              allowedExtensions=".xls,.xlsx"
            ></ejs-uploader>
          </div>
          <b-button
            @click="readIt"
            size="md"
            class="mx-1 col-md"
            variant="outline-primary"
          >
            {{ dict.upload[lang] }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-download-excelArchive"
      size="md"
      centered
      @shown="initModal"
      header-border-variant="primary"
      header-class="my-modal-header "
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      @hidden="file = ''"
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.downloadModalArchive[lang] }}</h5>
        </div>
      </template>

      <div>
        <div class="d-flex justify-content-center align-items-center gap-4">
          <div class="w-100">
            <input id="startDTP" type="text" />
          </div>

          <div class="w-100">
            <input id="endDTP" type="text" />
          </div>
          <b-button
            variant="outline-success"
            @click="searchXslx"
            size="sm"
            class="mt-3"
          >
            <i>
              <font-awesome-icon icon="fas fa-download" />
            </i>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="openDetails"
            size="sm"
            class="mt-3"
          >
            <i>
              <b-icon icon="eye" aria-hidden="true"></b-icon>
            </i>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { ToolbarComponent } from "@syncfusion/ej2-vue-navigations";
import dataPickerModal from "./dataManagerModalDataPicker.vue";
import alertEditorModal from "./dataManagerModalDataAlertEditor.vue";
import { v4 } from "uuid";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import * as XLSX from "xlsx";
import ArchivesDataToShow from "@/components/archivesDataToShow.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { mapGetters } from "vuex";
import { debounce } from "@/services/data.service";

export default {
  name: "dataAdderModal",
  components: {
    ArchivesDataToShow,
    dataPickerModal,
    alertEditorModal,
    "ejs-toolbar": ToolbarComponent
  },
  data() {
    return {
      genes: [],
      classList: [],
      perPage: 5,
      valPerPage: 5,
      curPage: 1,
      valCurPage: 1,
      reRender: 0,
      reRenderMe: 0,
      current: 1,
      currentPage: 1,
      curTableOptions: {
        items: [],
        name: "",
        fields: [
          {
            key: "data",
            label: "",
            hasTemplate: true,
            width: "auto"
          },
          { key: "modify", label: "", hasTemplate: true, width: "100" }
        ],
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Dialog"
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      validator: null,
      dataReady: false,
      heh: [],
      dict: {
        uploadFile: { eng: "upload file", per: "بارگذاری فایل" },
        download: { eng: "download file", per: "بارگیری فایل" },
        upload: { eng: "upload", per: "بارگذاری" },
        activeGenerate: { eng: "active generation", per: "فعال کردن تولید داده" },
        dir: { eng: "ltr", per: "rtl" },
        interval: { eng: "interval", per: "نرخ زمانی" },
        upon: { eng: "upon change", per: "به محض تغییر" },
        dataValue: { eng: "default value", per: "مقدار پیش فرض" },
        ArchivesEnable: { eng: "enable archive", per: "فعال کردن آرشیو" },
        excelTitleModal: { eng: "import data", per: "وارد کردن اطلاعات" },
        exportTitleModal: { eng: "export data", per: "خروجی گرفتن از دیتا" },
        archivesTitleModal: { eng: "upload data", per: "اطلاعات آرشیو شده" },
        downloadModalArchive: { eng: "download data", per: "اطلاعات آرشیو شده" },
        values: { eng: "values", per: "مقادیر" },
        definition: { eng: "Definition", per: "تعریف داده" },
        Archive: { eng: "Archives", per: "ذخیره داده" },
        generation: { eng: "Generation", per: "تولید داده" },
        dataName: { eng: "data name", per: "نام داده" },
        dataType: { eng: "data type", per: "نوع داده" },
        classes: { eng: "class list", per: "لیست کلاس ها" },
        archiveData: { eng: "archive data", per: "آرشیو داده" },
        formulla: { eng: "formulla", per: "فرمول نویسی" },
        removeAll: { eng: "delete All", per: "حذف همه" },
        importFile: { eng: "import data", per: "وارد کردن داده" },
        exportFile: { eng: "export data", per: "استخراج داده" },
        New: { eng: "New Data", per: "ایجاد داده" },
        addValue: { eng: "new value", per: "مقدار جدید" },
        Edit: { eng: "Edit Data", per: "ویرایش داده" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        general: {
          nextBtn: { "per": "ادامه", "eng": "Next" },
          pageTitle: { "per": "ایجاد اتصال Http server", "eng": "Add http server Connection" },
          perPage: { per: "تعداد در هر صفحه", eng: "per page" },
          searchBox: { per: "جستجو", eng: "search" }
        }
      },
      activeTab: "definition",
      tabs: [
        { value: "definition", name: "definition" },
        { value: "values", name: "values" },
        { value: "Archive", name: "Archive" },
        { value: "generation", name: "generation" }
        // { value: "Alert", name: "Alert" },
      ],
      isValid: null,
      from: null,
      to: null,
      file: null,
      modalTitle: "",
      activetab: 0,
      type: "",
      formType: "",
      values: [
        { name: "", type: "", id: v4(), value: "" }
      ],
      data: {
        _id: "",
        name: "",
        type: null,
        archive: {
          enable: false,
          interval: null,
          uponChange: false
        },
        values: [],
        transmit: {
          enable: false,
          interval: null,
          uponChange: false
        },
        generation: {
          enable: false,
          vars: [],
          formula: ""
        },
        parent: {
          ptype: "",
          pid: ""
        },
        alert: {
          enable: "",
          alerts: []
        },
        usedBy: []
      },
      archiveTable: {
        items: [],
        name: "",
        fields: [
          { key: "name", per: "نام", eng: "name", width: 150, hasTemplate: false },
          { key: "modify", per: "", eng: "", width: "auto", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      intervalList: [{ per: "یک مورد انتخاب کنید", eng: "Select One", value: "null" },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "2 ثانیه", eng: "2 sec", value: "2_s" },
        { per: "5 ثانیه", eng: "5 sec", value: "5_s" },
        { per: "10 ثانیه", eng: "10 sec", value: "10_s" },
        { per: "15 ثانیه", eng: "15 sec", value: "15_s" },
        { per: "30 ثانیه", eng: "30 sec", value: "30_s" },
        { per: "1 دقیقه", eng: "1 min", value: "1_m" },
        { per: "3 دقیقه", eng: "3 min", value: "3_m" },
        { per: "5 دقیقه", eng: "5 min", value: "5_m" },
        { per: "15 دقیقه", eng: "15 min", value: "15_m" },
        { per: "30 دقیقه", eng: "30 min", value: "30_m" },
        { per: "45 دقیقه", eng: "45 min", value: "45_m" },
        { per: "1 ساعت", eng: "1 hour", value: "1_h" }
      ],
      selectedDatas: [],
      tableFields: [
        { key: "modify", hasTemplate: true, per: "", eng: "" },
        // { key: '_id', label: 'ID' },
        { key: "name", hasTemplate: false, per: "نام", eng: "Name" }
      ],
      typeList: [
        { per: "یک مورد را انتخاب کنید", eng: "Select One", value: "null", values: null },
        { per: "عدد", eng: "number", value: "Number", values: null },
        { per: "رشته", eng: "string", value: "String", values: null },
        { per: "داده منطقی (Boolean)", eng: "boolean", value: "Boolean", values: null },
        { per: "شی (آبجکت)", eng: "object", value: "Object", values: null }
      ],
      typeList2: [,
        { per: "عدد", eng: "number", value: "Number" },
        { per: "رشته", eng: "string", value: "String" },
        { per: "داده منطقی (Boolean)", eng: "boolean", value: "Boolean" }
      ],
      alertTableFields: [
        { key: "type", label: "Type" },
        { key: "range", label: "Range" },
        { key: "message", label: "Text" },
        { key: "modify", label: "" }

      ],
      translateTypes: {
        number: "Number",
        string: "String",
        boolean: "Boolean"
      },
      submitted: false,
      successful: false,
      message: "",
      infos: [],
      objAlerts: {
        dataId: "",
        dataType: "",
        alerts: []
      }
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    archiveGenerator() {
      const start = (this.curPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.data.values.slice(start, end);
    },
    valuesGenerator() {
      const start = (this.valCurPage - 1) * this.valPerPage;
      const end = start + this.valPerPage;
      return this.data.values.slice(start, end);
    },
    toolbarForObj() {
      if (this.isObject) {
        return [
          {
            prefixIcon: "e-icons e-plus-3",
            text: this.dict.addValue[this.lang],
            id: "add",
            click: () => this.toolbarClick("add")
          },
          {
            prefixIcon: "e-icons e-upload-1",
            text: this.dict.importFile[this.lang],
            id: "import",
            click: () => this.toolbarClick("importFile")
          },
          {
            prefixIcon: "e-icons e-export-excel-2",
            text: this.dict.exportFile[this.lang],
            id: "export",
            click: () => this.toolbarClick("exportCsv")
          },
          {
            prefixIcon: "e-icons e-delete-5",
            text: this.dict.removeAll[this.lang],
            id: "export",
            click: () => this.toolbarClick("removeAll")
          }
        ];
      }

      if (this.isClass) {
        return [{
          prefixIcon: "e-icons e-export-excel-2",
          text: this.dict.exportFile[this.lang],
          id: "export",
          click: () => this.toolbarClick("exportCsv")
        }];
      }
    },
    generateTool() {
      return [{ text: this.lang == "per" ? "اضافه کردن" : "Add", id: "Add_generation", prefixIcon: "e-icons e-add" }];
    },
    toolbarGenerator() {
      if (this.selectedDatas.length > 1 && !this.isClass) {
        return [{
          text: this.dict.addValue[this.lang],
          id: "add_wb",
          prefixIcon: "e-icons e-add"
        },
          {
            text: this.dict.importFile[this.lang],
            id: "importFile",
            prefixIcon: "e-icons e-import"
          },
          {
            text: this.dict.exportFile[this.lang],
            id: "exportCsv",
            prefixIcon: "e-icons e-export-excel-2"
          }, {
            text: this.dict.removeAll[this.lang],
            id: "removeAll",
            prefixIcon: "e-icons e-delete-1 text-danger"
          }
        ];
      } else if (!this.isClass) {
        return [{
          text: this.dict.addValue[this.lang],
          id: "add_wb",
          prefixIcon: "e-icons e-add"
        },
          {
            text: this.dict.importFile[this.lang],
            id: "importFile",
            prefixIcon: "e-icons e-import"
          },
          {
            text: this.dict.exportFile[this.lang],
            id: "exportCsv",
            prefixIcon: "e-icons e-export-excel-2"
          }
        ];
      } else if (this.isClass)
        return [];
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    rendertabList() {
      if (this.isObject || this.isClass)
        return this.tabs.filter((item) => item.name != "generation");
      else return this.tabs.filter((item) => item.name != "values");
    },
    isClass() {
      if (this.data.type != "Number" && this.data.type != "String" && this.data.type != "Boolean" && this.data.type != "Object" && this.data.type != null)
        return true;
    },
    isObject() {
      if (this.data.type != "Number" && this.data.type != "String" && this.data.type != "Boolean" && this.data.type == "Object" && this.data.type != null)
        return true;
    },
    child2child() {
      return this.$store.state.chpge.child2child;
    }
  },
  watch: {
    "data.archive.enable": {
      handler(val) {
        if (val == false) {
          this.data.archive.interval = "null";
          this.data.archive.uponChange = false;
        }
      }
    },
    child2child(newValue, oldValue) {
      if (newValue.task === "pickedData") {
        this.data.generation.vars.push(newValue.item);
        this.$refs.generationTabel.ej2Instances.refresh();
      }
    },
    child2parent(newValue, oldValue) {
      if (newValue.task === "editAlert") {
        if (newValue.type === "New") {
          if (this.data.alert.alerts) {
            if (this.data.type != "Object") {
              this.data.alert.alerts.push(newValue.item);
            } else {
              this.objAlerts.dataId = this.parent2child.item.dataId;
              this.objAlerts.dataType = this.parent2child.item.dataType;
              this.objAlerts.alerts.push(newValue.item);
              this.$refs.alertTable[this.values.indexOf(this.objAlerts.dataId)].refresh();

              if (this.infos.length <= 0) {
                this.infos.push(this.objAlerts);
              } else {
                this.infos.forEach((item) => {
                  if (this.infos.indexOf(item) < -1) {
                    this.objAlerts.alerts.push(newValue.item);
                    this.infos.push(this.objAlerts);
                  }

                  if (item.dataId == this.parent2child.item.dataId)
                    item.alerts.push(newValue.item);
                });
              }
            }
          } else
            this.data.alert["alerts"] = [newValue.item];
        } else if (newValue.type === "Edit") {
          this.data.values = newValue.item.value ? newValue.item.value : [];
          this.data.alert.alerts[newValue.item.id] = newValue.item;
        }
        this.$refs.alertTable.ej2Instances.refresh();
      }
    },
    parent2child(newValue, oldValue) {
      if (newValue.task === "sentData2edit") {
        this.formType = "data";
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Data";
        this.data._id = newValue.item._id ? newValue.item._id : "";
        this.data.name = newValue.item.name ? newValue.item.name : "";
        this.data.type = newValue.item.type ? newValue.item.type : null;
        this.data.archive = newValue.item.archive ? newValue.item.archive : {
          enable: false,
          interval: null,
          uponChange: false
        };
        this.data.transmit = newValue.item.transmit ? newValue.item.transmit : {
          enable: false,
          interval: null,
          uponChange: false
        };
        this.data.generation = newValue.item.generation ? newValue.item.generation : {
          enable: false,
          vars: [],
          formula: ""
        };
        this.data.alert = newValue.item.alert ? newValue.item.alert : { enable: false, alerts: [] };
        this.data.parent = newValue.item.parent ? newValue.item.parent : { ptype: "", pid: "" };
        this.data.values = newValue.item.value ? newValue.item.value : [];

        this.dataReady = true;
      } else if (newValue.task === "sentConf2edit") {
        this.formType = "conf";
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Data";
        this.data._id = newValue.item._id ? newValue.item._id : "";
        this.data.name = newValue.item.name ? newValue.item.name : "";
        this.data.type = "complex";
        this.data.archive = newValue.item.archive ? newValue.item.archive : {
          enable: false,
          interval: null,
          uponChange: false
        };
        this.data.transmit = { enable: false, interval: null, uponChange: false };
        this.data.generation = { enable: false, vars: [], formula: "" };
        this.data.alert = { enable: false, alerts: [] };
        this.data.parent = newValue.item.parent ? newValue.item.parent : { ptype: "", pid: "" };
      }
    },
    "data.type": {
      handler(val) {
        if (this.isClass) {
          this.type == "New" ? this.data.values = [] : this.data.values = this.data.values;


          this.typeList.find((item) => {
            if (item.value == val) {
              item.values.forEach((x) => {
                this.data.values.push(x);
              });
            }
          });
        } else if (this.isObject) {
          if (this.type == "New") {
            this.data.values.push({
              name: "",
              type: "",
              id: v4(),
              value: "",
              required: false,
              enable: false,
              interval: null,
              uponChange: false,
              archived: false
            });
          }
        }
      }, deep: true
    },
    "data.generation.enable": {
      handler(val) {
        if (val == false) {
          this.data.generation.formula = "";
          this.data.generation.vars = [];
        }
      }
    }
  },
  methods: {
    getVal(e) {
      if (e.newProp.hasOwnProperty("pageSize"))
        this.perPage = e.newProp.pageSize;

      this.curPage = e.currentPage;
    },
    getValValue(e) {
      if (e.newProp.hasOwnProperty("pageSize"))
        this.valPerPage = e.newProp.pageSize;

      this.valCurPage = e.currentPage;
    },
    updateSwitch(value, field, id) {
      const index = this.data.values.indexOf(this.data.values.find(item => item.id == id));
      this.data.values[index][field] = value.checked;
    },
    updateData(value, field, id) {
      const index = this.data.values.indexOf(this.data.values.find(item => item.id == id));
      this.data.values[index][field] = value.value;
    },
    deleteRow(val) {
      this.data.values.splice(
        this.data.values.indexOf(
          this.data.values.find((x) => x.id == val.id)
        ), 1);

      this.$refs.negin.ej2Instances.refresh();
    },
    toolbarClick(args) {
      if (args == "add") {
        this.data.values.push({
          name: "",
          type: "",
          id: v4(),
          value: "",
          required: false,
          enable: false,
          interval: null,
          uponChange: false
        });

      } else if (args == "importFile") {
        this.$bvModal.show("modal-upload-excel");
      } else if (args == "exportCsv") {
        this.exportData();
      } else if (args == "removeAll") {
        this.deleteAll();
      }
    },
    toolbarClicks(args) {
      if (args.item.id == "Add_generation") {
        this.pickData();
      }
    },
    v4,
    initialtime() {
      // setTimeout(() => {
      //   this.validator = new FormValidator("#data_mnager_add");
      // }, 100);
      this.getAllClasses();
      this.activeTab = "definition";
    },
    initModal: function() {

      const startDateTimePicker = new DateTimePicker({
        value: this.from, // Set the initial date
        placeholder: "Start time",
        floatLabelType: "Auto",
        locale: "en",
        change: (args) => {
          this.from = args.value;
        }
      });

      const endDateTimePicker = new DateTimePicker({
        value: this.to, // Set the initial date
        placeholder: "End time",
        floatLabelType: "Auto",
        locale: "en",
        change: (args) => {
          this.to = args.value;
        }
      });
      startDateTimePicker.appendTo("#startDTP");
      endDateTimePicker.appendTo("#endDTP");
    },
    resetModal: function() {
      this.type = "";
      this.modalTitle = "";
      this.data.name = "";
      this.data.type = null;
      this.data.archive = { enable: false, interval: "null", uponChange: false };
      this.data.transmit = { enable: false, interval: "null", uponChange: false };
      this.data.generation = { enable: false, vars: [], formula: "" };
      this.data.alert = { enable: false, alerts: [] };
      this.data.values = [];
      this.selectedDatas = [];

      this.submitted = null;
      this.successful = null;
      this.message = null;
      this.validator = null;

      this.dataReady = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#data_mnager_add").validate();
      if (isValid) {
        if (this.data.generation.enable)
          this.data.parent.ptype = "Internal";

        if (this.type === "New") {
          // if (this.data.type == "Object") {
          //   this.values.forEach((item) => {
          //     this.data.alert.alerts.push({
          //       "dataId": item.id,
          //       "dataType": item.type,
          //       alerts: item.alerts
          //     });
          //   });
          // }
          if (this.checkDuplications()) {
            this.$bvModal.msgBoxConfirm("Data names must be unique.Do you want to replace them?").then((val) => {
              if (val == true) {
                this.uniqObjectByKey(this.data.values, "name");
                if (this.isClass) {
                  const val1 = this.data.values;
                  this.data.values = [];
                  val1.forEach((i) => {
                    this.data.values.push({ id: i.id, value: i.value });
                  });
                }
                this.$store.dispatch("data/createData", this.data).then(
                  data => {
                    this.message = data.message;
                    this.successful = true;
                    this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
                    this.$nextTick(() => {
                      this.values = [{ name: "", type: "", id: 0 }];
                      this.data.values = [];
                      this.$bvModal.hide("modal-data-manager");
                    });
                  },
                  error => {
                    this.message =
                      (error.response && error.response.data && error.response.data.message) ||
                      error.message ||
                      error.toString();
                    this.successful = false;
                    // alert(this.message);
                  }
                );
              }
            });

          } else {
            if (this.isClass) {
              const val1 = this.data.values;
              this.data.values = [];
              val1.forEach((i) => {
                this.data.values.push({ id: i.id, value: i.value });
              });
            }
            this.$store.dispatch("data/createData", this.data).then(
              data => {
                this.message = data.message;
                this.successful = true;
                this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
                this.$nextTick(() => {
                  this.data.values = [];
                  this.$bvModal.hide("modal-data-manager");
                });
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                // alert(this.message);
              }
            );
          }


        } else if (this.type === "Edit") {
          if (this.data.values.length <= 0 && this.data.type == "Number")
            this.data.values = undefined;
          var sdata = this.data;
          sdata["updataValue"] = false;
          if (true) {
            this.$store.dispatch("data/updateData", sdata).then(
              data => {

                this.message = data.message;
                this.successful = true;

                this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
                this.$nextTick(() => {
                  this.$bvModal.hide("modal-data-manager");
                });
              },

              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                alert(this.message);
              }
            );
          } else {
            alert("Data names must be unique.check and fix it.");
          }

        }
      }

    },
    checkDuplications() {
      let duplicates = [];

      duplicates = this.data.values.filter((item, index) => this.data.values.some((elem, idx) => elem.name == item.name && idx !== index));
      return duplicates.length > 0;
    },
    uniqObjectByKey(arr, key) {
      let obj = {};

      arr.forEach(item => {
        obj[key] = item;
      });

      arr.splice(arr.indexOf(obj), 1);

      return Object.values(obj);
    },
    moveItemUp(item) {
      const currentIndex = this.data.generation.vars.indexOf(item);
      if (currentIndex > 0) {
        this.data.generation.vars.splice(currentIndex, 1);
        this.data.generation.vars.splice(currentIndex - 1, 0, item);
      }
    },
    removeData(value) {
      let item = this.data.generation.vars.find(i => i._id == value.id);
      this.data.generation.vars.splice(this.data.generation.vars.indexOf(item));
      this.$refs.generationTabel.ej2Instances.refresh();
    },
    moveItemDown(item) {
      const currentIndex = this.data.generation.vars.indexOf(item);
      if (currentIndex < this.data.generation.vars.length - 1) {
        this.data.generation.vars.splice(currentIndex, 1);
        this.data.generation.vars.splice(currentIndex + 1, 0, item);
      }
    },
    pickData() {
      this.$store.dispatch("chpge/dataPickerCaller", {
        task: "pickedData", from: "generation", filter: {
          dataType: ["String", "Number", "Boolean", "Object"],
          usedBy: [], parents: [], archive: null, perPage: 5000, page: 1, withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    removeAlertItem(item) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.data.alert.alerts.findIndex(x => x.id === item.id);
            if (indx != -1) {
              this.data.alert.alerts.splice(indx, 1);
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    getFileFromInput(file) {
      this.file = file.filesData[0].rawFile;
    },
    uploadXslx() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", this.data._id);
      this.$store.dispatch("data/uploadFileXlsx", formData).then((res) => {
        alert("the file uploaded successfully!");
      });
      this.$bvModal.hide("modal-upload-excelArchive");
    },
    searchXslx() {
      const end = new Date(this.to);
      const start = new Date(this.from);
      const info = {
        id: this.data._id,
        to: end.getTime(end) / 1000,
        from: start.getTime(start) / 1000
      };
      let outPut = [];
      this.$store.dispatch("data/dlXlsx", info).then((res) => {

        res.forEach((item, key) => {
          Object.entries(item).forEach((i) => {
            outPut.push(
              Object.assign({}, { timeStamp: i[0] }, i[1])
            );
          });
        });

        this.$store.commit("data/getDataForXslx", res);

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(outPut);
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, "demo.xlsx");

      }).catch((err) => {
        alert("please upload your file.");
      });
    },
    openDetails() {
      const end = new Date(this.to);
      const start = new Date(this.from);
      const info = {
        id: this.data._id,
        to: end.getTime(end) / 1000,
        from: start.getTime(start) / 1000
      };

      this.$store.dispatch("data/dlXlsx", info).then((res) => {
        this.$store.commit("data/getDataForXslx", res);
        this.to = "";
        this.from = "";
        this.$bvModal.show("xslxDetails");

      }).catch((err) => {
        alert("please upload your file or check the time to show files");
      });
    },
    readXslxForValue() {
      if (this.file) {
        let repeated = [];
        const reader = new FileReader();

        reader.onload = (e) => {

          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          if (this.data.values.length == 1 && this.data.values[0].name == "") {
            this.data.values = [];
          }


          data.forEach((val) => {
            this.data.values.forEach((item) => {
              if (val[0] === item.name) {
                repeated.push(val[0]);
              }
            });
          });


          if (repeated.length > 0) {
            this.$bvModal.msgBoxConfirm("Some data names are repeated.Do you want to replace them?").then((value) => {
              if (value == true) {
                this.data.values = [];
                data.forEach((item) => {
                  if (item[0] != "timestamps" && item[0] != "name") {
                    this.data.values.push(
                      {
                        name: item[0], type: this.translateTypes[item[1]], id: v4(), value: "", required: false,
                        enable: false,
                        interval: null,
                        uponChange: false,
                        archived: false
                      }
                    );
                  }
                  // if (typeof item[0] != "number") {
                  //
                  // } else {
                  //   Vue.notify({
                  //     type: "alarm",
                  //     title: "error!",
                  //     text: " Data name can not be a number.",
                  //     duration: 5000,
                  //     speed: 500
                  //   });
                  // }

                });
              } else {
                console.log("eh!");
              }
            });
          } else
            data.forEach((item) => {
              // if (typeof item[0] != "number") {
              //
              // } else {
              //   Vue.notify({
              //     type: "alarm",
              //     title: "error!",
              //     text: " Data name can not be a number.",
              //     duration: 5000,
              //     speed: 500
              //   });
              // }
              if (item[0] != "timestamps" && item[0] != "name") {
                this.data.values.push(
                  {
                    name: item[0], type: this.translateTypes[item[1]], id: v4(), value: "", required: false,
                    enable: false,
                    interval: null,
                    uponChange: false,
                    archived: false
                  }
                );
              }

            });

          this.file = "";
          this.$bvModal.hide("modal-upload-excel");
        };
        reader.readAsBinaryString(this.file);
      }
    },
    exportData() {
      let output = [];
      if (this.selectedDatas.length <= 0) {
        this.data.values.forEach((item) => {
          output.push([
            item.name, item.type
          ]);
        });
      } else {
        this.selectedDatas.forEach((item) => {
          output.push([
            item.name, item.type
          ]);
        });
      }
      const worksheet = XLSX.utils.aoa_to_sheet(output);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(workbook, "data.xlsx");
      output = [];
    },
    deleteAll() {
      this.$bvModal.msgBoxConfirm("Are you sure to remove these items?").then((val) => {
        if (val == true) {
          this.data.values = [
            {
              name: "",
              type: "",
              id: v4(),
              value: "",
              required: false,
              enable: false,
              interval: null,
              uponChange: false
            }
          ];
        }
        this.selectedDatas = [];
      });
    },
    selectAllData(value, id) {
      if (this.selectedDatas.indexOf(id) < 0 && value.checked == true)
        this.selectedDatas.push(id);
      else this.selectedDatas.splice(this.selectedDatas.indexOf(id));
    },
    readIt() {
      if (this.file && (this.data.values.length > 0 || this.values.length >= 2)) {
        const reader = new FileReader();

        reader.onload = (e) => {

          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          const notRepeated = [];
          let names = [];
          if (this.data.values.length <= 1) {
            this.data.values = [];
          }


          names = this.data.values.map((i) => {
            if (i.name != "" && i.name != null && i.name != undefined) {
              return i.name;
            }
          });


          if (names.length > 0 && names[0] != undefined) {
            data[0].forEach((val) => {
              let foundedIndex = names.indexOf(val);
              if (foundedIndex < 0) {
                notRepeated.push(val);
              }
            });
          } else {
            data[0].forEach((val) => {
              notRepeated.push(val);
            });
          }
          if (notRepeated.length > 0) {
            this.$bvModal.msgBoxConfirm("some data names are undefined in values.Do you want to auto define them?").then((value) => {
              if (value == true) {
                notRepeated.forEach((item) => {
                  if (item != "timestamps" && item != "name") {
                    this.data.values.push(
                      {
                        name: item, type: "String", id: v4(), value: "", required: false,
                        enable: false,
                        interval: null,
                        uponChange: false,
                        archived: false
                      }
                    );
                  }
                });
                this.activeTab = "values";
                this.uploadXslx();
                this.$nextTick(() => {
                  this.$bvModal.hide("modal-upload-excelArchive");
                });
              } else {
                this.$nextTick(() => {
                  this.$bvModal.hide("modal-upload-excelArchive");
                });
              }
            });
          } else {
            notRepeated.forEach((item) => {
              if (item != "timestamps" && item != "name") {
                this.data.values.push(
                  {
                    name: item, type: "String", id: v4(), value: "", required: false,
                    enable: false,
                    interval: null,
                    uponChange: false,
                    archived: false
                  }
                );
              }
            });
            this.activeTab = "values";
            this.uploadXslx();
          }
        };
        reader.readAsBinaryString(this.file);
      } else
        this.uploadXslx();
    },
    getAllClasses() {
      this.typeList = [];
      const types = [{ per: "یک مورد را انتخاب کنید", eng: "Select One", value: "null", values: null },
        { per: "عدد", eng: "number", value: "Number", values: null },
        { per: "رشته", eng: "string", value: "String", values: null },
        { per: "داده منطقی (Boolean)", eng: "boolean", value: "Boolean", values: null },
        { per: "شی (آبجکت)", eng: "object", value: "Object", values: null }
      ];

      this.typeList = types;
      this.$store.dispatch("data/getAllClasses").then((res) => {
        res.forEach((item) => {
          this.typeList.push({
            per: item.name, eng: item.name, value: item._id, values: item.value
          });
        });
      });
    }
  },
  mounted() {
    this.getAllClasses();
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }

};
</script>

<style scoped>
.disableArchive {
  color: rgba(0, 0, 0, 0.42);
  background: transparent linear-gradient(90deg, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0) repeat-x bottom -1px left 0;
  background-size: 4px 1px;
  border-bottom-color: transparent;
  pointer-events: none;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}

.exBtn:hover > img {
  color: #f0f0f2 !important;
  background: url('/src/assets/icons/excel-icon.svg') no-repeat 0 -100px;
}


</style>