<template>
    <div class="yekan" style="text-align:left">
        <div class="yekan">
            <b-container fluid="md">
                <b-row class=my-5>
                    <b-col cols="1"></b-col>
                    <b-col cols="10" sm>
                        <b-card no-body>
                            <b-card-body>
                                <b-form ref="form" @submit.stop.prevent="handleSubmit" @reset="onReset">
                    
                                    <div style="display: flex; align-items: center; flex-wrap: wrap;">
                                        <h4 style="flex: 90%;">{{ title}}</h4>
                                        <b-button type="submit"  variant="outline-success"  class="mr-2" style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                        <b-button type="reset"  variant="outline-danger"  class="mr-2" style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>

                                    <hr class="my-2">

                                    <!-- <form ref="form" @submit.stop.prevent="handleSubmit"> -->
                                    <b-list-group flush>
                                        <b-list-group-item style="background-color:transparent;color:black;">
                                            <div style="display:flex; flex-direction:row">

                                                
                                                <b-form-group
                                                    label="Name"
                                                    label-for="name"                                            
                                                    :invalid-feedback="errors.first('name')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter name"
                                                        v-model="scenario.name"
                                                        :state="(submitted)? (!errors.has('name')): null"  
                                                        v-validate="'required|min:2|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>  
                                                
                                                <b-form-group
                                                    label="Floor"
                                                    label-for="floor"
                                                    
                                                    :invalid-feedback="errors.first('floor')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="floor"
                                                        :disabled="(type==='Edit'||scenario.floor)? true:false"  
                                                        size="lg"
                                                        v-model="scenario.floor"
                                                        :options="floorList"
                                                        :state="(submitted)? (!errors.has('floor')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="getGroups($event)"
                                                    ></b-form-select>
                                                </b-form-group> 

                                                <b-form-group v-if="scenario.floor"
                                                    label="Type"
                                                    label-for="type"
                                                    :invalid-feedback="errors.first('type')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="type"
                                                        size="lg"
                                                        v-model="scenario.type"
                                                        :options="typeList"
                                                        :state="(submitted)? (!errors.has('type')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        
                                                    ></b-form-select>
                                                </b-form-group> 
                                                
                                                

                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-form>

                                <div v-if="scenario.type === 'static'">
                                    static
                                </div>
                                <div v-else-if="scenario.type === 'scheduled'">
                                    <b-card-body>
                                        <div id='container'>
                                            <ejs-schedule id="Schedule" height='550px' ref="scheduleObj" :selectedDate='selectedDate' :eventSettings='eventSettings' 
                                            :currentView='currentView' :workDays='workDays' :popupOpen='onPopupOpen' >
                                                <e-views>
                                                    <e-view option='Week'></e-view>
                                                    <e-view option='Month'></e-view>
                                                    <e-view option='Year'></e-view>
                                                    <e-view option='Agenda'></e-view>
                                                </e-views>
                                            </ejs-schedule>
                                        </div>
                                    </b-card-body>
                                </div>
                                <div v-else-if="scenario.type === 'sensor'">
                                    sensor based
                                </div>
                                <div v-else>
                                    no type is selected
                                </div>
                            
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container> 
        </div>
    </div>
</template>


<script>
/* eslint-disable */
    import { SchedulePlugin, Week, Month, Year,Agenda, Resize, DragAndDrop, ActionEventArgs } from '@syncfusion/ej2-vue-schedule';
    import { MultiSelect } from '@syncfusion/ej2-dropdowns';
    import { createElement } from '@syncfusion/ej2-base';
    import { DataManager, UrlAdaptor, Query} from '@syncfusion/ej2-data';
        import { DropDownList } from '@syncfusion/ej2-dropdowns';
    import { Slider } from '@syncfusion/ej2-inputs';
    import { v4 as uuidv4 } from 'uuid';
    import {host} from '../models/host';

    let dataManager = new DataManager({
        url: host + "/api/data/schedule/get",
        crudUrl: host + "/api/data/schedule/update",
        adaptor: new UrlAdaptor,
    });
    let dataQuery = new Query().addParams('uuid','');

    export default {
        name: "groupsModal",
        data() {
            return {
                selectedDate: new Date(),
                currentView: 'Week',
                workDays: [1,2,3,4,5,6,7],
                eventSettings: { dataSource: dataManager, query: dataQuery },
                Groups:[],
                selectedGroups:[],
                
                scenario:{
                    type:'',
                    name:'',
                    uuid:'',
                    floor:''
                },
                floorList:[],
                typeList: [{ text: 'Select One', value: null },'static','scheduled','sensor'],

                submitted: false,
                successful: false,
                message: '',

                title:'',
            }
        },
        provide: {
            schedule: [Week, Year, Month, Agenda,DragAndDrop,Resize]
        },

        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            }
        },
        methods: {
            initModal() {
                this.title = this.type + " Scenario"
                this.getBuildConf();

                

                if (this.type==='Add')
                {
                    this.scenario.name = null;
                    this.scenario.type = null;
                    this.scenario.floor = null;
                    this.scenario.uuid = uuidv4();
                }
                else if (this.type==='Edit')
                {
                    this.scenario.name = this.db[this.index].name;
                    this.scenario.type = this.db[this.index].type;
                    this.scenario.floor = this.db[this.index].floor._id;
                    this.scenario.uuid = this.db[this.index].uuid;
                    this.getGroups(this.scenario.floor)
                }
                dataQuery.params[0].value = this.scenario.uuid;
            },
            onReset() {
                this.scenario.name = null;
                this.scenario.type = null;
                this.scenario.floor = null;
                
                if (this.type==='Add'){
                    //removeSchedule(this.scenario.uuid)
                    this.$store.dispatch('data/removeScenario', this.scenario.uuid).then(
                        data => {
                            
                        this.message = data.message;
                        this.successful = true;
                        this.scenario.uuid = null;
                        this.$store.dispatch('chpge/changePage','scenarioSettings')
                        },
                        error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.successful = false;
                        alert(this.message)
                        }
                    );
                }
                else{
                    this.$store.dispatch('data/editScenario', this.db[this.index]).then(
                        data => {
                            
                        this.message = data.message;
                        this.successful = true;
                        this.scenario.uuid = null;
                        this.$store.dispatch('chpge/changePage','scenarioSettings')
                        },
                        error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.successful = false;
                        alert(this.message)
                        }
                    );
                }
            },
            handleSubmit() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        console.log("valid")
                        if (this.type==='Add'){

                            this.$store.dispatch('data/createScenario', this.scenario).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                
                                this.$store.dispatch('chpge/changePage','scenarioSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                alert(this.message)
                                }
                            );
                        }else if (this.type==='Edit'){
                            
                            this.$store.dispatch('data/editScenario', this.scenario).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','scenarioSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                alert(this.message)
                                }
                            );
                        }
                    }
                });
            },

                // if (args.type === 'Editor') {
                //     console.log("-------------")
                //     // Create required custom elements in initial time
                //     if (!args.element.querySelector('.custom-field-row')) {
                //     let row = createElement('div', { className: 'custom-field-row' });
                //     let formElement = args.element.querySelector('.e-schedule-form');
                //     formElement.firstChild.insertBefore(row, args.element.querySelector('.e-start-end-row'));
                //     let container = createElement('div', { className: 'custom-field-container' });
                //     let inputEle = createElement('input', {
                //         className: 'e-field', attrs: { name: 'selectedGroups' }
                //     });
                //     let lableEle = createElement('label', {
                //         attrs: { for: 'MultiSelect' } , innerHTML:'Selected Groups'
                //     });
                //     lableEle.style.color = "rgba(0,0,0,0.54)";

                //     container.appendChild(lableEle);
                //     container.appendChild(inputEle);

                //     row.appendChild(container);

                //     // args.data.selectedGroups = this.selectedGroups;

                //     let multiSelectObject = new MultiSelect({
                //         id: 'MultiSelect',
                //         placeholder: 'Choose Groups',
                //         fields: { text: 'text', value: 'id' },
                //         dataSource: this.Groups,
                //         value: args.data.selectedGroups,
                //     });
                //     multiSelectObject.appendTo(inputEle);

                //     let titleelement = args.element.querySelectorAll('.e-location-container'); 
                //     titleelement[0].remove();

                //     let descriptionelement = args.element.querySelectorAll('.e-description-row'); 
                //     descriptionelement[0].remove();

                //     inputEle.setAttribute('name', 'selectedGroups');
                //     }
                // }
            onPopupOpen: function(args) {
    



                if (args.type === 'Editor') {
                    console.log("-------------")
                    // Create required custom elements in initial time
                    if (!args.element.querySelector('.custom-field-row')) {
                        let row = createElement('div', { className: 'custom-field-row' });
                        let formElement = args.element.querySelector('.e-schedule-form');
                        formElement.firstChild.insertBefore(row, args.element.querySelector('.e-start-end-row'));
                        
                        let container = createElement('div', { className: 'custom-field-container' });
                        let inputEle = createElement('input', {
                            className: 'e-field', attrs: { name: 'selectedGroups' }
                        });
                        let lableEle = createElement('label', {className: 'custom-label',
                            attrs: { for: 'MultiSelect' } , innerHTML:'Selected Groups'
                        });


                        let sliderEle = createElement('div', {id: 'slider', attrs: { name: 'slider' }});

                        let sliderEle2 = createElement('input', {id: 'range', attrs: { name: 'slider', type:'range' }});
                        // lableEle.style.color = "rgba(0, 0, 0, 0.54)";

                        container.appendChild(lableEle);
                        container.appendChild(inputEle);
                        container.appendChild(sliderEle);
                        container.appendChild(sliderEle2);

                        row.appendChild(container);

                        // args.data.selectedGroups = this.selectedGroups;

                        // let multiSelectObject = new MultiSelect({
                        //     id: 'MultiSelect',
                        //     placeholder: 'Choose Groups',
                        //     fields: { text: 'text', value: 'id' },
                        //     dataSource: this.groups,
                        //     value: args.data.selectedGroups,
                        // });
                        // multiSelectObject.appendTo(inputEle);

                        let dropDownListObject = new DropDownList({
                                placeholder: 'وضعیت روشنایی برای این بازه زمانی را انتخاب کنید', value: args.data.selectedGroups,
                                fields: { text: 'text', value: 'value' },
                                dataSource: this.groupsList,
                            });
                        dropDownListObject.appendTo(inputEle);

                        console.log(args.data.slider)


                        let slider = new Slider({value: args.data.slider, type: 'MinRange'});
                        slider.appendTo(sliderEle);





                            
                        // DurElement.ej2_instances[0].reposition(); 
                        // DurElement.ej2_instances[0].zIndex = 2000; 



                        let titleelement = args.element.querySelectorAll('.e-location-container'); 
                        titleelement[0].remove();

                        let descriptionelement = args.element.querySelectorAll('.e-description-row'); 
                        descriptionelement[0].remove();

                        inputEle.setAttribute('name', 'selectedGroups');
                    }
                    
                        var DurElement = args.element.querySelector("#range"); 
                        DurElement.value = args.data.slider 


                        // var DurElement = args.element.querySelector("#slider"); 
                        // console.log(DurElement.ej2_instances[0])
                        // DurElement.ej2_instances[0].reposition(); 
                        // DurElement.ej2_instances[0].zIndex = 2000; 

                }
            },

            onPopupClose: function(args) {
                if(args.type === 'Editor' && args.data) { 
                    var DurElement = args.element.querySelector(".e-slider-input"); 

                    args.data.slider = DurElement.value; 
                    console.log( args.data.slider)
                } 
            },


                

            getGroups(floor) {
                this.Groups=[]
                if (floor){
                    this.$store.dispatch('data/getGroupsofFloor',{floor:floor}).then(
                        data => {

                            for (var group of data){
                                this.Groups.push({ text: group.name, id: group._id })
                            }
                            console.log(JSON.stringify(this.Groups))
                        },
                        error => {
                            this.message =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                                // this.successful = false;
                        }
                    );
                }
            },
            getBuildConf() {
                this.$store.dispatch('data/getBuildConf').then(
                    data => {
                        this.floorList= [{ text: 'Select One', value: null }];
                        for (var d of data){
                            this.floorList.push({text:d.floorTitle,value:d._id})
                        }

                        console.log(JSON.stringify(this.floorList))
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
        },
        
        mounted() {
            this.initModal()
        }
    };
</script>

<style scoped>
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
</style>
