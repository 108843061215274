<template>
    <div class="yekan">
        <b-modal
        id="modal-setDashDGauge"
        size="lg"
        centered
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div class="yekan">
            <!-- <b-container fluid="md"> -->
                <!-- <div style="padding: 0px 20px 0px 20px;"> -->
                    <b-list-group flush>
                        <b-list-group-item style="background-color:transparent;color:black;">
                            <div style="display:flex; flex-direction:row">
                                <b-form-group
                                    label="Gauge Title"
                                    label-for="title"                                            
                                    :invalid-feedback="errors.first('title')"
                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                >
                                    <b-form-input
                                        name="title"
                                        type="text"
                                        placeholder="Enter title"
                                        v-model="gauge.name"
                                        :state="(submitted)? (!errors.has('title')): null"  
                                        v-validate="'required|min:2|max:20'"
                                        style="width:100%"
                                    ></b-form-input>
                                </b-form-group>  

                                <b-form-group
                                    label="Gauge Type"
                                    label-for="type"
                                    :invalid-feedback="errors.first('type')"
                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                >
                                    <b-form-select
                                        
                                        style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                        name="type"
                                        size="lg"
                                        v-model="gauge.type"
                                        :options="typeList"
                                        :state="(submitted)? (!errors.has('type')): null"  
                                        v-validate="'required'"
                                    ></b-form-select>
                                </b-form-group>
                            </div>


                                <b-form-group
                                    label="Gauge Data"
                                    label-for="Data"
                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                >
                                    <b-table :items="gauge.members" :fields="dataFields"  responsive="sm" small > 
                                        <template #cell(icon) >
                                            <i><font-awesome-icon icon="fas fa-database" /></i>  
                                        </template>

                                        <template #cell(did)="row">
                                            <i>{{ row.item.did }}</i>
                                        </template>

                                        <template #cell(dname)="row">
                                            <b-form-group
                                                label-for="name"                                            
                                                :invalid-feedback="errors.first('name')"
                                            >
                                                <b-form-input
                                                    
                                                    style=" font-variant:small-caps; font-weight: bold; width:100%; height:100%; padding: 0px;"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Enter name"
                                                    v-model="row.item.dname"
                                                    :state="(submitted)? (!errors.has('name')): null"  
                                                    v-validate="'required|min:3|max:20'"
                                                ></b-form-input>
                                            </b-form-group>
                                        </template>

                                        <template #cell(modify)="row" >
                                            <div style="display:flex; justify-content:flex-end;">
                                                <b-button pill size="sm" variant="outline-success" @click="setDataAddress(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                                </b-button>
                                                <b-button pill size="sm" variant="outline-danger" @click="removeData(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                                </b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                    <div style="display:flex; justify-content: right; align-items: center;">
                                        <b-button   @click="addData" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                                            <i><font-awesome-icon icon="fas fa-plus" /></i>
                                        </b-button>
                                    </div>
                                </b-form-group>

                                <b-form-group
                                    label="Operation"
                                    label-for="operation"
                                    :invalid-feedback="errors.first('operation')"
                                    style="width:50%; padding: 0px 10px 10px 0px;"
                                >
                                    <b-form-select
                                        style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                        name="operation"
                                        size="lg"
                                        v-model="gauge.operation"
                                        :options="operationList"
                                        :state="(submitted)? (!errors.has('operation')): null"  
                                        v-validate="'required'"
                                    ></b-form-select>
                                </b-form-group>



                        </b-list-group-item>
                    </b-list-group>
                <!-- </div> -->
            <!-- </b-container> -->
        </div>
        </b-modal>
    </div>
</template>




<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        props: {
            pid: String
        },
        
        data() {
            return {
                modalTitle:'',
                gauge:{
                    name:'',
                    type:null,
                    members:[],
                    id:'',
                    operation:null
                },
                component:{},
                // id:'',
                type:'',
                typeList:[  { value: null, text: 'یک نوع از گیج ها را انتخاب کنید'},
                            { value: 'G1', text: 'دما'},
                            { value: 'G2', text: 'رطوبت'},
                            { value: 'G3', text: 'شدت نور'},
                            { value: 'G4', text: 'حجم مخزن'},
                            { value: 'G5', text: 'دی اکسید کربن'},
                            { value: 'G6', text: 'رطوبت خاک'}],
                operationList:[{ value: null, text: 'یک عملیات را انتخاب کنید'},
                            { value: 'O0', text: 'بدون عملیات'},
                            { value: 'O1', text: 'میانگین'},
                            { value: 'O2', text: 'بیشینه'},
                            { value: 'O3', text: 'کمینه'},
                            { value: 'O4', text: 'جمع'},
                            { value: 'O5', text: 'تفاضل'}],
                dataFields: [
                    { key: 'icon', label: '',thStyle: { width: "5%" },},
                    { key: 'dname', label:'',thStyle: { width: "40%" }},
                    { key: 'did', label: '',thStyle: { width: "50%" }},
                    { key: 'modify', label: '',thStyle: { width: "5%" }}],
                
                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
            child2parent() {
                return this.$store.state.chpge.child2parent;
            }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='gauge_modal'){
                    this.db = newValue.data
                    this.type = newValue.type
                    console.log('-----------',JSON.stringify(this.db))
                }
            },
            child2parent(newValue, oldValue) {
                if (newValue.task==='dataSel'){
                    this.gauge.members[newValue.index].did = newValue.id
                }
            }
        },
        methods: {
            initModal: function(){
                this.modalTitle = this.type + ' Digital Gauge'
                if (this.type==='Edit')
                {
                    this.component._id          = this.db._id;
                    this.component.name       = this.db.name;
                    this.component.data        = this.db.data? this.db.data:{};
                    this.component.attribute    = this.db.attribute? this.db.attribute:{};

                    this.db.data       = this.db.data? this.db.data:{};
                    this.gauge.id      = this.db.data.gauge? this.db.data.gauge.id:'';
                    this.gauge.name    = this.db.data.gauge? this.db.data.gauge.name:'';
                    this.gauge.type    = this.db.data.gauge? this.db.data.gauge.type:null;
                    this.gauge.members = this.db.data.gauge? this.db.data.gauge.members:[];
                    this.gauge.operation     = this.db.data.gauge? this.db.data.gauge.operation:null;
                }

            },
            resetModal: function(){
                this.component.attribute = {};
                this.component.data = {};
                this.component._id = '';
                this.component.name = '';

                this.gauge.id='',
                this.gauge.name='',
                this.gauge.type=null,
                this.gauge.operation = null,
                this.gauge.members = [],
                
                // this.groups=[],
                this.id=''
                this.type=''

                this.submitted = null;
                this.successful = null;
                this.message= null;

                // this.$store.dispatch('chpge/parent2child', {task: 'close_group_modal'})
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                console.log('submit',this.type)
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.component.data = {gauge: JSON.parse(JSON.stringify(this.gauge))}
                        this.component.attribute = {}

                        this.$store.dispatch('chpge/child2parent', {task: 'setDashboard', type:'Edit', data: this.component})
                        
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal-setDashDGauge')
                        })
                    }
                });
            },
            getRecordSettings() {
                this.$store.dispatch('data/getRecordSettings').then(
                    data => {
                        for (var d of data.data){
                            this.recordList.push({ value: d._id, text: d.dname})
                        }
                        
                        // console.log(JSON.stringify(this.equipment))
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
            setDataAddress: function(index,event){
                this.$store.dispatch('chpge/parent2child', {index:index, id:this.gauge.members[index].did})
                this.$root.$emit('bv::show::modal', 'modal-datasel');
            },
            removeData(index){
                this.gauge.members.splice(index,1)
            },
            addData(){
                this.gauge.members.push({did:null, dname:''})
            },
        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .panel_header {
        padding: 10px;
        height: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-variant:small-caps;
        border-bottom: 2px solid rgb(210, 210, 210);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .panel_col {
        height: 100%; 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>