<template>
  <div style="height:100%; width:100%; display: flex;">
    <div style="width:50%; display: flex; justify-content: center; align-items: center; color: cornflowerblue">
      <div>
        <img v-if="data.gauge.type ==='G1'" class="black-white" src="../assets/images/temperature-5.svg" height="40 %" >
        <img v-else-if="data.gauge.type ==='G2'" class="black-white" src="../assets/images/humidity.svg" height="40 %" >
        <img v-else-if="data.gauge.type ==='G3'" class="black-white" src="../assets/images/brightness.svg" height="40 %" >
        <img v-else-if="data.gauge.type ==='G4'" class="black-white" src="../assets/images/water.svg" height="40 %" >
        <img v-else-if="data.gauge.type ==='G5'" class="black-white" src="../assets/images/co2-3.svg" height="40 %" >
        <img v-else-if="data.gauge.type ==='G6'" class="black-white" src="../assets/images/moisture-1.svg" height="40 %" >
        <p style="margin-bottom: 0;">{{data.gauge.name}}</p>
        <p v-if="data.gauge.type ==='G1'" style="margin-bottom: 0;">(℃)</p>
        <p v-else-if="data.gauge.type ==='G2'" style="margin-bottom: 0;">(%)</p>
        <p v-else-if="data.gauge.type ==='G3'" style="margin-bottom: 0;">(lux)</p>
        <p v-else-if="data.gauge.type ==='G4'" style="margin-bottom: 0;">(L)</p>
        <p v-else-if="data.gauge.type ==='G5'" style="margin-bottom: 0;">(ppm)</p>
        <p v-else-if="data.gauge.type ==='G6'" style="margin-bottom: 0;">(%)</p>
      </div>
    </div>
    <div style="width:50%; display:flex; justify-content: center; align-items: center; flex-direction:column;">
      <div class="myvalue">
        <p style="margin-bottom: 0;">{{gaugeData.value}}</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: "CustomPath",
    props: {
      plotId: String,
      data: Object
    },
    data() {
      return {
        gaugeData: {value:null, dids:[],datasets:[]},
      };
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      lang() {
        return this.$store.state.chpge.lang;
      }
    },
    watch: {
      updatedData(newValue) {

        for (var d of newValue){
          var dindex = this.gaugeData.datasets.findIndex(x => x.id === d.id)
          if (dindex != -1)
            this.gaugeData.datasets[dindex].value = parseFloat(d.value)
        }
        this.calculateValue();
      },
    },
    methods: {
      initGauge(){
        this.gaugeData = {value:null, dids:[],datasets:[]}
        for (var d of this.data.gauge.members){
          this.gaugeData.datasets.push({id:d.did, name:d.dname, value:null})
          this.gaugeData.dids.push(d.did)
        }

        this.$store.dispatch('panel/getData', {id:this.gaugeData.dids}).then(
          data => {
            for (var d of data){
              var dindex = this.gaugeData.datasets.findIndex(x => x.id === d.id)
              if (dindex != -1)
                this.gaugeData.datasets[dindex].value = parseFloat(d.value)
            }
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
              // alert(message)
          }
        );

      },
      calculateValue(){
          var sum = 0;
          var cnt = 0;
          for (var d of this.gaugeData.datasets){
            if (d.value){
              sum += d.value;
              cnt += 1;
            }
          }
          if (cnt>0)
            this.gaugeData.value = (sum/cnt).toFixed(2);
      }
    },

    mounted() {
      this.initGauge()
      // this.selectedChart = this.data.selectedChart;
      // this.selChart(this.selectedChart)
    }
};
</script>



<style scoped>
  .black-white{
    filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(135deg) brightness(106%) contrast(101%);
  }

  .myvalue {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: normal;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 2px rgb(68, 66, 66), 0 0 25px rgb(120, 120, 224), 0 0 5px rgb(104, 104, 118);
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
</style>