<template>
  <div class="container-fluid h-100 p-0 bg-white">
    <edit-user-data
      ref="registerMe"
      :is-register="true"
    />
  </div>
</template>

<script>
import EditUserData from "@/components/editUserData.vue";

export default {
  name: "registerForms",
  components: { EditUserData },
  data() {
    return {
      dict: {
        dir: { eng: "ltr", per: "rtl" }
      },
      captchaImageUrl: "",
      message: "",
      sessionId: "",
    };
  },
  methods: {
    refreshCaptcha() {
      this.$store.dispatch("auth/captcha").then(
        (response) => {
          this.captchaImageUrl = response.data.imageUrl;
          this.sessionId = response.data.sessionId;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
};
</script>

<style>
.cascading-right {
  margin-right: 75%;
  margin-left: -75%;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.cascading-left {
  margin-right: -75%;
  margin-left: 75%;
}

@media (max-width: 991.98px) {
  .cascading-left {
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>

<!-- @media (max-width: 991.98px) {
.cascading-right {
  margin-right: 0;
}
} -->
