<script>

export default {
  name: "areaMarker",
  props: {
    color: { default: "#000000" }
  }
};
</script>

<template>
  <div>
    <svg width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
      <path :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M18 16.016c1.245.529 2 1.223 2 1.984 0 1.657-3.582 3-8 3s-8-1.343-8-3c0-.76.755-1.456 2-1.984"/>
      <path :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M17 8.444C17 11.537 12 17 12 17s-5-5.463-5-8.556C7 5.352 9.239 3 12 3s5 2.352 5 5.444z"/>
      <circle cx="12" cy="8" r="1" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </svg>
  </div>
</template>

<style scoped>

</style>