<template>
  <div>
    <div ref="mainCont" style="height: 100%; width: 100%" :id="'marker'+plotId">
      <div class="card-header" style="min-height: 40px">
        <div class="row">
          <div class="col-8">
            <p class="mb-0">
              markers layer
            </p>
          </div>
          <div class="col-2 text-center" v-if="editable">
          <span class="edit" @click="editWidget">
            <i><font-awesome-icon icon="fas fa-pencil" /></i>
          </span>
          </div>
          <div class="col-2 text-center" v-if="editable">
          <span class="remove" @click="removeWidget">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
          </span>
          </div>
        </div>
      </div>
      <div :id="'theMap_'+plotId" style=" min-height:20rem!important;width: 100%;"></div>
    </div>


    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleSubmit"
    >
      <div class="p-3">
        <ejs-grid
          ref="grid"
          :width="'auto'"
          :height="'100%'"
          :allowPaging="true"
          :allowTextWrap="false"
          :allowResizing="false"
          :enableRtl="lang == 'per'"
          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
          :selectionSettings="selectionOptions"
          :dataSource="layers"
          loadingIndicator='"shimmer"'
          @rowSelected="onRowSelected"
        >
          <e-columns>
            <e-column
              v-for="(item, index) in fields"
              :key="index"
              :field="item.key"
              :headerText="item.label"
              :template="item.hasTemplate ? item.key : null"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      >
    </b-modal>
  </div>
</template>

<script>
import L from "leaflet";

export default {
  name: "markerWidget",
  data() {
    return {
      map: {
        center: [0, 0],
        minZoom: 1,
        maxZoom: 25,
        maxNativeZoom: 2,
        tileId: 1,
        viewZoom: 1
      },
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],
      theItem: {},
      mymap: [],
      modalTitle: "select layer",
      fields: [
        {
          key: "title",
          label: "name",
          hasTemplate: false
        },
        {
          key: "type",
          label: "type",
          hasTemplate: false
        }
      ],
      layers: [],
      markerList: [],
      selectionOptions: {
        type: "Single",
        persistSelection: false,
        checkboxOnly: false
      }
    };
  },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number
  },
  methods: {
    renderMarkers() {
      const markers = [];
      this.markerList.forEach((item) => {
        markers.push(
          L.marker(item.pos, {
            icon: L.icon(item.icon),
            draggable: false
          }).addTo(this.mymap)
        );
      });
    },
    onRowSelected(args) {
      this.theItem = this.layers.find((item) => item._id == args.data._id);

      this.map = this.theItem.details.map;
      this.markerList = this.theItem.details.markers;
      this.createMap();
      this.renderMarkers();
    },
    editWidget() {
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    addItem: function(type) {
      this.$root.$emit("bv::show::modal", "modal-widget-pick-map");
    },
    removeWidget() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this widget")
        .then((value) => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", {
              task: "rmDashItem",
              item: this.plotId
            });
          }
        })
        .catch((err) => {
        });
    },
    createMap: function() {

      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      var mapOptions = {
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom,
        attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
        id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
        accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      };

      L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);

    },
    initModal() {
      this.$store.dispatch("data/getAllMaps").then((res) => {
        this.layers = res.message.filter(item => item.type == "marker");
      });
    },
    resetModal() {
    },
    handleSubmit($bv) {
      $bv.preventDefault();
      this.$store.dispatch("data/editDashboard", {
        _id: this.plotId, data: {
          datas: { type: "markerWidget", id: this.plotId, name: this.theItem.title },
          details: {
            map: this.map,
            markers: this.theItem
          }
        }
      }).then(
        data => {
          this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
          this.$nextTick(() => {
            this.$bvModal.hide(this.plotId);
          });
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          alert(this.message);
        }
      );
    }
  },
  mounted() {
    if (this.data && this.data && this.data.details) {
      this.map = this.data.details["map"] || {
        center: [0, 0],
        minZoom: 1,
        maxZoom: 25,
        maxNativeZoom: 2,
        tileId: 1,
        viewZoom: 1
      };
      this.markerList = this.data.details["markers"].details.markers;

    }
    setTimeout(() => {
      this.mymap = L.map("theMap_" + this.plotId);
      this.createMap();
      if (this.data && this.data && this.data.details)
        this.renderMarkers();

    }, 500);
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>
