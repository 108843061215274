<template>




  <div style="height:100%; width:100%;">
    <div class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-8 ">
          <p class="mb-0">
            {{ myData.attribute.title}}
          </p>
        </div>
        <div  class="col-2 text-center" v-if="editable">
          <span class="edit" @click="editWidget">
            <i><font-awesome-icon icon="fas fa-pencil" /></i>
          </span>
        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="remove" @click="removeWidget">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
          </span>
        </div>
      </div>
    </div>


    <div class="card-body p-1 d-flex justify-content-center align-items-center"  style="height: calc(100% - 40px); width:100%;">
      <div :ref="plotId" :id="plotId" class="cont">
        <div class="slider-wrapper" :style="sliderValueStyle">
          <div class="slider-tooltip" >
          </div>
          <div
            id="slider"
            ref="slider"
            class="slider-track"
            @mousedown="onMouseDown"
          >
            <div ref="handle" class="slider-thumb">
              <div class="range-output">
                <output class="output" name="output" for="range">
                  {{ formatNumberWithUnits(sliderValue) }}
                </output>
              </div>
            </div>
          </div>
          <div class="slider-ticks" >
            <div
              v-for="(tick, index) in ticks"
              class="tick-cont"
              :key="index"
              :style="{ left: `${tick.position}%` }" 
            >
              <div class="tick"></div>
              <div class="text">{{tick.text}}</div>
            </div>
          </div>
        </div>
        <!-- <div class="value-container">
          {{ formatNumberWithUnits(sliderValue) }}
        </div> -->
      </div>
    </div>

      <b-modal
        :id="plotId"
        size="lg"
        centered
        body-class="my-body-class"
        hide-header-close
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div class="yekan">
          <b-container  fluid="md" class="p-0">
            <div class="card-header">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item" @click="activeTab = 'tab1'">
                  <p  class="nav-link"
                      :class="{ active: activeTab === 'tab1' }"
                      id="tab1-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                  >
                      data
                      <!-- {{dict.tab1[lang]}} -->
                  </p>
                </li>
                <li class="nav-item" @click="activeTab = 'tab2'">
                  <a  class="nav-link"
                      :class="{ active: activeTab === 'tab2' }"
                      id="tab2-tab"
                      data-toggle="tab"
                      href="#tab2"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="true"
                  >
                    attribute
                    <!-- {{dict.tab2[lang]}} -->
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body h-100 p-3 "  style="overflow: hidden;">
              <transition name="slide-fade" mode="out-in">
                <div class="tab-content h-100" :key="activeTab">
                  <div v-if="activeTab === 'tab1'" class="h-100 ">
                    <div fluid class="container-fluid yekan p-0 h-100 ">
                      <b-table ref="table1" :items="myData.datas" :fields="groupFields" thead-class="hidden_header" responsive="sm" small> 
                        <template #cell(modify)="row" >
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger" @click="removeItem(row.index)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>
                        <template #cell(color)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="color" v-model="row.item.color" class="w-100"/>
                          </div>
                        </template>

                        <template #cell(title)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="text" v-model="row.item.title" class="w-100"/>
                          </div>
                        </template>
                      </b-table>

                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button  v-if="myData.datas.length===0"  @click="addItem" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i><font-awesome-icon icon="fas fa-plus" /></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div v-if="activeTab === 'tab2'" class="h-100 ">
                    <div fluid class="container-fluid yekan p-0 h-100 ">

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.title[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.title" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.font[lang]}}</div>
                        <div class="col-9">
                          <input type="color" v-model="myData.attribute.sliderColor" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.minValue[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.minValue" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.maxValue[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.maxValue" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.step[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.step" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.sliderWidth[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.sliderWidth" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.sliderHeight[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.sliderHeight" class="w-100"/>
                        </div>
                      </div>

                      <div class="d-flex align-items-center p-2">
                        <div class="col-3" :class="lang==='per'?'text-end':'text-start'"  for="username">{{dict.ticksMajorSpace[lang]}}</div>
                        <div class="col-9">
                          <input type="text" v-model="myData.attribute.ticksMajorSpace" class="w-100"/>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </transition>
            </div>

          </b-container>
        </div>
      </b-modal>
      <dataPickerModal :plotId = "plotId"/>
    </div>
</template>

<script>
/* eslint-disable */
  import dataPickerModal  from './widgetPickDataModal.vue';
  import Draggable from 'gsap/Draggable';
  import { gsap } from 'gsap';
  

  export default {
    name: "CustomPath",
    components: {
      dataPickerModal,
    },
    props: {
      plotId: String,
      data: Object,
      editable: Boolean,
      resized: Number
    },
    data() {
      return {
        activeTab: 'tab1', // Set the initial active tab
        
        dragSlider: null,
        
        ticks: [],
        
        sliderValue: 0,
        sliderPercent:0,
        initialValue: 0,
        
        modalTitle : 'Edit Slider', 
        myData : {
          datas:[],
          attribute:{
            title:'pie chart',
            sliderWidth: 300,
            sliderHeight:35,
            ticksMajorSpace : 10,  //percent
            minValue:-10000,
            maxValue:0,
            step:500,
            sliderColor:'#ffff00',

            

          }
        },
        widgetData: {dids:[]},

        myDataTmp : {datas:[]},
                
        groupFields: [
          { key: 'name', label: ''},
          { key: 'modify', label: ''}
        ],

        submitted: false,
        successful: false,
        message: '',

        dict:{
          dir:{eng:'ltr',per:'rtl'},
          title: {eng:'title',per:'عنوان'},
          tab1: {eng:'user profile',per:'مشخصات کاربر'},
          tab2: {eng:'security settings',per:'تنظیمات امنیتی'},
          mode: {eng:'chart mode',per:'نوع ترسیم'},
          shape: {eng:'chart shape',per:'نحوه ترسیم'},
          dash: {eng:'chart dash',per:'نوع خط'},
          grid: {eng:'grid color',per:'رنگ گرید'},
          line: {eng:'line color',per:'رنگ خطوط'},
          font: {eng:'font color',per:'رنگ نوشتار'},
          minValue: {eng:'minimum value',per:'مقدار کمینه'},
          maxValue: {eng:'maximum value',per:'مقدار بیشینه'},
          sliderWidth: {eng:'slider Width',per:'عرض اسلایدر'},
          sliderHeight: {eng:'slider Height',per:'ارتفاع اسلایدر'},
          ticksMajorSpace: {eng:'ticks space (%)',per:'فاصله نشان ها (%)'},
          step: {eng:'step value',per:'گام تغییرات'},
          textinfo: {eng:'text info',per:'رنگ نوشتار'},
          textposition: {eng:'text position',per:'رنگ نوشتار'},
          insidetextorientation: {eng:'inside text orientation',per:'رنگ نوشتار'},
          hole: {eng:'hole size',per:'رنگ نوشتار'},
          showlegend: {eng:'legend',per:'رنگ نوشتار'},
        },
      };

    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      child2parent() {
        return this.$store.state.chpge.child2parent;
      },
      lang() {
        return this.$store.state.chpge.lang;
      },
      sliderValueStyle() {
        return {
          '--slider-value': this.sliderPercent,
          '--slider-height':this.myData.attribute.sliderHeight+ 'px',
          '--slider-width':this.myData.attribute.sliderWidth+ 'px',
          '--slider-color': this.myData.attribute.sliderColor
        };
      },

    },
    watch: {
      updatedData(newValue) {
        if (this.myData.datas.length > 0){
          for (var Data of newValue){
            if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)){
              this.sliderValue = Data.value;
              this.sliderPercent = gsap.utils.mapRange(this.myData.attribute.minValue, this.myData.attribute.maxValue, 0, 100, this.sliderValue); // 150
                            
              const handle = this.$refs.handle;
              const slider = this.$refs.slider;
              const maxPosition = slider.offsetWidth - handle.offsetWidth;
              const newPos = gsap.utils.mapRange(this.myData.attribute.minValue, this.myData.attribute.maxValue, 0, maxPosition, this.sliderValue); // 150
              gsap.set(handle,{x: newPos})
              console.log("#### sliderValue:",this.plotId, this.sliderValue, maxPosition, newPos)
            }
          }
        }
      },
      child2parent(newValue) {
        if ((newValue.task==='pickData')&&(newValue.to===this.plotId)){
          if (newValue.item.type === 'data'){
            console.log('=========',newValue.item,this.myData)

            this.myData.datas.push({...newValue.item})
          }
        }
      },
      resized(){
        this.resizeChart()
      },
      data(newValue,oldValue){
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)){
          console.log('ffffffffffffff change in data',newValue)
          if (newValue){
            console.log('#1')
            this.myData = JSON.parse(JSON.stringify(newValue))
            // this.$refs.table1.refresh();
            // this.selChart()
          }
          console.log('myData', this.myData)
        }
      },
      myData(newvalue){
      },

    },
    methods: {
      initSlider() {
        gsap.registerPlugin(Draggable);
        const handle = this.$refs.handle;
        const slider = this.$refs.slider;
        this.dragSlider = Draggable.create(handle, {
          type: 'x',
          bounds: slider,
          throwProps: true,
          inertia: true,
          onDrag: this.updateSlider,
          onDragEnd: this.setData,
          snap: {
            x: gsap.utils.snap(10)
          }
        })[0];

        const maxPosition = slider.offsetWidth - handle.offsetWidth;

        if (this.initialValue < this.myData.attribute.minValue)
          this.initialValue = this.myData.attribute.minValue
        if (this.initialValue > this.myData.attribute.maxValue)
          this.initialValue = this.myData.attribute.maxValue
        
        const initialPos = gsap.utils.mapRange(this.myData.attribute.minValue, this.myData.attribute.maxValue, 0, maxPosition, this.initialValue); // 150
        const initialPercent = gsap.utils.mapRange(this.myData.attribute.minValue, this.myData.attribute.maxValue, 0, 100, this.initialValue); // 150

        gsap.set(handle,{x: initialPos})
        this.sliderValue = this.initialValue;
        this.sliderPercent = initialPercent;


        var fpos = 80* this.myData.attribute.sliderHeight/this.myData.attribute.sliderWidth;
        var lpos = 100 - 80* this.myData.attribute.sliderHeight/this.myData.attribute.sliderWidth;
        var ticksMajorCnt = 100 / this.myData.attribute.ticksMajorSpace ;
        for (let index = 0; index <= ticksMajorCnt; index++) {
          var txt = this.formatNumberWithUnits(this.myData.attribute.minValue + index*this.myData.attribute.ticksMajorSpace*(this.myData.attribute.maxValue-this.myData.attribute.minValue)/100)
          this.ticks.push({ className: 'tick', position: (fpos + index*(lpos - fpos)/ticksMajorCnt), text:txt   })
        }

        this.getData()
      },
      updateSlider() {
        const slider = this.$refs.slider;
        const handle = this.$refs.handle;
        const maxPosition = slider.offsetWidth - handle.offsetWidth;
        var handlePosition = gsap.utils.snap((maxPosition * this.myData.attribute.step / (this.myData.attribute.maxValue - this.myData.attribute.minValue)), this.dragSlider.x)
        const percentage = (handlePosition / maxPosition) * 100;
        const out = gsap.utils.mapRange(0, 100, this.myData.attribute.minValue, this.myData.attribute.maxValue, percentage); // 150
        this.sliderPercent = Math.round(percentage);
        


        if (this.sliderValue !== Math.round(out)){
          this.sliderValue = Math.round(out);
          gsap.set(handle,{x: handlePosition})
          // console.log('change slider value',this.plotId, this.sliderValue)
        }

      },
      onMouseDown() {
        this.dragSlider.update();
      },
      removeWidget() {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this widget')
        .then(value => {
          if (value===true){
            this.$store.dispatch('chpge/child2parent', {task: 'rmDashItem', item:this.plotId})
          }
        })
        .catch(err => {
        })
      },
      editWidget(){
        // console.log('editwidget',this.plotId)
        this.$root.$emit('bv::show::modal', this.plotId);
      },
      formatNumberWithUnits(number) {
        if (Math.abs(number) >= 1.0e+9) {
          return (number / 1.0e+9).toFixed(2) + 'B'; // Display in billions
        } else if (Math.abs(number) >= 1.0e+6) {
          return (number / 1.0e+6).toFixed(2) + 'M'; // Display in millions
        } else if (Math.abs(number) >= 1.0e+3) {
          return (number / 1.0e+3).toFixed(2) + 'k'; // Display in thousands
        } else {
          return number.toString(); // Display as is if it's smaller than 1,000
        }
      },
      getData(){
        var dids=[];
        for (var d of this.myData.datas){
          dids.push(d._id)
        }
        if (dids.length > 0){
          this.$store.dispatch('panel/getData', {id:dids}).then(
            datas => {
              console.log('datas slider vertical',datas)
            },
            error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
          )
        }
      },
      setData() {
        let data = [{id:this.myData.datas[0]._id, value:this.sliderValue}]
        this.$store.dispatch('panel/setData', {data:data})
          .then(
              data => {
              },
              error => {
              var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
          );
      },
      initModal: function(){
        console.log('init modal', this.myData)
        // this.$refs.table1.refresh();
        this.myDataTmp = JSON.parse(JSON.stringify(this.myData))
      },
      resetModal: function(){
        console.log('reset modal')
        console.log('#2')
        this.myData = JSON.parse(JSON.stringify(this.myDataTmp))
      
        this.submitted = null;

        this.successful = null;
        this.message= null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
        console.log('submit',this.type)
        this.message = '';
        this.submitted = true;
        this.$validator.validate().then(isValid => {
          if (isValid) {

            this.$store.dispatch('data/editDashboard', {_id:this.plotId, data:this.myData}).then(
              data => {
                this.$store.dispatch('chpge/child2parent', {task: 'updateDash'})
                this.myDataTmp = JSON.parse(JSON.stringify(this.myData))
                this.$nextTick(() => {
                  this.$bvModal.hide(this.plotId)
                })
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                alert(this.message)
              }
            )
          }
        });
      },
      addItem: function(type){
        this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:this.plotId, filter:{dataType:['Number','Object'], usedBy:[],parents:[],archive:null,
            page: 1,
            perPage: 5000,
            withChild: false
          }})
        this.$root.$emit('bv::show::modal', 'modal-widget-pick-data-'+this.plotId);
      },
      removeItem: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this group')
        .then(value => {
          if (value===true){
            console.log('#3')
              this.myData.datas.splice(index,1)
          }
        })
        .catch(err => {
        })
      },
      resizeChart(){
        var update = {
          autosize:true
        };
        // Plotly.relayout(this.plotId, update);
      }
    },

    mounted() {
      console.log('ffffffffffffff mounted',this.data)
      if (this.data && this.data.datas && this.data.attribute){
        console.log('alo')
        this.myData = this.data
      }
      console.log('myData slider', this.myData)
      this.initSlider();
    }
};
</script>


<style scoped>
  .remove {
    cursor: pointer;
    color: rgb(175, 235, 11);
    font-size: 18px;
  }
  .edit {
    cursor: pointer;
    color: rgb(9, 180, 210);
    font-size: 18px;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100%);
  }
</style>

<style>
.my-body-class {
  padding: 0px !important;
  /* Add any other custom styles you want */
}
</style>


<style lang="scss" scoped>
  $height-range-output: 30px;
  $min-width-range-output: 40px;
  $max-width-range-output: 60px;
  $size-range-output-arrow: 10px;
  $offset-y-range-output: 4px;
  $color-range-output: #383c42;


  .cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
  }

  .slider-wrapper {
    --slider-width:.375em;
    --slider-height: .375em;
    --slider-value: 0;
    --slider-color: #1664e4;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // padding: 1em;
  }

  .slider-track {
    position: relative;
    width:var(--slider-width);
    display: flex;
    border-radius: 3.125em;
    height: var(--slider-height);
    background: linear-gradient(
      to top, /* Change the direction to make it vertical */
      rgba(92, 92, 92, 0.8) 0%, /* Lighter color at the left */
      rgba(92, 92, 92, 0.6) 50%, /* Slightly darker color in the middle */
      rgba(209, 203, 200, 0.6) 100%, /* Slightly lighter color in the middle */
    );
  }

  .slider-track:after{
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    height: 10px;
    width: calc(var(--slider-width) * 0.92);
    transform: translate(-50%, -50%);
    -wekbit-transform: translate(-50%, -50%);
    border-radius: 5px;
    background-image: 
      linear-gradient(
        to right,
        var(--slider-color) 0% calc(var(--slider-value) * 1%),
        rgba(50,23,19,1) calc(var(--slider-value) * 1%) 100%,
      ),
    ;
  }
  .slider-ticks{
    height:70px;
    width: 100%;
    padding-top: 5px;
  }
  .slider-tooltip{
    width:100%;
    height: 70px;
    padding-left: 5px;
  }
  
  .slider-thumb {
    z-index: 1;
    position: absolute;
    top: 50%; 
    transform: translate(0,-50%); 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: calc(var(--slider-height) * 1.6);
    height: calc(var(--slider-height) * 1.6);
    background-image: linear-gradient(to bottom, #f0f0f2, #c5c5cf);
    box-shadow: 0 .125em .25em rgb(0 0 0 / .3);
    
    &::before {
      content: '';
      position: absolute;
      border-radius: inherit;
      width: 68%;
      height: 68%;
      background-image: linear-gradient(to bottom, #c5c5cf, #f0f0f2);
    }
  }


  .range-output {
		position: absolute;
		user-select: none;
		.output {
			display: block;
			position: absolute;
			height: $height-range-output;
			line-height: $height-range-output;
			min-width: $min-width-range-output;
			padding: 0 5px;
      top: calc(calc(var(--slider-height) * 0.8 * -1) - $offset-y-range-output - $size-range-output-arrow );
			transform: translate(-50%, -100%);
			background: $color-range-output;
			color: #fff;
			border-radius: 5px;
			white-space: nowrap;
			font-weight: bold;
			font-size: 0.8em;
			text-align: center;

			&:before {
				content: "";
				position: absolute;
        bottom: - $size-range-output-arrow + 2px; //Subtract 2 to get slight overlap that renders more nicely
				left: 50%;
				border: $size-range-output-arrow solid $color-range-output;
				border-bottom: none;
				border-left-color: transparent;
				border-right-color: transparent;
				transform: translateX(-50%);
			}
    }
  }
  .value-container {
    // display: block;
    // position: absolute;
    height: $height-range-output;
    line-height: $height-range-output;
    min-width: $min-width-range-output;
    max-width: $max-width-range-output;
    padding: 0 5px;

    background: $color-range-output;
    color: #fff;
    border-radius: 5px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 0.8em;
    text-align: center;
  }
  .tick-cont {
    position: absolute;
    display: flex;
      .tick {
        width: 2px;
        height: 10px;
        background-color: #bcbcbc;
      }
      .text{
        margin-top: 5px;

        transform: translate(-50%,100%) rotate(90deg);


        white-space: nowrap;
        font-weight: bold;
        font-size: 0.8em;
        text-align: center;
        color: #bcbcbc;
      }
    }
   
</style>