import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuex, { mapGetters } from "vuex";
import VeeValidate from "vee-validate";
import "@/translation/translate";
//import BootstrapVueIcons from 'bootstrap-vue';
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueSSE from "vue-sse";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "leaflet/dist/leaflet.css";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
// import "@/assets/styles.scss";
import "@/assets/styles/theme.css";
import { Carousel3d } from "vue-carousel-3d";
import Notifications from "vue-notification";

import {
  RecurrenceEditorPlugin, SchedulePlugin
} from "@syncfusion/ej2-vue-schedule";
// import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAreaChart,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowDown,
  faArrowsRotate,
  faArrowUp,
  faArrowUpFromWaterPump,
  faBarChart,
  faBars,
  faBell,
  faCalendarDays,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faCloud,
  faCog,
  faCopy,
  faCubesStacked,
  faDatabase,
  faDownload,
  faDroplet,
  faEdit,
  faEllipsisVertical,
  faEquals,
  faExclamationCircle,
  faExclamationTriangle,
  faFan,
  faFileCirclePlus,
  faGear,
  faGlobe,
  faHandPaper,
  faHome,
  faHouse,
  faLineChart,
  faLock,
  faLockOpen,
  faMagic,
  faMinus,
  faMusic,
  faNotEqual,
  faPencil,
  faPencilSquare,
  faPieChart,
  faPlus,
  faPlusSquare,
  faPowerOff,
  faSave,
  faSearch,
  faSignOut,
  faSnowflake,
  faSun,
  faTag,
  faTemperatureArrowUp,
  faTemperatureLow,
  faTemperatureThreeQuarters,
  faThermometer,
  faTrashCan,
  faTriangleExclamation,
  faUpload,
  faUserCircle,
  faUserCog,
  faUserLock,
  faUserTie,
  faWater,
  faWrench,
  faXmark,
  faXmarkCircle,
  faXmarkSquare
} from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk, faLightbulb } from "@fortawesome/free-regular-svg-icons";

// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
// import { faAirConditioner } from '@fortawesome/free-solid-svg-icons'
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueNotifications from "@mathieustan/vue-notification";


// import * as SyncfusionCharts from "@syncfusion/ej2-vue-charts";
import * as SyncfusionInputs from "@syncfusion/ej2-vue-inputs";
import * as SyncfusionCalendars from "@syncfusion/ej2-vue-calendars";
import * as SyncfusionPopups from "@syncfusion/ej2-vue-popups";
import * as SyncfusionDropdowns from "@syncfusion/ej2-vue-dropdowns";
import * as SyncfusionLayOuts from "@syncfusion/ej2-vue-layouts";
import * as SyncfusionPivotview from "@syncfusion/ej2-vue-pivotview";
import * as SyncfusionGrid from "@syncfusion/ej2-vue-grids";
import * as SyncfusionDialog from "@syncfusion/ej2-vue-popups";
import * as SyncfusionButtons from "@syncfusion/ej2-vue-buttons";

function registerSyncfusionComponents(components) {
  for (const key in components) {
    const component = components[key];
    if ((component.name && component.name.startsWith("ejs-")) || (component.name && component.name.startsWith("e-"))) {
      Vue.use(component);
    }
  }
}

registerSyncfusionComponents(SyncfusionInputs);
registerSyncfusionComponents(SyncfusionPopups);
registerSyncfusionComponents(SyncfusionCalendars);
registerSyncfusionComponents(SyncfusionDropdowns);
registerSyncfusionComponents(SyncfusionLayOuts);
registerSyncfusionComponents(SyncfusionPivotview);
registerSyncfusionComponents(SyncfusionGrid);
registerSyncfusionComponents(SyncfusionDialog);
registerSyncfusionComponents(SyncfusionButtons);

require("leaflet-routing-machine");

window.joint = require("@joint/core/dist/joint");
window.$ = require("jquery");
Vue.use(mapboxgl);
Vue.use(VuePersianDatetimePicker, {
  name: "custom-date-picker", props: {
    format: "YYYY-MM-DD HH:mm:ss",
    displayFormat: "dddd jDD jMMMM jYYYY HH:mm",

    editable: false,
    inputClass: "form-control my-custom-class-name",
    placeholder: "Please select a date",
    altFormat: "YYYY-MM-DD HH:mm",
    color: "#1381e2",
    autoSubmit: true,
    type: "datetime",
    compactTime: true
  }
});
Vue.use(VueNotifications, {
  theme: {
    colors: {
      success: "#54d861",
      darkenSuccess: "#2d8e36",
      info: "#5d6a89",
      darkenInfo: "#535f7b",
      warning: "#f8a623",
      darkenWarning: "#f69a07",
      error: "#ff4577",
      darkenError: "#ff245f",
      offline: "#ff4577",
      darkenOffline: "#ff245f"
    },
    boxShadow: "10px 5px 5px red"
  },
  breakpoints: {
    0: {
      bottom: true
    },
    480: {
      top: true,
      right: true
    }
  }
});

// if ('serviceWorker' in navigator) {
//   console.log("Registering service worker...");
//   const register = navigator.serviceWorker.register("/worker.js", {
//     scope: "/"
//   });
//   console.log("Service Worker Registered...");

// }

store.dispatch("data/getSignupSetting");

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(faBars, faPowerOff, faPlus, faLightbulb, faMinus, faFileCirclePlus, faCog, faEquals, faXmark, faPencil, faTemperatureThreeQuarters, faWater, faCircleCheck, faHouse, faNotEqual, faDatabase, faTemperatureLow, faThermometer, faTrashCan, faSearch, faArrowUpFromWaterPump, faXmark, faChevronDown, faChevronUp, faLock, faUserLock, faUserCog, faUserTie, faTriangleExclamation, faLockOpen, faAreaChart, faBarChart, faLineChart, faPieChart, faGear, faHome, faCircleExclamation, faMagic, faHandPaper, faWrench, faCalendarDays, faClock, faCheckSquare, faXmarkSquare, faFan, faArrowsRotate, faSnowflake, faSun, faDroplet, faTemperatureArrowUp, faBell, faPlusSquare, faUpload, faUserCircle, faSignOut, faPencilSquare, faMusic, faFloppyDisk, faSave, faExclamationTriangle, faExclamationCircle, faGlobe, faEdit, faEllipsisVertical, faCheckCircle, faDownload, faArrowDown, faArrowUp, faArrowAltCircleUp, faArrowAltCircleDown, faXmarkCircle, faCloud, faCopy, faTag, faCubesStacked);


Vue.use(VeeValidate, {
  fieldsBagName: "vvFields"
});
Vue.use(BootstrapVue);
Vue.use(VueSSE);
Vue.use(BootstrapVueIcons);
Vue.use(SchedulePlugin);
Vue.use(RecurrenceEditorPlugin);
// Vue.use(DateTimePickerPlugin);
Vue.config.productionTip = false;
Vue.use(vuex);
Vue.use(Carousel3d);
Vue.use(Notifications);

// preload the ArcGIS API
// const options = {
//   url: 'https://js.arcgis.com/3.23/',
// };
// loadScript(options)
// store.dispatch("data/getPermission");
// store.dispatch("data/getWBListdata");


Vue.directive("permission", {
  bind: function(el, binding, vnode) {
    const checkPermission = () => {
      const userPermission = vnode.context["data/getUserPermission"];
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];
      if (userPermission.systemRole !== "administrator") {
        if (el.classList.contains("isMenu")) {
          let theItem = 0;
          permissions.forEach((item) => {
            if (userPermission.permissions.includes(item)) {
              theItem++;
            }
          });
          if (theItem === 0) {
            el.classList.add("d-none");
          }
        } else {
          if (!userPermission.permissions.includes(binding.value)) {
            el.classList.add("d-none");
          }
        }
      } else {
        if (userPermission.systemRole == "administrator" && el.classList.contains("isMenu")) {
          if (userPermission.permissions.length <= 0) {
            if (permissions[0] != "administrator") {
              el.classList.add("d-none");
            } else el.classList.remove("d-none", "d-none");
          }
        }
      }
    };

    const clickHandler = (evt) => {
      const userPermission = vnode.context["data/getUserPermission"];
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];


      if (!permissions.includes("administrator") && !permissions.some(p => userPermission.permissions.includes(p))) {
        evt.stopImmediatePropagation();
        evt.stopPropagation();
        // Vue.notify({
        //   type: "alarm",
        //   title: "خطای دسترسی",
        //   text: " !شما دسترسی لازم به این صفحه را ندارید",
        //   duration: 5000,
        //   speed: 500
        // });
      }
    };

    el.addEventListener("click", clickHandler);
    el._clickHandler = clickHandler; // Store the handler for later removal
    checkPermission();
  }, update: function(el, binding, vnode) {
    const checkPermission = () => {
      const userPermission = vnode.context["data/getUserPermission"];
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];


      if (userPermission.systemRole !== "administrator") {
        if (el.classList.contains("isMenu")) {
          let theItem = 0;
          permissions.forEach((item) => {
            if (userPermission.permissions.includes(item)) {
              theItem++;
            }
          });
          if (theItem === 0) {
            el.classList.add("d-none");
          }
        } else {
          if (!userPermission.permissions.includes(binding.value)) {
            el.classList.add("d-none");
          }
        }
      } else {
        if (userPermission.systemRole == "administrator" && el.classList.contains("isMenu")) {
          if (userPermission.permissions.length <= 0) {
            if (permissions[0] != "administrator") {
              el.classList.add("d-none");
            } else el.classList.remove("d-none", "d-none");
          }
        }
      }
    };

    el.classList.remove("d-none", "d-none");
    checkPermission();
  }, componentUpdated: function(el, binding, vnode) {
    const checkPermission = () => {
      const userPermission = vnode.context["data/getUserPermission"];
      const permissions = Array.isArray(binding.value) ? binding.value : [binding.value];


      if (userPermission.systemRole !== "administrator") {
        if (el.classList.contains("isMenu")) {
          let theItem = 0;
          permissions.forEach((item) => {
            if (userPermission.permissions.includes(item)) {
              theItem++;
            }
          });
          if (theItem === 0) {
            el.classList.add("d-none");
          }
        } else {
          if (!userPermission.permissions.includes(binding.value)) {
            el.classList.add("d-none");
          }
        }
      } else {
        if (userPermission.systemRole == "administrator" && el.classList.contains("isMenu")) {
          if (userPermission.permissions.length <= 0) {
            if (permissions[0] != "administrator") {
              el.classList.add("d-none");
            } else el.classList.remove("d-none", "d-none");
          }
        }
      }
    };

    el.classList.remove("d-none", "d-none");
    checkPermission();
  }, unbind: function(el) {
    // Clean up the event listener when the element is unbound
    el.removeEventListener("click", el._clickHandler);
    delete el._clickHandler;
  }
});


Vue.mixin({
  computed: {
    ...mapGetters(["data/getUserPermission"])
  }
});

new Vue({
  store, render: (h) => h(App)
}).$mount("#app");
