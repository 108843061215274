<template>
    <div >
      <div v-if="type==='type1'" >
        <label class="form-switch1">
          <input type="checkbox" v-model="status" true-value="on" false-value="off" @change="toggleHandler" :disabled="disable">
          <i><font-awesome-icon icon="fas fa-power-off" style="height:40%"/></i>
        </label>
      </div>
      <div v-else-if="type==='type2'">
        <label class="form-switch">
          <input type="checkbox" v-model="status" true-value="on" false-value="off" @change="toggleHandler" :disabled="disable" >
          <i></i>
        </label>
      </div>
    </div>
</template>

<script>
/* eslint-disable */

  export default {
    props: {
      panelData: Object,
      disable: Boolean,
      type: String
    },
    data() {
      return {
        status:null
      }
    },
    methods: {
      toggleHandler: function () {
        var data = {data: [{id:this.panelData.did, value:this.status}]}
        this.$store.dispatch('panel/setData', data)
          .then(
              data => {
              },
              error => {
              var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              // alert(message)
              }
          );
      }
    },
    mounted(){
      this.$store.dispatch('panel/getData', {id:[this.panelData.did]})
      .then(
          data => {
          },
          error => {
          var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
              // alert(message)
          }
      );
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
    },
    watch: {
      updatedData(newValue) {
        for (var Data of newValue){
          if (JSON.stringify(Data.id) === JSON.stringify(this.panelData.did)){
            this.status=Data.value
          }
        }
      },
    }
  };
</script>

<style scoped 
  src="@/assets/styles/ledStyle.css">
  
</style>