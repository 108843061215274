<template>
    <div class="yekan">
        <b-modal
        id="modal-setDashComp"
        size="lg"
        centered
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div class="yekan">
            <b-container v-if="component.type === 'chart'"  fluid="md">
                  <b-table ref="table1" :items="component.data.charts" :fields="groupFields" thead-class="hidden_header" responsive="sm" small> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editGroup(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removeGroup(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="addGroup" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>

            </b-container>
            <b-container v-if="component.type === 'momentaryValue'"  fluid="md">
                  <b-table ref="table1" :items="component.data.dGauges" :fields="groupFields" thead-class="hidden_header" responsive="sm" small> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editdGauge(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removedGauge(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="adddGauge" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>

            </b-container>
        </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        data() {
            return {
                modalTitle:'',
                
                groups:[],
                
                groupFields: [
                { key: 'name', label: ''},
                { key: 'modify', label: ''}],

                component:{
                    _id:'',
                    name:'',
                    type:'',
                    data:{},
                    attribute:{}
                },

                dataFields: [
                    { key: 'icon', label: '',thStyle: { width: "5%" },},
                    { key: 'dtype', label: '',thStyle: { width: "30%" }},
                    { key: 'dname', label:'',thStyle: { width: "30%" }},
                    { key: 'did', label: '',thStyle: { width: "40%" }},
                    { key: 'modify', label: '',thStyle: { width: "5%" }}],
                    
                typeList: [],

                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
            child2parent() {
                return this.$store.state.chpge.child2parent;
            }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='chart_modal'){
                    // let ai = newValue.data.findIndex(x => x._id === newValue._id)
                    // if (ai != -1)
                    //     this.db = newValue.data[ai]

                    this.db = newValue.data
                    this.type = newValue.type
                    console.log('-----------',JSON.stringify(this.db))
                }
            },
            child2parent(newValue, oldValue) {
                if (newValue.task==='component_modal'){
                    if (newValue.type==='Add'){
                        if (this.component.data.charts)
                            this.component.data.charts.push(JSON.parse(JSON.stringify(newValue.data)))
                        else
                            this.component.data = {charts:[JSON.parse(JSON.stringify(newValue.data))]}
                    }
                    if (newValue.type==='Edit'){
                        this.component.data.charts[newValue.index] = JSON.parse(JSON.stringify(newValue.data))
                        this.$refs.table1.refresh();
                    }
                }
            }
        },
        methods: {
            initModal: function(){
                console.log('-----------')
                this.component.type         = this.db.type;

                this.modalTitle = this.type + ' ' + 'Chart' //this.component.type

                if (this.type==='Edit')
                {
                    this.component._id          = this.db._id;
                    this.component.name       = this.db.name;
                    this.component.data        = this.db.data? this.db.data:{charts:[], selectedChart:0};
                    this.component.attribute    = this.db.attribute? this.db.attribute:{};
                }
            },
            resetModal: function(){
                console.log('-----------')
                // this.data = []
                this.component.attribute = {};
                this.component.data = {};
                this.component._id = '';
                this.component.name = '';
                
                this.submitted = null;

                this.successful = null;
                this.message= null;
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                console.log('submit',this.type)
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        console.log('aaa',JSON.stringify(this.component))


                       
                        this.$store.dispatch('chpge/child2parent', {task: 'setDashboard', type:'Edit', data: this.component})
                        
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal-setDashComp')
                        })

                        // this.$store.dispatch('data/createDashboard', this.component).then(
                        //     data => {
                                
                        //         this.message = data.message;
                        //         this.successful = true;
                                
                        //         this.$nextTick(() => {
                        //             this.$bvModal.hide('modal-setDashComp')
                        //         })
                        //     },
                        //     error => {
                        //     this.message =
                        //         (error.response && error.response.data && error.response.data.message) ||
                        //         error.message ||
                        //         error.toString();
                        //     this.successful = false;
                        //     alert(this.message)
                            
                        //     }
                        // );
                    }
                });
            },
            addGroup: function(type){
                this.$store.dispatch('chpge/parent2child', {task: 'data_modal', type:'Add', data: []})
                this.$root.$emit('bv::show::modal', 'modal-setDashGroup');
            },
            editGroup: function(index){
                this.$store.dispatch('chpge/parent2child', {task: 'data_modal', type:'Edit', data: this.component.data.charts[index], index})
                this.$root.$emit('bv::show::modal', 'modal-setDashGroup');
            },
            removeGroup: function(index){

                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this group')
                .then(value => {
                    if (value===true){
                        this.component.data.charts.splice(index,1)
                    }
                })
                .catch(err => {
                })
            },
            adddGauge: function(type){
                this.$store.dispatch('chpge/parent2child', {task: 'gauge_modal', type:'Add', data: []})
                this.$root.$emit('bv::show::modal', 'modal-setDashDGauge');
            },
            editdGauge: function(index){
                this.$store.dispatch('chpge/parent2child', {task: 'gauge_modal', type:'Edit', data: this.component.data.dGauge[index], index})
                this.$root.$emit('bv::show::modal', 'modal-setDashDGauge');
            },
            removedGauge: function(index){

                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this group')
                .then(value => {
                    if (value===true){
                        this.component.data.dGauge.splice(index,1)
                    }
                })
                .catch(err => {
                })
            },
        }
    };
</script>

<style scoped>
.td-class{
    width: 20%;
    text-align: left;
    font-weight: bold;
    font-size: large;
}
</style>