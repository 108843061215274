<template>

    <div class="mycont yekan">
        <div class="myrow">
            <div class="mymapcont" style="width: 15%">
                <div class="myrow" style="height: 10%; background-color: rgb(254, 237, 237)">
                    <div class="myheader"> <strong>List of Components</strong></div>
                    <!-- <div class="uploadfield">
                        <label>Map File: 
                            <input type="file" @change="uploadFile" ref="file">
                        </label>
                        <b-button  @click="submitFile" size="sm" class="mr-2" variant="outline-primary">upload
                        </b-button>
                    </div>               -->
                </div>
                <div class="myrow" style="height: 100%; background-color: rgb(254, 237, 237)">
                    <b-card no-body style="width: 100%">
                        <!-- <b-card-body> -->
                            <b-form-select v-model="selectedMap" :options="mapList" :select-size="4" style="width: 100%; height: 100%; border: 1px solid rgb(240, 240, 240); border-radius: 0.25rem;"></b-form-select>
                        <!-- </b-card-body> -->
                    </b-card>
                </div>
            </div>

            <div class="mymapcont" style="width: 84%">
                <div class="myrow" style="height: 10%; background-color: rgb(254, 237, 237); align-items: baseline;">
                    <div class="mystatus"> <strong>{{ mapTitle }}</strong></div>
                    <!-- <div class="mystatus">
                        <b-button  @click="mapSetting" size="sm"  variant="outline-primary" style="width:30%">
                            settings
                        </b-button>
                    </div> -->
                </div>
                <div class="myrow" style="height: 90%; background-color: rgb(254, 237, 237)">
                    <div class="mymapcont" style="width: 50%">
                        <myComponent_lighting  type = "type1" :panelData = "panelData"  />
                        <!-- <myComponent_panel_1_modal /> -->
 
                        
                    </div>
                    <div class="mymapcont" style="width: 50%">
                        <!-- <myComponent_lighting  type = "type1" :panelData = "panelData"  /> -->
                    </div>
                    

                    <!-- <div id="mapContainer" class="mymap"></div> -->
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import myComponent_lighting from "./myComponent_lighting.vue";
// import myComponent_panel_1_modal from "./myComponent_panel_1_modal.vue";

export default {
    name: 'components',
    components: {
        myComponent_lighting,
        // myComponent_panel_1_modal
    },
    data() {
        return {
            panelData:{ zone:'north corridor',
                        floor:'first floor',
                        title:'feeder 1',
                        status:'on',
                        id:'a1',
                        visible:true,
                        control: 'manual',
                        groups: [   {name:'group1',feeders:[0,1]},
                                    {name:'group2',feeders:[2]}],
                        feeders: [0,1,2,3,4]
                        },
            index:5,
            disable:false,

            // levels : null,
            // mymap :[],
            // popup : L.popup(),
            
            // map : { center : [0,0],
            //         minZoom : '',
            //         maxZoom : '',
            //         maxNativeZoom : '',
            //         tileId : '',
            //         viewZoom : ''
            //       },
            // tiles : ['outdoors-v11', 
            //          'streets-v11', 
            //          'dark-v10', 
            //          'light-v10', 
            //          'satellite-v9', 
            //          'satellite-streets-v11', 
            //          'navigation-day-v1', 
            //          'navigation-night-v1'],

            // file: null,
            buildingStruct:[],
            // successful: false,
            message: '',
            selectedMap: null,
            mapTitle:'',
            mapList :  [{ text: 'Select One', value: null }],
        };
    },

    mounted() {
        // this.setupLeafletMap();
        this.getBuildConf();
    },
    computed: {
        // updateMap() {
        //     return this.$store.state.panel.updateMap;
        // }
    },
    watch: {
        // updateMap(newValue, oldValue) {
        //     this.getfloorMap(this.selectedMap)
        // },
        // selectedMap(newValue, oldValue) {
        //     this.getfloorMap(newValue);
        // }
    },

    methods: {
        getBuildConf() {
            this.$store.dispatch('data/getBuildConf').then(
                data => {
                    this.buildingStruct = data
                    // console.log(this.buildingStruct[0]._id,this.buildingStruct[0].floorTitle,this.buildingStruct[0].floorId)

                    this.mapList =  [{ text: 'Select One', value: null }];
                    for (var map of this.buildingStruct){
                        
                        this.mapList.push({text: map.floorTitle? map.floorTitle:map._id , value:map._id})//floorId
                        
                    }
                },
                error => {
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                        // this.successful = false;
                }
            );
        },
        
   

        


    }
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
.small_header{
    font-size: x-small;
}
.bold_body{
    font-weight: bold;
    font-size: large;
    text-align: left;
    text-indent: 10px;
}
.td-class-1{
    width: 100px;
    text-align: left;
    font-weight: bold;
    font-size: large;
}
.uploadfield{
    height: 100%; 
    width: 100%;
    border-radius: 0.25rem;
    padding: 10px 10px 10px 10px;
    /* background-color: #a78713;     */
    display: flex;    
    flex-direction: row;     
    justify-content: center;
    /* color : rgb(255, 255, 255) */
}

  .mycont {
    height: 100%; 
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    background-color: #edf0f3;
    opacity: 1;
    padding: 10px 15px 10px 15px;
  }

  .myrow {
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    --bs-gutter-x: 1.5rem;
    border-radius: 0.25rem;
    margin: 5px 0px 5px 0px;
  }

  .mymap {
    height: 100%; 
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 0.25rem;
    padding: 10px 10px 10px 10px;
  }

  .mymapcont {
    height: 100%; 
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 0.25rem;
    background-color: #ffffff;
    opacity: 1;
    padding: 10px 10px 10px 10px;
    display: flex;
        flex-direction: column; 
  }

  .myheader {
    height: 100%; 
    width: 100%;
    /* border: 1px solid #ffffff; */
    /* border-radius: 0.25rem; */
    padding: 10px 10px 10px 10px;
    /* background-color: #a78713;     */
    display: flex;    
    flex-direction: column;     
    justify-content: center;
    color : rgb(219, 21, 21)
  }

  .mystatus {
    height: 100%; 
    width: 33%;
    /* border: 1px solid #ffffff; */
    /* border-radius: 0.25rem; */
    padding: 10px 10px 10px 10px;
    /* background-color: #a78713;     */
    display: flex;    
    flex-direction: column;     
    justify-content: center;
    color : rgb(219, 21, 21)
  }
  
  .black-white{
    filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(135deg) brightness(106%) contrast(101%);
  }

  .myvalue {
    font-size: 1.4rem;
    font-weight: bold;
    font-style: normal;
    color: white;
    text-shadow: 1px 1px 2px rgb(68, 66, 66), 0 0 25px rgb(120, 120, 224), 0 0 5px rgb(104, 104, 118);
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
</style>


