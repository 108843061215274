<template>
  <div>
    <div
      v-if="
        Object.keys(holeUsage).length > 0 &&
        Object.keys(usedResources).length > 0 &&
        Object.keys(usage).length > 0
      "
      class="row col-md-12"
    >
      <div
        class="col-12"
        v-for="(item, index) in mainResources.parameters"
        :key="index"
      >
        <label
          v-if="index != 'numberOfWorkbenches' && item.connections != 0"
          :for="index"
          class="pl-3 py-3 px-3"
        >{{ translation[index][lang] }}</label
        >
        <div
          class="row py-3 px-0 mx-0"
          v-if="index != 'numberOfWorkbenches' && item.connections != 0"
        >

          <div class="col-md-8 col-12 px-0 mx-0">
            <ejs-slider
              :id="'default' + index"
              :min="0"
              :enableRtl="lang=='per'"
              :max="showMax(index)"
              type="MinRange"
              :limits="{
                enabled: true,
                minStart:
                  typeof usage[index] == 'object'
                    ? usage[index].connections
                    : usage[index],
                minEnd: showMax(index),
              }"
              :tooltip="{
                placement: 'Before',
                isVisible: true,
                showOn: 'Auto'
              }"
              :value="usedResources[index].connections || usedResources[index]"
              @change="getVal($event,index)"
              :ticks="{
                placement: 'Before',
                largeStep:
                  Math.floor(showMax(index) / 10) == 0
                    ? 1
                    : Math.floor(showMax(index) / 10),
                smallStep: Math.floor(showMax(index) / 10) == 0
                    ? 1
                    : Math.floor(showMax(index) / 10),
                showSmallTicks: true,
              }"
            ></ejs-slider>
          </div>
          <!--          <div class="col-md-2 my-md-0  col-12">-->
          <!--            <ejs-textbox-->
          <!--              :value="holeUsage[index].connections || holeUsage[index]"-->
          <!--              :placeholder="translation.count[lang]"-->
          <!--              floatLabelType="Auto"-->
          <!--              :min="0"-->
          <!--              :max="mainResources.parameters[index].connections || mainResources.parameters[index]"-->
          <!--              :enableRtl="lang=='per'"-->
          <!--              type="number"-->
          <!--            ></ejs-textbox>-->
          <!--          </div>-->
          <div class="progress my-3 col-md-4">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :aria-valuenow="
                typeof usedResources[index] == 'object'
                  ? usedResources[index].connections
                  : usedResources[index]
              "
              aria-valuemin="0"
              :aria-valuemax="showMax(index)"
              :style="{
                width:
                  showPercent(
                    typeof usedResources[index] == 'object'
                      ? usedResources[index].connections
                      : usedResources[index],
                    typeof usage[index] == 'object'
                      ? usage[index].connections
                      : usage[index]
                  ) + '%',
              }"
            >
              {{
                showPercent(
                  typeof usedResources[index] == "object"
                    ? usedResources[index].connections
                    : usedResources[index],
                  typeof usage[index] == "object"
                    ? usage[index].connections
                    : usage[index]
                ) + "%"
              }}
            </div>
            <div
              class="
                progress-bar progress-bar-striped progress-bar-animated
                bg-secondary
              "
              role="progressbar"
              :style="{
                width:
                  showRemains(
                    typeof usedResources[index] == 'object'
                      ? usedResources[index].connections
                      : usedResources[index],
                    typeof usage[index] == 'object'
                      ? usage[index].connections
                      : usage[index]
                  ) + '%',
              }"
              aria-valuenow="30"
              aria-valuemin="0"
              :aria-valuemax="showMax(index)"
            >
              {{
                showRemains(
                  typeof usedResources[index] == "object"
                    ? usedResources[index].connections
                    : usedResources[index],
                  typeof usage[index] == "object"
                    ? usage[index].connections
                    : usage[index]
                ) + "%"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="p-5 text-center"
      v-if="
        Object.keys(holeUsage).length <= 0 &&
        Object.keys(usedResources).length <= 0 &&
        Object.keys(usage).length <= 0
      "
    >
      {{ translation.licenceLoader[lang] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "licenceHandler",
  props: {
    wbId: { default: "" }
  },
  data() {
    return {
      mainResources: {},
      usedResources: {},
      holeUsage: {},
      usage: {},
      fileToSend: {},
      newValues: {},
      translation: {
        count: { eng: "count", per: "تعداد تخصیص" },
        licenceLoader: { per: "منابع در حال بارگیری ...", eng: "loading licence...." },
        jsonMqtt: { per: "پروتکل mqtt با نگاشت JSON", eng: "Json Mqtt" },
        mtr22Mqtt: { per: "پروتکل mqtt با نگاشت mtr22", eng: "Mtr22 Mqtt" },
        jsonHttps: { per: "پروتکل https با نگاشت JSON", eng: "Json Https" },
        jsonHttpsServers: {
          per: "پروتکل سرور https با نگاشت JSON",
          eng: "Json Https Servers"
        },
        jsonCloud: { per: "تعداد فضای ابری", eng: "Json Cloud" },
        totalControlNodes: { per: "دستورات", eng: "Rules" },
        totalEventRules: { per: "قوانین رویدادها", eng: "Event Rules" },
        totalEventTypes: { per: "انواع رویدادها", eng: "Event Types" },
        totalDataIngestionModules: {
          per: "فرایند استخراج داده",
          eng: "Data Ingestion Modules"
        },
        totalProcessFlows: { per: "چرخه های فرایند", eng: "Process Flows" },
        totalData: { per: "تعداد دیتاهای سیستم", eng: "Datas" },
        totalMaps: { per: "نقشه ها", eng: "Maps" },
        totalDashboardWidgets: {
          per: "ویجت های دشبورد",
          eng: "Dashboard Widgets"
        },
        numberOfWorkbenches: { per: "میزکارها", eng: "Workbenches" }
      }
    };
  },
  mounted() {
    this.mainResources = this.$store.state.data.resource.all;
    this.getAllTakhsis();
    this.getWbCurLicense();
    this.getCurrentLicenceusage();
  },
  methods: {
    getVal(val, index) {
      if (!isNaN(val.value)) {
        this.newValues[index] = val.value;
        // if (typeof name == "object") {
        //   // if (!this.newValues[index]) {
        //   //   this.newValues[index] = name
        //   // }
        //   this.newValues[index]['connections'] = val.value;
        // } else this.newValues[index] = val.value;
      }
    },
    getAllTakhsis() {
      this.$store.dispatch("data/getAllSysTakhsis").then((res) => {
        this.holeUsage = res.data.message;
      });
    },
    getCurrentLicenceusage() {
      this.$store
        .dispatch("data/getWbLicenceById", { workbench: this.wbId })
        .then((res) => {
          this.usage = res.message;
        });
    },
    setWbLicences() {
      let result = {};
      let name = "";
      Object.entries(this.usedResources).forEach((item) => {
        name = item[0];
        Object.entries(this.newValues).forEach((item2) => {
          if (!result[name]) {
            result[name] = item[1];
          }
          if (item[0] == item2[0]) {
            if (typeof item[1] == "object") {
              result[name].connections = item2[1];
            } else result[name] = item2[1];
          }
        });
      });
      this.fileToSend = result;
      if (Object.keys(this.fileToSend).length>0)
      {
        this.$nextTick(() => {
          const data = {
            workbench: this.wbId,
            license: this.fileToSend
          };
          this.$store.dispatch("data/setWbLicence", data).then(() => {
            // console.log("success");
          });
        });
      }
      this.$store.dispatch("data/getAllSysTakhsis");
      this.$store.dispatch("data/getMainSysLicences");
      this.$store.dispatch("data/getUsedSysLicences");
    },
    getWbCurLicense() {
      this.$store
        .dispatch("data/getCurWbLicence", { workbench: this.wbId })
        .then((res) => {
          this.usedResources = res.message;
        });
    },
    showPercent(hole, used) {
      if (used != undefined && hole != undefined) {
        if (used == 0 || hole == 0) return 0;
        else return Math.floor((used / hole) * 100);
      }
    },
    showRemains(hole, used) {
      if (used == 0 || hole == 0) return 100;
      const a = ((hole - used) / hole) * 100;
      return Math.floor(a);
    },
    showMax(name) {
      let all =
        typeof this.mainResources.parameters[name] == "object"
          ? this.mainResources.parameters[name].connections
          : this.mainResources.parameters[name];
      let used =
        typeof this.holeUsage[name] == "object"
          ? this.holeUsage[name].connections
          : this.holeUsage[name];
      let usage =
        typeof this.usedResources[name] == "object"
          ? this.usedResources[name].connections
          : this.usedResources[name];
      if (used != undefined && usage != undefined && all != undefined) {
        return all - used + usage;
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<style>
.custom-label1 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 12px;
}
</style>
