<template>
  <div>
    <b-modal
      id="modal-2"
      size="sm"
      centered
      header-border-variant="primary"
      hide-header-close
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit" >
        <b-list-group flush>
          <!-- <b-list-group-item style="background-color:transparent;color:black;"> -->
          <b-form-group
            label="Zone Title"
            label-for="title"
            :invalid-feedback="errors.first('title')"
            style="width:100%; padding: 0px 0px 10px 0px;"
          >
            <b-form-input
              name="title"
              type="text"
              placeholder="Enter Title"
              v-model="zone.floorZones.title"
              :state="(submitted)? (!errors.has('title')): null"
              v-validate="'required|min:2|max:50'"
              style="width:100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="on click action"
            label-for="onClickType"
            :invalid-feedback="errors.first('onClickType')"
            style="width:100%; padding: 0px 0px 10px 0px;"
          >
            <b-form-select
              name="onClickType"
              size="lg"
              v-model="zone.floorZones.onClick.type"
              :options="typeList"
              :state="(submitted)? (!errors.has('onClickType')): null"
              v-validate="'required'"
              style="width:100%"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            v-if="zone.floorZones.onClick.type==='page'"
            label="page number"
            label-for="onClickValue"
            :invalid-feedback="errors.first('onClickValue')"
            style="width:100%; padding: 0px 0px 10px 0px;"
          >
            <b-form-input
              name="onClickValue"
              type="text"
              placeholder="Enter desired page number"
              v-model="zone.floorZones.onClick.value"
              :state="(submitted)? (!errors.has('onClickValue')): null"
              v-validate="'required|decimal:10|min_value:1'"
              style="width:100%"
            ></b-form-input>
          </b-form-group>

          <div style="display:flex; flex-direction:row; gap:10px">
            <b-form-group
              label="Line Weight"
              label-for="weight"
              :invalid-feedback="errors.first('weight')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="weight"
                type="range" min="0" max="10" step="0.1"
                placeholder="Enter Line Weight"
                v-model="zone.floorZones.weight"
                :state="(submitted)? (!errors.has('weight')): null"
                v-validate="'required|decimal:3|min_value:0|max_value:10'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.weight }}</div>
            </b-form-group>

            <b-form-group
              label="Smooth Factor"
              label-for="smoothFactor"
              :invalid-feedback="errors.first('smoothFactor')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="smoothFactor"
                type="range" min="0" max="10" step="0.1"
                placeholder="Enter Smooth Factor"
                v-model="zone.floorZones.smoothFactor"
                :state="(submitted)? (!errors.has('smoothFactor')): null"
                v-validate="'required|decimal:3|min_value:0|max_value:10'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.smoothFactor }}</div>
            </b-form-group>
          </div>

          <div style="display:flex; flex-direction:row; gap:10px">
            <b-form-group
              label="Line Opacity"
              label-for="opacity"
              :invalid-feedback="errors.first('opacity')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="opacity"
                type="range" min="0" max="1" step="0.1"
                placeholder="Enter opacity"
                v-model="zone.floorZones.opacity"
                :state="(submitted)? (!errors.has('opacity')): null"
                v-validate="'required|decimal:3|min_value:0|max_value:1'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.opacity }}</div>
            </b-form-group>


            <b-form-group
              label="Fill Opacity"
              label-for="fillOpacity"
              :invalid-feedback="errors.first('fillOpacity')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="fillOpacity"
                type="range" min="0" max="1" step="0.1"
                placeholder="Enter Fill Opacity"
                v-model="zone.floorZones.fillOpacity"
                :state="(submitted)? (!errors.has('fillOpacity')): null"
                v-validate="'required|decimal:3|min_value:0|max_value:1'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.fillOpacity }}</div>
            </b-form-group>
          </div>

          <div style="display:flex; flex-direction:row; gap:10px">

            <b-form-group
              label="Line Color"
              label-for="color"
              :invalid-feedback="errors.first('color')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="color"
                type="color"
                placeholder="Enter Color"
                v-model="zone.floorZones.color"
                :state="(submitted)? (!errors.has('color')): null"
                v-validate="'required'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.color }}</div>
            </b-form-group>

            <b-form-group
              label="Fill Color"
              label-for="fillcolor"
              :invalid-feedback="errors.first('fillcolor')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="fillcolor"
                type="color"
                placeholder="Enter Fill Color"
                v-model="zone.floorZones.fillColor"
                :state="(submitted)? (!errors.has('fillcolor')): null"
                v-validate="'required'"
                style="width:100%"
              ></b-form-input>
              <div> {{ zone.floorZones.fillColor }}</div>
            </b-form-group>
          </div>

          <b-form-group
            label="Order"
            label-for="order"
            :invalid-feedback="errors.first('order')"
            style="width:100%;"
          >
            <b-form-input
              name="order"
              type="range" min="-5" max="5"
              placeholder="Enter Order"
              v-model="zone.floorZones.order"
              :state="(submitted)? (!errors.has('order')): null"
              v-validate="'required|decimal|min_value:-5|max_value:5'"
              style="width:100%"
            ></b-form-input>
            <div> {{ zone.floorZones.order }}</div>
          </b-form-group>

          <!-- </b-list-group-item> -->
        </b-list-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import mySwitch from "./mySwitch.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "mapZoneSetting",
  components: {
    mySwitch
  },
  data() {
    return {
      modalTitle: "",
      typeList: [
        { text: "Select One", value: null },
        { text: "change page", value: "page" }
      ],
      submitted: false,
      successful: false,
      message: "",
      zone: {
        _id: "",
        title: "",
        floorZones: {
          fillColor: "",
          title: "",
          color: "",
          weight: "",
          onClick: { type: null, value: "" },
          type: null,
          fillOpacity: "",
          opacity: "",
          smoothFactor: "",
          order: ""
        }
      }
    };
  },
  computed: {
    zoneData() {
      return this.$store.state.panel.zoneData;
    },
    updateMap() {
      return this.$store.state.panel.updateMap;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }

  },
  watch: {
    // toggledSwitches(newValue, oldValue) {
    //     for (var feeder of newValue){
    //         if (this.feeders.find( ({ id }) => id === feeder.id.feeder ))
    //             this.feeders.find( ({ id }) => id === feeder.id.feeder ).status = feeder.status;
    //     }
    // }
    parent2child(val) {
      if (val.task == "zoneGet") {
        this.zone.floorZones = val.item.zoneOptions;
        this.zone._id = val.item._id;
        this.zone.title = val.item.zoneTitle;
      }
    }
  },
  methods: {
    initModal: function() {
      this.modalTitle = "edit Zone";//this.floor + " / " + this.zone
      // this.zone._id = this.zoneData._id.zone;
      // this.zone.title = this.zoneData.title;
      // this.zone.floorZones = this.zoneData.options;

      // console.log(this.zone)

    },
    resetModal: function() {
      this.modalTitle = null;
      this.zone._id = "";
      this.zone.title = "";
      this.zone.floorZones.fillColor = "";
      this.zone.floorZones.Color = "";
      this.zone.floorZones.weight = "";
      this.zone.floorZones.type = null;
      this.zone.floorZones.onClick = { type: null, value: "" };
      this.zone.floorZones.fillOpacity = "";
      this.zone.floorZones.opacity = "";
      this.zone.floorZones.smoothFactor = "";
      this.zone.floorZones.order = "";
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      const isValid=new FormValidator('#inputs_zone').validate();
      if (isValid) {
        var data = {
          type: "zone",
          _id: this.parent2child.all._id,
          zoneId: this.zone._id,
          floorZones: this.zone.floorZones
        };

        this.$store.dispatch("data/editFloor", data).then(
          data => {
            this.message = data.message;
            this.successful = true;
            this.$store.dispatch("panel/updateMap", this.updateMap + 1);
            this.$nextTick(() => {
              this.$bvModal.hide("modal-2");
            });
            // this.$store.dispatch('chpge/changePage','controllerSettings')
          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
          }
        );


      }
    }
  }

};
</script>
