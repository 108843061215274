<template>
    <div style="text-align:left">
        <div>
            <b-container fluid="md">
                <b-row class=my-5>
                    <b-col cols="1"></b-col>
                    <b-col cols="10" sm>
                        <b-card no-body>
                            <b-card-body>     
                                <b-form ref="form" @submit.stop.prevent="handleRegister" @reset="onReset">
                                    
                                    <div style="display: flex; align-items: center; flex-wrap: wrap;">
                                        <h4 style="flex: 90%;">{{ title}}</h4>
                                        <b-button type="submit"  variant="outline-success"  class="mr-2" style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                        <b-button type="reset"  variant="outline-danger"  class="mr-2" style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>

                                    <hr class="my-2">
                                
                                    <b-list-group flush>
                                        <!-- <b-list-group-item style="background-color:transparent;color:black;">
                                            <div style="display:flex; flex-direction:row">
                            
                                                <b-form-group
                                                    label="Floor"
                                                    label-for="floor"
                                                    :invalid-feedback="errors.first('floor')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="floor"
                                                        size="lg"
                                                        v-model="feeder.floor"
                                                        :options="floorList"
                                                        :state="(submitted)? (!errors.has('floor')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setZoneList($event)"
                                                    ></b-form-select>
                                                </b-form-group>   

                                                <b-form-group
                                                    label="Zone"
                                                    label-for="zone"
                                                    :invalid-feedback="errors.first('zone')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="zone"
                                                        size="lg"
                                                        v-model="feeder.zone"
                                                        :options="zoneList"
                                                        :state="(submitted)? (!errors.has('zone')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setZoneIndex($event)"
                                                    ></b-form-select>
                                                </b-form-group>     
                                            </div>
                                        </b-list-group-item> -->

                                        <b-list-group-item style="background-color:transparent;color:black;">
                                            <div style="display:flex; flex-direction:row">

                                                <b-form-group
                                                    label="feeder Title"
                                                    label-for="title"                                            
                                                    :invalid-feedback="errors.first('title')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        name="title"
                                                        type="text"
                                                        placeholder="Enter title"
                                                        v-model="feeder.title"
                                                        :state="(submitted)? (!errors.has('title')): null"  
                                                        v-validate="'required|min:2|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>  

                                                <b-form-group
                                                    label="Feeder Type"
                                                    label-for="type"
                                                    :invalid-feedback="errors.first('type')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="type"
                                                        size="lg"
                                                        v-model="feeder.type"
                                                        :options="typeList"
                                                        :state="(submitted)? (!errors.has('type')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                    ></b-form-select>
                                                </b-form-group> 

                                            </div>

                                            <div style="display:flex; flex-direction:row">
                                                

                                                <b-form-group
                                                    label="feeder Longitude"
                                                    label-for="longitude"                                            
                                                    :invalid-feedback="errors.first('longitude')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        disabled
                                                        name="longitude"
                                                        type="text"
                                                        placeholder="Enter longitude"
                                                        v-model="feeder.longitude"
                                                        :state="(submitted)? (!errors.has('longitude')): null"  
                                                        v-validate="'required|min:2|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>  

                                                <b-form-group
                                                    label="feeder Latitude"
                                                    label-for="latitude"                                            
                                                    :invalid-feedback="errors.first('latitude')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        disabled
                                                        name="latitude"
                                                        type="text"
                                                        placeholder="Enter latitude"
                                                        v-model="feeder.latitude"
                                                        :state="(submitted)? (!errors.has('latitude')): null"  
                                                        v-validate="'required|min:2|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>  

                                            </div>
                                        </b-list-group-item>

                                        <b-list-group-item style="background-color:transparent;color:black; ">
                                            <div style="display:flex; flex-direction:row">
                                            
                                                <b-form-group
                                                    label="Master"
                                                    label-for="master"
                                                    :invalid-feedback="errors.first('master')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="master"
                                                        size="lg"
                                                        v-model="feeder.master"
                                                        :options="masterList"
                                                        :state="(submitted)? (!errors.has('master')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setSlaveList($event)"
                                                    ></b-form-select>
                                                </b-form-group> 

                                                <b-form-group
                                                    label="Slave"
                                                    label-for="slave"
                                                    :invalid-feedback="errors.first('slave')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="slave"
                                                        size="lg"
                                                        v-model="feeder.slave"
                                                        :options="slaveList"
                                                        :state="(submitted)? (!errors.has('slave')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setSlotList($event)"
                                                    ></b-form-select>
                                                </b-form-group> 
                                            </div>

                                            <div style="display:flex; flex-direction:row">
                                                <b-form-group
                                                    label="Slot"
                                                    label-for="slot"
                                                    :invalid-feedback="errors.first('slot')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="slot"
                                                        size="lg"
                                                        v-model="feeder.slot"
                                                        :options="slotList"
                                                        :state="(submitted)? (!errors.has('slot')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setChannelList($event)"
                                                    ></b-form-select>
                                                </b-form-group> 
                                                
                                                <b-form-group
                                                    label="Channel"
                                                    label-for="channel"
                                                    :invalid-feedback="errors.first('channel')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="channel"
                                                        size="lg"
                                                        v-model="feeder.channel"
                                                        :options="channelList"
                                                        :state="(submitted)? (!errors.has('channel')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                        @change="setChannelIndex($event)"
                                                    ></b-form-select>
                                                </b-form-group> 
                                            </div>
                                        </b-list-group-item>

                                    </b-list-group>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container> 
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    // import { v4 as uuidv4 } from 'uuid';
    export default {
        name: "accountEdit",
        data() {
            return {
                feeder:{
                    id:'',
                    title:'',
                    type:null,
                    longitude:'',
                    latitude:'',
                    
                    floor:null,
                    zone:null,
                    master:null,
                    slave:null,
                    slot:null,
                    channel:null,

                    floorIndx:'',
                    zoneIndx:'',
                    masterIndx:'',
                    slaveIndx:'',
                    slotIndx:'',
                    channelIndx:'',
                },
                building: [],
                controller:[],

                floorList :  [{ text: 'Select One', value: null }],
                zoneList :  [{ text: 'Select One', value: null }],
                typeList: [{ text: 'Select One', value: null },'main','auxilary','hidden'],
                masterList :  [{ text: 'Select One', value: null }],
                slaveList :  [{ text: 'Select One', value: null }],
                slotList :  [{ text: 'Select One', value: null }],
                channelList :  [{ text: 'Select One', value: null }],

                submitted: false,
                successful: false,
                message: '',

                title:'',

            }
        },
        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        methods: {
            initModal() {
                this.title = this.type + " Feeder"
                //this.getBuildConf();
                this.getContConf();

                if (this.type==='Add')
                {
                    
                    // this.feeder.uuid = uuidv4();
                    this.feeder.floor       = this.db.floor;
                    this.feeder.zone        = this.db.zone;
                    this.feeder.longitude   = this.db.longitude;
                    this.feeder.latitude    = this.db.latitude;
                }
                else if (this.type==='Edit')
                {
                    this.feeder.id          = this.db.id;
                    this.feeder.floor       = this.db.floor;
                    this.feeder.zone        = this.db.zone;
                    this.feeder.title = this.db.title;
                    this.feeder.type = this.db.type;
                    this.feeder.longitude = this.db.longitude;
                    this.feeder.latitude = this.db.latitude;
                }
            },
            onReset() {
                this.feeder.id = null
                this.feeder.title = null;
                this.feeder.type = null;
                this.feeder.longitude = null;
                this.feeder.latitude = null;
                
                this.feeder.master = null;
                this.feeder.slave = null;
                this.feeder.slot = null;
                this.feeder.floor = null;
                this.feeder.zone = null;

                this.$store.dispatch('chpge/changePage','feederSettings')
            },
            handleRegister() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if (this.type==='Add'){

                            var data = {
                                // uuid: this.feeder.uuid,
                                title: this.feeder.title,
                                type: this.feeder.type,
                                longitude: this.feeder.longitude,
                                latitude: this.feeder.latitude,
                                floor: this.feeder.floor,
                                zone: this.feeder.zone,
                                master: this.controller[this.feeder.masterIndx]._id,
                                slave: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx]._id,
                                slot: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].slots[this.feeder.slotIndx]._id,
                                channel: this.feeder.channelIndx
                                // floor: this.building[this.feeder.floorIndx].floorUuid,
                                // zone: this.building[this.feeder.floorIndx].floorZones[this.feeder.zoneIndx].zoneUuid,

                                // master: this.controller[this.feeder.masterIndx].topic,
                                // slave: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].uuid,
                                // slot: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].slots[this.feeder.slotIndx].uuid,
                                // channel: this.feeder.channelIndx
                            }

                            this.$store.dispatch('data/createFeeder', data).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','feederSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }else if (this.type==='Edit'){
                            var data = {
                                id : this.feeder.id,
                                title: this.feeder.title,
                                type: this.feeder.type,
                                longitude: this.feeder.longitude,
                                latitude: this.feeder.latitude,
                                floor: this.feeder.floor,
                                zone: this.feeder.zone,
                                master: this.controller[this.feeder.masterIndx]._id,
                                slave: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx]._id,
                                slot: this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].slots[this.feeder.slotIndx]._id,
                                channel: this.feeder.channelIndx
                            }
                            console.log(JSON.stringify(data))
                            this.$store.dispatch('data/editFeeder', data).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','feederSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }
                    }
                });
            },
            // getBuildConf() {
            //     this.$store.dispatch('data/getBuildConf').then(
            //         data => {
            //             console.log(JSON.stringify(data))
            //             this.building = data;
            //             this.setFloorList()
            //             if (this.type==='Edit'){
            //                 this.feeder.floor = this.db[this.index].floor;
            //                 this.setZoneList(this.feeder.floor);
            //                 this.feeder.zone = this.db[this.index].zone;
            //                 this.setZoneIndex(this.feeder.zone);
            //             }
            //         },
            //         error => {
            //             this.message =
            //                 (error.response && error.response.data && error.response.data.message) ||
            //                 error.message ||
            //                 error.toString();
            //                 // this.successful = false;
            //         }
            //     );
            // },
            // setFloorList() {
            //     this.floorList =  [{ text: 'Select One', value: null }];
            //     for (var floor of this.building){
            //         this.floorList.push(floor.floorTitle)//floorId
            //     }
            //     this.feeder.floor = null;
            //     this.feeder.floorIndx = '';
            //     this.feeder.zone = null;
            //     this.feeder.zoneIndx = '';
            // },
            // setZoneList(value) {
            //     var index = this.floorList.indexOf(value)
            //     this.feeder.floorIndx = index-1;
            //     this.zoneList =  [{ text: 'Select One', value: null }];
            //     if (index>0){
            //         for (var zone of this.building[this.feeder.floorIndx].floorZones){
            //             this.zoneList.push(zone.zoneTitle)//floorId
            //         }
            //     }
            //     this.feeder.zone = null;
            //     this.feeder.zoneIndx = '';
            // },
            // setZoneIndex(value) {
            //     var index = this.zoneList.indexOf(value)
            //     this.feeder.zoneIndx = index-1;
            // },
            getContConf() {
                this.$store.dispatch('data/getContConf').then(
                    data => {
                        console.log(JSON.stringify(data))
                        this.controller = data
                        this.setMasterList();
                        if (this.type==='Edit'){
                            this.feeder.master = this.db.master;
                            this.setSlaveList(this.feeder.master);
                            this.feeder.slave = this.db.slave;
                            this.setSlotList(this.feeder.slave);
                            this.feeder.slot = this.db.slot;
                            this.setChannelList(this.feeder.slot);
                            this.feeder.channel = 'channel '+ this.db.channel;
                            this.setChannelIndex(this.feeder.channel);
                        }
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
            setMasterList() {
                this.masterList =  [{ text: 'Select One', value: null }];
                for (var master of this.controller){
                    this.masterList.push(master.topic)//floorId
                }
                this.feeder.master = null;
                this.feeder.masterIndx = '';
                this.feeder.slave = null;
                this.feeder.slaveIndx = '';
                this.feeder.slot = null;
                this.feeder.slotIndx = '';
                this.feeder.channel = null;
                this.feeder.channelIndx = '';

            },
            setSlaveList(value) {
                var index = this.masterList.indexOf(value)
                this.feeder.masterIndx = index-1;
                console.log(value,index)
                this.slaveList =  [{ text: 'Select One', value: null }];
                if (index>0){
                    for (var slave of this.controller[this.feeder.masterIndx].slaves){
                        this.slaveList.push(slave.name)//floorId
                    }
                }
                this.feeder.slave = null;
                this.feeder.slaveIndx = '';
                this.feeder.slot = null;
                this.feeder.slotIndx = '';
                this.feeder.channel = null;
                this.feeder.channelIndx = '';
            },
            setSlotList(value) {
                var index = this.slaveList.indexOf(value)
                this.feeder.slaveIndx = index-1;
                this.slotList =  [{ text: 'Select One', value: null }];
                if (index>0){
                    for (var slot of this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].slots){
                        this.slotList.push(slot.name)//floorId
                    }
                }
                this.feeder.slot = null;
                this.feeder.slotIndx = '';
                this.feeder.channel = null;
                this.feeder.channelIndx = '';
            },
            setChannelList(value) {
                var index = this.slotList.indexOf(value)
                this.feeder.slotIndx = index-1;
                this.channelList =  [{ text: 'Select One', value: null }];
                if (index>0){
                    for (var i=1; i<=this.controller[this.feeder.masterIndx].slaves[this.feeder.slaveIndx].slots[this.feeder.slotIndx].channels; i++){
                        this.channelList.push('channel '+ i)//floorId
                    }
                }
                this.feeder.channel = null;
                this.feeder.channelIndx = '';
            },
            setChannelIndex(value) {
                var index = this.channelList.indexOf(value)
                this.feeder.channelIndx = index;
            },

        },



        mounted() {
            this.initModal()
        }
    };
</script>
