



<template>
  <div ref="pageContainer" class="page_container" >
    <div class="sidebar" :class="{ expanded: isExpanded }" @mouseover="expandSidebar" @mouseleave="collapseSidebar">
      <div :class="{ icon: !isExpanded, iconexpanded: isExpanded }">
        <i><font-awesome-icon icon="fas fa-home" /></i>
        <span v-if="isExpanded">Home</span>
      </div>
      <div :class="{ icon: !isExpanded, iconexpanded: isExpanded }">
        <i><font-awesome-icon icon="fas fa-cog" /></i>
        <span v-if="isExpanded">Settings</span>
      </div>
      <div :class="{ icon: !isExpanded, iconexpanded: isExpanded }">
        <i><font-awesome-icon icon="fas fa-bar-chart" /></i>
        <span v-if="isExpanded">Charts</span>
      </div>
      <div :class="{ icon: !isExpanded, iconexpanded: isExpanded }" >
        <i><font-awesome-icon icon="fas fa-bell" /></i>
        <span v-if="isExpanded">Notifications</span>
      </div>
      <div :class="{ icon: !isExpanded, iconexpanded: isExpanded }" >
        <i><font-awesome-icon icon="fas fa-clock" /></i>
        <span v-if="isExpanded">Alarms</span>
      </div>
    </div>
    <div class="sidebarRight" >
      
      <div>
        <div class="nrmlItem" @click="close">
          <div class="base">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
          </div>
        </div>

        <div class="nrmlItem" @click="expandPanel('1')">
          <div class="base">
            <i><font-awesome-icon icon="fas fa-save" /></i>
          </div>
        </div>
      </div>
      
      <div>
        <div :class="selPanel==='1'? 'slcdItem':'nrmlItem'" @click="expandPanel('1')">
          <div  class="edgeUp">
            <div class="edgeUp1"></div>
          </div>
          <div class="base">
            <i><font-awesome-icon icon="fas fa-music" /></i>
          </div>
          <div  class="edgeDn">
            <div class="edgeDn1"></div>
          </div>
        </div>

        <div :class="selPanel==='2'? 'slcdItem':'nrmlItem'" @click="expandPanel('2')">
          <div  class="edgeUp">
            <div class="edgeUp1"></div>
          </div>
          <div class="base">
            <i><font-awesome-icon icon="fas fa-cog" /></i>
          </div>
          <div  class="edgeDn">
            <div class="edgeDn1"></div>
          </div>
        </div>
        
        <div :class="selPanel==='3'? 'slcdItem':'nrmlItem'" @click="expandPanel('3')">
          <div  class="edgeUp">
            <div class="edgeUp1"></div>
          </div>
          <div class="base">
            <i><font-awesome-icon icon="fas fa-bar-chart" /></i>
          </div>
          <div  class="edgeDn">
            <div class="edgeDn1"></div>
          </div>
        </div>
      </div>

    </div>

    <div v-if="isPanelExpanded" class="settingPanel">
      <div class="header">
        <div class="title">
          <i>Control Blocks</i>
        </div>
        <div class="button" @click="collapsePanel">
          <i ><font-awesome-icon icon="fas fa-xmark" /></i>
        </div>
      </div>
      <div class="body" v-if="selPanel==='1'">
        <treeMenu 
          :nodes="tree.nodes" 
          :depth="0"   
          :label="tree.label"
          :id="tree.id"
          :colors="tree.colors"
          :style="{backgroundColor: tree.colors[1] } "
        ></treeMenu>
      </div>
    </div>
     
    <VueDiagramEditor 
      ref="diagram"
      :height = pageContainerHeight
      class="editor"
      :node-color="nodeColor"
    >
      <!-- <pre slot="node" slot-scope="{node}">{{ format(node) }}</pre> -->
    </VueDiagramEditor>
  </div>
</template>

<script>
  import VueDiagramEditor from 'vue-diagram-editor';
  // import CodeEditor from 'simple-code-editor';
  import 'vue-diagram-editor/dist/vue-diagram-editor.css';
  import treeMenu from "./treeMenu.vue";


export default {
  name: 'GmailSidebar',

  components: {
    // CodeEditor,
    VueDiagramEditor,
    treeMenu
  },
  data() {
    return {
      isExpanded: false,
      isPanelExpanded: false,
      selPanel:null,
      pageContainerHeight:100,
      nodeCnt:2,
      nodes: {
        'node-1': {
          id: 'node-1',
          title: 'My node 1',
          size: {
            width: 200,
            height: 220
          },
          portsOut: {
            port1: 'port1',
            port2: 'port2'
          },
          data:{
            color:'#0f0'
          }
        },
        'node-2': {
          id: 'node-2',
          title: 'My node 2',
          size: {
            width: 200,
            height: 220
          },
          coordinates: {
            x: 280,
            y: 100
          },
          portsIn: {
            default: 'in port'
          },
          data:{
            color:'#00f'
          }
        },
      },
      links: {
        'link-1': {
          id: 'link-1',
          start_id: 'node-1',
          start_port: 'port1',
          end_id: 'node-2',
          end_port: 'default'
        }
      },
      tree : {
        label: 'root',
        id:'root',
        nodes: [
        ],
        colors: [{backgroundColor:'transparent', color:'white'},
                  {backgroundColor:'transparent', color:'white',fontWeight:'bold'},
                  {backgroundColor:'rgb(57, 94, 162)', color:'white'},
                  {backgroundColor:'#24303e', color:'white'}]
      },
      mynodes: [
        {
          id: 'MGA-CAT001_001',
          title: 'Hysteresis Controller',
          catId: 'CAT001',
          catTitle: 'controller',
          size: {
            width: 100,
            height: 120
          },
          ports: {
            out:{
              port1: 'port1',
              port2: 'port2'
            },
            in:{
              port1: 'port1',
              port2: 'port2'
            }
          },
          data:{
            color:'#00f'
          }
        },
        {
          id: 'MGA-CAT001_002',
          title: 'PID Controller',
          catId: 'CAT001',
          catTitle: 'controller',
          size: {
            width: 75,
            height: 150
          },
          ports: {
            out:{
              port1: 'out',
            },
            in:{
              port1: 'in+',
              port2: 'in-'
            }
          },
          data:{
            color:'red'
          }
        },
        {
          id: 'MGA-CAT002_001',
          title: 'AND',
          catId: 'CAT002',
          catTitle: 'logic',
          size: {
            width: 50,
            height: 75
          },
          ports: {
            out:{
              port1: 'out',
            },
            in:{
              port1: 'A',
              port2: 'B'
            }
          },
          data:{
            color:'blue'
          }
        },
        {
          id: 'MGA-CAT002_002',
          title: 'OR',
          catId: 'CAT002',
          catTitle: 'logic',
          size: {
            width: 50,
            height: 75
          },
          ports: {
            out:{
              port1: 'out',
            },
            in:{
              port1: 'A',
              port2: 'B'
            }
          },
          data:{
            color:'green'
          }
        },
      ],

    }
  },
  methods: {
    init() {
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links
      });
    },
    format(node) {
      return JSON.stringify(node, null, 2);
    },
    nodeColor(node) {
      return node.data.color;
    },

    nodePulsable(node) {
      return node.coordinates.y > 200;
    },
    GenerateList(){
      var catList = []
      var catObj = {}
      for (var node of this.mynodes){
        if (!catList.includes(node.catId)){
          catList.push(node.catId)
          catObj[node.catId] = node.catTitle
        }
      }
      for (var cat of catList){
        var NODES = this.mynodes.filter(x => x.catId === cat)
        var nodes =[]
        for (var inode of NODES){
          nodes.push({id: inode.id, label: inode.title})
        } 
        this.tree.nodes.push({
          label: catObj[cat],
          id:cat,
          nodes: nodes
        })
      }
    },
    expandPanel(icon) {
      this.isPanelExpanded = true;
      this.selPanel = icon
      // this.$emit('expand', icon);
    },
    collapsePanel() {
      this.isPanelExpanded = false;
      this.selPanel=null
      // this.$emit('collapse', icon);
    },
    expandSidebar() {
      this.isExpanded = true;
      // this.$emit('expand', icon);
    },
    collapseSidebar() {
      this.isExpanded = false;
      // this.$emit('collapse', icon);
    },
    pageHeight () {
      this.pageContainerHeight = this.$refs.pageContainer.clientHeight;
    },
    addController(id){
      this.nodeCnt++;
      var nn = 'node-'+this.nodeCnt;
      
      var indx = this.controllers.findIndex(x => x.id===id);
      if (indx!=-1){

        this.$refs.diagram.addNode({
          id: 'node-'+this.nodeCnt,
          title: this.controllers[indx].title,
          size: this.controllers[indx].size,
          portsIn: this.controllers[indx].ports.in,
          portsOut: this.controllers[indx].ports.out,
          data:this.controllers[indx].data,
        }) 
      }
    },
    close(){
      this.$bvModal.msgBoxConfirm('Do you want to save the design?')
        .then(value => {
          if (value===true){
            // console.log('save')

          }
          this.$store.dispatch('chpge/changePage','controlEditor2')
        })
      
    }

  },
  mounted (){
    this.pageHeight(),
    this.init();
    this.GenerateList();
  },
  created() {
    window.addEventListener("resize", this.pageHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.pageHeight);
  },
  computed: {
    child2parent(){
      return this.$store.state.chpge.child2parent;
    },
  },
  watch: {
    child2parent(newValue){
      if (newValue.task === 'addCompenet'){
        this.nodeCnt++;
        var nn = 'node-'+this.nodeCnt;
        
        var indx = this.mynodes.findIndex(x => x.id===newValue.data);
        if (indx!=-1){

          this.$refs.diagram.addNode({
            id: 'node-'+this.nodeCnt,
            title: this.mynodes[indx].title,
            size: this.mynodes[indx].size,
            portsIn: this.mynodes[indx].ports.in,
            portsOut: this.mynodes[indx].ports.out,
            data:this.mynodes[indx].data,
          }) 
        }
        
        
      }
    }

  },
  
}
</script>

<style scoped>
.page_container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #3b5775;
}

.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 300px;
  background-color: #f1f1f1;
  border-right: 1px solid #252424;
  border-radius: 0 1rem 1rem 0;
  z-index: 1;
  transition:  0.2s;
}

.sidebar.expanded {
  width: 200px;
  border-radius: 0 1rem 1rem 0;

}

.icon {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 50%;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}
.iconexpanded{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */

}
.iconexpanded:hover{
  background-color: #ddd;
}
.icon:hover {
  background-color: #ddd;
}
.iconexpanded i {
  font-size: 20px;
  color: #333;
  width: 30%;
}

.iconexpanded span {
  width: 70%;

  font-size: 20px;
  color: #1170af;
  text-align: left;
}

.icon i {
  font-size: 20px;
  color: #333;
 width: 100%;

}
.icon span {
  width: 160px;
  height: 40px;
  font-size: 20px;
  color: #af1111;
}

.tooltip {
  /* position: absolute;
  top: -20px;
  left: 40px; */
  /* display: none; */
  padding: 5px;
  background-color: #333;
  color: #d62121;
  border-radius: 5px;
  font-size: 14px;
}

/* .icon:hover .tooltip {
  display: block;
} */

.sidebarRight {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50px;
  height: 100%;
  background-color: #4f6883;
  z-index: 1;
  transition:  0.2s;
}

.sidebarRight .nrmlItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  /* background-color: #db4242; */
  margin-left: 5px;
  border-radius: 50%;
  cursor: pointer;
    color: #fff;
  /* transition: all 0.2s ease-in-out; */
}

.sidebarRight .nrmlItem .base:hover {
  background-color: #fff;
  color: #4f6883;
}
.sidebarRight .slcdItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  /* margin-bottom: 10px; */
  margin-right: 10px;
  border-radius: 0 50% 50% 0;
  cursor: pointer;
  background-color: #e3e8f1;
  color: #1b89b4;
  /* transition: all 0.2s ease-in-out; */
}
.sidebarRight .slcdItem .edgeUp {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}
.sidebarRight .slcdItem .edgeUp1 {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 0 0 0 100% ;
  background-color: #4f6883;
}

.sidebarRight .slcdItem .edgeDn {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}

.sidebarRight .slcdItem .edgeDn1 {
  width: 15px;
  height: 15px;
  border-radius: 100% 0 0 0;
  background-color: #4f6883;
}

.settingPanel {
  position: absolute;
  top: 7px;
  bottom: 3px;
  right: 50px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 300px;

  background-color: #e3e8f1;

  border-radius: 1rem;
  z-index: 10000;
  transition:  0.2s;
}

.settingPanel .header {
  display: flex;
  align-items: center;
  justify-content: left;
  
  height: 6%;
  padding: 5px;
  width: 96%;
  margin-top: 5px;
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border: 1px solid #a9aaac; 
  border-radius: inherit;
  /* background-color: #12419777; */
  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .header .title {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  font-weight: bold;
  font-size: large;
  color: #4e81df;
}

.settingPanel .header .button {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settingPanel .button:hover {
  color: #ddd;
}

.settingPanel .body {
  /* display: flex; */
  /* align-items: flex-start;
  justify-content:flex-start;
  flex-direction: column; */
  height: 91%;
  width: 96%;
  margin-bottom: 5px;
  padding: 5px;
  /* margin: 5px; */
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit;
  background-color: #4e81df;
  overflow: auto;
  /* transition: all 0.2s ease-in-out; */
}

.settingPanel .body .add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  height: 5%;
  padding: 10px;
  width: 100%;
  /* margin-top: 5px; */
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit;
  background-color: #e1e5eb;
  /* transition: all 0.2s ease-in-out; */
}
.settingPanel .body .add i:hover {
color: #3b63a0;
cursor: pointer;
}
.settingPanel .body .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  height: 9%;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  /* margin-bottom: 10px;
  border-radius: 50%; */
  border-radius: inherit ;
  background-color: #3b63a0;
  color: #ddd;
  
  /* transition: all 0.2s ease-in-out; */
}
.settingPanel .body .item span {
  width: 70%;
}

.settingPanel .body .item i {
  cursor: pointer;
  font-size: large;
}

.settingPanel .body .item i:hover {
  color:rgb(0, 255, 255)
}
/* .editor {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  right: 0;
  bottom:0;
}
.toolbox {
  position: absolute;
  z-index: 6;

  border: 1px solid #65f7ea;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;

  left: 0.75%;

  top: 2.5%;
} */
</style>




































  

