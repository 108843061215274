<template>
    <div>
        <b-modal
        id="modal-addWidget"
        size="md"
        centered
        body-bg-variant="light"
        body-text-variant="dark"
        content-class="shadow"
        >
            <template #modal-header>
                <h5>Add Widget</h5>
                <i><font-awesome-icon icon="fas fa-xmark" class="close" @click="handleClose"/></i>  
            </template>

            <div>
                <div style="display:flex; flex-direction:row; justify-content: space-between;">
                    <label>
                        <input type="file" @change="uploadFile" ref="file">
                    </label>
                    <b-button  @click="submitFile" size="sm" class="mr-2" variant="outline-primary">upload
                    </b-button>
                </div>
  
            </div>

            <template #modal-footer >
                <div  style="display:flex; flex-direction:row; justify-content: space-around; width: 100% !important;" :style="{color: successful?'green':'red'}">
                    <i v-if="successful === false"><font-awesome-icon icon="fas fa-exclamation-triangle"/></i>  
                    <i>{{message}}</i>
                    <i v-if="successful === false"><font-awesome-icon icon="fas fa-exclamation-triangle"/></i> 
                </div>   
            </template>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        data() {
            return {
                file:'',
                successful: true,
                message: '',
                updateWLCnt:0
            }
        },
        computed: {
            updateWL() {
                return this.$store.state.chpge.child2parent;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        watch: {
            updateWL(newValue) {
                if (newValue.task === 'updateWidgetList'){
                    this.updateWLCnt = newValue.data; 
                }
            }
        },
        methods: {

            uploadFile() {
                this.file = this.$refs.file.files[0];
                console.log(this.file)
            },
            submitFile() {
                const formData = new FormData();
                formData.append('file', this.file);
                this.$store.dispatch('data/uploadControlBlocks', formData).then(
                                 Data => {                                 
                                        this.successful = true;
                                        this.message = Data.message
                                        this.$store.dispatch('chpge/child2parent', {task: 'updateWidgetList', data: this.updateWLCnt+1})
                                    },
                                    
                                    error => {
                                    this.message =
                                        (error.response && error.response.data && error.response.data.message) ||
                                        error.message ||
                                        error.toString();
                                    this.successful = false;
                                    // alert(this.message)
                                    }
                )
            },
            handleClose(){
                this.successful = true;
                this.message = '';
                this.$bvModal.hide('modal-addWidget')
            },
            

        }
        
    };
</script>


<style scoped>
    .close {
        color:#dc3545;
        cursor:pointer;
    }
    .close :hover{
        color:#9b9b9b;
    }
</style>