import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { chpge } from "./chpge.module";
import { data } from "./data.module";
import { panel } from "./panel.module";
import { processFlow } from "./processFlow.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    chpge,
    data,
    panel,
    processFlow,
  }
});
