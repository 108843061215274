<template>
  <div
    :key="reRender"
    :dir="dict.dir[lang]"
    class="container-fluid h-100 p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
  >
    <h5 class="p-4 m-0 bg-page-title">{{ dict.title[lang] }}</h5>
    <div class="card px-4 text-center h-100 bg-none" style="border: none">
      <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
        <li
          v-for="(item, index) in tabs"
          class="mga-tab-nav-item"
          @click="activeTab = item.value"
          :key="index"
        >
          <a
            class="mga-tab-nav-link"
            :class="{ active: activeTab === item.value }"
            :id="item.name"
            data-toggle="tab"
            role="tab"
            aria-controls="tab1"
            aria-selected="true"
          >
            {{ dict[item.value][lang] }}
          </a>
        </li>
      </ul>
      <div class="card-body h-100 p-2">
        <transition name="slide-fade" mode="out-in">
          <div class="tab-content h-100" :key="activeTab">
            <div v-if="activeTab === 'tab1'" class="h-100">
              <div fluid class="container-fluid p-0 h-100">
                <div class="row g-0 h-100">
                  <div class="col-xl-6 col-lg-6 col-sm-12 h-100 p-3">
                    <div class="card card-shadow mb-3">
                      <div
                        class="
                          card-body
                          d-flex
                          flex-column
                          justify-content-center
                          align-items-center
                        "
                      >
                        <b-avatar
                          :src="avatarUrl"
                          :text="`${user.fname ? user.fname[0] : ''} ${
                            user.lname ? user.lname[0] : ''
                          }`"
                          size="200px"
                          class="rounded-circle shadow-10 bg-alternate avatar"
                        ></b-avatar>

                        <div
                          class="
                            d-flex
                            justify-content-evenly
                            align-items-center
                          "
                          style="width: 30%"
                        >
                          <p @click="openUploadDialog" class="mt-2 text-primary">
                            <i>
                              <font-awesome-icon icon="fas fa-pencil" />
                            </i>
                          </p>
                          <a href="#" @click="removeAvatar">
                            <i>
                              <font-awesome-icon icon="fas fa-close" />
                            </i>
                          </a>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        ref="avatarInput"
                        style="display: none"
                        @change="uploadAvatar"
                      />
                    </div>

                    <div class="card card-shadow mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.username[lang] }}
                              </div>
                              <div class="col-9">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.username"
                                  :enabled="false"
                                  floatLabelType="Auto"
                                ></ejs-textbox>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.role[lang] }}
                              </div>
                              <div class="col-9">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.systemRole"
                                  :enabled="false"
                                  floatLabelType="Auto"
                                ></ejs-textbox>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.fname[lang] }}
                              </div>
                              <div class="col-9 d-flex">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.fname"
                                  :required="true"
                                  :showClearButton="true"
                                  :enabled="editfname"
                                ></ejs-textbox>
                                <span
                                  v-if="!editfname"
                                  @click="editFname"
                                  class="e-icons e-edit-6 mt-3 mx-3"
                                ></span>
                                <span
                                  v-if="editfname"
                                  @click="applyFname"
                                  class="e-icons mx-3 e-check text-success mt-3"
                                ></span>
                                <span
                                  v-if="editfname"
                                  @click="cancleFname"
                                  class="e-icons e-close text-danger mt-3"
                                ></span>
                                <div
                                  v-if="submitted && errors.has('username')"
                                  class="alert alert-danger"
                                  role="alert"
                                >
                                  {{ errors.first("username") }}
                                </div>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.lname[lang] }}
                              </div>
                              <div class="col-9 d-flex">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.lname"
                                  :required="true"
                                  :showClearButton="true"
                                  :enabled="editlname"
                                ></ejs-textbox>
                                <span
                                  v-if="!editlname"
                                  @click="editLname"
                                  class="e-icons e-edit-6 mt-3 mx-3"
                                ></span>
                                <span
                                  v-if="editlname"
                                  @click="applyLname"
                                  class="e-icons mx-3 e-check text-success mt-3"
                                ></span>
                                <span
                                  v-if="editlname"
                                  @click="cancleLname"
                                  class="e-icons e-close text-danger mt-3"
                                ></span>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.phone[lang] }}
                              </div>
                              <div class="col-9 d-flex">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.phoneNumber"
                                  :required="true"
                                  :showClearButton="true"
                                  :enabled="editphone"
                                ></ejs-textbox>
                                <span
                                  v-if="!editphone"
                                  @click="editPhone"
                                  class="e-icons e-edit-6 mt-3 mx-3"
                                ></span>
                                <span
                                  v-if="editphone"
                                  @click="applyPhone"
                                  class="e-icons mx-3 e-check text-success mt-3"
                                ></span>
                                <span
                                  v-if="editphone"
                                  @click="canclePhone"
                                  class="e-icons e-close text-danger mt-3"
                                ></span>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.email[lang] }}
                              </div>
                              <div class="col-9">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.email"
                                  :enabled="false"
                                ></ejs-textbox>
                              </div>
                            </div>

                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                                for="username"
                              >
                                {{ dict.timeBasedAccess[lang] }}
                              </div>

                              <div class="col-9 d-flex">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="user.settings.timeBasedAccess"
                                  :required="true"
                                  :showClearButton="true"
                                  :enabled="editLogout"
                                ></ejs-textbox>
                                <span
                                  v-if="!editLogout"
                                  @click="logoutEditBtn"
                                  class="e-icons e-edit-6 mt-3 mx-3"
                                ></span>
                                <span
                                  v-if="editLogout"
                                  @click="applyLogout"
                                  class="e-icons mx-3 e-check text-success mt-3"
                                ></span>
                                <span
                                  v-if="editLogout"
                                  @click="cancleLogout"
                                  class="e-icons e-close text-danger mt-3"
                                ></span>
                              </div>
                            </div>
                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                              >
                                <label
                                  class="form-check-label"
                                  for="flexCheckChecked"
                                >
                                  {{ dict.showPopUpNotifications[lang] }}
                                </label>
                              </div>
                              <div class="col-9">
                                <ejs-dropdownlist
                                  :dataSource="popupOption"
                                  :fields="{ text: lang, value: 'value' }"
                                  v-model="
                                    $store.state.data.user.settings
                                      .showPopUpNotifications
                                  "
                                  @change="changePopupSetting"
                                ></ejs-dropdownlist>
                              </div>
                            </div>
                            <div class="d-flex align-items-center mt-2">
                              <div
                                class="col-3"
                                :class="
                                  lang === 'per' ? 'text-end' : 'text-start'
                                "
                              >
                                <label
                                  class="form-check-label"
                                  for="flexCheckChecked"
                                >
                                  {{ dict.systemLang[lang] }}
                                </label>
                              </div>
                              <div class="col-9">
                                <!--                                default_language-->
                                <ejs-dropdownlist
                                  :dataSource="sysLang"
                                  :fields="{ text: lang, value: 'value' }"
                                  v-model="
                                    $store.state.data.user.settings['default_language']
                                  "

                                  @change="changeSystemLang"
                                ></ejs-dropdownlist>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-sm-12 h-100 p-3">
                    <div class="card card-shadow mb-3">
                      <div class="card-body">
                        <div class="col-12 p-2">
                          <div class="row">
                            <div class="col-lg-6 col-md-5 col-sm-12 ">
                              <div class="d-flex h-100 align-items-center justify-content-center">
                                <div class="fw-bold ">{{ dict.headerOption[lang] }}</div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-5 col-sm-12">
                              <button type="button" class="btn btn-secondary rounded-pill"
                                      @click="selectThemeColor('header',{bg:'bg-default-header', font:'dark'})">
                                {{ dict.restore[lang] }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="p-2 border rounded-3 bg-white">
                          <div v-for="(colorClass,i) in colorClasses1" :key="'hcc1-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('header',colorClass)"></div>
                          <hr>
                          <div v-for="(colorClass,i) in colorClasses2" :key="'hcc2-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('header',colorClass)"></div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-shadow mb-3">
                      <div class="card-body">
                        <div class="col-12 p-2">
                          <div class="row">
                            <div class="col-lg-6 col-md-5 col-sm-12 ">
                              <div class="d-flex h-100 align-items-center justify-content-center">
                                <div class="fw-bold ">{{ dict.sidebarOption[lang] }}</div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-5 col-sm-12">
                              <button type="button" class="btn btn-secondary rounded-pill"
                                      @click="selectThemeColor('sidebar',{bg:'bg-default-sidebar', font:'dark'})">
                                {{ dict.restore[lang] }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="p-2 border rounded-3 bg-white">
                          <div v-for="(colorClass,i) in colorClasses1" :key="'scc1-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('sidebar',colorClass)"></div>
                          <hr>
                          <div v-for="(colorClass,i) in colorClasses2" :key="'scc2-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('sidebar',colorClass)"></div>
                        </div>
                      </div>
                    </div>

                    <div class="card card-shadow mb-3">
                      <div class="card-body">
                        <div class="col-12 p-2">
                          <div class="row">
                            <div class="col-lg-6 col-md-5 col-sm-12 ">
                              <div class="d-flex h-100 align-items-center justify-content-center">
                                <div class="fw-bold ">{{ dict.pageOption[lang] }}</div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-5 col-sm-12">
                              <button type="button" class="btn btn-secondary rounded-pill"
                                      @click="selectThemeColor('page',{bg:'bg-default-page', font:'dark'})">
                                {{ dict.restore[lang] }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="p-2 border rounded-3 bg-white">
                          <div v-for="(colorClass,i) in colorClasses1" :key="'pcc1-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('page',colorClass)"></div>
                          <hr>
                          <div v-for="(colorClass,i) in colorClasses2" :key="'pcc2-'+i" class="swatch-holder"
                               :class="colorClass.bg" @click="selectThemeColor('page',colorClass)"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div v-if="activeTab === 'tab2'">
              <div class="container-fluid p-0 h-100">
                <div class="h-100 gap-2 mx-auto card card-shadow">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block mx-5 mt-4">
                    <li
                      v-for="(item, index) in subTabs"
                      class="mga-tab-nav-item"
                      @click="activeSubTab = item.value"
                      :key="index"
                    >
                      <a
                        class="mga-tab-nav-link"
                        :class="{ active: activeSubTab === item.value }"
                        :id="item.name"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {{ dict[item.value][lang] }}
                      </a>
                    </li>
                  </ul>
                  <div class="row my-4 px-md-0 gap-3 px-4" id="res_pass">
                    <div class="col-md-3 d-flex col-12 mx-auto">
                      <ejs-textbox
                        :autocomplete="'off'"
                        :minLength="8"
                        :required="true"
                        v-model="pass.currentPassword"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict.currentPass[lang]"
                        :showClearButton="true"
                        :type="showCurrent? 'text' :'password' "
                      ></ejs-textbox>
                      <span class="e-icons e-eye mt-2" v-if="!showCurrent" @click="showCurrent=!showCurrent"></span>
                      <span class="e-icons e-eye-slash mt-2" v-if="showCurrent"
                            @click="showCurrent=!showCurrent"></span>
                    </div>
                    <div class="col-md-3 d-flex col-12 mx-auto">
                      <ejs-textbox
                        :autocomplete="'off'"
                        :minLength="8"
                        :required="true"
                        v-model="pass.newPassword"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict.newPass[lang]"
                        :showClearButton="true"
                        :type="showNew? 'text' :'password' "
                      ></ejs-textbox>
                      <span class="e-icons e-eye mt-2" v-if="!showNew" @click="showNew=!showNew"></span>
                      <span class="e-icons e-eye-slash mt-2" v-if="showNew" @click="showNew=!showNew"></span>
                    </div>
                    <div class="col-md-3 d-flex col-12 mx-auto">
                      <ejs-textbox
                        :autocomplete="'off'"
                        :minLength="8"
                        :required="true"
                        v-model="pass.confirmNewPassword"
                        :enableRtl="lang == 'per'"
                        :placeholder="dict.repeatPass[lang]"
                        :showClearButton="true"
                        :type="showRep? 'text' :'password' "
                      ></ejs-textbox>
                      <span class="e-icons e-eye mt-2" v-if="!showRep" @click="showRep=!showRep"></span>
                      <span class="e-icons e-eye-slash mt-2" v-if="showRep" @click="showRep=!showRep"></span>
                    </div>
                  </div>
                  <button class="btn-primary btn-sm col-md-1 col-11 mx-auto mx-md-5 m-2" @click="submitReset">
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>

                    <span v-show="!loading"> {{ dict.submitPass[lang] }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <b-modal id="modal-text-coppied" hide-footer hide-header centered size="sm">
      <div class="my-modal-text">Message copied to clipboard!</div>
    </b-modal>

    <inviteUser />
  </div>
</template>

<script>
/* eslint-disable */
import inviteUser from "./workbenchModal.vue";
import { host } from "../models/host";
import ToggleBtn from "@/components/base/toggleBtn.vue";
import { FormValidator } from "@syncfusion/ej2-inputs";

// import jsonItemEditor  from './setContModalPubSubJson.vue';
// import statJsonItemEditor  from './setContMasterModalStatusJson.vue';
export default {
  name: "masterEdit",
  components: {
    ToggleBtn,
    inviteUser
  },
  data() {
    return {
      avatarUrl:'',
      reRender: 0,
      host: host,
      activeTab: "tab1",
      activeSubTab: "resetPass",
      id: "",
      name: "",
      parent: "",
      title: "",
      registred: true,
      loading: false,
      showCurrent: false,
      showNew: false,
      showRep: false,
      mapType: null,
      popupOption: [
        { per: "فعال", eng: "active", value: true },
        { per: "غیرفعال", eng: "deactive", value: false }
      ],
      sysLang: [
        { per: "انگلیسی", eng: "english", value: "eng" },
        { per: "فارسی", eng: "persian", value: "per" }
      ],
      tabs: [
        { name: "tab1", value: "tab1" },
        { name: "tab2", value: "tab2" }
      ],
      subTabs: [
        { name: "resetPass", value: "resetPass" }
      ],
      editfname: false,
      fname: "",

      editlname: false,
      lname: "",

      editphone: false,
      editLogout: false,
      phoneNumber: "",

      editname: false,
      editowner: false,
      workbenchLabel: "",
      workbenchOwner: {},

      usersFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "avatar", label: "", sortable: true },
        { key: "name", label: "Name", sortable: true, sortDirection: "asc" },
        { key: "username", label: "username", sortable: true },
        { key: "permission", label: "permission", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "modify", label: "" }
      ],
      permissionList: [
        { text: "admin", value: "admin" },
        { text: "user", value: "user" },
        { text: "guest", value: "guest" }
      ],

      submitted: false,
      successful: false,
      message: "",

      statTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        submitPass: { eng: "submit changes", per: "ذخیره تغییرات" },
        showPopUpNotifications: {
          eng: "show Notifications popup",
          per: "نمایش اعلان سیستمی"
        },
        systemLang: {
          eng: "default system language",
          per: "زبان پیش فرض سیستم"
        },
        title: { eng: "user account", per: "حساب کاربری" },
        tab1: { eng: "user profile", per: "مشخصات کاربر" },
        tab2: { eng: "security settings", per: "تنظیمات امنیتی" },
        headerOption: { eng: "Header options", per: "گزینه های سربرگ" },
        sidebarOption: { eng: "Sidebar options", per: "گزینه های نوار کناری" },
        pageOption: { eng: "Page options", per: "گزینه های صفحه" },
        restore: { eng: "Restore default", per: "بازیابی پیش فرض" },

        fname: { eng: "first name", per: "نام" },
        lname: { eng: "last name", per: "نام خانوادگی" },
        email: { eng: "email", per: "ایمیل" },
        phone: { eng: "phone number", per: "تلفن همراه" },
        username: { eng: "username", per: "نام کاربری" },
        timeBasedAccess: { eng: "logout time", per: "تنظیم زمان خارح شدن اتوماتیک سیستم" },
        role: { eng: "system role", per: "نقش سیستمی" },

        password: { eng: "password", per: "کلمه عبور" },

        passwordRetype: { eng: "Password Confirmation", per: "تایید کلمه عبور" },


        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: { eng: "invalid captcha", per: "کد امنیتی وارد شده اشتباه می باشد" },
        errUserPass1: { eng: "invalid username or password", per: "نام کاربری یا کلمه عبور اشتباه است" },
        errUserPass2: { eng: "check your keyboard language", per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد" },
        errUserPass3: { eng: "the password is caps sensitive", per: "• پسورد به حروف بزرگ و كوچك حساس است" },
        newPass: { eng: "new password", per: "رمزعبور جدید" },
        currentPass: { eng: "current password", per: "رمزعبور فعلی" },
        repeatPass: { eng: "repeat new password", per: "تکرار رمز عبور جدید" },
        resetPass: { eng: "reset password", per: "بازنشانی رمزعبور" }
      },
      themeBox: {
        light: {
          header: { bg: "bg-light", font: "black" },
          sidebar: { bg: "bg-light", font: "black" },
          page: { bg: "bg-light", font: "black" }
        },
        dark: {
          header: { font: "light", bg: "bg-dark" },
          sidebar: { font: "light", bg: "bg-dark" },
          page: { font: "light", bg: "bg-dark" }
        },
        dark_blue: {
          header: { font: "light", bg: "bg-royal" },
          sidebar: { font: "light", bg: "bg-royal" },
          page: { font: "light", bg: "bg-royal" }
        },
        megashid: {
          header: { font: "light", bg: "bg-blue", text: "white" },
          sidebar: { font: "light", bg: "bg-blue", text: "white" },
          page: { font: "light", bg: "bg-light", text: "black" }
        }
      },
      colorClasses1: [
        { bg: "bg-primary", font: "light" },
        { bg: "bg-secondary", font: "light" },
        { bg: "bg-success", font: "light" },
        { bg: "bg-info", font: "light" },
        { bg: "bg-warning", font: "dark" },
        { bg: "bg-danger", font: "light" },
        { bg: "bg-light", font: "dark" },
        { bg: "bg-dark", font: "light" },
        { bg: "bg-focus", font: "light" },
        { bg: "bg-alternate", font: "light" }
      ],
      colorClasses2: [
        { bg: "bg-vicious-stance", font: "light" },
        { bg: "bg-midnight-bloom", font: "light" },
        { bg: "bg-night-sky", font: "light" },
        { bg: "bg-slick-carbon", font: "light" },
        { bg: "bg-asteroid", font: "light" },
        { bg: "bg-royal", font: "light" },
        { bg: "bg-warm-flame", font: "dark" },
        { bg: "bg-night-fade", font: "dark" },
        { bg: "bg-sunny-morning", font: "dark" },
        { bg: "bg-tempting-azure", font: "dark" },
        { bg: "bg-amy-crisp", font: "dark" },
        { bg: "bg-heavy-rain", font: "dark" },
        { bg: "bg-mean-fruit", font: "dark" },
        { bg: "bg-malibu-beach", font: "light" },
        { bg: "bg-deep-blue", font: "dark" },
        { bg: "bg-ripe-malin", font: "light" },
        { bg: "bg-arielle-smile", font: "light" },
        { bg: "bg-plum-plate", font: "light" },
        { bg: "bg-happy-fisher", font: "dark" },
        { bg: "bg-happy-itmeo", font: "light" },
        { bg: "bg-mixed-hopes", font: "light" },
        { bg: "bg-strong-bliss", font: "light" },
        { bg: "bg-grow-early", font: "light" },
        { bg: "bg-love-kiss", font: "light" },
        { bg: "bg-premium-dark", font: "light" },
        { bg: "bg-happy-green", font: "light" }
      ],
      pass: {
        newPassword: "", currentPassword: "", confirmNewPassword: ""
      }
    };
  },
  computed: {
    workbench() {
      return this.$store.state.data.workbench;
    },
    creatorList() {
      if (this.workbench.users.length > 0) {
        var admins = this.workbench.users.filter(x => (x.permission === "admin"));
        // var adminList=[];
        // admins.forEach(x => adminList.push({ text: {fname: x.fname, lname: x.lname, avatar: x.avatar}, value: x.id }))
        return admins;
      }
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    user() {
      return this.$store.state.data.user;
    }

  },
  methods: {
    submitReset() {
      this.loading = true;
      const isValid = new FormValidator("#res_pass").validate();
      if (isValid) {
        this.$store.dispatch("data/resetPasswordPost", this.pass).then(() => {
          this.pass = {
            newPassword: "", currentPassword: "", confirmNewPassword: ""
          };
          this.loading = false;
          this.logOut();
        }).catch(() => {
          this.loading = false;
        });
      } else this.loading = false;

    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("data/removeUserData");
      this.$store.dispatch("chpge/changePage", "signin");
      this.$store.dispatch("panel/sseSubscription");
    },
    openUploadDialog() {
      this.$refs.avatarInput.click();
    },
    getAvatar() {
      this.$store
        .dispatch("data/getUsersAvatar", { id: this.user.id })
        .then((response) => {
          if (response != null) {
            this.avatarUrl= response
          }
        });
    },
    uploadAvatar(event) {
      const file = event.target.files[0];
      if (file) {
        // Create a FormData object to send the file
        const formData = new FormData();
        formData.append("avatar", file);
        formData.append("task", "upload");

        this.$store.dispatch("data/uploadUserAvatar", formData).then(
          Data => {
            this.successful = true;
            this.$store.dispatch("data/getUserData");
            this.getAvatar();
            this.$store.dispatch('data/updateAgain',1)
            this.message = Data;
            // this.$store.dispatch('chpge/child2parent', {task: 'updateCB', data: this.updateCBCnt+1})

          },

          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message)
          }
        );
      }
    },
    removeAvatar() {
      const formData = new FormData();
      formData.append("task", "remove");

      this.$store.dispatch("data/uploadUserAvatar", formData).then(
        Data => {
          this.successful = true;
          this.message = Data;
          this.$store.dispatch("data/getUserData");
        },

        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    selectThemeColor(key, value) {
      // Object.entries(item).forEach(i => {
      //   this.$store.dispatch("data/changeTheme", { key: i[0], value: i[1] })
      // });
      this.$store.dispatch("data/changeTheme", { key: key, value: value });
      var data = {};
      data[key + "Color"] = value.bg;
      data[key + "Font"] = value.font;

      this.$store.dispatch("data/editUserData", data).then(
        data => {
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );

    },
    getUsers() {
      this.$store.dispatch("data/getWBusers").then(
        data => {
          this.workbench.users = data;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    editFname() {
      this.fname = this.user.fname;
      this.editfname = true;
    },
    cancleFname() {
      this.user.fname = this.fname;
      this.editfname = false;
    },
    applyFname() {
      this.editfname = false;
      this.$store.dispatch("data/editUserData", { fname: this.user.fname }).then(
        data => {
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    editLname() {
      this.lname = this.user.lname;
      this.editlname = true;
    },
    cancleLname() {
      this.user.lname = this.lname;
      this.editlname = false;
    },
    applyLname() {
      this.editlname = false;
      this.$store.dispatch("data/editUserData", { lname: this.user.lname }).then(
        data => {
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    editPhone() {
      this.phoneNumber = this.user.phoneNumber;
      this.editphone = true;
    },
    logoutEditBtn() {
      this.editLogout = true;
    },
    canclePhone() {
      this.user.phoneNumber = this.phoneNumber;
      this.editphone = false;
    },
    cancleLogout() {
      this.editLogout = false;
    },
    applyPhone() {
      this.editphone = false;
      this.$store.dispatch("data/editUserData", { phoneNumber: this.user.phoneNumber }).then(
        data => {
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
          // console.log("rename workbench: ",data)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    applyLogout() {
      this.editLogout = false;
      this.$store.dispatch("data/editUserData", {
        settings: {
          timeBasedAccess: Number(this.user.settings.timeBasedAccess),
          showPopUpNotifications: Boolean(this.user.settings.showPopUpNotifications),
          default_language: this.user.settings.default_language
        }
      }).then(
        data => {
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
          // console.log("rename workbench: ",data)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    editWorkbenchLabel() {
      this.workbenchOwner = this.workbench.creator;
      this.editowner = true;
    },
    cancelWorkbenchLabel() {
      this.workbench.creator = this.workbenchOwner;
      this.editowner = false;
    },
    applyWorkbenchLabel() {
      this.editowner = false;
      var data = { id: this.workbench.id, creator: this.workbench.creator.id };

      this.$store.dispatch("data/changeWBowner", data).then(
        data => {
          this.$store.dispatch('data/updateAgain',1)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    statusTableOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.statTableOptions.totalRows = filteredItems.length;
      this.statTableOptions.currentPage = 1;
    },
    inviteUser() {
      this.$root.$emit("bv::show::modal", "modal-invite-user");
    },
    changeUserPermission(id, role) {
      this.$store.dispatch("data/editRoleWBusers", { id: id, role: role }).then(
        (response) => {
          this.message = response.message;
          this.$store.dispatch("data/getUserData");
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();

          // if ((this.message === 'err1')||(this.message === 'err2')||(this.message === 'err3')){
          //     this.submitted = false
          //     this.successful = false
          //     this.user = ''
          // }

        }
      );
    },
    removeUser(id) {
      this.$store.dispatch("data/removeWBusers", { id: id }).then(
        (response) => {
          // console.log("response", response);
          this.message = response.message;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();

          // if ((this.message === 'err1')||(this.message === 'err2')||(this.message === 'err3')){
          //     this.submitted = false
          //     this.successful = false
          //     this.user = ''
          // }

        }
      );
    },
    changePopupSetting(val) {
      this.$store
        .dispatch("data/editUserData", {
          settings: {
            "timeBasedAccess": Number(this.user.settings.timeBasedAccess),
            "default_language": this.user.settings.default_language,
            "showPopUpNotifications": val.value
          }
        })
        .then((data) => {
          // console.log(data);
          this.$store.dispatch("data/getUserData");
          this.$store.dispatch('data/updateAgain',1)
          // console.log("rename workbench: ",data)
        });
    },
    changeSystemLang(val) {
      this.$store
        .dispatch("data/editUserData", {
          settings: {
            "timeBasedAccess": Number(this.user.settings.timeBasedAccess),
            "showPopUpNotifications": this.user.settings.showPopUpNotifications,
            "default_language": val.value
          }
        })
        .then((data) => {
          this.$store.dispatch("chpge/changeLanguage", val.value);
          this.$store.dispatch('data/updateAgain',1)
          this.$store.dispatch("data/getUserData");
        });
    }
  },
  watch: {
    activeTab() {
      this.pass = {
        newPassword: "", currentPassword: "", confirmNewPassword: ""
      };
    },
    user(v) {
      this.reRender += 1;
    }
  },
  mounted() {
    this.getAvatar()
  }
};
</script>

<style scoped>
.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}

.my-modal-text {
  text-align: center;
  font-weight: bold;
  color: #198754;
  /* background-color: aliceblue !important; */
  padding: 20px;
}

/* .tab-content {
    overflow: hidden;
}

.tab-content > div {
    display: none;
    transition: transform 0.3s ease-in-out;
}

.tab-content > div.active {
    display: block;
    transform: translateX(0);
}

.tab-content > div.left-enter, .tab-content > div.left-leave-to {
    transform: translateX(-100%);
} */

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}


/* body{
    color: #6F8BA4;
    margin-top:20px;
}
.section {
    padding: 100px 0;
    position: relative;
} */
.gray-bg {
  background-color: #f5f5f5;
}

.green-bg {
  background-color: #b6f3cd;
}

img {
  max-width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

/* About Me
---------------------*/
.about-text h3 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 6px;
}

@media (max-width: 767px) {
  .about-text h3 {
    font-size: 35px;
  }
}

.about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .about-text h6 {
    font-size: 18px;
  }
}

.about-text p {
  font-size: 18px;
  max-width: 450px;
}

.about-text p mark {
  font-weight: 600;
  color: #20247b;
}

.about-list {
  padding-top: 10px;
}

.about-list .media {
  padding: 5px 0;
}

.about-list label {
  color: #20247b;
  font-weight: 600;
  width: 88px;
  margin: 0;
  position: relative;
}

.about-list label:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  width: 1px;
  height: 12px;
  background: #20247b;
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  margin: auto;
  opacity: 0.5;
}

.about-list p {
  margin: 0;
  font-size: 15px;
}

@media (max-width: 991px) {
  .about-avatar {
    margin-top: 30px;
  }
}

.about-section .counter {
  padding: 22px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}

.about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-section .counter .count {
  font-weight: 700;
  color: #20247b;
  margin: 0 0 5px;
}

.about-section .counter p {
  font-weight: 600;
  margin: 0;
}

mark {
  background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

.theme-color {
  color: #fc5356;
}

.dark-color {
  color: #20247b;
}


.swatch-holder {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 5px 5px 0;
  transition: all .2s;
  opacity: .7;
  display: inline-block;
  border-radius: 30px;
}

.swatch-holder:hover {
  cursor: pointer;
}
</style>
<style>
.workbench-tab .tab-content {
  height: 100%;
}

.no-header-table thead {
  display: none;
}
</style>

<style scoped
       src="@/assets/styles/theme.css">
</style>
