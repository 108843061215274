import axios from "axios";
import authHeader from "./auth-header";
import { host } from "../models/host";
import store from "../store";
import Vue from "vue";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";

const status = require("@/lacale/statusCode.json");
const err = require("@/lacale/errors.json");
var timer = null;
const API_URL = host + "/api/data/";


export const debounce = function(fn, delay) {
  clearTimeout(timer);
  timer = setTimeout(fn, delay);
};
axios.interceptors.request.use(
  function(config) {
    var timeLog = 1000000;
    debounce(() => {
      if (store.state.auth.status.loggedIn) {
        store.dispatch("chpge/changeLogoutTimeout", "1");
      }
      if (store.state.data.user.settings.timeBasedAccess)
        timeLog = store.state.data.user.settings.timeBasedAccess * 60;
    }, (timeLog - 32) * 1000); // this time should be at least 40s less than token expiration time setted in backend
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    if (response.status != 200) {
      ToastUtility.show({
        content: status[response.status][store.state.chpge.lang],
        timeOut: 3000,
        cssClass: "e-toast-success", // Add a custom class for Error styling
        position: { X: "Right", Y: "Bottom" }, // Adjust the position
        showCloseButton: true,
        enableRtl: store.state.chpge.lang == "per" ? true : false
      });
    }
    return response;
  },
  function(error) {
    if (error.response.data != "err19")
      ToastUtility.show({
        content: err[error.response.data][store.state.chpge.lang],
        timeOut: 3000,
        cssClass: "e-toast-danger", // Add a custom class for Error styling
        position: { X: "Center", Y: "Center" }, // Adjust the position
        showCloseButton: true,
        enableRtl: store.state.chpge.lang == "per" ? true : false
      });
    if (error == "Error: Network Error") {
      debounce(() => {
        store.dispatch("panel/closeSse");
        store.dispatch("auth/logout");
        store.dispatch("data/removeUserData");
        store.dispatch("chpge/changePage", "signin");
        ToastUtility.show({
          content: status.internalErr[store.state.chpge.lang],
          timeOut: 3000,
          cssClass: "e-toast-danger",
          position: { X: "Right", Y: "Bottom" },
          showCloseButton: true
        });
      }, 3000);
    }

    if (error.response && error.response.status != 411) {
      // ToastUtility.show({
      //   title: "Error",
      //   content: error.response.status,
      //   timeOut: 3000,
      //   cssClass: "e-toast-danger",
      //   position: { X: "Center", Y: "Top" },
      //   showCloseButton: true,
      // });

      if (error.response.status === 401) {
        store.dispatch("panel/closeSse");
        store.dispatch("auth/logout");
        store.dispatch("data/removeUserData");
        store.dispatch("chpge/changePage", "signin");
      } else if (error.response.status === 403) {
        Vue.notify({
          type: "alarm",
          title: "خطای دسترسی",
          text: " !شما دسترسی لازم به این صفحه را ندارید",
          duration: 5000,
          speed: 500
        });
      } else if (error.response.status == 408) {
        store.state.data.expired = false;
      }
    } else if (error.request) {
      console.error("Request error:", error.request);
    } else {
      console.error("Error:", error.message);
    }


    return Promise.reject(error);
  }
);

class DataService {
  getSSOUrl(data) {
    return axios.post(host + "/api/auth/getsso", data);
  }

  createMapLayers(formData) {
    return axios.post(API_URL + "maps/create", formData, {
      headers: authHeader()
    });

  }

  resetPassword(formData) {
    return axios.post(API_URL + "user/resetpassword", formData, {
      headers: authHeader()
    });

  }

  usersAvatar(formData) {
    return axios.post(API_URL + "users/getAvatarFile", formData, {
      headers: authHeader()
    });
  }

  WbAvatar(formData) {
    return axios.post(API_URL + "workbench/getAvatarFile", formData, {
      headers: authHeader()
    });
  }

  editMapLayers(formData) {
    return axios.post(API_URL + "maps/edit", formData, {
      headers: authHeader()
    });

  }

  removeMarker(formData) {
    return axios.post(API_URL + "maps/remove", formData, {
      headers: authHeader()
    });

  }

  inviteToWbAdmin(formData) {
    return axios.post(API_URL + "users/inviteForAdmin", formData, {
      headers: authHeader()
    });
  }

  uploadLicence(formData) {
    return axios.post(API_URL + "license/upload", formData, {
      headers: authHeader()
    });
  }

  uploadXlsxFile(formData) {
    return axios.post(API_URL + "archived/uploadxlsx", formData, {
      headers: authHeader()
    });
  }

  searchForXlsx(formData) {
    return axios.post(API_URL + "archived/getxlsx", formData, {
      headers: authHeader()
    });
  }

  checkLicenceValidate() {
    return axios.get(API_URL + "license/checking", {
      headers: authHeader()
    });
  }

  mainLicences() {
    return axios.get(API_URL + "license/getMainLicense", {
      headers: authHeader()
    });
  }

  getLicenceById(data) {
    return axios.post(API_URL + "license/getCurrentWorkBenchLicenseUsage", data, {
      headers: authHeader()
    });
  }

  setLicences(data) {
    return axios.post(API_URL + "license/setCurrentWorkBenchLicenseForAdmin", data, {
      headers: authHeader()
    });
  }

  getWbCurLicences(data) {
    return axios.post(API_URL + "license/getCurrentWorkBenchLicense", data, {
      headers: authHeader()
    });
  }

  setAdminSignUp(data) {
    return axios.post(API_URL + "settings/setFront", data, {
      headers: authHeader()
    });
  }

  getAdminSignUp() {
    return axios.get(API_URL + "settings/getFront", {
      headers: authHeader()
    });
  }

  getUsageLicence() {
    return axios.get(API_URL + "license/usageFromAll", {
      headers: authHeader()
    });
  }

  getAlltakhsis() {
    return axios.get(API_URL + "license/sumLicenseFromAll", {
      headers: authHeader()
    });
  }

  getMapLayers() {
    return axios.get(API_URL + "maps/getAll", {
      headers: authHeader()
    });
  }

  getSingleMap(data) {
    return axios.post(API_URL + "maps/get", data, {
      headers: authHeader()
    });
  }

  getAllCloakes() {
    return axios.get(API_URL + "settings/authmethod/getall", {
      headers: authHeader()
    });
  }

  getXlsxConfirm(data) {
    return axios.post(API_URL + "archived/getxlsxConfirmation", data, {
      headers: authHeader()
    });
  }

  createKeyCloak(data) {
    return axios.post(API_URL + "settings/authmethod/create", data, {
      headers: authHeader()
    });
  }

  createClassForData(data) {
    return axios.post(API_URL + "dataclass/create", data, {
      headers: authHeader()
    });
  }

  createEventType(data) {
    return axios.post(API_URL + "event/eventType/create", data, {
      headers: authHeader()
    });
  }

  createEventSetting(data) {
    return axios.post(API_URL + "eventSetting/create", data, {
      headers: authHeader()
    });
  }

  createEventRule(data) {
    return axios.post(API_URL + "event/eventRule/create", data, {
      headers: authHeader()
    });
  }

  createCategoryForData(data) {
    return axios.post(API_URL + "datacategories/create", data, {
      headers: authHeader()
    });
  }

  updateCategoryForData(data) {
    return axios.post(API_URL + "datacategories/update", data, {
      headers: authHeader()
    });
  }

  updateEventTypes(data) {
    return axios.put(API_URL + "event/eventType/update", data, {
      headers: authHeader()
    });
  }

  updateEventRules(data) {
    return axios.put(API_URL + "event/eventRule/update", data, {
      headers: authHeader()
    });
  }

  updateClassForData(data) {
    return axios.post(API_URL + "dataclass/update", data, {
      headers: authHeader()
    });
  }

  getAllClassForData() {
    return axios.get(API_URL + "dataclass/getAll", {
      headers: authHeader()
    });
  }

  getAllEventTyps() {
    return axios.get(API_URL + "event/eventType/getAll", {
      headers: authHeader()
    });
  }

  getAllEventRule() {
    return axios.get(API_URL + "event/eventRule/getAll", {
      headers: authHeader()
    });
  }

  getAllCategoryForData() {
    return axios.get(API_URL + "datacategories/getAll", {
      headers: authHeader()
    });
  }

  getAllCategoryListForData() {
    return axios.get(API_URL + "datacategories/getAllWithoutDatas", {
      headers: authHeader()
    });
  }


  getOneClassForData(data) {
    return axios.post(API_URL + "dataclass/get", data, {
      headers: authHeader()
    });
  }

  deleteClassForData(data) {
    return axios.post(API_URL + "dataclass/remove", data, {
      headers: authHeader()
    });
  }

  deleteCategoryForData(data) {
    return axios.post(API_URL + "datacategories/remove", data, {
      headers: authHeader()
    });
  }

  deleteEventTypes(data) {
    return axios.post(API_URL + "event/eventType/delete", data, {
      headers: authHeader()
    });
  }

  deleteEventRules(data) {
    return axios.post(API_URL + "event/eventRule/delete", data, {
      headers: authHeader()
    });
  }


  deleteKeyCloak(data) {
    return axios.post(API_URL + "settings/authmethod/delete", data, {
      headers: authHeader()
    });
  }

  editKeyCloak(data) {
    return axios.post(API_URL + "settings/authmethod/edit", data, {
      headers: authHeader()
    });
  }

  editKeyCloakStatus(data) {
    return axios.post(API_URL + "settings/authmethod/status", data, {
      headers: authHeader()
    });
  }

  getCloakesById(data) {
    return axios.post(API_URL + "settings/authmethod/getSingle", data, {
      headers: authHeader()
    });
  }

  getPermissionList() {
    return axios.get(API_URL + "roles/permissions/getall", {
      headers: authHeader()
    });
  }

  getRoles() {
    return axios.get(API_URL + "roles/getall", {
      headers: authHeader()
    });
  }

  getRolesAdmin(data) {
    return axios.post(API_URL + "roles/getallForAdmin", data, {
      headers: authHeader()
    });
  }

  getSysRoles() {
    return axios.get(API_URL + "roles/getSystemRoles", {
      headers: authHeader()
    });
  }

  getTheRole(data) {
    return axios.post(API_URL + "roles/sget", data, {
      headers: authHeader()
    });
  }

  editRole(data) {
    return axios.post(API_URL + "roles/edit", data, {
      headers: authHeader()
    });
  }

  getContConf() {
    return axios.get(API_URL + "cont/conf", {
      headers: authHeader()
    });
  }

  uploadContConf(topic) {
    return axios.post(
      API_URL + "cont/upload",
      {
        topic: topic
      },
      {
        headers: authHeader()
      }
    );
  }

  createMaster(data) {
    return axios.post(API_URL + "cont/master/create", data, {
      headers: authHeader()
    });
  }

  sendSettingMaster(data) {
    return axios.post(API_URL + "cont/master/sendstgreq", data, {
      headers: authHeader()
    });
  }

  applySettingMaster(data) {
    return axios.post(API_URL + "cont/master/applystg", data, {
      headers: authHeader()
    });
  }

  editMaster(data) {
    return axios.post(API_URL + "cont/master/edit", data, {
      headers: authHeader()
    });
  }

  removeMaster(data) {
    return axios.post(API_URL + "cont/master/remove", data, {
      headers: authHeader()
    });
  }

  getFileMaster(data) {
    return axios.post(API_URL + "cont/master/getfile", data, {
      headers: authHeader()
    });
  }

  createhttpRequest(data) {
    return axios.post(API_URL + "cont/httpserver/create", data, {
      headers: authHeader()
    });
  }

  createCloud(data) {
    return axios.post(API_URL + "cont/cloud/create", data, {
      headers: authHeader()
    });
  }

  editCloud(data) {
    return axios.post(API_URL + "cont/cloud/edit", data, {
      headers: authHeader()
    });
  }

  updateIngestion(data) {
    return axios.post(API_URL + "dataIngestion/update", data, {
      headers: authHeader()
    });
  }

  editHttpRequest(data) {
    return axios.post(API_URL + "cont/httpserver/edit", data, {
      headers: authHeader()
    });
  }

  removeCloud(data) {
    return axios.post(API_URL + "cont/cloud/remove", data, {
      headers: authHeader()
    });
  }

  removeRole(data) {
    return axios.post(API_URL + "roles/remove", data, {
      headers: authHeader()
    });
  }

  getFileCloud(data) {
    return axios.post(API_URL + "cont/cloud/getfile", data, {
      headers: authHeader()
    });
  }

  uploadFileCloud(formData) {
    return axios.post(API_URL + "cont/cloud/uploadfile", formData, {
      headers: authHeader()
      // headers: {
      //   ...authHeader(),
      //   'Content-Type': 'multipart/form-data'
      // }
    });
  }

  createHttps(data) {
    return axios.post(API_URL + "cont/https/create", data, {
      headers: authHeader()
    });
  }

  editHttps(data) {
    return axios.post(API_URL + "cont/https/edit", data, {
      headers: authHeader()
    });
  }

  removeHttps(data) {
    return axios.post(API_URL + "cont/https/remove", data, {
      headers: authHeader()
    });
  }

  removeHttpServer(data) {
    return axios.post(API_URL + "cont/httpserver/remove", data, {
      headers: authHeader()
    });
  }

  getFileHttps(data) {
    return axios.post(API_URL + "cont/https/getfile", data, {
      headers: authHeader()
    });
  }

  createSlave(data) {
    return axios.post(API_URL + "cont/slave/create", data, {
      headers: authHeader()
    });
  }

  editSlave(data) {
    return axios.post(API_URL + "cont/slave/edit", data, {
      headers: authHeader()
    });
  }

  removeSlave(data) {
    return axios.post(API_URL + "cont/slave/remove", data, {
      headers: authHeader()
    });
  }

  createSlot(data) {
    return axios.post(API_URL + "cont/slot/create", data, {
      headers: authHeader()
    });
  }

  editSlot(data) {
    return axios.post(API_URL + "cont/slot/edit", data, {
      headers: authHeader()
    });
  }

  removeSlot(data) {
    return axios.post(API_URL + "cont/slot/remove", data, {
      headers: authHeader()
    });
  }

  // getContConf() {
  //   return axios.get(API_URL + 'cont/conf', {
  //       headers: authHeader(),
  //     })
  // }

  // uploadContConf(topic) {
  //   return axios.post(API_URL + 'cont/upload', {
  //     topic: topic,
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // createMaster(topic) {
  //   return axios.post(API_URL + 'cont/master/create', {
  //     topic: topic,
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // editMaster(master) {
  //   return axios.post(API_URL + 'cont/master/edit', {
  //     topic: master.topic,
  //     oldTopic: master.oldTopic,
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // removeMaster(topic) {
  //   return axios.post(API_URL + 'cont/master/remove', {
  //     topic: topic,
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // createSlave(slave) {
  //   return axios.post(API_URL + 'cont/slave/create', {
  //     topic: slave.topic,
  //     name: slave.name,
  //     Id: slave.Id,
  //     uuid: slave.uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // editSlave(slave) {
  //   return axios.post(API_URL + 'cont/slave/edit', {
  //     // topic: slave.topic,
  //     name: slave.name,
  //     Id: slave.Id,
  //     uuid: slave.uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // removeSlave(uuid) {
  //   return axios.post(API_URL + 'cont/slave/remove', {
  //     // topic: slave.topic,
  //     // name: slave.name,
  //     // Id: slave.Id,
  //     uuid: uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // createSlot(slot) {
  //   return axios.post(API_URL + 'cont/slot/create', {
  //     slave: slot.slave,
  //     name: slot.name,
  //     address: slot.address,
  //     position: slot.position,
  //     channels: slot.channels,
  //     onDelay: slot.onDelay,
  //     uuid: slot.uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // editSlot(slot) {
  //   return axios.post(API_URL + 'cont/slot/edit', {
  //     //slave: slot.slave,
  //     name: slot.name,
  //     address: slot.address,
  //     position: slot.position,
  //     channels: slot.channels,
  //     onDelay: slot.onDelay,
  //     uuid: slot.uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }

  // removeSlot(uuid) {
  //   return axios.post(API_URL + 'cont/slot/remove', {
  //     // slave: slot.slave,
  //     // name: slot.name,
  //     // address: slot.address,
  //     // position: slot.position,
  //     // channels: slot.channels,
  //     // onDelay: slot.onDelay,
  //     uuid: uuid
  //   },
  //   {
  //     headers: authHeader(),
  //   });
  // }
  //===============================
  uploadMap(formData) {
    return axios.post(API_URL + "maps/uploadMap", formData, {
      headers: authHeader()
    });
  }

  uploadbackUp(formData) {
    return axios.post(API_URL + "backup/restore", formData, {
      headers: authHeader()
    });
  }

  getBuildConf() {
    return axios.get(API_URL + "build/conf", {
      headers: authHeader()
    });
  }

  getBackups() {
    return axios.get(API_URL + "backup/create", {
      headers: authHeader()
    });
  }

  getfloorMap(floor) {
    return axios.post(API_URL + "build/floor/getMap", floor, {
      headers: authHeader()
    });
  }

  getfloorScenarios(floor) {
    return axios.post(
      API_URL + "build/floor/getScenario",
      {
        floor: floor
      },
      {
        headers: authHeader()
      }
    );
  }

  setfloorScenario(data) {
    return axios.post(API_URL + "build/floor/setScenario", data, {
      headers: authHeader()
    });
  }

  editFloor(floor) {
    return axios.post(API_URL + "maps/edit", floor, {
      headers: authHeader()
    });
  }

  removeFloor(floor) {
    return axios.post(API_URL + "build/floor/remove", floor, {
      headers: authHeader()
    });
  }

  editZone(zone) {
    return axios.post(API_URL + "build/zone/edit", zone, {
      headers: authHeader()
    });
  }

  getZone(zone) {
    return axios.post(API_URL + "build/zone/get", zone, {
      headers: authHeader()
    });
  }

  //===============================
  toggleGroup(data) {
    return axios.post(API_URL + "group/chgStatus", data, {
      headers: authHeader()
    });
  }

  toggleSwitch(data) {
    return axios.post(API_URL + "feeder/chgStatus", data, {
      headers: authHeader()
    });
  }

  getEquipments() {
    return axios.get(API_URL + "feeder/getAll", {
      headers: authHeader()
    });
  }

  getEquipmentsbyLoc(loc) {
    return axios.post(API_URL + "feeder/getbyLoc", loc, {
      headers: authHeader()
    });
  }

  getEquipmentsofFloor(floor) {
    return axios.post(API_URL + "feeder/getbyFloor", floor, {
      headers: authHeader()
    });
  }

  getPanelsofFloor(floor) {
    return axios.post(API_URL + "feeder/getPanels", floor, {
      headers: authHeader()
    });
  }

  createEquipment(equipment) {
    return axios.post(API_URL + "feeder/create", equipment, {
      headers: authHeader()
    });
  }

  editEquipment(equipment) {
    return axios.post(
      API_URL + "feeder/edit",
      equipment,

      {
        headers: authHeader()
      }
    );
  }

  removeEquipment(id) {
    return axios.post(
      API_URL + "feeder/remove",
      {
        id: id
      },
      {
        headers: authHeader()
      }
    );
  }

  //===============================
  getAllData(data) {
    return axios.post(API_URL + "edge/getAll", data, {
      headers: authHeader()
    });
  }

  createData(data) {
    return axios.post(API_URL + "edge/create", data, {
      headers: authHeader()
    });
  }

  updateData(data) {
    return axios.post(API_URL + "edge/update", data, {
      headers: authHeader()
    });
  }

  removeData(data) {
    return axios.post(API_URL + "edge/remove", data, {
      headers: authHeader()
    });
  }

  getData(id) {
    return axios.post(API_URL + "edge/getData", id, {
      headers: authHeader()
    });
  }

  setData(data) {
    return axios.post(API_URL + "edge/setData", data, {
      headers: authHeader()
    });
  }

  // createEdge(edge) {
  //   return axios.post(API_URL + 'edge/create',
  //   edge,
  //   {
  //     headers: authHeader(),
  //   });
  // }
  // createRecord(record) {
  //   return axios.post(API_URL + 'record/create',
  //   record,
  //   {
  //     headers: authHeader(),
  //   });
  // }

  //===============================
  getGroups() {
    return axios.get(API_URL + "group/getAll", {
      headers: authHeader()
    });
  }

  getGroupsofFloor(floor) {
    return axios.post(API_URL + "group/getbyFloor", floor, {
      headers: authHeader()
    });
  }

  createGroup(group) {
    return axios.post(API_URL + "group/create", group, {
      headers: authHeader()
    });
  }

  editGroup(group) {
    return axios.post(
      API_URL + "group/edit",
      group,

      {
        headers: authHeader()
      }
    );
  }

  removeGroup(_id) {
    return axios.post(
      API_URL + "group/remove",
      {
        _id: _id
      },
      {
        headers: authHeader()
      }
    );
  }

  //===============================
  getScenarios() {
    return axios.get(API_URL + "scenario/getAll", {
      headers: authHeader()
    });
  }

  createScenario(scenario) {
    return axios.post(API_URL + "scenario/create", scenario, {
      headers: authHeader()
    });
  }

  editScenario(scenario) {
    return axios.post(
      API_URL + "scenario/edit",
      scenario,

      {
        headers: authHeader()
      }
    );
  }

  removeScenario(uuid) {
    return axios.post(
      API_URL + "scenario/remove",
      {
        uuid: uuid
      },
      {
        headers: authHeader()
      }
    );
  }

  //===============================
  createDashboard(dashboard) {
    return axios.post(API_URL + "dashboard/create", dashboard, {
      headers: authHeader()
    });
  }

  addDashboardItem(item) {
    return axios.post(API_URL + "dashboard/addItem", item, {
      headers: authHeader()
    });
  }

  removeDashboardItem(item) {
    return axios.post(API_URL + "dashboard/removeItem", item, {
      headers: authHeader()
    });
  }

  removeAllXslx(item) {
    return axios.post(API_URL + "archived/deleteAll", item, {
      headers: authHeader()
    });
  }

  removeSelectedXslx(item) {
    return axios.post(API_URL + "archived/deleteSelections", item, {
      headers: authHeader()
    });
  }

  editDashboard(dashboard) {
    return axios.post(API_URL + "dashboard/edit", dashboard, {
      headers: authHeader()
    });
  }

  getDashboard(data) {
    return axios.post(API_URL + "dashboard/get", data, {
      headers: authHeader()
    });
  }

  getWidgetsList() {
    return axios.get(API_URL + "dashboard/getWidgetsList", {
      headers: authHeader()
    });
  }

  getWidgetFile(data) {
    return axios.post(API_URL + "dashboard/getWidgetFile", data, {
      headers: authHeader()
    });
  }

  setRecordSettings(data) {
    return axios.post(API_URL + "recordsettings/set", data, {
      headers: authHeader()
    });
  }

  getRecordSettings() {
    return axios.get(API_URL + "recordsettings/get", {
      headers: authHeader()
    });
  }

  getChartData(data) {
    return axios.post(API_URL + "dashboard/chart/get", data, {
      headers: authHeader()
    });
  }

  getHistoricalData(data) {
    return axios.post(API_URL + "dataIngestion/create", data, {
      headers: authHeader()
    });
  }

  getsingleIngestion(data) {
    return axios.post(API_URL + "dataIngestion/getData", data, {
      headers: authHeader()
    });
  }

  getDataIngestion() {
    return axios.get(API_URL + "dataIngestion/getAll", {
      headers: authHeader()
    });
  }

  getControlBlocks(data) {
    return axios.get(API_URL + "controlManager/controlBlocks/get", {
      headers: authHeader()
    });
  }

  addControlBlocks(data) {
    return axios.post(API_URL + "controlBlocks/add", data, {
      headers: authHeader()
    });
  }

  uploadControlBlocks(formData) {
    return axios.post(API_URL + "controlManager/controlBlocks/upload", formData, {
      headers: authHeader()
    });
  }

  nodeHandler(data) {
    return axios.post(API_URL + "controlManager/node/set", data, {
      headers: authHeader()
    });
  }

  getNodeData(data) {
    return axios.post(API_URL + "node/getData", data, {
      headers: authHeader()
    });
  }

  linkHandler(data) {
    return axios.post(API_URL + "controlManager/link/set", data, {
      headers: authHeader()
    });
  }

  eventGetCur(data) {
    return axios.get(API_URL + "event/event/getAll", {
      headers: authHeader()
    });
  }

  eventGetArc(data) {
    return axios.post(API_URL + "event/event/getArc", data, {
      headers: authHeader()
    });
  }

  eventAcknowledge(data) {
    return axios.post(API_URL + "event/event/acknowledge", data, {
      headers: authHeader()
    });
  }

  eventRemove(data) {
    return axios.post(API_URL + "event/remove", data, {
      headers: authHeader()
    });
  }

  ingestionRemove(data) {
    return axios.post(API_URL + "dataIngestion/delete", data, {
      headers: authHeader()
    });
  }

  ingestionCancel(data) {
    return axios.post(API_URL + "dataIngestion/cancel ", data, {
      headers: authHeader()
    });
  }

  getWBdata() {
    return axios.get(API_URL + "workbench/get", {
      headers: authHeader()
    });
  }

  getWBdataList() {
    return axios.get(API_URL + "workbench/getall", {
      headers: authHeader()
    });
  }

  getUsersAdminataList() {
    return axios.get(API_URL + "users/getall", {
      headers: authHeader()
    });
  }

  changeWBlabel(data) {
    return axios.post(API_URL + "workbench/rename", data, {
      headers: authHeader()
    });
  }

  editWBProfile(formData) {
    return axios.post(API_URL + "workbench/profile", formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });
  }

  changeWBowner(data) {
    return axios.post(API_URL + "workbench/owner", data, {
      headers: authHeader()
    });
  }

  createWB(WB) {
    return axios.post(API_URL + "workbench/create", WB, {
      headers: authHeader()
    });
  }

  selectWB(WB) {
    return axios.post(API_URL + "workbench/select", WB, {
      headers: authHeader()
    });
  }

  getUserData(user) {
    return axios.get(API_URL + "user/get", {
      headers: authHeader()
    });
  }

  editUserData(user) {
    return axios.post(API_URL + "user/edit", user, {
      headers: authHeader()
    });
  }

  uploadUserAvatar(formData) {
    return axios.post(API_URL + "user/ulavatar", formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
    });
  }

  getWBusers(data) {
    return axios.get(API_URL + "users/get", {
      headers: authHeader()
    });
  }


  getDataSSE(data) {
    return axios.post(API_URL + "edge/getDataForSSE", data, {
      headers: authHeader()
    });
  }

  inviteWBusers(data) {
    return axios.post(API_URL + "users/invite", data, {
      headers: authHeader()
    });
  }

  removeWBusers(data) {
    return axios.post(API_URL + "users/remove", data, {
      headers: authHeader()
    });
  }

  removeWBuserAdmins(data) {
    return axios.post(API_URL + "users/removeForAdmin", data, {
      headers: authHeader()
    });
  }

  removeUserFromAll(data) {
    return axios.post(API_URL + "users/removeFromSystemForAdmin", data, {
      headers: authHeader()
    });
  }

  transferAdminRole(data) {
    return axios.post(API_URL + "users/transferAdministrator\n", data, {
      headers: authHeader()
    });
  }

  editRoleWBusers(data) {
    return axios.post(API_URL + "users/editrole", data, {
      headers: authHeader()
    });
  }

  editRoleWBAdmin(data) {
    return axios.post(API_URL + "users/editroleForAdmin", data, {
      headers: authHeader()
    });
  }

  editRoleSystem(data) {
    return axios.post(API_URL + "users/edituserasadmin", data, {
      headers: authHeader()
    });
  }

  respInviteWBusers(data) {
    return axios.post(API_URL + "users/rsp2inv", data, {
      headers: authHeader()
    });
  }

  // processFlow APIs========================================
  createNewProcess(data) {
    return axios.post(API_URL + "processflow/create", data, {
      headers: authHeader()
    });
  }

  createNewRole(data) {
    return axios.post(API_URL + "roles/create", data, {
      headers: authHeader()
    });
  }

  createNewRoleAdmin(data) {
    return axios.post(API_URL + "roles/createForAdmin", data, {
      headers: authHeader()
    });
  }

  editProcess(data) {
    return axios.post(API_URL + "processflow/edit", data, {
      headers: authHeader()
    });
  }

  getProcessList(data) {
    return axios.get(API_URL + "processflow/getall", {
      headers: authHeader()
    });
  }

  removeProcess(data) {
    return axios.post(API_URL + "processflow/remove", data, {
      headers: authHeader()
    });
  }

  getProcessById(data) {
    return axios.post(API_URL + "processflow/sget", data, {
      headers: authHeader()
    });
  }

  // editEquipment(equipment) {
  //   return axios.post(API_URL + 'feeder/edit',
  //     equipment,

  //   {
  //     headers: authHeader(),
  //   });
  // }
}

export default new DataService();
