<template>
  <div>
    <ejs-dropdownlist
      :dataSource="item"
      v-model="portType"
      :fields="{ text: 'text', value: 'value' }"
      :placeholder="dict.portType[lang]"
      :enableRtl="lang === 'per'"
      :required="true"
      floatLabelType="Always"
    ></ejs-dropdownlist>
  </div>
</template>

<script>
export default {
  name: "portTypeSetter",
  props: {
    value: {
      default: "in"
    }
  },
  data() {
    return {
      item: [
        { text: "input", value: "in" },
        { text: "output", value: "out" }
      ],
      portType: "in",
      dict: {
        portType: { eng: "port type", per: "نوع پورت" }
      }
    };
  },
  watch: {
    portType: {
      handler() {
        console.log(this.portType);
        this.$emit("input", this.portType);
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<style scoped></style>
