<template>
  <div>
    <b-modal
      id="modal-setDashMap"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <!-- <b-container fluid="md"> -->
        <!-- <div style="padding: 0px 20px 0px 20px;"> -->
        <b-list-group flush>
          <b-list-group-item
            style="background-color: transparent; color: black"
          >
            <div style="display: flex; flex-direction: row">
              <!-- <b-form-group
                                    label="Device Title"
                                    label-for="title"                                            
                                    :invalid-feedback="errors.first('title')"
                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                >
                                    <b-form-input
                                        name="title"
                                        type="text"
                                        placeholder="Enter title"
                                        v-model="device.name"
                                        :state="(submitted)? (!errors.has('title')): null"  
                                        v-validate="'required|min:2|max:20'"
                                        style="width:100%"
                                    ></b-form-input>
                                </b-form-group>   -->

              <b-form-group
                label="Map Title"
                label-for="data"
                :invalid-feedback="errors.first('data')"
                style="width: 100%; padding: 0px 10px 10px 0px"
              >
                <b-form-select
                  style="
                    font-variant: small-caps;
                    width: 100%;
                    height: 100%;
                    padding: 0px;
                  "
                  name="data"
                  size="lg"
                  v-model="map.data"
                  :options="mapList"
                  :state="submitted ? !errors.has('data') : null"
                  v-validate="'required'"
                ></b-form-select>
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
        <!-- </div> -->
        <!-- </b-container> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        props: {
            pid: String
        },
        
        data() {
            return {
                modalTitle:'',
                map:{
                    data:null,
                    id:'',
                },
                component:{},
                // id:'',
                type:'',
                mapList:[],
                
                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
            // child2parent() {
            //     return this.$store.state.chpge.child2parent;
            // }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='map_modal'){
                    this.db = newValue.data
                    this.type = newValue.type
                }
            },
            // child2parent(newValue, oldValue) {
            //     if (newValue.task==='dataSel'){
            //         this.gauge.members[newValue.index].did = newValue.id
            //     }
            // }
        },
        methods: {
            initModal: function(){
                this.modalTitle = this.type + ' Map'
                if (this.type==='Edit')
                {
                    this.component._id         = this.db._id;
                    this.component.name        = this.db.name;
                    this.component.data        = this.db.data? this.db.data:{};
                    this.component.attribute   = this.db.attribute? this.db.attribute:{};

                    this.db.data       = this.db.data? this.db.data:{};
                    this.map.id      = this.db.data.map? this.db.data.map.id:'';
                    // this.map.name    = this.db.data.map? this.db.data.map.name:'';
                    this.map.data    = this.db.data.map? this.db.data.map.data:null;
                }
                this.getBuildConf()

            },
            resetModal: function(){
                this.component.attribute = {};
                this.component.data = {};
                this.component._id = '';
                this.component.name = '';

                this.map.id='',
                // this.map.name='',
                this.map.data=null,
                
                // this.groups=[],
                this.id=''
                this.type=''

                this.submitted = null;
                this.successful = null;
                this.message= null;

                // this.$store.dispatch('chpge/parent2child', {task: 'close_group_modal'})
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.component.data = {map: JSON.parse(JSON.stringify(this.map))}
                        this.component.attribute = {}

                        this.$store.dispatch('chpge/child2parent', {task: 'setDashboard', type:'Edit', data: this.component})
                        
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal-setDashMap')
                        })
                    }
                });
            },
            
            getBuildConf() {
                this.$store.dispatch('data/getBuildConf').then(
                    data => {
                        this.mapList =  [{ text: 'یک نقشه انتخاب کنید', value: null }];
                        for (var map of data){
                            this.mapList.push({text: map.floorTitle? map.floorTitle:map._id , value:map._id})//floorId
                        }
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
            getEquipments() {
                this.$store.dispatch('data/getEquipments').then(
                    data => {
                        for (var equipment of data){
                            this.dataList.push({text: equipment.title, value:{id: equipment.id.feeder}})
                        }
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },


        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .panel_header {
        padding: 10px;
        height: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-variant:small-caps;
        border-bottom: 2px solid rgb(210, 210, 210);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .panel_col {
        height: 100%; 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>