<template>
  <div style="text-align: left">
    <div>
      <b-container fluid>
        <b-row class="my-4">
          <b-col cols="12" sm>
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col cols="8" lg="11" md="10" sm="9">
                    <h5 style="flex: 90%">{{ title }}</h5>
                  </b-col>
                  <b-col cols="4" lg="1" md="2" sm="3">
                    <div
                      style="
                        font-size: 24px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <i  @click="handleRegister"
                        ><font-awesome-icon
                          icon="fas fa-check-square"
                          class="apply"
                      /></i>
                      <i @click="onReset"
                        ><font-awesome-icon
                          icon="fas fa-xmark-square"
                          class="reset"
                      /></i>
                    </div>
                  </b-col>
                </b-row>

                <hr class="my-2" />

                <b-card no-body>
                  <b-tabs card v-if="registred">
                    <b-tab title="Basic Information" active>
                      <b-list-group horizontal="md">
                        <b-form-group
                          label="name"
                          label-for="name"
                          :invalid-feedback="errors.first('name')"
                          style="width: 100%; padding: 0px 10px 10px 0px"
                        >
                          <b-form-input
                            disabled
                            name="name"
                            type="text"
                            placeholder="Enter a name"
                            v-model="name"
                            :state="submitted ? !errors.has('name') : null"
                            v-validate="'required|min:6|max:50'"
                            style="width: 100%"
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Mapping Type"
                          label-for="mapType"
                          :invalid-feedback="errors.first('mapType')"
                          style="width: 100%; padding: 0px 10px 10px 0px"
                        >
                          <b-form-select
                            disabled
                            style="
                              font-variant: small-caps;
                              width: 100%;
                              height: 100%;
                              padding: 0px;
                            "
                            name="mapType"
                            size="lg"
                            v-model="mapType"
                            :options="mapTypeList"
                            :state="submitted ? !errors.has('mapType') : null"
                            v-validate="'required'"
                          ></b-form-select>
                        </b-form-group>
                      </b-list-group>

                      <b-list-group horizontal="md">
                        <b-form-group
                          label="host"
                          label-for="host"
                          :invalid-feedback="errors.first('host')"
                          style="width: 100%; padding: 0px 10px 10px 0px"
                        >
                          <b-form-input
                            name="host"
                            type="text"
                            placeholder="Enter broker's IP or URL"
                            v-model="connection.host"
                            :state="submitted ? !errors.has('host') : null"
                            v-validate="'required|min:3|max:20'"
                            style="width: 100%"
                          ></b-form-input>
                        </b-form-group>
                        <!-- </b-list-group>

                                            <b-list-group horizontal="md"> -->
                        <b-form-group
                          label="port"
                          label-for="port"
                          :invalid-feedback="errors.first('port')"
                          style="width: 100%; padding: 0px 10px 10px 0px"
                        >
                          <b-form-input
                            name="port"
                            type="text"
                            placeholder="Enter broker's port"
                            v-model="connection.port"
                            :state="submitted ? !errors.has('port') : null"
                            v-validate="'required|min:3|max:20'"
                            style="width: 100%"
                          ></b-form-input>
                        </b-form-group>
                      </b-list-group>

                      <b-list-group horizontal="md">
                        <b-form-group
                          label="client Id"
                          label-for="clientId"
                          :invalid-feedback="errors.first('clientId')"
                          style="width: 100%; padding: 0px 10px 10px 0px"
                        >
                          <b-form-input
                            name="client Id"
                            type="text"
                            placeholder="Enter the client Id given by cloud"
                            v-model="connection.clientId"
                            :state="submitted ? !errors.has('clientId') : null"
                            v-validate="'required|min:3|max:20'"
                            style="width: 100%"
                          ></b-form-input>
                        </b-form-group>

                        <!-- <b-form-group style="width:100%; padding: 27px 10px 10px 0px;">
                                                    <b-form-checkbox v-model="connection.idPrefix" name="check-uponChange" switch >
                                                        Prefix Client Id to Topics
                                                    </b-form-checkbox>
                                                </b-form-group> -->
                      </b-list-group>
                    </b-tab>
                    <b-tab title="Security">
                      <b-form-group>
                        <b-form-radio-group
                          v-model="security.type"
                          class="
                            d-flex
                            justify-content-around
                            fw-bolder
                            text-center
                            border
                            rounded
                            p-1
                            mb-2
                            text-dark
                          "
                          style="
                            background-color: #a5b4fc;
                            width: 100%;
                            border-color: #a5b4fc !important;
                          "
                          name="security"
                          v-validate="'required'"
                        >
                          <b-form-radio value="anony">Anonymous</b-form-radio>
                          <b-form-radio value="basic" class="ml-5"
                            >Basic</b-form-radio
                          >
                          <b-form-radio value="cert" class="ml-5"
                            >Certificate</b-form-radio
                          >
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="submitted ? !errors.has('security') : null"
                          style="display: block !important"
                        >
                          <span> {{ errors.first("security") }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <b-collapse v-model="isSecClpsd1">
                        <b-card>
                          <p>
                            این گزینه از امنیت پایینی برخوردار است و توصیه نمی
                            شود
                          </p>
                        </b-card>
                      </b-collapse>

                      <b-collapse v-model="isSecClpsd2">
                        <b-card>
                          <b-list-group horizontal="md">
                            <b-form-group
                              label="user"
                              label-for="user"
                              :invalid-feedback="errors.first('user')"
                              style="width: 100%; padding: 0px 10px 10px 0px"
                            >
                              <b-form-input
                                :disabled="!isSecClpsd2"
                                name="user"
                                type="text"
                                placeholder="Enter username given by cloud"
                                v-model="security.basic.user"
                                :state="submitted ? !errors.has('user') : null"
                                v-validate="'required|min:3|max:50'"
                                style="width: 100%"
                              ></b-form-input>
                            </b-form-group>

                            <b-form-group
                              label="password"
                              label-for="pass"
                              :invalid-feedback="errors.first('pass')"
                              style="width: 100%; padding: 0px 10px 10px 0px"
                            >
                              <b-form-input
                                :disabled="!isSecClpsd2"
                                name="pass"
                                type="text"
                                placeholder="Enter password given by cloud"
                                v-model="security.basic.pass"
                                :state="submitted ? !errors.has('pass') : null"
                                v-validate="'required|min:3|max:50'"
                                style="width: 100%"
                              ></b-form-input>
                            </b-form-group>

                            <!-- <div style="padding:27px 10px 10px 0;">
                                                        <b-button blocksize="sm">
                                                            apply
                                                        </b-button>
                                                    </div> -->
                          </b-list-group>
                        </b-card>
                      </b-collapse>

                      <b-collapse v-model="isSecClpsd3">
                        <b-card>
                          <div class="form-group mb-2">
                            <label for="CAfile">CA file</label>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                              style="
                                border: 1px solid #ddd;
                                border-raduis: 20px;
                              "
                            >
                              <label>
                                <input
                                  name="CAfile"
                                  type="file"
                                  accept=".crt"
                                  @change="uploadFile('CAfile')"
                                  ref="CAfile"
                                  :disabled="!isSecClpsd3"
                                />
                              </label>
                              <div>
                                <b-button
                                  @click="submitFile('CAfile')"
                                  :disabled="!selectedFile.CAfile"
                                  class="mr-2"
                                  variant="outline-primary"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-upload"
                                  /></i>
                                </b-button>
                                <b-button
                                  @click="downloadFile('CA')"
                                  :disabled="!security.cert.caCert"
                                  class="mr-2"
                                  variant="outline-success"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-download"
                                  /></i>
                                </b-button>
                              </div>
                            </div>
                            <div
                              v-if="!security.cert.caCert"
                              class="alert-danger"
                              role="alert"
                            >
                              CA file is required!
                            </div>
                          </div>

                          <div class="form-group mb-2">
                            <label for="PKfile">Client Key file</label>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                              style="
                                border: 1px solid #ddd;
                                border-raduis: 20px;
                              "
                            >
                              <label>
                                <input
                                  name="PKfile"
                                  type="file"
                                  accept=".key"
                                  @change="uploadFile('PKfile')"
                                  ref="PKfile"
                                  :disabled="!isSecClpsd3"
                                />
                              </label>
                              <div>
                                <b-button
                                  @click="submitFile('PKfile')"
                                  :disabled="!selectedFile.PKfile"
                                  class="mr-2"
                                  variant="outline-primary"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-upload"
                                  /></i>
                                </b-button>
                                <b-button
                                  @click="downloadFile('PK')"
                                  :disabled="!security.cert.privateKey"
                                  class="mr-2"
                                  variant="outline-success"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-download"
                                  /></i>
                                </b-button>
                              </div>
                            </div>
                            <div
                              v-if="!security.cert.privateKey"
                              class="alert-danger"
                              role="alert"
                            >
                              Client Key file is required!
                            </div>
                          </div>

                          <div class="form-group mb-2">
                            <label for="CFfile">Certificate file</label>
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                              style="
                                border: 1px solid #ddd;
                                border-raduis: 20px;
                              "
                            >
                              <label>
                                <input
                                  name="CFfile"
                                  type="file"
                                  accept=".crt"
                                  @change="uploadFile('CFfile')"
                                  ref="CFfile"
                                  :disabled="!isSecClpsd3"
                                />
                              </label>
                              <div>
                                <b-button
                                  @click="submitFile('CFfile')"
                                  :disabled="!selectedFile.CFfile"
                                  class="mr-2"
                                  variant="outline-primary"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-upload"
                                  /></i>
                                </b-button>
                                <b-button
                                  @click="downloadFile('CF')"
                                  :disabled="!security.cert.cert"
                                  class="mr-2"
                                  variant="outline-success"
                                >
                                  <i
                                    ><font-awesome-icon icon="fas fa-download"
                                  /></i>
                                </b-button>
                              </div>
                            </div>

                            <div
                              v-if="!security.cert.cert"
                              class="alert-danger"
                              role="alert"
                            >
                              Certificate file is required!
                            </div>
                          </div>
                        </b-card>
                      </b-collapse>
                    </b-tab>

                    <b-tab title="Publish Mapping">
                      <b-collapse v-model="isMapClpsd1">
                        <b-container fluid class="yekan p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-form-group label-for="filter-input">
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!filter"
                                      @click="filter = ''"
                                      >Clear</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="addMapPubJsonItem" class="reset"
                                  ><font-awesome-icon icon="fas fa-plus-square"
                                /></i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                            style="
                              background-color: #fae8ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-table
                              :items="mappingPub.JSON.items"
                              :fields="mapPubJsonFields"
                              :current-page="currentPage"
                              :per-page="perPage"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :sort-direction="sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="onFiltered"
                              ref="mapPubJsonTable"
                            >
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="
                                      editMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <!-- <template #cell(data)="row" >
                                                                <i v-if="row.item.data">{{row.item.data.name}}</i>
                                                                
                                                            </template> -->
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <b-form-group label-for="per-page-select">
                                <b-form-select
                                  style="width: 100%"
                                  id="per-page-select"
                                  v-model="perPage"
                                  :options="pageOptions"
                                  size="sm"
                                ></b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd2">
                        <b-card> </b-card>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd3">
                        <b-card> </b-card>
                      </b-collapse>
                    </b-tab>

                    <b-tab title="Subscribe Mapping">
                      <b-collapse v-model="isMapClpsd1">
                        <b-container fluid class="yekan p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-form-group label-for="filter-input">
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!filter"
                                      @click="filter = ''"
                                      >Clear</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="addMapSubJsonItem" class="reset"
                                  ><font-awesome-icon icon="fas fa-plus-square"
                                /></i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                            style="
                              background-color: #fae8ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-table
                              :items="mappingSub.JSON.items"
                              :fields="mapSubJsonFields"
                              :current-page="currentPage"
                              :per-page="perPage"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :sort-direction="sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="onFiltered"
                              ref="mapSubJsonTable"
                            >
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="
                                      editMapSubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeMapSubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <template #cell(data)="row">
                                <i v-if="row.item.data">{{
                                  row.item.data.name
                                }}</i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <b-form-group label-for="per-page-select">
                                <b-form-select
                                  style="width: 100%"
                                  id="per-page-select"
                                  v-model="perPage"
                                  :options="pageOptions"
                                  size="sm"
                                ></b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd2">
                        <b-card> </b-card>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd3">
                        <b-card> </b-card>
                      </b-collapse>
                    </b-tab>

                    <b-tab title="Status">
                      <b-collapse v-model="isMapClpsd1">
                        <b-container fluid class="yekan p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-form-group label-for="filter-input">
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="statTableOptions.filter"
                                    type="search"
                                    placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!statTableOptions.filter"
                                      @click="statTableOptions.filter = ''"
                                      >Clear</b-button
                                    >
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="addStatJsonItem" class="reset"
                                  ><font-awesome-icon icon="fas fa-plus-square"
                                /></i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                            style="
                              background-color: #fae8ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-table
                              :items="status.JSON.items"
                              :fields="statJsonFields"
                              :current-page="statTableOptions.currentPage"
                              :per-page="statTableOptions.perPage"
                              :filter="statTableOptions.filter"
                              :filter-included-fields="
                                statTableOptions.filterOn
                              "
                              :sort-by.sync="statTableOptions.sortBy"
                              :sort-desc.sync="statTableOptions.sortDesc"
                              :sort-direction="statTableOptions.sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="statusTableOnFiltered"
                              ref="statusJsonTable"
                            >
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="editStatJsonItem(row.item, $event)"
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeStatJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <template #cell(data)="row">
                                <i v-if="row.item.data">{{
                                  row.item.data.name
                                }}</i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <b-form-group label-for="per-page-select">
                                <b-form-select
                                  style="width: 100%"
                                  id="per-page-select"
                                  v-model="statTableOptions.perPage"
                                  :options="statTableOptions.pageOptions"
                                  size="sm"
                                ></b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="statTableOptions.currentPage"
                                :total-rows="statTableOptions.totalRows"
                                :per-page="statTableOptions.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd2">
                        <b-card> </b-card>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd3">
                        <b-card> </b-card>
                      </b-collapse>
                    </b-tab>

                    <b-tab title="Attribute">
                      <b-form>
                        <!-- Input fields for Tab 4 -->
                      </b-form>
                    </b-tab>
                    <b-tab title="Telemetry">
                      <b-form>
                        <!-- Input fields for Tab 4 -->
                      </b-form>
                    </b-tab>
                    <b-tab title="Events">
                      <b-form>
                        <!-- Input fields for Tab 4 -->
                      </b-form>
                    </b-tab>
                  </b-tabs>

                  <div v-else>
                    <b-list-group horizontal="md">
                      <b-form-group
                        label="name"
                        label-for="name"
                        :invalid-feedback="errors.first('name')"
                        style="width: 100%; padding: 10px"
                      >
                        <b-form-input
                          name="name"
                          type="text"
                          placeholder="Enter name"
                          v-model="name"
                          :state="submitted ? !errors.has('name') : null"
                          v-validate="'required|min:2|max:50'"
                          style="width: 100%"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Mapping Type"
                        label-for="mapType"
                        :invalid-feedback="errors.first('mapType')"
                        style="width: 100%; padding: 10px"
                      >
                        <b-form-select
                          style="
                            font-variant: small-caps;
                            width: 100%;
                            height: 100%;
                            padding: 0px;
                          "
                          name="mapType"
                          size="lg"
                          v-model="mapType"
                          :options="mapTypeList"
                          :state="submitted ? !errors.has('mapType') : null"
                          v-validate="'required'"
                        ></b-form-select>
                      </b-form-group>

                      <b-button @click="registerCloud">Next</b-button>
                    </b-list-group>
                  </div>
                </b-card>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <mapJsonItemEditor></mapJsonItemEditor>
    <statJsonItemEditor />
  </div>
</template>

<script>
/* eslint-disable */

    import mapJsonItemEditor  from './setContModalPubSubJson.vue';
    import statJsonItemEditor  from './setContCloudModalStatusJson.vue';
    export default {
        name: "masterEdit",
        
        components: {
            mapJsonItemEditor,
            statJsonItemEditor
        },
        data() {
            return {
                id:'',
                name:'',
                parent:'',
                title:'',
                registred:false,
                mapType:null,

                security:{
                    type:'',
                    basic:{
                        user:'',
                        pass:''
                    },
                    cert:{
                        caCert:false,
                        privateKey:false,
                        cert:false
                    }
                },

                mappingPub:{
                    type:'',
                    JSON:{
                        items:[]
                    }
                },

                mappingSub:{
                    type:'',
                    JSON:{
                        items:[]
                    }
                },

                status:{
                    type:'',
                    JSON:{
                        items:[]
                    }
                },
                connection:{
                    host:'',
                    port:'',
                    clientId:'',
                    idPrefix:false
                },

                submitted: false,
                successful: false,
                message: '',
                myErrors:{},
                mapTypeList:[
                    { value: null, text: "select a type" },
                    { value: 'JSON', text: "Json Mapping" },
                    { value: 'BYTE', text: "Byte Mapping", disabled: true },
                    { value: 'CUST', text: "Custom mapping", disabled: true }
                ],

                mapTypeTextList:{
                    JSON: "JSON Mapping",
                    BYTE: "Byte Mapping",
                    CUST: "Custom mapping"
                },

                mapPubJsonFields: [
                    // { key: '_id', label: 'id', sortable: true},
                    { key: 'topic', label: 'Topic', sortable: true, sortDirection: 'asc' },
                    { key: 'key', label: 'Key', sortable: true},
                    { key: 'data.type', label: 'type', sortable: true},
                    { key: 'data.name', label: 'data', sortable: true},
                    { key: 'interval', label: 'interval', sortable: true},
                    { key: 'uponChange', label: 'upon change', sortable: true},
                    
                    { key: 'modify', label: '' }
                ],

                mapSubJsonFields: [
                    // { key: '_id', label: 'id', sortable: true},
                    { key: 'topic', label: 'Topic', sortable: true, sortDirection: 'asc' },
                    { key: 'key', label: 'Key', sortable: true},
                    { key: 'data.type', label: 'type', sortable: true},
                    { key: 'data.name', label: 'data', sortable: true},
                    { key: 'modify', label: '' }
                ],

                statJsonFields: [
                    // { key: '_id', label: 'id', sortable: true},
                    { key: 'topic', label: 'Topic', sortable: true, sortDirection: 'asc' },
                    { key: 'key', label: 'Key', sortable: true},
                    { key: 'value', label: 'Value', sortable: true},
                    { key: 'timeout', label: 'Timeout', sortable: true},

                    { key: 'modify', label: '' }
                ],

                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [
                    { value: 5, text: "5 rows" },
                    { value: 10, text: "10 rows" },
                    { value: 15, text: "15 rows" },
                    { value: 100, text: "Show a lot" }
                ],
                sortBy: 'topic',
                sortDesc: true,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                
                statTableOptions:{
                    totalRows: 1,
                    currentPage: 1,
                    perPage: 10,
                    pageOptions: [
                        { value: 5, text: "5 rows" },
                        { value: 10, text: "10 rows" },
                        { value: 15, text: "15 rows" },
                        { value: 100, text: "Show a lot" }
                    ],
                    sortBy: 'topic',
                    sortDesc: true,
                    sortDirection: 'asc',
                    filter: null,
                    filterOn: [],
                },
                
                selectedFile:{
                    CAfile:null,
                    CFfile:null,
                    PKfile:null,
                } ,
                fileState: null,
                filePlaceholder: "Choose a file...",

                isSecClpsd1: false,
                isSecClpsd2: false,
                isSecClpsd3: false,

                isMapClpsd1: false,
                isMapClpsd2: false,
                isMapClpsd3: false,

            }
        },
        watch: {
            secType(newVal) {
                this.isSecClpsd1 = newVal === 'anony';
                this.isSecClpsd2 = newVal === 'basic';
                this.isSecClpsd3 = newVal === 'cert';
            },
            mapType(newVal) {
                this.mappingPub.type = newVal
                this.mappingSub.type = newVal
                this.status.type = newVal

                this.isMapClpsd1 = newVal === 'JSON';
                this.isMapClpsd2 = newVal === 'BYTE';
                this.isMapClpsd3 = newVal === 'CUST';
            },
            child2parent(newValue, oldValue) {
                if ((newValue.task==='editMapJson')&&(newValue.to.type === 'cloudPub')){
                    var map = newValue.to.map
                    if (newValue.type === 'New'){
                        if (this.mappingPub[map].items){
                            this.mappingPub[map].items.push(JSON.parse(JSON.stringify(newValue.item)))
                        }
                        else{
                            this.mappingPub[map]['items'] = [JSON.parse(JSON.stringify(newValue.item))]
                        }
                    }
                    else if (newValue.type === 'Edit'){
                        var indx = this.mappingPub[map].items.findIndex(x=>x._id===newValue.item._id)
                        if (indx != -1){
                            this.mappingPub[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
                        }
                    }
                    console.log(this.mappingPub[map])
                    this.$refs.mapPubJsonTable.refresh();
                }else if ((newValue.task==='editMapJson')&&(newValue.to.type === 'cloudSub')){
                    var map = newValue.to.map
                    if (newValue.type === 'New'){
                        if (this.mappingSub[map].items){
                            this.mappingSub[map].items.push(JSON.parse(JSON.stringify(newValue.item)))
                        }
                        else{
                            this.mappingSub[map]['items'] = [JSON.parse(JSON.stringify(newValue.item))]
                        }
                    }
                    else if (newValue.type === 'Edit'){
                        var indx = this.mappingSub[map].items.findIndex(x=>x._id===newValue.item._id)
                        if (indx != -1){
                            this.mappingSub[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
                        }
                    }
                    this.$refs.mapSubJsonTable.refresh();
                }else if (newValue.task==='editStatJson'){
                    if (newValue.type === 'New'){
                        if (this.status.JSON.items){
                            this.status.JSON.items.push(JSON.parse(JSON.stringify(newValue.item)))
                        }
                        else{
                            this.status.JSON['items'] = [JSON.parse(JSON.stringify(newValue.item))]
                        }
                    }
                    else if (newValue.type === 'Edit'){
                        var indx = this.status.JSON.items.findIndex(x=>x._id===newValue.item._id)
                        if (indx != -1){
                            this.status.JSON.items[indx] = JSON.parse(JSON.stringify(newValue.item));
                        }
                    }
                    this.$refs.statusJsonTable.refresh();
                }
            },
        },
        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            },
            secType(){
                return this.security.type;
            },
            mapPubType(){
                return this.mappingPub.type;
            },
            mapSubType(){
                return this.mappingSub.type;
            },
            statType(){
                return this.status.type;
            },
            child2parent() {
                return this.$store.state.chpge.child2parent;
            },
            mapTypeText(){
                return this.mapTypeTextList[this.mapType]
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        methods: {
            initModal() {
                console.log(this.db)
                this.title = this.type + " Cloud Connection"

                if (this.type==='Add')
                {
                    this.parent = {ptype:this.db.ptype, pid:this.db.pid}
                }
                else if (this.type==='Edit')
                {
                    // this.topic = this.db.topic;
                    this.registred = true;
                    this.id = this.db.id;
                    this.name = this.db.name
                    this.mapType = this.db.mapType
                    this.parent = this.db.parent
                    this.security = this.db.security
                    this.mappingPub = this.db.mappingPub
                    this.mappingSub = this.db.mappingSub
                    this.status = this.db.status
                    this.connection = this.db.connection
                }
            },
            onReset() {
                // this.topic = null
                this.id = null,
                this.type = '', 
                this.mapType = '', 
                this.name = '',
                this.parent = {},
                this.security = {},
                this.mappingPub = {},
                this.mappingSub = {},
                this.status = {},
                this.connection = {},

                this.$store.dispatch('chpge/changePage','controllerSettings')
            },
            registerCloud(){
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    console.log(isValid)
                    if (isValid) {
                        console.log('register')
                        var data = {
                            type:"Cloud", 
                            name: this.name,
                            parent:this.parent,
                            mapType: this.mapType
                        }
                        this.$store.dispatch('data/createCloud', data).then(
                            data => {
                                console.log(data.message)
                                this.submitted = false;
                                this.successful = false;

                                this.registred = true;
                                this.id = data.message._id;
                                this.name = data.message.name
                                this.mapType = data.message.mapType
                                this.parent = data.message.parent
                                this.security = data.message.security
                                this.mappingPub = data.message.mappingPub,
                                this.mappingSub = data.message.mappingSub,
                                this.status = data.message.status
                                this.connection = data.message.connection


                            },
                            error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                alert(this.message)
                            }
                        );
                    }
                })

            },
            handleRegister() {
                this.message = '';
                this.submitted = true;
                
                this.$validator.validateAll().then(isValid => {
                    console.log(isValid,this.myErrors)
                    if (isValid) {
                        if ((this.security.type!=='cert')||(this.security.type==='cert' && this.security.cert.caCert && this.security.cert.cert && this.security.cert.privateKey)){
                            var data = {
                                id:this.id, 
                                type:"Cloud", 
                                name: this.name,
                                parent:this.parent,
                                mapType:this.mapType,
                                security:this.security,
                                mappingPub:this.mappingPub,
                                mappingSub:this.mappingSub,
                                status:this.status,
                                connection: this.connection,
                            }
                            console.log('----',data)
                        
                            this.$store.dispatch('data/editCloud', data).then(
                                data => {
                                    this.message = data.message;
                                    this.successful = true;
                                    //alert(this.message)
                                    this.getContConf();
                                    this.$store.dispatch('chpge/changePage','controllerSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                alert(this.message)
                                }
                            );
                        }
                    }
                });
            },
            getContConf() {
                this.$store.dispatch('data/getContConf').then(
                    data => {
                        console.log("master: ",JSON.stringify(data))
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },

            handleFileChange(file) {
                // Validate the selected file (e.g., check file size, type, etc.)
                if (file && file.size > 5242880) {
                    this.fileState = false; // Set state to "invalid" if file size exceeds 5MB
                    this.filePlaceholder = "File size exceeds 5MB";
                } else {
                    this.fileState = true; // Set state to "valid" if file is valid
                    this.filePlaceholder = "Choose a file...";
                }
            },

            downloadFile(type) {
                console.log(this.id, type )
                this.$store.dispatch('data/getFileCloud', {id:this.id, type:type })

                .then((response) => {
                    // Create a download link for the received file
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement("a");
                    link.href = url;

                    let fileName = '';
                    if (type === 'CA')
                        fileName = 'ca.crt'
                    else if (type === 'PK')
                        fileName = 'client.key'
                    else if (type === 'CF')
                        fileName = 'client.crt'

                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);

                    // Click the download link to initiate the file download
                    link.click();

                    // Clean up the temporary download link
                    document.body.removeChild(link);
                })
                .catch(error => {
                    alert('Error downloading file:', error);
                });
            },

            uploadFile(file) {
                this.selectedFile[file] = this.$refs[file].files[0];
                console.log(this.selectedFile)
            },
            submitFile(type) {
                const formData = new FormData();
                
                formData.append('id', this.id);
                formData.append('type', type);
                formData.append('file', this.selectedFile[type]);
                

                this.$store.dispatch('data/uploadFileCloud', formData).then(
                                 Data => {                                 
                                        this.successful = true;

                                        if (type === 'CAfile')
                                            this.security.cert.caCert = true
                                        else if (type === 'CFfile')
                                            this.security.cert.cert = true
                                        else if (type === 'PKfile')
                                            this.security.cert.privateKey = true;
                                        
                                        this.message = Data.message
                                        // this.$store.dispatch('chpge/child2parent', {task: 'updateCB', data: this.updateCBCnt+1})

                                    },
                                    
                                    error => {
                                        this.message =
                                            (error.response && error.response.data && error.response.data.message) ||
                                            error.message ||
                                            error.toString();
                                        this.successful = false;
                                    // alert(this.message)
                                    }
                )
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            statusTableOnFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.statTableOptions.totalRows = filteredItems.length
                this.statTableOptions.currentPage = 1
            },


            addMapPubJsonItem(){
                var channels = []
                this.$store.dispatch('chpge/parent2child', {task: 'editMapJson', from:{type:'cloudPub', map:'JSON', id:this.id, extra: channels}, type:'New', item:{}})
                this.$root.$emit('bv::show::modal', 'modal-map-pub-json-item');
            },
            editMapPubJsonItem(item){
                var channels = []
                this.$store.dispatch('chpge/parent2child', {task: 'editMapJson', from:{type:'cloudPub', map:'JSON', id:this.id, extra: channels}, type:'Edit', item:item})
                this.$root.$emit('bv::show::modal', 'modal-map-pub-json-item');
            },

            
            removeMapPubJsonItem: function(item,event){
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
                    .then(value => {
                    if (value===true){
                        var indx = this.mappingPub.JSON.items.findIndex(x=>x._id===item._id)
                        if (indx != -1){
                            this.mappingPub.JSON.items.splice(indx,1)
                        }
                    }
                    })
                    .catch(err => {
                    // An error occurred
                    })
            },
            addMapSubJsonItem(){
                var channels = []
                this.$store.dispatch('chpge/parent2child', {task: 'editMapJson', from:{type:'cloudSub', map:'JSON', id:this.id, extra: channels}, type:'New', item:{}})
                this.$root.$emit('bv::show::modal', 'modal-map-pub-json-item');
            },
            editMapSubJsonItem(item){
                var channels = []
                this.$store.dispatch('chpge/parent2child', {task: 'editMapJson', from:{type:'cloudSub', map:'JSON', id:this.id, extra: channels}, type:'Edit', item:item})
                this.$root.$emit('bv::show::modal', 'modal-map-pub-json-item');
            },
            removeMapSubJsonItem: function(item,event){
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
                    .then(value => {
                    if (value===true){
                        var indx = this.mappingSub.JSON.items.findIndex(x=>x._id===item._id)
                        if (indx != -1){
                            this.mappingSub.JSON.items.splice(indx,1)
                        }
                    }
                    })
                    .catch(err => {
                    // An error occurred
                    })
            },
            addStatJsonItem(){
                this.$store.dispatch('chpge/parent2child', {task: 'editStatJson', type:'New', item:{}})
                this.$root.$emit('bv::show::modal', 'modal-cloud-status-json-item');
            },
            editStatJsonItem(item){
                this.$store.dispatch('chpge/parent2child', {task: 'editStatJson', type:'Edit', item:item})

                this.$root.$emit('bv::show::modal', 'modal-cloud-status-json-item');
            },
            removeStatJsonItem: function(item,event){
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
                    .then(value => {
                    if (value===true){
                        var indx = this.status.JSON.items.findIndex(x=>x._id===item._id)
                        if (indx != -1){
                            this.status.JSON.items.splice(indx,1)
                        }
                    }
                    })
                    .catch(err => {
                    // An error occurred
                    })
            },

        },
        mounted() {
            this.initModal()
        }
    };
</script>

<style scoped>
    .apply {
        color:#198754;
        cursor:pointer;
    }
    .apply :hover{
        color:#9b9b9b;
    }
    .reset {
        color:#dc3545;
        cursor:pointer;
    }
    .reset :hover{
        color:#9b9b9b;
    }
    .download-link {
        margin-bottom: 10px;
    }
</style>
