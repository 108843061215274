<template>
    <div style="text-align:left">
        <div>
            <b-container fluid="md">
                <b-row class=my-5>
                    <b-col cols="1"></b-col>
                    <b-col cols="10" sm>
                        <b-card no-body>
                            <b-card-body>     
                                <b-form ref="form" @submit.stop.prevent="handleRegister" @reset="onReset">
                                    
                                    <div style="display: flex; align-items: center; flex-wrap: wrap;">
                                        <h4 style="flex: 90%;">{{ title}}</h4>
                                        <b-button type="submit"  variant="outline-success"  class="mr-2" style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                        <b-button type="reset"  variant="outline-danger"  class="mr-2" style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>

                                    <hr class="my-2">
                                
                                    <b-list-group flush>
                                        <b-list-group-item style="background-color:transparent;color:black;">
        
                                            <div style="display:flex; flex-direction:row">
                                                <b-form-group
                                                    label="Username"
                                                    label-for="username"                                            
                                                    :invalid-feedback="errors.first('username')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        :disabled="(type==='Edit')? true:false"  
                                                        name="username"
                                                        type="text"
                                                        placeholder="Enter username"
                                                        v-model="user.username"
                                                        :state="(submitted)? (!errors.has('username')): null"  
                                                        v-validate="'required|min:3|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>           

                                                <b-form-group
                                                    label="Email"
                                                    label-for="email"                                            
                                                    description="the account is created based on email."
                                                    :invalid-feedback="errors.first('email')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        :disabled="(type==='Edit')? true:false" 
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter email"
                                                        v-model="user.email"
                                                        :state="(submitted)? (!errors.has('email')): null"  
                                                        v-validate="'required|email|max:50'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>          

                                                <b-form-group
                                                    label="Role"
                                                    label-for="role"
                                                    :invalid-feedback="errors.first('role')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-select
                                                        name="role"
                                                        size="lg"
                                                        id="role-input"
                                                        v-model="user.role"
                                                        :options="roleList"
                                                        :state="(submitted)? (!errors.has('role')): null"  
                                                        v-validate="'required'"
                                                        style="width:100%"
                                                    ></b-form-select>
                                                </b-form-group>            
                                            </div>

                                            <!-- <div style="display:flex; flex-direction:row"> -->
                                                <b-form-group
                                                    label="Authorized Floors"
                                                    label-for="floor"
                                                    :invalid-feedback="errors.first('floor')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-checkbox-group
                                                        class="container"
                                                        name="floor"
                                                        v-model="user.floor"
                                                        :state="(submitted)? (!errors.has('floor')): null"  
                                                        v-validate="'required'"
                                                        :options="floorList"
                                                    ></b-form-checkbox-group>
                                                    

                                                </b-form-group>
                                            <!-- </div> -->

                                            <div style="display:flex; flex-direction:row">
                                                <b-form-group
                                                    label="Password"
                                                    label-for="password"
                                                    :invalid-feedback="errors.first('password')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        name="password"
                                                        type="password"
                                                        v-model="user.password"
                                                        :state="(submitted)? (!errors.has('password')): null"  
                                                        v-validate="'required|min:6|max:40'"
                                                        style="width:100%"
                                                        placeholder="Password" 
                                                        ref="password"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                    label="Password Confirmation"
                                                    label-for="password_confirmation"
                                                    :invalid-feedback="errors.first('password_confirmation')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        name="password_confirmation"
                                                        type="password"
                                                        v-model="passwordRetype"
                                                        :state="(submitted)? (!errors.has('password_confirmation')): null"  
                                                        v-validate="'required|confirmed:password'"
                                                        style="width:100%"
                                                        placeholder="Password, Again" 
                                                        data-vv-as="password"
                                                    ></b-form-input>
                                                </b-form-group>

                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container> 
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import User from '../models/user';
    export default {
        name: "accountEdit",
        data() {
            return {
                groups:[],
                item: [],
                Groups:[],
                
                user: new User(null, null, null,null,[],null,null),
                submitted: false,
                successful: false,
                message: '',
                roleList: [{ text: 'Select One', value: null },
                            {text:'User',value:'user'},
                            {text:'Moderator Level 1',value:'moderator1'},
                            {text:'Moderator Level 2',value:'moderator2'},
                            {text:'Moderator Level 3',value:'moderator3'},
                            {text:'Admin',value:'admin'}],
                floorList: [],
                title:'',
                passwordRetype:''
            }
        },
        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        methods: {
            initModal() {
                this.getBuildConf();
                this.title = this.type + " User"

                if (this.type==='Add')
                {
                }
                else if (this.type==='Edit')
                {
                    this.user.username = this.db[this.index].username;
                    this.user.email = this.db[this.index].email;
                    this.user.role = this.db[this.index].roles[0];
                    this.user.floor = this.db[this.index].floors;
                    this.user.password = "";
                    this.passwordRetype = "";
                }
            },
            onReset() {
                this.user.username = null
                this.user.email = null
                this.user.role = null
                this.user.floor = null
                this.user.password = null

                this.$store.dispatch('chpge/changePage','accounts')
            },
            handleRegister() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if (this.type==='Add'){
                            this.$store.dispatch('auth/register', this.user).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','accounts')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }else if (this.type==='Edit'){
                            this.$store.dispatch('auth/edit', this.user).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','accounts')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }
                    }
                });
            },
            getBuildConf() {
                this.$store.dispatch('data/getBuildConf').then(
                    data => {
                        this.floorList =  [];
                        for (var f of data){
                            this.floorList.push({ text: f.floorTitle, value: f._id })
                        }
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
        },
        mounted() {
            this.initModal()
        }
    };
</script>

<style>
  .container {
    display : flex;
    justify-content: space-between;
    border: 1px solid #ced4da; 
    border-radius: 0.25rem; 
    background-color: #e9ecef; 
    opacity: 1; 
    padding: 10px 15px 10px 15px;
  }

</style>
