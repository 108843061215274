<template>
  <div :dir="dict.dir[lang]">
    <b-modal
      id="modal-invite-user_box"
      size="md"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="dict.title[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
      :dir="dict.dir[lang]"
    >
      <div>
        <div id="invUser">
          <ejs-textbox
            :autocomplete="'off'"
            id="filter-input_connection"
            v-model="user"
            :enableRtl="lang== 'per'"
            :showClearButton="true"
            type="text"
            :required="true"
            :placeholder="dict.label[lang]"
          ></ejs-textbox>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex gap-3">
          <b-button variant="secondary" class="p-0" @click="closeModal">
            {{ dict.cancel[lang] }}
          </b-button>
          <b-button variant="primary" @click="handleOk">
            <b-spinner v-if="loading" small label="Loading..."></b-spinner>
            <span v-else>{{
                dict.ok[lang]
              }}</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "inviteUser",

  data() {
    return {
      // modalTitle:'',

      user: "",

      submitted: false,
      loading: false,
      successful: false,
      message: "",

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "invite new user", per: "دعوت کاربر جدید" },
        label: { eng: "username", per: "نام کاربری" },
        placeholder: { eng: "enter email of inviting user", per: "ایمیل کاربر مد نظر را وارد کنید" },
        username: { eng: "user", per: "نام کاربری" },
        success: {
          eng: "your invitation was successfuly sent to this user",
          per: "دعوتنامه شما با موفقیت برای کاربر ارسال شد"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        error1: { eng: "this user is not registred", per: "چنین کاربری در سیستم ثبت نشده است" },
        error2: { eng: "this user is a member of your workbench", per: "کاربر مورد نظر در میزکار شما عضو است" },
        error3: {
          eng: "an invitation has already been sent for this user",
          per: "پیشتر دعوتنامه ای برای این کاربر ارسال شده است"
        }
      }
    };
  },
  methods: {
    initModal: function() {
      this.user = "";
    },
    closeModal: function() {
      this.$nextTick(()=>{
        this.$bvModal.hide("modal-invite-user_box");
      })
    },
    resetModal: function() {
      this.user = "";
      this.message = "";
      this.submitted = false;
      this.successful = false;

    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      // console.log('submit',this.type)
      this.message = "";
      this.loading = true;
      const isValid = new FormValidator("#invUser").validate();
      if (isValid) {
        var data = { user: this.user };
        this.$store.dispatch("data/inviteWBusers", data).then(() => {
          this.loading = false;
          this.$store.dispatch("panel/sseSubscription");
          this.$nextTick(() => {
            this.$bvModal.hide("modal-invite-user_box");
          });
        }).catch(() => {
          this.loading = false;
        });
      } else this.loading = false;

    }

  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>