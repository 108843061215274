<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none yekan"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="../assets/icons/maps_manager-1.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-3 h-100">
        <div
          class="card text-center h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div
            class="myrow"
            style="height: 10%; background-color: rgb(254, 237, 237)"
          >
            <div class="uploadfield">
              <label>
                <input type="file" @change="uploadFile" ref="file" />
              </label>
              <b-button
                @click="submitFile"
                size="sm"
                class="mr-2"
                variant="outline-primary"
                >upload
              </b-button>
            </div>
          </div>
          <div
            class="myrow"
            style="height: 90%; background-color: rgb(254, 237, 237)"
          >
            <b-card no-body style="width: 100%">
              <b-card-body>
                <b-form-select
                  v-model="selectedMap"
                  :options="mapList"
                  :select-size="4"
                  style="width: 100%; height: 100%"
                ></b-form-select>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>

      <div class="col-lg-9 h-100">
        <div
          class="card text-center h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div
            class="myrow"
            style="
              min-height: 50px;
              background-color: rgb(254, 237, 237);
              align-items: baseline;
            "
          >
            <div class="mystatus">
              <strong>{{ mapTitle }}</strong>
            </div>
            <div class="mystatus">
              <b-button
                @click="mapSetting"
                size="sm"
                variant="outline-primary"
                style="width: 45%"
              >
                settings
              </b-button>
              <b-button
                @click="removeMap"
                size="sm"
                variant="outline-danger"
                style="width: 45%"
              >
                remove
              </b-button>
            </div>
          </div>
          <div
            class="myrow"
            style="
              min-height: calc(100vh - 242px);
              background-color: rgb(254, 237, 237);
            "
          >
            <div
              id="mapContainer"
              class="mymap"
              style="min-height: calc(100vh - 242px)"
            ></div>
          </div>
        </div>
      </div>

      <!-- <div class="mycont"> -->

      <!-- <div class="myrow">
                <div class="mymapcont" style="width: 25%">
                    <div class="myrow" style="height: 10%; background-color: rgb(254, 237, 237)">
                        <div class="uploadfield">
                            <label>
                                <input type="file" @change="uploadFile" ref="file">
                            </label>
                            <b-button  @click="submitFile" size="sm" class="mr-2" variant="outline-primary">upload
                            </b-button>
                        </div>              
                    </div>
                    <div class="myrow" style="height: 90%; background-color: rgb(254, 237, 237)">
                        <b-card no-body style="width: 100%">
                            <b-card-body>
                                <b-form-select v-model="selectedMap" :options="mapList" :select-size="4" style="width: 100%; height: 100%"></b-form-select>
                            </b-card-body>
                        </b-card>
                    </div>
                </div>

                <div class="mymapcont" style="width: 74%">
                    <div class="myrow" style="height: 10%; background-color: rgb(254, 237, 237); align-items: baseline;">
                        <div class="mystatus"> <strong>{{ mapTitle }}</strong></div>
                        <div class="mystatus">
                            <b-button  @click="mapSetting" size="sm"  variant="outline-primary" style="width:45%">
                                settings
                            </b-button>
                            <b-button  @click="removeMap" size="sm"  variant="outline-danger" style="width:45%">
                                remove
                            </b-button>
                        </div>
                    </div>
                    <div class="myrow" style="height: 90%; background-color: rgb(254, 237, 237)">
                        <div id="mapContainer" class="mymap"></div>
                    </div>
                </div>
            </div> -->
    </div>
    <mapZoneSetting />
    <mapSetting />
  </div>
</template>

<script>
import { latLng } from "leaflet";
import L from "leaflet";
import mapZoneSetting from "./mapZoneSetting.vue";
import mapSetting from "./mapSetting.vue";

export default {
  name: "buildingSettings",
  components: {
    mapZoneSetting,
    mapSetting,
  },
  data() {
    return {
      levels: null,
      mymap: [],
      popup: L.popup(),

      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: "",
      },
      tiles: [
        "outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1",
      ],

      file: null,
      buildingStruct: [],
      successful: false,
      message: "",
      selectedMap: null,
      mapTitle: "",
      mapList: [],

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "map manager", per: "مدیریت نقشه ها" },
        tab1: { eng: "current alarms", per: "خطاهای موجود" },
        tab2: { eng: "archived alarms", per: "خطاهای آرشیو شده" },
        tab3: { eng: "events", per: "رخدادها" },
        active: { eng: "active", per: "فعال" },
        acknowledge: { eng: "acknowledge", per: "تایید" },

        th_active: { eng: "activation time", per: "زمان فعال شدن" },
        th_source: { eng: "Associated with", per: "مربوط به" },
        th_message: { eng: "Message", per: "پیام" },
        th_deactive: { eng: "deactivation time", per: "زمان غیرفعال شدن" },
        th_acked: { eng: "acknowledgment time", per: "زمان تایید" },
        th_modify: { eng: "", per: "" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },

        po_5: { eng: "5 rows", per: "5 ردیف" },
        po_10: { eng: "10 rows", per: "10 ردیف" },
        po_15: { eng: "15 rows", per: "15 ردیف" },
        po_100: { eng: "100 rows", per: "ردیف های بیشتر" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد",
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است",
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد",
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است",
        },
      },
    };
  },
  mounted() {
    this.setupLeafletMap();
    this.getBuildConf();
  },
  computed: {
    updateMap() {
      return this.$store.state.panel.updateMap;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
  },
  watch: {
    updateMap(newValue, oldValue) {
      this.getfloorMap(this.selectedMap);
    },
    selectedMap(newValue, oldValue) {
      this.getfloorMap(newValue);
    },
  },

  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      this.$store.dispatch("data/uploadMap", formData).then((data) => {
        this.getBuildConf();
      });
    },
    getfloorMap(floor) {
      this.$store.dispatch("data/getfloorMap", { floor: floor }).then(
        (data) => {
          this.map.center = data.floorOptions.center;
          this.map.minZoom = data.floorOptions.minZoom;
          this.map.maxZoom = data.floorOptions.maxZoom;
          this.map.maxNativeZoom = data.floorOptions.maxNativeZoom;
          this.map.tileId = data.floorOptions.tileId;
          this.map.viewZoom = data.floorOptions.viewZoom;
          this.mapTitle = data.title ? data.title : "unknown";
          this.createSpaces(data);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getBuildConf() {
      this.$store.dispatch("data/getBuildConf").then(
        (data) => {
          this.buildingStruct = data;
          // console.log(this.buildingStruct[0]._id,this.buildingStruct[0].floorTitle,this.buildingStruct[0].floorId)

          // this.mapList =  [{ text: 'Select One', value: null }];
          this.mapList = [];
          for (var map of this.buildingStruct) {
            this.mapList.push({
              text: map.floorTitle ? map.floorTitle : map._id,
              value: map._id,
            }); //floorId
            // this.mapList.push({text: map._id + ': ' + map.floorTitle, value:map._id})//floorId
          }
          if (this.mapList.length > 0)
            this.selectedMap = this.mapList[this.mapList.length - 1].value;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },

    setupLeafletMap: function () {
      this.mymap = L.map("mapContainer");
    },

    createSpaces: function (floor) {
      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function (layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      var mapOptions = {
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        id: "mapbox/" + this.tiles[this.map.tileId], //'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
        accessToken:
          "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg",
      };

      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      ).addTo(this.mymap);

      var pointList = [];

      for (var j = 0; j < floor.floorPoints.length; j++) {
        pointList[j] = latLng(
          floor.floorPoints[j].lat,
          floor.floorPoints[j].lng
        );
      }

      var geometry = new L.Polygon(pointList, floor.floorOptions);
      this.levels = new L.layerGroup();
      this.levels.addLayer(geometry);

      //sort zones based on order level floor.floorZones

      floor.floorZones.sort((a, b) =>
        +a.zoneOptions.order > +b.zoneOptions.order
          ? 1
          : +b.zoneOptions.order > +a.zoneOptions.order
          ? -1
          : 0
      );
      // console.log(floor.floorZones)

      for (var zone of floor.floorZones) {
        pointList = [];
        for (var k = 0; k < zone.zonePoints.length; k++) {
          pointList[k] = latLng(zone.zonePoints[k].lat, zone.zonePoints[k].lng);
        }
        zone.zoneOptions["_id"] = { floor: floor._id, zone: zone._id };
        geometry = new L.Polygon(pointList, zone.zoneOptions);
        // if (zone.zoneOptions.type==="corridor" || zone.zoneOptions.type==="room")// || space.properties.stairs!=="undefined")
        // {
        geometry.on("click", this.zoneClick);
        geometry.on("mouseover", this.zoneOver);
        geometry.on("mouseout", this.zoneLeft);
        // }
        this.levels.addLayer(geometry);
      }

      this.levels.addTo(this.mymap);
    },
    zoneClick: function (e) {
      this.$store
        .dispatch("panel/clickonMapSett", {
          _id: e.target.options._id,
          latlng: e.latlng,
        })
        .then(
          (data) => {
            this.$root.$emit("bv::show::modal", "modal-2");
          },
          (error) => {
            var message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            alert(message);
          }
        );
    },
    zoneOver: function (e) {
      e.target.setStyle({ fillColorPrev: e.target.options.fillColor });
      e.target.setStyle({ fillColor: "#ffffff" });
    },
    zoneLeft: function (e) {
      e.target.setStyle({ fillColor: e.target.options.fillColorPrev });
    },
    mapSetting: function (e) {
      this.$store
        .dispatch("panel/mapSetting", { floor: this.selectedMap })
        .then(
          (data) => {
            this.$root.$emit("bv::show::modal", "modal-3");
          },
          (error) => {
            var message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            alert(message);
          }
        );
    },
    removeMap: function (e) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete it.")
        .then((value) => {
          if (value === true) {
            this.$store
              .dispatch("data/removeFloor", { id: this.selectedMap })
              .then(
                (data) => {
                  this.message = data.message;
                  this.successful = true;

                  this.getBuildConf();

                  //this.getGroups();
                },
                (error) => {
                  this.message =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                  this.successful = false;
                  alert(this.message);
                }
              );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
.small_header {
  font-size: x-small;
}
.bold_body {
  font-weight: bold;
  font-size: large;
  text-align: left;
  text-indent: 10px;
}
.td-class-1 {
  width: 100px;
  text-align: left;
  font-weight: bold;
  font-size: large;
}
.uploadfield {
  height: 100%;
  width: 100%;
  border-radius: 0.25rem;
  padding: 10px 10px 10px 10px;
  /* background-color: #a78713;     */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* color : rgb(255, 255, 255) */
}

.mycont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #edf0f3;
  opacity: 1;
  padding: 10px 15px 10px 15px;
}

.myrow {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  --bs-gutter-x: 1.5rem;
  border-radius: 0.25rem;
  margin: 5px 0px 5px 0px;
}

.mymap {
  height: 100%;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  padding: 10px 10px 10px 10px;
}

.mymapcont {
  height: 100%;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  background-color: #ffffff;
  opacity: 1;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
}

.mystatus {
  height: 100%;
  width: 33%;
  /* border: 1px solid #ffffff; */
  /* border-radius: 0.25rem; */
  padding: 10px 10px 10px 10px;
  /* background-color: #a78713;     */
  display: flex;
  justify-content: space-between;
  color: rgb(219, 21, 21);
}

.black-white {
  filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(135deg)
    brightness(106%) contrast(101%);
}

.myvalue {
  font-size: 1.4rem;
  font-weight: bold;
  font-style: normal;
  color: white;
  text-shadow: 1px 1px 2px rgb(68, 66, 66), 0 0 25px rgb(120, 120, 224),
    0 0 5px rgb(104, 104, 118);
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}
</style>

<style scoped src="@/assets/styles/theme.css"></style>
