<template>
  <!-- <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar class="scroll-container ps-show-limits ps-show-active" style="max-width: 600px; max-height: 600px;" ng-reflect-auto-propagation="true" ng-reflect-config="[object Object]" ng-reflect-scroll-indicators="true">
        <div style="position: static;" class="ps ps--active-y" ng-reflect-config="[object Object]" ng-reflect-disabled="false">
          <div class="ps-content">
            <div class="v-sidebar-menu vsm-default">
              <div class="vsm-list">
                <ngb-accordion role="tablist" class="accordion" ng-reflect-active-ids="dashboards" ng-reflect-destroy-on-hide="false" ng-reflect-close-other-panels="true" aria-multiselectable="false">
                <div class="card ng-star-inserted">
                  <div role="tab" class="card-header" id="ngb-panel-0-header">
                <div class="vsm-header ng-star-inserted">Main Navigation</div> -->

  <!-- open in mobile: closed-sidebar closed-sidebar-md closed-sidebar-open
close in mobile: closed-sidebar

close in web: closed-sidebar
hover in web: closed-sidebar closed-sidebar-open


 -->

  <!-- :class="currentUser.theme? currentUser.theme.headerColor:'bg-default-header'" -->
  <div
    :dir="dict.dir[lang]"
    class="app-sidebar sidebar-shadow"
    :class="computedClasses"
    @mouseover="toggleSidebar(true)"
    @mouseleave="toggleSidebar(false)"
  >
    <!-- <div :class="sidebar? 'app-sidebar sidebar-shadow': 'app-sidebar sidebar-shadow closed-sidebar'" @mouseover="expandSidebar" @mouseleave="collapseSidebar">   -->
    <!-- <div class="closed-sidebar closed-sidebar-md closed-sidebar-open app-sidebar sidebar-shadow"> -->
    <div class="app-header__logo bg-logo">
      <!-- class="logo-src" -->
      <div :class="lang === 'per' ? 'logo-src-per' : 'logo-src-eng'">
        <!-- <img src="../assets/images/logo-persian.png" alt="megashid" height="40 %"  style="margin-left:15px; margin-right:10px;"> -->
      </div>
      <div class="header__pane ml-auto">
        <div @click="changeLockSidebar(!lockSidebar)" class="hamburger">
          <i v-if="lockSidebar">
            <font-awesome-icon icon="fas fa-close" size="lg" />
          </i>
          <i v-else>
            <font-awesome-icon icon="fas fa-bars" size="lg" />
          </i>
        </div>
      </div>
    </div>
    <div
      :dir="dict.dir[lang]"
      class="app-sidebar-content"
      :style="{'overflow-y':isMoblieMode<=600 ?'scroll':'hideen' }"
      :class="sidebarColor ? 'bg-none' : 'bg-default-sidebar'"
    >
      <!--      :id="item.name + 'MenuItem'"-->

      <div
        @click="selectPage(item.component)"
        v-for="(item, key) in menuItems"
        :key="key"
        :class="iconClasses"
        class="isMenu my-2 cursor-pointer"
        v-permission:menu="item.permission"
      >
        <div
          class="image isMenu "
          v-permission:menu="item.permission"
        >
          <img
            :src="item.icon"
            alt="dashboard"
            v-permission:menu="item.permission"
            class="img-fluid isMenu"
            style="width: 45px"
          />
        </div>
        <div
          v-permission:menu="item.permission"
          class="isMenu"
        >
          <p
            v-show="sidebar"
            class="isMenu pt-3"
            v-permission:menu="item.permission"
          >
            {{ dict[item.name][lang] }}
          </p>
        </div>
        <!-- <i><font-awesome-icon icon="fas fa-home" size="xl"/></i> -->
      </div>
    </div>

    <!--    <div :class="{-->
    <!--      'sidebar_footer row px-md-4 mb-5':true,-->
    <!--      'mx-auto':isHovering-->
    <!--    }">-->
    <!--      <img-->
    <!--        src="@/assets/images/fava.jpg"-->
    <!--        alt="fava"-->
    <!--        :class="{-->
    <!--          'col-md-6 col-4': isHovering || lockSidebar,-->
    <!--          'col-md-12 col-4': !isHovering && !lockSidebar,-->
    <!--        }"-->
    <!--      />-->
    <!--&lt;!&ndash;      <img&ndash;&gt;-->
    <!--&lt;!&ndash;        src="@/assets/images/لوگوی_شرکت_فولاد_هرمزگان.svg.png"&ndash;&gt;-->
    <!--&lt;!&ndash;        alt="Hosco"&ndash;&gt;-->
    <!--&lt;!&ndash;        :class="{&ndash;&gt;-->
    <!--&lt;!&ndash;          'mx-auto ': isHovering || lockSidebar,&ndash;&gt;-->
    <!--&lt;!&ndash;          'col-md-5 col-4': isHovering || lockSidebar,&ndash;&gt;-->
    <!--&lt;!&ndash;          'col-md-12 col-4 mx-1': !isHovering && !lockSidebar,&ndash;&gt;-->
    <!--&lt;!&ndash;        }"&ndash;&gt;-->
    <!--&lt;!&ndash;        style="aspect-ratio: 1.5"&ndash;&gt;-->
    <!--&lt;!&ndash;      />&ndash;&gt;-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";

export default {
  name: "sidebar",

  data() {
    return {
      menuItems: [
        {
          name: "dashboard",
          value: "dashboard",
          component: "lighting",
          permission: [
            "edit_widgets",
            "add_page",
            "remove_single_widget",
            "pagination_show",
            "edit_single_widget",
            "widgets_list",
            "add_widgets"
          ],
          icon: require("@/assets/icons/dashboard_settings-3.png")
        },
        {
          name: "controller",
          value: "controller",
          component: "controllerSettings",
          permission: [
            "menu_hardware_config",
            "hardware_add_server",
            "hardware_upload_configure",
            "hardware_edit_server",
            "hardware_remove_server",
            "hardware_show_servers_list"
          ],
          icon: require("@/assets/icons/hardware_config-1.png")
        },
        {
          name: "dataManager",
          value: "dataManager",
          component: "dataManager",
          permission: [
            "menu_data_manager",
            "dataManager_add",
            "dataManager_edit",
            "dataManager_remove"],
          icon: require("@/assets/icons/data_manager-2.png")
        },
        {
          name: "hisData",
          value: "hisData",
          component: "setHistoricalData",
          permission: ["archive_add", "menu_data_archive"],
          icon: require("@/assets/icons/data_archive-1.png")
        },
        {
          name: "contEditor2",
          value: "contEditor2",
          component: "controlEditor2",
          permission: [
            "menu_controller_manager",
            "controller_new_node",
            "controller_connect_node",
            "controller_edit_connection",
            "controller_remove_connection",
            "controller_edit_node",
            "controller_delete_node"
          ],
          icon: require("@/assets/icons/controller_designer-2.png")
        },

        {
          name: "maps",
          value: "maps",
          component: "feederSettings",
          permission: [
            "menu_map_manager",
            "map_edit_indoor",
            "map_upload_indoor",
            "map_remove_indoor"
          ],
          icon: require("@/assets/icons/maps_manager-1.png")
        },
        // {
        //   name: "formGenerator",
        //   value: "formGenerator",
        //   component: "formGenerator",
        //   permission: [
        //     "menu_map_manager",
        //     "map_edit_indoor",
        //     "map_upload_indoor",
        //     "map_remove_indoor"
        //   ],
        //   icon: require("@/assets/icons/maps_manager-1.png")
        // },
        // {
        //   name: "tanker",
        //   value: "tanker",
        //   component: "tankerIndex",
        //   permission: [
        //     "build_conf",
        //     "uploadMap",
        //     "getMap",
        //     "editFloor",
        //     "removeFloor",
        //     "editZone",
        //     "getZone"
        //   ],
        //   icon: require("@/assets/icons/map-manager.png")
        // },
        // {
        //   name: "maps",
        //   value: "maps-level2",
        //   // component: "feederSettings",
        //   component: "indexMap",
        //   permission: [
        //     "build_conf",
        //     "uploadMap",
        //     "getMap",
        //     "editFloor",
        //     "removeFloor",
        //     "editZone",
        //     "getZone"
        //   ],
        //   icon: require("@/assets/icons/map-manager.png")
        // },
        {
          name: "processFlow",
          value: "processFlow",
          component: "processFlowDiagram",
          permission: [
            "menu_processflow_manager",
            "process_add",
            "process_edit",
            "process_remove"
          ],
          icon: require("@/assets/icons/3.png")
        },
        {
          name: "eventEditor",
          value: "eventEditor",
          component: "eventEditor",
          permission: ["getCurEvent", "getArchivedEvents", "acknEvent", "menu_event_manager"],
          icon: require("@/assets/icons/eve.png")
        },

        {
          name: "wbManager",
          value: "wbManager",
          component: "workbenchManager",
          permission: [
            "menu_workbench_manager",
            "wb_edit_name",
            "wb_edit_owner",
            "wb_invite_user",
            "wb_remove_user",
            "wb_role_manager",
            "wb_add_role",
            "wb_edit_role",
            "wb_remove_role"
          ],
          icon: require("@/assets/icons/workbench_manager-2.png")
        },
        {
          name: "adminPanel",
          value: "adminPanel",
          component: "adminPanel",
          permission: ["administrator",
            "menu_admin_panel",
            "admin_upload_license",
            "admin_allocate_resource",
            "admin_new_wb",
            "admin_workbench's_users_list",
            "admin_user_data",
            "admin_workbench_users",
            "admin_add_connection",
            "admin_remove_connection",
            "admin_status"
          ],
          icon: require("@/assets/icons/accounts-1.png")
        }
      ],
      isExpanded: false,
      isHovering: false,
      isPanelExpanded: false,
      selPanel: null,
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        dashboard: { eng: "Dashboard", per: "دشبورد" },
        accounts: { eng: "Accounts", per: "حساب های کاربری" },
        controller: {
          eng: "Device Manager",
          per: "پیکره بندی سخت افزار"
        },
        maps: { eng: "Maps Manager", per: "مدیریت نقشه ها" },
        equipments: { eng: "Equipment Manager", per: "مدیریت تجهیزات" },
        dashSettings: { eng: "Dashboard Settings", per: "تنظیمات دشبورد" },
        recSettings: { eng: "Record Settings", per: "تنظیمات ثبت داده" },
        processFlow: {
          eng: "process flow diagram",
          per: "دیاگرام چرخه فرآیند"
        },
        hisData: { eng: "Data Ingestion", per: "استخراج اطلاعات" },
        contEditor: { eng: "Controller Designer", per: "طراحی کنترل کننده" },
        contEditor2: { eng: "Rule Manager", per: "مدیریت دستورات" },
        eventEditor: { eng: "Event Manager", per: "مدیریت رخدادها" },
        dataManager: { eng: "Data Manager", per: "مدیریت داده" },
        wbManager: { eng: "Workbench Manager", per: "مدیریت میزکار" },
        adminPanel: { eng: "admin panel", per: "پنل ادمین" },
        formGenerator: { eng: "form generator", per: "فرم ساز" }
      }
    };
  },
  methods: {
    // nodePulsable(node) {
    //   return node.coordinates.y > 200;
    // },

    expandPanel(icon) {
      this.isPanelExpanded = true;
      this.selPanel = icon;
      // this.$emit('expand', icon);
    },
    collapsePanel() {
      this.isPanelExpanded = false;
      this.selPanel = null;
      // this.$emit('collapse', icon);
    },
    expandSidebar() {
      this.isExpanded = true;
      // this.$emit('expand', icon);
    },
    collapseSidebar() {
      this.isExpanded = false;
      // this.$emit('collapse', icon);
    },
    toggleSidebar(data) {
      this.isHovering = data;
      if (window.innerWidth > 991.98) {
        if (!this.lockSidebar)
          this.$store.dispatch("chpge/toggleSidebar", data);
      }
    },
    changeLockSidebar(data) {
      this.$store.dispatch("chpge/lockSidebar", data);
    },
    selectPage: function(page) {
      if (!this.lockSidebar) {
        this.$store.dispatch("chpge/changePage", page);
        this.toggleSidebar(false);
        this.$store.dispatch("chpge/toggleSidebar", false);
      } else {
        this.$store.dispatch("chpge/changePage", page);
      }
    }
  },
  computed: {
    ...mapState(["data/permissionList"]),
    isMoblieMode() {
      return this.$store.state.chpge.screenWidth;
    },
    page() {
      return this.$store.state.chpge.page;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    sidebar() {
      return this.$store.state.chpge.sidebar;
    },
    lockSidebar() {
      return this.$store.state.chpge.lockSidebar;
    },
    currentUser() {
      return this.$store.state.data.user;
    },
    permissions() {
      return this.$store.state.data.permissionList;
    },
    sidebarColor() {
      if (this.currentUser) return this.currentUser.theme.sidebarColor;
      else return "";
    },
    sidebarFont() {
      if (this.currentUser) return this.currentUser.theme.sidebarFont;
      else return "dark";
    },
    computedClasses() {
      const classes = {
        "closed-sidebar": !this.sidebar
      };

      if (this.sidebarColor) {
        classes[this.sidebarColor] = true;
      } else {
        classes["bg-default-sidebar"] = true;
      }

      if (this.sidebarFont) {
        classes[this.sidebarFont + "-font"] = true;
      } else {
        classes["dark-font"] = true;
      }

      if (this.lang === "per") {
        classes["text-end"] = true;
      } else {
        classes["text-start"] = true;
      }

      if (this.dict.dir[this.lang] === "rtl") classes["sidebar-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr")
        classes["sidebar-ltr"] = true;

      return classes;
    },
    iconClasses() {
      const classes = {};
      if (this.sidebar) {
        if (this.sidebarFont)
          classes["iconexpanded-" + this.sidebarFont] = true;
        else classes["iconexpanded-dark"] = true;
      } else {
        classes["icon"] = true;
      }
      return classes;
    },
    myworkBench() {
      return this.$store.state.data.workbench;
    },
    activeWorkbench() {
      if (this.myworkBench) {
        return this.myworkBench.activeWorkbench;
      } else return {};
    }
  }
};
</script>

<style scoped>
.activeBlur {
  filter: brightness(100%) blur(3px);
  cursor: not-allowed;
}

.page_container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #3b5775;
}

.app-sidebar {
  background-color: dimgrey;
  /* display: -ms-flexbox; */
  display: flex;
  z-index: 20;
  overflow: hidden;
  width: 280px;
  position: fixed;
  height: 100vh;
  box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);

  flex-direction: column;
  transition: all 0.3s ease;

  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  //"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  //"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.closed-sidebar.app-sidebar {
  width: 90px;
  min-width: 90px;
  flex: 0 0 90px;
  z-index: 13;
}

.app-sidebar .app-header__logo {
  /* background: #fafbfc; */
  padding: 0 1.5rem;
  height: 60px;
  width: 280px;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-align: center; */
  align-items: center;
  justify-content: space-between;
  transition: width 0.2s;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
}

.closed-sidebar-open.closed-sidebar.app-sidebar {
  width: 280px;
}

.app-sidebar .app-sidebar-content {
  /* background: #ffffff; */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.app-sidebar.closed-sidebar .app-header__logo {
  width: auto;
}

.app-sidebar.closed-sidebar .logo-src-per,
.app-sidebar.closed-sidebar .logo-src-eng {
  height: 40px;
  width: 40px;
  background-image: url("../assets/images/logo-sign.png");
  margin: 0 auto;
}

.logo-src-per {
  height: 40px;
  width: 210px;
  background-image: url("../assets/images/logo-persian.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-src-eng {
  height: 40px;
  width: 210px;
  background-image: url("../assets/images/logo-english.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.app-sidebar.closed-sidebar .header__pane {
  display: none;
}

@media (max-width: 991.98px) {
  /* .closed-sidebar-md .closed-sidebar-open .app-sidebar */
  .closed-sidebar-md.closed-sidebar-open.app-sidebar {
    /* -ms-transform: translateX(0); */
    transform: translateX(0);
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }

  .app-sidebar {
    position: fixed;
    z-index: 20;
    box-shadow: 0 0 0 0 transparent;
    padding-top: 60px;
  }

  .app-sidebar.sidebar-ltr.closed-sidebar {
    transform: translateX(-90px);
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }

  .app-sidebar.sidebar-rtl.closed-sidebar {
    transform: translateX(90px);
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }

  .app-sidebar .app-header__logo {
    display: none;
  }
}

.app-sidebar .hamburger {
  color: #5b98e9;
  cursor: pointer;
  font-size: x-large;
}

.app-sidebar .hamburger :hover {
  color: #9b9b9b;
}

.sidebar {
  /* position: fixed;
    top: 50%;
    left: 0; */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 300px;
  background-color: #f1f1f1;
  border-right: 1px solid #252424;
  border-radius: 0 1rem 1rem 0;
  z-index: 1;
  transition: 0.2s;
}

.sidebar.expanded {
  width: 200px;
  border-radius: 0 1rem 1rem 0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  /* height: 40px;
  margin-bottom: 10px; */
  border-radius: 50%;
  /* padding: 5px; */
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.iconexpanded-light,
.iconexpanded-dark {
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 40px; */
  /* margin-bottom: 10px; */
  border-radius: 5px;
  /* padding: 5px; */
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.iconexpanded-dark:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black;
  font-weight: bold;
}

.iconexpanded-light:hover {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
}

.icon:hover {
  background-color: #ddd;
}

.iconexpanded-dark .image,
.iconexpanded-light .image {
  width: 25%;
}

.iconexpanded-dark span,
.iconexpanded-light span {
  width: 75%;
}

.icon i {
  /* font-size: 20px; */
  /* color: #333; */
  width: 100%;
}

.icon span {
  width: 160px;
  /* height: 40px; */
  /* font-size: 20px; */
  /* color: #af1111; */
}

/* .tooltip {
  padding: 5px;
  background-color: #333;
  border-radius: 5px;
  font-size: 14px;
} */

/* .icon:hover .tooltip {
  display: block;
} */

/* .sidebarRight {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50px;
  height: 100%;
  background-color: #4f6883;
  z-index: 1;
  transition:  0.2s;
}

.sidebarRight .nrmlItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
}

.sidebarRight .nrmlItem .base:hover {
  background-color: #fff;
  color: #4f6883;
}
.sidebarRight .slcdItem .base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 0 50% 50% 0;
  cursor: pointer;
  background-color: #e3e8f1;
  color: #1b89b4;
}
.sidebarRight .slcdItem .edgeUp {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}
.sidebarRight .slcdItem .edgeUp1 {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 0 0 0 100% ;
  background-color: #4f6883;
}

.sidebarRight .slcdItem .edgeDn {
  width: 15px;
  height: 15px;
  background-color: #e3e8f1;
}

.sidebarRight .slcdItem .edgeDn1 {
  width: 15px;
  height: 15px;
  border-radius: 100% 0 0 0;
  background-color: #4f6883;
} */

/* .settingPanel {
  position: absolute;
  top: 7px;
  bottom: 3px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  background-color: #e3e8f1;
  border-radius: 1rem;
  z-index: 10000;
  transition:  0.2s;
}

.settingPanel .header {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 6%;
  padding: 5px;
  width: 96%;
  margin-top: 5px;
  border: 1px solid #a9aaac;
  border-radius: inherit;
}

.settingPanel .header .title {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  font-weight: bold;
  font-size: large;
  color: #4e81df;
}

.settingPanel .header .button {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.settingPanel .button:hover {
  color: #ddd;
}

.settingPanel .body {
  height: 91%;
  width: 96%;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: inherit;
  background-color: #4e81df;
  overflow: auto;
}

.settingPanel .body .add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5%;
  padding: 10px;
  width: 100%;
  border-radius: inherit;
  background-color: #e1e5eb;
}
.settingPanel .body .add i:hover {
color: #3b63a0;
cursor: pointer;
}
.settingPanel .body .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9%;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  border-radius: inherit ;
  background-color: #3b63a0;
  color: #ddd;
}
.settingPanel .body .item span {
  width: 70%;
}

.settingPanel .body .item i {
  cursor: pointer;
  font-size: large;
}

.settingPanel .body .item i:hover {
  color:rgb(0, 255, 255)
} */
</style>

<style scoped src="@/assets/styles/theme.css"></style>
