<template>
  <div :dir="dict.dir[lang]" :class="lang=='eng'? 'text-start': 'text-end'">
    <div>
      <b-container fluid>
        <b-row class="my-4">
          <b-col cols="12" sm>
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col cols="8" lg="11" md="10" sm="9">
                    <h5 style="flex: 90%">{{ title }}</h5>
                  </b-col>
                  <b-col cols="4" lg="1" md="2" sm="3" v-if="registred">
                    <div
                      style="
                        font-size: 24px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <i @click="handleRegister">
                        <font-awesome-icon
                          icon="fas fa-check-square"
                          class="apply"
                        />
                      </i>
                      <i @click="onReset">
                        <font-awesome-icon
                          icon="fas fa-xmark-square"
                          class="reset"
                        />
                      </i>
                    </div>
                  </b-col>
                </b-row>

                <hr class="my-2" />
                <div class="p-3">
                  <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
                    <li
                      v-for="(item, index) in tabGeneration"
                      class="mga-tab-nav-item"
                      @click="activeTab = item.value"
                      :key="index"
                    >
                      <a
                        class="mga-tab-nav-link"
                        :class="{ active: activeTab === item.value }"
                        :id="item.name"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {{ dict[item.value][lang] }}
                      </a>
                    </li>
                  </ul>

                  <div v-if="registred" class="my-3">
                    <div v-if="activeTab=='ConnectionData'">
                      <div class="row">
                        <div class="col-md-6 col-12 my-3 d-flex">
                          <ejs-textbox
                            :autocomplete="'off'"
                            v-model="name"
                            title="name"
                            floatLabelType="Auto"
                            :readOnly="registred"
                            :placeholder="dict.Name[lang]"
                            :required="true"
                            :minLength="6"
                            :maxLength="50"
                            :enableRtl="lang === 'per'"
                          ></ejs-textbox>
                          <b-button @click="copyToClipboard(name)">
                            <font-awesome-icon icon="fas fa-copy" />
                          </b-button>
                        </div>
                        <div class="col-md-6 col-12 my-3">
                          <ejs-dropdownlist
                            :enabled="!registred"
                            :dataSource="mapTypeList"
                            floatLabelType="Always"
                            v-model="mapType"
                            :fields="{ text: lang, value: 'value' }"
                            :placeholder="dict.mapType[lang]"
                            :enableRtl="lang === 'per'"
                            :required="true"
                          ></ejs-dropdownlist>
                        </div>
                      </div>

                      <div class="row my-3">
                        <div class="col-md-4 gap-3 col-12 d-flex">
                          <ejs-textbox
                            :autocomplete="'off'"
                            v-model="connection.host"
                            :readOnly="true"
                            floatLabelType="Always"
                            :placeholder="dict.host[lang]"
                            :enableRtl="lang === 'per'"
                            :required="true"
                            :minLength="6"
                            :maxLength="50"
                          ></ejs-textbox>
                          <b-button @click="copyToClipboard(connection.host)">
                            <font-awesome-icon icon="fas fa-copy" />
                          </b-button>
                        </div>
                        <div class="col-md-4 gap-3 col-12 d-flex">
                          <ejs-textbox
                            :autocomplete="'off'"
                            v-model="connection.port"
                            :readOnly="true"
                            floatLabelType="Always"
                            :placeholder="dict.port[lang]"
                            :enableRtl="lang === 'per'"
                            :required="true"
                            :minLength="6"
                            :maxLength="50"
                          ></ejs-textbox>
                          <b-button @click="copyToClipboard(connection.port)">
                            <font-awesome-icon icon="fas fa-copy" />
                          </b-button>
                        </div>
                        <div class="col-md-4 gap-3 col-12 d-flex">
                          <ejs-textbox
                            :autocomplete="'off'"
                            v-model="connection.clientId"
                            :readOnly="true"
                            floatLabelType="Always"
                            :enableRtl="lang === 'per'"
                            placeholder="clientId"
                            :required="true"
                            :minLength="6"
                            :maxLength="50"
                          ></ejs-textbox>
                          <b-button
                            @click="copyToClipboard(connection.clientId)"
                          >
                            <font-awesome-icon icon="fas fa-copy" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div v-show="activeTab=='Security'">
                      <b-form-group>
                        <b-form-radio-group
                          v-model="security.type"
                          class="
                            d-flex
                            justify-content-around
                            fw-bolder
                            text-center
                            border
                            rounded
                            p-1
                            mb-2
                            text-dark
                          "
                          style="
                            background-color: #a5b4fc;
                            width: 100%;
                            border-color: #a5b4fc !important;
                          "
                          name="security"
                          v-validate="'required'"
                        >
                          <b-form-radio value="basic" class="ml-5"
                          ><span class="mx-2">{{ dict.Basic[lang] }}</span>
                          </b-form-radio>
                          <b-form-radio value="cert" class="ml-5"
                          ><span class="mx-2">{{ dict.Certificate[lang] }}</span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="submitted ? !errors.has('security') : null"
                          style="display: block !important"
                        >
                          <span> {{ errors.first("security") }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <b-collapse v-model="isSecClpsd2">
                        <b-card>
                          <p style="color: red;font-size: 13px">{{ dict.secWords[lang] }}</p>
                          <div class="row mx-auto">
                            <div class="col-md-6 gap-3 col-12 d-flex">
                              <ejs-textbox
                                :autocomplete="'off'"
                                v-model="security.basic.user"
                                :readOnly="true"
                                :placeholder="dict.userName[lang]"
                                floatLabelType="Always"
                                :enableRtl="lang === 'per'"
                                :required="true"
                                :minLength="6"
                                :maxLength="50"
                              ></ejs-textbox>
                              <b-button
                                @click="copyToClipboard(security.basic.user)"
                              >
                                <font-awesome-icon icon="fas fa-copy" />
                              </b-button>
                            </div>
                            <div class="col-md-6 gap-3 col-12 d-flex">
                              <ejs-textbox
                                :autocomplete="'off'"
                                :value="security.basic.pass"
                                :placeholder="dict.pass[lang]"
                                floatLabelType="Auto"
                                :enableRtl="lang === 'per'"
                                @input="(val)=>{security.basic.pass=val.value}"
                                :type="newGenerate==false ? 'password' : 'text'"
                              ></ejs-textbox>
                              <b-button
                                v-if="newGenerate"
                                @click="copyToClipboard(security.basic.pass)"
                              >
                                <font-awesome-icon icon="fas fa-copy" />
                              </b-button>
                              <b-button
                                v-if="!newGenerate"
                                @click="generateRandomString(10)"
                              >
                                <font-awesome-icon icon="fas fa-refresh" />
                              </b-button>
                            </div>
                          </div>
                        </b-card>
                      </b-collapse>

                      <b-collapse v-model="isSecClpsd3">
                        <b-card>
                          <div class="download-link">
                            <a @click="downloadFile('CA')">
                              <i>
                                <font-awesome-icon icon="fas fa-download" />
                              </i>
                            </a>
                            <i style="margin-left: 10px" class="mx-2">{{ dict.caFile[lang] }}</i>
                          </div>
                          <div class="download-link">
                            <a @click="downloadFile('PK')">
                              <i>
                                <font-awesome-icon icon="fas fa-download" />
                              </i>
                            </a>
                            <i class="mx-2" style="margin-left: 10px">{{ dict.pkFile[lang] }}</i>
                          </div>
                          <div class="download-link">
                            <a @click="downloadFile('CF')">
                              <i>
                                <font-awesome-icon icon="fas fa-download" />
                              </i>
                            </a>
                            <i class="mx-2" style="margin-left: 10px">{{ dict.certFile[lang] }}</i>
                          </div>
                        </b-card>
                      </b-collapse>
                    </div>

                    <div v-show="activeTab=='PublishMapping'">
                      <b-container fluid class="yekan p-0">
                        <div
                          :class="
                            mapType === 'MTR22'
                              ? 'fw-bolder text-center border border-bottom-0 rounded-top p-1 text-light'
                              : 'fw-bolder text-center border rounded p-1 mb-2 text-light'
                          "
                          style="
                            background-color: #a5b4fc;
                            width: 100%;
                            border-color: #a5b4fc !important;
                          "
                        >
                          {{ mapTypeText }}
                        </div>
                        <b-row
                          v-if="mapType === 'MTR22'"
                          align-h="between"
                          class="
                            g-0
                            mb-2
                            p-2
                            border border-top-0
                            rounded-bottom
                          "
                        >
                          <b-col lg="4" sm="6" md="5">
                            <ejs-textbox
                              :autocomplete="'off'"
                              :key="mapType"
                              :value="mappingPub.MTR22.topic"
                              v-model="mappingPub.MTR22.topic"
                              :readOnly="true"
                              :enableRtl="lang === 'per'"
                              :placeholder="dict.topic[lang]"
                              floatLabelType="Always"
                              :minLength="6"
                              :maxLength="50"
                            ></ejs-textbox>
                          </b-col>
                        </b-row>

                        <b-row
                          :dir="dict.dir[lang]"
                          align-h="between"
                          class="g-0 p-2 border rounded-top border-bottom-0"
                        >
                          <b-col lg="4" sm="6" md="5" class="my-1">
                            <ejs-textbox
                              :value="mapPubTableOptions.filter"
                              :placeholder="dict.search[lang]"
                              :enableRtl="lang === 'per'"
                              floatLabelType="Auto"
                              :showClearButton="true"
                              v-model="mapPubTableOptions.filter"
                            ></ejs-textbox>
                          </b-col>

                          <b-col lg="1" sm="1" md="1" class="my-1">
                            <div
                              style="
                                font-size: 24px;
                                display: flex;
                                justify-content: end;
                              "
                            >
                              <i @click="addMapPubItem(mapType)" class="reset">
                                <font-awesome-icon icon="fas fa-plus-square" />
                              </i>
                            </div>
                          </b-col>
                        </b-row>
                        <!--                        <ejs-grid-->
                        <!--                          ref="wb_admin"-->
                        <!--                          :width="'auto'"-->
                        <!--                          :locale="lang"-->
                        <!--                          height="100%"-->
                        <!--                          :allowSorting="true"-->
                        <!--                          :key="mappingPub[mapType].items.length"-->
                        <!--                          :allowExcelExport="true"-->
                        <!--                          :allowFiltering="true"-->
                        <!--                          :allowPdfExport="true"-->
                        <!--                          :toolbar="toolbarGenerator"-->
                        <!--                          :toolbarClick="toolbarClick"-->
                        <!--                          :actionBegin="actionBegin"-->
                        <!--                          :actionComplete="actionComplete"-->
                        <!--                          :enableAdaptiveUI="screenWidth<=600"-->
                        <!--                          :allowPaging="true"-->
                        <!--                          :enableRtl="lang == 'per'"-->
                        <!--                          :pageSettings="{ pageSize: 5 }"-->
                        <!--                          :selectionSettings="curTableOptions.selectionOptions"-->
                        <!--                          :dataSource="mappingPub[mapType].items"-->
                        <!--                        >-->
                        <!--                          <e-columns>-->
                        <!--                            <e-column width="50" type="checkbox"></e-column>-->
                        <!--                            <e-column-->
                        <!--                              v-for="(item, index) in mapPubFields[mapType]"-->
                        <!--                              :key="index"-->
                        <!--                              :field="item.key"-->
                        <!--                              :width="screenWidth<=600?'100':'auto'"-->
                        <!--                              :headerText="item.label"-->
                        <!--                              :template="item.hasTemplate ? item.key : null"-->
                        <!--                            ></e-column>-->
                        <!--                          </e-columns>-->
                        <!--                        </ejs-grid>-->

                        <b-row
                          align-h="between"
                          class="g-0 p-2 border border-top-0 border-bottom-0"
                        >
                          <b-table
                            :items="mappingPub[mapType].items"
                            :fields="mapPubFields[mapType]"
                            :current-page="mapPubTableOptions.currentPage"
                            :per-page="mapPubTableOptions.perPage"
                            :filter="mapPubTableOptions.filter"
                            :filter-included-fields="
                              mapPubTableOptions.filterOn
                            "
                            :sort-by.sync="mapPubTableOptions.sortBy"
                            :sort-desc.sync="mapPubTableOptions.sortDesc"
                            :sort-direction="mapPubTableOptions.sortDirection"
                            :empty-text="dict.emptyStr[lang]"
                            stacked="md"
                            show-empty
                            small
                            @filtered="mapPubTableOnFiltered"
                            ref="mapPubJsonTable"
                          >
                            <template #head(topic)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(key)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(data.type)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(data.name)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(interval)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(uponChange)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(channel.pos)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(channel.iden)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #cell(modify)="row">
                              <div
                                style="display: flex; justify-content: flex-end"
                              >
                                <b-button
                                  pill
                                  size="sm"
                                  variant="outline-success"
                                  @click="
                                    editMapPubItem(mapType, row.item, $event)
                                  "
                                  class="mr-2"
                                  style="
                                    outline: none !important;
                                    border: none;
                                    box-shadow: none;
                                  "
                                >
                                  <b-icon
                                    icon="pencil-square"
                                    aria-hidden="true"
                                  ></b-icon>
                                </b-button>
                                <b-button
                                  pill
                                  size="sm"
                                  variant="outline-danger"
                                  @click="
                                    removeMapPubItem(mapType, row.item, $event)
                                  "
                                  class="mr-2"
                                  style="
                                    margin-inline: 10px;
                                    outline: none !important;
                                    border: none;
                                    box-shadow: none;
                                  "
                                >
                                  <b-icon
                                    icon="trash-fill"
                                    aria-hidden="true"
                                  ></b-icon>
                                </b-button>
                              </div>
                            </template>
                          </b-table>
                        </b-row>

                        <b-row
                          align-h="between"
                          class="
                            g-0
                            mb-0
                            p-2
                            border border-top-0
                            rounded-bottom
                          "
                        >
                          <b-col lg="2" sm="4" md="3" class="my-1">
                            <ejs-dropdownlist
                              :dataSource="mapPubTableOptions.pageOptions"
                              :fields="{ text: lang, value: 'value' }"
                              v-model="mapPubTableOptions.perPage"
                              @change="(val)=>{mapPubTableOptions.perPage=val.value}"
                            ></ejs-dropdownlist>
                          </b-col>

                          <b-col lg="4" sm="6" md="5" class="my-1">
                            <b-pagination
                              v-model="mapPubTableOptions.currentPage"
                              :total-rows="mapPubTableOptions.totalRows"
                              :per-page="mapPubTableOptions.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>

                    <div v-show="activeTab=='SubscribeMapping'">
                      <b-container fluid class="yekan p-0">
                        <div
                          :class="
                            mapType === 'MTR22'
                              ? 'fw-bolder text-center border border-bottom-0 rounded-top p-1 text-light'
                              : 'fw-bolder text-center border rounded p-1 mb-2 text-light'
                          "
                          style="
                            background-color: #a5b4fc;
                            width: 100%;
                            border-color: #a5b4fc !important;
                          "
                        >
                          {{ mapTypeText }}
                        </div>

                        <b-row
                          v-if="mapType === 'MTR22'"
                          align-h="between"
                          class="
                            g-0
                            mb-2
                            p-2
                            border border-top-0
                            rounded-bottom
                          "
                        >
                          <b-col lg="4" sm="6" md="5">
                            <ejs-textbox
                              :autocomplete="'off'"
                              v-model="mappingSub.MTR22.topic"
                              :readOnly="true"
                              placeholder="TOPIC"
                              :enableRtl="lang === 'per'"
                              floatLabelType="Always"
                              :minLength="6"
                              :maxLength="50"
                            ></ejs-textbox>
                          </b-col>
                        </b-row>

                        <b-row
                          align-h="between"
                          class="g-0 p-2 border rounded-top border-bottom-0"
                        >
                          <b-col lg="4" sm="6" md="5" class="my-1">
                            <ejs-textbox
                              :value="mapSubTableOptions.filter"
                              :placeholder="dict.search[lang]"
                              :enableRtl="lang === 'per'"
                              floatLabelType="Auto"
                              :showClearButton="true"
                              v-model="mapSubTableOptions.filter"
                            ></ejs-textbox>
                          </b-col>

                          <b-col lg="1" sm="1" md="1" class="my-1">
                            <div
                              style="
                                font-size: 24px;
                                display: flex;
                                justify-content: end;
                              "
                            >
                              <i @click="addMapSubItem(mapType)" class="reset">
                                <font-awesome-icon icon="fas fa-plus-square" />
                              </i>
                            </div>
                          </b-col>
                        </b-row>

                        <b-row
                          align-h="between"
                          class="g-0 p-2 border border-top-0 border-bottom-0"

                        >
                          <b-table
                            :items="mappingSub[mapType].items"
                            :fields="mapSubFields[mapType]"
                            :current-page="mapSubTableOptions.currentPage"
                            :per-page="mapSubTableOptions.perPage"
                            :filter="mapSubTableOptions.filter"
                            :filter-included-fields="
                              mapSubTableOptions.filterOn
                            "
                            :empty-text="dict.emptyStr[lang]"
                            :sort-by.sync="mapSubTableOptions.sortBy"
                            :sort-desc.sync="mapSubTableOptions.sortDesc"
                            :sort-direction="mapSubTableOptions.sortDirection"
                            stacked="md"
                            show-empty
                            small
                            @filtered="mapSubTableOnFiltered"
                            ref="mapSubJsonTable"
                          >
                            <template #head(channel.iden)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(channel.pos)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(data.type)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #head(data.name)="row">
                              {{ row.field[lang] }}
                            </template>
                            <template #cell(modify)="row">
                              <div
                                style="display: flex; justify-content: flex-end"
                              >
                                <b-button
                                  pill
                                  size="sm"
                                  variant="outline-success"
                                  @click="
                                                                editMapSubItem(mapType, row.item, $event)
                                                              "
                                  class="mr-2"
                                  style="
                                                                outline: none !important;
                                                                border: none;
                                                                box-shadow: none;
                                                              "
                                >
                                  <b-icon
                                    icon="pencil-square"
                                    aria-hidden="true"
                                  ></b-icon>
                                </b-button>
                                <b-button
                                  pill
                                  size="sm"
                                  variant="outline-danger"
                                  @click="
                                                                removeMapSubItem(mapType, row.item, $event)
                                                              "
                                  class="mr-2"
                                  style="
                                                                margin-inline: 10px;
                                                                outline: none !important;
                                                                border: none;
                                                                box-shadow: none;
                                                              "
                                >
                                  <b-icon
                                    icon="trash-fill"
                                    aria-hidden="true"
                                  ></b-icon>
                                </b-button>
                              </div>
                            </template>
                          </b-table>
                        </b-row>

                        <b-row
                          align-h="between"
                          class="
                            g-0
                            mb-0
                            p-2
                            border border-top-0
                            rounded-bottom
                          "
                        >
                          <b-col lg="2" sm="4" md="3" class="my-1">
                            <ejs-dropdownlist
                              :dataSource="mapSubTableOptions.pageOptions"
                              :fields="{ text: lang, value: 'value' }"
                              v-model="mapSubTableOptions.perPage"
                              @change="(val)=>{mapSubTableOptions.perPage=val.value}"
                            ></ejs-dropdownlist>
                          </b-col>

                          <b-col lg="4" sm="6" md="5" class="my-1">
                            <b-pagination
                              v-model="mapSubTableOptions.currentPage"
                              :total-rows="mapSubTableOptions.totalRows"
                              :per-page="mapSubTableOptions.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                          </b-col>
                        </b-row>
                      </b-container>

                      <!-- <b-collapse v-model="isMapClpsd1">
                                                <b-card>

                                                </b-card>
                                            </b-collapse>

                                            <b-collapse v-model="isMapClpsd2">
                                                <b-container fluid class="yekan p-0">

                                                    <div class="fw-bolder text-center border rounded p-1 mb-2 text-light" style="background-color:#a5b4fc;  width: 100%; border-color:#a5b4fc !important">
                                                        {{mapTypeText}}
                                                    </div>

                                                    <b-row align-h="between" class="g-0 p-2 border rounded-top  border-bottom-0" style="background-color:#eef2ff; border-color:#a5b4fc !important" >
                                                        <b-col lg="4" sm="6" md="5" class="my-1">
                                                            <b-form-group
                                                            label-for="filter-input"
                                                            >
                                                            <b-input-group size="sm">
                                                                <b-form-input
                                                                id="filter-input"
                                                                v-model="filter"
                                                                type="search"
                                                                placeholder="Type to Search"
                                                                ></b-form-input>

                                                                <b-input-group-append>
                                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            </b-form-group>
                                                        </b-col>

                                                        <b-col lg="1" sm="1" md="1" class="my-1">
                                                            <div style="font-size: 24px; display: flex; justify-content: end;">
                                                                <i @click="addMapSubJsonItem" class="reset"><font-awesome-icon icon="fas fa-plus-square"/></i>
                                                            </div>
                                                        </b-col>
                                                    </b-row>

                                                    <b-row align-h="between" class="g-0 p-2 border border-top-0 border-bottom-0" style="background-color:#fae8ff; border-color:#a5b4fc !important" >
                                                        <b-table
                                                        :items="mappingSub.JSON.items"
                                                        :fields="mapSubJsonFields"
                                                        :current-page="currentPage"
                                                        :per-page="perPage"
                                                        :filter="filter"
                                                        :filter-included-fields="filterOn"
                                                        :sort-by.sync="sortBy"
                                                        :sort-desc.sync="sortDesc"
                                                        :sort-direction="sortDirection"
                                                        stacked="md"
                                                        show-empty
                                                        small
                                                        @filtered="onFiltered"
                                                        ref="mapSubJsonTable"
                                                        >
                                                            <template #cell(modify)="row" >
                                                                <div style="display:flex; justify-content:flex-end;">
                                                                <b-button pill size="sm" variant="outline-success" @click="editMapSubJsonItem(row.item,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                                                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                                                </b-button>
                                                                <b-button pill size="sm" variant="outline-danger" @click="removeMapSubJsonItem(row.item,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                                                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                                                </b-button>
                                                                </div>
                                                            </template>

                                                            <template #cell(data)="row" >
                                                                <i v-if="row.item.data">{{row.item.data.name}}</i>

                                                            </template>
                                                        </b-table>
                                                    </b-row>

                                                    <b-row align-h="between" class="g-0 mb-0 p-2 border border-top-0 rounded-bottom" style="background-color:#eef2ff; border-color:#a5b4fc !important" >
                                                        <b-col lg="2" sm="4" md="3" class="my-1">
                                                            <b-form-group
                                                            label-for="per-page-select"
                                                            >
                                                            <b-form-select
                                                                style="width:100%;"
                                                                id="per-page-select"
                                                                v-model="perPage"
                                                                :options="pageOptions"
                                                                size="sm"
                                                            ></b-form-select>
                                                            </b-form-group>
                                                        </b-col>

                                                        <b-col lg="4" sm="6" md="5" class="my-1">
                                                            <b-pagination
                                                            v-model="currentPage"
                                                            :total-rows="totalRows"
                                                            :per-page="perPage"
                                                            align="fill"
                                                            size="sm"
                                                            class="my-0"
                                                            ></b-pagination>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                            </b-collapse>

                                            <b-collapse v-model="isMapClpsd3">
                                                <b-card>

                                                </b-card>
                                            </b-collapse>

                                            <b-collapse v-model="isMapClpsd4">
                                                <b-card>

                                                </b-card>
                                            </b-collapse> -->
                    </div>

                    <div v-show="activeTab=='Status'">
                      <b-collapse v-model="isMapClpsd1">
                        <b-card></b-card>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd2">
                        <b-container fluid class="yekan p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-form-group label-for="filter-input">
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="filter-input"
                                    v-model="statTableOptions.filter"
                                    type="search"
                                    placeholder="Type to Search"
                                  ></b-form-input>

                                  <b-input-group-append>
                                    <b-button
                                      :disabled="!statTableOptions.filter"
                                      @click="statTableOptions.filter = ''"
                                    >Clear
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="addStatJsonItem" class="reset">
                                  <font-awesome-icon
                                    icon="fas fa-plus-square"
                                  />
                                </i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                            style="
                              background-color: #fae8ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-table
                              :items="status.JSON.items"
                              :fields="statJsonFields"
                              :current-page="statTableOptions.currentPage"
                              :per-page="statTableOptions.perPage"
                              :filter="statTableOptions.filter"
                              :filter-included-fields="
                                statTableOptions.filterOn
                              "
                              :sort-by.sync="statTableOptions.sortBy"
                              :sort-desc.sync="statTableOptions.sortDesc"
                              :sort-direction="statTableOptions.sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="statusTableOnFiltered"
                              ref="statusJsonTable"
                            >
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="editStatJsonItem(row.item, $event)"
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeStatJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <template #cell(data)="row">
                                <i v-if="row.item.data">{{
                                    row.item.data.name
                                  }}</i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                            style="
                              background-color: #eef2ff;
                              border-color: #a5b4fc !important;
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <ejs-dropdownlist
                                :dataSource="statTableOptions.pageOptions"
                                :fields="{ text: lang, value: 'value' }"
                                v-model="statTableOptions.perPage"
                                @change="(val)=>{statTableOptions.perPage=val.value}"
                              ></ejs-dropdownlist>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="statTableOptions.currentPage"
                                :total-rows="statTableOptions.totalRows"
                                :per-page="statTableOptions.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd3">
                        <b-card></b-card>
                      </b-collapse>

                      <b-collapse v-model="isMapClpsd4">
                        <b-card></b-card>
                      </b-collapse>
                    </div>

                    <div v-show="activeTab=='Attribute'">
                      <div>
                        <ejs-grid
                          ref="attributes_sec"
                          :width="'auto'"
                          :locale="lang"
                          height="100%"
                          :allowSorting="true"
                          :toolbar="['Search']"
                          :allowPaging="true"
                          :enableRtl="lang == 'per'"
                          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
                          :dataSource="mappingSettings"
                        >
                          <e-columns>
                            <e-column
                              v-for="(item, index) in attrsFields"
                              :key="index"
                              :field="item.key"
                              :headerText="item.label"
                              :template="item.hasTemplate ? item.key : null"
                            ></e-column>
                          </e-columns>

                          <template v-slot:modify="{data}">
                            <div class="d-flex justify-content-between col-3">
                              <span class="e-icons e-send-1" style="font-size:17px" @click="sendSeting(data)"></span>
                              <span class="e-icons e-changes-accept" v-if="data.newPendingSettings"
                                    @click="applySeting(data)" style="font-size:17px"></span>
                            </div>
                          </template>
                        </ejs-grid>
                      </div>
                    </div>
                    <div v-if="activeTab=='Telemetry'">
                      <b-form>
                        <!-- Input fields for Tab 4 -->
                      </b-form>
                    </div>
                    <div v-if="activeTab=='Events'">
                      <b-form>
                        <!-- Input fields for Tab 4 -->
                      </b-form>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row px-5 my-3">
                      <div class="col-md-5 col-12">
                        <ejs-textbox
                          :autocomplete="'off'"
                          v-model="name"
                          :enabled="!registred"
                          :placeholder="dict.Name[lang]"
                          :required="true"
                          :enableRtl="lang === 'per'"
                          :minLength="6"
                          :maxLength="50"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-5 col-12">
                        <ejs-dropdownlist
                          :dataSource="mapTypeList"
                          v-model="mapType"
                          :fields="{ text: lang, value: 'value' }"
                          :placeholder="dict.mapType[lang]"
                          :enableRtl="lang === 'per'"
                          :required="true"
                        ></ejs-dropdownlist>
                      </div>
                      <div
                        class="col-md"
                        :class="{
                          'text-end': lang == 'eng',
                          'text-start': lang == 'per',
                        }"
                      >
                        <b-button class="w-auto" @click="registerClient"
                        >{{ dict.nextBtn[lang] }}
                        </b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="modal-text-coppied" hide-footer hide-header centered size="sm">
      <div class="my-modal-text">{{ dict.copy[lang] }}</div>
    </b-modal>
    <jsonItemEditor></jsonItemEditor>
    <statJsonItemEditor />
  </div>
</template>

<script>
/* eslint-disable */

import jsonItemEditor from "./setContModalPubSubJson.vue";
import statJsonItemEditor from "./setContMasterModalStatusJson.vue";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";

import crypto from "crypto";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "masterEdit",
  components: {
    jsonItemEditor,
    statJsonItemEditor
  },
  data() {
    return {
      activeTab: "ConnectionData",
      dict: {
        emptyStr: { per: "هیچ رکوردی برای نمایش وجود ندارد", eng: "No records to display" },
        secWords: {
          per: "کاراکترهای مجاز برای نام کاربری (user) و رمزعبور فقط حروف بزرگ و کوچک انگلیسی و اعداد می باشند.",
          eng: "Allowed characters for username and password are only upper and lower case English letters and numbers."
        },
        topic: { per: "TOPIC", eng: "TOPIC" },
        nextBtn: { per: "ادامه", eng: "Next" },
        dir: { per: "rtl", eng: "ltr" },
        copy: { per: "متن موردنظر در کلیپ بورد کپی شد.", eng: "Message copied to clipboard!" },
        Add: { per: "ایجاد", eng: "Add" },
        Edit: { per: "ویرایش", eng: "Edit" },
        ConnectionData: { per: "اتصال داده", eng: "Connection data" },
        Security: { per: "امنیت", eng: "Security" },
        PublishMapping: { per: "ارسال داده", eng: "Publish Mapping" },
        SubscribeMapping: { per: "دریافت داده", eng: "Subscribe Mapping" },
        Status: { per: "وضعیت", eng: "Status" },
        Attribute: { per: "اتربیوت", eng: "Attribute" },
        Telemetry: { per: "تله متری", eng: "Telemetry" },
        Events: { per: "رویدادها", eng: "Events" },
        search: { per: "جستجو", eng: "Search" },
        clear: { per: "پاک کردن", eng: "clear" },
        Name: { per: "نام", eng: "Name" },
        mapType: { per: "نگاشت", eng: "Mapping Type" },
        userName: { per: "نام کاربری", eng: "user" },
        pass: { per: "رمزعبور", eng: "password" },
        host: { per: "هاست (میزبان)", eng: "host" },
        port: { per: "پورت", eng: "port" },
        Basic: { eng: "Basic", per: "بااستفاده از رمز عبور" },
        Certificate: { eng: "Certificate", per: "بااستفاده از گواهی" },
        caFile: { eng: "CA file", per: "فایل CA file" },
        pkFile: { eng: "private key file", per: "فایل private key" },
        certFile: { eng: "certificate file", per: "فایل certificate" }
      },
      tabs: [
        { name: "ConnectionData", value: "ConnectionData" },
        { name: "Security", value: "Security" },
        { name: "PublishMapping", value: "PublishMapping" },
        { name: "SubscribeMapping", value: "SubscribeMapping" },
        { name: "Attribute\"", value: "Attribute" }
      ],
      id: "",
      name: "",
      parent: "",
      translateTable: {
        "Topic": { per: "", eng: "Topic" },
        "Key": { per: "", eng: "Key" },
        "upon change": { per: "", eng: "upon" },
        "Identifier": { per: "", eng: "Identifier" },
        "Position": { per: "", eng: "Position" },
        "type": { per: "", eng: "type" },
        "data": { per: "", eng: "data" },
        "interval": { per: "", eng: "interval" }
      },
      title: "",
      registred: false,
      newGenerate: false,
      newGenerated: false,
      mapType: null,

      security: {
        type: "basic",
        basic: {
          user: "",
          pass: ""
        },
        cert: {
          caCert: "",
          privateKey: "",
          cert: ""
        }
      },

      mappingPub: {
        type: "",
        MTR22: {
          topic: "",
          items: []
        },
        JSON: {
          items: []
        }
      },

      mappingSub: {
        type: "",
        MTR22: {
          topic: "",
          items: []
        },
        JSON: {
          items: []
        }
      },

      status: {
        type: "",
        MTR22: {
          topic: ""
        },
        JSON: {
          items: []
        }
      },
      connection: {
        host: "",
        port: "",
        clientId: "",
        idPrefix: false
      },
      mappingSettings: {},

      submitted: false,
      successful: false,
      message: "",

      mapTypeTextList: {
        MTR22: "MTR22 Mapping",
        JSON: "JSON Mapping",
        BYTE: "Byte Mapping",
        CUST: "Custom mapping"
      },
      mapTypeList: [
        { value: null, per: "یک نوع انتخاب کنید", eng: "select a type" },
        { value: "MTR22", per: "MTR22 Mapping", eng: "MTR22 Mapping" },
        { value: "JSON", per: "Json Mapping", eng: "Json Mapping" },
        { value: "BYTE", per: "Byte Mapping", eng: "Byte Mapping", disabled: true },
        { value: "CUST", per: "Custom mapping", eng: "Custom mapping", disabled: true }
      ],


      // mapJsonFields: [
      //     // { key: '_id', label: 'id', sortable: true},
      //     { key: 'topic', label: 'Topic', sortable: true, sortDirection: 'asc' },
      //     { key: 'key', label: 'Key', sortable: true},
      //     { key: 'data', label: 'data', sortable: true},
      //     { key: 'modify', label: '' }
      // ],

      mapPubFields: {
        JSON: [
          { key: "topic", per: "تاپیک", eng: "Topic", label: "Topic", sortable: true, sortDirection: "asc" },
          { key: "key", per: "کلید", eng: "Key", label: "Key", sortable: true },
          { key: "data.type", per: "نوع", eng: "type", label: "type", sortable: true },
          { key: "data.name", per: "داده", eng: "data", label: "data", sortable: true },
          { key: "interval", per: "فاصله زمانی", eng: "interval", label: "interval", sortable: true },
          { key: "uponChange", per: "به محض تغییر", eng: "upon change", label: "upon change", sortable: true },
          { key: "modify", per: "", eng: "", label: "" }
        ],
        MTR22: [
          {
            key: "channel.iden",
            eng: "Identifier",
            per: "معرف",
            label: "Identifier",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "channel.pos", eng: "Position", per: "موقعیت", label: "Position", sortable: true },
          { key: "data.type", eng: "type", per: "نوع", label: "type", sortable: true },
          { key: "data.name", eng: "data", per: "داده", label: "data", sortable: true },
          { key: "interval", eng: "interval", per: "فاصله زمانی", label: "interval", sortable: true },
          { key: "uponChange", eng: "upon change", per: "به محض تغییر", label: "upon change", sortable: true },
          {
            key: "modify", eng: "",
            per: "", label: ""
          }
        ]

      },

      attrsFields: [
        { key: "stgreq", label: "stgreq", hasTemplate: false, sortDirection: "asc" },
        { key: "stgres", label: "stgres", hasTemplate: false },
        { key: "newPendingSettings", label: "newPendingSettings", hasTemplate: false },
        { key: "currentSettings", label: "currentSettings", hasTemplate: false },
        { key: "pendingSettings", label: "pendingSettings", hasTemplate: false },
        { key: "modify", label: "", hasTemplate: true }
      ],
      mapSubFields: {
        JSON: [
          { key: "topic", label: "Topic", sortable: true, sortDirection: "asc" },
          { key: "key", label: "Key", sortable: true },
          { key: "data.type", label: "type", sortable: true },
          { key: "data.name", label: "data", sortable: true },
          { key: "modify", label: "" }
        ],
        MTR22: [
          {
            key: "channel.iden",
            eng: "Identifier",
            per: "معرف",
            label: "Identifier",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "channel.pos", eng: "Position", per: "موقعیت", label: "Position", sortable: true },
          { key: "data.type", eng: "type", per: "نوع", label: "type", sortable: true },
          { key: "data.name", eng: "data", per: "داده", label: "data", sortable: true },
          { key: "modify", label: "" }
        ]
      },


      statJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "type", label: "Type", sortable: true, sortDirection: "asc" },
        { key: "topic", label: "Topic", sortable: true },
        { key: "key", label: "Key", sortable: true },
        { key: "value", label: "Value", sortable: true },
        { key: "heartbeat.enable", label: "heartbeat", sortable: true },
        { key: "heartbeat.timeout", label: "timeout", sortable: true },
        { key: "message", label: "Message", sortable: true },
        { key: "modify", label: "" }
      ],

      mapPubTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      mapSubTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },


      statTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      isSecClpsd1: false,
      isSecClpsd2: true,
      isSecClpsd3: false,

      isMapClpsd1: false,
      isMapClpsd2: false,
      isMapClpsd3: false,
      isMapClpsd4: false

    };
  },
  watch: {
    secType(newVal) {
      this.isSecClpsd1 = newVal === "anony";
      this.isSecClpsd2 = newVal === "basic";
      this.isSecClpsd3 = newVal === "cert";
    },
    mapType(newVal) {
      this.mappingPub.type = newVal;
      this.mappingSub.type = newVal;
      this.status.type = newVal;

      this.isMapClpsd1 = newVal === "MTR22";
      this.isMapClpsd2 = newVal === "JSON";
      this.isMapClpsd3 = newVal === "BYTE";
      this.isMapClpsd4 = newVal === "CUST";
    },
    child2parent(newValue, oldValue) {

      if ((newValue.task === "editMapJson") && (newValue.to.type === "masterPub")) {
        var map = newValue.to.map;
        console.log("PPP", newValue);
        if (newValue.type === "New") {
          if (this.mappingPub[map].items) {
            this.mappingPub[map].items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            this.mappingPub[map]["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          var indx = this.mappingPub[map].items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.mappingPub[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        // console.log(this.mappingPub[map])
        this.mapPubTableOptions.totalRows = this.mappingPub[map].items.length;
        this.$refs.mapPubJsonTable.refresh();
      } else if ((newValue.task === "editMapJson") && (newValue.to.type === "masterSub")) {
        var map = newValue.to.map;
        if (newValue.type === "New") {
          if (this.mappingSub[map].items) {
            this.mappingSub[map].items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            this.mappingSub[map]["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          var indx = this.mappingSub[map].items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.mappingSub[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        this.mapSubTableOptions.totalRows = this.mappingSub[map].items.length;
        this.$refs.mapSubJsonTable.refresh();
      } else if (newValue.task === "editStatJson") {
        if (newValue.type === "New") {
          if (this.status.JSON.items) {
            this.status.JSON.items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            this.status.JSON["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          var indx = this.status.JSON.items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.status.JSON.items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        this.statTableOptions.totalRows = this.status.JSON.items.length;
        this.$refs.statusJsonTable.refresh();
      }
    }
  },
  computed: {
    type() {
      return this.$store.state.chpge.type;
    },
    db() {
      return this.$store.state.chpge.db;
    },
    index() {
      return this.$store.state.chpge.index;
    },
    secType() {
      return this.security.type;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    mapTypeText() {
      return this.mapTypeTextList[this.mapType];
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    tabGeneration() {
      if (this.registred) {
        return this.tabs;
      } else return this.tabs.filter(item => item.value == "ConnectionData");
    }
  },
  methods: {
    sendSeting(data) {
      this.$store.dispatch("data/sendMasterSetting", {
        stgreqtopic: data.stgreq
      }).then(() => {
        ToastUtility.show({
          title: "successfully!",
          content: "request sent successfully!",
          timeOut: 3000,
          cssClass: "e-toast-success", // Add a custom class for Error styling
          position: { X: "Center", Y: "Top" }, // Adjust the position
          showCloseButton: true

        });
      }).catch(() => {
        ToastUtility.show({
          title: "Error",
          content: "request failed",
          timeOut: 3000,
          cssClass: "e-toast-danger",
          position: { X: "Center", Y: "Top" },
          showCloseButton: true
        });
      });
    },
    applySeting(data) {
      this.$refs.attributes_sec.ej2Instances.refresh();
      this.$store.dispatch("data/applyMasterSetting", {
        masterId: this.id, pendingSettings: data.pendingSettings, masterName: this.name
      }).then(() => {
        ToastUtility.show({
          title: "successfully!",
          content: "request sent successfully!",
          timeOut: 3000,
          cssClass: "e-toast-success", // Add a custom class for Error styling
          position: { X: "Center", Y: "Top" }, // Adjust the position
          showCloseButton: true

        });
      }).catch(() => {
        ToastUtility.show({
          title: "Error",
          content: "request failed",
          timeOut: 3000,
          cssClass: "e-toast-danger",
          position: { X: "Center", Y: "Top" },
          showCloseButton: true
        });
      });
    },
    initModal() {
      this.title = this.dict[this.type][this.lang] + " MQTT client";

      if (this.type === "Add") {
        this.parent = { ptype: this.db.ptype, pid: this.db.pid };
      } else if (this.type === "Edit") {
        // this.topic = this.db.topic;
        this.registred = true;
        this.id = this.db.id;
        this.name = this.db.name;
        this.mapType = this.db.mapType;
        this.parent = this.db.parent;
        this.security = this.db.security;
        this.mappingPub = this.db.mappingPub;
        this.mappingSub = this.db.mappingSub;
        this.status = this.db.status;
        this.connection = this.db.connection;
        this.mappingSettings = [this.db.mappingSettings];

        this.mapPubTableOptions.totalRows = this.mappingPub[this.mappingPub.type].items.length;
        this.mapSubTableOptions.totalRows = this.mappingSub[this.mappingSub.type].items.length;
        this.statTableOptions.totalRows = this.status.JSON.items.length;
      }
    },
    onReset() {
      // this.topic = null
      this.id = null;
      this.type = "";
      this.name = "";
      this.mapType = "";
      this.parent = {};
      this.security = {};
      this.mappingPub = {};
      this.mappingSub = {};
      this.status = {};
      this.connection = {};
      this.mappingSettings = [];
      this.activeTab = "";
      this.mapPubTableOptions.totalRows = 0;
      this.mapSubTableOptions.totalRows = 0;
      this.statTableOptions.totalRows = 0;

      this.$store.dispatch("chpge/changePage", "controllerSettings");
    },
    registerClient() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          console.log("register");
          var data = {
            // id:this.id,
            // type:"Master",
            // name: this.name,
            // parent:this.parent,
            // security:this.security,
            // mappingPub:this.mappingPub,
            // status:this.status,
            // connection: this.connection,

            type: "Master",
            name: this.name,
            parent: this.parent,
            mapType: this.mapType

          };
          this.$store.dispatch("data/createMaster", data).then(
            data => {
              console.log(data.message);

              this.registred = true;
              this.id = data.message._id;
              this.name = data.message.name;
              this.mapType = data.message.mapType;
              this.parent = data.message.parent;
              this.security = data.message.security;
              this.mappingPub = data.message.mappingPub;
              this.mappingSub = data.message.mappingSub;
              this.status = data.message.status;
              this.connection = data.message.connection;
              this.mappingSettings = [data.message.mappingSettings];

              this.mapPubTableOptions.totalRows = 0;
              this.mapSubTableOptions.totalRows = 0;
              this.statTableOptions.totalRows = 0;
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              alert(this.message);
            }
          );
        }
      });

    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        console.log(isValid);
        if (isValid) {
          var data = {
            id: this.id,
            type: "Master",
            name: this.name,
            mapType: this.mapType,
            parent: this.parent,
            security: this.security,
            mappingPub: this.mappingPub,
            mappingSub: this.mappingSub,
            status: this.status,
            connection: this.connection

          };
          console.log("----", data);

          // if (this.type==='Add'){
          //     this.$store.dispatch('data/createMaster', data).then(
          //         data => {
          //             this.message = data.message;
          //             this.successful = true;
          //             // alert(this.message)
          //             this.getContConf();
          //             this.$store.dispatch('chpge/changePage','controllerSettings')
          //         },
          //         error => {
          //             this.message =
          //                 (error.response && error.response.data && error.response.data.message) ||
          //                 error.message ||
          //                 error.toString();
          //             this.successful = false;
          //             alert(this.message)
          //         }
          //     );
          // }else if (this.type==='Edit'){

          this.$store.dispatch("data/editMaster", data).then(
            data => {
              this.message = data.message;
              this.successful = true;
              //alert(this.message)
              this.getContConf();
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              alert(this.message);
            }
          );
          // }
        }
      });
    },
    getContConf() {
      this.$store.dispatch("data/getContConf").then(
        data => {
          console.log("master: ", JSON.stringify(data));
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    downloadFile(type) {
      console.log(this.id, type);
      this.$store.dispatch("data/getFileMaster", { id: this.id, type: type })

        .then((response) => {
          // Create a download link for the received file
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;

          let fileName = "";
          if (type === "CA")
            fileName = "ca.crt";
          else if (type === "PK")
            fileName = "client.key";
          else if (type === "CF")
            fileName = "client.crt";

          link.setAttribute("download", fileName);
          document.body.appendChild(link);

          // Click the download link to initiate the file download
          link.click();

          // Clean up the temporary download link
          document.body.removeChild(link);
        })
        .catch(error => {
          alert("Error downloading file:", error);
        });
    },
    copyToClipboard(value) {
      // Create a temporary textarea element to copy the text to the clipboard
      const textarea = document.createElement("textarea");
      textarea.value = value;
      textarea.style.position = "fixed"; // Set the textarea off-screen
      document.body.appendChild(textarea);
      textarea.select();

      try {
        // Use the document.execCommand('copy') method to copy the text to the clipboard
        const success = document.execCommand("copy");
        if (success) {
          this.$bvModal.show("modal-text-coppied");
          setTimeout(() => {
            this.$bvModal.hide("modal-text-coppied");
          }, 2000);
        } else {
          console.error("Failed to copy text to clipboard.");
        }
      } catch (err) {
        console.error("Unable to copy text: ", err);
      }

      // Remove the temporary textarea element
      document.body.removeChild(textarea);
      this.newGenerate = false;
    },
    generateRandomString(length) {
      this.newGenerate = true;
      this.newGenerated = true;
      const newStr = crypto.randomBytes(length).toString("base64");
      this.security.basic.pass = newStr.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "");
    },
    mapPubTableOnFiltered(filteredItems) {
      this.mapPubTableOptions.totalRows = filteredItems.length;
      this.mapPubTableOptions.currentPage = 1;
    },
    mapSubTableOnFiltered(filteredItems) {
      this.mapSubTableOptions.totalRows = filteredItems.length;
      this.mapSubTableOptions.currentPage = 1;
    },
    statusTableOnFiltered(filteredItems) {
      this.statTableOptions.totalRows = filteredItems.length;
      this.statTableOptions.currentPage = 1;
    },

    addMapPubItem(type) {
      var channels = [];
      if (type === "MTR22") {
        this.mappingPub.MTR22.items.forEach(x => channels.push(x.channel));
      }
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "masterPub", map: type, id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-map-pub-json-item");
    },
    editMapPubItem(type, item) {
      var channels = [];
      if (type === "MTR22") {
        this.mappingPub.MTR22.items.forEach(x => channels.push(x.channel));
      }
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "masterPub", map: type, id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-map-pub-json-item");
    },
    removeMapPubItem: function(type, item, event) {
      console.log(type);
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.mappingPub[type].items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.mappingPub[type].items.splice(indx, 1);
              this.mapPubTableOptions.totalRows = this.mappingPub[type].items.length;
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    addMapSubItem(type) {
      var channels = [];
      if (type === "MTR22") {
        this.mappingSub.MTR22.items.forEach(x => channels.push(x.channel));
      }
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "masterSub", map: type, id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-map-pub-json-item");
    },
    editMapSubItem(type, item) {
      var channels = [];
      if (type === "MTR22") {
        this.mappingSub.MTR22.items.forEach(x => channels.push(x.channel));
      }
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "masterSub", map: type, id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-map-pub-json-item");
    },
    removeMapSubItem: function(type, item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.mappingSub[type].items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.mappingSub[type].items.splice(indx, 1);
              this.mapSubTableOptions.totalRows = this.mappingSub[type].items.length;
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    addStatJsonItem() {
      this.$store.dispatch("chpge/parent2child", { task: "editStatJson", type: "New", item: {} });
      this.$root.$emit("bv::show::modal", "modal-status-json-item");
    },
    editStatJsonItem(item) {
      this.$store.dispatch("chpge/parent2child", { task: "editStatJson", type: "Edit", item: item });

      this.$root.$emit("bv::show::modal", "modal-status-json-item");
    },
    removeStatJsonItem: function(item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.status.JSON.items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.status.JSON.items.splice(indx, 1);
              this.statTableOptions.totalRows = this.status.JSON.items.length;
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    }

  },
  mounted() {
    this.initModal();
  },
  provide: {
    grid: [Page, Sort, Toolbar, Edit, Search]
  }
};
</script>

<style scoped>
.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}

.my-modal-text {
  text-align: center;
  font-weight: bold;
  color: #198754;
  /* background-color: aliceblue !important; */
  padding: 20px;
}
</style>
