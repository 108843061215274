<script>
export default {
  name: "toggleBtn",
  props: {
    value: {
      default: false
    },
    title: {
      default: ""
    },
  },
  data() {
    return {
      inputVal: false
    };
  },
  methods: {
    changeValue(val) {
      this.$emit("input", val.target.value == "false" ? false : true);
    }
  },
  watch: {
    value: {
      handler(val) {
        this.inputVal = val;
      }
    }
  },
  mounted() {
    this.inputVal = Boolean(this.value);
  }
};
</script>

<template>
  <div class="d-flex px-0 mx-0">
    <p class="mx-3">{{ title }}</p>
    <label class="switch mx-0">
      <input v-model="inputVal" onclick="$(this).val(this.checked ? true : false)" @change="changeValue($event)"
             type="checkbox" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #0d6efd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
