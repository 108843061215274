<template>
    <div>
        <b-modal
        id="modal-setDashControlNode"
        size="lg"
        centered
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div>
            <b-list-group flush>
                <b-list-group-item style="background-color:transparent;color:black;">
                    <div style="display:flex; flex-direction:row">
                        <b-form-group
                            label="control node"
                            label-for="type"
                            :invalid-feedback="errors.first('type')"
                            style="width:100%; padding: 0px 10px 10px 0px;"
                        >
                            <b-form-select
                                
                                style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                name="type"
                                size="lg"
                                v-model="controlNode.node"
                                :options="nodeList"
                                :state="(submitted)? (!errors.has('type')): null"  
                                v-validate="'required'"
                            ></b-form-select>
                        </b-form-group>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
        </b-modal>
    </div>
</template>




<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        props: {
            pid: String
        },
        
        data() {
            return {
                modalTitle:'',
                controlNode:{
                    node:null,
                    id:'',
                    type:null
                },
                component:{},
                // id:'',
                type:'',
                nodeList:[],
                idType:null,
                
                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='controlNode_modal'){
                    this.db = newValue.data
                    this.type = newValue.type
                }
            },
        },
        methods: {
            initModal: function(){
                this.getControlNodes()
                this.modalTitle = this.type + ' Control Node'
                if (this.type==='Edit')
                {
                    this.component._id          = this.db._id;
                    this.component.name       = this.db.name;
                    this.component.data        = this.db.data? this.db.data:{};
                    this.component.attribute    = this.db.attribute? this.db.attribute:{};

                    this.db.data       = this.db.data? this.db.data:{};
                    this.controlNode.id      = this.db.data.controlNode? this.db.data.controlNode.id:'';
                    this.controlNode.node    = this.db.data.controlNode? this.db.data.controlNode.node:null;
                    this.controlNode.type    = this.db.data.controlNode? this.db.data.controlNode.type:null;

                }

            },
            resetModal: function(){
                this.component.attribute = {};
                this.component.data = {};
                this.component._id = '';
                this.component.name = '';
                this.nodeList=null;
                this.idType = null;
                this.controlNode.id='',
                this.controlNode.node=null,
                this.controlNode.type=null,
                
                this.id=''
                this.type=''

                this.submitted = null;
                this.successful = null;
                this.message= null;
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.controlNode.type = this.idType[this.controlNode.node]
                        this.component.data = {controlNode: JSON.parse(JSON.stringify(this.controlNode))}
                        this.component.attribute = {}

                        this.$store.dispatch('chpge/child2parent', {task: 'setDashboard', type:'Edit', data: this.component})
                        
                        this.$nextTick(() => {
                            this.$bvModal.hide('modal-setDashControlNode')
                        })
                    }
                });
            },
            getControlNodes() {
                this.$store.dispatch('data/nodeHandler',{type:'get'}).then(
                data => {
                    this.nodeList = [  { value: null, text: 'یکی از نودهای کنترلی را انتخاب کنید'}];
                    this.idType={}
                    Object.entries(data.data).forEach( item => {
                        this.nodeList.push({ value: item[1].id, text: item[1].data.title})
                        this.idType[item[1].id] = item[1].type
                    });
                },
                error => {
                    this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                    // this.successful = false;
                }
                );
            },
            
        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .panel_header {
        padding: 10px;
        height: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-variant:small-caps;
        border-bottom: 2px solid rgb(210, 210, 210);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .panel_col {
        height: 100%; 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>