<template>
  <div class="w-full">
    <!--        <new-port-setter v-model="portType"/>-->
    <div class="row mx-auto mb-5">
      <div
        v-for="item in sections.shape"
        class="col-md-2 mx-1"
        :key="item.name"
      >
        <drag-able :img="item" :key="item.name" v-model="selectedElement" />
      </div>
    </div>
    <port-type-setter v-model="portType" />

    <!--    <div>-->
    <!--      <range-slider v-for="(item,index) in sections.size" :key="index" :title="item.name" :range-value="50"-->
    <!--                    v-model.number="item.value"/>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { mapState } from "vuex";
import DragAble from "@/components/processFlowComponents/dragDrop/dragAble.vue";
import { processFlow } from "@/store/processFlow.module";
import PortTypeSetter from "@/components/processFlowComponents/portTypeSetter.vue";
import { pipePort } from "@/mixins";

export default {
  name: "portCustomizer",
  components: { PortTypeSetter, DragAble },
  data() {
    return {
      selectedElement: {},
      sections: {
        shape: [

          // {
          //   element: "pipePort",
          //   name: "pipe new",
          //   img: require("@/assets/svgIcons/pipe.svg")
          // },
          // {element: 'portRight', name: 'pipe-left', img: require('@/assets/icons/pipe-left.svg')},
          {
            element: "portRect",
            name: "rectangle",
            img: require("@/assets/svgIcons/portRectangle.svg"),
            portType: ""
          },
          {
            element: "circlePort",
            name: "circle",
            img: require("@/assets/svgIcons/portCircle.svg"),
            portType: ""
          },
          {
            element: "customPipe",
            name: "pipe-right",
            img: require("@/assets/svgIcons/pipe.svg"),
          },
        ],
        position: [],
        size: [
          { name: "x", value: 1 },
          { name: "y", value: 1 }
        ]
      },
      theShape: "",
      portType: "in",
      editModal: false
    };
  },
  computed: {
    ...mapState(["portSetting"])
  },
  watch: {
    "sections.size": {
      handler(val) {
        this.portSetting.size.x = val[0].value;
        this.portSetting.size.y = val[1].value;
      },
      deep: true
    },
    portType() {
      this.portSetting.type = this.portType;
    },
    editModal() {
      processFlow.state.portSetting.shape = "";
      processFlow.state.portSetting.shape = this.selectedElement.element;
      processFlow.state.portSetting.args = this.selectedElement.position;
      processFlow.state.portSetting.type = this.portType;
    },
    selectedElement: {
      handler() {
        if (Object.keys(this.selectedElement).length > 0) this.editModal = true;
      }
    }
  }
};
</script>
