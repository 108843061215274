<template>
  <div style="height:100%; width:100%;">
    <div v-if="dataReady" class="card-header" style="min-height: 40px;">
      <div class="row">
        <div class="col-2 ">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" value="" id="CBEn" v-model="myData.status.enable" @change="onChangeStatus()">
          </div>
        </div>

        <div class="col-8 ">
          <p class="mb-0">
            {{ myData.attribute.title}}
          </p>
        </div>

        <div  class="col-2 text-center">
          <div class="d-flex" style="gap:5px;">
            <!-- <span v-if="!myData.status.enable" class="remove w-100" @click="changeTab" >
              <i v-if="activeTab==='tab1'" style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-gear" /></i>
              <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i> 
            </span> -->
            <span v-if="editable" class="remove w-100 text-danger" @click="removeWidget">
              <i><font-awesome-icon icon="fas fa-xmark" /></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataReady"  class="card-body p-1 d-flex justify-content-center align-items-center"  style="height: calc(100% - 98px); width:100%; overflow: hidden;">
      <div class="h-100 w-100" >
        <div fluid class="container-fluid p-2 h-100 " style="display:flex; flex-direction:column; ">
          <div class="h-100 w-100 p-2 econtainor" style="display:flex; flex-direction:column; ">
            <b-form-group
              label="Center Latitude"
              label-for="centLat"                                            
              :invalid-feedback="errors.first('centLat')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="centLat"
                type="text"
                placeholder="Enter Center Latitude"
                v-model="myData.parameters.automatic.param_1"
                :state="(submitted)? (!errors.has('centLat')): null"  
                v-validate="'required|decimal:10|min_value:-90|max_value:90'"
                style="width:100%"
              ></b-form-input>
            </b-form-group> 

            <b-form-group
              label="Center Logitude"
              label-for="centLng"                                            
              :invalid-feedback="errors.first('centLng')"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                name="centLng"
                type="text"
                placeholder="Enter Center Logitude"
                v-model="myData.parameters.automatic.param_2"
                :state="(submitted)? (!errors.has('centLng')): null"  
                v-validate="'required|decimal:10|min_value:-180|max_value:180'"
                style="width:100%"
              ></b-form-input>
            </b-form-group> 

            <b-form-group v-for="(indicator,i) in pannel.status.indicators" 
              :key="i"
              :label="indicator.title"
              :label-for="'stat_'+i"                                            
              :invalid-feedback="errors.first('stat_'+i)"
              style="width:100%; padding: 0px 0px 10px 0px;"
            >
              <b-form-input
                disabled
                :name="'stat_'+i"
                type="text"
                v-model="indicator.value"
                :state="(submitted)? (!errors.has('stat_'+i)): null"  
                style="width:100%"
              ></b-form-input>
            </b-form-group> 
            



          </div>
          

        </div>
      </div>

    </div>
    <div class="card-footer text-muted" style="min-height: 54px;">
      <div class="d-flex w-100 justify-content-between" >
        <div>
          <!-- <b-button variant="secondary"  style="margin-right:10px" @click="handleScheduleEditorModalReset" > Cancel </b-button> -->
          <b-button variant="primary"  @click="saveData('autoPanel')" > Apply </b-button>
        </div>
      </div>
    </div>
          
  </div>
</template>

<script>
/* eslint-disable */
  import myComponent_4 from "./myComponent_4.vue";
  import { v4 as uuidv4 } from 'uuid';

  import { ScheduleComponent, SchedulePlugin, Week, Month, Year,Agenda, Resize, DragAndDrop, ActionEventArgs,RecurrenceEditor } from '@syncfusion/ej2-vue-schedule';
  import { MultiSelect } from '@syncfusion/ej2-dropdowns';
  import { createElement } from '@syncfusion/ej2-base';
  import { DropDownList } from '@syncfusion/ej2-dropdowns';
  import { Slider } from '@syncfusion/ej2-inputs';
  import { DateTimePicker  } from '@syncfusion/ej2-calendars';
  import { FormValidator } from '@syncfusion/ej2-inputs';
  import { isNullOrUndefined } from '@syncfusion/ej2-base';
  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  import RoundSlider from "vue-round-slider";

  export default {
    components: {
        myComponent_4, //transform: scale(.75);
        'ejs-schedule': ScheduleComponent,
        PerfectScrollbar,
        RoundSlider
    },
    props: {
      plotId: String,
      data: Object,
      editable: Boolean,
      resized: Number
    },
    provide: {
        schedule: [Week, Year, Month, Agenda,DragAndDrop,Resize]
    },
    data() {
      return {

        isDisabled:true,

        status:{
          error: false,
          msg: 'no setting is selected'
        },

        pannel:{
          settingVis : false,

          
          status:{
            indicators:[
              { title:'Sun Altitude',
                value:'1',
                tag:'stat_1'},
              { title:'Time',
                value:'0',
                tag:'stat_2'},
            ],
            
          }
        },

        myData : {
          datas:[],
          attribute:{
            title:'controller',
          },
          parameters:{
            enable:false,
            schedules:[],
            settings:[],
            selectedSchedule:'',
            activeEvent:'',
            controlMode:'',
            automatic:{},
            manual:{},
            
          },
          status:{}
        },

        dataReady : false,
        submitted: false,
        successful: false,
        message: '',
      }
    },
    methods: {
      getData(){
        var dids=[];
        for (var d of this.myData.datas){
          dids.push(d._id)
        }
        if (dids.length > 0){
          this.$store.dispatch('panel/getData', {id:dids}).then(
            datas => {
            },
            error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
          )
        }
      },

      removeWidget() {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this widget')
        .then(value => {
          if (value===true){
            this.$store.dispatch('chpge/child2parent', {task: 'rmDashItem', item:this.plotId})
          }
        })
        .catch(err => {
        })
      },
      editWidget(){
        // console.log('editwidget',this.plotId)
        this.$root.$emit('bv::show::modal', this.plotId);
      },

      

 
      saveData(type){
        console.log('save data',type);
        var update = false;
        if (type==='autoPanel' && (this.myData.parameters.controlMode === 'automatic')){
          var bc = JSON.stringify(this.myData.parameters.automatic)
          // this.myData.parameters.automatic = {
          //   param_1: this.pannel.rsliderA.value,
          //   param_2: this.pannel.rsliderB.value,
          //   selectedSetting: this.pannel.selectedSetting
          // }
          // var ac = JSON.stringify(this.myData.parameters.automatic)
          // if (ac !== bc)
            update = true;
          
        }

        

        if (update){
          var sdata = [{
            id:this.myData.datas[0]._id, 
            value:{
              status: this.myData.status,
              parameters: this.myData.parameters,
              connections:this.myData.connections,
              type:this.myData.type
            }
          }]
          console.log('panel/setData7',sdata)
          this.$store.dispatch('panel/setData', {data:sdata})
          .then(
            data => {
              // this.$nextTick(() => {
              //   this.$bvModal.hide(this.plotId+'_schedule')
              // })
            },
            error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            }
          );
        } 
      },
      updateStatus(){
        var keys = Object.keys(this.myData.parameters.status)
        keys.forEach(key => {
          var value = this.myData.parameters.status[key]
          var indx = this.pannel.status.indicators.findIndex(x => x.tag === key)
          if (indx != -1){
            this.pannel.status.indicators[indx].value = value;
          }
          // indx = this.pannel.status.sliders.findIndex(x => x.tag === key)
          // if (indx != -1){
          //   this.pannel.status.sliders[indx].value = value;
          // }
        })
        

      },
      onChangeStatus(state) {
        var sdata = [{
          id:this.myData.datas[0]._id, 
          value:{
            status: this.myData.status,
            parameters: this.myData.parameters,
            connections:this.myData.connections,
            type:this.myData.type
          }
        }]
        console.log('panel/setData100',sdata)
        this.$store.dispatch('panel/setData', {data:sdata})
        .then(
          data => {
          },
          error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          }
        );
        
      },
    },

    mounted(){

      if (this.data && this.data.datas && this.data.attribute){
        this.myData = this.data
      }
      // console.log('===========control myData: ', this.myData)
      this.getData()
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      lang() {
        return this.$store.state.chpge.lang;
      }
    },
    watch: {
      updatedData(newValue) {
        // console.log('newdata')
        if (this.myData.datas.length > 0){
          for (var Data of newValue){
            // console.log('**************-------------******************0',Data,this.myData.datas[0]._id)
            if (JSON.stringify(Data.id) === JSON.stringify(this.myData.datas[0]._id)){
              var LMD = JSON.stringify(this.myData)
              
              this.myData = {...this.data,...Data.value}
              if (!this.myData['parameters']){
                this.myData['parameters'] = {   

                  schedules:[],
                  selectedSchedule:'',
                  activeEvent:'',
                  settings:[],
                  controlMode:'automatic',
                  automatic:{},
                  manual:{},
                  status:{},
                  
                }
              }
              var CMD = JSON.stringify(this.myData)
              if (LMD!==CMD){
                this.updateStatus();

                this.dataReady = true;

              }

              
            }
          }
        }
      },



    },
  };
</script>

<style scoped 
  src="@/assets/styles/ledStyle.css">
  
</style>

<style>
.disabled-div {
  pointer-events: none;
  /* Add any additional styling for a disabled appearance */
  opacity: 0.5; /* Example: reduce opacity for a disabled look */
}

  .component-main-holder {
    position: absolute;
    z-index: 7; 
    display: flex;
    justify-content: center;
    width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .component-hole {
    position: absolute;
    z-index: 8;
    /* background: #ffffff; */
    border-radius: 50%;
    /* left:  30%;
    right: 30%;
    bottom:30%;
    top: 30%; */
        left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .component-hole .text {
    display: flex;
    justify-content: center;
    font-size: 40px !important;
    font-weight: bold !important;
    color:#ffffff !important;
    font-family: Arial, Helvetica, sans-serif !important;
    margin-bottom: 0px;
  }
  .component-hole .note {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px !important;
    /* font-weight: bold !important; */
    color:#ffffff !important;
    /* font-family: Arial, Helvetica, sans-serif !important; */
  }



  .modal-footer {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .rs-custom.rs-bar {
    z-index: 4;
  }
  .rs-custom .rs-border {
    border-color: #ababab;
    margin-left: 18px;
    width: 5px;
  }
  .rs-custom .rs-border.rs-bold {
    width: 10px;
   
  }
  .rs-custom.selected .rs-border {
    border-color: #ff9500;
  }


  .rs-custom1.rs-bar {
    z-index: 3;
  }
  .rs-custom1 .rs-border {
    border-color: transparent;
    margin-left: 30px;
    width: 5px;
  }
  .rs-custom1 .rs-border.rs-bold {
    width: 30px;
    width: 30px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }

  .rs-custom1 .rs-border.rs-bold.mirrored {
    transform: translate(0, -50%) rotate(180deg) ;
    justify-content: end;
  }



  .rs-custom1.selected .rs-border {
    color:#ff9500;
  }


  .rs-pointer.rs-bar {
    z-index: 5;
  }
  .rs-pointer .rs-border {
    width: 0px;
    /* height: 25px !important; */
    position: absolute;
    top: 50%; 
    /* left: -30px; */
    transform: translate(0,-50%); 

    /* border-radius: 5px; */
    border: 1px solid rgb(4, 202, 169) !important;
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: bold; 
  }
  .rs-pointer .rs-border:before{
    content: "";
    position: absolute;
    bottom: 50%;
    right: -5px;
    border: 10px solid rgb(4, 202, 169);
    border-right: none;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: translateY(50%);
  }
  .rs-value.rs-tooltip.rs-tooltip-text{
    color: rgb(4, 202, 169) !important;
    font-family: Segoe UI !important;
    font-size: 40px !important;
    font-weight: bold !important; 
  }
  span.rs-number {
    position: absolute;
    top: -12px;
    left: -26px;
    font-family: Segoe UI;
    font-size: 24px;
    font-weight: bold;
  }
</style>


<style scoped>

  .panel_container {
    width: 500px;
    height: 500px;
  }
  .panel_container .panel_header {
    /* padding: 10px; */
    height: 10%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-variant:small-caps;
    border-bottom: 2px solid rgb(210, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container .panel_body1 {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    /* border-bottom: 2px solid rgb(166, 41, 41); */
    display: flex;
    justify-content: space-between;
  }
  .panel_container  .panel_col {
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container  .panel_colf {
    height: 100%; 
    width: 100%;
  }
  .panel_container .panel_footer {
    transition: height 1s; 
    height:90%
  }
  .buttonHolder {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
  }
  .radioboxGroup {
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .radiobtnGroup {
    width: 100%;
    height: 50px;
    display: inline-block;
        margin:2px
  }
  .radiobtnGroup .radioinpGroup {
    display: none;
  }
  .radiobtnGroup .radiospanGroup:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }
  .radiobtnGroup .radiospanGroup:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtnGroup .radiospanGroup {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
    /* background-color: #ddd; */
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0);
    padding: 5px;

  }
  .radiobtnGroup input:checked + span{
    background-color:  hsl(25.5, 94.8%, 54.3%);
    color:rgb(255, 255, 255)
  }
  .radiobox {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
  .radiobtn {
    width: 50%;
    height: 100%;
    display: inline-block;
  }

  .radiobtn .radioinp {
    display: none;
  }

  .radiobtn .radiospan:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }

  .radiobtn .radiospan:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtn .radiospan {
    display: flex;
    width: 100%;
    height: 100%;
    /* border: 1px solid rgb(255, 255, 255); */
    border-radius: 5px;
    background-color: #ddd;
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0)
  }
  .radiobtn input:checked + span{
    background-color: hsl(0, 0%, 0%); 
    color:rgb(255, 255, 255)
  }
  .TSS .radiobtn input:checked + span{
    background-color: hsl(22, 83%, 43%); 
    color:rgb(255, 255, 255)
  }
  .tab-content{
    height: 100%;

  }
  /* .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
{
    transform: translateX(10px);
    opacity: 0;
  } */
  .fcontainor{
    display:flex; 
    justify-content: space-between;
    flex-direction:row; 
    border: 1px solid #ced4da; 
    padding: 2%;
    border-radius: 0.5rem;
    margin-top: 2%;
  }
  .econtainor{
      border: 1px solid #ced4da; 
      padding: 10px;
      border-radius: 0.5rem;
      /* margin-top: 10px; */
  }



  .panel_body {
      /* padding: 0px 2% 0px 2%; */
      /* height: 68vh; */
      overflow-y: auto;
  }


</style>

<style scoped>

  .remove {
    cursor: pointer;
    /* color: rgb(212, 64, 27); */
    font-size: 18px;
  }
  .edit {
    cursor: pointer;
    color: rgb(9, 180, 210);
    font-size: 18px;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100%);
  }
  .cont{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;

  }
</style>

<style scoped>
  @import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
  @import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
  
  .wrapper {
    max-width: 250px;
    margin: 0 auto;
  }

  /* .e-subject-container{
       width: 100% !important;
  } */
  .custom-event-editor .e-textlabel {
    padding-right: 15px;
    text-align: right;
  }
  .custom-event-editor td {
    padding: 7px;
    padding-right: 16px;
  }  
</style>

  <style>
    .e-slider-container{
      padding:5px 10% 5px 10%;
    }

    .e-subject-container{
      width: 100% !important;
    }
    .custom-field-row{
      padding-bottom: 12px;
    }
    .custom-label1{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
    }
    .custom-label2{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
    }
    .custom-label3{
      color: rgba(0, 0, 0, 0.54) !important;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bolder;
    }
    .flex-container{   
      display: flex;
      padding-bottom: 12px;
      width: 100%;
      justify-content: space-between;
    }
    .field-container{
      width:30%;
    }
    .field-container-controller{
      width:48%;
      margin-bottom: 10px;
    }
    .panel_header {
      padding: 10px;
      height: 60px;
      font-size: 20px;
      color: rgb(0, 0, 0);
      font-variant:small-caps;
      border-bottom: 2px solid rgb(210, 210, 210);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .panel_col {
      height: 100%; 
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      height: 25px;
    }

    .e-schedule .e-vertical-view .e-time-cells-wrap table td,
    .e-schedule .e-vertical-view .e-work-cells {
      font-size: 13px;
    }



    /* .e-input-group.e-control-wrapper.e-float-input {
      color: royalblue;

    }
    
    .e-float-text::after {
      color: royalblue;

    }

    .e-float-line::before {
        background: royalblue !important;
    }

    .e-float-line::after {
        background: royalblue !important;
    }

    .e-float-text.e-label-top{
      color: royalblue !important;

    } */
</style>

<style scoped>
  .led-red {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #940;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0 2px 12px;
  }
  .led-yellow {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #A90;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #660 0 -1px 9px, #DD0 0 2px 12px;
  }
  .led-green {
    /* margin: 20px auto; */
    width: 12px;
    height: 12px;
    background-color: #690;
    border-radius: 50%;
    box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0 2px 12px;
  }

</style>