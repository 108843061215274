<template>
  <div>
    <b-modal
      id="modal-map-pub-json-item"
      size="lg"
      centered
      :dir="dict.dir[lang]"
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card no-body> -->
          <b-list-group v-if="to.map === 'JSON'" horizontal="md">
            <b-form-group
              label="Topic"
              label-for="topic"
              :invalid-feedback="errors.first('topic')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="topic"
                type="text"
                placeholder="Enter topic"
                v-model="data.topic"
                :state="submitted ? !errors.has('topic') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Key"
              label-for="key"
              :invalid-feedback="errors.first('key')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="key"
                type="text"
                placeholder="Enter key"
                v-model="data.key"
                :state="submitted ? !errors.has('key') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
          </b-list-group>
          <b-list-group v-else-if="to.map === 'MTR22'" horizontal="md">
            <b-form-group
              :label="dict.channel[lang]"
              label-for="Channel"
              :invalid-feedback="errors.first('Channel')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-input-group style="flex-wrap: inherit">
                <b-form-input
                  disabled
                  name="Channel"
                  type="text"
                  :placeholder="dict.selectChannel[lang]"
                  v-model="data.channel.iden"
                  :state="submitted ? !errors.has('Channel') : null"
                  v-validate="'required|min:1|max:200'"
                  style="width: 100%; font-family: Vazir"
                ></b-form-input>
                <b-form-input
                  disabled
                  name="Channel.pos"
                  type="text"
                  v-model="data.channel.pos"
                  style="width: 100%; font-family: Vazir"
                ></b-form-input>

                <b-input-group-append>
                  <b-button @click="selectFromMTR22">{{ dict.select[lang] }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-list-group>
          <div class="d-flex align-items-center">
            <ejs-textbox
              :value="data.data.name"
              :placeholder="dict.data[lang]"
              :enableRtl="lang === 'per'"
              floatLabelType="Auto"
              :showClearButton="true"
              v-model="data.data.name"
            ></ejs-textbox>
            <b-button @click="pickData" class="btn-sm" variant="primary"><span class="e-icons e-edit-6 "></span>
            </b-button>
          </div>
          <!--          <b-list-group horizontal="md">-->
          <!--            <b-form-group-->
          <!--              :label="dict.data[lang]"-->
          <!--              label-for="data"-->
          <!--              :invalid-feedback="errors.first('data')"-->
          <!--              style="width: 100%; padding: 0px 10px 10px 0px"-->
          <!--            >-->
          <!--              <b-input-group style="flex-wrap: inherit">-->
          <!--                <b-form-input-->
          <!--                  disabled-->
          <!--                  name="data"-->
          <!--                  type="text"-->
          <!--                  :placeholder="dict.selectData[lang]"-->
          <!--                  v-model="data.data.name"-->
          <!--                  :state="submitted ? !errors.has('data') : null"-->
          <!--                  v-validate="'required|min:1|max:200'"-->
          <!--                  style="width: 100%"-->
          <!--                ></b-form-input>-->

          <!--                <b-input-group-append>-->
          <!--                  <b-button @click="pickData">{{ dict.select[lang] }}</b-button>-->
          <!--                </b-input-group-append>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <!--          </b-list-group>-->

          <div
            v-if="to.type === 'cloudPub' || to.type === 'masterPub'"
            class="row mx-auto"
          >
            <div class="col-md-6 col-12">
              <ejs-dropdownlist
                v-model="data.interval"
                :dataSource="intervalList"
                :value="data.interval"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.transmit[lang]"
                floatLabelType="Auto"
                :enableRtl="lang=='per'"
                :required="true"
              >
              </ejs-dropdownlist>
            </div>
            <div class="col-md-6 col-12 pt-4">
              <ejs-switch
                @change="
                (val) => {
                  data.uponChange = val.checked;
                }
              "
                :checked="data.uponChange "
                :value="data.uponChange"
              />
              <span class="mx-2">{{ dict.upon[lang] }}</span>
            </div>
          </div>

          <!-- </b-card> -->
        </b-form>
      </div>
    </b-modal>
    <dataPickerModal></dataPickerModal>
    <MTR22SelModal />
  </div>
</template>

<script>
/* eslint-disable */
import dataPickerModal from "./setContModalDataPicker.vue";
import MTR22SelModal from "./setContModalMTR22Sel.vue";
import { v4 as uuidv4 } from "uuid";
// import alertEditorModal  from './dataManagerModalDataAlertEditor.vue';

export default {
  name: "dataAdderModal",
  components: {
    dataPickerModal,
    MTR22SelModal
    // alertEditorModal
  },
  data() {
    return {
      modalTitle: "",
      type: "",
      to: "",
      data: {
        _id: "",
        topic: "",
        key: "",
        channel: {
          iden: "",
          pos: "",
          dir: ""
        },
        data: {
          type: "",
          _id: "",
          name: ""
        },
        interval: null,
        uponChange: false
      },
      intervalList: [{ per:'مقداری انتخاب کنید',eng: "Select One", value: null },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "2 ثانیه", eng: "2 sec", value: "2_s" },
        { per: "5 ثانیه", eng: "5 sec", value: "5_s" },
        { per: "10 ثانیه", eng: "10 sec", value: "10_s" },
        { per: "15 ثانیه", eng: "15 sec", value: "15_s" },
        { per: "30 ثانیه", eng: "30 sec", value: "30_s" },
        { per: "1 دقیقه", eng: "1 min", value: "1_m" },
        { per: "3 دقیقه", eng: "3 min", value: "3_m" },
        { per: "5 دقیقه", eng: "5 min", value: "5_m" },
        { per: "15 دقیقه", eng: "15 min", value: "15_m" },
        { per: "30 دقیقه", eng: "30 min", value: "30_m" },
        { per: "45 دقیقه", eng: "45 min", value: "45_m" },
        { per: "1 ساعت", eng: "1 hour", value: "1_h" }
      ],
      submitted: false,
      successful: false,
      message: "",
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        ok: { per: "تایید", eng: "Ok" },
        cancel: { per: "لغو", eng: "Cancel" },
        select: { per: "انتخاب", eng: "Select" },
        selectData: { per: "داده ای انتخاب کنید", eng: "Select a data" },
        data: { per: "داده", eng: "data" },
        channel: { per: "کانال", eng: "channel" },
        selectChannel: { per: "کانالی انتخاب کنید", eng: "select a channel" },
        upon: { eng: "upon change", per: "به محض تغییر" },
        transmit: { eng: "transmit interval", per: "بازه زمانی ارسال پیام" }
      }
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if ((newValue.task === "pickData") && (newValue.to === "pubsub")) {
        this.data.data = newValue.item;
      } else if ((newValue.task === "selChannel") && (newValue.to.type === "pubsub")) {
        this.data.channel = newValue.id;
      }
    },
    parent2child(newValue, oldValue) {
      if (newValue.task === "editMapJson") {
        this.to = newValue.from;
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Item";
        this.data._id = (newValue.item._id || newValue.item._id === 0) ? newValue.item._id : "";
        this.data.topic = newValue.item.topic ? newValue.item.topic : "";
        this.data.channel = newValue.item.channel ? newValue.item.channel : { iden: "", pos: "", dir: "" };
        this.data.key = newValue.item.key ? newValue.item.key : null;
        this.data.data = newValue.item.data ? newValue.item.data : { _id: "", name: "" };
        this.data.interval = newValue.item.interval ? newValue.item.interval : null;
        this.data.uponChange = newValue.item.uponChange ? newValue.item.uponChange : false;

        // console.log((this.data.parent.ptype.length === 0)||(this.data.parent.ptype === 'Internal'))
      }
    }
  },
  methods: {
    initModal: function() {
    },
    resetModal: function() {
      this.type = "";
      this.modalTitle = "";

      this.data._id = "";
      this.data.topic = "";
      this.data.channel = {
        iden: "",
        pos: "",
        dir: ""
      };

      this.data.key = "";
      this.data.data = { type: "", _id: "", name: "" };
      this.data.interval = null;
      this.data.uponChange = false;

      this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      // console.log('submit',this.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.type === "New")
            this.data._id = uuidv4();

          this.$store.dispatch("chpge/child2parent", {
            task: "editMapJson",
            to: this.to,
            type: this.type,
            item: JSON.parse(JSON.stringify(this.data))
          });
          this.$bvModal.hide("modal-map-pub-json-item");
        }
      });
    },
    pickData() {
      console.log("jkl");
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];


      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "pubsub",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          page: 1,
          perPage: 1000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");

    },
    selectFromMTR22() {
      this.$store.dispatch("chpge/parent2child", {
        task: "selChannel",
        from: { type: "pubsub", id: this.to.id },
        items: { selected: this.data.channel, locked: this.to.extra }
      });
      this.$root.$emit("bv::show::modal", "modal-MTR22-selector");
    }
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>