<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(50vh - 60px)"
  >
    <div class="px-5 my-4  mx-auto">
      <div
        class="mt-4"
        ref="paper-custom-elements-namespace"
        id="paper-custom-elements-namespace"
      ></div>
    </div>

    <div>
      <b-modal
        id="modal-setDashMap"
        size="lg"
        hide-header
        centered
        scrollable
        header-text-variant="dark"
        header-border-variant="primary"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="handleOk"
        hide-footer
      >
        <div>
          <drap-able />
        </div>
      </b-modal>
      <b-modal
        id="confirm_modal"
        size="md"
        centered
        header-text-variant="dark"
        header-border-variant="primary"
        body-text-variant="dark"
        no-close-on-backdrop
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="clearSpace"
      >
        <div>
          <p :dir="confirmationText.dir[lang]">
            {{ confirmationText.title[lang] }}
          </p>
        </div>
      </b-modal>
      <b-modal
        id="portModal"
        size="md"
        centered
        header-text-variant="dark"
        header-border-variant="primary"
        body-text-variant="dark"
        hide-header-close
        hide-header
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        hide-footer
      >
        <div>
          <port-customizer />
          <ejs-textbox
            :autocomplete="'off'"
            v-model="elName"
            :required="true"
            floatLabelType="Always"
            :placeholder="dict.elNames[lang]"
          ></ejs-textbox>
        </div>
      </b-modal>
      <b-modal
        id="linkModal"
        size="md"
        centered
        header-text-variant="dark"
        header-border-variant="primary"
        body-text-variant="dark"
        no-close-on-backdrop
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
      >
        <div>
          <link-customizer />
        </div>
      </b-modal>
      <b-modal
        id="paperModal"
        size="md"
        centered
        header-text-variant="dark"
        header-border-variant="primary"
        body-text-variant="dark"
        hide-header-close
        hide-header
        hide-footer
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
      >
        <div>
          <div>
            <b-form-select
              name="refPB"
              @input="changePaper($event, 'gridType')"
              :options="papersType"
              class="form-control"
              style="width: 100%; font-size: small"
            >
            </b-form-select>
          </div>
          <div class="my-5">
            <label>background:</label>
            <b-form-input
              title="background"
              class="form-control"
              @input="changePaper($event, 'color')"
              type="color"
            ></b-form-input>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import DrapAble from "@/components/processFlowComponents/dragDrop/drapAble.vue";
import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons";

const joint = require("@joint/core/dist/joint");
import {
  conicTank,
  ControlValve,
  ControlValveView,
  Cylinder,
  Ellipse,
  handValve,
  liquidTank,
  panel,
  PanelView,
  pipeItem,
  PipeView,
  pump,
  PumpControl,
  PumpView,
  TextBlock,
  Circle,
  Polygon,
  Rectangle,
  Join,
  Zone,
  SliderValveControl,
  ToggleValveControl,
  editLinkMarkup,
  removeMarkup,
  portRect,
  circlePort,
  customPipe,
  ResizeTool,
  editButton,
  RotateTool,
  Image,
  pipePort,
  artBoard,
  Pomps,
  heightMeter,
  pool,
  ZoneReverse,
  flowMeter
} from "@/mixins";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { processFlow } from "@/store/processFlow.module";
import PortCustomizer from "@/components/processFlowComponents/portCustomizer.vue";
import LinkCustomizer from "@/components/processFlowComponents/linkCustomizer.vue";
import { g } from "jointjs";
import { debounce } from "@/services/data.service";
import TabTransition from "@/components/base/tabTransition.vue";
import { log10 } from "chart.js/helpers";
import { iterator } from "core-js/stable/dom-collections";
// import TabTransition from "@/components/base/tabTransition.vue";

export default {
  name: "scada",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    LinkCustomizer,
    PortCustomizer,
    DrapAble
  },
  props: {
    newWidth: { default: "auto" },
    newHeight: { default: "auto" },
    id: { default: "" }
  },
  data() {
    return {
      pipe: null,
      spaceName: "",
      pageMode: "",
      columnId: "",
      tabActive: "",
      elName: "",
      modalTitle: "",
      currentElementWidth: "",
      currentElementHeight: "",
      portList: [],
      resultList: [],
      graph: {},
      paper: {},
      currentPort: {},
      getPortt: {},
      current: {},
      currentLink: {},
      linkView: {},
      toolsView: {},
      pipeView: new pipeItem(),
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "process flow diagram", per: "دیاگرام چرخه فرآیند" },
        spaceName: { eng: "Process flow name", per: "نام چرخه فرآیند" },
        NewElement: { eng: "New element", per: "المان جدید" },
        paperSetting: { eng: "Paper setting", per: "تنظیمات برگه" },
        options: { eng: "options", per: "دیگر" },
        elNames: { eng: "element name", per: "نام المان" }
      },
      items: [
        { text: "New element", value: "new" },
        { text: "Paper setting", value: "setting" }
      ],
      confirmationText: {
        dir: { eng: "ltr", per: "rtl" },
        title: {
          eng: "Are you sure about clear the workspace?",
          per: "آیا از پاکسازی این محیط کار اطمینان دارید؟"
        }
      },
      nexPos: {
        x: 0,
        y: 0
      },
      currentPortPos: {
        x: 0,
        y: 0
      },
      portFields: [
        { name: "type", value: "type", isActive: true },
        { name: "shape", value: "shape", isActive: false },
        { name: "position", value: "position", isActive: false }
      ],
      tabFields: [
        { name: "element", value: "element", isActive: true },
        { name: "ports", value: "ports", isActive: false }
      ],
      portTranslator: {
        portRect: portRect,
        circlePort: circlePort,
        customPipe: customPipe,
        pipePort: pipePort
      },
      nameSpace: {
        standard: joint.shapes.standard,
        artBoard,
        pump,
        handValve,
        liquidTank,
        conicTank,
        panel,
        PumpControl,
        ControlValve,
        PipeView,
        pipeItem,
        PumpView,
        PanelView,
        Circle,
        Cylinder,
        Rectangle,
        Ellipse,
        Polygon,
        TextBlock,
        Pomps,
        heightMeter,
        pool,
        ZoneReverse,
        flowMeter
      },
      instanceViews: {
        ControlValve: ControlValveView,
        Pump: PumpView,
        Panel: PanelView
      },
      translator: {
        Pool: pool,
        handValve: handValve,
        liquidTank: liquidTank,
        conicTank: conicTank,
        ControlValve: ControlValve,
        Join: Join,
        panel: panel,
        PanelView: PanelView,
        pipeItem: pipeItem,
        PipeView: PipeView,
        pump: pump,
        PumpView: PumpView,
        Zone: Zone,
        Image: Image,
        Circle: Circle,
        Cylinder: Cylinder,
        Rectangle: Rectangle,
        Ellipse: Ellipse,
        Polygon: Polygon,
        TextBlock: TextBlock,
        ArtBoard: artBoard,
        Pomps: Pomps,
        heightMeter: heightMeter,
        ZoneR: ZoneReverse,
        FlowMeter: flowMeter
      },
      papersType: [
        { text: "none", value: null },
        { text: "dot", value: "dot" },
        { text: "mesh", value: "mesh" },
        { text: "fixedDot", value: "fixedDot" },
        { text: "doubleMesh", value: "doubleMesh" }
      ],
      paperType: null,
      meter: null
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    ...mapGetters([
      "paperWatcher",
      "paperSettingWatcher",
      "portSettingWatcher",
      "newElementWatcher",
      "linkSettingWatcher",
      "processWatcher"
    ]),
    ...mapState([
      "parent",
      "chosenElement",
      "currentElement",
      "clearWorkspace",
      "linkSetting",
      "portSetting",
      "paperSetting"
    ])
  },
  methods: {
    getVal(e) {
      if (e.item.value == "new") {
        this.$nextTick(() => {
          this.$bvModal.show("modal-setDashMap");
        });
      } else if (e.item.value == "setting") {
        this.$bvModal.show("paperModal");
      }
    },
    initModal: function() {
    },
    addNewEl() {
      this.$bvModal.show("modal-setDashMap");
    },
    paperSetup() {
      this.$bvModal.show("paperModal");
    },
    resetModal: function() {
      this.modalTitle = "";
      this.elName = "";
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
    },
    dragPort() {
      const updatePortPosition = (id, x, y) => {
        if (id) {
          this.currentElementWidth = this.current.model.get("size").width;
          this.currentElementHeight = this.current.model.get("size").height;

          this.currentPortPos.x = x;
          this.currentPortPos.y = y;

          if (x < 0) {
            this.currentPortPos.x = -10;
          } else if (x > this.currentElementWidth) {
            this.currentPortPos.x = this.currentElementWidth;
          }

          if (y < 0) {
            this.currentPortPos.y = -30;
          } else if (y > this.currentElementHeight) {
            this.currentPortPos.y = this.currentElementHeight - 30;
          }

          this.current.model.portProp(
            id,
            "markup/0/attributes/x",
            this.currentPortPos.x
          );
          this.current.model.portProp(
            id,
            "markup/0/attributes/y",
            this.currentPortPos.y
          );
        }
      };
      this.paper.on(
        "element:magnet:pointerclick",
        (elementView, evt, magnetSVGElement, x, y) => {
          let portElement = {};
          if (this.currentPort) {
            portElement = elementView.el.querySelector(
              `.joint-port-body[port="${this.currentPort.id}"]`
            );
            let id = magnetSVGElement.getAttribute("port");

            let initialMouseX, initialMouseY, initialPortX, initialPortY;

            // portElement.addEventListener("mousedown", function (event) {
            //   initialMouseX = event.clientX;
            //   initialMouseY = event.clientY;
            //   initialPortX = portBBox.x;
            //   initialPortY = portBBox.y;
            //
            //   document.addEventListener("mousemove", handleDrag);
            //
            //   document.addEventListener("mouseup", stopDrag);
            // });

            // eslint-disable-next-line no-inner-declarations
            function handleDrag(event) {
              const dx = event.clientX - initialMouseX;
              const dy = event.clientY - initialMouseY;
              updatePortPosition(id, initialPortX + dx, initialPortY + dy);
            }

            // eslint-disable-next-line no-inner-declarations
            function stopDrag() {
              document.removeEventListener("mousemove", handleDrag);
              document.removeEventListener("mouseup", stopDrag);
            }
          }
        }
      );
    },
    destroyLinkView: function() {
      this.paper.on("blank:pointerclick", () => {
        if (
          processFlow.state.parent.pipes.length > 0 &&
          Object.keys(this.current).length > 0
        ) {
          this.current.unhighlight(this.current.el);
          this.linkView.hideTools();
          this.linkView.removeTools();
        }
      });
    },
    selectLink() {
      this.paper.on("link:pointerclick", (linkView) => {
        if (this.currentLink)
          linkView.addTools(
            new joint.dia.ToolsView({
              name: "editLink",
              tools: [
                new joint.linkTools.Button({
                  focusOpacity: 0.5,
                  distance: -110,
                  offset: 19,
                  rotate: false,
                  action: (evt) => {
                    this.$bvModal.show("linkModal");
                  },
                  markup: editLinkMarkup
                }),
                new joint.linkTools.Remove({
                  distance: -60,
                  markup: removeMarkup,
                  action() {
                    store.commit("removeLinkFromParent", linkView.model.id);
                    linkView.remove();
                  }
                })
              ]
            })
          );

        this.currentLink = linkView;
      });
    },
    selectElement() {
      this.paper.on("element:pointerclick", (elementView) => {
        this.$store.commit("chooseElement", elementView.model);
        this.current = elementView;
        this.current.highlight(this.current.el);
        this.linkView = this.current.model.findView(this.paper);
        this.linkView.addTools(this.toolsView);
      });
    },
    selectPort: function() {
      this.paper.on(
        "element:magnet:pointerclick",
        (modelView, magnet, target, g) => {
          let id = magnet.target.getAttribute("port");
          if (id == null) id = target.getAttribute("port");
          this.currentPort = {};
          this.portList.find((item) => {
            if (item.id == id) {
              this.currentPort = item;
            }
          });
          // this.portModal = true
        }
      );
    },
    zoomInZoomOut: function() {
      this.paper.on("paper:pinch", (evt, x, y, sx) => {
        evt.preventDefault();
        const { sx: sx0 } = this.paper.scale();
        this.paper.scaleUniformAtPoint(sx0 * sx, { x, y });
      });
      this.paper.on("paper:pan", (evt, tx, ty) => {
        evt.preventDefault();
        evt.stopPropagation();
        const { tx: tx0, ty: ty0 } = this.paper.translate();
        this.paper.translate(tx0 - tx, ty0 - ty);
      });
    },
    hideLinkRemoveTool: function() {
      this.paper.on("link:mouseleave", (linkView) => {
        setTimeout(() => linkView.removeTools(), 5000);
      });
    },
    removeCurrentPort: () => {
      this.current.model.removePort(this.currentPort.id);
    },
    clearSpace() {
      this.graph.clear();
      this.$store.commit("clearParentElement", true);
      this.$store.commit("updateClearWorkSpace", true);
    },
    workSpaceChangeWatcher() {
      this.graph.on("change", function(cell) {
        if (cell instanceof pipeItem == false)
          debounce(() => {
            store.commit("updateParent", cell);
          }, 2000);
        else store.commit("updateParentPipe", cell);
      });
    },
    workSpaceAddWatcher() {
      this.graph.on("add", function(cell) {
        if (cell instanceof pipeItem == false)
          store.commit("createParentElement", cell);
      });
    },
    changePaper(e, name) {
      this.$store.commit("updatePaper", { name: name, val: e });
    },
    submitWorkSpageChanges() {
      if (this.pageMode == null) {
        if (!this.spaceName || this.spaceName == "")
          alert("وارد کردن نام مدل اجباری است.");
        else {
          this.$store.dispatch("createNew", {
            name: this.spaceName,
            paper: processFlow.state.paperSetting,
            items: {
              elements: processFlow.state.parent.elements,
              pipes: processFlow.state.parent.pipes
            }
          });
          alert("successfully!");
          this.$store.dispatch("chpge/changePage", "processFlowDiagram");
        }
      } else {
        this.$store.dispatch("editProcess", {
          _id: this.columnId,
          name: this.spaceName,
          paper: processFlow.state.paperSetting,
          items: processFlow.state.parent
        });
        alert("successfully!");
        this.$store.dispatch("chpge/changePage", "processFlowDiagram");
      }
      processFlow.state.parent = {
        elements: [],
        pipes: []
      };
    },
    getDataById() {
      this.$store
        .dispatch("getProcessById", {
          _id: this.columnId
        })
        .then((res) => {
          this.resultList = res;
        });
    },
    updateWaterLevel(e) {
      // const model = this.data.datas[0].find()
      const originalY = 221;
      const originalHeight = 59;

      const newHeight = Number(e.target.value);

      const newY = originalY - (newHeight - originalHeight);

      this.current.model.attr({
        rect4: {
          height: newHeight,
          y: newY
        }
      });

      if (newHeight >= 24) {
        this.meter.attr({
          rect18: {
            fill: "red"
          }
        });
      } else if (newHeight < 24) {
        this.meter.attr({
          rect18: {
            fill: "black"
          }
        });
        if (this.pipe != null) {
          this.pipe.model.set("flow", 0);
        }
      }

      if (newHeight >= 50) {
        this.meter.attr({
          rect17: {
            fill: "red"
          }
        });
        if (this.pipe != null) {
          this.pipe.model.set("flow", 1);
        }
      } else if (newHeight < 50) {
        this.meter.attr({
          rect17: {
            fill: "black"
          }
        });
      }

      if (newHeight >= 133) {
        this.meter.attr({
          rect16: {
            fill: "red"
          }
        });
      } else if (newHeight < 133) {
        this.meter.attr({
          rect16: {
            fill: "black"
          }
        });
      }

      if (newHeight >= 175) {
        this.attr({
          rect15: {
            fill: "red"
          }
        });
      }
    }
  },
  watch: {
    paperType: {
      handler: () => {
        this.paper.setGridSize(this.gridSize);
        this.paper.setGrid({ name: this.paperType });
      }
    },
    elName(val) {
      if (val != "") this.current.model.attr("label/text", val);
    },
    newElementWatcher: {
      // handler(val) {
      //   let el = {};
      //   if (val !== "") {
      //     if (val.name == "Image") {
      //       el = new Image({
      //         position: val.val.position,
      //         size: { width: 100, height: 80 },
      //         attrs: {
      //           image: {
      //             "xlink:href": val.img,
      //           },
      //         },
      //       });
      //     } else {
      //       el = new this.translator[val.name]({
      //         position: val.val.position,
      //         size: val.size ? val.size : undefined,
      //       });
      //     }
      //     this.graph.addCell(el);
      //   }
      //   this.$store.commit("updateParent", this.graph.attributes.cells.models);
      // },
    },
    "portSettingWatcher.shape": {
      handler: function(val) {
        this.currentElementWidth = this.current.model.get("size").width;
        this.currentElementHeight = this.current.model.get("size").height;
        let portX = processFlow.state.portSetting.args.x;
        let portY = processFlow.state.portSetting.args.y;

        if (portX < 0) {
          portX = -10;
          this.currentPortPos.x = -10;
        } else if (portX > this.currentElementWidth) {
          portX = this.currentElementWidth;
          this.currentPortPos.x = this.currentElementWidth;
        }

        if (portY < 0) {
          portY = 0;
          this.currentPortPos.y = 0;
        } else if (portY > this.currentElementHeight) {
          portY = this.currentElementHeight;
          this.currentPortPos.y = this.currentElementHeight;
        }

        if (val == "pipePort") {
          this.current.model.addPort(pipePort);
        }

        Object.assign(this.portTranslator[val].markup[0].attributes, {
          x: portX == this.nexPos.x ? portX + 30 : portX,
          y: portY == this.nexPos.y ? portY - 30 : portY
        });

        this.current.model.addPort({
          group: this.portSettingWatcher.type,
          markup: this.portTranslator[val].markup
        });

        const portsLength = this.current.model.attributes.ports.items.length;
        const theLast =
          this.current.model.attributes.ports.items[portsLength - 1];
        this.nexPos.x = theLast.markup[0].attributes.x;
        this.nexPos.y = theLast.markup[0].attributes.y;

        this.portList = [];
        this.current.model.getPorts().forEach((port) => {
          this.portList.push(port);
        });
      },
      deep: true
    },
    "linkSettingWatcher.color": {
      handler(val) {
        const setting = {
          liqColor: "",
          lineColor: "",
          outlineColor: ""
        };
        // if (Object.keys(this.currentLink).length > 0)
        Object.values(val).forEach((item) => {
          setting[item.elName] = item.value;
        });
        this.currentLink.model.attr({
          liquid: {
            stroke: setting.liqColor
          },
          line: {
            stroke: setting.lineColor,
            strokeWidth: 5
          },
          outline: {
            stroke: setting.outlineColor,
            strokeWidth: 5
          }
        });
      },
      deep: true
    },
    "linkSettingWatcher.router": {
      handler(val) {
        this.currentLink.model.router(val[0].value);
      },
      deep: true
    },
    "linkSettingWatcher.width": {
      handler(val) {
        // if (Object.keys(this.currentLink).length > 0)
        const setting = {
          lineWidth: "",
          outlineWidth: ""
        };
        Object.values(val).forEach((item) => {
          setting[item.elName] = item.value;
        });

        this.currentLink.model.attr({
          line: {
            strokeWidth: Number(setting.lineWidth)
          },
          outline: {
            strokeWidth: Number(setting.lineWidth)
          }
        });
      },
      deep: true
    },
    paperSettingWatcher: {
      handler(val) {
        if (val.gridType == null) this.paper.setGrid(val.gridType);
        this.paper.setGrid({ name: val.gridType, args: { color: val.color } });
        this.paper.setGridSize(val.gridSize);
      },
      deep: true
    },
    clearWorkspace(val) {
      if (val) {
        this.graph.clear();
        this.$store.commit("updateClearWorkSpace", false);
      }
    },
    resultList: {
      handler(val) {
        // this.spaceName = val.name;
        // if (this.resultList.items[0].elements.length > 0) {
        //   this.resultList.items.forEach((parent) => {
        //     parent.elements.forEach((elItem) => {
        //       let elName;
        //       if (
        //         elItem.name === "LiquidTank" ||
        //         elItem.name === "ConicTank" ||
        //         elItem.name === "Pump" ||
        //         elItem.name === "HandValve" ||
        //         elItem.name === "Panel"
        //       ) {
        //         elName =
        //           elItem.name.charAt(0).toLowerCase() + elItem.name.slice(1);
        //       } else elName = elItem.name;
        //
        //       if (elItem.name == "Pomps") {
        //         let el = new Pomps({
        //           position: elItem.attrs.position,
        //         });
        //         this.graph.addCell(el);
        //       }
        //
        //       if (elName == "Zone") {
        //         let el = new Zone({
        //           position: elItem.attrs.position,
        //           attrs: elItem.attrs.attrs,
        //           size: elItem.attrs.size ? elItem.attrs.size : undefined,
        //           id: elItem.attrs.id,
        //         });
        //         this.graph.addCell(el);
        //       }
        //
        //       if (
        //         elName != "Zone" &&
        //         elName != "heightMeter" &&
        //         elName != "pool"
        //       ) {
        //         let el = new this.translator[elName]({
        //           position: elItem.attrs.position,
        //           attrs: elItem.attrs.attrs,
        //           size: elItem.attrs.size ? elItem.attrs.size : undefined,
        //           id: elItem.attrs.id,
        //         });
        //         this.graph.addCell(el);
        //       }
        //
        //       if (elItem.name == "heightMeter") {
        //         let el = new pool({
        //           position: elItem.attrs.position,
        //         });
        //         this.graph.addCell(el);
        //       } else {
        //         // if (elItem.attrs.ports)
        //         //   elItem.attrs.ports.items.forEach((x) => {
        //         //     el.addPort({
        //         //       group: x.group,
        //         //       markup: x.markup,
        //         //       id: x.id
        //         //     });
        //         //   });
        //       }
        //     });
        //
        //     if (parent.pipes.length > 0) {
        //       let sElement = {};
        //       let tElement = {};
        //       parent.pipes.forEach((PItem) => {
        //         store.commit("updateParentPipeEdit", PItem);
        //         parent.elements.find((i) => {
        //           if (i.attrs.id == PItem.attrs.target.id)
        //             sElement = {
        //               id: i.attrs.id,
        //               port: PItem.attrs.target.port,
        //               magnet: "image",
        //             };
        //
        //           if (i.attrs.id == PItem.attrs.source.id)
        //             tElement = {
        //               id: i.attrs.id,
        //               port: PItem.attrs.source.port,
        //               magnet: "image",
        //             };
        //         });
        //
        //         const pump1Pipe1 = new pipeItem({
        //           source: tElement,
        //           target: sElement,
        //           attrs: PItem.attrs.attrs,
        //           id: PItem.id,
        //         });
        //         this.graph.addCell(pump1Pipe1);
        //         pump1Pipe1.toBack();
        //       });
        //     }
        //   });
        // }
      },
      deep: true
    }
  },
  mounted() {
    const main = this;
    setTimeout(() => {
      this.pageMode = this.$store.state.chpge.type;
      this.columnId = this.$store.state.chpge.index;
      processFlow.state.parent = {
        elements: [],
        pipes: []
      };

      if (this.pageMode != null) this.getDataById();

      this.graph = new joint.dia.Graph({}, { cellNamespace: main.nameSpace });
      this.paper = new joint.dia.Paper({
        el: this.$refs["paper-custom-elements-namespace"],
        width: 800,
        height: 600,
        gridSize: 5,
        drawGrid: { name: "mesh", args: { color: "white" } },
        async: true,
        overflow: false,
        markAvailable: true,
        snapLinks: { radius: 10 },
        embeddingMode: true,
        model: this.graph,
        linkPinning: false,
        interactive: {
          linkMove: false,
          stopDelegation: false
        },
        defaultAnchor: {
          name: "perpendicular"
        },
        restrictTranslate: true,
        elementView: (element) => {
          return this.instanceViews[element.attributes.type];
        },
        highlighting: {
          default: {
            name: "mask",
            options: {
              layer: joint.dia.Paper.Layers.BACK,
              attrs: {
                stroke: "#0276ec",
                "stroke-width": 1,
                "stroke-dasharray": "5,5"
              }
            }
          }
        },
        defaultLink: () =>
          new pipeItem({
            attrs: {
              ".marker-arrowhead-group-source": {
                display: "block"
              },
              ".link-tools": {
                display: "block"
              }
            }
          }),
        linkView: PipeView,
        defaultConnectionPoint: { name: "boundary" },
        validateConnection: function(
          cellViewS,
          magnetS,
          cellViewT,
          magnetT,
          end,
          linkView
        ) {
          if (magnetS && magnetS.getAttribute("port-group") === "in")
            return true;

          if (cellViewS === cellViewT) return true;

          return magnetT && magnetT.getAttribute("port-group") === "in";
        },
        validateMagnet: function(cellView, magnet) {
          return magnet.getAttribute("magnet") !== "passive";
        }
      });
      const contentBBox = main.paper.getContentBBox();

      const paperSize = main.paper.getComputedSize();

      const offsetX = (paperSize.width - contentBBox.width) / 2 - contentBBox.x;
      const offsetY = (paperSize.height - contentBBox.height) / 2 - contentBBox.y;

      main.paper.translate(offsetX, offsetY);

      this.paper.model.on("add", function(cell) {
        main.paper.fitToContent({
          padding: 10, // Optional padding around elements
          allowNewOrigin: "any", // Allow shifting the origin to fit content
          useModelGeometry: true // Use model's geometry to determine fit
        });

        if (cell.isElement()) {
          setTimeout(function() {

            const elementView = main.paper.findViewByModel(cell);
            if (cell.attributes.type == "Pump")
              PumpControl.add(elementView, elementView.el, elementView.cid);
            else if (cell.attributes.type == "ControlValve")
              SliderValveControl.add(
                elementView,
                elementView.el,
                elementView.cid
              );
            else if (cell.attributes.type == "HandValve")
              ToggleValveControl.add(
                elementView,
                elementView.el,
                elementView.cid
              );
          }, 10);

          if (cell.attributes.type == "ArtBoard") {
            // setInterval(() => {
            //   cell.attr({
            //     handlePath:{transform:`rotate(${45} 75 75)`}
            //   }); // Rotate around center (75, 75)
            // }, 3000);
          }

          if (cell.attributes.type == "PompsBB") {
            // setTimeout(()=>{
            //   cell.attr('rect2', {
            //     fill:{
            //       type: "linearGradient",
            //       stops: [
            //         { offset: "0%", color: "rgba(23,152,195,0.98)" },
            //         { offset: "50%", color: "white" },
            //         { offset: "100%", color: "rgba(23,152,195,0.98)" }
            //       ]
            //     }
            //   });
            // },3000)
          }

          if (cell.attributes.type == "pool") {
            let prev = cell;
            let el = new main.translator["heightMeter"]({
              position: {
                x: cell.attributes.position.x + 20,
                y: cell.attributes.position.y + 65
              }
            });
            main.graph.addCell(el);
            // cell.embed(prev)
          }

          // if (
          //   cell.attributes.type != "heightMeter" &&
          //   cell.attributes.type != "pool"
          // ) {
          //   cell.addPort({
          //     group: "in", // Group 'in' should be defined in the element
          //     attrs: {
          //       circle: {
          //         magnet: true,
          //         opacity: 0, // Make the port invisible
          //         stroke: "#FFF", // No visible stroke
          //         fill: "#FFF",
          //       },
          //     },
          //   });
          //   cell.addPort({
          //     group: "out", // Group 'in' should be defined in the element
          //     attrs: {
          //       circle: {
          //         magnet: true,
          //         opacity: 0, // Make the port invisible
          //         stroke: "#FFF", // No visible stroke
          //         fill: "#FFF",
          //       },
          //     },
          //   });
          // }

          if (cell.attributes.type == "heightMeter") {
            main.meter = cell;
          }

          if (
            cell.attributes.type == "ZoneR" ||
            cell.attributes.type == "Zone"
          ) {
            // cell.rotate(180);
          }
        }
      });
      this.paper.el.style.cursor = "move";

      const removeTool = new joint.elementTools.Remove({
        rotate: true,
        useModelGeometry: true,
        focusOpacity: 0.5,
        action() {
          this.remove();
          store.commit("removeElementFromParent", this.model.id);
        }
      });

      const editBtn = new editButton({
        action() {
          main.$bvModal.show("portModal");
        }
      });

      const resizer = new ResizeTool({
        focusOpacity: 0.5
      });

      this.toolsView = new joint.dia.ToolsView({
        name: "basic-tools",
        tools: [removeTool, editBtn, resizer]
      });

      main.paper.on("link:connect", function(linkView) {
        linkView.model.toBack();
        store.commit("createParentPipes", linkView.model);
      });

      // window.addEventListener("resize", function() {
      //   const wrapper = document.getElementById("proc" + this.id);
      //   console.log(wrapper);
      //   const scaleFactor = Math.min(wrapper.clientWidth / main.paper.options.width, wrapper.clientHeight / main.paper.options.height);
      //
      //   main.paper.scale(scaleFactor, scaleFactor);
      //
      //   main.paper.setDimensions(wrapper.clientWidth, wrapper.clientHeight);
      // });
      const tank1 = new liquidTank({
        position: { x: 50, y: 250 }
      });
      const panel1 = new panel({
        position: { x: 70, y: 300 }
      });
      panel1.listenTo(tank1, "change:level", (_, level) => {
        const color =
          level > 80
            ? "red"
            : level < 20
              ? "green"
              : "blue";
        panel1.set({ level, color });
      });

      const tank2 = new conicTank({
        position: { x: 820, y: 200 }
      });

      tank2.addTo(main.graph);

// Pumps

      const pump1 = new pump({
        position: { x: 460, y: 250 },
        attrs: {
          label: {
            text: "Pump 1"
          }
        }
      });

      pump1.addTo(main.graph);
      pump1.power = 1;

      const pump2 = new pump({
        position: { x: 460, y: 450 },
        attrs: {
          label: {
            text: "Pump 2"
          }
        }
      });

      pump2.addTo(main.graph);
      pump2.power = 0;

// CTRL Valves

      const controlValve1 = new ControlValve({
        position: { x: 300, y: 295 },
        open: 1,
        attrs: {
          label: {
            text: "CTRL Valve 1"
          }
        }
      });

      controlValve1.addTo(main.graph);

      const controlValve2 = new ControlValve({
        position: { x: 300, y: 495 },
        open: 0.25,
        attrs: {
          label: {
            text: "CTRL Valve 2"
          }
        }
      });
      const zone1 = new Zone({
        position: { x: 50, y: 600 },
        attrs: {
          label: {
            text: "Zone 1"
          }
        }
      });

      const zone2 = new Zone({
        position: { x: 865, y: 600 },
        attrs: {
          label: {
            text: "Zone 2"
          }
        }
      });

      const handValve1 = new handValve({
        position: { x: 875, y: 450 },
        open: 1,
        angle: 270,
        attrs: {
          label: {
            text: "Valve 1"
          }
        }
      });

      handValve1.addTo(main.graph);

      const handValve2 = new handValve({
        position: { x: 650, y: 250 },
        open: 1,
        angle: 0,
        attrs: {
          label: {
            text: "Valve 2"
          }
        }
      });

      handValve2.addTo(main.graph);

      const handValve3 = new handValve({
        position: { x: 650, y: 450 },
        open: 1,
        angle: 0,
        attrs: {
          label: {
            text: "Valve 3"
          }
        }
      });

      handValve3.addTo(main.graph);

// Joins

      const join1 = new Join({
        position: { x: 772, y: 460 }
      });

      join1.addTo(main.graph);

      const join2 = new Join({
        position: { x: 810, y: 605 }
      });

      join2.addTo(main.graph);

// Pipes

      const tank1Pipe1 = new pipeItem({
        source: {
          id: tank1.id,
          anchor: { name: "right", args: { dy: -25 } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: controlValve1.id,
          port: "left",
          anchor: { name: "left" }
        }
      });

      tank1Pipe1.addTo(main.graph);

      const tank1Pipe2 = new pipeItem({
        source: {
          id: tank1.id,
          anchor: { name: "bottomRight", args: { dy: -40 } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: controlValve2.id,
          port: "left",
          anchor: { name: "left" },
          connectionPoint: { name: "anchor" }
        }
      });

      tank1Pipe2.addTo(main.graph);

      const tank2Pipe1 = new pipeItem({
        source: {
          id: tank2.id,
          selector: "bottom",
          anchor: { name: "bottom" },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: handValve1.id,
          port: "right",
          anchor: { name: "right", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        }
      });

      tank2Pipe1.addTo(main.graph);

      const ctrlValve1Pipe1 = new pipeItem({
        source: { id: controlValve1.id, port: "right", anchor: { name: "right" } },
        target: { id: pump1.id, port: "left", anchor: { name: "left" } }
      });

      ctrlValve1Pipe1.addTo(main.graph);

      const valve2Pipe1 = new pipeItem({
        source: {
          id: handValve2.id,
          port: "right",
          anchor: { name: "right", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: join1.id,
          anchor: { name: "top" },
          connectionPoint: { name: "anchor" }
        }
      });

      valve2Pipe1.addTo(main.graph);

      const valve1Pipe1 = new pipeItem({
        source: {
          id: handValve1.id,
          port: "left",
          anchor: { name: "left", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: join2.id,
          anchor: { name: "top" },
          connectionPoint: { name: "anchor" }
        }
      });

      valve1Pipe1.addTo(main.graph);

      const pump1Pipe1 = new pipeItem({
        source: {
          id: pump1.id,
          port: "right",
          anchor: { name: "right", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: handValve2.id,
          port: "left",
          anchor: { name: "left", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        }
      });

      pump1Pipe1.addTo(main.graph);

      const valve3Pipe1 = new pipeItem({
        source: {
          id: handValve3.id,
          port: "right",
          anchor: { name: "right", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: join1.id,
          anchor: { name: "left" },
          connectionPoint: { name: "anchor" }
        }
      });

      valve3Pipe1.addTo(main.graph);

      const pump2Pipe1 = new pipeItem({
        source: {
          id: pump2.id,
          port: "right",
          anchor: { name: "right", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: handValve3.id,
          port: "left",
          anchor: { name: "left", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        }
      });

      pump2Pipe1.addTo(main.graph);

      const ctrlValve2Pipe1 = new pipeItem({
        source: { id: controlValve2.id, port: "right", anchor: { name: "right" } },
        target: {
          id: pump2.id,
          port: "left",
          anchor: { name: "left", args: { rotate: true } },
          connectionPoint: { name: "anchor" }
        }
      });

      ctrlValve2Pipe1.addTo(main.graph);

      const zone1Pipe1 = new pipeItem({
        source: {
          id: zone1.id,
          port: "left",
          anchor: { name: "left", args: { rotate: true, dx: 10 } },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: tank1.id,
          anchor: { name: "bottomLeft", args: { dy: -30 } },
          connectionPoint: { name: "anchor" }
        }
      });

      zone1Pipe1.addTo(main.graph);

      const join1Pipe1 = new pipeItem({
        source: {
          id: join1.id,
          anchor: { name: "bottom" },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: join2.id,
          anchor: { name: "left" },
          connectionPoint: { name: "anchor" }
        }
      });

      join1Pipe1.addTo(main.graph);

      const join2Pipe1 = new pipeItem({
        source: {
          id: join2.id,
          anchor: { name: "right" },
          connectionPoint: { name: "anchor" }
        },
        target: {
          id: zone2.id,
          anchor: { name: "left", args: { dx: 10 } },
          connectionPoint: { name: "anchor" }
        }
      });

      join2Pipe1.addTo(main.graph);

      tank1.level = "green";

      let extraLiquid = 0;
      setInterval(function() {
        const tank1Level = tank1.level;
        const liquidIn = g.random(0, 15);

        let newLevel = tank1Level + liquidIn;
        if (newLevel >= 100) {
          extraLiquid += newLevel - 100;
        } else {
          extraLiquid = 0;
        }

        // Tank 1 Instrumentation

        // Tank 1 Pipes
        const tank1Pipe1Flow = tank1Level > 70 ? 1 : 0;
        const tank1Pipe2Flow = tank1Level > 0 ? 1 : 0;
        tank1Pipe1.set("flow", tank1Pipe1Flow);
        tank1Pipe2.set("flow", tank1Pipe2Flow);

        // CTRL Valve 1
        const ctrlValve1Open = controlValve1.get("open");
        const ctrlValve1Pipe1Flow = tank1Pipe1Flow * ctrlValve1Open;
        ctrlValve1Pipe1.set("flow", ctrlValve1Pipe1Flow);
        // CTRL Valve 2
        const ctrlValve2Open = controlValve2.get("open");
        const ctrlValve2Pipe1Flow = tank1Pipe2Flow * ctrlValve2Open;
        ctrlValve2Pipe1.set("flow", ctrlValve2Pipe1Flow);

        // Pump 1
        const pump1Power = pump1.power;
        const pump1Pipe1Flow = ctrlValve1Pipe1Flow * (1 + 2 * pump1Power);
        pump1Pipe1.set("flow", pump1Pipe1Flow);

        // Pump 2
        const pump2Power = pump2.power;
        const pump2Pipe1Flow = ctrlValve2Pipe1Flow * (1 + 2 * pump2Power);
        pump2Pipe1.set("flow", pump2Pipe1Flow);

        // Hand Valve 2
        const handValve2Open = Number(handValve2.get("open"));
        const handValve2Pipe1Flow = pump1Pipe1Flow * handValve2Open;
        valve2Pipe1.set("flow", handValve2Pipe1Flow);

        // Hand Valve 3
        const handValve3Open = Number(handValve3.get("open"));
        const handValve3Pipe1Flow = pump2Pipe1Flow * handValve3Open;
        valve3Pipe1.set("flow", handValve3Pipe1Flow);

        // Join 1
        const join1Pipe1Flow = handValve2Pipe1Flow + handValve3Pipe1Flow;
        join1Pipe1.set("flow", join1Pipe1Flow);

        // Tank 2
        const tank2Pipe1Flow = 0.5; // constant flow
        tank2Pipe1.set("flow", tank2Pipe1Flow);

        // Hand Valve 1
        const handValve1Open = Number(handValve1.get("open"));
        const handValve1Pipe1Flow = tank2Pipe1Flow * handValve1Open;
        valve1Pipe1.set("flow", handValve1Pipe1Flow);

        // Join 2
        const join2Pipe1Flow = join1Pipe1Flow + handValve1Pipe1Flow;
        join2Pipe1.set("flow", join2Pipe1Flow);

        // Tank1
        const liquidOut = join2Pipe1Flow * 4;
        tank1.level = tank1Level + liquidIn - liquidOut;

        // Gauge 1
        let pressure1 = ctrlValve1Pipe1Flow * 10;
        if (pressure1 > 0) {
          pressure1 += Math.min(30, extraLiquid * Math.max(1.1 - handValve2Open));
          if (handValve2Open === 0) {
            pressure1 += Math.max(0, tank1Level - 70) * 0.3;
          }
        }

        // Gauge 2
        let pressure2 = ctrlValve2Pipe1Flow * 10;
        if (pressure2 > 0) {
          pressure2 += Math.min(30, extraLiquid * Math.max(1.1 - handValve3Open));
          if (handValve3Open === 0) {
            pressure2 += tank1Level * 0.3;
          }
        }

      }, 1000);


      main.graph.addCells([zone1, zone2]);


      controlValve2.addTo(main.graph);


      tank1.addTo(main.graph);
      panel1.addTo(main.graph);
      tank1.embed(panel1);
      this.selectLink();
      this.selectElement();
      this.zoomInZoomOut();
      this.destroyLinkView();
      this.selectPort();
      this.dragPort();
      this.hideLinkRemoveTool();
      this.workSpaceChangeWatcher();
      this.workSpaceAddWatcher();
      main.paper.options.interactive = false;
      main.paper.scale(0.75, 0.75);
      let num = 10;

      // setInterval(function() {
      //   num = num + 2;
      //     // if (num <= 180)
      //     //   this.updateWaterLevel({ target: { value: num } });
      // }.bind(this), 1000);
    }, 200);
  }
};
</script>
<style src="@/assets/styles/elements.css"></style>
