<template>
  <div>
    <b-modal
      id="xslxDetails"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      title="archived data"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div class="overflow-x-scroll">
        <div class="d-flex justify-content-between">
          <b-icon
            v-if="selected.length<=0"
            @click="deleteAllFile"
            icon="trash-fill"
            aria-hidden="true"
            class="py-0 mb-0 mt-3"
            title="delete all"
            variant="danger"
          ></b-icon>
          <b-icon
            v-if="selected.length>0"
            @click="deleteSelectedRecords"
            icon="trash-fill"
            title="delete selected"
            class="py-0 mb-0 mt-3"
            aria-hidden="true"
            variant="danger"
          >
          </b-icon>
          <b-col lg="3" sm="6" md="5" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
        </div>
        <b-table
          :striped="false"
          :bordered="true"
          :outlined="true"
          :small="true"
          :hover="true"
          head-row-variant="secondary"
          :items="info"
          :fields="fields"
          :sort-desc.sync="sortDesc"
          :filter-included-fields="filterOn"
          :sort-direction="sortDirection"
          content-class="shadow"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          responsive
          class="bg-white text-small text-start"
          style="font-size: 12.5px"
          @filtered="confOnFiltered"
          @row-selected="onRowSelected"
          ref="xslxTable"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="selected"
              :value="row.item.timeStamp"
              :aria-describedby="true"
              inline
            ></b-form-checkbox>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "archivesDataToShow",
  props: {
    id: {
      default: ""
    }
  },
  data() {
    return {
      fields: [
        { key: "checkbox", label: "" },
      ],
      info: [],
      selected: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      pageOptions: [
        { value: 5, text: "5 rows" },
        { value: 10, text: "10 rows" },
        { value: 15, text: "15 rows" },
        { value: 100, text: "Show a lot" }
      ],
      sortBy: "item.",
      sortDesc: true,
      sortDirection: "asc",
      filterOn: []
    };
  },
  computed: {
    infoGenerator() {
      return this.$store.state.data.xslxDatas;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  methods: {
    initModal() {
      this.dataSeting();
    },
    resetModal() {
      this.selected = [];
    },
    handleOk() {
    },
    selectAllRows() {
      this.$refs.xslxTable.selectAllRows();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    dataSeting() {
      this.info = [];
      this.infoGenerator.forEach((item, key) => {
        Object.entries(item).forEach((i) => {
          this.info.push(
            Object.assign({}, { timeStamp: i[0] }, i[1])
          );
        });
      });


      Object.entries(this.info[0]).forEach((x, i) => {
        this.fields.push({
          key: x[0],
          label: x[0],
          sortable: true
        });
      });
      this.totalRows = this.info.length;
      // this.info = this.info.slice(0, 9);
    },
    confOnFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteAllFile() {
      this.$bvModal
        .msgBoxConfirm("delete all records confirmation", {
          title: "Are you sure to delete all the records?",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.lang == "per" ? "بله" : "YES",
          cancelTitle: this.lang == "per" ? "لغو" : "NO",
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.$store
              .dispatch("data/removeAllXslxFile", { id: this.id })
              .then(() => {
                this.$bvModal.hide("xslxDetails");
              });
          } else this.$bvModal.show("xslxDetails");
        })
        .catch((err) => {
          this.$bvModal.show("xslxDetails");
        });
    },
    deleteSelectedRecords() {
      this.$bvModal
        .msgBoxConfirm("delete selected records confirmation", {
          title: "Are you sure to delete selected records?",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.lang == "per" ? "بله" : "YES",
          cancelTitle: this.lang == "per" ? "لغو" : "NO",
          hideHeaderClose: true,
          noCloseOnBackdrop: true,
          centered: true
        })
        .then((value) => {
          if (value == true) {
            this.$store
              .dispatch("data/removeSeletedRec", { id: this.id, timestamps: this.selected })
              .then(() => {
                this.$bvModal.hide("xslxDetails");
              });
          } else this.$bvModal.show("xslxDetails");
        })
        .catch((err) => {
          this.$bvModal.show("xslxDetails");
        });
    }
  }
};
</script>
