<template>
  <div
    ref="draggable"
    @mousedown="dragging = true"
    class="draggable bg-inherit"
    @dragend="endDrag"
  >
    <img class="img-fluid" :src="img.img" alt="" />
    <p style="font-size: 12px">{{ img.name }}</p>
  </div>
</template>

<script>
export default {
  name: "dragAble",
  props: {
    img: {},
  },
  data() {
    return {
      dragging: false,
      position: {
        x: 0,
        y: 0,
      },
      offset: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    endDrag(event) {
      this.img["position"] = {
        x: event.clientX + event.offsetX,
        y: event.clientY + event.offsetY,
      };
      this.$emit("input", this.img);
      this.dragging = false;
    },
  },
};
</script>

<style>
.draggable {
  border: none;
  cursor: all-scroll;
}
</style>
