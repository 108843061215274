<template>
  <div>
    <b-row align-h="between" class="g-0 p-0 h-100">
      <b-container fluid class="p-0 h-100">
        <ejs-grid
          ref="grid"
          :width="'auto'"
          :locale="lang"
          :allowSorting="true"
          :enableAdaptiveUI="true"
          :allowExcelExport="true"
          :allowFiltering="true"
          :editSettings="statTableOptions.editSettings"
          :allowPdfExport="true"
          :toolbar="toolbarGenerator"
          :pdfExportComplete="pdfExportComplete"
          :toolbarClick="toolbarClick"
          :actionBegin="actionBegin"
          :allowPaging="true"
          :enableRtl="lang == 'per'"
          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
          :selectionSettings="statTableOptions.selectionOptions"
          :dataSource="userData.users"
        >
          <e-columns>
            <e-column width="50" type="checkbox"></e-column>
            <e-column
              v-for="(item, index) in usersFields"
              :key="index"
              :field="item.key"
              :width="150"
              :headerText="item[lang]"
              :template="item.hasTemplate ? item.key : null"
            ></e-column>
          </e-columns>
          <template v-slot:modify="{ data }">
            <span class="e-icons e-delete-1 text-danger"
                  @click="removeUser(data.id)"
                  style="font-size: 17px"
                  :key="data.id"></span>
          </template>
          <template v-slot:name="{ data }">
            {{ data.fname + " " + data.lname }}
          </template>
          <template v-slot:avatar="{ data }">
            <div>
              <b-avatar
                variant="info"
                :src="imgUrl[data.id]"
                :text="`${data.fname ? data.fname[0] : ''} ${
                  data.lname ? data.lname[0] : ''
                }`"
                style="background-color: #0d6efd; color: white"
              ></b-avatar>
            </div>
          </template>
          <template v-slot:role="{ data }">
            <div>
              <ejs-dropdownlist
                id="dropdownlist_role"
                :dataSource="permissionList"
                @select="changeUserPermission(data,data.id, $event)"
                :fields="{ text: 'text', value: 'value' }"
                v-model="data.permission.id"
                :enabled="data.status != 'creator'"
                :value="data.permission.id"
              ></ejs-dropdownlist>

            </div>
          </template>
          <template v-slot:status="{ data }">
            <p>{{ translateStatus[data.status][lang] }}</p>
          </template>
        </ejs-grid>
      </b-container>
    </b-row>
    <b-modal
      id="invUser"
      size="md"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      body-text-variant="dark"
      content-class="shadow"
      :dir="dir[lang]"
    >
      <template #modal-header>
        <h5>{{ modalTitle[lang] }}</h5>
        <i @click="$bvModal.hide('invUser')">
          <font-awesome-icon icon="fas fa-xmark" class="close" />
        </i>
      </template>
      <div>
        <ejs-dropdownlist
          id="ddlelement"
          :dataSource="items"
          :placeholder="title[lang]"
          :fields="{ text: 'text', value: 'value' }"
          v-model="selectedUser"
        ></ejs-dropdownlist>
      </div>
      <template #modal-footer>
        <div :dir="dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button variant="secondary" @click="$bvModal.hide('invUser')">{{
                cancel[lang]
              }}
            </b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="invUser">{{
                ok[lang]
              }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="openCreate"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      content-class="shadow"
      :dir="dir[lang]"
      :cancel-title="cancel[lang]"
      :ok-title="ok[lang]"
      @ok="handleOk"
    >
      <template #modal-header>
        <h5>{{ roleTitle[lang] }}</h5>
        <i @click="closeModal">
          <font-awesome-icon icon="fas fa-xmark" class="close" />
        </i>
      </template>
      <create-roles :id-wb="userData.id" ref="roles" />
    </b-modal>
  </div>
</template>
<script>
import { host } from "@/models/host";
import CreateRoles from "@/components/createRoles.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";


export default {
  name: "workbenchUsers",
  components: { CreateRoles },
  props: {
    userData: {
      default: {}
    }
  },
  data() {
    return {
      imgUrl: {},
      items: [],
      translateStatus: {
        creator: { eng: "workbench creator", per: "سازنده میزکار" },
        invited: { per: "دعوت شده", eng: "invited" },
        owner: { per: "مالک", eng: "owner" },
        waiting: { per: "در انتظار تایید", eng: "waiting" }
      },
      statTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: true,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      usersFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "avatar", per: "آواتار", eng: "avatar", hasTemplate: true },
        { key: "name", per: "نام", eng: "Name", hasTemplate: true },
        { key: "username", per: "نام کاربری", eng: "username", hasTemplate: false },
        { key: "role", per: "نقش", eng: "role", hasTemplate: true },
        { key: "status", per: "وضعیت", eng: "status", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      permissionList: [],
      selectedUser: "",
      modalTitle: { per: "اضافه کردن کاربر", eng: "add user" },
      roleTitle: { per: "نقش جدید", eng: "new role" },
      dir: { per: "rtl", eng: "ltr" },
      cancel: { eng: "Cancel", per: "لغو" },
      ok: { eng: "Ok", per: "قبول" },
      title: { per: "دعوت کاربر", eng: "invite user" },
      deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
      deleteConfirmContent: {
        eng: "Are you sure to remove the user from this workbench ?",
        per: "آیا از حذف این کاربر از این میزکار اطمینان دارید ؟"
      }
    };
  },
  computed: {
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    userPermission() {
      return this.$store.state.data.user.permissions;
    },
    toolbarGenerator() {
      if (this.userPermission.includes("admin_workbench_users")) {
        return [
          "Search",
          {
            text: this.roleTitle[this.lang],
            id: "roleBtn",
            prefixIcon: "e-icons e-add"
          },
          {
            text: this.modalTitle[this.lang],
            id: "inviteUser",
            prefixIcon: "e-icons e-add"
          }
        ];
      } else {
        return ["Search"];
      }
    }
  },
  methods: {
    getAvatar(id) {
      this.$store
        .dispatch("data/getUsersAvatar", { id: id })
        .then((response) => {
          if (response != null) {
            this.$set(this.imgUrl, id, response);
          }
        });
    },
    actionBegin(args) {
      if (args.requestType != "paging" && args.requestType != "refresh") {
        args.cancel = true;
        if (args.requestType == "add") {
          this.$bvModal.show("invUser");
        }
      }
    },
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.grid.showSpinner();
        this.$refs.grid.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.grid.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.grid.csvExport();
      } else if (args.item.id == "roleBtn") {
        this.$bvModal.show("openCreate");
      }
      if (args.item.id == "inviteUser") {
        this.$bvModal.show("invUser");
      }
    },
    pdfExportComplete() {
      this.$refs.grid.hideSpinner();
    },
    host() {
      return host;
    },
    handleOk(e) {
      e.preventDefault();
      this.$refs.roles.storeRole();
      setTimeout(() => {
        this.getRoles(this.userData.id);
      }, 100);
      this.$bvModal.hide("openCreate");
    },
    invUser($ev) {
      $ev.preventDefault();
      this.$store
        .dispatch("data/invFromAdmin", {
          workbench: this.userData.id,
          user: this.selectedUser
        })
        .then((res) => {
          this.userData = res.currentWorkbench;
          this.$bvModal.hide("invUser");
        });
    },
    changeUserPermission(val, id, role) {
      let roleName = "";
      this.permissionList.find((x) => {
        if (x.value == role.itemData.value)
          roleName = x.text;
      });
      this.$store
        .dispatch("data/editRoleWBByAdmin", {
          id: id,
          role: roleName,
          workbench: this.userData.id
        })
        .then(
          (response) => {
            // this.message = response.message;
            // this.$store.dispatch("data/getWBListdata");
            // this.$refs.wbUsers.refresh();
            this.userData["users"] = response;
            this.$refs.grid.refresh();
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            // if ((this.message === 'err1')||(this.message === 'err2')||(this.message === 'err3')){
            //     this.submitted = false
            //     this.successful = false
            //     this.user = ''
            // }
          }
        );
    },
    removeUser(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.deleteConfirm[this.lang],
        locale: this.lang,
        Enabletrl: this.lang == "per" ? true : false,
        content: this.deleteConfirmContent[this.lang],
        okButton: {
          text: this.ok[this.lang], click: () => {
            this.$store
              .dispatch("data/removeWBuserAdmin", {
                id: id,
                workbench: this.userData.id
              }).then((response) => {
              this.userData = response.currentWorkBench;
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    getRoles(id) {
      this.$store
        .dispatch("data/getRolesListAdmin", { workbench: id })
        .then((res) => {
          this.roles = [];
          this.permissionList = [];
          this.roles = res.message;
          this.roles.forEach((x) => {
            this.permissionList.push({
              text: x.name,
              value: x._id
            });
          });
        });
    },
    closeModal() {
      // this.getRoles(this.userData.id);
      this.$bvModal.hide("openCreate");
    }
  },
  mounted() {
    this.getRoles(this.userData.id);
    this.$store.dispatch("data/getUsersAdminListdata").then((res) => {
      this.items = [];
      res.message.forEach((item) => {
        this.items.push({
          text: item.name + " " + item.familyName,
          value: item.username
        });

        this.getAvatar(item.id);
      });
    });
  },

  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>
