<template>
  <div>
    <div>
      <div class="flex items-center w-full mb-3">
        <label
          for="dropzone-file"
          class="
            flex flex-col
            items-center
            justify-center
            w-[100%]
            h-[30%]
            border-2 border-gray-300 border-dashed
            rounded-lg
            cursor-pointer
            bg-gray-50
            hover:bg-gray-100
            :hover:bg-gray-600
          "
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              class="w-5 h-5 mb-2 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p class="mb-2 text-[10px] text-gray-500">
              <span class="font-semibold">Click to upload</span>
            </p>
          </div>
          <input
            @change="toBase64Creator"
            id="dropzone-file"
            type="file"
            class="hidden"
          />
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { base64Creator } from "@/mixins";
import store from "@/store";
import { mapState } from "vuex";
import { processFlow } from "@/store/processFlow.module";

const joint = require("@joint/core/dist/joint");

export default {
  name: "elementCreator",
  props:{
    value:{}
  },
  data() {
    return {
      path: new joint.shapes.standard.Image(),
      img: "",
      imgSrc: ""
    };
  },
  methods: {
    toBase64Creator(file)  {
      base64Creator(file)
        .then((fileInfo) => {
          if (processFlow.state.imgElement["img"] !== fileInfo.base64) {
            this.$store.commit("imaCreator", {
              element: "Image",
              name: "image",
              img: fileInfo.base64,
              position: { x: 380, y: 20 },
            });
            this.$store.commit("updateGallery", fileInfo.base64);
            this.$emit('input',{
              element: "Image",
              name: "image",
              img: fileInfo.base64,
              position: { x: 380, y: 20 },
            })
          } else alert("فایل آپلود شده در سیستم وجود دارد!");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    ...mapState(["imgElement"]),
  },
};
</script>
