<template>
  <div>
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li v-for="(item,index) in items" class="nav-item" @click="activitionItems(item.value)" :key="index">
          <p
            class="nav-link"
            :class="{ active: activeTab === item.value }"
            :id="'tab-'+index"
            data-toggle="tab"
            role="tab"
            aria-selected="true"
          >
            {{ item.title }}
          </p>
        </li>
      </ul>
    </div>
    <div class="card-body h-100" style="overflow: hidden">
      <transition name="slide-fade" mode="out-in">
        <div class="tab-content h-100">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    items: {
      default: []
    }
  },
  data() {
    return {
      activeTab: ""
    };
  },
  methods: {
    activitionItems(item) {
      this.activeTab = item;
    }
  },
  watch: {
    activeTab(val) {
      this.$emit("input", val);
    }
  }
};
</script>
