<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/map-manager.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>

    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card text-center h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="card-body h-100" style="overflow: hidden">
            <b-container fluid style="padding: 0 !important">
              <b-row
                align-h="between"
                class="g-0"
                style="
                  border: 1px solid #ddd;
                  padding: 0 0 10px 0;
                  border-radius: 10px;
                  padding: 10px;
                  margin-bottom: 10px;
                "
              >
                <b-col lg="4" sm="6" md="5" class="my-1">
                  <b-form-group label-for="filter-input">
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="curTableOptions.filter"
                        type="search"
                        :placeholder="dict.search[lang]"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!curTableOptions.filter"
                          @click="curTableOptions.filter = ''"
                        >{{ dict.clear[lang] }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col lg="4" sm="6" md="5" class="my-1">
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      v-model="curTableOptions.filterOn"
                      :aria-describedby="ariaDescribedby"
                      class="mt-1"
                      style="display: flex; justify-content: space-around"
                    >
                      <b-form-checkbox value="source"
                      >{{ dict.th_name[lang] }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>

                <b-col lg="1" sm="1" md="1" class="my-1">
                  <div
                    style="font-size: 24px; display: flex; justify-content: end"
                  >
                    <b-dropdown
                      id="dropdown-dropleft"
                      dropleft
                      :text="dict.newLayer[lang]"
                      variant="primary"
                      class="m-2"
                    >
                      <b-dropdown-item @click="addIndoorMap"
                      >indoor map
                      </b-dropdown-item
                      >
                      <b-dropdown-item
                        @click="$bvModal.show('modal-new-indoor-map')"
                      >marker
                      </b-dropdown-item
                      >
                      <b-dropdown-item>url</b-dropdown-item>
                    </b-dropdown>
                    <!--                    <b-dropdown dropleft class="reset">-->
                    <!--                      <font-awesome-icon icon="fas fa-plus-square" />-->
                    <!--                    </b-dropdown>-->
                  </div>
                </b-col>
              </b-row>

              <b-table
                :items="curTableOptions.items"
                :fields="curTableOptions.fields"
                :current-page="curTableOptions.currentPage"
                :per-page="curTableOptions.perPage"
                :filter="curTableOptions.filter"
                :filter-included-fields="curTableOptions.filterOn"
                :sort-by.sync="curTableOptions.sortBy"
                :sort-desc.sync="curTableOptions.sortDesc"
                :sort-direction="curTableOptions.sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="curTableOnFiltered"
                ref="curTable"
              >
                <template #cell(modify)="row">
                  <div style="display: flex; justify-content: flex-end">
                    <b-button
                      :key="row"
                      pill
                      size="sm"
                      variant="outline-success"
                      class="mr-2"
                      style="
                        outline: none !important;
                        border: none;
                        box-shadow: none;
                      "
                      @click="openEditMode(row.item)"
                    >
                      <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      variant="outline-danger"
                      class="mr-2"
                      @click="removeMarkerMap(row.item._id)"
                      style="
                        margin-inline: 10px;
                        outline: none !important;
                        border: none;
                        box-shadow: none;
                      "
                    >
                      <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                    </b-button>
                  </div>
                </template>

                <template #cell(active)="row">
                  {{ getIrDate(row.item.active["eng"], lang) }}
                </template>
              </b-table>

              <b-row align-h="between">
                <b-col lg="2" sm="4" md="3" class="my-1">
                  <b-form-group label-for="per-page-select">
                    <b-form-select
                      style="width: 100%"
                      id="per-page-select"
                      v-model="curTableOptions.perPage"
                      :options="curTableOptions.pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col lg="4" sm="6" md="5" class="my-1">
                  <b-pagination
                    v-model="curTableOptions.currentPage"
                    :total-rows="curTableOptions.totalRows"
                    :per-page="curTableOptions.perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
    </div>
    <map-manager />
    <b-modal
      id="modal-upload-map"
      size="md"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      @hidden="resetModal"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.uploadIndoorMap[lang] }}</h5>
        </div>
      </template>

      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button variant="secondary" @click="resetModal">{{
                dict.cancel[lang]
              }}
            </b-button>
            <b-button variant="primary" @click="submitFile">{{
                dict.ok[lang]
              }}
            </b-button>
          </div>
        </div>
      </template>

      <div :dir="dict.dir[lang]" class="form-group mb-2">
        <div class="mb-3">
          <label for="name" class="form-label">{{ dict.mapName[lang] }}</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            :placeholder="dict.enterMapName[lang]"
            v-model="indoorMap.name"
            v-validate="'required'"
          />
          <div
            v-if="submitted && errors.has('name')"
            class="alert alert-danger"
            role="alert"
          >
            {{ dict.validName[lang] }}
          </div>
        </div>
        <label class="form-label" for="indoorMap">{{
            dict.indoorMap[lang]
          }}</label>
        <div class="d-flex justify-content-between align-items-center">
          <input
            class="form-control"
            type="file"
            ref="indoorMap"
            id="indoorMap"
            @change="uploadFile"
            accept=".osm"
          />
        </div>
        <div
          v-if="submitted && indoorMap.file === null"
          class="alert-danger"
          role="alert"
        >
          file is required!
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import MapManager from "@/components/mapManager.vue";

export default {
  name: "indexMap",
  components: { MapManager },
  data() {
    return {
      curTableOptions: {
        items: [],
        fields: [
          {
            key: "title",
            label: "name",
            sortable: true,
            sortDirection: "asc"
          },
          {
            key: "type",
            label: "type",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "modify", label: "" }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      successful: true,
      submitted: false,
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "map manager", per: "مدیریت نقشه ها" },
        newLayer: { eng: "new layer", per: "ایجاد لایه جدید" },

        tab1: { eng: "indoor maps", per: "نقشه های داخلی" },
        tab2: { eng: "markers", per: "نشان گرها" },

        uploadIndoorMap: {
          eng: "upload indoor map",
          per: "بارگذاری نقشه داخلی"
        },
        indoorMap: { eng: "map file", per: "فایل نقشه" },
        chooseFile: { eng: "choose file", per: "انتخاب فایل" },
        noFile: { eng: "no file chosen", per: "فایلی انتخاب نشده است" },
        mapName: { eng: "map name", per: "نام نقشه" },
        enterMapName: { eng: "enter map name", per: "نام نقشه را وارد کنید" },

        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },

        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },

        tab3: { eng: "events", per: "رخدادها" },
        active: { eng: "active", per: "فعال" },
        acknowledge: { eng: "acknowledge", per: "تایید" },

        th_floorTitle: { eng: "name", per: "نام" },

        th_name: { eng: "name", per: "نام" },

        th_modify: { eng: "", per: "" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },

        po_5: { eng: "5 rows", per: "5 ردیف" },
        po_10: { eng: "10 rows", per: "10 ردیف" },
        po_15: { eng: "15 rows", per: "15 ردیف" },
        po_100: { eng: "100 rows", per: "ردیف های بیشتر" },

        wbname: { eng: "workbench name", per: "نام میزکار" },
        wbowner: { eng: "workbench owner", per: "مالک میزکار" },
        wbresources: { eng: "workbench resources", per: "منابع میزکار" },

        regmsg: { eng: "Don't have an account?", per: "حساب کاربری ندارید؟" },
        register: { eng: "Register now", per: "ثبت نام" },
        errCaptcha: {
          eng: "invalid captcha",
          per: "کد امنیتی وارد شده اشتباه می باشد"
        },
        errUserPass1: {
          eng: "invalid username or password",
          per: "نام کاربری یا کلمه عبور اشتباه است"
        },
        errUserPass2: {
          eng: "check your keyboard language",
          per: "• وضعيت زبان(En/FA)سيستم خود را كنترل كنيد"
        },
        errUserPass3: {
          eng: "the password is caps sensitive",
          per: "• پسورد به حروف بزرگ و كوچك حساس است"
        }
      },
      indoorMap: {
        name: "",
        file: null
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    lang(newValue) {
      this.curTableOptions.fields.forEach((field) => {
        field.label = this.dict["th_" + field.key][newValue];
      });
      this.curTableOptions.pageOptions.forEach((field) => {
        field.text = this.dict["po_" + field.value][newValue];
      });
    }
  },
  methods: {
    formatDateTime(date, option) {
      return new Intl.DateTimeFormat("fa-IR", option).format(date);
    },
    curTableOnFiltered(filteredItems) {
      this.curTableOptions.totalRows = filteredItems.length;
      this.curTableOptions.currentPage = 1;
    },
    getIrDate(idate, lang) {
      const date = new Date(idate);
      if (lang === "eng") return date;
      else if (lang === "per") {
        var time = this.formatDateTime(date, {
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        });
        var year = this.formatDateTime(date, { year: "numeric" });
        var month = this.formatDateTime(date, { month: "long" });
        var wday = this.formatDateTime(date, { weekday: "long" });
        var day = this.formatDateTime(date, { day: "numeric" });
        var str = `${wday} ${day} ${month} ${year} ساعت  ${time}`;
        return str;
      }
    },
    addIndoorMap() {
      this.$bvModal.show("modal-upload-map");
    },
    uploadFile() {
      this.indoorMap.file = this.$refs.indoorMap.files[0];
      // console.log(this.indoorMap.file);
    },
    submitFile() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          const formData = new FormData();
          formData.append("name", this.indoorMap.name);
          formData.append("file", this.indoorMap.file);

          this.$store.dispatch("data/uploadMap", formData).then(
            (data) => {
              this.getIndoorMaps();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-upload-map");
              });
            },
            (error) => {
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              alert(this.message);
            }
          );
        }
      });
    },
    editIndoorMap(map) {
      this.$store.dispatch("chpge/parent2child", {
        task: "editIndoorMap",
        from: "mapManager",
        map: map
      });
      this.$root.$emit("bv::show::modal", "modal-indoor-map");
    },
    removeIndoorMap(map) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete it.")
        .then((value) => {
          if (value === true) {
            this.$store.dispatch("data/removeFloor", { id: map.id }).then(
              (data) => {
                this.message = data.message;
                this.successful = true;

                this.getIndoorMaps();

                //this.getGroups();
              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                alert(this.message);
              }
            );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    removeMarkerMap(map) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete it.")
        .then((value) => {
          if (value === true) {
            this.$store.dispatch("data/deleteMarker", { id: map }).then(
              (data) => {
                this.message = data.message;
                this.successful = true;
                this.getIndoorMaps();

              },
              (error) => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                alert(this.message);
              }
            );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    resetModal() {
      (this.indoorMap = {
        file: null,
        name: ""
      }),
        (this.submitted = false),
        this.$nextTick(() => {
          this.$bvModal.hide("modal-upload-map");
        });
    },
    getIndoorMaps() {
      this.$store.dispatch("data/getAllMaps").then(
        (data) => {
          this.curTableOptions.items = data.message;
          this.curTableOptions.totalRows = this.curTableOptions.items.length;
          this.$refs.curTable.refresh();
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    openEditMode(data) {
      this.$store.dispatch("chpge/parent2child", {
        task: "editMarker",
        type: "Edit",
        data: data
      });
      this.$store.state.data.markerList = [];
      data.details.shape.forEach((item) => {
        this.$store.commit("data/updateMarkerList", item);
      });
      this.$bvModal.show("modal-new-indoor-map");
    }
  },
  mounted() {
    this.getIndoorMaps();
  }
};
</script>
<style>
.table-t-notice {
  background-color: #58d68d;
}

.table-t-alarm {
  background-color: #ec7063;
}

.table-t-warning {
  background-color: #f1c40f;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.my-modal-header {
  display: block !important;
}
</style>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-100%);
}
</style>
