<template>
    <div>
        <b-modal
        id="modal-setDashCompAdd"
        size="lg"
        centered
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
        <div>
            <b-container fluid="md">
                <div style="padding: 0px 20px 0px 20px;">
                    <b-list-group flush>
                        <b-list-group-item style="background-color:transparent;color:black;">
                            <b-form-group
                                label="Component Name"
                                label-for="name"                                            
                                :invalid-feedback="errors.first('name')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-input
                                    name="name"
                                    type="text"
                                    placeholder="Enter Name"
                                    v-model="component.name"
                                    :state="(submitted)? (!errors.has('name')): null"  
                                    v-validate="'required|min:2|max:20'"
                                    style="width:100%"
                                ></b-form-input>
                            </b-form-group> 

                            <b-form-group
                                label-for="type"
                                :invalid-feedback="errors.first('type')"
                            >
                                <b-form-select
                                    style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                    name="type"
                                    size="lg"
                                    v-model="component.type"
                                    :options="typeList"
                                    :state="(submitted)? (!errors.has('type')): null"  
                                    v-validate="'required'"
                                    
                                ></b-form-select>
                            </b-form-group>   

                            
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </b-container>
        </div>
        </b-modal>
    </div>
</template>




<script>
/* eslint-disable */

    export default {
        name: "setEquipLight",
        props: {
            pid: String
        },
        
        data() {
            return {
                modalTitle:'',

                component:{
                    name: '',
                    type:null,
                    data:{},
                    attribute:{}
                },

                submitted: false,
                successful: false,
                message: '',

                typeList: [{ text: 'Select One', value: null },
                            { text: 'plot', value: 'plot' },
                            { text: 'display', value: 'display'},

                            ],
            }
        },

        methods: {
            initModal: function(){
                this.modalTitle = 'Add New Component'
            },
            resetModal: function(){
                this.component.name = '',
                this.component.type = null,

                this.submitted = null;
                this.successful = null;
                this.message= null;

                // this.$store.dispatch('chpge/parent2child', {task: 'close_group_modal'})
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                console.log('submit',this.type)
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        this.$store.dispatch('data/createDashboard', this.component).then(
                            data => {
                                
                            this.message = data.message;
                            this.successful = true;


                            // this.$store.dispatch('chpge/updatePage', this.updatePage+1)
                            this.$nextTick(() => {
                                this.$bvModal.hide('modal-setDashCompAdd')
                            })
                            },
                            error => {
                            this.message =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.successful = false;
                            alert(this.message)
                            
                            }
                        );
                    }
                });
            },
            
        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .panel_header {
        padding: 10px;
        height: 60px;
        font-size: 20px;
        color: rgb(0, 0, 0);
        font-variant:small-caps;
        border-bottom: 2px solid rgb(210, 210, 210);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .panel_col {
        height: 100%; 
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>