<template>
    <div>
      <div class="panel_container">
        <div class="panel_header">
          <div class="panel_col" style="width: 15%"></div>
          <div class="panel_col" style="width: 70%">
            <p style="margin: 0;"><strong>{{ panelData.title }}</strong></p>
          </div>
          <div class="panel_col" style="width: 15%">
            <button
              style="padding:0px; border: none; box-shadow: none; background-color: transparent;"
              @click="visible=!visible"
              >
              <i v-if="visible" style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-home" /></i>
              <i v-else style="color:rgb(112, 112, 113)"><font-awesome-icon icon="fas fa-gear" /></i>
            </button>
          </div>
        </div>
        
        <!-- settings -->
        <Transition  name="slide-fade" mode="out-in">  <!-- :duration="{ enter: 200, leave: 200 }" -->
          <div v-if="visible" key="1" class="panel_footer">
            <b-card no-body style="overflow-y: auto; height:100%">
              <b-tabs card>
                <b-tab title="Groups" active>
                  <b-table :items="groups" :fields="groupFields" thead-class="hidden_header" responsive="sm" small> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editGroup(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removeGroup(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="addGroup" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>
                </b-tab>
                <b-tab title="Schedule">
                  <b-table  :items="schedules" :fields="scheduleFields" thead-class="hidden_header" responsive="sm" small   ref="selectableTable"  @row-clicked="onRowSelected"> 
                    <template #cell(modify)="row" >
                        <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-success" @click="editSchedule(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                            </b-button>
                            <b-button pill size="sm" variant="outline-danger" @click="removeSchedule(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                        </div>
                    </template>
                      <template #cell(isSelected)="row">
                        <input
                          style="pointer-events: none"
                          type="checkbox"
                          :checked="row.item._id === selectedSchedule"
                        >
<!--                        <template v-if="row.item._id===selectedSchedule">-->
<!--                          <span aria-hidden="true">&check;</span>-->
<!--                          <span class="sr-only"></span>-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                          <span aria-hidden="true">&nbsp;</span>-->
<!--                          <span class="sr-only"></span>-->
<!--                        </template>-->
                      </template>
                  </b-table>
                  <div style="display:flex; justify-content: right; align-items: center;">
                    <b-button   @click="addSchedule" size="sm" variant="outline-danger"   style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                        <i><font-awesome-icon icon="fas fa-plus" /></i>
                    </b-button>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>

          <!-- home -->
          <div v-else key="2">
            <div class="panel_body1" >
              <div class="panel_col" style="width: 5%"></div>
              <div class="panel_colf" style="width: 70%; padding:5px; ">
                
                <div class="radiobox" >
                  <label class="radiobtn" v-for="opt in options" :key="opt.value" >
                    <input  class="radioinp" type="radio" v-model="panelData.options.control" :value="opt.value" :name="panelData.id +'_'+ opt.value" />
                    <span v-if="opt.text==='Manual'" class="radiospan"><font-awesome-icon icon="fas fa-hand-paper"/></span>
                    <span v-if="opt.text==='Schedule'" class="radiospan"><font-awesome-icon icon="fas fa-calendar-days"/></span>
                    <span v-if="opt.text==='Sensor'" class="radiospan"><font-awesome-icon icon="fas fa-magic"/></span>
                  </label>
                </div>

                <div  class="radiobox" style="height:10%">
                  <i v-if="panelData.options.control==='schedule'" ><font-awesome-icon icon="fas fa-lock" /></i>
                  <i v-else ><font-awesome-icon icon="fas fa-lock-open" /></i>
                  <div v-if="panelData.options.control==='schedule'">
                    <div v-if="selectedScheduleTitle">
                      {{selectedScheduleTitle}}
                    </div>
                    <div v-else >
                      <font-awesome-icon icon="fas fa-calendar-days" fade style="color:red"/>
                    </div>
                  </div>
                </div>

                <myComponent_2  v-if="dataReady" :id = panelData.id :panelData = "panelData" :disable="(panelData.options.control==='manual')? false:true " style="height:75%;"/> 
                
                <!-- <myComponent_1  type = "type2" :panelData = "panelData" :disable="(panelData.control==='manual')? false:true " style="transform: scale(1.7);"/> -->

              </div>

              <div class="panel_colf" style="width: 25%; display: flex; justify-content: flex-start; flex-direction: column; padding-left: 10px;">
                <img src="../assets/images/hangLight1.png" class="card-img card-img-top " height="250"/>
              </div>

            </div>
          </div>
        </Transition>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
import myComponent_1 from "./myComponent_1.vue";
import myComponent_2 from "./myComponent_2.vue";
// import myComponent_panel_1_modal from "./myComponent_panel_1_modal.vue";
// import myComponent_panel_1_schedule from "./myComponent_panel_1_schedule.vue";
  export default {
    components: {
        myComponent_1, //transform: scale(.75);
        myComponent_2,
        // myComponent_panel_1_modal,
        // myComponent_panel_1_schedule
    },

    props: {
      panelData: Object,
      type: String,

    },
    data() {
      return {
        dataReady : false,
        visible:false,
        feeders:[],
        groups:[],
        options: [
          { text: 'Manual', value: 'manual', },
          { text: 'Schedule', value: 'schedule' },
          // { text: 'Sensor', value: 'sensor' }
        ],

        selected:null,
        groupFields: [
          { key: 'name', label: ''},
          { key: 'modify', label: ''}],

        scheduleFields: [{ key: 'isSelected', label: '' },{key:'title', label: ''},{ key: 'modify', label: ''}],
        schedules:[],
        selectedSchedule:null
      }
    },
    methods: {

      onRowSelected(item) {
        // console.log(item._id)
        this.selectedSchedule = item._id

        var data =  {
                      id: this.panelData.id,
                      selectedSchedule: item._id,
                      editType: 'selectedSchedule'
                    }
        this.$store.dispatch('data/editEquipment', data).then(
          panelData => {
            // this.$store.dispatch('chpge/updatePage', this.updatePage+1)
            this.successful = true;
          },
          error => {
          this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
          this.successful = false;
          }
        );
      },
      collapsefunc() {
        this.visible = !this.visible
      },
      addSchedule: function(type){
        this.$store.dispatch('chpge/parent2child', {task: 'open_schedule_modal', type:'Add', id:this.panelData.id, groups:this.panelData.data, schedules:this.schedules, options:this.panelData.options})
      },
      editSchedule: function(index){
        this.$store.dispatch('chpge/parent2child', {task: 'open_schedule_modal', type:'Edit', id:this.panelData.id, groups:this.panelData.data, schedules:this.schedules, index, options:this.panelData.options})
      },
      addGroup: function(type){
        this.$store.dispatch('chpge/parent2child', {task: 'open_group_modal', type:'Add', id:this.panelData.id, feeders:this.feeders, groups:this.groups})
      },
      editGroup: function(index){
        this.$store.dispatch('chpge/parent2child', {task: 'open_group_modal', type:'Edit', id:this.panelData.id, feeders:this.feeders, groups:this.groups, index})
      },
      removeGroup: function(index){

        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this group')
        .then(value => {
            if (value===true){
              // this.groups.splice(index,1)
              var data =  {
                            id: this.panelData.id,
                            group: this.groups[index],
                            editType: 'removeGroup'
                          }
              this.$store.dispatch('data/editEquipment', data).then(
                panelData => {
                  this.$store.dispatch('chpge/updatePage', this.updatePage+1)
                  // this.groups = [];
                  // for (var data of panelData.data){
                  //     if (data.dtype==="Group"){
                  //     this.groups.push({name: data.dname, id: data._id, members: data.dmembers})
                  //     }
                  // }
                  this.successful = true;
                },
                error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.successful = false;
                // alert(this.message)
                }
              );
            }
        })
        .catch(err => {
        })
      },
      removeSchedule: function(index){
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this schedule')
        .then(value => {
            if (value===true){
              var data =  {
                            id: this.panelData.id,
                            schedule: this.schedules[index]._id,
                            editType: 'removeSchedule'
                          }
              this.$store.dispatch('data/editEquipment', data).then(
                panelData => {
                  this.$store.dispatch('chpge/updatePage', this.updatePage+1)
                  this.successful = true;
                },
                error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.successful = false;
                }
              );
            }
        })
        .catch(err => {
        })
      },
    },
    mounted(){
      // console.log('panelData: ',JSON.stringify(this.panelData))
      this.feeders = [];
      for (var data of this.panelData.data){
        if (data.dtype==="Feeder"){
          this.feeders.push({name: data.dname, id: data._id})
        }
      }

      this.groups = [];
      for (var data of this.panelData.data){
        if (data.dtype==="Group"){
          this.groups.push({name: data.dname, id: data._id, members: data.dmembers, did:data.did})
        }
      }

      this.schedules = this.panelData.schedules.schedules;
      this.selectedSchedule = this.panelData.schedules.selected;
      // console.log("ooooooooooooooooo",JSON.stringify(this.schedules))



      // var a = this.$refs.selectableTable.items.findIndex(x=> x.isSelected == true);//this.$refs.selectableTable.items
      //       console.log("ooooooooooooooooo",JSON.stringify(this.$refs.selectableTable.items),a)
      // if (a !=-1){
      //   this.$refs.selectableTable.selectRow(a)
      // }
      this.dataReady = true;
    },
    computed: {
      selectedScheduleTitle(){
        var s = this.schedules.find(x=> JSON.stringify(x._id) === JSON.stringify(this.selectedSchedule));
        if (s)
          return s.title;
        else
          return null
      },
      child2parent() {
          return this.$store.state.chpge.child2parent;
      },
      control(){
        return this.panelData.options.control
      },
      updatePage() {
        return this.$store.state.chpge.reload;
      },
      lang() {
        return this.$store.state.chpge.lang;
      }
    },
    watch: {

      child2parent(newValue, oldValue) {
          this.groups = JSON.parse(JSON.stringify(newValue.groups));
          // console.log('child2parent',this.groups)
          // this.equipment.data[newValue.index].did = newValue.id
      },
      control(newValue){
        // console.log('control',newValue)
        var data = {
                      id: this.panelData.id,
                      control: newValue,
                      editType: 'control'
                    }
        this.$store.dispatch('data/editEquipment', data).then(
          panelData => {
            this.$store.dispatch('chpge/updatePage', this.updatePage+1)
            this.successful = true;
          },
          error => {
            this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            this.successful = false;
            // alert(this.message)
          }
        );
      }
    },
  };
</script>



<style scoped>
  .panel_container {
    width: 500px;
    height: 500px;
  }
  .panel_container .panel_header {
    /* padding: 10px; */
    height: 10%;
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-variant:small-caps;
    border-bottom: 2px solid rgb(210, 210, 210);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container .panel_body1 {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
  }
  .panel_container  .panel_col {
    height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .panel_container  .panel_colf {
    height: 100%; 
    width: 100%;
  }
  .panel_container .panel_footer {
    /* transition: height 1s;  */
    height:90%
  }
  .buttonHolder {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
  }
  .radioboxGroup {
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .radiobtnGroup {
    width: 100%;
    height: 50px;
    display: inline-block;
        margin:2px
  }
  .radiobtnGroup .radioinpGroup {
    display: none;
  }
  .radiobtnGroup .radiospanGroup:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }
  .radiobtnGroup .radiospanGroup:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtnGroup .radiospanGroup {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
    /* background-color: #ddd; */
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0);
    padding: 5px;

  }
  .radiobtnGroup input:checked + span{
    background-color:  hsl(25.5, 94.8%, 54.3%);
    color:rgb(255, 255, 255)
  }
  .radiobox {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }
  .radiobtn {
    width: 50%;
    height: 40px;
    display: inline-block;
  }

  .radiobtn .radioinp {
    display: none;
  }

  .radiobtn .radiospan:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }

  .radiobtn .radiospan:before {
    background-color: rgb(198, 22, 22);
  }
  .radiobtn .radiospan {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 5px;
    background-color: #ddd;
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0)
  }
  .radiobtn input:checked + span{
    background-color: hsl(0, 0%, 0%); 
    color:rgb(255, 255, 255)
  }
  .tab-content{
    height: 100%;
    background-color: aqua;
  }
  /* .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: opacity ease;
  }
  .slide-fade-enter-from,
  .slide-fade-leave-to {
    opacity: 0;
  } */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
