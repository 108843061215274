<template>
    <div @dblclick="addComponent" style="background-color: #16113a; height:100%; width:100%;">
        
        <div style="display: flex; justify-content: flex-end; padding: 5px;">
            
            <b-dropdown v-if="editable" right size="sm" variant="link"   no-caret >
                <template #button-content>
                    <i><font-awesome-icon icon="fas fa-plus" /></i>  
                </template>
                <b-dropdown-item href="#" @click="addItem('chart')">Chart</b-dropdown-item>
                <b-dropdown-item href="#" @click="addItem('deviceStatus')">Device Status</b-dropdown-item>
                <b-dropdown-item href="#" @click="addItem('gauge')">Gauge</b-dropdown-item>
                <b-dropdown-item href="#" @click="addItem('map')">Map</b-dropdown-item>
                <b-dropdown-item href="#" @click="addItem('controlNode')">Control Node</b-dropdown-item>
                
            </b-dropdown>
        </div>
        <div style="color:white">
            {{newBreakpoint}}

        </div>

        <!-- <button v-if="editable" @click="addItem">+</button> -->
        <!-- <input type="checkbox" v-model="draggable" /> Draggable
        <input type="checkbox" v-model="resizable" /> Resizable
        <input type="checkbox" v-model="responsive" /> Responsive -->
        <grid-layout :layout.sync="layout"
                     :responsive-layouts="layouts"
                     :col-num="colNum"
                     :cols="cols"
                     :margin="margin"
                     :row-height="15"
                     :is-draggable="draggable"
                     :is-resizable="resizable"
                     :responsive="responsive"
                     :isBounded="bounded"
                     :vertical-compact="true"
                     :use-css-transforms="true"
                     @breakpoint-changed="breakpointChangedEvent"
                     
        >
            <grid-item v-for="item in layout"
                       :static="item.static"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :h="item.h"
                       :i="item.i"
                       :key="item.i"
            >
                 <div style="display: flex; justify-content: flex-end; padding: 5px;">
                    <span v-if="editable" class="edit" @click="handleButton('Edit',item.i)">
                        <i><font-awesome-icon icon="fas fa-pencil" /></i>
                    </span>
                    <span v-if="editable" class="remove" @click="removeItem(item.i)">
                        <i><font-awesome-icon icon="fas fa-xmark" /></i>
                    </span>
                </div>
                <hr style="margin-top: 5px;margin-bottom: 5px;">
                <div>
                    <span v-if="editable" class="text">{{item.type}}</span>
                </div>
            </grid-item>
        </grid-layout>
    
        <setEquipDataSel/>
        <setDashboardChart/>
        <setDashboardChartGroup/>
        <setDashboardGauge/>
        <setDashboardDeviceStatus/>
        <setDashboardMap/>
        <setDashboardControlNode/>
    
    </div>

</template>



    <!-- <div class="page_container yekan">
        <div class="mycont" @dblclick="addComponent">
            <div class="myrow" style="height: 60%">
                <div class="mycol" style="width: 15.75%">
                    <div class="mystatus" style="background-color: #E188B8; height:32%" > 
                    </div>
                    <div class="mystatus" style="background-color: #85A0AB; height:32%">
                    </div>
                    <div class="mystatus" style="background-color: #6768AB; height:32%;">
                    </div>
                </div>
                
                <div class="mycol" style="width: 15.75%">
                    <div class="mystatus" style="background-color: #EDC371; height:32%;">
                    </div>
                    <div class="mystatus" style="background-color: #769F51; height:32%;">
                    </div>
                    <div class="mystatus" style="background-color: #EA435D; height:32%;">
                    </div>
                </div>
                
                <div class="mycol" style="width: 10.75%">
                </div>

                <div class="mycol" style="width: 10.75%">
                </div>

                <div class="mycol" style="width: 10.75%">
                </div>

                <div class="mycol" style="width: 33.5%">
                </div>
            </div>

            <div class="myrow" style="height: 40%">
                <div class="mycol" style="width: 49.75%">
                    <div class="mymapcont">
                    </div>
                </div>
                <div class="mycol" style="width: 49.75%">
                    <div class="mymapcont">
                        <div style="display: flex; justify-content: flex-end;">
                            <b-button  @click="handleButton('Edit','2')" size="sm" variant="link"   >
                                <i><font-awesome-icon icon="fas fa-pencil" /></i>
                            </b-button>
                            
                            <b-button  @click="handleButton('delete','2')" size="sm" variant="link"   >
                                <i><font-awesome-icon icon="fas fa-xmark" /></i>
                            </b-button>

                        </div>

                        <hr style="margin-top: 5px;margin-bottom: 5px;">



                    </div>
                </div>
            </div>
        </div>
        <setEquipDataSel/>
        <setDashboardModal/>
        <setDashboardModal2/>
        <setDashboardModal3/>
    </div> -->












<script>
/* eslint-disable */
    import { GridLayout, GridItem } from "vue-grid-layout"
    import setEquipDataSel from "./setEquipDataSel.vue";
    import setDashboardChart from "./setDashboard_chart.vue";
    import setDashboardChartGroup from "./setDashboard_chart_group.vue";
    import setDashboardGauge from "./setDashboard_gauge.vue";
    import setDashboardDeviceStatus from "./setDashboard_deviceStatus.vue";
    import setDashboardMap from "./setDashboard_map.vue";
    import setDashboardControlNode from "./setDashboard_controlNode.vue";

    import setDashboardModal3 from "./setDashboardModal3.vue";
    export default {
        name: "setEquipLight",
        components: {
            setEquipDataSel,
            setDashboardChart,
            setDashboardGauge,
            setDashboardChartGroup,
            setDashboardDeviceStatus,
            setDashboardMap,
            setDashboardControlNode,
            GridLayout,
            GridItem
        },
        data() {
            return {
                title:'Dashboard Settings',

                // panelData:{components:[ {id:null, type:'plot', name:'plot1', data:{charts:[{id:'0',name:'chart1',members:[]}]}, attribue:{}},
                //                         {id:null, type:'plot', name:'plot2', data:{charts:[{id:'0',name:'chart1',members:[]}]}, attribue:{}}]
                //             },
                recordList:[],

                equipment:{
                    id:'',
                    data:[],
                    options:{}
                },

                dataFields: [
                    { key: 'icon', label: '',thStyle: { width: "5%" },},
                    { key: 'dtype', label: '',thStyle: { width: "30%" }},
                    { key: 'dname', label:'',thStyle: { width: "30%" }},
                    { key: 'did', label: '',thStyle: { width: "40%" }},
                    { key: 'modify', label: '',thStyle: { width: "5%" }}],
                    
                typeList: [{ text: 'Select One', value: null },
                            { text: 'Humidity Chart', value: 'HumChart' },
                            { text: 'Temperature Chart', value: 'tmpChart'},
                            { text: 'Illumination Chart', value: 'illChart'},
                            { text: 'Water Tank Level Chart', value: 'watChart'},

                            ],

                submitted: false,
                successful: false,
                message: '',

                layout: [],
                layouts: {},
                editable: false,
                draggable: false,
                resizable: false,
                responsive: true,
                bounded:true,
                colNum: 36,
                index: 0,
                cols:{ lg: 36, md: 30, sm: 18, xs: 12, xxs: 6 },
                margin:[5,5],
                newBreakpoint: '',
                newLayout:[]
                
            }
        },
        computed: {
            updatePage() {
                return this.$store.state.chpge.reload;
            },
            child2parent() {
                return this.$store.state.chpge.child2parent;
            }
        },
        watch: {
            child2parent(newValue, oldValue) {
                if (newValue.task==='setDashboard'){
                    if (newValue.type==='Edit'){
                        // console.log('dashboard child2parent',JSON.stringify(newValue.data))
                        let ai = this.layout.findIndex(x => JSON.stringify(x._id) === JSON.stringify(newValue.data._id))
                        if (ai != -1)
                            this.layout[ai].data = JSON.parse(JSON.stringify(newValue.data.data))
                            this.layout[ai].attribute = JSON.parse(JSON.stringify(newValue.data.attribute))
                    }
                }
            }
        },
        methods: {
            initModal: function(){
                this.index = this.layout.length;
                this.getDashboard()
                //get data
                // this.modalTitle = this.type + ' ' + this.equipment.type
                // this.typeList = [{ text: 'Select One', value: null },'Actuator',{ text: 'Humidity Sensor', value: 'SensorHum' },{ text: 'Temperature Sensor', value: 'SensorTmp' }]
            },

            onReset: function(){
                this.equipment.data=[],
                this.equipment.id = null,
                this.equipment.options = {},
                this.submitted = null;
                this.successful = null;
                this.message= null;
                this.$store.dispatch('chpge/changePage','lighting')
            },
            handleRegister() {
                console.log('submit',this.type)
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {

                        // var data = {
                        //     id: this.equipment.id,
                        //     data: this.equipment.data,
                        //     options: this.equipment.options,
                        // }
                        var data = {page:'page1', breakpoint:this.newBreakpoint, layout:this.layout}
                        console.log(data)

                        

                        this.$store.dispatch('data/createDashboard', data).then(
                            data => {
                                
                            this.message = data.message;
                            this.successful = true;
                            this.getDashboard()

                            // this.$store.dispatch('chpge/updatePage', this.updatePage+1)
                            // this.$nextTick(() => {
                            //     this.$bvModal.hide('modal-setEquipment')
                            // })
                            },
                            error => {
                            this.message =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                            this.successful = false;
                            alert(this.message)
                            
                            }
                        );
 
                    }
                });
            },
            setDataAddress: function(index,event){
                this.$store.dispatch('chpge/parent2child', {index:index, id:this.equipment.data[index].did})
                this.$root.$emit('bv::show::modal', 'modal-datasel');
            },
            addData(){
                this.equipment.data.push({did:null, dname:'', dtype:null})
            },
            getDashboard() {
                this.$store.dispatch('data/getDashboard').then(
                    data => {
                        // console.log(JSON.stringify(data))
                        // this.layout = data;
                        
                        var layouts={}
                        console.log('ssssssss',data)
                        var bps = ['lg','md','sm','xs','xxs']
                        data.forEach(element => {
                        for (var bp of bps){
                            console.log(bp)
                            if (element[bp]){
                                if (layouts[bp])
                                    layouts[bp].push({...element[bp],i:element.i,_id:element._id,type:element.type,data:element.data?element.data:null})
                                else
                                    layouts[bp] = [{...element[bp],i:element.i,_id:element._id,type:element.type,data:element.data?element.data:null}]
                            }
                        }
                        });
                        console.log(layouts)
                        this.layouts = layouts;

                        for (var bp of bps){
                            if (layouts[bp]){
                                this.layout = layouts[bp];
                                break;
                           }
                        }
                        console.log(this.layout)


                        




                        // for (var d of data.data){
                        //     this.equipment.data.push({did:d.did, dname:d.dname, dtype:d.dtype})
                        // }
                        // console.log(JSON.stringify(this.equipment))
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
            
            
            handleButton(type,component) {
                let ai = this.layout.findIndex(x => x._id === component)
                if (ai != -1){
                    var data = this.layout[ai]
                    if (data.type === 'chart'){
                        this.$store.dispatch('chpge/parent2child', {task: 'chart_modal', type:type, data:data})
                        this.$root.$emit('bv::show::modal', 'modal-setDashComp');
                    }
                    else if (data.type === 'gauge'){
                        this.$store.dispatch('chpge/parent2child', {task: 'gauge_modal', type:type, data:data})
                        this.$root.$emit('bv::show::modal', 'modal-setDashDGauge');
                    }
                    else if (data.type === 'deviceStatus'){
                        this.$store.dispatch('chpge/parent2child', {task: 'deviceStatus_modal', type:type, data:data})
                        this.$root.$emit('bv::show::modal', 'modal-setDashDeviceStatus');
                    }
                    else if (data.type === 'map'){
                        this.$store.dispatch('chpge/parent2child', {task: 'map_modal', type:type, data:data})
                        this.$root.$emit('bv::show::modal', 'modal-setDashMap');
                    }
                    else if (data.type === 'controlNode'){
                        this.$store.dispatch('chpge/parent2child', {task: 'controlNode_modal', type:type, data:data})
                        this.$root.$emit('bv::show::modal', 'modal-setDashControlNode');
                    }

                }
            },
            addComponent(){
                console.log('double click on container')
                this.editable = !this.editable
                this.draggable = !this.draggable
                this.resizable = !this.resizable
                // this.$root.$emit('bv::show::modal', 'modal-setDashCompAdd');
                if (!this.editable){
                    console.log(this.layout)
                    this.handleRegister()
                }
            },
            addItem: function (type) {
                // Add a new item. It must have a unique key!
                var item = {
                    x: (this.layout.length * 2) % (this.colNum || 12),
                    y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                    w: 2,
                    h: 2,
                    type: type,
                    name: '',
                    data: {},
                    attribute: {} 
                };

                // this.layout.push()

                this.$store.dispatch('data/addDashboardItem', {page:this.curPage, item:item}).then(
                    data => {
                        console.log('new Item',data)

                        var addedItem = {
                            x: data.lg.x,
                            y: data.lg.y,
                            w: data.lg.w,
                            h: data.lg.h,
                            type: data.type,
                            name: data.name,
                            data: {},
                            attribute: {}, 
                            i:data.i,
                            _id:data._id,
                        }

                        console.log('new layout',this.layout,addedItem)

                        this.layout.push(addedItem);
                      
                        var bps = ['lg','md','sm','xs','xxs']

                        for (var bp of bps){
                            this.layouts[bp].push(addedItem)
                        }

                        console.log('new layout',this.layout)




                        // this.message = data.message;
                        // this.successful = true;
                    },
                    error => {
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.successful = false;
                    alert(this.message)
                    
                    }
                );
                
                // Increment the counter to ensure key is always unique.
                // this.index++;
            },
            removeItem: function (item) {
                this.$store.dispatch('data/removeDashboardItem', {item:item}).then(
                    data => {
                        const index = this.layout.map(item => item.i).indexOf(item);
                        this.layout.splice(index, 1);

                        console.log('new layout',this.layout)
                      
                        var bps = ['lg','md','sm','xs','xxs']

                        for (var bp of bps){
                            const index = this.layouts[bp].map(item => item.i).indexOf(item);
                            this.layouts[bp].splice(index, 1);
                        }

                        console.log('new layout',this.layouts)
                    },
                    error => {
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.successful = false;
                    alert(this.message)
                    
                    }
                );

                
            },
            breakpointChangedEvent: function(newBreakpoint, newLayout){
                this.newBreakpoint = newBreakpoint;
                this.newLayout = newLayout
                console.log("BREAKPOINT CHANGED breakpoint=", newBreakpoint, ", layout: ", newLayout );
            }

        },
        mounted() {
            this.initModal()
        }
        
    };
</script>

<style scoped>
    .td-class{
        width: 20%;
        text-align: left;
        font-weight: bold;
        font-size: large;
    }
    .page_container {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .mycont {
        height: 100%; 
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
        background-color: #16113a;
        opacity: 1;
        padding: 10px 15px 10px 15px;

            z-index: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .myrow {
        /* height: 100%;  */
        width: 100%;
        display: flex;
        justify-content: space-between;
        --bs-gutter-x: 1.5rem;
        margin: 5px 0px 5px 0px;
    }

    .mycol {
        height: 100%; 
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* justify-content: space-between; */
        /* --bs-gutter-x: 1.5rem; */
        /* margin: 5px 0px 5px 0px; */
    }
    .mystatus {
        /* height: 100%; 
        width: 100%; */
        border: 1px solid #16113a;
        border-radius: 0.475rem;
        padding: 10px 10px 10px 10px;
        background-color: rgb(39, 41, 83);
        display: flex;    
        flex-direction: column;     
        justify-content: center;
        color : rgb(255, 255, 255)
    }
    .mymapcont {
        height: 100%; 
        width: 100%;
        border: 1px solid #16113a;
        border-radius: 0.45rem;
        background-color:rgb(39, 41, 83);
        opacity: 1;
        /* padding: 10px 10px 10px 10px; */
        display: flex;
        flex-direction: column; 
    }

    .layoutJSON {
        background: #ddd;
        border: 1px solid black;
        margin-top: 10px;
        padding: 10px;
    }
    .columns {
        -moz-columns: 120px;
        -webkit-columns: 120px;
        columns: 120px;
    }
/*************************************/
    .remove {
        cursor: pointer;
        color: rgb(175, 235, 11);
        margin-right: 10px;
        font-size: 18px;
    }
    .edit {
        cursor: pointer;
        color: rgb(9, 180, 210);
        margin-right: 10px;
        font-size: 18px;
    }
    .vue-grid-layout {
        background-color: #16113a;
        
    }
    .vue-grid-item:not(.vue-grid-placeholder) {
        background-color:rgb(39, 41, 83);
        border: 1px solid #16113a;
        border-radius: 0.45rem;
    }
    .vue-grid-item .resizing {
        opacity: 0.9;
    }
    .vue-grid-item .static {
        background: #cce;
    }
    .vue-grid-item .text {
        color:#cce;
        font-size: 24px;
        text-align: center;
        /* position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; */
        margin: auto;
        /* height: 100%;
        width: 100%; */
    }
    .vue-grid-item .no-drag {
        height: 100%;
        width: 100%;
    }
    .vue-grid-item .minMax {
        font-size: 12px;
    }
    .vue-grid-item .add {
        cursor: pointer;
    }
    .vue-draggable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
        background-position: bottom right;
        padding: 0 8px 8px 0;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        cursor: pointer;
    }


</style>