<script lang="ts">
import * as XLSX from "xlsx";

export default {
  name: "uploadExcel",
  data() {
    return {
      file: "",
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        excelTitleModal: { eng: "import data", per: "وارد کردن اطلاعات" },
        exportTitleModal: { eng: "export data", per: "خروجی گرفتن از دیتا" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" }
      }
    };
  },
  methods: {
    getFileFromInput(file) {
      this.file = file;
    },
    readXslxForValue() {
      if (this.file) {
        let repeated = [];
        const reader = new FileReader();

        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.$store.dispatch("chpge/child2parent", {
            task: "excel_file_data",
            type: "upload",
            item: data
          });

          this.$bvModal.hide("modal-upload-excel");
        };
        reader.readAsBinaryString(this.file.target.files[0]);
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<template>
  <b-modal
    id="modal-upload-excel-component"
    size="md"
    centered
    header-border-variant="primary"
    header-class="my-modal-header "
    @hidden="file = ''"
    hide-footer
  >
    <template #modal-header>
      <div :dir="dict.dir[lang]">
        <h5>{{ dict.excelTitleModal[lang] }}</h5>
      </div>
    </template>

    <div>
      <div class="d-flex justify-content-start align-items-center">
        <div class="form-group mb-2 col-md-10">
          <label class="form-label" for="indoorMap">import file </label>
          <div class="d-flex justify-content-between">
            <input
              class="form-control"
              type="file"
              @change="getFileFromInput"
              accept=".xls,.xlsx"
            />
          </div>
        </div>
        <b-button
          :disabled="!file"
          @click="readXslxForValue"
          size="sm"
          class="mx-1 mt-4 col-md"
          variant="outline-primary"
        >
          upload
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped></style>
