<script>
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { FormValidator, TextBox } from "@syncfusion/ej2-inputs";
import Vue from "vue";

export default {
  name: "setGetMappingUrl",
  components: { dataPickerModal },
  props: {
    value: {
      default: ""
    },
    validator: {},
    reRen: { default: false },
    isHttp: { default: false }
  },
  data() {
    return {
      modalTitle: "",
      type: "",
      to: "",
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        pickData: {per:'انتخاب داده',eng:"Pick Data"},
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        transition2: { eng: "slide2-fade-ltr", per: "slide2-fade-rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },

        contTitle: { eng: "title", per: "عنوان" },
        contUrl: { eng: "url", per: "لینک مورد نظر را وارد کنید" },
        contType: { eng: "type", per: "نوع دیتا مورد نظر را وارد کنید" },
        namePH: { eng: "Enter value", per: "مقدار موردنظر را وارد کنید" },
        contTitlePH: { eng: "Title", per: "عنوان را وارد کنید" },

        startBtn1: { eng: "search", per: "جستجو" },

        notice_7: { eng: "The analysis was completed successfully", per: "عملیات با موفقیت انجام شد " },

        danger: { eng: "Alarm", per: "هشدار " },
        warning: { eng: "Warning", per: "اخطار " },
        info: { eng: "Information", per: "اطلاعات" },
        success: { eng: "Sucess", per: "موفقیت در انجام" }
      },
      dynamicComponentNames: [],
      data: {
        url: "",
        interval: "none",
        uponChange: {
          enable: false,
          value: "",
          data: {}
        },
        items: []
      },
      cdb: {},
      intervalList: [
        { text: "Select One", value: null },
        { text: "none", value: "none" },
        { text: "30 sec", value: "30_s" },
        { text: "1 min", value: "1_m" },
        { text: "3 min", value: "3_m" },
        { text: "5 min", value: "5_m" },
        { text: "15 min", value: "15_m" },
        { text: "30 min", value: "30_m" },
        { text: "45 min", value: "45_m" },
        { text: "1 hour", value: "1_h" }
      ],
      submitted: false,
      successful: false,
      message: ""
    };
  },
  methods: {
    initModal() {
    },
    resetModal() {
    },
    handleOk() {
    },
    pickData() {
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];


      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "uponChange",
        filter: { dataType: ["!complex"], usedBy: [], parents: filterParents, archive: null, withChild: false }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");

    }


  },
  watch: {
    data: {
      handler(val) {
        this.$emit("input", val);
      }, deep: true
    },
    child2parent(newValue, oldValue) {
      if ((newValue.task === "pickData") && (newValue.to === "uponChange")) {
        this.data.uponChange.data = newValue.item;
      }
    },
    parent2child: {
      handler(newValue) {
        if (newValue.type == "Edit" && newValue.task == "editMapJson") {
          this.data=newValue.item
        }
      }, deep: true,

    }
  },
  computed: {
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  mounted() {
    if (this.parent2child.type == "Edit")
      this.data = this.value;
    else {
      {
        this.data = {
          url: "",
          interval: "none",
          uponChange: {
            enable: false,
            value: "",
            data: {
              type: "",
              _id: "",
              name: "",
              dataType: ""
            }
          },
          items: []
        };
      }
    }

  }
};
</script>

<template>
  <div>
    <div>
      <div class="row mb-5">
        <div class="col-12 col-md-4 pt-3">
          <ejs-textbox
            :autocomplete="'off'"
            :value="data.url"
            :title="dict.contUrl[lang]"
            :placeholder="dict.contUrl[lang]"
            :enableRtl="lang === 'per'"
            v-model="data.url"
            :required="true"
          ></ejs-textbox>
        </div>
        <div v-if="!isHttp"  class="col-12 col-md-4 pt-3">
          <ejs-dropdownlist
            :title="dict.contType[lang]"
            :dataSource="intervalList"
            :value="data.interval"
            v-model="data.interval"
            :fields="{ text: 'text', value: 'value' }"
            :placeholder="dict.contType[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
          ></ejs-dropdownlist>
        </div>
        <div v-if="!isHttp" class="col-12 col-md-4 mt-4 form-check form-switch mt-1">
          <input
            v-model="data.uponChange.enable"
            class="form-check-input mx-md-auto"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
          />
          <label class="form-check-label mx-2" for="flexSwitchCheckChecked"
          >upon change</label
          >
        </div>
      </div>

      <div class="my-2 row" v-if="data.uponChange.enable">
        <div class="col-md-6 col-12">
          <div class="d-flex align-items-center">
            <ejs-textbox
              :value="data.uponChange.data['name']"
              :placeholder="dict.pickData[lang]"
              :enableRtl="lang === 'per'"
              floatLabelType="Auto"
              :showClearButton="true"
            ></ejs-textbox>
            <b-button @click="pickData" class="btn-sm" variant="primary"><span
              class="e-icons e-edit-6 "></span></b-button>

          </div>
        </div>

        <div class="col-md-6 col-12 pt-3">
          <ejs-textbox
            :autocomplete="'off'"
            :value="data.uponChange.value"
            v-model="data.uponChange.value"
            :title="dict.namePH[lang]"
            :placeholder="dict.namePH[lang]"
            :enableRtl="lang === 'per'"
          ></ejs-textbox>
        </div>
      </div>
    </div>
    <dataPickerModal></dataPickerModal>
  </div>
</template>

<style scoped
       src="@/assets/styles/ledStyle.css">

</style>

