<template>
  <div
    :dir="dict.dir[lang]"
    class="container-fluid p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
    style="min-height: calc(100vh - 60px)"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        style="background: none !important"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/data_manager-2.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>
    </div>
    <div
      class="row g-0 p-2"
      :class="lang === 'per' ? 'text-end' : 'text-start'"
      style="min-height: calc(100vh - 152px)"
    >
      <div class="col-lg-12 h-100">
        <div
          class="card h-100"
          style="
            background-color: rgba(255, 255, 255, 0.75) !important;
            min-height: calc(100vh - 168px);
          "
        >
          <div class="p-3">
            <b-container fluid class="p-0">
              <ejs-grid
                :width="'auto'"
                :allowSorting="true"
                :locale="lang"
                :allowExcelExport="true"
                :loadingIndicator="{ indicatorType: 'Shimmer' }"
                :allowFiltering="true"
                :allowPdfExport="true"
                :toolbar="toolbarGenerator"
                :toolbarClick="categoryToolbarClick"
                :actionBegin="categoryActionBegin"
                :dataBound="categoryBound"
                :allowPaging="true"
                :enableRtl="lang == 'per'"
                :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
                :dataSource="items"
              >
                <e-columns>
                  <e-column width="50" type="checkbox"></e-column>
                  <e-column
                    v-for="(item, index) in fields"
                    :key="index"
                    :field="item.key"
                    :headerText="item[lang]"
                    :template="item.hasTemplate ? item.key : null"
                  ></e-column>
                </e-columns>
                <template v-slot:modify="{ data }">
                  <div class="d-flex justify-content-between col-md-3">
                    <span
                      class="e-icons e-edit-6"
                      @click="edit(data)"
                      style="font-size: 16px"
                    ></span>
                    <span
                      class="e-icons e-delete-1 text-danger"
                      @click="remove(data._id)"
                      style="font-size: 16px"
                    ></span>
                  </div>
                </template>
              </ejs-grid>
            </b-container>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-new-form"
      size="xl"
      :title="dict.New[lang]"
      centered
      hide-header-close
      :dir="dict.dir[lang]"
      @hidden="resetPage"
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
    >
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="gap: 10px">
            <b-button
              variant="secondary"
              @click="$bvModal.hide('modal-new-form')"
            >{{ dict.cancel[lang] }}
            </b-button>
            <b-button variant="primary" @click="createForm"
            >{{ dict.ok[lang] }}
            </b-button>
          </div>
        </div>
      </template>
      <div id="form_generation" class="p-3 row h-auto">
        <div>
          <ejs-dropdownbutton
            :enableRtl="lang == 'per'"
            :select="onSelect"
            :items="drpItems"
          >{{ dict.selectBox[lang] }}
          </ejs-dropdownbutton>
          <div class="row my-3">
            <div class="col-md-4 d-flex gap-4 my-3"
                 v-for="(item,index) in list"
                 :key="index"
            >
              <component
                :enableRtl="lang == 'per'"
                :dataSource="item.options"
                :field="{text:lang,value:'value'}"
                :placeholder="item.attributes.placeHolder"
                :label="item.attributes.placeHolder"
                :type="item.type!=null ?item.type :'' "
                :is="item.component"></component>
              <span v-if="item.component=='ejs-switch'" class="mx-2">{{ item.attributes.placeHolder }}</span>
              <div class="d-flex gap-3 mt-3">
                <span class="e-icons e-edit-6" @click="editField(item)"></span>
                <span class="e-icons e-delete-1 text-danger" @click="removeField(item,index)"></span>
              </div>
            </div>
          </div>
          <!--          <ejs-dashboardlayout id="defaultLayout" :columns="6" :cellSpacing="[10, 10]"-->
          <!--                               :allowResizing="true"-->
          <!--          >-->
          <!--            <e-panels>-->
          <!--            <div class="row my-3">-->
          <!--              <div class="col-md-4 d-flex gap-4 "-->
          <!--                   v-for="(item,index) in list"-->
          <!--                   :key="index"-->
          <!--              >-->
          <!--                <component-->
          <!--                  :enableRtl="lang == 'per'"-->
          <!--                  :type="item.type!=null ?item.type :'' "-->
          <!--                  :is="item.component"></component>-->
          <!--                <div class="d-flex gap-3 mt-3">-->
          <!--                  <span class="e-icons e-edit-3" @click="editField(item)"></span>-->
          <!--                  <span class="e-icons e-delete-3" @click="removeField(item,index)"></span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            </e-panels>-->
          <!--            &lt;!&ndash;            <e-panels>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="0" :col="0" :sizeX="1" :sizeY="1" header="hi">&ndash;&gt;-->
          <!--            &lt;!&ndash;              </e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="0" :col="1" :sizeX="3" :sizeY="2" content="<div class='panel-content'>1</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="0" :col="4" :sizeX="1" :sizeY="3" content="<div class='panel-content'>2</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="1" :col="0" :sizeX="1" :sizeY="1" content="<div class='panel-content'>3</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="2" :col="0" :sizeX="2" :sizeY="1" content="<div class='panel-content'>4</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="2" :col="2" :sizeX="1" :sizeY="1" content="<div class='panel-content'>5</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <e-panel :row="2" :col="3" :sizeX="1" :sizeY="1" content="<div class='panel-content'>6</div>"></e-panel>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </e-panels>&ndash;&gt;-->
          <!--          </ejs-dashboardlayout>-->
          <!--          <div class="row my-3">-->
          <!--            <div class="col-md-4 d-flex gap-4 "-->
          <!--                 -->
          <!--            >-->
          <!--              <component-->
          <!--                :enableRtl="lang == 'per'"-->
          <!--                :type="item.type!=null ?item.type :'' "-->
          <!--                :is="item.component"></component>-->
          <!--              <div class="d-flex gap-3 mt-3">-->
          <!--                <span class="e-icons e-edit-3" @click="editField(item)"></span>-->
          <!--                <span class="e-icons e-delete-3" @click="removeField(item,index)"></span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-edit-field"
      size="xl"
      :title="dict.EditField[lang]"
      centered
      hide-header-close
      @hidden="selectedComponent = {}"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header "
    >
      <div v-if="Object.keys(selectedComponent).length>0">
<!--        <div>-->
<!--          <component-->
<!--            ref="theComponent"-->
<!--            :type="selectedComponent.type!=null ?selectedComponent.type :'' "-->
<!--            :enableRtl="lang == 'per'"-->
<!--            :dataSource="selectedComponent.options"-->
<!--            :fields="{ text:lang, value: 'value' }"-->
<!--            :placeholder="selectedComponent.attributes.placeHolder"-->
<!--            :label="selectedComponent.attributes.placeHolder"-->
<!--            :is="selectedComponent.component"></component>-->
<!--          <span v-if="selectedComponent.component=='ejs-switch'"-->
<!--                class="mx-2">{{ selectedComponent.attributes.placeHolder }}</span>-->
<!--        </div>-->
        <div class="row my-3">
          <div class="col-md-3 col-12 my-2" v-for="(item,index) in selectedComponent.attributes" :key="index">
            <ejs-textbox
              floatLabelType="Auto"
              :enableRtl="lang=='per'"
              :placeholder="dict[index][lang]"
              v-model="selectedComponent.attributes[index]"
              :required="selectedComponent.validation.required"
              :min="selectedComponent.validation.min"
              :max="selectedComponent.validation.max"
              :minLength="selectedComponent.validation.minLength"
              :maxLength="selectedComponent.validation.maxLength"
            ></ejs-textbox>
          </div>
          <div class="col-md-3 col-12" v-for="(item,index) in selectedComponent.validation" :key="index">
            <ejs-dropdownlist
              v-if="index=='required'"
              floatLabelType="Auto"
              :enableRtl="lang=='per'"
              :dataSource="booleanType"
              v-model="selectedComponent.validation[index]"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict[index][lang]"
              @change="(val) => {selectedComponent.validation[index] = val.value}"
            ></ejs-dropdownlist>
            <ejs-numerictextbox
              v-else
              floatLabelType="Auto"
              :enableRtl="lang=='per'"
              :placeholder="dict[index][lang]"
              v-model="selectedComponent.validation[index]"
            ></ejs-numerictextbox>
          </div>
          <div v-if="selectedComponent.component=='ejs-dropdownlist'">
            <div class="d-flex justify-content-between pt-4">
              <p class="e-icons e-add" >{{dict.addOptions[lang]}}</p>
              <span @click="addOptionsField" class="e-icons e-plus"><span class="mx-2" style="font-size: 13px">{{dict.add[lang]}}</span></span>
            </div>

            <div
              class="row col-12" v-for="(item,index) in selectedComponent.options" :key="index"
            >
              <div class="col-md-3 col-12">
                <ejs-textbox
                  floatLabelType="Auto"
                  v-model="item['per']"
                  :enableRtl="lang=='per'"
                  :placeholder="dict.titles[lang]"
                ></ejs-textbox>
              </div>
              <div class="col-md-3 col-12">
                <ejs-textbox
                  floatLabelType="Auto"
                  v-model="item['eng']"
                  :enableRtl="lang=='per'"
                  :placeholder="dict.titles[lang]"
                ></ejs-textbox>
              </div>
              <div class="col-md-4 col-12">
                <ejs-textbox
                  floatLabelType="Auto"
                  v-model="item['value']"
                  :enableRtl="lang=='per'"
                  :placeholder="dict.value[lang]"
                ></ejs-textbox>
              </div>
              <span @click="removeOption(item,index)" class="e-icons col mt-4 e-delete-1 text-danger"></span>
            </div>
          </div>
        </div>

      </div>

    </b-modal>
  </div>
</template>
<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { DropDownButtonComponent } from "@syncfusion/ej2-vue-splitbuttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DashboardLayoutComponent, PanelDirective, PanelsDirective } from "@syncfusion/ej2-vue-layouts";
import WebMap from "@/components/WebMap.vue";

export default {
  name: "formGenerator",
  components: {
    WebMap,
    "ejs-dropdownbutton": DropDownButtonComponent,
    "ejs-numerictextbox": NumericTextBoxComponent,
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective
  },
  props: {},
  data() {
    return {
      position: { x: 0, y: 0 }, // Initial position
      dragging: false, // Drag state
      offset: { x: 0, y: 0 },
      dict: {
        add: { eng: "New option", per: "مقدار جدید" },
        addOptions: { eng: "Select box options", per: "لیست مقادیر" },
        selectBox: { eng: "New element", per: "المان جدید" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        title: { eng: "Forms manager", per: "مدیریت فرم ها" },
        addData: { eng: "New form", per: "ایجاد فرم " },
        New: { eng: "New form", per: "فرم جدید" },
        EditField: { eng: "Edit field", per: "ویرایش فیلد" },
        colorInput: { eng: "input color", per: "فرم جدید" },
        textInput: { eng: "input text", per: "فرم جدید" },
        numberInput: { eng: "input number", per: "فرم جدید" },
        selectInput: { eng: "Input select box", per: "فرم جدید" },
        multiselectInput: { eng: "Input multi select box", per: "فرم جدید" },
        required: { eng: "required", per: "مقدار آن خالی نباشد" },
        min: { eng: "min", per: "حداقل مقدار" },
        max: { eng: "max", per: "حداکثر مقدار" },
        minLength: { eng: "minLength", per: "حداقل تعداد کاراکتر" },
        maxLength: { eng: "maxLength", per: "حداکثر تعداد کاراکتر" },
        placeHolder: { eng: "placeHolder", per: "عنوان" },
        value: { eng: "value", per: "مقدار" },
        titles: { eng: "title", per: "عنوان" }
      },
      chosenComponent: null,
      type: "New",
      fields: [
        { key: "name", per: "نام", eng: "Name", hasTemplate: false },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      items: [],
      drpItems: [
        {
          text: "colorInput",
          value: "ejs-textbox"
        },
        {
          text: "textInput",
          value: "ejs-textbox"
        },
        {
          text: "numberInput",
          value: "ejs-textbox"
        },
        {
          text: "selectInput",
          value: "ejs-dropdownlist"
        },
        {
          text: "multiselectInput",
          value: "ejs-textbox"
        },
        {
          text: "textArea",
          value: "ejs-textarea"
        },
        {
          text: "switchInput",
          value: "ejs-switch"
        },
        {
          text: "radioButton",
          value: "ejs-radiobutton"
        },
        {
          text: "checkBox",
          value: "ejs-checkbox"
        },
        {
          text: "rangeSlider",
          value: "ejs-slider"
        }
      ],
      inputComponents: {
        colorInput: {
          name: "",
          component: "ejs-textbox",
          type: "color",
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        textArea: {
          name: "",
          component: "ejs-textarea",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        textInput: {
          name: "",
          component: "ejs-textbox",
          type: "text",
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        numberInput: {
          name: "",
          component: "ejs-numerictextbox",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        selectInput: {
          name: "",
          component: "ejs-dropdownlist",
          type: "select",
          fields: [],
          options: [],
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        multiselectInput: {
          name: "",
          component: "ejs-dropdownlist",
          type: "multiselect",
          fields: [],
          options: [],
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        switchInput: {
          name: "",
          component: "ejs-switch",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        radioButton: {
          name: "",
          component: "ejs-radiobutton",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        checkBox: {
          name: "",
          component: "ejs-checkbox",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        },
        rangeSlider: {
          name: "",
          component: "ejs-slider",
          type: null,
          fields: null,
          options: null,
          validation: {
            required: false,
            min: 0,
            max: 100,
            minLength: 1,
            maxLength: 100
          },
          attributes: {
            placeHolder: ""
          }
        }
      },
      selectedComponent: {},
      list: [],
      booleanType: [{ per: "صحیح", eng: true, value: true }, { per: "غلط", eng: false, value: false }]
    };
  },
  methods: {
    categoryToolbarClick(args) {
      if (args.item.id.includes("add_form")) {
        this.$bvModal.show("modal-new-form");
      }
    },
    edit() {
    },
    remove() {
    },
    categoryActionBegin() {
    },
    categoryBound() {
    },
    createForm() {
    },
    onSelect(args) {
      this.chosenComponent = args.item.text;
      let Item = Object.entries(this.inputComponents).find(item => item[0] == this.chosenComponent);
      this.list.push(Item[1]);

    },
    editField(item) {
      this.selectedComponent = {};
      this.selectedComponent = item;
      this.$bvModal.show("modal-edit-field");

    },
    removeField(item, index) {
      this.list.splice(index, 1);
    },
    resetPage() {
      this.items = [];
      this.selectedComponent = {};
      this.list = [];
    },
    addOptionsField() {
      this.selectedComponent.options.push({
        per:'',value:'',eng:''
      })
    },
    removeOption(item) {
      this.selectedComponent.options.splice(this.selectedComponent.options.indexOf(item),1)
    },
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolbarGenerator() {
      return [
        "Search",
        {
          text: this.dict.addData[this.lang],
          id: "add_form",
          prefixIcon: "e-icons e-add"
        }
      ];
      // if (!this.userPemission.includes("dataManager_add")) {
      //   return ["Search"];
      // } else {
      //
      // }
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";

</style>
