<template>
  <b-container fluid="md">
    <b-row class="my-5">
      <b-col sm>
        <b-card tag="article" style="max-width: 20rem" class="mb-2">
          <img
            src="../assets/images/Lighting.jpg"
            class="card-img card-img-top"
            height="180"
          />
          <b-card-text>
            <!-- Some quick example text to build on the card title and make up the bulk of the card's content. -->
          </b-card-text>

          <b-button
            variant="outline-primary"
            @click="selectPage('lighting', $event)"
            style="width: 100%"
          >
            Lighting Control System
          </b-button>
        </b-card>
      </b-col>

      <b-col sm>
        <b-card tag="article" style="max-width: 20rem" class="mb-2">
          <img
            src="../assets/images/HVAC.jpg"
            class="card-img card-img-top"
            height="180"
          />
          <b-card-text>
            <!-- Some quick example text to build on the card title and make up the bulk of the card's content. -->
          </b-card-text>

          <b-button
            disabled
            variant="outline-primary"
            @click="selectPage('lighting', $event)"
            style="width: 100%"
          >
            HVAC Control System
          </b-button>
        </b-card>
      </b-col>

      <b-col sm>
        <b-card tag="article" style="max-width: 20rem" class="mb-2">
          <img
            src="../assets/images/Access.jpg"
            class="card-img card-img-top"
            height="180"
          />
          <b-card-text>
            <!-- Some quick example text to build on the card title and make up the bulk of the card's content. -->
          </b-card-text>

          <b-button
            disabled
            variant="outline-primary"
            @click="handleGetAllUsers()"
            style="width: 100%"
          >
            Access Control System
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  // components: {
  //     Carousel3d,
  //     Slide
  // },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 262,
        class: "my-1",
      },
      message: "",
      successful: false,
    };
  },
  methods: {
    selectPage: function (page) {
      this.$store.dispatch("chpge/changePage", page);
    },

    handleGetAllUsers() {
      this.$store.dispatch("auth/getAllUsers").then(
        (data) => {
          this.message = data.message;
          this.successful = true;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
  },
};
</script>
