<template>
  <div>
    <b-modal
      id="modal-https-map-write-read-item"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card no-body> -->
          <b-list-group v-if="to.map === 'JSON'" horizontal="md">
            <b-form-group
              label="sub-route"
              label-for="subroute"
              :invalid-feedback="errors.first('subroute')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="subroute"
                type="text"
                placeholder="Enter sub-route"
                v-model="data.subroute"
                :state="submitted ? !errors.has('subroute') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Key"
              label-for="key"
              :invalid-feedback="errors.first('key')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="key"
                type="text"
                placeholder="Enter key"
                v-model="data.key"
                :state="submitted ? !errors.has('key') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
          </b-list-group>
          <!-- <b-list-group v-else-if="to.map==='MTR22'" horizontal="md">
                            <b-form-group
                                label="Channel"
                                label-for="Channel"                                            
                                :invalid-feedback="errors.first('Channel')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-input-group style="flex-wrap: inherit;">
                                    <b-form-input
                                        disabled
                                        name="Channel"
                                        type="text"
                                        placeholder="select a channel"
                                        v-model="data.channel.iden"
                                        :state="(submitted)? (!errors.has('Channel')): null"  
                                        v-validate="'required|min:1|max:200'"
                                        style="width:100%; font-family: monospace"
                                    ></b-form-input>
                                    <b-form-input
                                        disabled
                                        name="Channel.pos"
                                        type="text"
                                        v-model="data.channel.pos"
                                        style="width:100%; font-family: monospace"
                                    ></b-form-input>
                                    
                                    <b-input-group-append>
                                        <b-button  @click="selectFromMTR22">Select</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group> 
                        </b-list-group> -->
          <div class="d-flex align-items-center">
            <ejs-textbox
              :value="data.data.name"
              :placeholder="dict.pickData[lang]"
              :enableRtl="lang == 'per'"
              floatLabelType="Auto"
              :showClearButton="true"
              v-model="data.data.name"
            ></ejs-textbox>
            <b-button @click="pickData" class="btn-sm" variant="primary"><span
              class="e-icons e-edit-6 "></span>
            </b-button>
          </div>
<!--          <b-list-group horizontal="md">-->
          <!--            <b-form-group-->
          <!--              label="Data"-->
          <!--              label-for="data"-->
          <!--              :invalid-feedback="errors.first('data')"-->
          <!--              style="width: 100%; padding: 0px 10px 10px 0px"-->
          <!--            >-->
          <!--              <b-input-group style="flex-wrap: inherit">-->
          <!--                <b-form-input-->
          <!--                  disabled-->
          <!--                  name="data"-->
          <!--                  type="text"-->
          <!--                  placeholder="Select a data"-->
          <!--                  v-model="data.data.name"-->
          <!--                  :state="submitted ? !errors.has('data') : null"-->
          <!--                  v-validate="'required|min:1|max:200'"-->
          <!--                  style="width: 100%"-->
          <!--                ></b-form-input>-->

          <!--                <b-input-group-append>-->
          <!--                  <b-button @click="pickData">Select</b-button>-->
          <!--                </b-input-group-append>-->
          <!--              </b-input-group>-->
          <!--            </b-form-group>-->
          <!--          </b-list-group>-->

          <!-- <b-list-group v-if="to.type==='cloudPub' || to.type==='masterPub'" horizontal="md">
                            <b-form-group
                                label="transmit interval"
                                label-for="interval"
                                :invalid-feedback="errors.first('interval')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-select
                                    style=" font-variant:small-caps; width:100%; height:100%; padding: 0px;"
                                    
                                    name="interval"
                                    size="lg"
                                    v-model="data.interval"
                                    :options="intervalList"
                                    :state="(submitted)? (!errors.has('interval')): null"  
                                    v-validate="'required'"
                                    
                                ></b-form-select>
                            </b-form-group>

                            <b-form-group style="width:100%; padding: 27px 10px 10px 0px;">
                                <b-form-checkbox v-model="data.uponChange" name="check-uponChange" switch >
                                    upon change
                                </b-form-checkbox>
                            </b-form-group>
                        </b-list-group> -->

          <!-- </b-card> -->
        </b-form>
      </div>
    </b-modal>
    <dataPickerModal plotId="https" />
    <!-- <MTR22SelModal/> -->
  </div>
</template>

<script>
/* eslint-disable */
import dataPickerModal from "./widgetPickDataModal.vue";
// import MTR22SelModal  from './setContModalMTR22Sel.vue';
import { v4 as uuidv4 } from "uuid";
// import alertEditorModal  from './dataManagerModalDataAlertEditor.vue';

export default {
  name: "dataAdderModal",
  components: {
    dataPickerModal
    // MTR22SelModal
    // alertEditorModal
  },
  data() {
    return {
      dict: {
        pickData: { per: "انتخاب داده", eng: "Pick Data" }
      },
      modalTitle: "",
      type: "",
      to: "",

      data: {
        _id: "",
        subroute: "",
        key: "",
        // channel:{
        //     iden:'',
        //     pos:'',
        //     dir:''
        // },
        data: {
          type: "",
          _id: "",
          name: ""
        }
        // interval:null,
        // uponChange:false
      },

      // intervalList: [{ text: 'Select One', value: null },
      //             { text: 'none', value: 'none' },
      //             { text: '2 sec', value: '2_s' },
      //             { text: '5 sec', value: '5_s'},
      //             { text: '10 sec', value: '10_s'},
      //             { text: '15 sec', value: '15_s'},
      //             { text: '30 sec', value: '30_s'},
      //             { text: '1 min', value: '1_m'},
      //             { text: '3 min', value: '3_m'},
      //             { text: '5 min', value: '5_m'},
      //             { text: '15 min', value: '15_m'},
      //             { text: '30 min', value: '30_m'},
      //             { text: '45 min', value: '45_m'},
      //             { text: '1 hour', value: '1_h'},
      // ],
      submitted: false,
      successful: false,
      message: ""
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if ((newValue.task === "pickData") && (newValue.to === "pubsub")) {
        this.data.data = newValue.item;
      }
      // else if ((newValue.task==='selChannel')&&(newValue.to.type==='pubsub')){
      //     this.data.channel = newValue.id
      // }
    },
    parent2child(newValue, oldValue) {
      if (newValue.task === "editHttpsJson") {
        this.to = newValue.from;
        this.type = newValue.type;
        this.modalTitle = newValue.type + " Item";
        this.data._id = (newValue.item._id || newValue.item._id === 0) ? newValue.item._id : "";
        this.data.subroute = newValue.item.subroute ? newValue.item.subroute : "";
        // this.data.channel = newValue.item.channel?newValue.item.channel:{iden:'', pos:'', dir:''};
        this.data.key = newValue.item.key ? newValue.item.key : null;
        this.data.data = newValue.item.data ? newValue.item.data : { _id: "", name: "" };
        // this.data.interval = newValue.item.interval?newValue.item.interval:null;
        // this.data.uponChange = newValue.item.uponChange?newValue.item.uponChange:false;

        // console.log((this.data.parent.ptype.length === 0)||(this.data.parent.ptype === 'Internal'))
      }
    }
  },
  methods: {
    initModal: function() {
    },
    resetModal: function() {
      this.type = "";
      this.modalTitle = "";

      this.data._id = "";
      this.data.subroute = "";
      // this.data.channel = {
      //     iden:'',
      //     pos:'',
      //     dir:''
      // };

      this.data.key = "";
      this.data.data = { type: "", _id: "", name: "" };
      // this.data.interval =null;
      // this.data.uponChange = false;

      this.submitted = null;
      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      // console.log('submit',this.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.type === "New")
            this.data._id = uuidv4();

          this.$store.dispatch("chpge/child2parent", {
            task: "editHttpsJson",
            to: this.to,
            type: this.type,
            item: JSON.parse(JSON.stringify(this.data))
          });
          this.$bvModal.hide("modal-https-map-write-read-item");
        }
      });
    },
    pickData() {
      var filterParents = ["empty"];
      if (this.to.type === "masterPub" || this.to.type === "cloudPub")
        filterParents = ["empty", "Internal"];

      this.$store.dispatch("chpge/parent2child", {
        task: "pickData", from: "pubsub", filter: {
          dataType: ["!complex"], usedBy: [], parents: filterParents, archive: null, page: 1,
          perPage: 5000,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + "https");
    }
    // selectFromMTR22(){
    //     this.$store.dispatch('chpge/parent2child', {task: 'selChannel', from:{type:'pubsub', id:this.to.id}, items:{selected: this.data.channel, locked:this.to.extra}})
    //     this.$root.$emit('bv::show::modal', 'modal-MTR22-selector');
    // },
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>