<template>
  <div>
    <b-modal
      id="modal-new-workbench"
      size="sm"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle[lang]"
      @hidden="resetModal"
      :dir="dir[lang]"
      @ok="handleOk"
      :ok-title="ok[lang]"
      :cancel-title="cancel[lang]"
    >
      <div id="wb_section">
        <ejs-textbox
          :autocomplete="'off'"
          v-model="label"
          :placeholder="wbName[lang]"
          :required="true"
          :enableRtl="lang=='per'"
        ></ejs-textbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "newWorkbench",

  data() {
    return {
      modalTitle: { per: "میزکار جدید", eng: "new workbench" },
      wbName: { per: "نام میزکار", eng: "workbench name" },
      cancel: { eng: "Cancel", per: "لغو" },
      ok: { eng: "Ok", per: "قبول" },
      dir: { per: "rtl", eng: "ltr" },
      label: "",
      submitted: false,
      successful: false,
      message: "",
      validator: null
    };
  },
  methods: {
    resetModal: function() {
      this.label = "";
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validator = new FormValidator("#wb_section");
      const isValid = this.validator.validate();

      if (isValid) {
        var data = { label: this.label };
        this.$store.dispatch("data/createWB", data).then(() => {
          this.$nextTick(() => {
            this.$store.dispatch("panel/sseSubscription");
            this.$store.dispatch("data/getWBListdata");
            this.$bvModal.hide("modal-new-workbench");
          });
          this.$store.dispatch("data/getUserData");
        });
      }
    }

  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>