import DataService from "../services/data.service";
import { host } from "../models/host";

const initialState = {
  floor: null,
  zone: null,
  equipments: null,
  toggledSwitches: null,
  toggledGroups: null,
  sse: null,
  zoneData: null,
  mapData: null,
  updateMap: null,
  eventSource: null,
  socket: null,
  data: [],
  record: [],
  event: [],
  newUser: null
};

const API_URL = host + "/api/sse/";

export const panel = {
  namespaced: true,
  state: initialState,
  actions: {
    lightingPanel({ commit }, data) {
      return DataService.getPanelsofFloor(data).then(
        (response) => {
          //commit('clickonMapSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('clickonMapFailure');
          return Promise.reject(error);
        }
      );
    },
    clickonMap({ commit }, data) {
      return DataService.getEquipmentsbyLoc(data).then(
        (response) => {
          commit("clickonMapSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("clickonMapFailure");
          return Promise.reject(error);
        }
      );
    },
    clickonMapSett({ commit }, zone) {
      return DataService.getZone(zone._id).then(
        (response) => {
          response.data._id = zone._id;
          // console.log(zone.latlng)
          response.data["latlng"] = zone.latlng;
          commit("clickonMapSettSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("clickonMapSettFailure");
          return Promise.reject(error);
        }
      );
    },
    mapSetting({ commit }, map) {
      return DataService.getfloorMap(map).then(
        (response) => {
          commit("mapSettingSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("mapSettingFailure");
          return Promise.reject(error);
        }
      );
    },
    toggleSwitch({ commit }, data) {
      return DataService.toggleSwitch(data).then(
        (response) => {
          //commit('toggleSwitchSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('toggleSwitchFailure');
          return Promise.reject(error);
        }
      );
    },
    toggleGroup({ commit }, data) {
      return DataService.toggleGroup(data).then(
        (response) => {
          //commit('toggleSwitchSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('toggleSwitchFailure');
          return Promise.reject(error);
        }
      );
    },

    getData({ commit }, id) {
      return DataService.getData(id).then(
        (response) => {
          commit("getDataSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("getDataFailure");
          return Promise.reject(error);
        }
      );
    },
    setData({ commit }, data) {
      return DataService.setData(data).then(
        (response) => {
          //commit('toggleSwitchSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          //commit('toggleSwitchFailure');
          return Promise.reject(error);
        }
      );
    },
    closeSse({ commit }) {
      commit("setEventSource", true);
    },
    sseSubscription({ commit }, data) {
      commit("SSEStatus", null);
      // const token = "yourAuthToken"; // Replace with the actual token
      // const eventSource = new EventSource(`/sse-endpoint?token=${token}`);
      var url;
      var user = JSON.parse(localStorage.getItem("user"));
      if (user && user.accessToken)
        url = `${API_URL}?x-access-token=${user.accessToken}`;
      else url = API_URL;
      commit("setEventSource", new EventSource(url));
      this.state.panel.eventSource.addEventListener("message", (event) => {
        const eventData = JSON.parse(event.data);
        if (eventData.type === "data") commit("getDataSuccess", eventData.data);
        if (eventData.type === "record")
          commit("getRecordSuccess", eventData.data);
        if (eventData.type === "event") {
          commit("getEventSuccess", eventData.data);
          // Send a message to the service worker to show a notification
          navigator.serviceWorker.controller.postMessage({
            type: "sseMessage",
            title: eventData.data,
            message: "Notified by Traversy Media!"
          });
        }
        if (eventData.type === "feeder")
          commit("toggleSwitchSuccess", eventData.data);
        else if (eventData.type === "group")
          commit("toggleGroupSuccess", eventData.data);
      });
      this.state.panel.eventSource.onopen = (e) => {
        commit("SSEStatus", true);
      };

      this.state.panel.eventSource.onerror = function(err) {
        this.state.panel.eventSource.close();
        commit("SSEStatus", false);
      };

      //   sseClient = this.$sse.create({
      //     url: this.$host + ':1882/countdown',
      //     format: 'json',
      //     withCredentials: false,
      //     polyfill: true,
      //   });

      //   sseClient.on('error', (e) => {
      //     console.error('lost connection or failed to parse!', e);
      //   });

      //   sseClient.on('message', this.handleMessage);

      //   sseClient.connect()
      //     .then(sse => {
      //       console.log('We\'re connected!');
      //     })
      //     .catch((err) => {
      //       console.error('Failed to connect to server', err);
      //     });
    },
    sseSubscriptionّFava({ commit }, data) {

      commit("setSocket", new EventSource(`${host}/api/callbacksocket?id=${data}`));
      this.state.panel.socket.addEventListener("message", (event) => {
        commit("setNewUser", JSON.parse(event.data));
        this.state.panel.socket.onopen = (e) => {
          commit("setSocket", true);
        };

        this.state.panel.socket.onerror = function(err) {
          this.state.panel.socket.close();
          commit("setSocket", false);
        };

      });
    },
    updateMap({ commit }, mapId) {
      commit("updateMap", mapId);
    }
  },
  mutations: {
    mapSettingSuccess(state, data) {
      state.mapData = {
        _id: data._id,
        title: data.title,
        options: data.floorOptions
      };
    },
    mapSettingFailure(state) {
      state.mapData = null;
    },
    clickonMapSettSuccess(state, data) {
      state.zoneData = {
        _id: data._id,
        title: data.title,
        options: data.options,
        latlng: data.latlng
      };
    },
    clickonMapSettFailure(state) {
      state.zoneData = null;
    },
    clickonMapSuccess(state, data) {
      state.floor = data.floor;
      state.zone = data.zone;
      state.equipments = data.equipments;
    },
    clickonMapFailure(state) {
      state.floor = null;
      state.zone = null;
      state.items = null;
    },
    toggleSwitchSuccess(state, data) {
      state.toggledSwitches = data;
    },
    toggleSwitchFailure(state) {
      state.toggledSwitches = null;
    },
    toggleGroupSuccess(state, data) {
      state.toggledGroups = data;
    },
    SSEStatus(state, value) {
      state.sse = value;
    },
    updateMap(state, mapId) {
      state.updateMap = mapId;
    },
    getDataSuccess(state, data) {
      state.data = data;
    },
    getRecordSuccess(state, data) {
      state.record = data;
    },
    getEventSuccess(state, data) {
      state.event = data;
    },
    getDataFailure(state) {
      state.data = [];
    },
    setEventSource(state, data) {
      if (data == true)
        state.eventSource = state.eventSource.close();
      else
        state.eventSource = data;
    },
    setSocket(state, data) {
      if (data == true)
        state.socket = state.socket.close();
      else
        state.socket = data;
    },
    setNewUser(state, data) {
      state.newUser = data;
    }
  }
};
