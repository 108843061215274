<template>
  <div :dir="dict.dir[lang]">
    <b-row align-h="between" class="g-0 p-0 h-100">
      <b-container fluid class="p-0 h-100">
        <ejs-grid
          ref="grid_users"
          :width="'auto'"
          :height="'100%'"
          :key="usersData.length"
          :locale="lang"
          :allowSorting="true"
          :allowExcelExport="true"
          :allowFiltering="true"
          :editSettings="statTableOptions.editSettings"
          :loadingIndicator="{ indicatorType: 'Shimmer' }"
          :allowPdfExport="true"
          :toolbar="toolbarGenerator"
          :toolbarClick="toolbarClick"
          :allowPaging="true"
          :enableAdaptiveUI="true"
          :allowTextWrap="false"
          :allowResizing="false"
          :enableRtl="lang == 'per'"
          :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
          :selectionSettings="statTableOptions.selectionOptions"
          :dataSource="usersData"
          @dataBound="dataBounded"
          @actionBegin="actionBegin"
          @actionComplete="actionComplete"
        >
          <e-columns>
            <e-column width="50" type="checkbox"></e-column>
            <e-column
              v-for="(item, index) in usersFields"
              :key="index"
              :field="item.key"
              :width="150"
              :headerText="item[lang]"
              :template="item.hasTemplate ? item.key : null"
            ></e-column>
          </e-columns>
          <template v-slot:modify="{ data }">
            <div class="d-flex justify-content-between">
              <span
                class="e-icons e-eye mx-auto"
                v-permission="'admin_workbench_users'"
                style="font-size: 17px"
                @click="openWbModal(data.workBenches)"
              ></span>
              <span
                class="e-icons e-edit-6 mx-auto"
                style="font-size: 17px"
                @click="openEditModal(data)"
              ></span>
              <span
                v-if="
                  sysRoles.find((item) => item.value == data.systemRole).text !=
                  'administrator'
                "
                class="e-icons e-delete-1 mx-auto text-danger"
                style="font-size: 17px"
                @click="removeUser(data._id)"
              ></span>
            </div>
          </template>
          <template v-slot:name="{ data }">
            {{
              `${data.name ? data.name : ""} ${
                data.familyName ? data.familyName : ""
              }`
            }}
          </template>
          <template v-slot:avatar="{ data }">
            <div>
              <b-avatar
                variant="primary"
                :src="avatarUrl[data._id]"
                :text="`${data.name ? data.name[0] : ''} ${
                  data.familyName ? data.familyName[0] : ''
                }`"
                style="background-color: #0d6efd; color: white"
              ></b-avatar>
            </div>
            <!-- <b-avatar variant="dark" class="mr-3" :text="`${row.item.fname? row.item.fname[0]:''} ${row.item.lname? row.item.lname[0]:''}`" style="background-color: #99f6e4"></b-avatar> -->
          </template>
          <template v-slot:systemRole="{ data }">
            <ejs-dropdownlist
              v-if="
                sysRoles.find((item) => item.value == data.systemRole).text ==
                'administrator'
              "
              id="dropdownlist_roles_admin"
              :enableRtl="lang=='per'"
              :dataSource="sysRoles"
              v-model="data.systemRole"
              :enabled="
                sysRoles.find((item) => item.value == data.systemRole).text ==
                'administrator'
                  ? false
                  : true
              "
              :fields="{ text: lang, value: 'value' }"
            ></ejs-dropdownlist>
            <ejs-dropdownlist
              v-else
              id="dropdownlist_roles_admin"
              :dataSource="sysRoles"
              :enableRtl="lang=='per'"
              v-model="data.systemRole"
              @change="changeUserPermission($event, data.id)"
              :enabled="canEdit"
              :fields="{ text: lang, value: 'value' }"
            ></ejs-dropdownlist>
          </template>
        </ejs-grid>
      </b-container>
    </b-row>
    <b-modal
      id="wbModal"
      size="xl"
      centered
      style="width: 150% !important"
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      content-class="shadow"
      :dir="dict.dir[lang]"
      ok-only
      :ok-title="dict.cancel[lang]"
    >
      <template #modal-header>
        <h5>{{ modalTitle }}</h5>
        <i>
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="$bvModal.hide('wbModal')"
          />
        </i>
      </template>
      <div>
        <workbench-administrator :workbench-items="wbItem" />
      </div>
    </b-modal>
    <b-modal
      id="registerModal"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      @shown="initRegisteration"
      content-class="shadow"
      @close="openRegister = false"
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <h5>{{ dict[mode][lang] }}</h5>
        <i>
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="$bvModal.hide('registerModal')"
          />
        </i>
      </template>
      <div id="register_user_section">
        <div class="row">
          <div
            class="col-md-6 col-12 my-2"
            v-for="(item,index) in fields"
            :key="index"
          >
            <ejs-textbox
              :lacale="lang"
              :autocomplete="'off'"
              :enableRtl="lang == 'per'"
              :required="item.required"
              :enabled="!(mode == 'edit' && item.readonly)"
              floatLabelType="Auto"
              :type="item.type"
              :minLength="item.name=='phone'? 11:3 "
              :placeholder="item[lang]"
              v-model="userInfo[item.eng]"
            ></ejs-textbox>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button
              variant="secondary"
              @click="$bvModal.hide('registerModal')"
            >{{ dict.cancel[lang] }}
            </b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="registerUsers">
              <b-spinner v-if="loading" small label="Loading..."></b-spinner>

              <span v-else>{{ dict.ok[lang] }}</span></b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="admin_new"
      size="sm"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      @shown="initRegisteration"
      content-class="shadow"
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <h5>{{ dict.transferAdmin[lang] }}</h5>
        <i>
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="$bvModal.hide('admin_new')"
          />
        </i>
      </template>
      <div id="register_user_section">
        <div class="row px-5">
          <ejs-dropdownlist
            floatLabelType="Auto"
            :locale="lang"
            v-model="selectedUser"
            :enableRtl="lang == 'per'"
            :dataSource="users"
            :placeholder="dict.usersList[lang]"
            :fields="{ text: 'text', value: 'value' }"
          ></ejs-dropdownlist>
        </div>
      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button variant="secondary" @click="$bvModal.hide('admin_new')"
            >{{ dict.cancel[lang] }}
            </b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="transferAdmin">
              <b-spinner v-if="loading" small label="Loading..."></b-spinner>

              <span v-else>{{ dict.ok[lang] }}</span></b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import store from "@/store";
import WorkbenchAdministrator from "@/components/workbenchAdministrator.vue";
import EditUserData from "@/components/editUserData.vue";
import RegisterForms from "@/components/registerForms.vue";
import { setCulture } from "@syncfusion/ej2-base";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { host } from "@/models/host";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import { mapGetters } from "vuex";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { L10n } from "@syncfusion/ej2-base";

export default {
  name: "usersAdministrator",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    WorkbenchAdministrator,
    "ejs-dropdownlist": DropDownListComponent
  },
  props: {
    userData: {
      default: {}
    },
    canEdit: {
      default: false
    }
  },
  data() {
    return {
      avatarUrl: {},
      toggleName: { per: "استفاده از ثبت نام", eng: "use signUp to register" },
      openEdit: false,
      loading: false,
      fromMounted: false,
      openRegister: false,
      registerType: false,
      userInfo: {},
      wbItem: {},
      statTableOptions: {
        selectionOptions: {
          type: "Single",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Dialog"
        }
      },
      usersFields: [
        { key: "avatar", per: "آواتار", eng: "avatar", hasTemplate: true },
        { key: "name", per: "نام", eng: "name", hasTemplate: true },
        {
          key: "username",
          per: "نام کاربری",
          eng: "username",
          hasTemplate: false
        },
        { key: "email", per: "ایمیل", eng: "email", hasTemplate: false },
        {
          key: "phoneNumber",
          per: "شماره موبایل",
          eng: "phoneNumber",
          hasTemplate: false
        },
        {
          key: "systemRole",
          per: "نقش سیستمی",
          eng: "systemRole",
          hasTemplate: true
        },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      permissionList: [],
      sysRoles: [],
      users: [],
      selectedUser: "",
      dict: {
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the user ?",
          per: "آیا از حذف این کاربر از این میزکار اطمینان دارید ؟"
        },
        transferConfirm: {
          eng: "Confirmation Role transformation",
          per: "تاییدیه انتقال نقش"
        },
        transferConfirmContent: {
          eng: "Are you sure to transfer your role ?",
          per: "آیا از انتقال نقش خود اطمینان دارید ؟"
        },
        dir: { eng: "ltr", per: "rtl" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        newUser: { eng: "New user", per: "کاربر جدید" },
        transferAdmin: {
          eng: "Transfer administrator",
          per: "انتقال ادمین کل"
        },
        editUser: { eng: "Edit user", per: "ویرایش کاربر" },
        clear: { eng: "clear", per: "حذف" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        modalTitle: { eng: "workbenches list", per: "لیست میزکارها" },
        usersList: { eng: "users list", per: "لیست کاربران" },
        add: {
          eng: "add new user",
          per: "افزودن کاربر جدید"
        },
        edit: { eng: "edit user data", per: "ویرایش اطلاعات کاربر" },
        confirmText: {
          eng: "user registration confirm",
          per: "تاییدیه ثبت نام کاربر"
        },
        confirmTextTitle: {
          eng: "Are you sure to change the users registration status?",
          per: "آیا از تغییر وضعیت ثبت نام کاربران اطمینان دارید؟"
        },
        administrator: { per: "ادمین کل سیستم", eng: "administrator" },
        poweruser: { per: "کاربر قدرتمند", eng: "poweruser" },
        user: { per: "کاربر عادی", eng: "user" }
      },
      fields: [
        {
          readonly: false,
          name: "fname",
          param: "name",
          value: "",
          required: true,
          per: "نام",
          eng: "name",
          type: "text"
        },
        {
          name: "lname",
          param: "familyName",
          readonly: false,
          value: "",
          type: "text",
          required: true,
          per: "نام خانوادگی",
          eng: "familyName"
        },
        {
          name: "password",
          param: "",
          readonly: false,
          value: "",
          type: "password",
          required: false,
          per: "رمز عبور",
          eng: "password"
        },
        {
          name: "passwordRetype",
          param: "",
          readonly: false,
          value: "",
          type: "password",
          required: false,
          per: "تایید رمز عبور",
          eng: "password repeat"
        },
        {
          name: "phone",
          param: "phoneNumber",
          readonly: false,
          value: "",
          type: "number",
          required: true,
          per: "شماره موبایل",
          eng: "phoneNumber"
        },
        {
          name: "email",
          param: "",
          readonly: true,
          value: "",
          type: "email",
          required: true,
          per: "ایمیل",
          eng: "email"
        },
        {
          name: "username",
          param: "",
          readonly: true,
          value: "",
          type: "text",
          required: true,
          per: "نام کاربری",
          eng: "username"
        }
      ],
      mode: "",
      validator: null
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    workbench() {
      return this.$store.state.data.workbench.activeWorkbench;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    modalTitle() {
      return this.dict.modalTitle[this.lang];
    },
    usersData() {
      return store.state.data.adminUsersLists;
    },
    user() {
      return this.$store.state.data.user;
    },
    userPermissions() {
      return this.$store.state.data.user.permissions;
    },
    toolbarGenerator() {
      if (this.userPermissions.includes("admin_user_data"))
        return [
          "Search",
          {
            text: this.dict.newUser[this.lang],
            id: "new_user",
            prefixIcon: "e-icons e-add"
          },
          {
            text: this.dict.transferAdmin[this.lang],
            id: "transfer",
            prefixIcon: "e-icons e-repeat"
          }
        ];
      else return ["Search"];
    }
  },
  methods: {
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.grid.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.grid.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.grid.csvExport();
      } else if (args.item.id.includes("new_user")) {
        this.mode = "add";
        this.$bvModal.show("registerModal");
      } else if (args.item.id.includes("transfer")) {
        this.$bvModal.show("admin_new");
      }
    },
    pdfExportComplete() {
      this.$refs.grid.hideSpinner();
    },
    setRegister(val) {
      this.$store
        .dispatch("data/setAdminRegister", {
          data: { signUpPreview: Boolean(val) }
        })
        .then((res) => {
        });
    },
    registerUsers(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.submitUser();
    },
    initRegisteration() {
      setTimeout(() => {
        this.validator = new FormValidator("#register_user_section");
      }, 200);

      if (this.mode != "edit") {
        this.fields.forEach((item) => {
          this.userInfo[item.eng] = item.value;
        });
      }
    },
    inviteUser() {
      this.$root.$emit("bv::show::modal", "modal-invite-user");
    },
    changeUserPermission(event, userId) {
      this.$store
        .dispatch("data/editRoleSystem", {
          systemRole: event.itemData.value,
          userId: userId
        })
        .then(
          (response) => {
            this.getAdminUsers();
            this.message = response.message;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },

    removeUser(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        position: "center center",
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang],
          click: () => {
            this.$store
              .dispatch("data/removeSystemUserAdmin", { id: id })
              .then(() => {
                this.getAdminUsers();
              });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    transferAdmin() {
      this.loading = true;
      this.$store
        .dispatch("data/transferAdministrator", {
          newUserId: this.selectedUser
        })
        .then(() => {
          this.$store.dispatch("auth/logout");
          this.$store.dispatch("data/removeUserData");
          this.$store.dispatch("chpge/changePage", "signin");
          this.$store.dispatch("panel/sseSubscription");
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getSystemRoles() {
      this.$store.dispatch("data/getSysRolesList").then((res) => {
        this.roles = res.message;
        this.roles.forEach((x) => {
          if (x.name != "poweruser")
            this.sysRoles.push({
              text: x.name,
              value: x._id,
              eng: this.dict[x.name].eng,
              per: this.dict[x.name].per
            });
        });
      });
    },
    getSignupStatus() {
      this.$store.dispatch("data/getSignupSetting").then((res) => {
        this.registerType = res.message.signUpPreview;
      });
    },
    getAdminUsers() {
      this.users = [];
      this.$store.dispatch("data/getUsersAdminListdata").then((res) => {
        this.statTableOptions.totalRows = res.message.length;
      });
      this.userData = store.state.data.adminUsersLists;
      this.userData.forEach((item) => {
        this.users.push({
          text: item.name + " " + item.familyName,
          value: item.id
        });
        this.getAvatar(item.id);
      });
    },
    openWbModal(data) {
      this.wbItem = data;
      this.$bvModal.show("wbModal");
    },
    openEditModal(data) {
      this.mode = "edit";
      this.userInfo = data;
      this.$bvModal.show("registerModal");
    },
    host() {
      return host;
    },
    actionBegin(args) {
      if (args.requestType === "refresh")
        args.cancel = true;
      if (args.requestType == "cancel") {
        this.fields = [
          {
            readonly: false,
            name: "fname",
            param: "name",
            value: "",
            required: true,
            per: "نام",
            eng: "name",
            type: "text"
          },
          {
            name: "lname",
            param: "familyName",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "نام خانوادگی",
            eng: "familyName"
          },
          {
            name: "password",
            param: "",
            readonly: false,
            value: "",
            type: "password",
            required: true,
            per: "رمز عبور",
            eng: "password"
          },
          {
            name: "passwordRetype",
            param: "",
            readonly: false,
            value: "",
            type: "password",
            required: true,
            per: "تایید رمز عبور",
            eng: "password repeat"
          },
          {
            name: "phone",
            param: "phoneNumber",
            readonly: false,
            value: "",
            type: "number",
            required: true,
            per: "شماره موبایل",
            eng: "phoneNumber"
          },
          {
            name: "email",
            param: "",
            readonly: true,
            value: "",
            type: "email",
            required: true,
            per: "ایمیل",
            eng: "email"
          },
          {
            name: "username",
            param: "",
            readonly: true,
            value: "",
            type: "text",
            required: true,
            per: "نام کاربری",
            eng: "username"
          }
        ];
      }
      if (args.requestType == "beginEdit") {
        this.mode = "beginEdit";
        let selectedRow = args.rowData;
        this.fields.forEach((item1) => {
          Object.keys(selectedRow).forEach((item2) => {
            if (item1.eng == item2) {
              item1.value = selectedRow[item2];
            }
          });
        });
      } else if (args.requestType == "add") {
        this.mode = "add";
        args.cancel = true;
      } else if (this.mode != "add" && this.mode != "edit") {
        if (args.requestType === "paging") {
          this.$refs.grid_users.showSpinner();
        }
      }
    },
    dataBounded(e) {
      this.$refs.grid_users.hideSpinner();
    },
    actionComplete(args) {
      if (args.requestType == "cancel") {
        this.fields = [
          {
            readonly: false,
            name: "fname",
            param: "name",
            value: "",
            required: true,
            per: "نام",
            eng: "name",
            type: "text"
          },
          {
            name: "lname",
            param: "familyName",
            readonly: false,
            value: "",
            type: "text",
            required: true,
            per: "نام خانوادگی",
            eng: "familyName"
          },
          {
            name: "password",
            param: "",
            readonly: false,
            value: "",
            type: "password",
            required: true,
            per: "رمز عبور",
            eng: "password"
          },
          {
            name: "passwordRetype",
            param: "",
            readonly: false,
            value: "",
            type: "password",
            required: true,
            per: "تایید رمز عبور",
            eng: "password repeat"
          },
          {
            name: "phone",
            param: "phoneNumber",
            readonly: false,
            value: "",
            type: "number",
            required: true,
            per: "شماره موبایل",
            eng: "phoneNumber"
          },
          {
            name: "email",
            param: "",
            readonly: true,
            value: "",
            type: "email",
            required: true,
            per: "ایمیل",
            eng: "email"
          },
          {
            name: "username",
            param: "",
            readonly: true,
            value: "",
            type: "text",
            required: true,
            per: "نام کاربری",
            eng: "username"
          }
        ];
      }
    },
    submitUser() {
      this.loading = true;
      const isValid = this.validator.validate();
      if (isValid) {
        if (this.mode == "add") {
          let data = {};
          this.fields.forEach((item) => {
            data[item.name] = this.userInfo[item.eng];
          });
          this.$store
            .dispatch("auth/register", data)
            .then(() => {
              this.loading = false;
              this.getAdminUsers();
              this.$bvModal.hide("registerModal");
            })
            .catch(() => {
              this.loading = false;
            });
        } else if (this.mode == "edit") {
          let data = {
            userId: this.userInfo.id
          };
          this.fields.forEach((item) => {
            data[item.name] = this.userInfo[item.eng] || undefined;
          });
          this.$store
            .dispatch("data/editRoleSystem", data)
            .then(() => {
              this.loading = false;
              this.$store.dispatch("data/getUsersAdminListdata");
              this.$bvModal.hide("registerModal");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } else this.loading = false;
    },
    getAvatar(id) {
      this.$store
        .dispatch("data/getUsersAvatar", { id: id })
        .then((response) => {
          if (response != null) {
            this.$set(this.avatarUrl, id, response);
          }
        });
    }
  },
  watch: {
    registerType(val) {
      this.setRegister(val);
    }
  },
  mounted() {
    this.getSystemRoles();
    this.userData = store.state.data.adminUsersLists;
    this.getAdminUsers();
    // this.getSignupStatus();

  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>
