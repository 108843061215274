<template>
  <div class="tree-menu">
    <div   class="label-wrapper" @click="toggleChildren">
      <div v-if="depth!=0" :class="labelClasses" class="label" :style="indent" @click="addComponent">
        {{ label }}
        <i v-if="nodes"><font-awesome-icon :icon="iconClasses" /></i>  
      </div>
    </div>
    <div v-if="showChildren || depth==0">
      <tree-menu 
      
        v-for="(node,i) in nodes" 
          :nodes="node.nodes" 
          :label="node.label"
          :id="node.id"
          :depth="depth + 1"
          :colors="colors"
          :key="i"   
        >
      </tree-menu>
    </div>
    
  </div>
</template>

<script>
/* eslint-disable */

  export default {
    name: 'tree-menu',
    props: {
      nodes: Array,
      label: String,
      id: String,
      depth: Number,
      colors: Array
    },
    data() {
      return {
        showChildren: false
      }
    },
    computed: {
      iconClasses() {
        if (!this.showChildren)
          return 'fas fa-chevron-down'
        else
          return 'fas fa-chevron-up'
      },
      labelClasses() {
        return { 'has-children': this.nodes, 'component': !this.nodes }
      },
      indent() {
        return this.colors[this.depth]
        // return this.colors[this.depth]
        //return { transform: `translate(${this.depth * 50}px)` }
      }
    },
    methods: {
      toggleChildren() {
        this.showChildren = !this.showChildren;
      },
      addComponent(){
        if (!this.nodes){
          this.$store.dispatch('chpge/child2parent', {task: 'addCompenet', type:'add', data: this.id})
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .tree-menu {
    // padding: 5px;
    .label-wrapper {
      // padding-bottom: 10px;
      // margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      .has-children {
        cursor: pointer;
      }
      .component:hover {
        cursor: pointer;
        font-weight: bold;
        color: rgb(57, 94, 162) !important;
        background-color: rgb(255, 255, 255) !important;
      }
      .label{
        display: flex; 
        justify-content: space-between; 
        padding:5px; 

        // width:100%
      }
    }
  }
  
</style>


