<template>
  <div :dir="dict.dir[lang]">
    <b-modal
      id="modal-invite-user_table"
      size="md"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      hide-footer
      content-class="shadow"
      :title="dict.title[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
      :dir="dict.dir[lang]"
    >
      <div>


        <b-table :items="invitedWBs" :fields="invitedFields" responsive="sm" small striped hover
                 thead-class="hidden_header">
          <template #cell(modify)="row">
            <div style="display:flex; justify-content:flex-end;">
              <b-button pill size="sm" variant="outline-success" @click="changeStatus(row.item.id,'accept')"
                        class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                قبول
              </b-button>
              <b-button pill size="sm" variant="outline-danger" @click="changeStatus(row.item.id,'decline')"
                        class="mr-2"
                        style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                انصراف
              </b-button>
            </div>
          </template>
        </b-table>

        <div v-if="message==='success'" class="alert alert-success" :class="lang==='per'?'text-end ':'text-start'">
          <p class="mb-0">
            {{ dict.success[lang] }}
          </p>
        </div>
        <div v-else-if="message==='err1'" class="alert alert-danger" :class="lang==='per'?'text-end ':'text-start'">
          <p class="mb-0">
            {{ dict.error1[lang] }}
          </p>
        </div>
        <div v-else-if="message==='err2'" class="alert alert-danger" :class="lang==='per'?'text-end ':'text-start'">
          <p class="mb-0">
            {{ dict.error2[lang] }}
          </p>
        </div>
        <div v-else-if="message==='err3'" class="alert alert-danger" :class="lang==='per'?'text-end ':'text-start'">
          <p class="mb-0">
            {{ dict.error3[lang] }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "inviteUser",

  data() {
    return {
      // modalTitle:'',

      user: "",

      submitted: false,
      successful: false,
      message: "",

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "recieved invitations", per: "دعوتنامه های دریافتی" },
        label: { eng: "user's email", per: "ایمیل کاربر" },
        placeholder: { eng: "enter email of inviting user", per: "ایمیل کاربر مد نظر را وارد کنید" },
        username: { eng: "user", per: "نام کاربری" },
        success: {
          eng: "your invitation was successfuly sent to this user",
          per: "دعوتنامه شما با موفقیت برای کاربر ارسال شد"
        },
        error1: { eng: "this user is not registred", per: "چنین کاربری در سیستم ثبت نشده است" },
        error2: { eng: "this user is a member of your workbench", per: "کاربر مورد نظر در میزکار شما عضو است" },
        error3: {
          eng: "an invitation has already been sent for this user",
          per: "پیشتر دعوتنامه ای برای این کاربر ارسال شده است"
        }
      },

      invitedFields: [
        { key: "label", label: "" },

        { key: "modify", label: "" }
      ]
    };
  },
  methods: {
    initModal: function() {
      // this.modalTitle = 'invite new user';
    },
    resetModal: function() {
      this.user = "";
      this.message = "";
      this.submitted = false;
      this.successful = false;

      // this.modalTitle = '';


    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      // console.log('submit',this.type)
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          var data = { user: this.user };
          this.$store.dispatch("data/inviteWBusers", data).then(
            (response) => {
              this.message = response.message;

              this.$nextTick(() => {
                this.$bvModal.hide("modal-invite-user");
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();

              if ((this.message === "err1") || (this.message === "err2") || (this.message === "err3")) {
                this.submitted = false;
                this.successful = false;
                this.user = "";
              }
            }
          );

        }
      });
    },
    sendResp(data) {
      this.$store.dispatch("data/respInviteWBusers", data).then(
        (response) => {
          // this.message = response.message

          // this.$nextTick(() => {
          //     this.$bvModal.hide('modal-invite-user')
          // })
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();

          // if ((this.message === 'err1')||(this.message === 'err2')||(this.message === 'err3')){
          //     this.submitted = false
          //     this.successful = false
          //     this.user = ''
          // }

        }
      );


    },
    changeStatus(id, status) {
      this.sendResp({ id: id, resp: status });
      this.$bvModal.hide("modal-invite-user");
    }

  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    invitedWBs() {
      if (this.parent2child && (this.parent2child.task === "invitedWBs")) {
        return this.parent2child.items;
      }

    }
  }
};
</script>

<style scoped>
.arrow-up {
  color: #198754;
  cursor: pointer;
}

.arrow-up :hover {
  color: #9b9b9b;
}

.arrow-down {
  color: #dc3545;
  cursor: pointer;
}

.arrow-down :hover {
  color: #9b9b9b;
}
</style>

<style>
.hidden_header {
  display: none;
}
</style>