<template>
    <div style="text-align:left">
        <div>
            <b-container fluid="md">
                <b-row class=my-5>
                    <b-col cols="1"></b-col>
                    <b-col cols="10" sm>
                        <b-card no-body>
                            <b-card-body>
                            
                                <b-form ref="form" @submit.stop.prevent="handleRegister" @reset="onReset">
                                    
                                    <div style="display: flex; align-items: center; flex-wrap: wrap;">
                                        <h4 style="flex: 90%;">{{ title}}</h4>
                                        <b-button type="submit"  variant="outline-success"  class="mr-2" style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                        <b-button type="reset"  variant="outline-danger"  class="mr-2" style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>

                                    <hr class="my-2">

                                    <b-list-group flush>
                                        <b-list-group-item style="background-color:transparent;color:black;">
                                            <div style="display:flex; flex-direction:row">

                                                    <b-form-group
                                                        label="Group Name"
                                                        label-for="name"                                            
                                                        :invalid-feedback="errors.first('name')"
                                                        style="width:100%; padding: 0px 10px 10px 0px;"
                                                    >
                                                        <b-form-input
                                                            :disabled="(type==='Edit')? true:false" 
                                                            name="name"
                                                            type="text"
                                                            placeholder="Enter name"
                                                            v-model="group.name"
                                                            :state="(submitted)? (!errors.has('name')): null"  
                                                            v-validate="'required|min:3|max:20'"
                                                            style="width:100%"
                                                        ></b-form-input>
                                                    </b-form-group> 

                                                    <b-form-group
                                                        label="Floor"
                                                        label-for="floor"
                                                        :invalid-feedback="errors.first('floor')"
                                                        style="width:100%; padding: 0px 10px 10px 0px;"
                                                    >
                                                        <b-form-select
                                                            name="floor"
                                                            :disabled="(type==='Edit')? true:false" 
                                                            size="lg"
                                                            v-model="group.floor"
                                                            :options="floorList"
                                                            :state="(submitted)? (!errors.has('floor')): null"  
                                                            v-validate="'required'"
                                                            style="width:100%"
                                                            @change="getFeeders($event)"
                                                        ></b-form-select>
                                                    </b-form-group> 
                                                
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-form>
                            

                                <b-table :items="group.members" :fields="memberFields" responsive="sm" small>
                                    <template #cell(modify)="row" >
                                        <div style="display:flex; justify-content:flex-end;">
                                            <b-button pill size="sm" variant="outline-danger" @click="removeMember(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                                                <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                                                        
                                <div style="display: flex; flex-direction: row; justify-content: flex-end; height:30px;">
                                    <b-button pill size="sm" type="submit" variant="primary" @click="addMember" style="vertical-align: middle;  margin-left:20px;">
                                        <b-icon icon="plus" aria-hidden="true" style="vertical-align: middle;"></b-icon>
                                    </b-button>
                                </div>
                            </b-card-body>

                        </b-card>
                    </b-col>
                </b-row>
            </b-container> 
        </div>

        <b-modal ref="my-modal" size="lg" centered title= "Add member"  @ok="handleOk">
            <div>
                <b-list-group flush>
                    <b-list-group-item style="background-color:transparent;color:black;">
                        <div style="display:flex; flex-direction:row">
                            
                            <!-- <b-form-group
                                label="Floor"
                                label-for="floor"
                                :invalid-feedback="errors.first('floor')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-select
                                    name="floor"
                                    size="lg"
                                    v-model="member.floor"
                                    :options="floorList"
                                    :state="(submitted)? (!errors.has('floor')): null"  
                                    v-validate="'required'"
                                    style="width:100%"
                                    @change="setZoneList($event)"
                                ></b-form-select>
                            </b-form-group>  -->
                            
                            <b-form-group
                                label="Zone"
                                label-for="zone"
                                :invalid-feedback="errors.first('zone')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-select
                                    name="zone"
                                    size="lg"
                                    v-model="member.zone"
                                    :options="zoneList"
                                    :state="(submitted)? (!errors.has('zone')): null"  
                                    v-validate="'required'"
                                    style="width:100%"
                                    @change="setFeederList($event)"
                                ></b-form-select>
                            </b-form-group> 
                            
                            <b-form-group
                                label="Feeder"
                                label-for="feeder"
                                :invalid-feedback="errors.first('feeder')"
                                style="width:100%; padding: 0px 10px 10px 0px;"
                            >
                                <b-form-select
                                    name="feeder"
                                    size="lg"
                                    v-model="member.title"
                                    :options="feederList"
                                    :state="(submitted)? (!errors.has('feeder')): null"  
                                    v-validate="'required'"
                                    style="width:100%"
                                    @change="selectFeeder($event)"
                                ></b-form-select>
                            </b-form-group> 

                         </div>
                    </b-list-group-item>
                </b-list-group>

                <b-table :items="filtrdFeeders" :fields="filtrdFeedersFields" select-mode="multi" responsive="sm" ref="selectableTable" selectable @row-selected="onRowSelected" >
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only"></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only"></span>
                        </template>
                    </template>
                </b-table>

            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
    export default {
        name: "accountEdit",
        data() {
            return {
                group:{
                    name:'',
                    floor:null,
                    _id:'',
                    members:[],
                },
                member:{
                    floor:null,
                    zone:null,
                    title:null,
                },
                memberFields: ['floor', 'zone', 'title', { key: 'modify', label: '' }],
                filtrdFeedersFields: ['floor','zone','title',{ key: 'selected', label: '' }],
                
                filtrdFeeders:[],
                feeders:[],
                selectedFeeders:[],

                floorList:[],
                zoneList:[],
                feederList:[],

                submitted: false,
                successful: false,
                message: '',

                title:'',

            }
        },
        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        methods: {
            initializa() {
                this.title = this.type + " Group"
                this.getBuildConf()
                // this.getFeeders()

                if (this.type==='Add')
                {
                    this.group.name = '';
                    this.group.floor = null,
                    this.group.members = [];
                }
                else if (this.type==='Edit')
                {
                    this.group._id = this.db[this.index]._id;
                    this.group.name = this.db[this.index].name;
                    this.group.floor = this.db[this.index].floor;
                    this.getFeeders(this.group.floor)
                    this.group.members = this.db[this.index].members;
                    

                    
                }
            },
            onReset() {
                this.group.name = null
                this.group.members = null;
                this.group.floor = null,
                
                this.$store.dispatch('chpge/changePage','groupSettings')
            },
            handleRegister() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if (this.type==='Add'){

                            var data = {name:this.group.name,floor:this.group.floor,members:[]}
                            for (var member of this.group.members){
                                data.members.push(member.id.feeder)
                            }
                            // console.log(JSON.stringify(data))

                            this.$store.dispatch('data/createGroup', data).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','groupSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                alert(this.message)
                                }
                            );
                        }else if (this.type==='Edit'){
                            var data = {_id:this.group._id,members:[]}
                            for (var member of this.group.members){
                                data.members.push(member.id.feeder)
                            }

                            this.$store.dispatch('data/editGroup', data).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','groupSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }
                    }
                });
            },
            addMember: function(){
                this.showModal()
            },
            removeMember: function(index,event){
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
                .then(value => {
                    if (value===true){
                        this.feeders = this.feeders.concat(this.group.members[index])
                        this.group.members.splice(index, 1);
                        this.setZoneList();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getFeeders(floor) {
                this.group.members = [];
                if (floor){
                    
                    this.$store.dispatch('data/getFeedersofFloor',{floor:floor}).then(
                        data => {
                            // console.log(JSON.stringify(data))
                            this.feeders = data
                            this.setZoneList();
                        },
                        error => {
                            this.message =
                                (error.response && error.response.data && error.response.data.message) ||
                                error.message ||
                                error.toString();
                                // this.successful = false;
                        }
                    );
                }
                else{
                    this.feeders = null;
                    this.setZoneList();
                }
            },
            getBuildConf() {
                this.$store.dispatch('data/getBuildConf').then(
                    data => {
                        this.floorList= [{ text: 'Select One', value: null }];
                        for (var d of data){
                            this.floorList.push({text:d.floorTitle,value:d._id})
                        }


                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },

            setZoneList() {
                for (var member of this.group.members){
                    var index = this.feeders.findIndex(feeder => (feeder.floor === member.floor &&
                                                            feeder.zone  === member.zone &&
                                                            feeder.title === member.title ))
                    if (index !== -1) {
                        this.feeders.splice(index, 1);
                    }
                }
                this.filtrdFeeders = this.feeders

                this.zoneList =  [{ text: 'Select One', value: null }];
                this.feederList =  [{ text: 'Select One', value: null }];

                this.filtrdFeeders = this.feeders
                for (var feeder of this.filtrdFeeders){
                    if (!this.zoneList.includes(feeder.zone)){
                        this.zoneList.push(feeder.zone)
                    }
                }

                this.member.zone = null;
                this.member.title = null;
            },
            setFeederList(value) {
                this.feederList =  [{ text: 'Select One', value: null }];
                if (value){
                    this.filtrdFeeders =   this.feeders.filter(feeder => (feeder.zone === value))

                    for (var feeder of this.filtrdFeeders){
                        if (!this.feederList.includes(feeder.title)){
                            this.feederList.push(feeder.title)
                        }
                    }
                }
                else{
                    this.filtrdFeeders =   this.feeders
                }
                this.member.title = null;
            },
            selectFeeder(value) {
                if (value){
                    this.filtrdFeeders =   this.feeders.filter(feeder => (  feeder.zone === this.member.zone) &&
                                                                            feeder.title === this.member.title)
                }
                else{
                    this.filtrdFeeders =   this.feeders.filter(feeder =>  ( feeder.zone  === this.member.zone))
                }

            },
            onRowSelected(items) {
                this.selectedFeeders = items
            },
            showModal() {
                this.$refs['my-modal'].show()
            },
            handleOk(bvModalEvt) {
                this.group.members = this.group.members.concat(this.selectedFeeders)
                this.setZoneList();
            },
        },
        mounted() {
            this.initializa()
        }
    };
</script>
