<template>
  <div class="component-container">
    <div class="component-button">
    </div>

    <div class="component-main-holder">
      <div @mousedown="infoc" class="component-main" v-if="slider_A.visible">
        <div style="position:relative">
          <round-slider v-if="Reference_A.visible"
                        id="reference-value-A"
                        style="position: absolute;"
                        ref="rslider-A"
                        dir="ltr"
                        v-model="Reference_A.value"
                        :change="handleChangeRefA"
                        :min="Reference_A.min"
                        :max="Reference_A.max"
                        step="0.1"
                        :startAngle="slider.startAngleA"
                        :endAngle="slider.endAngleA"
                        :radius="slider.radius"
                        width="12"
                        handleSize="24"
                        startValue="1"
                        rangeColor="#ff9500"
                        pathColor="#c4c4c4"
                        borderWidth="0"
                        lineCap="round"
                        svgMode="true"
                        showTooltip="false"
                        :tooltipFormat="tooltipFormat"
                        editableTooltip="false"
                        sliderType="min-range"
                        :disabled=disable_sch
          ></round-slider>

          <round-slider v-for="(actual,i) in Actual_A" :key="i"
                        ref="sslider-A"
                        :style="i<(Actual_A.length-1)? {position: 'absolute'}:{position: 'relative'}"
                        id="actual-value-A"
                        v-model="actual.value"
                        :min="Reference_A.min"
                        :max="Reference_A.max"
                        step="0.1"
                        :startAngle="slider.startAngleA"
                        :endAngle="slider.endAngleA"
                        :radius="slider.radius"
                        width="12"
                        dir="ltr"
                        startValue="1"
                        rangeColor="#ff9500"
                        pathColor="#c4c4c4"
                        borderWidth="0"
                        lineCap="round"
                        svgMode="true"
                        sliderType="default"
                        showTooltip="false"
                        readOnly="true"
                        :disabled=disable_sch
          ></round-slider>

          <div class="component-hole">
            <div style="height:25%">
              <p class="note">{{ Reference_A.title }}</p>
            </div>
            <div style="height:50%">
              <p class="text">{{ Reference_A.value }}</p>
            </div>
            <div style="height:25%">
              <i class="note">
                <font-awesome-icon icon="fas fa-power-off" size="2x"
                                   :style="parseInt(Actuator.value)===1? {color:'rgb(0, 255, 255)'}:{color:'#a6a6a6'}" />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div @mousedown="infoc" class="component-main" v-if="slider_B.visible">
        <div style="position:relative">
          <round-slider v-if="Reference_B.visible"
                        id="reference-value-B"
                        style="position: absolute;"
                        ref="rslider-B"
                        dir="ltr"
                        v-model="Reference_A.value"
                        :change="handleChangeRefB"
                        :min="Reference_A.max"
                        :max="Reference_A.min"
                        step="0.1"
                        :startAngle="slider.startAngleB"
                        :endAngle="slider.endAngleB"
                        :radius="slider.radius"
                        width="12"
                        handleSize="24"
                        startValue="1"
                        rangeColor="#ff9500"
                        pathColor="#c4c4c4"
                        borderWidth="0"
                        lineCap="round"
                        svgMode="true"
                        showTooltip="false"
                        :tooltipFormat="tooltipFormat"
                        editableTooltip="false"
                        sliderType="min-range"
                        :disabled=disable_sch
          ></round-slider>

          <round-slider v-for="(actual,i) in Actual_B" :key="i"
                        ref="sslider-B"
                        :style="i<(Actual_B.length-1)? {position: 'absolute'}:{position: 'relative'}"
                        id="actual-value-B"
                        v-model="actual.value"
                        :min="Reference_A.max"
                        :max="Reference_A.min"
                        step="0.1"
                        dir="ltr"
                        :startAngle="slider.startAngleB"
                        :endAngle="slider.endAngleB"
                        :radius="slider.radius"
                        width="12"
                        startValue="1"
                        rangeColor="#ff9500"
                        pathColor="#c4c4c4"
                        borderWidth="0"
                        lineCap="round"
                        svgMode="true"
                        sliderType="default"
                        showTooltip="false"
                        readOnly="true"
                        :disabled=disable_sch
          ></round-slider>

          <div class="component-hole">
            <div style="height:25%">
              <p class="note">{{ Reference_B.title }}</p>
            </div>
            <div style="height:50%">
              <p class="text">{{ Reference_B.value }}</p>
            </div>
            <div style="height:25%">
              <i class="note">
                <font-awesome-icon icon="fas fa-power-off" size="2x"
                                   :style="parseInt(Actuator.value)===1? {color:'rgb(0, 255, 255)'}:{color:'#a6a6a6'}" />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!slider_A.visible && !slider_B.visible" style="transform: scale(1.7);">
        <label class="form-switch">
          <input type="checkbox" v-model="Actuator.value" true-value="1" false-value="0" @change="handleActuator"
                 :disabled="disable_sch || disable_cont">
          <i></i>
        </label>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import RoundSlider from "vue-round-slider";

export default {
  props: {
    id: String,
    panelData: Object
  },
  components: {
    RoundSlider
  },
  data() {
    return {
      slider_A: { visible: false },
      slider_B: { visible: false },

      Reference_A: {},
      Actual_A: [],
      Reference_B: {},
      Actual_B: [],
      Actuator: { dids: [], value: "" },
      // Reference_B:{did:'',value:'',max:'',min:'',title:''},
      // ActualCoil:{did:'',value:''},
      // ActualRoom:{did:'',value:''},
      // Hysteresis:{did:'',value:''},


      gdid: [],
      slider: {
        radius: "85",
        startAngleA: "-135",
        endAngleA: "135",
        startAngleB: "45",
        endAngleB: "315"

      },

      groupOptions: [],

      selectedGroup: null,
      selectedText: ""


    };
  },
  methods: {
    handleChangeRefA() {
      let sd = [{ id: this.Reference_A.did, value: this.Reference_A.value }];
      this.sendData(sd);
    },
    handleChangeRefB() {
      let sd = [{ id: this.Reference_B.did, value: this.Reference_B.value }];
      this.sendData(sd);
    },
    handleActuator() {
      let sd = [];
      for (var did of this.Actuator.dids) {
        sd.push({ id: did, value: this.Actuator.value });
      }
      // let sd = [{id:this.Actuator.did, value:this.Actuator.value}]
      this.sendData(sd);
    },
    tooltipFormat(e) {
      // if (this.Mode.value==='0')
      //   return "OFF";
      // else
      //   return `cooling <br> ${e.value} %`;
    },
    infoc: function(e) {
      e.stopPropagation();
      e.currentTarget.focus();
    },
    sendData: function(data) {
      this.$store.dispatch("panel/setData", { data: data })
        .then(
          data => {
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            // alert(message)
          }
        );
    }

  },
  mounted() {
    // this.disable = panelData.options.control === 'shedule'? true:false;

    for (var g in this.panelData.data) {
      this.gdid.push(this.panelData.data[g].did);
      if (this.panelData.data[g].dtype === "Actuator") {
        this.Actuator.dids.push(this.panelData.data[g].did);
      }
    }

    if (this.panelData.options.controller.visible && (this.panelData.options.control != "manual")) {
      var moduleA = this.panelData.options.controller.moduleA;
      var moduleB = this.panelData.options.controller.moduleB;

      if (moduleA.visible) {
        this.slider_A.visible = true;
        if ((moduleA.inP.source === "Reference_A") || (moduleA.inN.source === "Reference_A")) {
          if (moduleA.inP.source === "Reference_A") {
            this.Reference_A = {
              visible: true,
              title: moduleA.title,
              max: moduleA.inP.max,
              min: moduleA.inP.min,
              did: this.panelData.data.find(x => x.dtype === "Reference_A").did,
              value: ""
            };
            this.Actual_A = [];
            var acBs = this.panelData.data.filter(x => x.dtype === moduleA.inN.source);
            for (var acB of acBs) {
              this.Actual_A.push({ did: acB.did, value: "" });
            }
          } else {
            this.Reference_A = {
              visible: true,
              title: moduleA.title,
              max: moduleA.inN.max,
              min: moduleA.inN.min,
              did: this.panelData.data.find(x => x.dtype === "Reference_A").did,
              value: ""
            };
            this.Actual_A = [];
            var acBs = this.panelData.data.filter(x => x.dtype === moduleA.inP.source);
            for (var acB of acBs) {
              this.Actual_A.push({ did: acB.did, value: "" });
            }
          }

        } else if (moduleA.inP.source === moduleA.inN.source) {
          this.Reference_A = {
            visible: false,
            title: moduleA.title,
            value: "..."
          };
          this.Actual_A = [];
          var acBs = this.panelData.data.filter(x => x.dtype === moduleA.inP.source);
          for (var acB of acBs) {
            this.Actual_A.push({ did: acB.did, value: "" });
          }
        }
      } else {
        this.slider_A.visible = false;
      }

      if (moduleB.visible) {
        this.slider_B.visible = true;
        if ((moduleB.inP.source === "Reference_B") || (moduleB.inN.source === "Reference_B")) {
          if (moduleB.inP.source === "Reference_B") {
            this.Reference_B = {
              visible: true,
              title: moduleB.title,
              max: moduleB.inP.max,
              min: moduleB.inP.min,
              did: this.panelData.data.find(x => x.dtype === "Reference_B").did,
              value: ""
            };
            this.Actual_B = [];
            var acBs = this.panelData.data.filter(x => x.dtype === moduleB.inN.source);
            for (var acB of acBs) {
              this.Actual_B.push({ did: acB.did, value: "" });
            }
          } else {
            this.Reference_B = {
              visible: true,
              title: moduleB.title,
              max: moduleB.inN.max,
              min: moduleB.inN.min,
              did: this.panelData.data.find(x => x.dtype === "Reference_B").did,
              value: ""
            };
            this.Actual_B = [];
            var acBs = this.panelData.data.filter(x => x.dtype === moduleB.inP.source);
            for (var acB of acBs) {
              this.Actual_B.push({ did: acB.did, value: "" });
            }
          }

        } else if (moduleB.inP.source === moduleB.inN.source) {
          this.Reference_B = {
            visible: false,
            title: moduleB.title,
            value: "..."
          };
          this.Actual_B = [];
          var acBs = this.panelData.data.filter(x => x.dtype === moduleB.inP.source);
          for (var acB of acBs) {
            this.Actual_B.push({ did: acB.did, value: "" });
          }
        }
      } else {
        this.slider_B.visible = false;
      }
    } else {
      this.slider_A.visible = false;
      this.slider_B.visible = false;
    }

    // this.groupOptions = [{ text: 'خاموش', value: null}]
    // this.selectedText = this.groupOptions.find(x=> JSON.stringify(x.value)===JSON.stringify(this.selectedGroup))
    // this.gdid=[]
    // for (var g in this.groups){
    //   this.groupOptions.push({ text: this.groups[g].name, value: this.groups[g].did})
    //   this.gdid.push(this.groups[g].did)
    // }

    // var a = document.querySelectorAll(".spread")


    this.$store.dispatch("panel/getData", { id: this.gdid })

      .then(
        data => {
        },
        error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // alert(message)
        }
      );


  },
  computed: {
    updatedData() {
      return this.$store.state.panel.data;
    },
    styleObject() {
      // console.log()
    },
    disable_sch() {
      return (this.panelData.options.control === "schedule") ? true : false;
    },
    disable_cont() {
      return (this.panelData.options.control === "control") ? true : false;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }

  },
  watch: {
    // selectedGroup(newValue) {
    //   this.selectedText = this.groupOptions.find(x=> JSON.stringify(x.value)===JSON.stringify(newValue))

    //   // console.log('selectedText',JSON.stringify(selectedText.text))

    // },

    updatedData(newValue) {
      for (var Data of newValue) {
        for (var did of this.gdid) {
          if (JSON.stringify(Data.id) === JSON.stringify(did)) {

            let fd = this.panelData.data.find(x => JSON.stringify(x.did) === JSON.stringify(did));


            if (fd) {
              if (this[fd.dtype]) {
                // console.log(fd.dtype)
                this[fd.dtype].value = JSON.parse(JSON.stringify(Data.value));
              } else {
                var AAI = this.Actual_A.findIndex(x => JSON.stringify(x.did) === JSON.stringify(did));
                if (AAI != -1) {
                  this.Actual_A[AAI].value = JSON.parse(JSON.stringify(Data.value));
                }

                var ABI = this.Actual_B.findIndex(x => JSON.stringify(x.did) === JSON.stringify(did));
                if (ABI != -1) {
                  this.Actual_B[ABI].value = JSON.parse(JSON.stringify(Data.value));
                }
              }
            }
            // console.log(JSON.stringify(this.pData))
            // if (Data.value==='on'){
            //   this.selectedGroup = Data.id
            //   this.selectedText = this.groupOptions.find(x=> JSON.stringify(x.value)===JSON.stringify(this.selectedGroup))
            //   console.log('selectedGroup',this.selectedGroup,this.selectedText)
            // }
          }
        }
      }
    }
  }
};
</script>

<style scoped
       src="@/assets/styles/ledStyle.css">

</style>

<style scoped>

.fan {
  transform: rotate(90deg);
  color: white

}

.fan-selected {
  transform: rotate(90deg);
  color: rgb(0, 0, 0)
}

.swing {
  filter: invert(1);

}

.swing-selected {
  filter: invert(0);

}

/* datalist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  writing-mode: vertical-lr;
  width: 200px;
} */

.range {
  display: flex;
  width: 600px;
}

.range__slider {
  width: 45%;
}

.range__value {
  width: 35%;
  margin-left: 45px;
  text-align: center;
  border-left: #e6e4e4 1px solid;
}


.range__slider label {
  margin-bottom: 10px;
}

.range__slider [type="range"] {
  width: 100%;
  -webkit-appearance: none;
  height: 13px;
  border-radius: 6px;
  background: #f1f1f1;
  outline: none;
  padding: 0;
  margin: 0;
}

/* custom thumb */
.range__slider [type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #7a00ff;
  border: #f9ca24 5px solid;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range__slider [type="range"]::-webkit-slider-thumb:hover {
  background: #f0932b;
}

.range__slider [type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #f0932b;
  border: #f9ca24 5px solid;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.range__slider [type="range"]::-moz-range-thumb:hover {
  background: #f9ca24;
}

/* remove border */
input::-moz-focus-inner, input::-moz-focus-outer {
  border: 0;
}


.rs-path, .rs-range {
  stroke-dasharray: 2 5;
}

.rs-custom.rs-bar {
  z-index: 40;
}

.rs-custom .rs-border {
  border-color: #d61f1f;
  margin-left: 7px;
  width: 5px;
}

.rs-custom .rs-border.rs-bold {
  width: 14px;
}

.rs-custom.selected .rs-border {
  border-color: #ff9500;
}

.rs-control .rs-tooltip.rs-tooltip-text {
  top: 30px;
  font-size: 30px;
}

.component-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.component-button {
  background-color: #7a7979;
  display: flex;
  justify-content: space-between;
  /* display:flex; */
  position: absolute;
  z-index: 5;
  left: 0;
  top: 50%;
  right: 0;
  bottom: 0;
  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
}

.component-button .component_col {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
}

.component-hole {
  position: absolute;
  z-index: 8;
  /* background: #ffffff; */
  border-radius: 50%;
  /* left:  30%;
  right: 30%;
  bottom:30%;
  top: 30%; */
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.component-hole .text {
  display: flex;
  justify-content: center;
  font-size: 40px !important;
  font-weight: bold !important;
  color: #ffffff !important;
  font-family: Vazir sans-serif !important;
  margin-bottom: 0px;
}

.component-hole .note {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 13px !important;
  /* font-weight: bold !important; */
  color: #ffffff !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
}

.component-main-holder {
  position: absolute;
  z-index: 7;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.component-main {
  /* display:flex; */
  width: fit-content;
  height: fit-content;
  /* position: absolute;*/
  /* z-index: 7;  */
  /* left: 50%;
  top: 50%; */
  /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  background-color: #f9701c;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.radioboxGroup {
  margin: 20px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.radiobtnGroup {
  width: 100%;
  height: 50px;
  display: inline-block;
  margin: 2px
}

.radiobtnGroup .radioinpGroup {
  display: none;
}

.radiobtnGroup .radiospanGroup:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtnGroup .radiospanGroup:before {
  background-color: rgb(198, 22, 22);
}


.radiobtnGroup .radiospanGroup {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(150, 150, 150, 0.368);
  border-radius: 10px;
  background-color: rgba(150, 150, 150, 0.089);
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 5px;

}

.radiobtnGroup input:checked + span {
  background-color: rgba(249, 124, 28, 0.904);
  border: 1px solid rgb(252, 118, 15);
  color: rgb(255, 255, 255)
}

.radiobox {
  margin: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;

}

.radiobtn {
  width: 50%;
  height: 40px;
  display: inline-block;
}

.radiobtn .radioinp {
  display: none;
}

.radiobtn .radiospan:hover {
  /* background-color: hsla(0, 0%, 100%, 0.063); */
  cursor: pointer;
}

.radiobtn .radiospan:before {
  background-color: rgb(198, 22, 22);
}

.radiobtn .radiospan {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0)
}

.radiobtn input:checked + span {
  background-color: hsl(0, 0%, 0%);
  color: rgb(255, 255, 255)
}

.radiobtn input:checked + span img {
  filter: invert(1);
}
</style>

<style scoped lang="scss">
@import "compass/css3";


.ars-range {
  background: #fff0;
  // width: 100%;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    box-shadow: none;
    background: #cacaca;
    // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    box-shadow: none;
    background: #dd9a35;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 10px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;
    content: 'ali';
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 10px;
    border-radius: 22px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -14px;

  }

  &::-moz-focus-outer {
    border: 0;
  }
}


$icon-size: 120px; /* [2] */
$menu-size: 300px / 2; /* [3] */
$unit-size: 45px; /* [4] */
$menu-position: (($menu-size * 2) - $icon-size) / -2;
$offset: 100px; /* [5] */


/* Styles: */
.icon-disable, .icon {
  position: relative;
  background: rgba(#f9701c, 1);

  cursor: pointer;
  margin: auto;
  color: #ffffff;
  // border: 5px solid #f9701c;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  // line-height: $icon-size*1.5;
  // text-align: center;
  // font-size: 1.5rem;
  // font-weight: bold;
  // transition: 0.24s 0.2s;
  // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  // box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.icon-hover {
  background: #b1b1b1;
  height: $icon-size;
  width: $icon-size;
  line-height: $icon-size;
  font-size: 1rem;

  .menu {
    transition: transform 0.4s 0.08s,
    z-index 0s 0.5s;
    transform: scale(1);
    z-index: 5;
  }

  .spread {
    transition: all 0.6s;
  }

  .unit {
    // transition: all 0.6s;
    color: rgb(255, 255, 255)
  }
}

.menu {
  position: absolute;
  top: $menu-position;
  left: $menu-position;
  border: $menu-size solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1.4s 0.07s;
  z-index: -5;
  margin: 0px;
  padding: 0px;
}

.spread {
  position: absolute;
  top: -($offset);
  left: -($offset);
  transform-origin: $offset $offset;
  transition: all 0.5s 0.1s;
}

// .icon-hover {
//  .menu {
// 	 transition: transform 0.4s 0.08s,
// 			  				        z-index   0s  0.5s;
// 	 transform: scale(1);
// 	 z-index: 5;
//  }
//  .spread, .unit {
//  	 transition: all 0.6s;
//  }

/* Where all the MAGIC happens: */
//  @for $m from 1 through $li {
//    .spread:nth-child(#{$m}) {
// 	    transition-delay: $m * 0.02s;
// 	    transform: rotate(45deg + $deg * $m - $deg);
// 	    .unit {
// 		    transition-delay: $m * 0.04s;
//         transform: rotate(45deg);
// 	    }
//    }
// }
// } /* END .icon:hover */

.unit {
  position: absolute;

  background: #272c2f;
  font-size: 0.8rem;
  text-decoration: none;
  width: $unit-size*1.5;
  height: $unit-size;
  line-height: $unit-size;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  transition: 0.6s;
  display: flex;
  justify-content: center;

}


ul, li {
  margin: 0;
  outline: 0;
  padding: 0;
  list-style: none
}


</style>

<style>
#actual-value-A .rs-handle, #actual-value-B .rs-handle {
  background-color: #000000 !important;
  box-shadow: 0 0 0 0 #000 !important;
  transform: rotate(-180deg);
}

#actual-value-A .rs-handle::after, #actual-value-B .rs-handle::after {
  height: 100%;
  width: 200%;
  display: flex;
  align-items: center;
  justify-content: center;
  content: attr(aria-valuenow);
  font-size: 15px;
  border-bottom: 1px solid rgb(0, 0, 0);
  color: #ff8400 !important;
  font-weight: bold !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(50%, -50%) !important;
}

/* .rs-tooltip-text {

   font-size: 50px !important;
   font-weight: bold !important;
   color:#ffffff !important;
   font-family: Arial, Helvetica, sans-serif !important;

 } */

.rs-tooltip {
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
}
</style>