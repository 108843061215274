<script>
import Vue from "vue";

export default Vue.extend({
  name: "dataPickerSelector",
  props: {
    isMultiSelect: { default: false },
    value: { default: [] }
  },
  data() {
    return {
      items: [],
      message: null,
      type: "data",
      val: [],
      dict: {
        name: { per: "انتخاب داده", eng: "choose data" }
      }
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child: {
      handler(newValue) {
        if (newValue.task === "pickData") {
          this.to = newValue.from;
          this.filterData = newValue.filter;
        }
      },
      deep: true
    },
    val: {
      handler(v) {
        let theValue = [];
        if (this.isMultiSelect) {
          v.forEach((i) => {
            this.items.forEach((item) => {
              if (item.value == i)
                theValue.push(item.values);
            });
          });
        } else {
          theValue = [];
          theValue = this.items.find((item) => item.value == v).values;
        }
        this.$store.dispatch("chpge/child2parent", {
          task: "pickData",
          to: this.to,
          item: theValue
        });
      }, deep: true
    }
  },
  methods: {
    getAllData() {
      this.items = [];
      const filterData = {
        dataType: ["!complex"],
        usedBy: [],
        parents: [],
        archive: null,
        withChild: false,
        perPage: 5000,
        page: 1
      };
      this.$store.dispatch("data/getAllData", filterData).then(
        (data) => {
          data.newmatches.forEach((item) => {
            this.items.push({
              text: item.name,
              value: item.id,
              values: {
                dataType: item.type,
                name: item.name,
                type: this.type,
                _id: item.id
              }
            });
            // if (item.type != "Object") {
            //   this.items.push({
            //     text: item.name,
            //     value: item.id,
            //     values: {
            //       dataType: item.type,
            //       name: item.name,
            //       type: this.type,
            //       _id: item.id
            //     }
            //   });
            // } else if (item.type == "Object") {
            //   // let a = {};
            //   // a = { text: item.name, value: item._id };
            //
            //   // a["child"].push({
            //   //   text: item2.name,
            //   //   value: {
            //   //     dataType: item2.type,
            //   //     name: item2.name,
            //   //     type: this.type,
            //   //     _id: item._id + "_" + item2.id
            //   //   }
            //   // });
            //
            //   item.value.forEach((item2) => {
            //     this.items.push({
            //       text: item2.name,
            //       value: item._id + "_" + item2.id,
            //       values: {
            //         dataType: item2.type,
            //         name: item2.name,
            //         type: this.type,
            //         _id: item._id + "_" + item2.id
            //       },
            //       category: item.name
            //     });
            //   });
            // }
          });

          // this.items = data;
          // this.totalRows = this.items.length;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    }
  },
  mounted() {
    if (this.value.length > 0) {
      this.val = [];
      this.value.forEach((item) => {
        if (this.val.indexOf(item._id) <= -1)
          this.val.push(item._id);
      });
    }
    this.getAllData();
  }
});
</script>

<template>
  <div>
    <div
      v-if="!isMultiSelect"
      :key="items"
    >
      <ejs-dropdownlist
        :dataSource="items"
        :fields="{ value: 'value', text: 'text' }"
        :placeholder="dict.name[lang]"
        :required="true"
        v-model="val"
      ></ejs-dropdownlist>
    </div>
    <div
      v-if="isMultiSelect"
      :key="items"
    >
      <ejs-multiselect
        :placeholder="dict.name[lang]"
        :dataSource="items"
        :required="true"
        :fields="{ value: 'value', text: 'text' }"
        v-model="val"
      ></ejs-multiselect>

    </div>
  </div>
</template>

<style scoped></style>
