import { g } from "jointjs";
import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { TextBox } from "@syncfusion/ej2-inputs";
import Vue from "vue";

const joint = require("@joint/core/dist/joint");
const { dia, ui, shapes } = joint;
const pipeSize = { width: 50, height: 30 };

const r = 30;
const d = 10;
const l = (3 * r) / 4;
const step = 20;

const itemPosition = { x: 800, y: 300 };
const valveItem = { x: 400, y: 300 };


// const stencil = new ui.stencil();
//
// const AlignmentLine = joint.dia.HighlighterView.extend({
//   tagName: "path",
//
//   attributes: function() {
//     const { color = "blue" } = this.options;
//     return {
//       fill: "none",
//       stroke: color,
//       "stroke-width": 2,
//       "stroke-dasharray": "5 5"
//     };
//   },
//
//   highlight(elementView) {
//     const { vertical = true, horizontal = true, overflow = 50 } = this.options;
//     // Highlighter is always rendered relatively to the CellView origin i.e
//     // the top-left corner is always in (0, 0) regardless of the CellView position.
//     const bbox = new g.Rect(elementView.model.size());
//     const { x, y } = bbox.center();
//     let d = "";
//     if (vertical) {
//       d += `M ${x} ${-overflow} ${x} ${bbox.height + overflow}`;
//     }
//     if (horizontal) {
//       d += `M ${-overflow} ${y} ${bbox.width + overflow} ${y}`;
//     }
//     if (d) {
//       this.el.setAttribute("d", d);
//     }
//   }
// });
//
// // Highlighter to show the distance between the element and the other element.
// const DistanceLine = joint.dia.HighlighterView.extend({
//   tagName: "g",
//   children: function() {
//     const color = this.options.color || "blue";
//     return [
//       {
//         tagName: "path",
//         selector: "lineEl",
//         attributes: {
//           stroke: color,
//           "stroke-width": 2,
//           "shape-rendering": "optimizeSpeed"
//         }
//       },
//       {
//         tagName: "text",
//         selector: "labelEl",
//         attributes: {
//           stroke: "none",
//           fill: color,
//           "text-anchor": "middle",
//           "font-size": 12,
//           "font-family": "sans-serif"
//         }
//       }
//     ];
//   },
//
//   highlight(elementView) {
//     const {
//       length = 20,
//       units = "px",
//       direction = "right",
//       aligned = false,
//       margin = 3
//     } = this.options;
//     const { model } = elementView;
//
//     // Highlighter is always rendered relatively to the CellView origin i.e
//     // the top-left corner is always in (0, 0) regardless of the CellView position.
//     const bbox = new g.Rect(model.size());
//
//     this.renderChildren();
//
//     let start, end, textAnchor;
//     switch (direction) {
//       case "right":
//         start = bbox.rightMiddle();
//         end = start.clone().offset(length, 0);
//         textAnchor = "middle";
//         start.offset(margin, 0);
//         end.offset(-margin, 0);
//         break;
//       case "left":
//         start = bbox.leftMiddle();
//         end = start.clone().offset(-length, 0);
//         textAnchor = "middle";
//         start.offset(-margin, 0);
//         end.offset(margin, 0);
//         break;
//       case "top":
//         start = bbox.topMiddle();
//         end = start.clone().offset(0, -length);
//         textAnchor = "start";
//         start.offset(0, -margin);
//         end.offset(0, margin);
//         break;
//       case "bottom":
//         start = bbox.bottomMiddle();
//         end = start.clone().offset(0, length);
//         textAnchor = "start";
//         start.offset(0, margin);
//         end.offset(0, -margin);
//         break;
//     }
//
//     const { lineEl, labelEl } = this.childNodes;
//     let d = `M ${start.x} ${start.y} L ${end.x} ${end.y}`;
//
//     labelEl.setAttribute("text-anchor", textAnchor);
//     if (direction === "left" || direction === "right") {
//       labelEl.setAttribute("x", (start.x + end.x) / 2);
//       labelEl.setAttribute("y", start.y + 20);
//       // Draw the perpendicular lines at the end of the line.
//       d += `M ${end.x} ${end.y - 5} L ${end.x} ${end.y + 5}`;
//       d += `M ${start.x} ${start.y - 5} L ${start.x} ${start.y + 5}`;
//       // Draw the cross line close to the end of the line if
//       // the aligned option is enabled.
//       if (aligned) {
//         const c = end.clone().move(start, -8);
//         d += `M ${c.x - 3} ${c.y - 5} L ${c.x + 3} ${c.y + 5}`;
//       }
//     } else {
//       labelEl.setAttribute("x", start.x + 10);
//       labelEl.setAttribute("y", (start.y + end.y) / 2);
//       // Draw the perpendicular lines at the end of the line.
//       d += `M ${end.x - 5} ${end.y} L ${end.x + 5} ${end.y}`;
//       d += `M ${start.x - 5} ${start.y} L ${start.x + 5} ${start.y}`;
//       // Draw the cross line close to the end of the line if
//       // the aligned option is enabled.
//       if (aligned) {
//         const c = end.clone().move(start, -8);
//         d += `M ${c.x - 5} ${c.y - 3} L ${c.x + 5} ${c.y + 3}`;
//       }
//     }
//     labelEl.textContent = `${length} ${units}`;
//     lineEl.setAttribute("d", d);
//   }
// });
//
// const PositionInfo = joint.dia.HighlighterView.extend({
//   // by default the highlighter is not updated when the associated element moves
//   UPDATE_ATTRIBUTES: ["position"],
//   tagName: "text",
//   attributes: {
//     fill: "#131e29",
//     "stroke-width": 3,
//     stroke: "#dde6ed",
//     "paint-order": "stroke",
//     "text-anchor": "middle",
//     "font-size": 10,
//     "font-family": "sans-serif"
//   },
//   highlight(elementView) {
//     const { x, y, width, height } = elementView.model.getBBox();
//     this.el.setAttribute("x", width / 2);
//     this.el.setAttribute("y", height - 13);
//     this.el.textContent = `${x.toFixed(0)},${y.toFixed(0)}`;
//   }
// });
//
// // Events
// // ------
//
// stencil.on("element:dragstart", () => {
//   // This is the first workaround for the fact that there is no public API
//   // for snapping the stencil preview element.
//   stencil.listenTo(
//     stencil._graphDrag,
//     "change:position",
//     stencil.onCloneSnapped.bind(stencil)
//   );
// });
//
// stencil.on("element:drag", (cloneView, _evt, cloneArea, validDropTarget) => {
//   if (!validDropTarget) {
//     clearLines(cloneView);
//     return;
//   }
//   drawLines(cloneView, cloneArea, true);
// });

// stencil.on("element:dragend", (cloneView) => clearLines(cloneView));
const removeMarkup = [
  {
    tagName: "circle",
    selector: "button",
    attributes: {
      r: 10,
      fill: "#FFD5E8",
      stroke: "#FD0B88",
      "stroke-width": 2,
      cursor: "pointer"
    }
  },
  {
    tagName: "path",
    selector: "icon",
    attributes: {
      d: "M -4 -4 4 4 M -4 4 4 -4",
      fill: "none",
      stroke: "#333",
      "stroke-width": 3,
      "pointer-events": "none"
    }
  }
];
const editLinkMarkup = [
  {
    tagName: "image",
    selector: "handle",
    attributes: {
      cursor: "pointer",
      width: 35,
      height: 35,
      "xlink:href": "https://cdn-icons-png.flaticon.com/512/5996/5996831.png"
    }
  }
];

const FLOW_FLAG = "FLOW";
const POWER_FLAG = "POWER";
const OPEN_FLAG = "OPEN";
const LIGHT_FLAG = "LIGHT";

const LIQUID_COLOR = "#0f9ec1";
// const MAX_LIQUID_COLOR = "#ED2637";
const MIN_LIQUID_COLOR = "#FFD23F";

const pipe = {
  portRoot: {
    magnetSelector: "pipeEnd"
  },
  pipeBody: {
    magnet: true,
    width: "calc(w)",
    height: "calc(h)",
    y: "calc(h / -2)",
    fill: "#fff"
  },
  pipeEnd: {
    width: 10,
    height: "calc(h+6)",
    y: "calc(h / -2 - 3)",
    stroke: "gray",
    strokeWidth: 1,
    fill: "white"
  }
};
const portRect = {
  markup: [
    {
      tagName: "image",
      selector: "image",
      attributes: {
        "xlink:href": "https://www.svgrepo.com/show/488348/rectangle.svg", // Replace 'path/to/image.png' with the path to your image file
        width: 25,
        height: 25,
        magnet: true,
        z: -1
      },
      z: -1
    }
  ]
};
const circlePort = {
  markup: [
    {
      tagName: "image",
      selector: "image",
      attributes: {
        "xlink:href": "https://www.svgrepo.com/show/506447/circle-on.svg", // Replace 'path/to/image.png' with the path to your image file
        width: 25,
        height: 25,
        magnet: true,
        z: -1
      },
      z: -1
    }
  ]
};

const customPipe = {
  markup: [
    {
      tagName: "image",
      selector: "image",
      attributes: {
        "xlink:href": "https://www.svgrepo.com/show/208490/pipe.svg", // Replace 'path/to/image.png' with the path to your image file
        width: 50,
        height: 50,
        magnet: true,
        z: -1
      },
      z: -1
    }
  ]
};

const pipePort = {
  markup: joint.util.svg`
                          <rect @selector="pipeBody" />
                          <rect @selector="pipeEnd" />
                      `,
  size: { width: 50, height: 30 },
  attrs: {
    portRoot: {
      magnetSelector: "pipeEnd"
    },
    pipeBody: {
      width: 50,
      height: 30,
      strokeWidth: 4,
      x: 0,
      y: 0,
      rx: 10,
      ry: 10,
      fill: {
        type: "linearGradient",
        stops: [
          { offset: "0%", color: "gray" },
          { offset: "30%", color: "white" },
          { offset: "70%", color: "white" },
          { offset: "100%", color: "gray" }
        ],
        attrs: {
          x1: "0%",
          y1: "0%",
          x2: "0%",
          y2: "100%"
        }
      }
    },
    pipeEnd: {
      width: 10,
      height: 30,
      y: 10,
      stroke: "gray",
      strokeWidth: 3,
      fill: "white"
    }
  }
};
const Zone = class Zone extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "Zone",
      size: {
        width: 120,
        height: 40
      },
      attrs: {
        body: {
          fill: "#ffffff",
          stroke: "#cad8e3",
          strokeWidth: 1,
          d: "M 0 calc(0.5*h) calc(0.5*h) 0 H calc(w) V calc(h) H calc(0.5*h) Z"
        },
        label: {
          fontSize: 14,
          text:'Zone',
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fill: LIQUID_COLOR,
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          x: "calc(w / 2 + 10)",
          y: "calc(h / 2)"
        },
        root:{
          magnetSelector:'body'
        }
      },
      markup: joint.util.svg/* xml */`
            <path @selector="body"/>
            <text @selector="label"/>
        `,
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 120,
                y: 20
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: "top",
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }
};
const ZoneReverse = class Zone extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "ZoneR",
      size: {
        width: 120,
        height: 40
      },
      attrs: {
        body: {
          fill: "#ffffff",
          stroke: "#cad8e3",
          strokeWidth: 1,
          d: "M 0 calc(0.5*h) calc(0.5*h) 0 H calc(w) V calc(h) H calc(0.5*h) Z"
        },
        label: {
          fontSize: 14,
          text:'Zone',
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fill: LIQUID_COLOR,
          textVerticalAnchor: "middle",
          textAnchor: "middle",
          x: "calc(w / 2 + 10)",
          y: "calc(h / 2)"
        },
        root:{
          magnetSelector:'body'
        }
      },
      markup: joint.util.svg/* xml */`
            <path @selector="body"/>
            <text @selector="label"/>
        `,
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 0,
                y: 20
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: "top",
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }
};

const Join = class Join extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "Join",
      size: {
        width: 30,
        height: 30
      },
      attrs: {
        body: {
          fill: "#eee",
          stroke: "#666",
          strokeWidth: 2,
          d: "M 10 0 H calc(w - 10) l 10 10 V calc(h - 10) l -10 10 H 10 l -10 -10 V 10 Z"
        }
      },
      markup: joint.util.svg/* xml */`
            <path @selector="body"/>
        `
    };
  }


};

const handValve = class HandValve extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "HandValve",
      size: {
        width: 50,
        height: 50
      },
      power: 0,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        body: {
          rx: "calc(w / 2)",
          ry: "calc(h / 2)",
          cx: "calc(w / 2)",
          cy: "calc(h / 2)",
          stroke: "gray",
          strokeWidth: 2,
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "70%", color: "white" },
              { offset: "100%", color: "gray" }
            ]
          }
        },
        stem: {
          width: 10,
          height: 30,
          x: "calc(w / 2 - 5)",
          y: -30,
          stroke: "#333",
          strokeWidth: 2,
          fill: "#555"
        },
        handwheel: {
          width: 60,
          height: 10,
          x: "calc(w / 2 - 30)",
          y: -30,
          stroke: "#333",
          strokeWidth: 2,
          rx: 5,
          ry: 5,
          fill: "#666"
        },
        label: {
          text: "Valve",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(0.5*w)",
          y: "calc(h+10)",
          fontSize: "14",
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      markup: joint.util.svg/* xml */`
          <rect @selector="stem" />
          <rect @selector="handwheel" />
          <ellipse @selector="body" />
          <text @selector="label" />
      `,
      ports: {
        groups: {
          'pipes': {
            position: {
              name: "absolute",
              args: {
                x: "calc(w / 2)",
                y: "calc(h / 2)"
              }
            },
            markup: joint.util.svg`
                          <rect @selector="pipeBody" />
                          <rect @selector="pipeEnd" />
                      `,
            size: { width: 50, height: 30 },
            attrs: {
              portRoot: {
                magnetSelector: "pipeEnd"
              },
              pipeBody: {
                width: "calc(w)",
                height: "calc(h)",
                y: "calc(h / -2)",
                fill: {
                  type: "linearGradient",
                  stops: [
                    { offset: "0%", color: "gray" },
                    { offset: "30%", color: "white" },
                    { offset: "70%", color: "white" },
                    { offset: "100%", color: "gray" }
                  ],
                  attrs: {
                    x1: "0%",
                    y1: "0%",
                    x2: "0%",
                    y2: "100%"
                  }
                }
              },
              pipeEnd: {
                width: 10,
                height: "calc(h+6)",
                y: "calc(h / -2 - 3)",
                stroke: "gray",
                strokeWidth: 3,
                fill: "white"
              }
            }
          }
        },
        items: [
          {
            id: "left",
            group: "pipes",
            z: 0,
            attrs: {
              pipeBody: {
                x: "calc(-1 * w)"
              },
              pipeEnd: {
                x: "calc(-1 * w)"
              }
            }
          },
          {
            id: "right",
            group: "pipes",
            z: 0,
            attrs: {
              pipeEnd: {
                x: "calc(w - 10)"
              }
            }
          }
        ]
      }
    };
  }
};

const artBoard = class ArtBoard extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "ArtBoard",
      size: {
        width: 50,
        height: 50
      },
      power: 0,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        backgroundCircle1: {
          cx: 25,
          cy: 25,
          r: 27.97,
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "60%", color: "#fff" },
              { offset: "30%", color: "#fff" },
              { offset: "56%", color: "#898788" },
              { offset: "100%", color: "#231f20" }
            ]
          },
          stroke: "#898788",
          strokeWidth: 1
        },
        backgroundCircle2: {
          cx: 25,
          cy: 25,
          r: 22.47,
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "38%", color: "#fdfdfd" },
              { offset: "52%", color: "#f6f6f6" },
              { offset: "62%", color: "#ebebeb" },
              { offset: "7%", color: "#dadada" },
              { offset: "77%", color: "#c5c4c4" },
              { offset: "83%", color: "#aaa8a9" },
              { offset: "88%", color: "#8a8888" },
              { offset: "93%", color: "#656263" },
              { offset: "98%", color: "#3d393a" },
              { offset: "100%", color: "#231f20" }
            ]
          },
          stroke: "#898788",
          strokeWidth: 1
        },
        innerCircle: {
          cx: 25,
          cy: 25,
          r: 1.87,
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          },
          stroke: "#898788",
          strokeWidth: 1
        },
        handlePath: {
          d: "M25,20 l-1.33,4 a8.83,8.83,0,0,1,1.67,0.55 c0,0,1.67,-10,1.67,-10Z",
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "34%", color: "#b6b4b5" },
              { offset: "98%", color: "#231f20" }
            ]
          },
          stroke: "#000",
          strokeWidth: 1
        },
        label: {
          text: "Valve",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(0.5*w)",
          y: "calc(h+10)",
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      markup: joint.util.svg/* xml */`
        <circle @selector="backgroundCircle1" />
        <circle @selector="backgroundCircle2" />
        <circle @selector="innerCircle" />
        <path @selector="handlePath" />
        <text @selector="label" />
    `,
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 25   // Adjusted for size 50
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 50   // Adjusted for size 50
              }
            },
            attrs: {
              circle: {
                r: 2,  // Adjusted port circle radius for new size
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }

  rotateHandle(angle) {
    this.attr("handlePath/transform", `rotate(${angle} 75 75)`); // Rotate around center (75, 75)
    this.set("rotationAngle", angle); // Store new angle in the model
  }
};


const flowMeter = class FlowMeter extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "FlowMeter",
      power: 0,
      size: { width: 100, height: 134.5 },  // Half of 200 x 269
      attrs: {
        rect1: {
          x: 32.17,   // Half of 64.34
          y: 22.79,   // Half of 45.57
          width: 43.15,   // Half of 86.3
          height: 11.75,   // Half of 23.49
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        rect2: {
          x: 39.22,   // Half of 78.45
          y: 56.18,   // Half of 112.35
          width: 29.29,   // Half of 58.59
          height: 23.8,   // Half of 47.6
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        rect3: {
          x: 28.6,   // Half of 57.19
          y: 79.98,  // Half of 159.95
          width: 51.07,  // Half of 102.13
          height: 31.4,  // Half of 62.81
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "26%", color: "#b8b7b7" },
              { offset: "52%", color: "#797677" },
              { offset: "74%", color: "#4a4748" },
              { offset: "90%", color: "#2e2a2b" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        rect4: {
          x: 48.55,   // Half of 97.09
          y: 84.25,   // Half of 168.5
          width: 11.65,   // Half of 23.3
          height: 27.13,   // Half of 54.26
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle1: {
          cx: 52.79,   // Half of 105.57
          cy: 44.74,   // Half of 89.48
          r: 19.79,    // Half of 39.57
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle2: {
          cx: 52.79,   // Half of 105.57
          cy: 44.74,   // Half of 89.48
          r: 12,       // Half of 24
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        rect5: {
          x: 43.6,   // Half of 87.19
          y: 39.38,   // Half of 78.76
          width: 18.38,   // Half of 36.76
          height: 10.73,  // Half of 21.45
          fill: "#4a4a4a"
        },
        circle3: {
          cx: 54.13,   // Half of 108.25
          cy: 89.46,   // Half of 178.91
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle4: {
          cx: 54.37,   // Half of 108.74
          cy: 106.83,  // Half of 213.65
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle5: {
          cx: 39.22,   // Half of 78.45
          cy: 84.25,   // Half of 168.5
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle6: {
          cx: 68.27,   // Half of 136.53
          cy: 84.25,   // Half of 168.5
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle7: {
          cx: 68.52,   // Half of 137.04
          cy: 106.83,  // Half of 213.65
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        },
        circle8: {
          cx: 39.22,   // Half of 78.45
          cy: 106.83,  // Half of 213.65
          r: 5.2,      // Half of 10.41
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "0%", color: "#fff" },
              { offset: "100%", color: "#231f20" }
            ]
          }
        }
      },
      markup: joint.util.svg/* xml */`
        <rect @selector="rect1" />
        <rect @selector="rect2" />
        <rect @selector="rect3" />
        <rect @selector="rect4" />
        <circle @selector="circle1" />
        <circle @selector="circle2" />
        <rect @selector="rect5" />
        <circle @selector="circle3" />
        <circle @selector="circle4" />
        <circle @selector="circle5" />
        <circle @selector="circle6" />
        <circle @selector="circle7" />
        <circle @selector="circle8" />
      `,
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: -25.26,  // Half of -50.51
                y: 115   // Half of 230
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: {
              name: "absolute",
              args: {
                x: 40,  // Half of 80
                y: 100   // Half of 200
              }
            },
            attrs: {
              circle: {
                r: 1,  // Half of port circle radius
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }

  rotateHandle(angle) {
    this.attr("handlePath/transform", `rotate(${angle} 75 75)`); // Rotate around center (75, 75)
    this.set("rotationAngle", angle); // Store new angle in the model
  }
};

const heightMeter = class HeightMeter extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "heightMeter",
      size: { width: 75, height: 200 },  // Reduced size to half
      markup: joint.util.svg/* xml */`
            <g>
                <rect class="cls-1" @selector="rect1" />
                <rect class="cls-1" @selector="rect2" />
                <rect class="cls-1" @selector="rect3" />
                <rect class="cls-1" @selector="rect4" />
                <rect class="cls-1" @selector="rect5" />
                <rect class="cls-1" @selector="rect6" />
                <rect class="cls-1" @selector="rect7" />
                <rect class="cls-1" @selector="rect8" />
                <rect class="cls-1" @selector="rect9" />
                <rect class="cls-1" @selector="rect10" />
                <rect class="cls-1" @selector="rect11" />
                <rect class="cls-1" @selector="rect12" />
                <rect class="cls-2" @selector="rect13" />
                <rect class="cls-2" @selector="rect14" />
                <rect class="cls-3" @selector="rect15" />
                <rect class="cls-4" @selector="rect16" />
                <rect class="cls-5" @selector="rect17" />
                <rect class="cls-6" @selector="rect18" />
                <rect @selector="rect19" />
                <rect @selector="rect20" />
                <rect @selector="rect21" />
                <rect @selector="rect22" />
            </g>
        `,
      attrs: {
        rect1: { x: 29.365, y: 7.275, width: 6.13, height: 200.2, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect2: { x: 52.215, y: 7.275, width: 6.13, height: 200.2, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect3: { x: 35.495, y: 12.13, width: 16.725, height: 5.87, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect4: { x: 35.495, y: 22.34, width: 16.725, height: 12.765, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect5: { x: 35.495, y: 55.53, width: 16.725, height: 8.425, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect6: { x: 35.495, y: 70.085, width: 16.725, height: 7.66, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect7: { x: 35.495, y: 82.085, width: 16.725, height: 5.105, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect8: { x: 35.495, y: 91.53, width: 16.725, height: 6.64, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect9: { x: 35.495, y: 103.275, width: 16.725, height: 9.955, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect10: { x: 35.495, y: 117.32, width: 16.725, height: 2.81, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect11: { x: 35.495, y: 123.95, width: 16.725, height: 6.385, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect12: { x: 35.495, y: 134.68, width: 16.725, height: 14.045, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect13: { x: 35.495, y: 151.53, width: 16.725, height: 6.385, fill: "none", stroke: "#231f20", "stroke-width": 1.5 },
        rect14: { x: 36.515, y: 162.765, width: 15.7, height: 18.895, fill: "none", stroke: "#231f20", "stroke-width": 1.5 },
        rect15: { x: 63.325, y: 21.53, width: 17.5, height: 17.5, fill: "#1d1d1d" },
        rect16: { x: 63.325, y: 62.565, width: 17.5, height: 17.5, fill: "#ee2428" },
        rect17: { x: 63.5, y: 146.895, width: 17.5, height: 17.5, fill: "#ee2428" },
        rect18: { x: 66.005, y: 171.605, width: 17.5, height: 17.5, fill: "#ee2428" },
        rect19: { x: 52.215, y: 66.255, width: 11.105, height: 11.105, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect20: { x: 52.79, y: 175.33, width: 13.215, height: 11.105, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect21: { x: 52.79, y: 25.03, width: 11.105, height: 11.105, fill: "none", stroke: "#231f20", "stroke-width": 2 },
        rect22: { x: 52.215, y: 150.095, width: 11.105, height: 11.105, fill: "none", stroke: "#231f20", "stroke-width": 2 }
      }
    };
  }
};
const pool = class Pool extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "pool",
      size: { width: 75, height: 200 },
      markup: joint.util.svg/* xml */`
            <g>
                <rect class="cls-1" @selector="rect1" />
                <rect class="cls-2" @selector="rect2" />
                <rect class="cls-3" @selector="rect3" />
                <rect class="cls-4" @selector="rect4" />
            </g>
        `,
      attrs: {
        rect1: {

          x: 42.26, y: 80.61, width: 592, height: 180, fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#7b6f6f" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#7b6f6f" }
            ]
          }
        },
        rect2: {
          x: 15.7, y: 250.32, width: 645.11, height: 27, fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#7b6f6f" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#7b6f6f" }
            ]
          }
        },
        rect3: {
          z:100,
          x: 15.7, y: 80.72, width: 660.36, height: 27, fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#7b6f6f" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#7b6f6f" }
            ]
          }
        },
        rect4: {
          x: 50,
          y: 140,
          width: 575,
          height: 115, // You can modify this dynamically
          fill: "#00a79d"
        }
      }
    };
  }

};


const Pomps = class Pomps extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "Pomps",
      size: { width: 107.23, height: 356.17 },  // Size from the original SVG viewBox
      markup: joint.util.svg/* xml */`
        <rect class="cls-1" @selector="rect1"/>
        <rect class="cls-2" @selector="rect2"/>
        <rect class="cls-3" @selector="rect3"/>
        <rect class="cls-4" @selector="rect4"/>
        <rect class="cls-5" @selector="rect5"/>
        <rect class="cls-6" @selector="rect6"/>
        <rect class="cls-6" @selector="rect7"/>
        <polygon class="cls-7" @selector="polygon"/>
        <path class="cls-8" @selector="path1"/>
        <rect class="cls-9" @selector="rect8"/>
        <rect class="cls-10" @selector="rect9"/>
        <path class="cls-8" @selector="path2"/>
        <text class="label" @selector="label"/>   <!-- Added text label -->
      `,
      attrs: {
        root: {
          magnetSelector: "rect2"
        },
        label: {
          text: 'پمپ',        // Label text
          fontSize: 16,         // Font size for label
          fontFamily: "sans-serif",
          fontWeight: "bold",
          fill: "#000000",      // Black color for text
          textAnchor: "middle", // Center alignment
          textVerticalAnchor: "middle",
          x: 53.61,             // Horizontal center (half of width: 107.23 / 2)
          y: -10,               // Position of the label (you can adjust this)
        },
        rect1: {
          x: 15.62,
          y: 27.84,
          width: 74.29,
          height: 46.43,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect2: {
          x: 22.23,
          y: 74.27,
          width: 61.63,
          height: 78.51,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect3: {
          x: 24.62,
          y: 152.78,
          width: 55.16,
          height: 12.1,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect4: {
          x: 27.44,
          y: 164.88,
          width: 48.12,
          height: 48.12,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect5: {
          x: 46.85,
          y: 171.07,
          width: 9.29,
          height: 35.74,
          fill: "#010101" // Black color
        },
        rect6: {
          x: 31.1,
          y: 152.78,
          width: 16.88,
          height: 8.16,
          fill: "#474748" // Gray rectangle 1
        },
        rect7: {
          x: 57.27,
          y: 152.78,
          width: 18.29,
          height: 8.16,
          fill: "#474748" // Gray rectangle 2
        },
        polygon: {
          points: "24.62 27.84 24.62 13.2 33.35 6.73 73.59 6.73 81.47 13.77 81.47 27.84 24.62 27.84",
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        path1: {
          d: "M15.62,48.94L8.44,51.05s-6.33,0-2.95-9.28l10.13-5.91Z",
          stroke: "#231f20",
          strokeWidth: 5,
          strokeMiterlimit: 10,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect8: {
          x: 39.54,
          y: 213,
          width: 25.04,
          height: 139.86,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        rect9: {
          x: 75.56,
          y: 171.07,
          width: 8.3,
          height: 30.11,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "#010101" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          }
        },
        path2: {
          d: "M89.91,48.94l7.17,2.11s6.34,0,3-9.28L89.91,35.86Z",
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "rgba(1,1,1,0.8)" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "#010101" }
            ]
          },
          stroke: "#231f20",
          strokeWidth: 5,
          strokeMiterlimit: 10
        }
      },
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 80,
                y: 185
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: "top",
            attrs: {
              circle: {
                r: 6,
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }

};

const ToggleValveControl = joint.dia.HighlighterView.extend({
  UPDATE_ATTRIBUTES: ["open"],
  children: joint.util.svg/* xml */`
        <foreignObject width="100" height="50" >
            <div class="jj-switch" xmlns="http://www.w3.org/1999/xhtml">
                <div @selector="label" class="jj-switch-label" style=""></div>
                <button @selector="buttonOn" class="jj-switch-on">open</button>
                <button @selector="buttonOff" class="jj-switch-off">close</button>
            </div>
        </foreignObject>
    `,
  events: {
    "click button": "onButtonClick"
  },
  highlight: function(cellView) {
    this.renderChildren();
    const { model } = cellView;
    const { el, childNodes } = this;
    const size = model.size();
    const isOpen = model.get("open");
    el.setAttribute(
      "transform",
      `translate(${size.width / 2 - 50}, ${size.height + 10})`
    );
    childNodes.buttonOn.disabled = !isOpen;
    childNodes.buttonOff.disabled = isOpen;
    childNodes.label.textContent = model.attr("label/text");
  },
  onButtonClick: function(evt) {
    const { model } = this.cellView;
    const isOpen = model.get("open");
    model.set("open", !isOpen);
  }
});

const ControlValve = class ControlValve extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "ControlValve",
      size: {
        width: 60,
        height: 60
      },
      markup: joint.util.svg/* xml */`
          <rect @selector="stem" />
          <path @selector="control" />
          <ellipse @selector="body" />
          <rect @selector="coverFrame" />
          <path @selector="liquid" />
          <rect @selector="cover" />
          <text @selector="label" />
      `,
      open: 1,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        body: {
          rx: "calc(w / 2)",
          ry: "calc(h / 2)",
          cx: "calc(w / 2)",
          cy: "calc(h / 2)",
          stroke: "gray",
          strokeWidth: 2,
          fill: {
            type: "radialGradient",
            stops: [
              { offset: "80%", color: "white" },
              { offset: "100%", color: "gray" }
            ]
          }
        },
        liquid: {
          // We use path instead of rect to make it possible to animate
          // the stroke-dasharray to show the liquid flow.
          d: "M calc(w / 2 + 12) calc(h / 2) h -24",
          stroke: LIQUID_COLOR,
          strokeWidth: 24,
          strokeDasharray: "3,1"
        },
        cover: {
          x: "calc(w / 2 - 12)",
          y: "calc(h / 2 - 12)",
          width: 24,
          height: 24,
          stroke: "#333",
          strokeWidth: 2,
          fill: "#fff"
        },
        coverFrame: {
          x: "calc(w / 2 - 15)",
          y: "calc(h / 2 - 15)",
          width: 30,
          height: 30,
          stroke: "#777",
          strokeWidth: 2,
          fill: "none",
          rx: 1,
          ry: 1
        },
        stem: {
          width: 10,
          height: 30,
          x: "calc(w / 2 - 5)",
          y: -30,
          stroke: "#333",
          strokeWidth: 2,
          fill: "#555"
        },
        control: {
          d: "M 0 0 C 0 -30 60 -30 60 0 Z",
          transform: "translate(calc(w / 2 - 30), -20)",
          stroke: "#333",
          strokeWidth: 2,
          rx: 5,
          ry: 5,
          fill: "#666"
        },
        label: {
          text: "Valve",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(0.5*w)",
          y: "calc(h+10)",
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      ports: {
        groups: {
          pipes: {
            position: {
              name: "absolute",
              args: {
                x: "calc(w / 2)",
                y: "calc(h / 2)"
              }
            },
            markup: joint.util.svg`
                          <rect @selector="pipeBody" />
                          <rect @selector="pipeEnd" />
                      `,
            size: { width: 50, height: 30 },
            attrs: {
              portRoot: {
                magnetSelector: "pipeEnd"
              },
              pipeBody: {
                width: "calc(w)",
                height: "calc(h)",
                y: "calc(h / -2)",
                fill: {
                  type: "linearGradient",
                  stops: [
                    { offset: "0%", color: "gray" },
                    { offset: "30%", color: "white" },
                    { offset: "70%", color: "white" },
                    { offset: "100%", color: "gray" }
                  ],
                  attrs: {
                    x1: "0%",
                    y1: "0%",
                    x2: "0%",
                    y2: "100%"
                  }
                }
              },
              pipeEnd: {
                width: 10,
                height: "calc(h+6)",
                y: "calc(h / -2 - 3)",
                stroke: "gray",
                strokeWidth: 3,
                fill: "white"
              }
            }
          }
        },
        items: [
          {
            id: "left",
            group: "pipes",
            z: 0,
            attrs: {
              pipeBody: {
                x: "calc(-1 * w)"
              },
              pipeEnd: {
                x: "calc(-1 * w)"
              }
            }
          },
          {
            id: "right",
            group: "pipes",
            z: 0,
            attrs: {
              pipeEnd: {
                x: "calc(w - 10)"
              }
            }
          }
        ]
      }
    };
  }
};
const ControlValveView = joint.dia.ElementView.extend({
  presentationAttributes: joint.dia.ElementView.addPresentationAttributes({
    open: [OPEN_FLAG]
  }),

  initFlag: [joint.dia.ElementView.Flags.RENDER, OPEN_FLAG],

  framePadding: 6,

  liquidAnimation: null,

  confirmUpdate(...args) {
    let flags = joint.dia.ElementView.prototype.confirmUpdate.call(
      this,
      ...args
    );
    this.animateLiquid();
    if (this.hasFlag(flags, OPEN_FLAG)) {
      this.updateCover();
      flags = this.removeFlag(flags, OPEN_FLAG);
    }
    return flags;
  },

  updateCover() {
    const { model } = this;
    const opening = Math.max(0, Math.min(1, model.get("open") || 0));
    const coverEl = this.selectors["cover"];
    const coverFrameEl = this.selectors["coverFrame"];
    const frameWidth =
      Number(coverFrameEl.getAttribute("width")) - this.framePadding;
    const width = Math.round(frameWidth * (1 - opening));
    coverEl.animate(
      {
        width: [`${width}px`]
      },
      {
        fill: "forwards",
        duration: 200
      }
    );
  },

  animateLiquid() {
    if (this.liquidAnimation) return;
    const liquidEl = this.selectors["liquid"];
    this.liquidAnimation = liquidEl.animate(
      {
        // 24 matches the length of the liquid path
        strokeDashoffset: [0, 24]
      },
      {
        fill: "forwards",
        iterations: Infinity,
        duration: 3000
      }
    );
  },
});

const pump = class Pump extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "Pump",
      size: {
        width: 100,
        height: 100
      },
      power: 0,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        body: {
          rx: "calc(w / 2)",
          ry: "calc(h / 2)",
          cx: "calc(w / 2)",
          cy: "calc(h / 2)",
          stroke: "gray",
          strokeWidth: 2,
          fill: "lightgray"
        },
        label: {
          text: "Pump",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(0.5*w)",
          y: "calc(h+10)",
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        },
        rotorGroup: {
          transform: "translate(calc(w/2),calc(h/2))",
          event: "element:power:click",
          cursor: "pointer"
        },
        rotorFrame: {
          r: 40,
          fill: "#eee",
          stroke: "#666",
          strokeWidth: 2
        },
        rotorBackground: {
          r: 34,
          fill: "#777",
          stroke: "#222",
          strokeWidth: 1,
          style: {
            transition: "fill 0.5s ease-in-out"
          }
        },
        rotor: {
          // d: `M ${a} ${a} ${b} ${r} -${b} ${r} -${a} ${a} -${r} ${b} -${r} -${b} -${a} -${a} -${b} -${r} ${b} -${r} ${a} -${a} ${r} -${b} ${r} ${b} Z`,
          d: `M 0 0 V ${r} l ${-d} ${-l} Z M 0 0 V ${-r} l ${d} ${l} Z M 0 0 H ${r} l ${-l} ${d} Z M 0 0 H ${-r} l ${l} ${-d} Z`,
          stroke: "#222",
          strokeWidth: 3,
          fill: "#bbb"
        }
      },
      markup: joint.util.svg/* xml */`
            <ellipse @selector="body" />
            <g @selector="rotorGroup">
                <circle @selector="rotorFrame" />
                <circle @selector="rotorBackground" />
                <path @selector="rotor" />
            </g>
            <text @selector="label" />
        `,
      ports: {
        groups: {
          pipes: {
            position: {
              name: "line",
              args: {
                start: { x: "calc(w / 2)", y: "calc(h)" },
                end: { x: "calc(w / 2)", y: 0 }
              }
            },
            markup: joint.util.svg`
                            <rect @selector="pipeBody" />
                            <rect @selector="pipeEnd" />
                        `,
            size: { width: 80, height: 30 },
            attrs: {
              portRoot: {
                magnetSelector: "pipeEnd"
              },
              pipeBody: {
                width: "calc(w)",
                height: "calc(h)",
                y: "calc(h / -2)",
                fill: {
                  type: "linearGradient",
                  stops: [
                    { offset: "0%", color: "gray" },
                    { offset: "30%", color: "white" },
                    { offset: "70%", color: "white" },
                    { offset: "100%", color: "gray" }
                  ],
                  attrs: {
                    x1: "0%",
                    y1: "0%",
                    x2: "0%",
                    y2: "100%"
                  }
                }
              },
              pipeEnd: {
                width: 10,
                height: "calc(h+6)",
                y: "calc(h / -2 - 3)",
                stroke: "gray",
                strokeWidth: 3,
                fill: "white"
              }
            }
          }
        },
        items: [
          {
            id: "left",
            group: "pipes",
            z: 1,
            attrs: {
              pipeBody: {
                x: "calc(-1 * w)"
              },
              pipeEnd: {
                x: "calc(-1 * w)"
              }
            }
          },
          {
            id: "right",
            group: "pipes",
            z: 0,
            attrs: {
              pipeEnd: {
                x: "calc(w - 10)"
              }
            }
          }
        ]
        }

    }
  }

  get power() {
    return this.get("power") || 0;
  }

  set power(value) {
    this.set("power", value);
  }
};

const PumpControl = joint.dia.HighlighterView.extend({
  UPDATE_ATTRIBUTES: ["power"],
  tagName: "g",
  children: joint.util.svg/* xml */`
        <foreignObject width="20" height="20">
            <div class="jj-checkbox" xmlns="http://www.w3.org/1999/xhtml">
                <input @selector="input" class="jj-checkbox-input" type="checkbox" style="width: 14px; height: 14px; box-sizing: border-box; margin: 2px;"/>
            </div>
        </foreignObject>
    `,
  events: {
    "change input": "onChange"
  },
  attributes: {
    transform: "translate(5, 5)"
  },
  highlight: function(cellView) {
    this.renderChildren();
    this.childNodes.input.checked = Boolean(cellView.model.power);
  },
  onChange: function(evt) {
    this.cellView.model.power = evt.target.checked ? 1 : 0;
  }
});

const PumpView = joint.dia.ElementView.extend({
  presentationAttributes: joint.dia.ElementView.addPresentationAttributes({
    power: [POWER_FLAG]
  }),

  initFlag: [joint.dia.ElementView.Flags.RENDER, POWER_FLAG],

  powerAnimation: null,

  confirmUpdate(...args) {
    let flags = joint.dia.ElementView.prototype.confirmUpdate.call(
      this,
      ...args
    );
    if (this.hasFlag(flags, POWER_FLAG)) {
      this.togglePower();
      flags = this.removeFlag(flags, POWER_FLAG);
    }
    return flags;
  },

  getSpinAnimation() {
    let { spinAnimation } = this;
    if (spinAnimation) return spinAnimation;
    const rotorEl = this.selectors["rotor"];
    const keyframes = { transform: ["rotate(0deg)", "rotate(360deg)"] };
    spinAnimation = rotorEl.animate(keyframes, {
      fill: "forwards",
      duration: 1000,
      iterations: Infinity
    });
    this.spinAnimation = spinAnimation;
    return spinAnimation;
  },

  togglePower() {
    const { model } = this;
    this.getSpinAnimation().playbackRate = model.power;
  }
});

const liquidTank = class LiquidTank extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "LiquidTank",
      size: {
        width: 160,
        height: 300
      },
      markup: joint.util.svg/* xml */`
            <path @selector="legs"/>
            <rect @selector="body"/>
            <rect @selector="top"/>
            <text @selector="label" />
        `,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        legs: {
          fill: "none",
          stroke: "#350100",
          strokeWidth: 8,
          strokeLinecap: "round",
          d: "M 20 calc(h) l -5 10 M calc(w - 20) calc(h) l 5 10"
        },
        body: {
          stroke: "gray",
          strokeWidth: 4,
          x: 0,
          y: 0,
          width: "calc(w)",
          height: "calc(h)",
          rx: 120,
          ry: 10,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "gray" },
              { offset: "30%", color: "white" },
              { offset: "70%", color: "white" },
              { offset: "100%", color: "gray" }
            ]
          }
        },
        top: {
          x: 0,
          y: 20,
          width: "calc(w)",
          height: 20,
          fill: "none",
          stroke: "gray",
          strokeWidth: 2
        },
        label: {
          text: "Tank 1",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(w / 2)",
          y: "calc(h + 10)",
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 25   // Adjusted for size 50
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 50   // Adjusted for size 50
              }
            },
            attrs: {
              circle: {
                r: 2,  // Adjusted port circle radius for new size
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }

  get level() {
    return this.get("level") || 0;
  }

  set level(level) {
    const newLevel = Math.max(0, Math.min(100, level));
    this.set("level", newLevel);
  }
};
const LEVEL_FLAG = "LEVEl";

const panel = class Panel extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "Panel",
      size: {
        width: 100,
        height: 230
      },
      level: 0,
      attrs: {
        root: {
          magnetSelector: "panelBody"
        },
        panelBody: {
          x: 0,
          y: 0,
          width: "calc(w)",
          height: "calc(h)",
          rx: 1,
          ry: 1,
          fill: "lightgray",
          stroke: "gray",
          strokeWidth: 1
        },
        panelWindow: {
          // turn the panel over so that we can grow the liquid from the bottom
          // by increasing the height of the bar.
          transform: "translate(10, 10) rotate(180) translate(-40,-205)"
        },
        panelTicks: {
          transform: "translate(55, 15)",
          d: `M 0 0 h 8 M 0 ${step} h 8 M 0 ${step * 2} h 8 M 0 ${
            step * 3
          } h 8 M 0 ${step * 4} h 8 M 0 ${step * 5} h 8 M 0 ${
            step * 6
          } h 8 M 0 ${step * 7} h 8 M 0 ${step * 8} h 8 M 0 ${
            step * 9
          } h 8 M 0 ${step * 10} h 8`,
          fill: "none",
          stroke: "black",
          strokeWidth: 2,
          strokeLinecap: "round"
        },
        panelValues: {
          text: "100\n90\n80\n70\n60\n50\n40\n30\n20\n10\n0",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: 80,
          y: 10,
          lineHeight: step,
          fontSize: 14,
          fontFamily: "sans-serif"
        },
        frame: {
          width: 40,
          height: 200,
          rx: 1,
          ry: 1,
          fill: "none",
          stroke: "black",
          strokeWidth: 3
        },
        liquid: {
          x: 0,
          y: 0,
          width: 40,
          height: 0,
          stroke: "black",
          strokeWidth: 2,
          strokeOpacity: 0.2,
          fill: MIN_LIQUID_COLOR
        },
        glass: {
          x: 0,
          y: 0,
          width: 40,
          height: 200,
          fill: "blue",
          stroke: "none",
          fillOpacity: 0.1
        },
        label: {
          text: "Tank 1",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(w / 2)",
          y: "calc(h + 10)",
          fontSize: 20,
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      markup: joint.util.svg/* xml */`
            <rect @selector="panelBody"/>
            <path @selector="panelTicks"/>
            <text @selector="panelValues" />
            <g @selector="panelWindow">
                <rect @selector="glass"/>
                <rect @selector="liquid"/>
                <rect @selector="frame"/>
            </g>
      `
    };
  }
};
const PanelView = joint.dia.ElementView.extend({
  presentationAttributes: joint.dia.ElementView.addPresentationAttributes({
    level: [LEVEL_FLAG],
    color: [LEVEL_FLAG]
  }),

  initFlag: [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG],

  confirmUpdate(...args) {
    let flags = joint.dia.ElementView.prototype.confirmUpdate.call(
      this,
      ...args
    );
    if (this.hasFlag(flags, LEVEL_FLAG)) {
      this.updateLevel();
      flags = this.removeFlag(flags, LEVEL_FLAG);
    }
    return flags;
  },

  updateLevel() {
    const { model } = this;
    const level = Math.max(0, Math.min(100, model.get("level") || 0));
    const color = model.get("color") || "red";
    const liquidEl = this.selectors["liquid"];
    const windowEl = this.selectors["frame"];
    const windowHeight = Number(windowEl.getAttribute("height"));
    const height = Math.round((windowHeight * level) / 100);
    liquidEl.animate(
      {
        height: [`${height}px`],
        fill: [color]
      },
      {
        fill: "forwards",
        duration: 1000
      }
    );
  }
});

const SliderValveControl = joint.dia.HighlighterView.extend({
  UPDATE_ATTRIBUTES: ["open"],
  children: joint.util.svg/* xml */`
        <foreignObject width="100" height="70" >
            <div class="jj-slider" xmlns="http://www.w3.org/1999/xhtml">
                <div @selector="label" class="jj-slider-label" style="">Valve 4</div>
                <input @selector="slider" class="jj-slider-input" type="range" min="0" max="100" step="25" style="width:100%;"/>
                <output @selector="value" class="jj-slider-output"></output>
            </div>
        </foreignObject>
    `,
  events: {
    "input input": "onInput"
  },
  highlight: function(cellView) {
    const { name = "" } = this.options;
    const { model } = cellView;
    const size = model.size();
    if (!this.childNodes) {
      // Render the slider only once to allow the user to drag it.
      this.renderChildren();
      this.childNodes.slider.value = model.get("open") * 100;
    }
    this.el.setAttribute(
      "transform",
      `translate(${size.width / 2 - 50}, ${size.height + 10})`
    );
    this.childNodes.label.textContent = name;
    this.childNodes.value.textContent = this.getSliderTextValue(
      model.get("open")
    );
  },
  getSliderTextValue: function(value = 0) {
    if (value === 0) {
      return "Closed";
    }
    if (value === 1) {
      return "Open";
    }
    return `${value * 100}% open`;
  },
  onInput: function(evt) {
    this.cellView.model.set("open", Number(evt.target.value) / 100);
  }
});

const conicTank = class ConicTank extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "ConicTank",
      size: {
        width: 160,
        height: 100
      },
      attrs: {
        root: {
          magnetSelector: "body"
        },
        body: {
          stroke: "gray",
          strokeWidth: 4,
          x: 0,
          y: 0,
          width: "calc(w)",
          height: "calc(h)",
          rx: 120,
          ry: 10,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "0%", color: "gray" },
              { offset: "30%", color: "white" },
              { offset: "70%", color: "white" },
              { offset: "100%", color: "gray" }
            ]
          }
        },
        top: {
          x: 0,
          y: 20,
          width: "calc(w)",
          height: 20,
          fill: "none",
          stroke: "gray",
          strokeWidth: 2
        },
        bottom: {
          d: "M 0 0 L calc(w) 0 L calc(w / 2 + 10) 70 h -20 Z",
          transform: "translate(0, calc(h - 10))",
          stroke: "gray",
          strokeLinejoin: "round",
          strokeWidth: 2,
          fill: {
            type: "linearGradient",
            stops: [
              { offset: "10%", color: "#aaa" },
              { offset: "30%", color: "#fff" },
              { offset: "90%", color: "#aaa" }
            ],
            attrs: {
              gradientTransform: "rotate(-10)"
            }
          }
        },
        label: {
          text: "Tank 2",
          textAnchor: "middle",
          textVerticalAnchor: "bottom",
          x: "calc(w / 2)",
          y: -10,
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        }
      },
      markup: joint.util.svg/* xml */`
          <path @selector="bottom"/>
          <rect @selector="body"/>
          <rect @selector="top"/>
          <text @selector="label" />
      `,
      ports: {
        groups: {
          "in": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 25   // Adjusted for size 50
              }
            },
            attrs: {
              magnet: true,
              z: -1
            }
          },
          "out": {
            position: {
              name: "absolute",
              args: {
                x: 25,  // Adjusted for size 50
                y: 50   // Adjusted for size 50
              }
            },
            attrs: {
              circle: {
                r: 2,  // Adjusted port circle radius for new size
                magnet: true,
                stroke: "#000",
                fill: "#FFF"
              }
            }
          }
        }
      },
      items: [
        {
          id: "left",
          group: "in",
          z: 0
        },
        {
          id: "right",
          group: "out",
          z: 0
        }
      ]
    };
  }
};

const pipeItem = class Pipe extends joint.dia.Link {
  defaults() {
    return {
      ...super.defaults,
      type: "PipePort",
      z: -1,
      router: { name: "rightAngle" },
      size: {
        height: 200,
        width: 200,
      },
      flow: 1,
      attrs: {
        liquid: {
          connection: true,
          stroke:'#1f69da',
          strokeWidth: 10,
          strokeLinejoin: "round",
          strokeLinecap: "square",
          strokeDasharray:'10,20'
        },
        line: {
          connection: true,
          stroke: {
            type: "linearGradient",
            stops: [
              { offset: "50%", color: "rgb(179,176,176)" },
              { offset: "0%", color: "white" },
              { offset: "50%", color: "rgb(179,176,176)" }
            ]
          },
          strokeWidth: 10,
          strokeLinejoin: "round",
          strokeLinecap: "round"
        },
        outline: {
          connection: true,
          stroke: {
            type: "linearGradient",
            stops: [
              { offset: "100%", color: "rgba(141,140,140,0.84)" },
              { offset: "50%", color: "white" },
              { offset: "100%", color: "rgba(141,140,140,0.84)" }
            ]
          },
          strokeWidth: 12,
          strokeLinejoin: "round",
          strokeLinecap: "round"
        }
      },
      markup: joint.util.svg/* xml */`
            <path @selector="outline" fill="none"/>
            <path @selector="line" fill="none"/>
            <path @selector="liquid" fill="none"/>
        `
    };
  }
};

const PipeView = joint.dia.LinkView.extend({
  presentationAttributes: joint.dia.LinkView.addPresentationAttributes({
    flow: [FLOW_FLAG]
  }),

  initFlag: [...joint.dia.LinkView.prototype.initFlag, FLOW_FLAG],

  flowAnimation: null,

  confirmUpdate(...args) {
    let flags = joint.dia.LinkView.prototype.confirmUpdate.call(this, ...args);
    if (this.hasFlag(flags, FLOW_FLAG)) {
      this.updateFlow();
      flags = this.removeFlag(flags, FLOW_FLAG);
    }
    return flags;
  },

  getFlowAnimation() {
    let { flowAnimation } = this;
    if (flowAnimation) return flowAnimation;
    const liquidEl = this.selectors["liquid"];
    // stroke-dashoffset = sum(stroke-dasharray) * n;
    // 90 = 10 + 20 + 10 + 20 + 10 + 20
    const keyframes = { strokeDashoffset: [90, 0] };
    flowAnimation = liquidEl.animate(keyframes, {
      fill: "forwards",
      duration: 1000,
      iterations: Infinity
    });
    this.flowAnimation = flowAnimation;
    return flowAnimation;
  },

  updateFlow() {
    const { model } = this;
    const flowRate = model.get("flow") || 0;
    this.getFlowAnimation().playbackRate = flowRate;
    const liquidEl = this.selectors["liquid"];
    liquidEl.style.stroke = flowRate === 0 ? "#ccc" : "";
  }
});

const Circle = joint.shapes.standard.Circle.extend({
  name: "Circle",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 100 }
  }
});

const Cylinder = joint.shapes.standard.Cylinder.extend({
  name: "Cylinder",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 200 }
  }
});

const Rectangle = joint.shapes.standard.Rectangle.extend({
  name: "Rectangle",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 150 }
  }
});

const Ellipse = joint.shapes.standard.Ellipse.extend({
  name: "Ellipse",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 150 }
  }
});

const Polygon = joint.shapes.standard.Polygon.extend({
  name: "Polygon",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 150 }
  }
});

const TextBlock = joint.shapes.standard.TextBlock.extend({
  name: "TextBlock",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 150 },
    label: {
      text: "TextBlock"
    }
  }
});

const editButton = joint.elementTools.Button.extend({
  name: "edit-button",
  options: {
    focusOpacity: 0.5,
    rotate: true,
    useModelGeometry: true,
    distance: 60,
    action: function(evt) {
      // alert("View id: " + this.id + "\n" + "Model id: " + this.model.id);
    },
    markup: [
      {
        tagName: "circle",
        selector: "button",
        attributes: {
          r: 7,
          fill: "#dfd50c",
          cursor: "pointer"
        }
      },
      {
        tagName: "path",
        selector: "icon",
        attributes: {
          d: "M -2 4 2 4 M 0 3 0 0 M -2 -1 1 -1 M -1 -4 1 -4",
          fill: "none",
          stroke: "#FFFFFF",
          "stroke-width": 2,
          "pointer-events": "none"
        }
      }
    ],
    x: 0,
    y: "100%",
    offset: {
      x: 0,
      y: 0
    }
  }
});

const ResizeTool = joint.elementTools.Control.extend({
  children: [
    {
      tagName: "image",
      selector: "handle",
      attributes: {
        cursor: "pointer",
        width: 20,
        height: 20,
        x: 2,
        y: -50,
        offset: {
          x: 0,
          y: 0
        },
        "xlink:href":
          "https://assets.codepen.io/7589991/8725981_image_resize_square_icon.svg"
      }
    },
    {
      tagName: "rect",
      selector: "extras",
      attributes: {
        "pointer-events": "none",
        fill: "none",
        stroke: "#33334F",
        "stroke-dasharray": "2,4",
        rx: 5,
        ry: 5
      }
    }
  ],
  getPosition: function(view) {
    const model = view.model;
    const { width, height } = model.size();
    return { x: width, y: height };
  },
  setPosition: function(view, coordinates) {
    const model = view.model;
    model.resize(
      Math.max(coordinates.x - 10, 1),
      Math.max(coordinates.y - 10, 1)
    );
  }
});
const RotateTool = joint.elementTools.Control.extend({
  children: [
    {
      tagName: "image",
      selector: "handle",
      attributes: {
        cursor: "pointer",
        width: 20,
        height: 20,
        "xlink:href": "https://cdn-icons-png.freepik.com/512/73/73450.png"
      }
    }
  ],
  options: {
    x: 0,
    y: 0
  },
  getPosition: function(view) {
    const model = view.model;
    const { width, height } = model.size();
    return { x: width, y: height };
  },
  setPosition: function(view, coordinates) {
    const model = view.model;
    model.rotate(0.5);
  }
});

const base64Creator = function(fileInput) {
  return new Promise((resolve, reject) => {
    if (!fileInput.target.files.length) {
      reject(new Error("No files selected."));
      return;
    }

    const file = fileInput.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const base64 = btoa(e.target.result);
      const fileInfo = {
        base64: "data:" + file.type + ";base64," + base64,
        filetype: file.type,
        size: file.size,
        filename: file.name
      };
      resolve(fileInfo);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};
const Image = joint.shapes.standard.Image.extend({
  name: "Image",
  options: {
    position: { x: 1400, y: 210 },
    size: { width: 100, height: 80 }
  }

});

export {
  pump,
  handValve,
  liquidTank,
  conicTank,
  panel,
  pipeItem,
  pipePort,
  PipeView,
  PumpView,
  PanelView,
  ControlValve,
  SliderValveControl,
  ToggleValveControl,
  ControlValveView,
  Join,
  PumpControl,
  Zone,
  Image,
  editButton,
  Circle,
  Cylinder,
  Rectangle,
  Ellipse,
  Polygon,
  TextBlock,
  portRect,
  circlePort,
  customPipe,
  ResizeTool,
  removeMarkup,
  RotateTool,
  editLinkMarkup,
  base64Creator,
  artBoard,
  heightMeter,
  pool,
  Pomps,
  ZoneReverse,
  flowMeter
};
