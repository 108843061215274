<template>
  <div :dir="dict.dir[lang]" :class="{'text-end':lang=='per', 'text-start':lang=='eng'}">
    <div>
      <b-container fluid="md">
        <b-row class="my-5">
          <b-col cols="1"></b-col>
          <b-col cols="10" sm>
            <b-card no-body>
              <b-card-body>
                <div style="display: flex; align-items: center; flex-wrap: wrap;">
                  <h4 style="flex: 90%;">{{ title }}</h4>
                  <b-button @click="handleRegister" type="submit" variant="outline-success" class="mr-2"
                            style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                    <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1"
                            aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button @click="onReset" type="reset" variant="outline-danger" class="mr-2"
                            style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                    <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1"
                            aria-hidden="true"></b-icon>
                  </b-button>
                </div>
                <div class="row mx-auto" id="slave_submits">
                  <div class="col-md-6 col-12 my-3">
                    <ejs-textbox
                      :value="slave.name"
                      :placeholder="dict.name[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      :minLength="3"
                      :maxLength="20"
                      floatLabelType="Auto"
                      v-model="slave.name"
                    ></ejs-textbox>
                  </div>
                  <div class="col-md-6 col-12 my-3">
                    <ejs-textbox
                      :value="slave.Id"
                      :placeholder="dict.Id[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      :min="0"
                      type="number"
                      :max="255"
                      floatLabelType="Auto"
                      v-model="slave.Id"
                    ></ejs-textbox>

                  </div>
                  <div class="col-md-6 col-12 my-3">
                    <ejs-dropdownlist
                      :dataSource="typeList"
                      :value="slave.type"
                      floatLabelType="Auto"
                      :fields="{ text: lang, value: 'value' }"
                      :placeholder="dict.type[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      @change="(val) => {slave.type = val.value}"
                      :enabled="type != 'Edit'"
                    ></ejs-dropdownlist>

                  </div>
                  <div v-if="slave.type==='slave_S'" class="col-md-6 col-12 my-3">
                    <ejs-dropdownlist
                      :dataSource="regTypeList"
                      :value="slave.regType"
                      floatLabelType="Auto"
                      :fields="{ text: lang, value: 'value' }"
                      :placeholder="dict.regType[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      @change="(val) => {slave.regType = val.value}"
                      :enabled="type != 'Edit'"
                    ></ejs-dropdownlist>
                  </div>
                  <div v-if="slave.type==='slave_S'" class="col-md-6 col-12 my-3">
                    <ejs-textbox
                      :value="slave.regSAdd"
                      :placeholder="dict.start[lang]"
                      :enableRtl="lang === 'per'"
                      :required="true"
                      :min="0"
                      :enabled="type != 'Edit'"
                      type="number"
                      :max="999"
                      floatLabelType="Auto"
                      v-model="slave.regSAdd"
                    ></ejs-textbox>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "slaveEdit",
  data() {
    return {
      slave: {
        parent: "",
        name: "",
        Id: "",
        id: "",
        type: null,
        regSAdd: "",
        regType: null
      },
      dict: {
        Id: { per: "آیدی", eng: "Id" },
        regType: { per: "نوع رجیستر", eng: "Type of Register" },
        start: { per: "آدزس شروع", eng: "Start Address" },
        enterId: { per: "آیدی را وارد کنید", eng: "Enter Id" },
        regPl: { per: "آدزس شروع را وارد کنید", eng: "Enter Start Address" },
        enterName: { per: "نام را وارد کنید", eng: "Enter name" },
        enterAddress: { per: "نام را وارد کنید", eng: "Enter address" },
        enterType: { per: "نوع را وارد کنید", eng: "Enter type" },
        enterStartup_delay: { per: "تاخیر در شروع را وارد کنید", eng: "Enter startup delay" },
        name: { per: "نام", eng: "name" },
        address: { per: "آدرس", eng: "address" },
        type: { per: "نوع", eng: "type" },
        "Edit Slave": { per: "ویرایش اسلیو", eng: "Edit Slot" },
        "َAdd Slave": { per: "ایجاد اسلیو", eng: "َAdd Slot" },
        startup_delay: {
          per: "تاخیر در شروع", eng: "startup delay"
        },
        dir: { eng: "ltr", per: "rtl" }
      },
      submitted: false,
      successful: false,
      message: "",
      title: "",

      typeList: [{ eng: "Select One",per: "یک مورد انتخاب کنید", value: null },
        { per: "Extendable I/O module",eng: "Extendable I/O module", value: "slave_E" },
        { per: "Sensor",eng: "Sensor", value: "slave_S" },
        { per: "HVAC Controller",eng: "HVAC Controller", value: "slave_SC" }
      ],
      regTypeList: [{ eng: "Select One",per: "یک مورد انتخاب کنید", value: null },
        { per: "Holding Register (4x)",eng: "Holding Register (4x)", value: "4" },
        { per: "Input Register (3x)",eng: "Input Register (3x)", value: "3" }
      ]
    };
  },
  computed: {
    type() {
      return this.$store.state.chpge.type;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    db() {
      return this.$store.state.chpge.db;
    },
    index() {
      return this.$store.state.chpge.index;
    }
  },
  methods: {
    initModal() {
      this.title = this.type + " Slave";

      if (this.type === "Add") {
        this.slave.parent = { ptype: this.db.ptype, pid: this.db.pid };
      } else if (this.type === "Edit") {
        this.slave.name = this.db.name;
        this.slave.Id = this.db.Id;
        this.slave.id = this.db.id;
        this.slave.type = this.db.type;
        this.slave.regSAdd = this.db.regSAdd;
        this.slave.regType = this.db.regType;
      }
    },
    onReset() {
      this.slave.name = null;
      this.slave.Id = null;
      this.slave.id = null;
      this.$store.dispatch("chpge/changePage", "controllerSettings");
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#slave_submits").validate();
      if (isValid) {
        if (this.type === "Add") {
          this.$store.dispatch("data/createSlave", this.slave).then(
            data => {

              this.message = data.message;
              this.successful = true;
              // alert(this.message);
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        } else if (this.type === "Edit") {

          this.$store.dispatch("data/editSlave", this.slave).then(
            data => {

              this.message = data.message;
              this.successful = true;
              //alert(this.message)
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      }

    }
  },
  mounted() {
    this.initModal();
  }
};
</script>
