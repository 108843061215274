<template>
  <div ref="mainCont" style="height: 100%; width: 100%" :id="'proc'+plotId">
    <div class="card-header" style="min-height: 40px">
      <div class="row">
        <div class="col-8">
          <p class="mb-0">
            process flow diagram
          </p>
        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="edit" @click="editWidget">
            <i><font-awesome-icon icon="fas fa-pencil" /></i>
          </span>
        </div>
        <div class="col-2 text-center" v-if="editable">
          <span class="remove" @click="removeWidget">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
          </span>
        </div>
      </div>
    </div>

    <div
      ref="mainCont"
      class="card-body p-2"
      style="height: calc(100% - 42px); width: 100%"
    >
      <div>
        <show-process :id="plotId" :new-height="200" new-width="100%" ref="crProcess" v-if="createProcess" />
      </div>
    </div>

    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li
                v-for="(tab, index) in tabList"
                :key="index"
                class="nav-item"
                @click="activeTab = tab"
              >
                <p
                  class="nav-link"
                  :class="{ active: activeTab === tab }"
                  :id="tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tab1"
                  aria-selected="true"
                >
                  {{ dict[tab][lang] }}
                </p>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3" style="overflow: hidden">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div class="h-100">
                  <div fluid class="container-fluid p-0 h-100">
                    <b-table
                      ref="table1"
                      :items="processList"
                      :fields="groupFields"
                      thead-class="hidden_header"
                      responsive="sm"
                      small
                      @row-clicked="rowSelect"
                    >
                    </b-table>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      id="modal-widget-pick-map"
      size="lg"
      centered
      header-bg-variant="warning"
      header-text-variant="dark"
      header-border-variant="primary"
      body-bg-variant="light"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initPickMapModal"
      @hidden="resetPickMapModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ pickMap.modalTitle }}</h5>
        <i
        >
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="pickMapHandleClose"
          />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!-- <b-form-group>
              <b-form-radio-group
                  v-model="type"
                  class="d-flex"
                  style="justify-content:space-around"
              >
                  <b-form-radio value="data">Data</b-form-radio>
                  <b-form-radio value="config" class="ml-5">Config</b-form-radio>
              </b-form-radio-group>
          </b-form-group> -->

          <b-card>
            <b-row align-h="between" class="mb-2">
              <b-col lg="4" sm="6" md="5" class="my-1">
                <b-form-group label-for="filter-input">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="pickMap.filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!pickMap.filter"
                        @click="pickMap.filter = ''"
                      >Clear
                      </b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :items="pickMap.items"
              :fields="pickMap.fields"
              :current-page="pickMap.currentPage"
              :per-page="pickMap.perPage"
              :filter="pickMap.filter"
              :filter-included-fields="pickMap.filterOn"
              :sort-by.sync="pickMap.sortBy"
              :sort-desc.sync="pickMap.sortDesc"
              :sort-direction="pickMap.sortDirection"
              stacked="md"
              show-empty
              small
              hover
            >
            </b-table>

            <b-row align-h="between">
              <b-col lg="3" sm="4" md="4" class="my-1">
                <b-form-group label-for="per-page-select">
                  <b-form-select
                    style="width: 100%"
                    id="per-page-select"
                    v-model="pickMap.perPage"
                    :options="pickMap.pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col lg="7" sm="8" md="8" class="my-1">
                <b-pagination
                  v-model="pickMap.currentPage"
                  :total-rows="pickMap.totalRows"
                  :per-page="pickMap.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import L from "leaflet";
import ShowProcess from "@/components/processFlowComponents/crud/showProces.vue";

export default Vue.extend({
  name: "widgetProcess",
  components: { ShowProcess },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number
  },
  data() {
    return {
      activeTab: "data", // Set the initial active tab
      tabList: ["data"],
      createProcess: false,
      mapinit: false,

      hhh: 300,

      modalTitle: "Edit Chart",
      myData: {
        datas: [],
        attribute: {
          center: [0, 0],
          minZoom: 1,
          maxZoom: 25,
          maxNativeZoom: 22,
          tileId: 1,
          viewZoom: 18,
          mapHeight: 100,
          mapWidth: 100
        }
      },
      processList: [],
      groupFields: [
        { key: "name", label: "" },
        { key: "modify", label: "" }
      ],

      myDataTmp: { datas: [] },

      activeZone: null,
      carousel: false,
      schedule: false,
      group: false,
      control: false,
      carouselData: [],
      levels: null,
      markers: [],
      mymap: [],
      popup: L.popup(),

      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: ""
      },

      tileIdList: [
        { text: "Select One", value: null },
        { text: "outdoors", value: 0 },
        { text: "streets", value: 1 },
        { text: "dark", value: 2 },
        { text: "light", value: 3 },
        { text: "satellite", value: 4 },
        { text: "satellite streets", value: 5 },
        { text: "navigation day", value: 6 },
        { text: "navigation night", value: 7 }
      ],

      tiles: [
        "outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"
      ],

      equipments: [],

      pickMap: {
        to: "",
        type: "data",
        items: [],
        fields: [
          {
            key: "floorTitle",
            label: "name",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "_id", label: "id", sortable: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "name",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        filterData: {},
        modalTitle: "select Map"
      },

      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        basic: { eng: "basic", per: "تنظیمات پایه" }
      },

      submitted: false,

      successful: false,
      message: "null"
    };
  },
  methods: {
    rowSelect(e) {
      this.createProcess = true;
      this.$store.commit("chpge/changePageStatus", {
        type: "Edit",
        index: e._id
      });
      this.myData.datas.push({ type: "proccessflow", id: e._id, name: e.name });
      this.$bvModal.hide("modal-widget-pick-map");
    },
    initModal: function() {
      // this.$refs.table1.refresh();
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    removeWidget() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this widget")
        .then((value) => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", {
              task: "rmDashItem",
              item: this.plotId
            });
          }
        })
        .catch((err) => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    addItem: function(type) {
      // this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:this.plotId, filter:{dataType:['Number'], usedBy:[],parents:[],archive:null}})
      this.$root.$emit("bv::show::modal", "modal-widget-pick-map");
    },
    initPickMapModal: function() {
      this.getAllMaps();
    },
    resetPickMapModal: function() {
      this.pickMap.items = [];
    },
    pickMapHandleClose() {
      this.$bvModal.hide("modal-widget-pick-map");
    },
    getList() {
      this.$store.dispatch("getAllProcess").then((res) => {
        this.processList = res;
      });
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              alert(this.message);
            }
          );
        }
      });
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  mounted() {
    this.getList();
    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      this.$store.commit("chpge/changePageStatus", {
        type: "Edit",
        index: this.data.datas[0].id
      });
      this.createProcess = true;
    }
  }
});
</script>

<style scoped></style>
