<template>
  <b-modal
    id="modal-new-indoor-map"
    size="xl"
    centered
    header-border-variant="primary"
    header-class="my-modal-header "
    no-close-on-esc
    no-close-on-backdrop
    @shown="initModal"
    :dir="dict.dir[lang]"
    @hidden="resetModal"
    @cancel="showMsgBoxTwo"
  >
    <template #modal-header>
      <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
        <div>
          <h5>{{ dict.uploadIndoorMap[lang] }}</h5>
        </div>
        <div>
          <i class="reset mx-2" @click="addMarkers">
            <font-awesome-icon icon="fas fa-plus-square" />
          </i>

          <b-button variant="primary" style="border-radius: unset!important;" @click="mapSetting">{{ dict.edit[lang]
            }}
          </b-button>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
        <div class="d-flex mx-2" style="gap: 10px">
          <b-button variant="secondary" @click="showMsgBoxTwo">{{ dict.close[lang] }}</b-button>
        </div>
        <div class="d-flex" style="gap: 10px">
          <b-button variant="primary" @click="editLayer">{{ dict.ok[lang] }}</b-button>
        </div>
      </div>
    </template>
    <div id="mapContainer" class="mymap"></div>
    <mapZoneSetting />
    <mapSetting />
    <marker-creator ref="createMarker" :type="modalState" :lat-lng="thePosition" :information="myIcon" />
  </b-modal>

</template>

<script>
/* eslint-disable */
import L from "leaflet";
import mapZoneSetting from "./mapZoneSetting.vue";
import mapSetting from "./mapSetting.vue";
import MarkerCreator from "@/components/markerCreator.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  name: "mapManager",
  components: {
    MarkerCreator,
    mapZoneSetting,
    mapSetting
  },
  props: {
    plotId: String,
    data: Object
  },
  data() {
    return {
      currentId: "",
      markerList: [],
      thePosition: [],
      marker: null,
      levels: null,
      markers: [],
      mymap: [],
      popup: L.popup(),

      modalState: "",
      to: "",
      imap: {},
      myData: {
        datas: [],
        attribute: {}
      },
      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: 14
      },
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "map manager", per: "مدیریت نقشه ها" },
        confirmText: { eng: "Are you sure to close this window?", per: "آیا از بستن این پنجره اطمینان دارید؟" },
        uploadIndoorMap: { eng: "marker layers", per: "ساخت لایه مارکرها" },
        indoorMap: { eng: "map file", per: "فایل نقشه" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        close: { eng: "Close", per: "بستن" },
        ok: { eng: "Ok", per: "تایید" },
        cancel: { per: "لغو", eng: "Cancel" },
        edit: { eng: "View map settings", per: "تنظیمات نمایش نقشه" },
        editMarker: { eng: "Edit", per: "ویرایش" },
        delMarker: { eng: "Delete", per: "حذف" }
      },
      myIcon: {},
      icons: []
    };
  },
  methods: {
    showMsgBoxTwo() {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.confirmTitle[this.lang],
        locale: this.lang,
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.confirmText[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$nextTick(() => {
              this.$bvModal.hide("modal-new-indoor-map");
            });
            this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
            this.markerList = [];
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    setupLeafletMap: function() {
      this.mymap = L.map("mapContainer");

      this.createMap();
      if (this.myData.datas.length > 0) {
        this.getfloorMap(this.myData.datas[0].id);
      }
    },
    zoneClick: function(e) {
      this.$store.dispatch("panel/clickonMapSett", { _id: e.target.options._id, latlng: e.latlng }).then(
        data => {
          this.$root.$emit("bv::show::modal", "modal-2");
        },
        error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          alert(message);
        }
      );
    },
    editLayer() {
      this.$store.dispatch("data/editFloor", {
        _id: this.currentId,
        details: {
          map: this.map,
          markers: this.markerList
        }
      }).then((res) => {
        this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
        this.$nextTick(() => {
          this.$bvModal.hide("modal-new-indoor-map");
        });
      });
    },
    renderMarkers() {
      const markerFounded = this.markerFinder();
      markerFounded.forEach(item => {
        this.mymap.removeLayer(item);
      });

      const markers = [];
      this.markerList.forEach((item) => {

        markers.push(L.marker([item.properties.lat, item.properties.lng], {
          icon: L.icon(item.constantIcon.properties.icon),
          draggable: false
        }).addTo(this.mymap).on("click", (e) => {
        }).bindPopup(`
        <div class="d-flex">
          <i class="text-success mx-2 fas fa-plus-square" id="editBtn-${item.id}" data-id="${item.id}">
          ${this.dict.editMarker[this.lang]}
          </i>
          <i class="reset mx-2 fas fa-plus-square" id="deleteBtn-${item.id}" data-id="${item.id}">
           ${this.dict.delMarker[this.lang]}
          </i>
          </div>

        `).on("popupopen", () => {
          document.getElementById(`editBtn-${item.id}`).addEventListener("click", (e) => {
            const markerId = e.target.dataset.id;
            let current = this.markerList.find(item => item.id == markerId);
            this.$store.dispatch("chpge/child2child", {
              task: "chosenMarker",
              item: {
                id: markerId,
                name: current.name,
                properties: current.properties,
                movable: current.movable,
                constantIcon: current.constantIcon,
                variableIcon: current.variableIcon,
                onClick: current.onClick
              }

            });
            this.thePosition = current.pos;
            this.modalState = "edit";
            this.$bvModal.show("create-marker");
          });

          // Attach click event listener to the delete button
          document.getElementById(`deleteBtn-${item.id}`).addEventListener("click", (e) => {
            const markerId = e.target.dataset.id;
            this.deleteMarker(markerId); // Implement your marker deletion logic here
          });
        }));

      });

    },
    addMarkers: function(e) {
      this.getMouseCoordinate();
      this.myIcon = {
        iconSize: [50, 50],
        draggable: true
      };
      this.thePosition = this.myData.attribute.center;
      var myIcon = L.icon({
        iconUrl: "https://static.vecteezy.com/system/resources/thumbnails/039/400/934/small_2x/3d-rendering-realistic-location-map-pin-gps-pointer-markers-gps-location-symbol-maps-and-navigation-apps-red-geolocation-markers-placemark-icons-cartography-and-traveler-interest-symbols-png.png",
        iconSize: [50, 50],
        iconAnchor: [30, 50],
        popupAnchor: [0, -30],
        shadowAnchor: [0, 0]
      });
      const pop = `
      <p>lat:  ${this.thePosition[0]}</p>
       <p>lng:  ${this.thePosition[1]}</p>
      `;

      this.marker = L.marker(this.thePosition, {
        icon: myIcon,
        draggable: true
      }).addTo(this.mymap).bindPopup(pop);


      this.marker.on("dragend", (e) => {
        this.thePosition = [e.target.getLatLng().lat.toFixed(4), e.target.getLatLng().lng.toFixed(4)];
      });
      // const markerId = e.target.dataset.id;
      // let current = this.markerList.find(item => item.id == markerId);
      //
      // this.$store.dispatch("chpge/parent2child", {
      //   task: "chosenMarker",
      //   item: {
      //     id: markerId,
      //     icon: current.icon,
      //     data: current.data,
      //     pos: current.pos
      //   }
      //
      // });
      // this.thePosition = current.pos;
      // this.modalState = "edit";
      // this.$bvModal.show("create-marker");
      // });
      this.marker.on("click", (e) => {
        this.markerList.forEach((x) => {
          if (x.icon == e.target) {
            this.$store.commit("data/updateItemInList", { id: x.id });
            this.$store.dispatch("chpge/child2child", {
              task: "chosenMarker",
              item: {
                id: x.id,
                icon: x.icon,
                data: x.data,
                pos: x.pos,
                shapes: x.shapes,
                draggingItems: x.draggingItems,
                popupTitle: x.popupTitle
              }

            });
            this.thePosition = x.pos;
          }
        });
        this.modalState = "edit";
        this.$bvModal.show("create-marker");
      });
      this.$store.commit("data/getCurrentmarker", this.marker.options.icon.options);
      // this.$bvModal.show("create-marker");

    },
    markerFinder() {
      let allMarkers = [];
      this.mymap.eachLayer(function(layer) {
        if (layer instanceof L.Marker) {
          allMarkers.push(layer);
        }
      });
      return allMarkers;
    },
    deleteMarker(markerId) {
      let allMarkers = this.markerFinder();
      let markerToDelete = this.markerList.find(item => item.id == markerId);
      if (markerToDelete && allMarkers.length > 0) {
        allMarkers.forEach((item) => {
          if (item._latlng.lat == markerToDelete.properties.lat && item._latlng.lng == markerToDelete.properties.lng) {
            this.mymap.removeLayer(item);
            this.markerList.splice(this.markerList.indexOf(markerToDelete), 1);
          }
        });
      }
    },
    createMap: function() {
      this.map.center = [32.6524, 51.6746];
      this.map.minZoom = this.myData.attribute.minZoom;
      this.map.maxZoom = this.myData.attribute.maxZoom;
      this.map.maxNativeZoom = this.myData.attribute.maxNativeZoom;
      this.map.tileId = this.myData.attribute.tileId;
      this.map.viewZoom = this.myData.attribute.viewZoom;

      this.mymap.setView([32.6524, 51.6746], this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      // var mapOptions = {
      //   minZoom: this.map.minZoom,
      //   maxZoom: this.map.maxZoom,
      //   maxNativeZoom: this.map.maxNativeZoom,
      //   attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
      //   id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //   accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      // };


      L.tileLayer.wms("https://name.isfahan.ir/saeeserver/wms", {
        layers: "monam:boostan,monam:roads,monam:water",
        format: "image/png",
        transparent: true,
        version: "1.3.0",
        tiled: true,
        crs: L.CRS.EPSG32639,
        uppercase: true,
        styles: "",
        attribution: "Map data © <source>"
      }).addTo(this.mymap);
      //
      // L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
      //   layers: 'TOPO-OSM-WMS',
      // }).addTo(this.mymap);


      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);
      // L.tileLayer("http://192.168.2.22:8080/tile/{z}/{x}/{y}.png", mapOptions).addTo(this.mymap);

      if (this.markerList.length > 0) {
        this.renderMarkers();
      }

    },
    initModal() {
      this.setupLeafletMap();
    },
    resetModal() {
      this.levels = null;
      this.markers = [];
      this.mymap = [];
      this.to = "";
      this.imap = {};
      this.map = {};
      this.currentId = "";
      this.markerList = [];

      this.$nextTick(() => {
        this.$bvModal.hide("modal-new-indoor-map");
      });
    },
    mapSetting: function(e) {
      this.$store.dispatch("chpge/parent2child", {
        task: "markerSetting",
        item: this.myData.attribute,
        mode: "new"
      });
      this.$root.$emit("bv::show::modal", "modal-3");
    },
    getMouseCoordinate() {
      this.mymap.on("mousemove", (e) => {
        this.thePosition = [(e.latlng.lat).toFixed(4), (e.latlng.lng).toFixed(4)];
        this.marker.setLatLng(this.thePosition);
      });

      this.mymap.on("click", (e) => {
        console.log(this.thePosition);
        this.thePosition = [(e.latlng.lat).toFixed(4), (e.latlng.lng).toFixed(4)];
        this.mymap.off("mousemove");
        this.modalState = "new";
        this.$bvModal.show("create-marker");
        this.mymap.off("click");
      });
    }
  },
  computed: {
    updatePage() {
      return this.$store.state.chpge.reload;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child(newValue) {
      if (newValue.task == "editMarker") {
        this.myData.attribute = newValue.map.details.map;
        this.currentId = newValue.map._id;
        this.markerList = newValue.map.details.markers;
        if (this.markerList.length > 0) {
          setTimeout(() => {
            // this.renderMarkers();
          }, 1000);
        }

        // this.icons = newValue.data.details.shape;

      }
    },
    child2parent: {
      handler(newValue) {
        if (newValue.task == "settingMapForMarker") {
          this.myData.attribute = newValue.item;
          this.createMap();

          // this.icons = newValue.data.details.shape;
          // setTimeout(() => {
          //   this.renderMarkers();
          // }, 1000);
        } else if (newValue.task == "newMarker") {
          if (newValue.item && newValue.item.constantIcon.properties["newIcon"] && newValue.item.constantIcon.properties["newIcon"] != undefined && Object.keys(newValue.item.constantIcon.properties.newIcon).length > 0) {
            let markers = this.markerFinder();
            let chosenMarker = markers.find(item => item._latlng.lat == newValue.item.properties.lat && item._latlng.lng == newValue.item.properties.lng);
            if (chosenMarker) {
              chosenMarker.setIcon(newValue.item.constantIcon.properties.newIcon);
              newValue.item.constantIcon.properties.icon = newValue.item.constantIcon.properties.newIcon.options;
              this.markerList.push(newValue.item);
              this.renderMarkers();
            }
          } else {
            this.markerList.push(newValue.item);
            this.renderMarkers();
          }
        } else if (newValue.task == "editMarker") {
          if (newValue.item && newValue.item.constantIcon.properties.newIcon && Object.keys(newValue.item.constantIcon.properties.newIcon).length > 0) {
            let markers = this.markerFinder();
            let chosenMarker = markers.find(item => item._latlng.lat == newValue.item.properties.lat && item._latlng.lng == newValue.item.properties.lng);
            if (chosenMarker) {
              const curMarker = this.markerList.find(x => x.id == newValue.id);
              chosenMarker.setIcon(newValue.item.constantIcon.properties.newIcon);

              curMarker.name = newValue.item.name;
              curMarker.properties = newValue.item.properties;
              curMarker.movable = newValue.item.movable;
              curMarker.constantIcon = newValue.item.constantIcon;
              curMarker.constantIcon.properties.icon = newValue.item.constantIcon.properties.newIcon.options;
              curMarker.constantIcon.properties.newIcon = {};
              curMarker.variableIcon = newValue.item.variableIcon;
              curMarker.tooltip = newValue.item.tooltip;
              curMarker.onClick = newValue.item.onClick;
            }
          } else {
            const curMarker = this.markerList.find(x => x.id == newValue.id);
            curMarker.name = newValue.item.name;
            curMarker.properties = newValue.item.properties;
            curMarker.movable = newValue.item.movable;
            curMarker.constantIcon = newValue.item.constantIcon;
            curMarker.variableIcon = newValue.item.variableIcon;
            curMarker.tooltip = newValue.item.tooltip;
            curMarker.onClick = newValue.item.onClick;
          }
        }
      }, deep: true
    }
  }
};
</script>

<style scoped>
.blink-marker {
  width: 25px;
  height: 25px;
  background-color: red;
  border-radius: 50%;
  opacity: 1;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.my-modal-header {
  display: block !important;
}

.mymap {
  min-height: 75vh;
  width: 100%;
}

/* .mymap.leaflet-container{
  background: none;
}

.map {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
} */


</style>

