const initialState = {
  page: "signin",
  type: null,
  index: null,
  db: null,
  reload: null,
  parent2child: null,
  child2parent: null,
  child2child: null,
  dataPickerCaller: null,
  lang: "eng",
  sidebar: false,
  lockSidebar: false,
  dashPage: "1",
  logoutTimeout: "0",
  dataTypeForMap: "",
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight
};

export const chpge = {
    namespaced: true,
    state: initialState,
    getters: {
      screenWidth: (state) => state.screenWidth,
      screenHeight: (state) => state.screenHeight
    },
    actions: {
      updateScreenSize({ commit }) {
        commit("SET_SCREEN_SIZE", {
          width: window.innerWidth,
          height: window.innerHeight
        });
      },
      toggleSidebar({ commit }, data) {
        commit("toggleSidebar", data);
      },
      lockSidebar({ commit }, data) {
        commit("lockSidebar", data);
      },
      changePage({ commit }, pageName) {
        commit("changePage", pageName);
      },
      changeDashPage({ commit }, pageName) {
        commit("changeDashPage", pageName);
      },
      openModal({ commit }, data) {
        commit("openModal", data);
      },
      updatePage({ commit }, counter) {
        commit("updatePage", counter);
      },
      child2child({ commit }, data) {
        commit("child2child", data);
      },
      child2parent({ commit }, data) {
        commit("child2parent", data);
      },
      dataPickerCaller({ commit }, data) {
        commit("dataPickerCaller", data);
      },
      parent2child({ commit }, data) {
        commit("parent2child", data);
      },
      changeLanguage({ commit }, data) {
        commit("changeLanguage", data);
      },
      changeLogoutTimeout({ commit }, data) {
        commit("changeLogoutTimeout", data);
      }
    },
    mutations: {
      SET_SCREEN_SIZE(state, { width, height }) {
        state.screenWidth = width;
        state.screenHeight = height;
      },
      setDataType(state, value) {
        state.dataTypeForMap = value;
      },
      changePage(state, pageName) {
        state.page = pageName;
        state.type = null;
        state.index = null;
        state.db = null;
      },
      changePageStatus(state, pageInfo) {
        state.type = pageInfo.type;
        state.index = pageInfo.index;
        state.db = null;
      },
      openModal(state, data) {
        state.page = data.page ? data.page : state.page;
        state.type = data.type;
        state.index = data.index;
        state.db = data.db;
      },
      updatePage(state, counter) {
        state.reload = counter;
      },
      child2child(state, data) {
        state.child2child = data;
      },
      child2parent(state, data) {
        state.child2parent = data;
      },
      parent2child(state, data) {
        state.parent2child = data;
      },
      dataPickerCaller(state, data) {
        state.dataPickerCaller = data;
      },
      changeLanguage(state, data) {
        state.lang = data;
      },
      toggleSidebar(state, data) {
        state.sidebar = data;
      },
      lockSidebar(state, data) {
        state.lockSidebar = data;
      },
      changeDashPage(state, data) {
        state.dashPage = data;
      },
      changeLogoutTimeout(state, data) {
        state.logoutTimeout = data;
      }
    }
  }
;
