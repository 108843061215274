<template>
  <div class="yekan">
    <b-modal
      id="modal-data-picker"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initModal"
      :dir="dict.dir[lang]"
      @hidden="resetModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ modalTitle[lang] }}</h5>
        <i
        >
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="handleClose"
          />
        </i>
      </template>

      <div class="yekan">
        <b-container fluid>
          <b-row align-h="between" class="mb-2">
            <b-col lg="4" sm="6" md="5" class="my-1">
              <ejs-textbox
                :value="filter"
                :placeholder="dict.search[lang]"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="filter"
              ></ejs-textbox>
            </b-col>
          </b-row>

          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="md"
            show-empty
            bordered
            small
            striped
            hover
            @filtered="onFiltered"
            @row-clicked="onRowClicked"
          >
            <template #head(name)="row">
              {{ row.field[lang] }}
            </template>
            <template #head(type)="row">
              {{ row.field[lang] }}
            </template>
            <template #cell(detail)="row">
              <b-icon-chevron-bar-down
                v-if=" (row.item.type!='Number' && row.item.type!='String' && row.item.type!='Boolean') "
                @click.stop="row.toggleDetails" class="m-1" />
            </template>
            <template #cell(name)="row">
              <p v-if="(row.item.type!='Number' && row.item.type!='String' && row.item.type!='Boolean')" class="m-1">
                <b>{{ row.item.name }}</b></p>
              <p v-else class="m-1">{{ row.item.name }}</p>
            </template>
            <template #row-details="row">
              <table class="table table-bordered m-0">
                <th class="p-2 border">{{ dict.name[lang] }}</th>
                <th class="p-2 border">{{ dict.type[lang] }}</th>
                <tr @click="onRowClicked(item)" v-for="(item,index) in row.item.value" :key="index">
                  <td class="mx-auto p-2 border">{{ item.name }}</td>
                  <td class="mx-auto border p-2">{{ item.type }}</td>
                </tr>
              </table>
              <!--              <div style="background: rgba(176,176,255,0.65)" class="p-0">-->
              <!--                <div @click.prevent="onRowClicked(item)" v-for="(item,index) in row.item.value" :key="index"-->
              <!--                     class="d-flex mx-auto my-0 py-1">-->
              <!--                  <p class="mx-auto mb-0"><span class="mx-1">&#8226;</span>{{ item.name }}</p>-->
              <!--                  <p class="mx-auto mb-0">{{ item.type }}</p>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
          </b-table>

          <b-row align-h="between">
            <b-col lg="3" sm="4" md="4" class="my-1">
              <ejs-dropdownlist
                :dataSource="pageOptions"
                :fields="{ text: lang, value: 'value' }"
                v-model="perPage"
                @change="(val)=>{pageOptions=val.value}"
              ></ejs-dropdownlist>
            </b-col>

            <b-col lg="7" sm="8" md="8" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "dataPickerModal",
  data() {
    return {
      selected: null,
      to: "",
      type: "data",
      items: [],
      fields: [
        {
          key: "detail",
          label: "",
          _showDetails: true,
          thStyle: { width: "5%" }
        },
        { key: "type", per: "نوع", eng: "type", label: "type", sortable: true, sortDirection: "asc" },
        { key: "name", per: "نام", eng: "name", label: "name", sortable: true }

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
        { value: 10, per: "10 ردیف", eng: "10 rows" },
        { value: 15, per: "15 ردیف", eng: "15 rows" },
        { value: 100, per: "100 ردیف", eng: "Show a lot" }],
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filterData: {},
      modalTitle: { per: "انتخاب داده", eng: "Pick Data" },
      dict: {
        dir: { per: "rtl", eng: "ltr" },
        Clear: { per: "حذف", eng: "Clear" },
        search: { per: "جستجو", eng: "search" },
        name: { per: "نام داده", eng: "name" },
        type: { per: "نوع داده", eng: "type" }
      }

    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    dataPickerCaller() {
      return this.$store.state.chpge.dataPickerCaller;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    type(newValue) {
      if (newValue === "config") {
        this.getAllControlNodes();
      } else if (newValue === "data") {
        this.getAllData();
      }

    },
    parent2child(newValue) {
      if (newValue.task === "pickedData") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    },
    dataPickerCaller(newValue) {
      if (newValue.task === "pickedData") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    }
  },
  methods: {
    initModal: function() {
      this.getAllData();
    },
    resetModal: function() {
      this.items = [];
    },
    getAllData() {
      this.$store.dispatch("data/getAllData", this.filterData).then(
        data => {
          // console.log("all data: ",JSON.stringify(data))
          this.items = data.newmatches;
          this.totalRows = this.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    getAllControlNodes() {
      this.$store.dispatch("data/nodeHandler", { type: "get" }).then(
        data => {
          var items = [];
          Object.entries(data.data).forEach(item => {
            items.push(items.push({ _id: item[1].id, name: item[1].data.title }));
          });
          this.items = JSON.parse(JSON.stringify(items));
          this.totalRows = this.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowClicked(item, index, event) {
      let a;
      let objName;
      this.items.forEach((val) => {
        if (typeof val.value == "object") {
          if (val.value.filter((v) => v.id == item.id).length > 0) {
            a = val._id;
            objName = val.name;
          }
        }
      });

      if (this.filterData["withChild"] == false) {
        if (item.type != "Object") {
          this.$store.dispatch("chpge/child2child", {
            task: "pickedData",
            to: this.to,
            item: {
              type: this.type,
              id: a != undefined ? a + "_" + item.id : item.id || item._id,
              name: objName != undefined ? objName + "_" + item.name : item.name || item.name,
              dataType: item.type
            }
          });
          this.$bvModal.hide("modal-data-picker");
        }
      } else {
        this.$store.dispatch("chpge/child2child", {
          task: "pickedData",
          to: this.to,
          item: { type: this.type, id: item._id, name: item.name, dataType: item.type }
        });
        this.$bvModal.hide("modal-data-picker");
      }
    },
    handleClose() {
      this.$bvModal.hide("modal-data-picker");
    }
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>