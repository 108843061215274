
<template>
    <div  style="padding: 1.5rem;	margin-right: 0;	margin-left: 0;	border-width: .2rem;">
        <b-card no-body>
          <b-card-body>

            <b-table :items="accounts" :fields="accountsFields" responsive="sm" small>  <!--thead-class="small_header" tbody-class="bold_body"> -->
              <template #cell(modify)="row" >
                <div style="display:flex; justify-content:flex-end;">
                  <b-button pill size="sm" variant="outline-success" @click="editAccount(row.index,$event)" class="mr-2" style="outline: none !important; border: none; box-shadow: none;">
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button pill size="sm" variant="outline-danger" @click="removeAccount(row.index,$event)" class="mr-2" style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
              </template>
            </b-table>
                                                    
            <div style="display: flex; flex-direction: row; justify-content: flex-end; height:30px;">
              <b-button pill size="sm" type="submit" variant="primary" @click="addAccount" style="vertical-align: middle;  margin-left:20px;">
                <b-icon icon="plus" aria-hidden="true" style="vertical-align: middle;"></b-icon>
              </b-button>
            </div>

          </b-card-body>
        </b-card>
    </div>
</template>

<script>
import User from '../models/user';

export default {
  name: 'Accounts',
  data() {
    return {
      accountsFields: ['username','email','role',{ key: 'modify', label: '' }],
      accounts: [],

      user: new User(null,null,null,null,[],null,null),
      successful: false,
      message: ''
    };
  },
  mounted() {
    this.handleGetAllUsers();
  },
  methods: {
    addAccount: function(){
      this.$store.dispatch('chpge/openModal',{
        page:'accountEdit',
        type:'Add',
        index:null,
        db:this.accounts
      })
    },
    editAccount: function(index,event){
      this.$store.dispatch('chpge/openModal',{
        page:'accountEdit',
        type:'Edit',
        index:index,
        db:this.accounts
      })
    },
    removeAccount: function(index,event){
      //this.checkItems('topic',index)
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete it.')
        .then(value => {
          if (value===true){
                        
            this.user.username = this.accounts[index].username;
            this.user.email = this.accounts[index].email;
            this.user.role = this.accounts[index].roles[0];
            this.user.password = this.accounts[index].password;
            
            this.$store.dispatch('auth/remove', this.user).then(
              data => {
                                   
                this.message = data.message;
                this.successful = true;
                this.handleGetAllUsers();
              },
              error => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.successful = false;
                alert(this.message)
              }
            );
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    handleGetAllUsers() {
      this.$store.dispatch('auth/getAllUsers').then(
        data => {
          
          this.accounts = data
          
          var roleList = [ {text:'User',value:'user'},
                {text:'Moderator Level 1',value:'moderator1'},
                {text:'Moderator Level 2',value:'moderator2'},
                {text:'Moderator Level 3',value:'moderator3'},
                {text:'Admin',value:'admin'}];
          
          for(var i in data){
            var index = roleList.find(e => e.value === data[i].roles[0]).text;
            this.accounts[i].role = index
          }
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
            // this.successful = false;
        }
      );
    },
  }
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
.small_header{
    font-size: x-small;
}
.bold_body{
    font-weight: bold;
    font-size: large;
    text-align: left;
    text-indent: 10px;
}
.td-class-1{
    width: 100px;
    text-align: left;
    font-weight: bold;
    font-size: large;
}
</style>