<template>
  <div>
    <b-modal
      id="modal-MTR22-selector"
      size="xl"
      centered
      scrollable
      :dir="dict.dir[lang]"
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      hide-footer

      :title="modalTitle[lang]"
      @shown="initModal"
      @hidden="resetModal"

    >
      <div>
        <b-container fluid="md">

          <div v-if="dataReady" class="cont" id="container" ref="container" :style="{ 'height': contheight + 'px' }">
            <div class="div1">
              <b-row>
                <b-col cols="12">
                  <div class="remote">
                    <b-row style="padding: 0.5rem 0 0.5rem 0; ">
                      <b-col cols="2">
                        <div style="display:flex;">
                          <contBlock :database="myMTR22" :value="value" :to="to" :ref="myMTR22.id" />
                          <!-- draw extensions -->
                          <div v-for="h in  myMTR22.extensions.length" :key="myMTR22.extensions[h-1].id"
                               style="display:flex;">
                            <contBlock :database="myMTR22.extensions[h-1]" :value="value" :to="to"
                                       :ref="myMTR22.extensions[h-1].id" />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row style="padding: 0.5rem 0 0.5rem 0;">
                      <b-col cols="2">
                      </b-col>
                      <b-col cols="10">
                        <div v-for="k in  myMTR22.slaves.length" :key="myMTR22.slaves[k-1].id"
                             style="display:flex; padding: 0.5rem 0 0.5rem 0;">
                          <!-- draw slave -->
                          <contBlock :database="myMTR22.slaves[k-1]" :value="value" :to="to"
                                     :ref="myMTR22.slaves[k-1].id" />
                          <!-- draw slots -->
                          <div v-for="l in  myMTR22.slaves[k-1].slots.length" :key="myMTR22.slaves[k-1].slots[l-1].id"
                               style="display:flex;">
                            <contBlock :database="myMTR22.slaves[k-1].slots[l-1]" :value="value" :to="to"
                                       :ref="myMTR22.slaves[k-1].slots[l-1].id" />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="div2">
              <svg class="mysvg" v-if="pointsReady">
                <line v-for="j in  MSconnections.length" :key="j-1" :x1="MSconnections[j-1].x1"
                      :y1="MSconnections[j-1].y1"
                      :x2="MSconnections[j-1].x2"
                      :y2="MSconnections[j-1].y2"
                      class="myline" />
              </svg>
            </div>
          </div>

        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import contBlock from "./setContBlockDS.vue";

export default {
  name: "setEquipLight",
  components: {
    contBlock
  },
  data() {
    return {
      modalTitle: "",
      dataReady: false,
      pointsReady: false,
      MSconnections: [{ x1: 0, y1: 0, x2: 457.8999938964844, y2: 256.5 }],
      contheight: 1000,
      to: {},
      myMTR22: {},
      dict: {
        dir: { eng: "ltr", per: "rtl" }
      }
    };
  },
  computed: {
    systemStruct() {
      return this.$store.state.data.contConf;
    },
    // value() {
    //     return this.$store.state.chpge.parent2child;
    // },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  updated() {
    if (!this.pointsReady)
      // this.getOffset();
      setTimeout(this.getOffset, 10);
  },
  watch: {
    systemStruct(newValue, oldValue) {
      var indx = newValue.remotes.findIndex(x => x.id === this.to.id);
      if (indx != -1) {
        this.myMTR22 = newValue.remotes[indx];
      }

      this.dataReady = true;
      this.pointsReady = false;
    },
    parent2child(newValue) {
      if (newValue.task === "selChannel") {

        this.value = newValue.items;
        this.to = newValue.from;
        this.to["task"] = "selChannel";

      }
    }
  },
  methods: {
    initModal: function() {
      this.modalTitle = {per:'انتخاب داده ها',eng:'Data Selection'};
      // console.log("mounted: ",this.dataReady)
      this.getContConf();
    },
    resetModal: function() {

    },
    getContConf() {
      this.$store.dispatch("data/getContConf").then(
        data => {
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    getOffset() {
      const arr1 = Object.keys(this.$refs).map((key) => ((this.$refs[key][0]) ? Object.values(this.$refs[key][0].$refs)[0] :

          (this.$refs[key].$refs ?
              Object.values(this.$refs[key].$refs)[0] :
              this.$refs[key]
          )
      ));
      var arr3 = {};
      for (var ij = 0; ij < arr1.length; ij++) {
        if (arr1[ij].id) {
          arr3[arr1[ij].id] = arr1[ij].getBoundingClientRect();
        }
      }
      var arr4 = {};
      var bott = [];
      var visibleItems = [this.myMTR22.id];
      for (var islave of this.myMTR22.slaves) {
        visibleItems.push(islave.id);
        for (var slot of islave.slots) {
          visibleItems.push(slot.id);
        }
      }
      for (var iextension of this.myMTR22.extensions) {
        visibleItems.push(iextension.id);
      }
      Object.keys(arr3).forEach(key => {
        if (visibleItems.includes(key) || key === "container") {
          arr4[key] = {
            left: arr3[key].left - arr3["container"].left,
            top: arr3[key].top - arr3["container"].top,
            right: arr3[key].right - arr3["container"].left,
            bottom: arr3[key].bottom - arr3["container"].top
          };
          if (key != "container")
            bott.push(arr4[key].bottom);
        }
      });
      this.contheight = Math.max(...bott);
      this.MSconnections = [];
      var newPoint = {
        x1: (arr4[this.myMTR22.id].right + arr4[this.myMTR22.id].left) / 2,
        y1: (arr4[this.myMTR22.id].bottom)
      };
      for (var slave of this.myMTR22.slaves) {
        newPoint.x2 = arr4[slave.id].left;
        newPoint.y2 = (arr4[slave.id].top + arr4[slave.id].bottom) / 2;
        this.MSconnections.push(newPoint);

        newPoint = { x1: arr4[slave.id].left, y1: (arr4[slave.id].top + arr4[slave.id].bottom) / 2 };
      }
      var newConn = [];
      var oldConn = [];
      for (var j = 0; j < this.MSconnections.length; j++) {
        if (this.MSconnections[j].x1 === this.MSconnections[j].x2) {
          newConn.push({
            x1: this.MSconnections[j].x1 - 20,
            x2: this.MSconnections[j].x2 - 20,
            y1: this.MSconnections[j].y1,
            y2: this.MSconnections[j].y2
          });
          newConn.push({
            x1: this.MSconnections[j].x1 - 20,
            x2: this.MSconnections[j].x2,
            y1: this.MSconnections[j].y1,
            y2: this.MSconnections[j].y1
          });
          newConn.push({
            x1: this.MSconnections[j].x1 - 20,
            x2: this.MSconnections[j].x2,
            y1: this.MSconnections[j].y2,
            y2: this.MSconnections[j].y2
          });
        } else {
          if (this.MSconnections[j].y1 != this.MSconnections[j].y2) {
            newConn.push({
              x1: this.MSconnections[j].x1,
              x2: this.MSconnections[j].x1,
              y1: this.MSconnections[j].y1,
              y2: this.MSconnections[j].y2
            });
            newConn.push({
              x1: this.MSconnections[j].x1,
              x2: this.MSconnections[j].x2,
              y1: this.MSconnections[j].y2,
              y2: this.MSconnections[j].y2
            });
          } else {
            oldConn.push(this.MSconnections[j]);
          }
        }
      }
      this.MSconnections = oldConn.concat(newConn);
      this.pointsReady = true;
    }
  }
};
</script>

<style scoped>
.mysvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;


}

.cont {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.div1 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div2 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.myline {
  stroke-width: 2px;
  stroke: rgb(0, 0, 0);
}


.block {
  position: fixed;
  height: 100px;
  width: 50px;
  border: 2px solid black;
  /* margin : -100px 0 0 -100px */
}

.block:hover {
  background-color: yellow;
  cursor: pointer;
}

/* .hidden_header {
  display: none;
} */
/* .small_header{
    font-size: x-small;
} */
/* .bold_body{
    font-weight: bold;
    font-size: large;
    text-align: left;
    text-indent: 10px;
} */
/* .td-class-1{
    width: 100px;
    text-align: left;
    font-weight: bold;
    font-size: large;
} */
.remote {

  border: 1px solid #c4c4c4;
  border-radius: 0.35rem;
  /* background-color: #ffffff;
  opacity: 1; */
  margin: 5px 5px 5px 5px;
  padding: 5px 10px 5px 10px;
}

</style>