<template>
    <div>
        <b-modal
        id="modal-datasel"
        size="xl"
        centered
        scrollable
        
        header-bg-variant="warning"
        header-text-variant="dark"
        header-border-variant="primary"

        body-bg-variant="light"
        body-text-variant="dark"
        hide-header-close
        hide-footer

        content-class="shadow"
        :title="modalTitle"
        @shown="initModal"
        @hidden="resetModal"

        >
        <div>
            <b-container fluid="md">

                <div v-if="dataReady" class="cont" id="container" ref="container" :style="{ 'height': contheight + 'px' }">
                    <div class="div1" >
                        <b-row style="padding: 0.5rem 0 0.5rem 0;">
                            
                            <b-col cols="2">
                                <div style="display:flex;">
                                    <contBlock  :database="systemStruct" :value="value" :ref="systemStruct.id"/>
                                    <!-- draw extensions -->
                                    <div  v-for="g in  systemStruct.extensions.length" :key="systemStruct.extensions[g-1].id" style="display:flex;">
                                        <contBlock  :database="systemStruct.extensions[g-1]" :value="value" :ref="systemStruct.extensions[g-1].id"/>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="2">
                            </b-col>
                            <b-col cols="10">
                                <div  v-for="j in  systemStruct.remotes.length" :key="systemStruct.remotes[j-1].id" class="remote">
                                    <!-- draw masters -->
                                    <b-button
                                        style="width:98%; margin:0.25rem"
                                         variant="light"
                                        :class="visible[systemStruct.remotes[j-1].id] ? null : 'collapsed'"
                                        :aria-expanded="visible[systemStruct.remotes[j-1].id] ? 'true' : 'false'"
                                        :aria-controls="systemStruct.remotes[j-1].id"
                                        @click="collapsefunc(systemStruct.remotes[j-1].id,$event)"
                                        >
                                        {{systemStruct.remotes[j-1].topic}}
                                    </b-button>
                                    <b-collapse v-model="visible[systemStruct.remotes[j-1].id]" :id="systemStruct.remotes[j-1].id" style="transition: height 0s">
                                        <b-row style="padding: 0.5rem 0 0.5rem 0; " >                                    
                                            <b-col cols="2">
                                                <div  style="display:flex;">
                                                    <contBlock :database="systemStruct.remotes[j-1]" :value="value" :ref="systemStruct.remotes[j-1].id"/>
                                                    <!-- draw extensions -->
                                                    <div  v-for="h in  systemStruct.remotes[j-1].extensions.length" :key="systemStruct.remotes[j-1].extensions[h-1].id" style="display:flex;">
                                                        <contBlock  :database="systemStruct.remotes[j-1].extensions[h-1]" :value="value" :ref="systemStruct.remotes[j-1].extensions[h-1].id"/>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row style="padding: 0.5rem 0 0.5rem 0;"> 
                                            <b-col cols="2">
                                            </b-col>  
                                            <b-col cols="10">
                                                <div  v-for="k in  systemStruct.remotes[j-1].slaves.length" :key="systemStruct.remotes[j-1].slaves[k-1].id" style="display:flex; padding: 0.5rem 0 0.5rem 0;">
                                                    <!-- draw slave -->
                                                    <contBlock :database="systemStruct.remotes[j-1].slaves[k-1]" :value="value" :ref="systemStruct.remotes[j-1].slaves[k-1].id"/>
                                                    <!-- draw slots -->
                                                    <div  v-for="l in  systemStruct.remotes[j-1].slaves[k-1].slots.length" :key="systemStruct.remotes[j-1].slaves[k-1].slots[l-1].id" style="display:flex;">
                                                        <contBlock  :database="systemStruct.remotes[j-1].slaves[k-1].slots[l-1]" :value="value" :ref="systemStruct.remotes[j-1].slaves[k-1].slots[l-1].id"/>
                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-collapse>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="div2">
                        <!-- <svg v-if="pointsReady"> -->
                        <svg  class="mysvg" v-if="pointsReady">
                            <line v-for="j in  MSconnections.length" :key="j-1" :x1="MSconnections[j-1].x1" 
                                                                                :y1="MSconnections[j-1].y1" 
                                                                                :x2="MSconnections[j-1].x2" 
                                                                                :y2="MSconnections[j-1].y2" 
                                                                                class="myline" />
                        </svg>
                    </div>
                </div >

            </b-container>
        </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
    import contBlock from "./setContBlockDS.vue";
    export default {
        name: "setEquipLight",
        components: {
            contBlock,
        },
        data() {
            return {
                modalTitle:'',
                dataReady:false,
                pointsReady:false,
                MSconnections: [{x1:0,y1:0,x2:457.8999938964844,y2:256.5}],
                contheight:1000,
                visible: {}
            }
        },
        computed: {
            systemStruct() {
                return this.$store.state.data.contConf;
            },
            value() {
                return this.$store.state.chpge.parent2child;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        updated() {
            if (!this.pointsReady)
                // this.getOffset();
                setTimeout(this.getOffset, 10)
        },
        watch: {
            systemStruct(newValue, oldValue) {
                this.dataReady = true
                this.pointsReady = false
                for (var i=0; i<newValue.remotes.length; i++){
                    if (!this.visible[newValue.remotes[i].id])
                        this.visible[newValue.remotes[i].id]=true
                }
            },
        },
        methods: {
            initModal: function(){
                this.modalTitle = "Data Selection"
                // console.log("mounted: ",this.dataReady)
                this.getContConf();
            },
            resetModal: function(){

            },
            getContConf() {
                this.$store.dispatch('data/getContConf').then(
                    data => {
                    },
                    error => {
                        this.message =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                            // this.successful = false;
                    }
                );
            },
            getOffset() {
                const arr1 = Object.keys(this.$refs).map((key) => ((this.$refs[key][0])?    Object.values(this.$refs[key][0].$refs)[0] :
                                                                                            
                                                                                            (this.$refs[key].$refs? 
                                                                                                                    Object.values(this.$refs[key].$refs)[0]:
                                                                                                                    this.$refs[key]
                                                                                            )
                                                                                        ))
                
                // console.log(arr1)

                var arr3 = {};
                for (var ij = 0; ij < arr1.length; ij++) {
                    if (arr1[ij].id){
                        arr3[arr1[ij].id] = arr1[ij].getBoundingClientRect();
                    }
                }
                // console.log("arr3: ",arr3)

                var arr4={}
                var bott=[]
                
                var visibleItems = [this.systemStruct.id]
                for (var extension of this.systemStruct.extensions){
                    visibleItems.push(extension.id)
                }
                for (var remote of this.systemStruct.remotes){
                    if (this.visible[remote.id]){
                        visibleItems.push(remote.id)
                        for (var islave of remote.slaves){
                            visibleItems.push(islave.id)
                            for (var slot of islave.slots){
                                visibleItems.push(slot.id)
                            }
                        }
                        for (var iextension of remote.extensions){
                            visibleItems.push(iextension.id)
                        }
                    }
                }
                // console.log(visibleItems)

                Object.keys(arr3).forEach(key => {
                    if (visibleItems.includes(key) || key==='container'){
                    // if ((arr3[key].height!=0)&&(arr3[key].width!=0)){
                        arr4[key] = {left: arr3[key].left- arr3['container'].left,
                                    top:  arr3[key].top - arr3['container'].top,
                                    right: arr3[key].right - arr3['container'].left,
                                    bottom: arr3[key].bottom - arr3['container'].top}
                        if (key!='container')
                            bott.push(arr4[key].bottom)
                    }
                });

                // console.log("arr4: ",arr4)

                this.contheight = Math.max(...bott)
                // console.log(bott)
                // console.log(this.contheight)

                // console.log(JSON.stringify(this.systemStruct))
                this.MSconnections=[];
                
                for (var master of this.systemStruct.remotes){
                    if (arr4[master.id]){
                        var LanNet={x1:(arr4[this.systemStruct.id].right+arr4[this.systemStruct.id].left)/2,
                                    y1:arr4[this.systemStruct.id].bottom,
                                    x2:arr4[master.id].left,
                                    y2:(arr4[master.id].top+arr4[master.id].bottom)/2 }
                        this.MSconnections.push(LanNet)

                        // var points = []
                        var newPoint={x1:(arr4[master.id].right+arr4[master.id].left)/2, y1:(arr4[master.id].bottom)}
                        for (var slave of master.slaves){
                            newPoint.x2 = arr4[slave.id].left;
                            newPoint.y2 = (arr4[slave.id].top+arr4[slave.id].bottom)/2;
                            this.MSconnections.push(newPoint)

                            newPoint = {x1:arr4[slave.id].left, y1:(arr4[slave.id].top+arr4[slave.id].bottom)/2}
                        }
                    }
                }

                // console.log(JSON.stringify(this.MSconnections))

                var newConn=[]
                var oldConn=[]

                for (var j=0; j<this.MSconnections.length; j++){
                    if (this.MSconnections[j].x1 === this.MSconnections[j].x2){
                        newConn.push({x1: this.MSconnections[j].x1-20, x2: this.MSconnections[j].x2-20, y1: this.MSconnections[j].y1, y2: this.MSconnections[j].y2})
                        newConn.push({x1: this.MSconnections[j].x1-20, x2: this.MSconnections[j].x2, y1: this.MSconnections[j].y1, y2: this.MSconnections[j].y1})
                        newConn.push({x1: this.MSconnections[j].x1-20, x2: this.MSconnections[j].x2, y1: this.MSconnections[j].y2, y2: this.MSconnections[j].y2})
                    }
                    
                    else{
                        if (this.MSconnections[j].y1 != this.MSconnections[j].y2){
                            newConn.push({x1: this.MSconnections[j].x1, x2: this.MSconnections[j].x1, y1: this.MSconnections[j].y1, y2: this.MSconnections[j].y2})
                            newConn.push({x1: this.MSconnections[j].x1, x2: this.MSconnections[j].x2, y1: this.MSconnections[j].y2, y2: this.MSconnections[j].y2})
                        }
                        else{
                            oldConn.push(this.MSconnections[j])
                        }
                    }
                }

                this.MSconnections = oldConn.concat(newConn)

                // console.log(JSON.stringify(this.MSconnections))

                this.pointsReady = true;
                
            },
            collapsefunc(id,event) {
                this.pointsReady = false
                var vis = JSON.parse(JSON.stringify(this.visible));
                vis[id] = !vis[id]
                this.visible = JSON.parse(JSON.stringify(vis)); 
            }
        }
    };
</script>

<style scoped>
.mysvg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


}
.cont{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

.div1{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.div2{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.myline{
    stroke-width:2px;
    stroke:rgb(0,0,0);
}


.block {
  position: fixed;
  height: 100px;
  width: 50px;
  border : 2px solid black;
  /* margin : -100px 0 0 -100px */
}

.block:hover {
  background-color: yellow;
  cursor: pointer;
}

/* .hidden_header {
  display: none;
} */
/* .small_header{
    font-size: x-small;
} */
/* .bold_body{
    font-weight: bold;
    font-size: large;
    text-align: left;
    text-indent: 10px;
} */
/* .td-class-1{
    width: 100px;
    text-align: left;
    font-weight: bold;
    font-size: large;
} */
.remote{

    border: 1px solid #c4c4c4;
    border-radius: 0.35rem;
    /* background-color: #ffffff;
    opacity: 1; */
    margin: 5px 5px 5px 5px;
    padding: 5px 10px 5px 10px;
}

</style>