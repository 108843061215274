<script>
import { v4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "setValueTable",
  props: { value: { default: [] } },
  data() {
    return {
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        transition2: { eng: "slide2-fade-ltr", per: "slide2-fade-rtl" },
        keyPH: { eng: "key", per: "نام متغیر" },
        keyTitle: { eng: "key", per: "نام متغیر" },

        typeOper: { eng: "operation", per: "نوع اپراتور" },
        typeTitle: { eng: "type", per: "نوع متغیر" },
        typePH: { eng: "type", per: "نوع متغیر" }
      },
      reRender: 0,
      validator1: null,
      chooseName: "",
      mode: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "key",
      sortDesc: true,
      sortDirection: "asc",
      setValTitle: "",
      selectedid: "",
      headers: [],
      selectedRow: {},
      data: []
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    child2child() {
      return this.$store.state.chpge.child2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    generateFields() {
      let head = [];
      this.headers.forEach((val) => {
        if (val.key != "modify")
          head.push({ key: val._id, label: val.key });
      });
      head.push({ key: "modify" });
      return head;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetModal() {
      this.reRender = 0;
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "modal-identifier-setValue");
      });
    },

    showIt() {
      this.validator1 = new FormValidator("#setVal_sec", {
        rules: {},
        customPlacement: (element, error) => {
          console.log("element", element);
          element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
        }
      });
      setTimeout(() => {
        this.reRender += 1;
      }, 100);
      if (this.mode == "Add") {
        this.$nextTick(() => {
          let temp = {};

          this.headers.forEach((val) => {
            if (val.key != "modify")
              if (val.type == "data") {
                temp[val._id] = {
                  type: "",
                  _id: "",
                  name: "",
                  dataType: ""
                };
              } else
                temp[val._id] = "";
          });
          temp["_id"] = v4();

          this.selectedRow = { ...temp };
        });
      }
    },

    addnewRow() {
      this.mode = "Add";
      this.setValTitle = "Add Item";
      let temp = {};
      this.headers.forEach((val) => {
        if (val.key != "modify")
          if (val.type == "data") {
            temp[val._id] = {
              type: "",
              _id: "",
              name: "",
              dataType: ""
            };
          } else
            temp[val._id] = "";
      });
      temp["_id"] = v4();

      this.selectedRow = { ...temp };
      this.$root.$emit("bv::show::modal", "modal-identifier-setValue");
    },
    handleSubmit($bv) {
      $bv.preventDefault();
      const isValid = this.validator1.validate();
      if (isValid) {
        if (this.mode == "Add") {
          this.data.push(this.selectedRow);
          this.selectedRow = {};
          this.$nextTick(() => {
            this.$root.$emit("bv::hide::modal", "modal-identifier-setValue");
          });
        } else {
          let foundedColumns = this.data.find((item) => {
            item._id == this.selectedRow._id;
          });
          foundedColumns = { ...this.selectedRow };
          this.$refs.setValueTable.refresh();

        }
        this.$store.dispatch("chpge/child2parent", {
          task: "setDataMapping",
          from: "setvalue",
          filter: {
            dataType: ["!complex"],
            usedBy: [],
            parents: [],
            archive: null,
            withChild: false
          },
          type: "Edit",
          item: this.data
        });
        this.$nextTick(() => {
          this.$root.$emit("bv::hide::modal", "modal-identifier-setValue");
        });
      }

    },

    editRow(row) {
      this.mode = "Edit";
      this.setValTitle = "Edit Item";
      this.selectedRow = row.item;
      this.$root.$emit("bv::show::modal", "modal-identifier-setValue");
    },
    daleteRow(row) {
      this.data.splice(this.data.indexOf(row.item._id), 1);
      this.$refs.setValueTable.refresh();
      this.$store.dispatch("chpge/child2parent", {
        task: "setDataMapping",
        from: "setvalue",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false
        },
        type: "Edit",
        item: this.data
      });
    },
    pickData(id) {
      this.selectedid = id;
      let filterParents = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    }
  },
  watch: {
    child2child(newValue, oldValue) {
      this.headers = newValue.item;
    },
    child2parent(newValue, oldValue) {
      if (newValue.task === "pickData" && newValue.to === "getPostMap") {
        this.selectedRow[this.selectedid] = newValue.item;
      }
    }
  },
  mounted() {
    if (this.parent2child.task === "editGetMappingMain" && this.parent2child.type == "Edit") {
      if (Array.isArray(this.parent2child.item.data)) {
        this.data = this.parent2child.item.data;
      } else this.data = [];
      this.headers = this.parent2child.item.identifiers;
    }
  }
};
</script>

<template>
  <div>

    <b-row
      :dir="dict.dir[lang]"
      align-h="between"
      style="background-color: #fff"
      class="g-0 p-2 border border-top-0 border-bottom-0"
    >
      <b-table
        :items="data"
        :fields="generateFields"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        show-empty
        @filtered="onFiltered"
        small
        responsive
        class="mt-2"
        ref="setValueTable"
      >

        <template #cell(modify)="row">
          <div style="display: flex; justify-content: flex-end">
            <b-button
              pill
              size="sm"
              variant="outline-success"
              class="mr-2"
              @click="editRow(row)"
              style="outline: none !important; border: none; box-shadow: none"
            >
              <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              pill
              size="sm"
              variant="outline-danger"
              class="mr-2"
              @click="daleteRow(row)"
              style="
                margin-inline: 10px;
                outline: none !important;
                border: none;
                box-shadow: none;
              "
            >
              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col class="my-1">
        <div
          style="font-size: 24px; display: flex; justify-content: end"
        >
          <i @click="addnewRow" class="reset">
            <font-awesome-icon icon="fas fa-plus-square" />
          </i>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-identifier-setValue"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      @shown="showIt"
      content-class="shadow"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="mode=='Add'">{{ dict.titleTranslateAdd[lang] }}</h5>
          <h5 v-if="mode=='Edit'">{{ dict.titleTranslateEdit[lang] }}</h5>
        </div>
      </template>
      <div :dir="dict.dir[lang]" v-if="Object.keys(selectedRow).length>0">
        <div class="my-3 row">
          <div class="col-md-4 col-12"
               v-for="(item,index) in headers.filter((item1)=>item1.type=='data' && (item1.operation!='latest' &&item1.operation!='earliest'))"
               :key="index">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="selectedRow[item._id]['name']"
                :placeholder="item.key"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
              ></ejs-textbox>
              <b-button @click="pickData(item._id)" class="btn-sm" variant="primary"><span
                class="e-icons e-edit-6 "></span></b-button>

            </div>
            <!--            <b-form-group-->
            <!--              :label="item.key"-->
            <!--              label-for="data"-->
            <!--              :invalid-feedback="errors.first('name')"-->
            <!--              style="width: 100%; padding: 0px 10px 10px 0px"-->
            <!--            >-->
            <!--              <b-input-group :key="index" style="flex-wrap: inherit">-->
            <!--                <b-form-input-->
            <!--                  name="data"-->
            <!--                  disabled-->
            <!--                  type="text"-->
            <!--                  :value="selectedRow[item._id]['name']"-->
            <!--                  placeholder="Select a data"-->
            <!--                  v-validate="'required|min:1|max:200'"-->
            <!--                  style="width: 100%"-->
            <!--                ></b-form-input>-->

            <!--                <b-input-group-append>-->
            <!--                  <b-button @click="pickData(item._id)">Select</b-button>-->
            <!--                </b-input-group-append>-->
            <!--              </b-input-group>-->
            <!--            </b-form-group>-->
          </div>

        </div>

        <div class="row" id="setVal_sec">
          <div class="col-md-3 col-12"
               :key="index"
               v-for="(item,index) in headers.filter((item1)=>item1.type=='identifier' && (item1.operation!='latest' &&item1.operation!='earliest'))"
          >
            <component
              :is="'TextBox'"
              :id="'setVal-key_'+index"
              :value="selectedRow[item._id]"
              :title="item.key"
              :key="'key_'+index+reRender"
              :placeholder="item.key"
              :enableRtl="lang === 'per'"
              :validatorInstance="validator1"
              :validatorRules="{ required: true }"
              @change="
                (val) => {
                  selectedRow[item._id] = val;
                }
              "
            ></component>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button variant="secondary" @click="resetModal">{{ dict.cancel[lang] }}</b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="handleSubmit">{{ dict.ok[lang] }}</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style scoped></style>
