<script>
import dataPickerModal from "@/components/setContModalDataPicker.vue";
import { v4 } from "uuid";
import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import { FormValidator, TextBox } from "@syncfusion/ej2-inputs";
import Vue from "vue";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import { mapGetters } from "vuex";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "identifierstable",
  components: { dataPickerModal },
  props: {
    value: { default: [] },
    pageType: { default: "" },
    isHttp: { default: false }
  },
  data() {
    return {

      selectionOptions: {
        type: "Multiple",
        persistSelection: true,
        checkboxOnly: true
      },
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        template: "set_clock"
      },
      cdb: {},
      validator: null,
      renderTime: 0,
      sortDesc: true,
      reRen: false,
      submitted: false,
      successful: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: "key",
      mode: "key",
      sortDirection: "asc",
      idenTitleModal: "",
      fields: [
        {
          key: "key",
          eng: "key",
          per: "کلید",
          hasTemplate: false
        },
        {
          key: "type",
          eng: "type",
          per: "نوع",
          hasTemplate: false
        },
        {
          eng: "",
          per: "",
          key: "modify",
          hasTemplate: true,
          _id: ""
        }
      ],
      keyList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: null },
        { per: "داده", eng: "data", value: "data" },
        { per: "معرف", eng: "identifier", value: "identifier" }
      ],
      operationsList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: null },
        { per: "برابر", eng: "equal", value: "equal" },
        { per: "بزرگتر از", eng: "greater", value: "greater" },
        { per: "گمتر از", eng: "less", value: "less" },
        { per: "بزرگتر و مساوی", eng: "greaterEqual", value: "greaterEqual" },
        { per: "کمتر و مساوی", eng: "lessEqual", value: "lessEqual" },
        { per: "اخرین", eng: "latest", value: "latest" },
        { per: "اولین", eng: "earliest", value: "earliest" }
      ],
      idenValues: {
        key: "",
        operation: null,
        type: "",
        _id: v4()
      },
      columns: [],
      dynamicComponentNames: [],
      dict: {
        add: { eng: "New item", per: "آیتم جدید" },
        dir: { eng: "ltr", per: "rtl" },
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        transition2: { eng: "slide2-fade-ltr", per: "slide2-fade-rtl" },
        keyPH: { eng: "key", per: "نام متغیر" },
        keyTitle: { eng: "key", per: "نام متغیر" },

        typeOper: { eng: "operation", per: "نوع اپراتور" },
        typeTitle: { eng: "type", per: "نوع متغیر" },
        typePH: { eng: "type", per: "نوع متغیر" }
      }
    };
  },
  computed: {
    ...mapGetters("chpge", ["screenWidth", "screenHeight"]),
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolbars() {

      return [{ text: this.dict.add[this.lang], id: "_add", prefixIcon: "e-icons e-add" }];
    }
  },
  watch: {
    child2parent(newValue, oldValue) {
      if (newValue.task === "pickData" && newValue.to === "getPostMap") {
        this.data.data = newValue.item;
      }
    },
    "idenValues.type"(val) {
      if (this.isHttp == false) {
        if (this.pageType == "postMapping" && val == "identifier") {
          this.idenValues.operation = "equal";
        } else if (this.pageType != "postMapping" && this.mode != "Edit")
          this.idenValues.operation = null;
      } else {
        if (this.pageType != "postMapping" && val == "identifier") {
          this.idenValues.operation = "equal";
        } else if (this.pageType == "postMapping" && this.mode != "Edit")
          this.idenValues.operation = null;
      }
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addNewColumn() {
      this.mode = "New";
      this.idenTitleModal = "New Item";
      this.idenValues = {
        _id: v4(),
        key: "",
        operation: null,
        type: ""
      };
      this.$bvModal.show("modal-identifier-handler");
    },
    editColumn(row) {
      this.mode = "Edit";
      this.idenTitleModal = "Edit Item";
      this.idenValues = row;
      this.$bvModal.show("modal-identifier-handler");
    },
    deleteColumn(row) {
      let start = this.columns.find(i => i._id == row._id);
      this.columns.splice(this.columns.indexOf(start), 1);
      this.$refs.identifierTable_gen.ej2Instances.refresh();

      this.$store.dispatch("chpge/child2child", {
        task: "addIdentifier",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false
        },
        type: "Add",
        item: this.columns
      });
      this.$store.dispatch("chpge/child2parent", {
        task: "setDataMapping",
        from: "setIdentifier",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false
        },
        type: "Delete",
        item: this.columns
      });

    },
    resetModal() {
      this.reRen = false;
      this.validator = null;
      this.idenValues = {
        _id: v4(),
        key: "",
        operation: null,
        type: ""
      };

      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "modal-identifier-handler");
      });
    },
    handleSubmit($ev) {
      $ev.preventDefault();
      this.submitData();
    },
    submitData() {
      const isValid = this.validator.validate();
      if (isValid) {
        if (this.mode === "New") {
          let items = this.columns.filter((i) => i.key !== "modify");

          items.push(this.idenValues);

          items.push(this.fields[2]);

          this.columns = [...items];

          this.$store.dispatch("chpge/child2child", {
            task: "addIdentifier",
            from: "getPostMap",
            filter: {
              dataType: ["!complex"],
              usedBy: [],
              parents: [],
              archive: null,
              withChild: false
            },
            type: "Add",
            item: this.columns
          });
          this.$store.dispatch("chpge/child2parent", {
            task: "setDataMapping",
            from: "setIdentifier",
            filter: {
              dataType: ["!complex"],
              usedBy: [],
              parents: [],
              archive: null,
              withChild: false
            },
            type: "Edit",
            item: this.columns
          });
          this.$nextTick(() => {
            this.$root.$emit("bv::hide::modal", "modal-identifier-handler");
          });

        } else {
          this.deleteColumn(this.idenValues);
          this.columns.push(this.idenValues);
          this.$nextTick(() => {
            this.$root.$emit("bv::hide::modal", "modal-identifier-handler");
          });
        }
      }
    },
    pickData() {
      let filterParents = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    initModal() {
      this.reRen = true;
      this.validator = new FormValidator("#identifier-body");
    },
    toolbarClick(args) {
      if (args.item.id.includes("_add")) this.addNewColumn();
    }
  },
  mounted() {
    if (
      this.parent2child.task === "editGetMappingMain" &&
      this.parent2child.type == "Edit"
    ) {
      this.columns = this.parent2child.item.identifiers;
      this.$store.dispatch("chpge/child2child", {
        task: "addIdentifier",
        from: "getPostMap",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: false
        },
        type: "Add",
        item: this.columns
      });
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<template>
  <div>
    <div class="mt-4">
      <ejs-grid
        ref="identifierTable_gen"
        :width="'auto'"
        :locale="lang"
        :key="columns.length"
        :enableRtl="lang == 'per'"
        :allowSorting="true"
        :editSettings="editSettings"
        :toolbar="toolbars"
        :toolbarClick="toolbarClick"
        :allowPaging="true"
        :enableAdaptiveUI="screenWidth <= 600"
        :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
        :selectionSettings="selectionOptions"
        :dataSource="columns.filter((item) => item.key != 'modify')"
      >
        <e-columns>
          <e-column width="50" type="checkbox"></e-column>
          <e-column
            v-for="(item, index) in fields"
            :key="index"
            :width="screenWidth <= 1024 ? 150 : 'auto'"
            :field="item.key"
            :headerText="item[lang]"
            :template="item.hasTemplate ? item.key : null"
          ></e-column>
        </e-columns>
        <template v-slot:modify="{ data }">
          <div class="d-flex justify-content-between col-5">
          <span
            class="e-icons text-danger e-delete-1"
            style="font-size: 17px"
            @click="deleteColumn(data)"
          ></span>
            <span
              class="e-icons e-edit-6"
              style="font-size: 17px"
              @click="editColumn(data)"
            ></span>
          </div>

          <!--            <b-button-->
          <!--              v-if="!licence"-->
          <!--              pill-->
          <!--              size="sm"-->
          <!--              variant="outline-success"-->
          <!--              class="mr-2"-->
          <!--              :id="data.id"-->
          <!--              @click="showUsers(data)"-->
          <!--              style="outline: none !important; border: none; box-shadow: none"-->
          <!--            >-->
          <!--              <b-icon icon="eye" aria-hidden="true"></b-icon>-->
          <!--            </b-button>-->
        </template>
      </ejs-grid>

    </div>
    <b-modal
      id="modal-identifier-handler"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      @shown="initModal"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="mode == 'New'">{{ dict.titleTranslateAdd[lang] }}</h5>
          <h5 v-if="mode == 'Edit'">{{ dict.titleTranslateEdit[lang] }}</h5>
        </div>
      </template>
      <div :dir="dict.dir[lang]" class="row" id="identifier-body">
        <!--        :validatorInstance="cdb.validator"-->

        <div class="col col-md-4">
          <ejs-textbox
            :autocomplete="'off'"
            v-model="idenValues.key"
            :title="dict.keyTitle[lang]"
            :placeholder="dict.keyPH[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
          >
          </ejs-textbox>
        </div>
        <div class="col col-md-4">
          <ejs-dropdownlist
            :title="dict.typeTitle[lang]"
            :dataSource="keyList"
            v-model="idenValues.type"
            :fields="{ text: lang, value: 'value' }"
            :placeholder="dict.typePH[lang]"
            :enableRtl="lang === 'per'"
            :required="true"
          ></ejs-dropdownlist>
        </div>
        <div
          class="col col-md-4"
          v-if="idenValues.type == 'identifier'"
        >
          <ejs-dropdownlist
            :title="dict.typeOper[lang]"
            :enabled="
              (isHttp == false && pageType == 'postMapping') ||
              (isHttp == true && pageType != 'postMapping')
                ? false
                : true
            "
            :required="idenValues.type == 'identifier' ? true : false"
            :dataSource="operationsList"
            v-model="idenValues.operation"
            :fields="{ text: lang, value: 'value' }"
            :placeholder="dict.typeOper[lang]"
            :enableRtl="lang === 'per'"
          >
          </ejs-dropdownlist>
        </div>
      </div>
      <template #modal-footer>
        <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
          <div class="d-flex mx-2" style="gap: 10px">
            <b-button variant="secondary" @click="resetModal"
            >{{ dict.cancel[lang] }}
            </b-button>
          </div>
          <div class="d-flex" style="gap: 10px">
            <b-button variant="primary" @click="handleSubmit"
            >{{ dict.ok[lang] }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <dataPickerModal></dataPickerModal>
  </div>
</template>
