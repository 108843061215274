<template>
  <div class="component-container">
    <div class="component-button"></div>
    <div class="component-hole"></div>
    <div class="component-main">
      <div  :class="{'icon-hover': (!disable && hovered), 'icon': !disable, 'icon-disable':disable}" @mouseover="hovered = true" @mouseleave="hovered = false">{{selectedText.text}}
        <ul class="menu">
          <li class="spread" v-for="(option,i) in groupOptions" :key="i" :style="hovered?{ transitionDelay: (i+1)*0.02+'s', transform: 'rotate('+((360/groupOptions.length)*i-45) +'deg)' }:{transform:'none'}">
            <a class="unit" href="#" @click.prevent="check(option.value)" :style="hovered?{ transitionDelay: i*0.04+'s', transform: 'rotate('+45 +'deg)' }:{transform:'none'}"  >
              {{ option.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

  export default {
    props: {
      id:String,
      panelData: Object,
      disable: Boolean,
    },
    data() {
      return {
        hovered:false,
        groupOptions:[],
        selectedText:'',
        SelectedGroup:{dids:[], value:''},
      }
    },
    methods: {
      infoc: function (e) {
        e.stopPropagation();
        e.currentTarget.focus();
      },
      check: function (value) {
        this.selectedText = this.groupOptions.find(x=> JSON.stringify(x.value)===JSON.stringify(value))
        let data = [{id:this.SelectedGroup.dids[0], value:value}]
        // console.log('/////',data)
        this.$store.dispatch('panel/setData', {data:data})
          .then(
              data => {
              },
              error => {
              var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
          );
      }
    },
    mounted(){
      this.groupOptions = [{ text: 'خاموش', value: null}]
      for (var data of this.panelData.data){
        if (data.dtype==="Group"){
          this.groupOptions.push({ text: data.dname, value: data.did}) 
        }
        else if (data.dtype==='SelectedGroup'){
            this.SelectedGroup.dids.push(data.did)
        }
      }
      
      this.$store.dispatch('panel/getData', {id:this.SelectedGroup.dids})  //this.gdid
      
      .then(
          data => {
          },
          error => {
          var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
              // alert(message)
          }
      );
    },
    computed: {
      updatedData() {
        return this.$store.state.panel.data;
      },
      styleObject(){
        console.log()
      },
      lang() {
        return this.$store.state.chpge.lang;
      }
    },
    watch: {
      updatedData(newValue) {
        for (var Data of newValue){
            if (JSON.stringify(Data.id) === JSON.stringify(this.SelectedGroup.dids[0])){
              this.selectedText = this.groupOptions.find(x=> JSON.stringify(x.value)===JSON.stringify(Data.value))
              // console.log(this.selectedText)
            }
        }
      },
    }
  };
</script>

<style scoped >

  .component-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .component-button {
    background-color:#7a7979;

    /* display:flex; */
    position: absolute;
    z-index: 5;
    left:  0;
    top: 50%;
    right: 0;
    bottom:0;
/* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */


  }
  .component-hole {
    position: absolute;
    z-index: 6;
    background: #ffffff;
    border-radius: 50%;
    left:  21%;
    right: 21%;
    bottom:20%;
    top: 20%;
  }
  .component-main {
    display:flex;
    position: absolute;
    z-index: 7;
    left:  0;
    right: 0;
    bottom:0;
    top: 0;
  }

  .radioboxGroup {
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .radiobtnGroup {
    width: 100%;
    height: 50px;
    display: inline-block;
        margin:2px
  }
  .radiobtnGroup .radioinpGroup {
    display: none;
  }

  .radiobtnGroup .radiospanGroup:hover {
    /* background-color: hsla(0, 0%, 100%, 0.063); */
    cursor: pointer;
  }

  .radiobtnGroup .radiospanGroup:before {
    background-color: rgb(198, 22, 22);
  }


  .radiobtnGroup .radiospanGroup {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(150, 150, 150, 0.368);
    border-radius: 10px;
    background-color:  rgba(150, 150, 150, 0.089);
    justify-content: center;
    align-items: center;
    color:rgb(0, 0, 0);
    padding: 5px;

  }

  .radiobtnGroup input:checked + span{
    background-color:  rgba(249, 124, 28, 0.904);
    border: 1px solid rgb(252, 118, 15);
    color:rgb(255, 255, 255)
  }

</style>



<style scoped lang="scss">
  @import "compass/css3";


    $icon-size: 120px; /* [2] */
    $menu-size: 300px / 2; /* [3] */
    $unit-size: 45px;  /* [4] */
$menu-position: (($menu-size * 2) - $icon-size) / -2;
       $offset: 100px; /* [5] */


/* Styles: */
  .icon-disable,.icon {
    position: relative;
    background: rgba(#f9701c,1);

    cursor: pointer;
    margin: auto;
    color: #ffffff;
    // border: 5px solid #f9701c;
    border-radius: 50%;
    height: $icon-size*1.5;
    width: $icon-size*1.5;
    line-height: $icon-size*1.5;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    transition: 0.24s 0.2s;
    // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    // box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .icon-hover {
        background: #b1b1b1;
    height: $icon-size;
    width: $icon-size;
    line-height: $icon-size;
    font-size: 1rem;
    .menu {
      transition: transform 0.4s 0.08s,
      z-index   0s  0.5s;
      transform: scale(1);
      z-index: 5;
    }
    .spread {
      transition: all 0.6s;
    }
    .unit {
      // transition: all 0.6s;
      color:rgb(255, 255, 255)
    }
  }

  .menu {
    position: absolute;
    top: $menu-position;
    left: $menu-position;
    border: $menu-size solid transparent;
    cursor: default;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 1.4s 0.07s;
    z-index: -5;
    margin: 0px;
    padding: 0px;
  }

  .spread {
    position: absolute;
    top: -($offset);
    left: -($offset);
    transform-origin: $offset $offset;
    transition: all 0.5s 0.1s;
  }

// .icon-hover {
	//  .menu {
	// 	 transition: transform 0.4s 0.08s,
	// 			  				        z-index   0s  0.5s;
  // 	 transform: scale(1);
  // 	 z-index: 5;
	//  }
	//  .spread, .unit {
	//  	 transition: all 0.6s;
	//  }
  
/* Where all the MAGIC happens: */
	//  @for $m from 1 through $li {
	//    .spread:nth-child(#{$m}) {
	// 	    transition-delay: $m * 0.02s;
	// 	    transform: rotate(45deg + $deg * $m - $deg);
	// 	    .unit {
	// 		    transition-delay: $m * 0.04s;
  //         transform: rotate(45deg);
	// 	    }
	//    }
  // }
// } /* END .icon:hover */

  .unit {
    position: absolute;
    
    background: #272c2f;
    font-size: 0.8rem;
    text-decoration: none;
    width: $unit-size*1.5;
    height: $unit-size;
    line-height: $unit-size;
    color: rgb(255, 255, 255);
    border-radius: 50%;
    transition: 0.6s;
    display: flex;
    justify-content: center;
    
  }


  ul,li{margin:0;outline:0;padding:0;list-style:none}




</style>