<template>
  <div :dir="dict.dir[lang]">
    <b-container fluid style="padding: 0 !important">
      <ejs-grid
        ref="wb_role_manager"
        :allowSorting="true"
        :locale="lang"
        :key="curTableOptions.items.length"
        :allowExcelExport="true"
        :allowFiltering="true"
        :editSettings="curTableOptions.editSettings"
        :allowPdfExport="true"
        :toolbar="toolbarGenerator"
        :pdfExportComplete="pdfExportComplete"
        :toolbarClick="toolbarClick"
        :autoFit="true"
        :enableAdaptiveUI="true"
        :allowPaging="true"
        :enableRtl="lang == 'per'"
        :pageSettings="{ pageSize: 5,pageSizes: [5, 10, 20, 50] }"
        :selectionSettings="curTableOptions.selectionOptions"
        :dataSource="curTableOptions.items"
      >
        <e-columns>
          <e-column width="50" type="checkbox"></e-column>
          <e-column
            v-for="(item, index) in curTableOptions.fields"
            :key="index"
            :field="item.key"
            :headerText="item[lang]"
            :template="item.hasTemplate ? item.key : null"
          ></e-column>
        </e-columns>

        <template v-slot:modify="{ data }">
          <div class="d-flex justify-content-between col-md-3">
            <span
              @click="getRoleInfo(data._id, $event)"
              v-permission="'wb_edit_role'"
              v-if="data.name!='admin'"
              class="e-icons e-edit-6">
            </span>
            <span
              v-permission="'wb_remove_role'"
              v-if="data.name!='admin'"
              class="e-icons e-delete-1 text-danger" style="font-size: 16px"
              @click="removeItem(data._id, $event)"
            ></span>
          </div>
        </template>
      </ejs-grid>
    </b-container>
    <b-modal
      id="createRole"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      content-class="shadow"
      scrollable
      :dir="dict.dir[lang]"
    >
      <template #modal-header>
        <h5>{{ modalTitle }}</h5>
        <i @click="closeModal">
          <font-awesome-icon icon="fas fa-xmark" class="close" />
        </i>
      </template>
      <div class="py-3" id="roleName">
        <ejs-textbox
          :autocomplete="'off'"
          :placeholder="dict.fieldTitle[lang]"
          :enableRtl="lang=='per'"
          v-model="name"
          :required="true"
          floatLabelType="Auto"
        ></ejs-textbox>
        <div dir="ltr" class="accordion col-12 my-3" role="tablist">
          <b-card no-body class="mb-1">
            <ejs-accordion :key="reRender" expandMode="Single" :enableRtl="lang == 'per'">
              <div
                v-for="(item, tabName) in permissions"
                :key="'acc' + tabName"
              >
                <div>
                  <div> {{ dict[tabName][lang] }}</div>
                </div>
                <div class="row">
                  <div
                    v-for="(btn, i) in item"
                    :key="i"
                    class="col-md-3 col-12 py-2"
                  >

                    <p v-if="btn.permissionTab!=null" class="text-bold">{{ btn[lang] }}</p>
                    <div v-if="btn.permissionTab==null">
                      <ejs-switch
                        @change="getVal($event,btn.value,btn._id)"
                        :enableRtl="lang == 'per'"
                        :checked="selectedPermissions[btn.value] ? true:false "
                        :value="btn._id"
                      />
                      <span class="mx-2">{{ btn[lang] }}</span>
                    </div>

                    <div
                      class="py-2"
                      v-for="(btn2, i) in btn.permissionTab"
                      :key="i">
                      <ejs-switch
                        @change="getVal($event,btn2.value, btn2._id)"
                        :enableRtl="lang == 'per'"
                        :value="btn2._id"
                        :checked="selectedPermissions[btn2.value] ? true:false"
                      />
                      <span class="mx-2">{{ btn2[lang] }}</span>
                    </div>
                  </div>
                </div>
              </div>

            </ejs-accordion>
          </b-card>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex gap-3">
          <b-button variant="secondary" class="p-0" @click="closeModal">
            {{ dict.cancel[lang] }}
          </b-button>
          <b-button variant="primary" @click="storeRole">
            <b-spinner v-if="loading" small label="Loading..."></b-spinner>
            <span v-else>{{
                dict.ok[lang]
              }}</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import {
  AccordionComponent
} from "@syncfusion/ej2-vue-navigations";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { log10 } from "chart.js/helpers";
import { DialogUtility } from "@syncfusion/ej2-popups";
import err from "@/lacale/errors.json";
import store from "@/store";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";

export default {
  name: "roleManager",
  components: {
    "ejs-accordion": AccordionComponent
  },
  data() {
    return {
      sorce: ["Badminton", "Cricket", "Football", "Golf", "Tennis"],
      categoryList: [],
      selectedCats: [],
      curTableOptions: {
        selectionOptions: {
          type: "Multiple",
          persistSelection: false,
          checkboxOnly: true
        },
        editSettings: {
          allowEditing: false,
          allowAdding: true,
          allowDeleting: true,
          mode: "Dialog"
        },
        items: [],
        name: "",
        fields: [
          {
            key: "name",
            eng: "name",
            per: "نام",
            hasTemplate: false
          },
          { key: "modify", per: "", eng: "", hasTemplate: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      dict: {
        err: { eng: "choose at least one permission.", per: "نقش کاربر حتما باید حداقل یک دسترسی داشته باشد." },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the role ?",
          per: "آیا از حذف این نقش اطمینان دارید ؟"
        },
        dir: { eng: "ltr", per: "rtl" },
        newRole: { eng: "new role", per: "نقش جدید" },
        title: { eng: "process flow diagram", per: "دیاگرام چرخه فرآیند" },
        fieldTitle: { eng: "role name", per: "عنوان نقش" },
        tab1: { eng: "process flow diagram", per: "چرخه فرآیند" },
        mapName: { eng: "map name", per: "نام نقشه" },
        enterMapName: { eng: "enter map name", per: "نام نقشه را وارد کنید" },
        modalTitle: { eng: "create role", per: "ایجاد نقش" },
        modalTitleEdit: { eng: "edit role", per: "ویرایش نقش" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        confirmRemoveTitle: { eng: "remove confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to close this window?",
          per: "آیا از بستن این پنجره اطمینان دارید؟"
        },
        confirmRemoveText: {
          eng: "Are you sure to delete this role?",
          per: "آیا از حذف این نقش اطمینان دارید؟"
        },

        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" },
        dashboard: { eng: "dashboard", per: "دشبورد" },
        menu: { eng: "sidebar menu", per: "منوی سایدبار" },
        hardwareConfigure: {
          eng: "hardware Confiurator",
          per: "پیکره بندی سخت افزار"
        },
        controllers: { eng: "controllers", per: "کنترل کننده ها" },
        dataManager: { eng: "data manager", per: "مدیریت اطلاعات" },
        workbenchManager: { eng: "workbench manager", per: "مدیریت میزکار" },
        adminPanel: { eng: "admin panel", per: "پنل ادمین" },
        mapManager: { eng: "map manager", per: "مدیریت نقشه " },
        processflowManager: { eng: "process flow manager", per: "مدیریت چرخه فرآیند " },
        dataArchive: { eng: "data archive manager", per: "مدیریت آرشیو داده ها " },
        eventManager: { eng: "event manager", per: "مدیریت رویدادها " }
      },
      list: [],
      selectedPermissions: {},
      loading: false,
      editMode: false,
      activeAcc: null,
      id: "",
      permissions: {
        menu: [
          {
            per: "پیکره بندی سخت افزار",
            eng: "hardware configurator",
            value: "menu_hardware_config",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت کنترلرها",
            eng: "controller manager",
            value: "menu_controller_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت داده",
            eng: "data manager",
            value: "menu_data_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت میزکار",
            eng: "workbench manager",
            value: "menu_workbench_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "پنل ادمین",
            eng: "admin panel",
            value: "menu_admin_panel",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت نقشه",
            eng: "map manager",
            value: "menu_map_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت رویداد",
            eng: "event manager",
            value: "menu_event_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت چرخه فرآیند",
            eng: "process flow manager",
            value: "menu_processflow_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "آرشیو داده",
            eng: "data archive",
            value: "menu_data_archive",
            permissionTab: null,
            _id: ""
          }
        ],

        dashboard: [
          {
            per: "ویرایش ویجت ها",
            eng: "edit widgets",
            value: "edit_widgets",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف تکی ویجت",
            eng: "remove the widget",
            value: "remove_single_widget",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش تکی ویجت",
            eng: "edit the widget",
            value: "edit_single_widget",
            permissionTab: null,
            _id: ""
          },
          {
            per: "افزودن ویجت",
            eng: "add widgets",
            value: "add_widgets",
            permissionTab: null,
            _id: ""
          },
          {
            per: "افزودن صفحه",
            eng: "add page",
            value: "add_page",
            permissionTab: null,
            _id: ""
          },
          {
            per: "نمایش صفحات",
            eng: "pagination show",
            value: "pagination_show",
            permissionTab: null,
            _id: ""
          },
          {
            per: "لیست ویجت ها",
            eng: "widgets list",
            value: "widgets_list",
            permissionTab: null,
            _id: ""
          }
        ],
        hardwareConfigure: [
          {
            per: "افزودن سرور جدید",
            eng: "add new server",
            value: "hardware_add_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش سرور",
            eng: "edit server",
            value: "hardware_edit_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف سرور",
            eng: "remove server",
            value: "hardware_remove_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "نمایش لیست سرورها",
            eng: "show servers list",
            value: "hardware_show_servers_list",
            permissionTab: null,
            _id: ""
          },
          {
            per: "بارگذاری فایل سخت افزاری",
            eng: "upload configure",
            value: "hardware_upload_configure",
            permissionTab: null,
            _id: ""
          }
        ],
        controllers: [
          {
            per: "افزودن کنترلر",
            eng: "add new node",
            value: "controller_new_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش اتصال کنترلر",
            eng: "edit connection",
            value: "controller_edit_connection",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش کنترلر",
            eng: "edit node",
            value: "controller_edit_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف کنترلر",
            eng: "delete node",
            value: "controller_delete_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ایجاد لینک",
            eng: "connect node",
            value: "controller_connect_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف لینک",
            eng: " remove connection",
            value: "controller_remove_connection",
            permissionTab: null,
            _id: ""
          }
        ],
        dataManager: [
          {
            per: "ایجاد داده",
            eng: "add data",
            value: "dataManager_add",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش داده",
            eng: "edit data",
            value: "dataManager_edit",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف داده",
            eng: "remove data",
            value: "dataManager_remove",
            permissionTab: null,
            _id: ""
          }
        ],
        processflowManager: [
          {
            per: "ایجاد فرآیند",
            eng: "add process",
            value: "process_add",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش داده",
            eng: "edit process",
            value: "process_edit",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف داده",
            eng: "remove process",
            value: "process_remove",
            permissionTab: null,
            _id: ""
          }
        ],
        dataArchive: [
          {
            per: "ایجاد آرشیو",
            eng: "add archive",
            value: "archive_add",
            permissionTab: null,
            _id: ""
          }
        ],
        workbenchManager: [
          {
            per: "سربرگ مشخصات میزکار",
            eng: "add data",
            value: "properties_tab",
            permissionTab: [
              {
                per: "ویرایش نام میزکار",
                eng: "edit name",
                value: "wb_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تغییر مالکیت میزکار",
                eng: "edit owner",
                value: "wb_edit_owner",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کاربران",
            eng: "user management tab",
            value: "user_management_tab",
            permissionTab: [
              {
                per: "دعوت کاربر",
                eng: " invite user",
                value: "wb_invite_user",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف کاربر",
                eng: " remove user",
                value: "wb_remove_user",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ایجاد نقش",
                eng: "role manager",
                value: "wb_role_manager",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت نقش ها",
            eng: "roles management tab",
            value: "roles_management_tab",
            permissionTab: [
              {
                per: "ایجاد نقش",
                eng: "add role",
                value: "wb_add_role",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش نقش",
                eng: "edit role",
                value: "wb_edit_role",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف نقش",
                eng: "remove role",
                value: "wb_remove_role",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ],
        adminPanel: [
          {
            per: "سربرگ مدیریت منابع",
            eng: "license manager",
            value: "license_tab",
            permissionTab: [
              {
                per: "بارگذاری فایل لایسنس",
                eng: "upload license",
                value: "admin_upload_license",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تخصیص منابع",
                eng: "allocate resources",
                value: "admin_allocate_resource",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت میزکارها",
            eng: "workbenches manager",
            value: "workbenches_tab",
            permissionTab: [
              {
                per: "ایجاد میزکار",
                eng: "add new workbench",
                value: "admin_new_wb",
                permissionTab: null,
                _id: ""
              },
              {
                per: "مشاهده لیست کاربران میزکار",
                eng: "workbench's users list",
                value: "admin_workbench's_users_list",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کاربران",
            eng: "users manager",
            value: "users_tab",
            permissionTab: [
              {
                per: "ویرایش اطلاعات کاربر",
                eng: "edit user data",
                value: "admin_user_data",
                permissionTab: null,
                _id: ""
              },
              {
                per: "لیست میزکارهای کاربران",
                eng: "workbench's users list",
                value: "admin_workbench_users",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کانکشن ها",
            eng: "connections manager",
            value: "connections_tab",
            permissionTab: [
              {
                per: "ایجاد کانکشن",
                eng: "add connection",
                value: "admin_add_connection",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف کانکشن",
                eng: "remove connection",
                value: "admin_remove_connection",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تغییر وضعیت کانکشن",
                eng: "change status",
                value: "admin_status",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ],
        mapManager: [
          {
            per: "سربرگ مدیریت نقشه",
            eng: "map tab",
            value: "map_tab",
            permissionTab: [
              {
                per: "بارگذاری فایل نقشه",
                eng: "upload indoor map",
                value: "map_upload",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش فایل نقشه",
                eng: "edit indoor map",
                value: "map_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف فایل نقشه",
                eng: "remove indoor map",
                value: "map_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ],
        eventManager: [
          {
            per: "سربرگ رویدادهای کنونی",
            eng: "current alarms tab",
            value: "event_current",
            permissionTab: [
              {
                per: "فعال کردن رویداد",
                eng: "acknowledge event",
                value: "event_acknowledge",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ رویدادهای آرشیو شده",
            eng: "archived alarms tab",
            value: "event_archives",
            permissionTab: [
              {
                per: "مشاهده لیست آرشیوها",
                eng: "show archived list",
                value: "event_archive_list",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ انواع رویدادها",
            eng: "event type tab",
            value: "event_types",
            permissionTab: [
              {
                per: "ایجاد نوع جدید رویداد",
                eng: "add event type",
                value: "event_type_add",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش نوع رویداد",
                eng: "edit event type",
                value: "event_type_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف نوع رویداد",
                eng: "remove event type",
                value: "event_type_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ قوانین رویدادها",
            eng: "event rules tab",
            value: "event_rules",
            permissionTab: [
              {
                per: "ایجاد رویداد",
                eng: "add event rule",
                value: "event_rule_add",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش رویداد",
                eng: "edit event rule",
                value: "event_rule_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف رویداد",
                eng: "remove event rule",
                value: "event_rule_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ]
      },
      listPermission: [],
      validator: null,
      reRender: 0,
      name: ""
    };
  },
  computed: {
    toolbarGenerator() {
      if (this.userPermission.includes("wb_add_role")) {
        return ["Search", {
          text: this.dict.newRole[this.lang],
          id: "add_role",
          prefixIcon: "e-icons e-add"
        }];
      } else return ["Search"];
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    userPermission() {
      return this.$store.state.data.user.permissions;
    },
    modalTitle() {
      if (this.editMode) return this.dict.modalTitleEdit[this.lang];
      else return this.dict.modalTitle[this.lang];
    }
  },
  methods: {
    getVal(val, name, id) {
      if (val.checked) {
        this.selectedPermissions[name] = id;
      } else {
        delete this.selectedPermissions[name];
      }
    },
    toolbarClick(args) {
      if (args.item.id.includes("pdfexport")) {
        this.$refs.wb_role_manager.showSpinner();
        this.$refs.wb_role_manager.pdfExport();
      } else if (args.item.id.includes("excelexport")) {
        this.$refs.wb_role_manager.excelExport();
      } else if (args.item.id.includes("csvexport")) {
        this.$refs.wb_role_manager.csvExport();
      } else if (args.item.id.includes("add_role")) {
        this.openModal();
      }
    },
    pdfExportComplete() {
      this.$refs.wb_role_manager.hideSpinner();
    },
    getUserDatas() {
      this.$store.dispatch("data/getUserData");
    },
    getList() {
      this.$store.dispatch("data/getPermission").then((res) => {
        this.categoryList = [];
        this.$store.state.data.permissionList.message.forEach((x) => {
          if (x.category[0] != undefined && x.category[0] != "")

            this.permissions[x.category[0]].forEach((per) => {

              if (per.permissionTab == null && per.value == x.name) {
                per._id = x._id;
              }
              if (per.permissionTab != null)
                per.permissionTab.forEach((sub) => {
                  if (sub.value == x.name)
                    sub._id = x._id;
                });

            });
        });
        this.list = res.message;
      });
    },
    getRoles() {
      this.$store.dispatch("data/getRolesList").then((res) => {
        this.curTableOptions.items = this.$store.state.data.roleList.message;
        this.curTableOptions.totalRows =
          this.$store.state.data.roleList.message.length;
      });
    },
    getRoleInfo(id) {
      this.editMode = true;
      this.id = id;
      this.$store.dispatch("data/getRoleData", { _id: id }).then((res) => {
        this.name = res.message.name;
        res.message.permissions.forEach((i) => {
          Object.values(i).forEach((cat) => {
            cat.forEach((n) => {
              this.selectedPermissions[n.name] = n.id;
            });
          });
        });
        this.reRender += 1;
        this.$bvModal.show("createRole");

      });
    },
    storeRole($bv) {
      $bv.preventDefault();
      this.loading = true;
      let permission = [];
      Object.entries(this.selectedPermissions).forEach((item) => {
        permission.push(item[1]);
      });
      const isValid = new FormValidator("#roleName").validate();
      if (isValid && permission.length > 0) {
        if (!this.editMode) {
          const data = {
            name: this.name,
            permissions: permission
          };
          this.$store
            .dispatch("data/createRole", data)
            .then((res) => {
              this.loading = false;
              this.selectedPermissions = {};
              this.categoryList = [];
              this.selectedCats = [];
              this.name = "";
              this.getRoles();
              this.getList();
              this.$bvModal.hide("createRole");
            })
            .catch((err) => {
              this.loading = false;
            });
        } else this.updateRole();
      } else {
        this.loading = false;
        ToastUtility.show({
          content: this.dict.err[this.lang],
          timeOut: 3000,
          cssClass: "e-toast-danger", // Add a custom class for Error styling
          position: { X: "Center", Y: "Center" }, // Adjust the position
          showCloseButton: true,
          enableRtl: store.state.chpge.lang == "per" ? true : false
        });
      }
    },
    removeItem(id) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeRoleById", { _id: id }).then(() => {
              this.getRoles();
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    openModal() {
      this.name = "";
      this.editMode = false;
      this.$bvModal.show("createRole");
    },
    closeModal() {
      this.name = "";
      this.selectedPermissions = {};
      this.selectedCats = [];
      this.$bvModal.hide("createRole");
    },
    updateRole() {
      let permission = [];
      Object.entries(this.selectedPermissions).forEach((item) => {
        permission.push(item[1]);
      });
      this.$store
        .dispatch("data/updateRoleInfo", {
          name: this.name,
          _id: this.id,
          permissions: permission
        })
        .then((res) => {
          this.selectedPermissions = {};
          this.name = "";
          this.$bvModal.hide("createRole");
          this.getRoles();
          this.getUserDatas();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getList();
    this.getRoles();
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>
<style>
* {
  font-family: Vazir, serif;
}

#defaultLayout .e-panel .e-panel-container {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.panel-content {
  line-height: 80px;
}

#defaultLayout .e-panel {
  transition: none !important;
}
</style>
