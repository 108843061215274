<template>
    <div class="switch-holder">
      <div class="switch-label"><i></i><span>{{switchprops.title}}</span></div>
      <div>
        <input v-model="switchprops.status" type="checkbox" name=""  @change="toggleHandler" true-value="on" false-value="off"/>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
  export default {
    props: {
      switchprops: Object,
    },
    methods: {
      toggleHandler: function () {
        // console.log(JSON.stringify(this.switchprops))
        this.$store.dispatch('panel/toggleSwitch', this.switchprops)
          .then(
              data => {
              },
              error => {
              var message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              alert(message)
              }
          );
      }
    },
  };
</script>

<style scoped src="@/assets/styles/mystyle1.css"></style>