<template>
    <div style="text-align:left">
        <div>
            <b-container fluid="md">
                <b-row class=my-5>
                    <b-col cols="1"></b-col>
                    <b-col cols="10" sm>
                        <b-card no-body>
                            <b-card-body>     
                                <b-form ref="form" @submit.stop.prevent="handleRegister" @reset="onReset">
                                    
                                    <div style="display: flex; align-items: center; flex-wrap: wrap;">
                                        <h4 style="flex: 90%;">{{ title}}</h4>
                                        <b-button type="submit"  variant="outline-success"  class="mr-2" style="padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="check-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                        <b-button type="reset"  variant="outline-danger"  class="mr-2" style="margin-left: 10px; padding: 0px; flex: 1%; outline: none !important; border: none; box-shadow: none;">
                                            <b-icon icon="x-square-fill" font-scale="2" class="border rounded p-1" aria-hidden="true"></b-icon>
                                        </b-button>
                                    </div>

                                    <hr class="my-2">
                                
                                    <b-list-group flush>
                                        <b-list-group-item style="background-color:transparent;color:black;">
        
                                            <div style="display:flex; flex-direction:row">
                                                <b-form-group
                                                    label="floor Id"
                                                    label-for="Id"                                            
                                                    :invalid-feedback="errors.first('Id')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        disabled
                                                        name="Id"
                                                        type="text"
                                                        placeholder="Enter Id"
                                                        v-model="floor.floorId"
                                                        :state="(submitted)? (!errors.has('Id')): null"  
                                                        v-validate="'required|min:0|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>   

                                                <b-form-group
                                                    label="floor title"
                                                    label-for="title"                                            
                                                    :invalid-feedback="errors.first('title')"
                                                    style="width:100%; padding: 0px 10px 10px 0px;"
                                                >
                                                    <b-form-input
                                                        name="title"
                                                        type="text"
                                                        placeholder="Enter title"
                                                        v-model="floor.floorTitle"
                                                        :state="(submitted)? (!errors.has('title')): null"  
                                                        v-validate="'required|min:3|max:20'"
                                                        style="width:100%"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </div>
                                            
                                            

                                            

                                        </b-list-group-item>
                                    </b-list-group>
                                </b-form>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container> 
        </div>
    </div>
</template>

<script>
/* eslint-disable */
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "floorEdit",
        data() {
            return {
                floor:{
                    floorId:'',
                    floorTitle:'',
                    floorUuid:''
                    //uuid:''
                },

                submitted: false,
                successful: false,
                message: '',
                title:'',
            }
        },
        computed: {
            type() {
                return this.$store.state.chpge.type;
            },
            db() {
                return this.$store.state.chpge.db;
            },
            index() {
                return this.$store.state.chpge.index;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        methods: {
            initModal() {
                this.title = this.type + " Floor"

                if (this.type==='Add')
                {
                    this.floor.floorUuid = uuidv4();
                }
                else if (this.type==='Edit')
                {
                    this.floor.floorId = this.db[this.index].floorId;
                    this.floor.floorTitle = this.db[this.index].floorTitle;
                    this.floor.floorUuid = this.db[this.index].floorUuid;
                }
            },
            onReset() {
                this.floor.floorId = null
                this.floor.floorTitle = null
                this.floor.floorUuid = null
                this.$store.dispatch('chpge/changePage','buildingSettings')
            },
            handleRegister() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if (this.type==='Add'){
                            // this.$store.dispatch('data/createBuild', this.floor).then(
                            //     data => {
                                    
                            //     this.message = data.message;
                            //     this.successful = true;
                            //     alert(this.message)
                            //     this.$store.dispatch('chpge/changePage','buildingSettings')
                            //     },
                            //     error => {
                            //     this.message =
                            //         (error.response && error.response.data && error.response.data.message) ||
                            //         error.message ||
                            //         error.toString();
                            //     this.successful = false;
                            //     alert(this.message)
                            //     }
                            // );
                        }else if (this.type==='Edit'){
                            this.$store.dispatch('data/editFloor', this.floor).then(
                                data => {
                                    
                                this.message = data.message;
                                this.successful = true;
                                //alert(this.message)
                                this.$store.dispatch('chpge/changePage','buildingSettings')
                                },
                                error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.successful = false;
                                // alert(this.message)
                                }
                            );
                        }
                    }
                });
            }
        },
        mounted() {
            this.initModal()
        }
    };
</script>
