<template>
  <div style="position:absolute; top:0px; left:0px; right:0px; bottom:0px; ">
    <img src="../assets/images/fava.jpeg" class="card-img card-img-top "  height="100%" />

    <!-- <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000; height:100%"
      fade
      indicators
      img-width="1024"
      img-height="480"
    >
      <b-carousel-slide
        caption="گلخانه پارک فاوا"
        img-src="https://picsum.photos/1024/480/?image=10"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Second Slide"
        img-src="https://picsum.photos/1024/480/?image=12"
      ></b-carousel-slide>
      <b-carousel-slide
        caption="Third Slide"
        img-src="https://picsum.photos/1024/480/?image=22"
      ></b-carousel-slide>
    </b-carousel> -->
  </div>






</template>

<script>
  import {winsize} from '../models/host';
  export default {
    data() {
      return {
        height: winsize.height,
      }
    }
  }
  
  // height="100%"
</script>


