<template>
    <div >
        <div 
          v-if="database.data && checkSimilarity1(value.selected)" 
          :ref="database.id" 
          :id="database.id" 
          :class="database.type" 
          :type="database.type" 
          style="background-color:#2643e1"
        >
          <div style="text-align: center; color:#ffffff;"> <p style="margin-bottom: 0px; margin-top: 5px;"><strong> {{type}}</strong></p> </div>
          <hr style="margin-top: 1px;margin-bottom: 1px; color:#ffffff;">
          <div style="text-align: center; color:#ffffff;"> <i> {{title}}</i> </div>
          <hr style="margin-top: 1px;margin-bottom: 1px; color:#ffffff;">
          <div v-if="database.data">
            <b-button   v-for="g in  database.data.length" 
                        :key="g" 
                        :variant= "checkSimilarity2(database.data[g-1])?'light':'outline-light'"
                        class="data_button"

                        @click="handleButton(database.data[g-1])"
            >{{'D'+(g-1)}}</b-button>
          </div>
        </div>
        <div v-else-if="database.data" :ref="database.id" :id="database.id" :class="database.type" :type="database.type">
          <div style="text-align: center;"> <p style="margin-bottom: 0px; margin-top: 5px;"><strong> {{type}}</strong></p> </div>
            <hr style="margin-top: 1px;margin-bottom: 1px;">
            <div style="text-align: center;"> <i> {{title}}</i> </div>
            <hr style="margin-top: 1px;margin-bottom: 1px;">
            <b-button   v-for="g in  database.data.length" 
                        :key="g" 
                        :variant = "checkSimilarity2(database.data[g-1])?'secondary':'outline-primary'"
                        :disabled = "checkSimilarity2(database.data[g-1])"
                        class="data_button"
                        @click="handleButton(database.data[g-1])"
            >{{'D'+(g-1)}}</b-button>
        </div>
        <div v-else :ref="database.id" :id="database.id" :class="database.type" :type="database.type">
          <div style="text-align: center;"> <p style="margin-bottom: 0px; margin-top: 5px;"><strong> {{type}}</strong></p> </div>
            <hr style="margin-top: 1px;margin-bottom: 1px;">
            <div style="text-align: center;"> <i> {{title}}</i> </div>
            <hr style="margin-top: 1px;margin-bottom: 1px;">
        </div>
    </div>
</template>

<script>
export default {
  name: 'controllerSettings1',
    props: {
        database: Object,
        value: Object,
        to: Object,
    },
    data() {
        return {
            title:'',
            type:'',

            types : { slot_DI8: 'DI 8',
                      slot_DI16: 'DI 16',
                      slot_DO8: 'DO 8',
                      slot_DO16: 'DO 16',
                      slot_DO4pb:'DO 4 Push but.',
                      slot_DO8pb:'DO 8 Push-but.',
                      slot_DO16pb:'DO 16 Push-but.',
                      slot_DI4DO4:'DI 4 DO 4',
                      slot_DI8DO8:'DI 8 DO 8',
                      slot_AI6:'AI 4',
                      slot_AO6:'AO 4',
                      slot_RTD4:'RTD 4',
                      slave_E: 'Extend. RTU',
                      slave_S: 'RTU sensor',
                      slave_SC: 'RTU termostat',
                      Master: 'TCP RTU',
                      Server: 'Server'}
        };
    },
    mounted() {
      this.title = (this.database.name)? this.database.name:this.database.topic;
      this.type = this.types[this.database.type]
      console.log('DS lllllllllllllllllllllllll',this.database)
    },
    methods: {
        handleButton: function(id,event){
          // console.log(id)
          // console.log(this.to)
          this.$store.dispatch('chpge/child2parent', {task: this.to.task, to:{type:this.to.type, id:this.to.id}, index:this.value.index, id:id})
            this.$nextTick(() => {
            this.$bvModal.hide('modal-MTR22-selector')
          })
        },
        checkSimilarity1: function (sample) {
          var same = this.database.data.filter(obj => {
            return (obj.iden === sample.iden && obj.pos === sample.pos && obj.dir === sample.dir)
          })
          if (same.length>0)
            return true;
          else
            return false
        },
        checkSimilarity2: function (sample) {
          var same = this.value.locked.filter(obj => {
            return (obj.iden === sample.iden && obj.pos === sample.pos && obj.dir === sample.dir)
          })
          if (same.length>0)
            return true;
          else
            return false
          
        }
    }
};
</script>

<style scoped>



.Server {
  position: relative;
  height: 200px;
  width: 100px;
  border : 2px solid hsla(166, 100%, 20%, 0.3);;
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.Server:hover {
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}

.Master {
  position: relative;
  height: 200px;
  width: 100px;
  border : 2px solid hsla(166, 100%, 20%, 0.3);;
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.Master:hover {
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}

.slave_E {
  position: relative;
  height: 200px;
  width: 100px;
  border : 2px solid hsla(166, 100%, 20%, 0.3);;
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.slave_E:hover {
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}

.slave_SC, .slave_S {
  position: relative;
  height: 150px;
  width: 200px;
  border : 2px solid hsla(166, 100%, 20%, 0.3);;
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}

.slave_SC:hover, .slave_S:hover {
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}

.slot_DI8,.slot_DI16,.slot_DO8,.slot_DO16,.slot_DO4pb,.slot_DO8pb,.slot_DO16pb,.slot_DI4DO4,.slot_DI8DO8,.slot_AI6,.slot_AO6,.slot_RTD4 {
  position: relative;
  height: 200px;
  width: 100px;
  border : 2px solid hsla(166, 100%, 20%, 0.3);;
  background-color: hsla(166, 100%, 35%, 0.05);
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  /* margin : -100px 0 0 -100px */
}
.slot_DI8:hover,.slot_DI16:hover,.slot_DO8:hover,.slot_DO16:hover,.slot_DO4pb:hover,.slot_DO8pb:hover,.slot_DO16pb:hover,.slot_DI4DO4:hover,.slot_DI8DO8:hover,.slot_AI6:hover,.slot_AO6:hover,.slot_RTD4:hover{
  background-color: hsla(166, 100%, 60%, 0.3);
  cursor: pointer;
}

.data_button {
  padding: 0;
  margin:1px; 
  width:30%; 
  height:10%; 
  font-size:12px;
}
</style>