<template>
  <b-modal
    id="modal-indoor-map"
    size="xl"
    centered
    header-border-variant="primary"
    header-class="my-modal-header "
    @shown="initModal"
    @hidden="resetModal"
  >
    <template #modal-header>
      <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-between">
        <h5>{{ dict.uploadIndoorMap[lang] }}</h5>
        <b-button variant="primary" @click="mapSetting">{{ dict.edit[lang] }}</b-button>
      </div>
    </template>

    <template #modal-footer>
    <div :dir="dict.dir[lang]" class="d-flex w-100 justify-content-end">
      <div class="d-flex" style="gap: 10px">
        <b-button variant="secondary" @click="resetModal">{{ dict.close[lang] }}</b-button>
      </div>
    </div>
  </template>

    <div id="mapContainer" class="mymap"></div>
    <mapZoneSetting />
    <mapSetting />
  </b-modal>

</template>

<script>
/* eslint-disable */
import { latLng } from "leaflet";
import L from "leaflet";
import mapZoneSetting from "./mapZoneSetting.vue";
import mapSetting from "./mapSetting.vue";

export default {
  name: "CustomPath",
  components: {
    mapZoneSetting,
    mapSetting
  },
  props: {
    plotId: String,
    data: Object
  },
  data() {
    return {
      mapDetalis: {},
      levels: null,
      markers: [],
      mymap: [],
      popup: L.popup(),

      to: "",
      imap: {},

      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: ""
      },
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],


      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "map manager", per: "مدیریت نقشه ها" },

        uploadIndoorMap: { eng: "edit indoor map", per: "ویرایش نقشه داخلی" },
        indoorMap: { eng: "map file", per: "فایل نقشه" },


        close: { eng: "Close", per: "بستن" },
        edit: { eng: "View settings", per: "تنظیمات نمایش" }
      }
    };

  },
  methods: {
    setupLeafletMap: function() {
      this.mymap = L.map("mapContainer");
    },
    zoneClick: function(e) {
      this.$root.$emit("bv::show::modal", "modal-2");
      this.$store.dispatch("chpge/parent2child", {
        task: "zoneGet",
        all: this.mapDetalis,
        item: this.mapDetalis.details.floorZones.find(item => item._id == e.target.options._id.zone)
      });
    },

    // zoneClick: function (e){
    //   this.$store.dispatch('chpge/parent2child', {task: 'zoneClick', type:'Edit', data: e.target.options._id})
    // },
    // zoneOver: function (e){
    //     e.target.setStyle({fillColorPrev: e.target.options.fillColor});
    //     e.target.setStyle({fillColor: '#96ceb4'});
    // },
    // zoneLeft: function (e){
    //     e.target.setStyle({fillColor: e.target.options.fillColorPrev});
    // },
    zoneOver: function(e) {
      e.target.setStyle({ fillColorPrev: e.target.options.fillColor });

      e.target.setStyle({ fillColor: "#ffff00" });
    },
    zoneLeft: function(e) {
      e.target.setStyle({ fillColor: e.target.options.fillColorPrev });
    },

    onMapZoom: function(e) {
      var zoomlevel = this.mymap.getZoom();
      if (zoomlevel > 21) {
        this.addMarkers();
      } else {
        this.removeMarkers();
      }
    },
    addMarkers: function() {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].addLayer(this.markers[j]);
        }
      }
    },
    removeMarkers: function(spaces) {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].removeLayer(this.markers[j]);
        }
      }
    },


    createSpaces: function(floor) {
      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      var mapOptions = {
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom,
        attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
        id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
        accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      };
      L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);
      L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);

      var pointList = [];
      for (var j = 0; j < floor.floorPoints.length; j++) {
        pointList[j] = latLng(floor.floorPoints[j].lat, floor.floorPoints[j].lng);
      }

      var geometry = new L.Polygon(pointList, floor.floorOptions);
      this.levels = new L.layerGroup;
      this.levels.addLayer(geometry);


      //sort zones based on order level floor.floorZones


      floor.floorZones.sort((a, b) => (+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0));
      // console.log(floor.floorZones)

      for (var zone of floor.floorZones) {
        pointList = [];
        for (var k = 0; k < zone.zonePoints.length; k++) {
          pointList[k] = latLng(zone.zonePoints[k].lat, zone.zonePoints[k].lng);
          console.log(zone.zonePoints[k]);
        }
        zone.zoneOptions["_id"] = { floor: floor._id, zone: zone._id };
        geometry = new L.Polygon(pointList, zone.zoneOptions);
        // if (zone.zoneOptions.type==="corridor" || zone.zoneOptions.type==="room")// || space.properties.stairs!=="undefined")
        // {
        geometry.on("click", this.zoneClick);
        geometry.on("mouseover", this.zoneOver);
        geometry.on("mouseout", this.zoneLeft);
        // }
        this.levels.addLayer(geometry);
      }

      this.levels.addTo(this.mymap);
    },
    getfloorMap(floor) {
      this.$store.dispatch("data/getSingleMapLayer", { id: floor }).then(
        data => {
          this.mapDetalis=data
          this.map.center = data.details.floorOptions.center;
          this.map.minZoom = data.details.floorOptions.minZoom;
          this.map.maxZoom = data.details.floorOptions.maxZoom;
          this.map.maxNativeZoom = data.details.floorOptions.maxNativeZoom;
          this.map.tileId = data.details.floorOptions.tileId;
          this.map.viewZoom = data.details.floorOptions.viewZoom;
          this.createSpaces(data.details);
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    initModal() {
      this.setupLeafletMap();
      this.getfloorMap(this.imap._id);
    },
    resetModal() {
      this.levels = null;
      this.markers = [];
      this.mymap = [];
      this.to = "";
      this.imap = {};
      this.map = {};

      this.$nextTick(() => {
        this.$bvModal.hide("modal-indoor-map");
      });
    },
    mapSetting: function(e) {
      this.$store.dispatch("data/getSingleMapLayer", { id: this.imap._id }).then(
        data => {
          this.$root.$emit("bv::show::modal", "modal-3");
          this.$store.dispatch("chpge/parent2child", {
            task: "settingGet",
            item: data
          });
        },
        error => {
          var message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          alert(message);
        }
      );
    }
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    updateMap() {
      return this.$store.state.panel.updateMap;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child(newValue) {
      if (newValue.task === "editIndoorMap") {
        this.to = newValue.from;
        this.imap = newValue.map;

      }
    },
    updateMap(newValue, oldValue) {
      this.getfloorMap(this.imap._id);
    }
  }

};
</script>

<style scoped>
.my-modal-header {
  display: block !important;
}

.mymap {
  min-height: 75vh;
  width: 100%;
}


</style>

