<template>
  <div>
    <b-modal
      id="modal-cloud-status-json-item"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      content-class="shadow"
      :title="modalTitle"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-form ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card no-body> -->
          <b-list-group horizontal="md">
            <b-form-group
              label="Topic"
              label-for="topic"
              :invalid-feedback="errors.first('topic')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="topic"
                type="text"
                placeholder="Enter topic"
                v-model="data.topic"
                :state="submitted ? !errors.has('topic') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Key"
              label-for="key"
              :invalid-feedback="errors.first('key')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="key"
                type="text"
                placeholder="Enter key"
                v-model="data.key"
                :state="submitted ? !errors.has('key') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
          </b-list-group>

          <b-list-group horizontal="md">
            <b-form-group
              label="Value"
              label-for="value"
              :invalid-feedback="errors.first('value')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="value"
                type="text"
                placeholder="Enter value"
                v-model="data.value"
                :state="submitted ? !errors.has('value') : null"
                v-validate="'required|min:1|max:200'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Timeout (ms)"
              label-for="timeout"
              :invalid-feedback="errors.first('timeout')"
              style="width: 100%; padding: 0px 10px 10px 0px"
            >
              <b-form-input
                name="timeout"
                type="text"
                placeholder="Enter timeout"
                v-model="data.timeout"
                :state="submitted ? !errors.has('timeout') : null"
                v-validate="'required|decimal:10|min_value:0|max_value:1000000'"
                style="width: 100%"
              ></b-form-input>
            </b-form-group>
          </b-list-group>

          <!-- </b-card> -->
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

    import { v4 as uuidv4 } from 'uuid';
    
    export default {
        name: "dataAdderModal",
        data() {
            return {
                modalTitle:'',
                type:'',
                
                data:{
                    _id:'',
                    topic:'',
                    key:'',
                    value:'',
                    timeout:'',
                },

                alertTypeList: [
                    { text: 'Select One', value: null },
                    { text: 'Alarm', value: 'alarm' },
                    { text: 'Warning', value: 'warning'},
                    { text: 'Notice', value: 'notice'},
                ],

                submitted: false,
                successful: false,
                message: '',
            }
        },
        computed: {
            parent2child() {
                return this.$store.state.chpge.parent2child;
            },
            updatePage() {
                return this.$store.state.chpge.reload;
            },
          lang() {
            return this.$store.state.chpge.lang;
          }
        },
        watch: {
            parent2child(newValue, oldValue) {
                if (newValue.task==='editStatJson'){
                    this.type = newValue.type;
                    this.modalTitle = newValue.type + ' Item';
                    this.data._id = (newValue.item._id || newValue.item._id===0)?newValue.item._id:''
                    this.data.topic = newValue.item.topic?newValue.item.topic:'';
                    this.data.key = newValue.item.key?newValue.item.key:null;
                    this.data.value = newValue.item.value?newValue.item.value:null;
                    this.data.timeout = newValue.item.timeout?newValue.item.timeout:'';
                    
               
                }
            }
        },
        methods: {
            initModal: function(){
            },
            resetModal: function(){
                this.type = '';
                this.modalTitle = '';
                
                this.data._id = ''
                this.data.topic = '';
                this.data.key = '';
                this.data.value = '';
                this.data.timeout = '';
                
                this.submitted = null;
                this.successful = null;
                this.message= null;
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                this.message = '';
                this.submitted = true;
                this.$validator.validate().then(isValid => {
                    if (isValid) {
                        if(this.type === "New")
                            this.data._id = uuidv4();

                        this.$store.dispatch('chpge/child2parent', {task: 'editStatJson', type:this.type, item:this.data})
                        this.$bvModal.hide('modal-cloud-status-json-item')
                    }
                });
            },
        }
    };
</script>

<style scoped>
    .arrow-up {
        color:#198754;
        cursor:pointer;
    }
    .arrow-up :hover{
        color:#9b9b9b;
    }
    .arrow-down {
        color:#dc3545;
        cursor:pointer;
    }
    .arrow-down :hover{
        color:#9b9b9b;
    }
</style>