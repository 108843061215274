<template>
  <div class="yekan">
    <b-modal
      id="modal-editNode"
      size="md"
      centered
      :dir="dict.dir[lang]"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <template #modal-header>
        <h5>{{ header }}</h5>
      </template>

      <div class="yekan">
        <b-form-group
          v-if="task==='updateCont'"
          :label="dict.title[lang]"
          label-for="title"
          :invalid-feedback="errors.first('title')"
          style="width:100%; padding: 0px 10px 10px 0px;"
        >
          <b-form-input
            :disabled="type==='Edit'"
            name="title"
            type="text"
            :placeholder="dict.titlePl[lang]"
            v-model="title"
            :state="(submitted)? (!errors.has('title')): null"
            v-validate="'required|min:3|max:500'"
            style="width:100%"
          ></b-form-input>
        </b-form-group>

        <div class="d-flex align-items-center" v-if="task==='updateTag'">
          <ejs-textbox
            :value="data.name"
            :placeholder="dict.Data[lang]"
            :enableRtl="lang == 'per'"
            floatLabelType="Auto"
            :showClearButton="true"
            v-model="data.name"
          ></ejs-textbox>
          <b-button @click="pickData" class="btn-sm" variant="primary"><span
            class="e-icons e-edit-6 "></span>
          </b-button>
        </div>
      </div>


    </b-modal>
    <dataPickerModal plotId="CEN" />
  </div>

</template>

<script>
/* eslint-disable */
import dataPickerModal from "./widgetPickDataModal.vue";

export default {
  name: "setEquipLight",
  components: {
    dataPickerModal

  },
  data() {
    return {
      header: "",
      title: "",
      node: null,
      type: "",
      task: "",
      successful: true,
      message: "",
      submitted: false,
      data: { name: "" },
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        title: { eng: "title", per: "عنوان" },
        titlePl: { eng: "Enter title", per: "عنوان را وارد کنید" },
        Data: { eng: "Data", per: "داده" },
        selectPl: { eng: "Select a data", per: "داده ای انتخاب کنید" },
        select: { eng: "Select", per: "انتخاب کردن" }
      }

    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    parent2child(newValue, oldValue) {
      if (newValue.task === "updateCont" || newValue.task === "updateTag") {
        this.task = newValue.task;
        this.type = newValue.type;
        this.node = newValue.data;
        if (newValue.task === "updateCont")
          this.header = `${this.type} controller`;
        else if (newValue.task === "updateTag")
          this.header = `${this.type} Tag`;
        if (Object.keys(this.node.data.data).length > 0)
          this.data = this.node.data.data;


      }
    },
    child2parent(newValue) {
      if ((newValue.task === "pickData") && (newValue.to === "this.plotId")) {
        if (newValue.item.type === "data") {
          this.data = newValue.item;
          // this.myData.datas.push({...newValue.item})
        }
      }
    }
  },
  methods: {
    handleOk() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          if (this.task === "updateCont") {
            this.node.data["title"] = this.title;
          } else if (this.task === "updateTag") {
            this.node.data["data"] = this.data;
          }
          this.$store.dispatch("chpge/child2parent", { task: this.task, type: this.type, data: this.node });
          this.$bvModal.hide("modal-editNode");
        }
      });
    },
    initModal: function() {
      this.title = this.node.data.title ? this.node.data.title : "";
    },
    resetModal: function() {
      this.title = "";
      this.node = null;
      this.message = "";
      this.submitted = false;
      this.data = { name: "" };
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "this.plotId",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          withChild: true,
          page: 1,
          perPage: 1000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-widget-pick-data-" + "CEN");

      // this.$store.dispatch('chpge/parent2child', {task: 'pickData', from:'pubsub', filter:{dataType:[], usedBy:[],parents:[]}})
      // this.$root.$emit('bv::show::modal', 'modal-pick-data');
    }
  }
};
</script>

