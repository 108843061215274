export const persianLacale = {
  schedule: {
    "day": "روز",
    "week": "هفته",
    "workWeek": "هفته کاری",
    "month": "ماه",
    "year": "سال",
    "agenda": "دستور کار",
    "weekAgenda": "Week Agenda",
    "workWeekAgenda": "Work Week Agenda",
    "monthAgenda": "Month Agenda",
    "today": "امروز",
    "noEvents": "هیچ رویدادی وجود ندارد.",
    "emptyContainer": "هیچ رویدادی برای امروز وجود ندارد.",
    "allDay": "همه روزها",
    "start": "آغاز",
    "end": "پایان",
    "more": "بیشتر",
    "close": "بستن",
    "cancel": "لغو",
    "noTitle": "(بدون عنوان)",
    "delete": "حذف",
    "deleteEvent": "حذف رویداد",
    "deleteMultipleEvent": "حذف رویدادهای چندتایی",
    "selectedItems": "آیتم های انتخاب شده",
    "deleteSeries": "حذف سری ها",
    "edit": "ویرایش",
    "editSeries": "ویرایش سری",
    "editEvent": "ویرایش رویداد",
    "createEvent": "ساخت",
    "subject": "عنوان",
    "addTitle": "افزودن عنوان",
    "moreDetails": "اطلاعات بیشتر",
    "moreEvents": "رویدادهای بیشتر",
    "save": "ذخیره",
    "editContent": "Do you want to edit only this event or entire series?",
    "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
    "deleteContent": "Are you sure you want to delete this event?",
    "deleteMultipleContent": "Are you sure you want to delete the selected events?",
    "newEvent": "New Event",
    "title": "عنوان",
    "location": "مکان",
    "description": "توضیحات",
    "timezone": "موقعیت زمانی",
    "startTimezone": "شروع زمانی",
    "endTimezone": "پایان زمانی",
    "repeat": "تکرار",
    "saveButton": "ذخیره",
    "cancelButton": "لغو",
    "deleteButton": "حذف",
    "recurrence": "Recurrence",
    "wrongPattern": "The recurrence pattern is not valid.",
    "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
    "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
    "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
    "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
    "occurenceAlert": "Cannot reschedule an occurrence of the recurring appointment if it skips over a later occurrence of the same appointment.",
    "editRecurrence": "Edit Recurrence",
    "repeats": "Repeats",
    "alert": "Alert",
    "startEndError": "The selected end date occurs before the start date.",
    "invalidDateError": "The entered date value is invalid.",
    "blockAlert": "Events cannot be scheduled within the blocked time range.",
    "ok": "Ok",
    "yes": "Yes",
    "no": "No",
    "occurrence": "Occurrence",
    "series": "Series",
    "previous": "Previous",
    "next": "Next",
    "timelineDay": "Timeline Day",
    "timelineWeek": "Timeline Week",
    "timelineWorkWeek": "Timeline Work Week",
    "timelineMonth": "Timeline Month",
    "expandAllDaySection": "Expand",
    "collapseAllDaySection": "Collapse",
    "timelineYear": "Timeline Year",
    "editFollowingEvent": "Following Events",
    "deleteTitle": "Delete Event",
    "editTitle": "Edit Event",
    "beginFrom": "Begin From",
    "endAt": "End At",
    "searchTimezone": "Search Timezone",
    "noRecords": "No records found",
    "of": "of"
  },
  recurrenceeditor: {
    "none": "هیچکدام",
    "daily": "روزانه",
    "weekly": "هفتگی",
    "monthly": "ماهانه",
    "month": "ماه",
    "yearly": "سالانه",
    "never": "هرگز",
    "until": "تا",
    "count": "Count",
    "first": "اول",
    "second": "دوم",
    "third": "سوم",
    "fourth": "چهارم",
    "last": "آخر",
    "repeat": "تکرار",
    "repeatEvery": "تکرار هر",
    "on": "تکرار در",
    "end": "پایان",
    "onDay": "روز",
    "days": "روزها",
    "weeks": "هفته ها",
    "months": "ماه ها",
    "years": "سال ها",
    "every": "هر",
    "summaryTimes": "time(s)",
    "summaryOn": "on",
    "summaryUntil": "until",
    "summaryRepeat": "Repeats",
    "summaryDay": "day(s)",
    "summaryWeek": "week(s)",
    "summaryMonth": "month(s)",
    "summaryYear": "year(s)"
  },
  "datepicker": {
    today: "امروز"
  },
  grid: {
    EditOperationAlert: "هیچ رکوردی برای نمایش وجود ندارد",
    EmptyRecord: "هیچ رکوردی برای نمایش وجود ندارد",
    Search: "جستجو",
    True: "true",
    Print: "چاپ",
    Pdfexport: "خروجی به PDF",
    ExcelExport: "صادرات به Excel",
    InvalidFilterMessage: "فیلتر وارد شده معتبر نیست",

    SortAscending: "مرتب‌سازی صعودی",
    SortDescending: "مرتب‌سازی نزولی",
    FilterButton: "فیلتر",
    ClearButton: "پاک کردن",
    Cancel: "لغو"

  },
  pager: {
    currentPageInfo: `صفحه {0} از {1} `,
    totalItemsInfo: "({0} رکورد)",
    totalItemInfo: "({0} رکورد)",
    Search: "جستجو",
    Page: "صفحه",
    pagerDropDown: "تعداد در هر صفحه",
    Pages: "صفحات",
    Of: "از"
  },
  dialog: {
    buttons: {
      "ok": "تأیید",
      "cancel": "لغو"
    }
  },
  formValidator: {
    required: "پر کردن این فیلد الزامی است",
    email: "لطفاً یک ایمیل معتبر وارد کنید",
    minLength: "لطفاً حداقل {0} کاراکتر وارد کنید"
  },
  textbox: {
    required: "پر کردن این فیلد الزامی است",
    email: "لطفاً یک ایمیل معتبر وارد کنید",
    minLength: "لطفاً حداقل {0} کاراکتر وارد کنید"
  }
};